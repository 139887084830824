

import React, { useState, useEffect, useContext } from 'react'
import { useSelector, usestore, useStore } from "react-redux"

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Buttons from '../../../Common/button/Buttons';
import { CHECK, CLOSE, FILTER } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';

import { getUserSelectedDomain } from '../../../../store/actions';
import LandingDomainTabs from '../../../Common/mainLandingPage/components/LandingDomainTabs';
import SearchInput from '../../../Common/search/Search';
import SchedulerGridListIndex from '../../../Common/claristaNewLandingPage/SchedulerGridListIndex';
import { setSelectedScheduler } from '../../../../store/modules/scheduler/action';
import { Dropdown } from 'antd';



const SchedulerModal = ({ isModalOpen, onClose, fromRoute = '', data }) => {

    const store = useStore()
    const selectedTempScheduler = useSelector(state => state.Scheduler.selectedTempScheduler)
    const [searchTerm, setSearchTerm] = useState('')
    const [activeTabKey, setActiveTabKey] = useState('pod')
    const [activeDomain, setActiveDomain] = useState('')
    const [isDomainFailed, setDomainFailed] = useState(false)
    const [isDataLoading, setDataLoading] = useState(false)
    const [selectedFlow, setselectedFlow] = useState({})
    const [filterList, setFilterList] = React.useState({ connections: [], tags: [] })
    const [selectedSchedulerFilter, setselectedSchedulerFilter] = useState('')
    const [optionOpen, setOptionOpen] = useState(false)
    let defaultTabKey = 'pod'
    useEffect(() => {
        if (defaultTabKey) {
            setActiveTabKey(defaultTabKey)
        }
    }, [defaultTabKey])

    useEffect(() => {
        if (data &&
            Object.keys(data).length > 0 &&
            data?.data_domain_detail?.data_domain_id
        ) {
            setActiveDomain(data?.data_domain_detail?.data_domain_id)
        } else {
            // if (currentTransformer && !getObjectLength(currentTransformer?.content) && !currentTransformer?.content?.table_id) {

            store.dispatch(getUserSelectedDomain())
                .then(res => {
                    const domain = res?.data?.domain ?? undefined
                    if (domain && domain?.id) {
                        setActiveDomain(domain?.id)
                    }
                    else if (domain) {
                        setActiveDomain(domain?.id ?? 0)
                    }
                })
                .catch(err => {
                    setDomainFailed(true)
                    console.error(err?.message)
                })
            // }

        }

    }, [data])

 

    const optionList = [
        {
            label: <span className={`dbox-opt-itm`} onClick={() => setselectedSchedulerFilter('active')}>
                Active
            </span>,
            key: '1',
            disabled: false
        },
        {
            label: <span  className={`dbox-opt-itm`} onClick={() => setselectedSchedulerFilter('inactive')}>
                Inactive
            </span>,
            key: '2',
            disabled: false
        },
        {
            label: <span  className={`dbox-opt-itm`} onClick={() => setselectedSchedulerFilter('')}>
                All
            </span>,
            key: '3',
            disabled: false
        },

    ]
    return (
        <Modal size="xl" isOpen={isModalOpen} className="custom-modal scheduler-flow-modal" centered={true}>
            <ModalHeader>

                <div className="paragraph font-w-600">Select Scheduler</div>
                <Buttons props={{ tooltip: 'Close', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon float-right", buttonEvent: () => { onClose() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />

            </ModalHeader>
            <ModalBody className='overflow-hidden'>
                <div className='flow-select-modal'>
                    {/* <FlowsLandingPage isModal={true} fromRoute={fromRoute !== '' ? fromRoute : "alert"} /> */}
                    <div className='w-100'>
                        <div className=''>
                            <div className='domain-tabs'>
                                {
                                    // activeTabKey === 'business' ? ''
                                    //     :
                                    <LandingDomainTabs
                                        activeDomain={activeDomain}
                                        setActiveDomain={setActiveDomain}
                                        isDomainFailed={isDomainFailed}
                                        setDomainFailed={setDomainFailed}
                                        activeTabKey={activeTabKey}
                                        isDataLoading={isDataLoading}
                                    />
                                }
                                <div className='pod-filters d-flex justify-content-end'>

                                    <SearchInput
                                        searchData={searchTerm}
                                        setSearchData={setSearchTerm}
                                        placeholder={'Search Assests'}
                                        autoFocus={true}
                                    />
                                    <Dropdown
                                        destroyPopupOnHide={true}
                                        menu={{
                                            items: optionList,
                                            onClick: (e) => e.domEvent.stopPropagation()
                                        }}
                                        trigger={["hover"]}
                                        placement="bottomRight"
                                        onOpenChange={(bool) => setOptionOpen(bool)}
                                        overlayStyle={{ border: 'solid 1px #e4e4e4' }}
                                    >

                                        <button
                                            className={` ml-2
                                                 ${optionOpen ? "custom-btn-primary" : "custom-btn-outline"} 
                                                custom-btn btn-with-icon border-0 ${optionOpen || selectedSchedulerFilter ? 'rdg-filter-active' : ''}`
                                            }

                                        >
                                            <FILTER />
                                        </button>
                                    </Dropdown>

                                </div>
                            </div>
                        </div>
                        <div className='pod-list-wrap'>

                            {<SchedulerGridListIndex
                                activeDomain={activeDomain}
                                searchTerm={searchTerm}
                                activeTabKey={activeTabKey}
                                setselectedFlow={setselectedFlow}
                                filterList={filterList}
                                setFilterList={setFilterList}
                                fromRoute={'schedulerModal'}
                                setDataLoading={setDataLoading}
                                isDataLoading={isDataLoading}
                                selectedSchedulerFilter={selectedSchedulerFilter}
                            />}

                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Buttons props={{ tooltip: 'Done', buttonText: 'Done', buttonClassName: " custom-btn-primary custom-btn btn-with-text float-right", buttonEvent: () => { store.dispatch(setSelectedScheduler(selectedTempScheduler)); onClose() }, ImgSrc: () => <CHECK />, isDisable: selectedTempScheduler?.id || fromRoute === 'scheduler' ? false : true, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />

            </ModalFooter>

        </Modal >
    )
}

export default SchedulerModal
