import React from 'react'
import { ICON_CHAT_TAIL, ICON_CHECK, ICON_CLARISTA } from '../../Common/newIconSource'
import SqlEditor from '../dataCatalogue/preview/components/SqlEditor'
import copy from 'copy-to-clipboard';
import Buttons from '../../Common/button/Buttons';
import { COPY } from '../../Common/iconSource';
import { Literals } from '../common/literals';
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'

const ReplyMessage = React.forwardRef(({
    msg,
    sql
}, ref) => {

    const [copied, setCopied] = React.useState(false)

    React.useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 2000)
        }
    }, [copied])

    return (
        <div className='cpilot-conv-wrap'>
            <div className='cpilot-conv-reply'>
                {
                    sql && sql !== ''
                    ?
                    <div>
                        <div className='mb-1 fontInterSemiBold text-black d-flex justify-content-between'>
                            <span className='d-flex'>
                                SQL
                            </span>
                            <span className='d-flex position-relative' style={{top: -3}}>
                                <Buttons 
                                    props={{ 
                                            tooltip: copied ? 'Copied!' : 'Copy',
                                            tooltipPlacement: 'top',
                                            actionType: 'button', 
                                            buttonText: '', 
                                            buttonClassName: `${copied ? 'rdg-filter-active' : ''} 
                                            custom-btn-outline custom-btn`, 
                                            buttonEvent: () => { 
                                                copy(sql); 
                                                setCopied(true) 
                                            }, 
                                            ImgSrc: () => copied ? <ICON_CHECK/> : <COPY />, 
                                            isDisable: false, 
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT 
                                        }} 
                                />

                            </span>
                        </div>
                        <SqlEditor
                            QueryValue={sql}
                            disableMinimap={true}
                            hideLineNumbers={true}
                            previewQuery={true}
                            hideFolding={true}
                            editorFontSize={11}
                            editorPadding={{top: 5}}
                            // height={'100px'}
                            height={
                                (() => {
                                    if(sql) {
                                        let h = Math.min(280, sql?.split("\n").length * 25)
                                        return h
                                    }
                                    return '100px'
                                })()
                            }
                            readOnly={true}
                            configEditorOptions={
                                {
                                    contextmenu: false
                                }
                            }
                        />
                    </div>
                    :   ''
                }
                
                {
                    msg && msg !== ''
                    ?
                    <span className={`${sql && sql !== '' ? 'mt-2' : ''} d-block copilot-reply-box`} style={{wordBreak: 'break-word'}}>
                        {/* {msg} */}
                        <ReactMarkdown remarkPlugins={[remarkGfm]} children={msg}  />
                    </span>
                    :   ''
                }

                <span className='cpilot-conv-tail'>
                    <ICON_CHAT_TAIL/>
                </span>
            </div>
            <div className='cpilot-user-clarista'>
                <ICON_CLARISTA/>
            </div>
        </div>
    )
})

export default ReplyMessage