import axios from "axios";
import { setLoadingStatus } from "../store/modules/common/loadingActions";
import {
  getUser,
  logoutUser,
  removeUserToLocalStorage,
  setUser,
  supersetLogin,
  userLogut,
} from "../store/userAuthentication/userActions";
import { SET_FILE_PROGRESS } from "../store/modules/dataCatalogue/createPOD/createPODActionTypes";

import { fetchAccessToken } from "../store/userAuthentication/userActions";
import { setReduxDefault } from "../components/VerticalLayout/SidebarContent";
import { emitToastNotification } from "../helpers/toast_helper";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import { getGlobalConnectionCache } from "../store/modules/UserManagement/connection/connectionActions";

let cancelTokenSource = axios.CancelToken.source();



// Create an Axios instance with the cancellation token
let count = 0;
const instance = axios.create({
  cancelToken: cancelTokenSource.token,
});
export const fetchData = (
  method,
  endpoint,
  data,
  profileToken,
  setCookies,
  ...props
) => {
  return async (dispatch, getState) => {
    localStorage.setItem("showNetworkError", "false");
    const API_BASE_URL = window._env_.REACT_APP_API_BASE_URL;
    const TENANT_HOST_URL = window._env_.REACT_APP_PUBLIC_API_BASE_URL;

    const getnewEndpoint = () => {
      let locaAPIURL = JSON.parse(localStorage.getItem('TENANT_HOST_URL'));

      return new Promise((res, rej) => {
        if (!API_BASE_URL || (endpoint && endpoint?.startsWith('undefined'))) {
          if (locaAPIURL?.REACT_APP_API_BASE_URL) {

            if (endpoint && endpoint?.includes('undefined')) {
              let newEndpoint = cloneDeep(endpoint);
              let url = newEndpoint;
              const undefinedStr = "undefined";
              // Find the index of the first occurrence of "undefined"
              let indexOfUndefined = url.indexOf("undefined");

              const startIndex = indexOfUndefined + undefinedStr?.length;

              if (startIndex !== -1) {
                // Extract the substring starting from the first occurrence of "undefined"
                let substringAfterUndefined = url.substring(startIndex);
                endpoint = `${locaAPIURL?.REACT_APP_API_BASE_URL}${substringAfterUndefined}`;

                return res(endpoint)
              } else {
                res(endpoint)
              }
            } else {
              return res(endpoint)
            }
          } else {
            let requestParams = {
              url: `${TENANT_HOST_URL}tenant/tenant_host/`,
              method: "GET",
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              }
            };
            // let requestParams = {
            //   url: `${TENANT_HOST_URL}tenant/tenant_host/`,   /// for localhost call 
            //   method: "POST",
            //   data: JSON.stringify({
            //     "UI_HOST": "dev.claristaconnect.com"
            //   }),
            //   headers: {
            //     "Content-Type": "application/json;charset=UTF-8",
            //   }
            // };

            instance(requestParams).then((resp) => {

              if (resp.status === 200) {
                let data = resp?.data?.data

                window._env_['REACT_APP_API_BASE_URL'] = `https://${data?.APP_HOST}/`

                window._env_['REACT_APP_VISUALIZATION_BASE_URL'] = `https://${data?.SUPERSET_HOST}/`
                window._env_['REACT_APP_TRINO_HOST_URL'] = `${data?.TRINO_HOST}`

                let host_url = {
                  'REACT_APP_API_BASE_URL': `https://${data?.APP_HOST}/`,

                  'REACT_APP_VISUALIZATION_BASE_URL': `https://${data?.SUPERSET_HOST}/`,
                  'REACT_APP_TRINO_HOST_URL': `${data?.TRINO_HOST}`,

                }
                localStorage.setItem('TENANT_HOST_URL', JSON.stringify(host_url))
              
                if (endpoint) {
                  let newEndpoint = cloneDeep(endpoint);
                  let url = newEndpoint;
                  const undefinedStr = "undefined";
                  // Find the index of the first occurrence of "undefined"
                  let indexOfUndefined = url.indexOf("undefined");

                  const startIndex = indexOfUndefined + undefinedStr?.length;

                  if (startIndex !== -1) {
                    // Extract the substring starting from the first occurrence of "undefined"
                    let substringAfterUndefined = url.substring(startIndex);
                    endpoint = `${host_url?.REACT_APP_API_BASE_URL}${substringAfterUndefined}`;

                    return res(endpoint)
                  } else {
                    res(endpoint)
                   
                  }
                }
              }
            })
          }
        } else {
          res(endpoint)
        }
      })

    }

    endpoint = await getnewEndpoint();


    const userDetails = getState();
    let object = {};
    let json;
    if (data instanceof FormData) {
      data.forEach(function (value, key) {
        object[key] = value;
      });
      json = object;
    }
    let params = data;
    let requestParams = {};
    let headers = {};

    if (data !== undefined && data instanceof FormData) {
      headers = {
        "Content-Type": "multipart/form-data",
      };
      requestParams = {
        url: endpoint,
        method: method,
        onUploadProgress: profileToken,
        withCredentials: true,
      };
    } else {
      headers = {
        "Content-Type": "application/json;charset=UTF-8",
      };

      requestParams = {
        url: endpoint,
        method: method,
        withCredentials: true, ///endpoint.includes('auth/login') ? false : true /// added for auto cookies read and send in every request except login api
      };
      params = JSON.stringify(data);
    }
    if (
      (endpoint.indexOf("query_download") !== -1 &&
        data["download"] === true) ||
      endpoint.includes("dictionary/download_template/") ||
      (data && data?.endpoint && endpoint.includes(data.endpoint))
    ) {
      Object.assign(requestParams, {
        responseType: "arraybuffer",
      });
    }

    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie =
        name +
        "=null;" +
        "expires=Thu, 01-Jan-1970 00:00:01 GMT;" +
        "path=" +
        "/;";
    }


    Object.assign(requestParams, {
      headers: {
        ...headers,
      },
    });

    if (method.toLowerCase() === "get") {
      Object.assign(requestParams, {
        params: data,
      });
    } else if (data) {
      Object.assign(requestParams, {
        data: params,
      });
    }
    // let count = 0;

    instance.interceptors.response.use(
      function (response) {
        localStorage.setItem("isUnAuth", false);
        return response;
      },

      function (error) {
        return new Promise((resolve, reject) => {
          const { config, response } = error;
          console.log(count)
          if (
            response &&
            response.status === 401 &&
            !window.location.pathname?.includes("/login") &&
            !window.location.pathname?.includes("/logout") &&
            count < 1
          ) {
            dispatch(logoutUser(dispatch));
            count = 1;
            // dispatch(userLogut());
            // removeUserToLocalStorage();
            // setReduxDefault();
            // localStorage.clear();
          } else {
            localStorage.setItem("isUnAuth", false);
            count = 0;
            return reject(error);
          }
          return reject(error);
        });
      }
    );

    return new Promise((resolve, reject) => {
      count = 0;
      let isUnAuth = localStorage.getItem("isUnAuth");

      instance(requestParams)
        .then((resp) => {
          if (
            resp &&
            (resp.status === 200 || resp.status === 204 || resp.status === 201)
          ) {
            instance.interceptors.response?.clear();
            instance.interceptors.request?.clear();
            localStorage.setItem("showNetworkError", "false");
            localStorage.setItem("supersetLoginDone", false);
            resolve(resp.data);
          } else if (resp && resp?.status !== 401) {
            instance.interceptors.response?.clear();
            instance.interceptors.request?.clear();
            return reject(resp);
          }
        })
        .catch((error) => {
          count = 0;
          instance.interceptors.response?.clear();
          instance.interceptors.request?.clear();

          let showNetworkError = localStorage.getItem("showNetworkError");
          localStorage.setItem("supersetLoginDone", false);

         
          if (
            (showNetworkError === "false" &&
              error &&
              error.code === "ERR_NETWORK" &&
              !error?.response &&
              error?.response?.status !== 401) ||
            error === undefined
          ) {
            dispatch(setLoadingStatus(false));
            // console.log(error);
            if (!error?.config?.url?.includes('latest_status/')) {
              reject({ status: "CORS", message: "" });
              if (error?.message) {
                localStorage.setItem("showNetworkError", "true");
                emitToastNotification(
                  "error",
                  error.message,
                  "",
                  {
                    timeOut: 500,
                    autoClose: 500,
                    closeMethod: "fadeOut",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    closeEasing: "swing",
                    closeOnClick: true,
                    draggable: false,
                    enableHtml: true,
                    preventDuplicates: true,
                  },
                  1
                );
              } else if (error === undefined) {
                // emitToastNotification(
                //   "error",
                //   "Network Error",
                //   "",
                //   {
                //     timeOut: 500,
                //     autoClose: 500,
                //     closeMethod: "fadeOut",
                //     closeEasing: "swing",
                //     position: toast.POSITION.BOTTOM_RIGHT,
                //     closeOnClick: true,
                //     draggable: false,
                //     enableHtml: true,
                //     preventDuplicates: true,
                //   },
                //   1
                // );
              }
            }

            // localStorage.setItem("showNetworkError", "true");
          }
          if (
            showNetworkError === "false" &&
            (error?.response?.status === 502 ||
              error?.response?.status === 503) &&
            error?.response?.status !== 401 &&
            error?.response?.data?.message?.toLowerCase() !==
            "token is invalid or expired"
          ) {
            dispatch(setLoadingStatus(false));
            // localStorage.setItem("showNetworkError", "true");

            return reject({ status: 502, message: "" });
          }

          if (
            error &&
            error?.response?.status === 401 &&
            error?.response?.config?.url?.includes("auth/login/") &&
            error?.response?.data?.message?.toLowerCase() !==
            "token is invalid or expired"
          ) {
            return reject(error?.response);
          } else if (
            error &&
            error?.response?.status === 403 &&
            error?.response?.status !== 401 &&
            error?.response?.data?.message?.toLowerCase() !==
            "token is invalid or expired"
            && !window.location.href?.includes('/logout')
          ) {
            let newObj = cloneDeep(error?.response);
            newObj["message"] = error?.response?.data?.message;

            return reject(newObj);
          } else if (
            error &&
            error?.response?.status !== 401 &&
            error?.response?.data?.message &&
            error?.response?.data?.message?.toLowerCase() !==
            "token is invalid or expired"
          ) {
            error["response"]["data"]["status"] = error?.response?.status;
            return reject(error?.response?.data);
          } else {
            let newError = {};
            if (error && error.code === "ERR_NETWORK") {
              newError["response"] = {
                data: { status: error?.code, message: "" },
              };
            }
            if (
              error &&
              error?.response?.status === 401 &&
              error?.response?.data?.message
            ) {
              error["response"]["data"]["message"] = undefined;
            }
            if (!error.response) {
              return reject(newError?.response?.data); // resolve 500 error
            } else {
              return reject(error?.response?.data); // resolve 500 error
            }
          }
        });
      // }
    });
  };
};

export const callS3API = (method, endpoint, contentType, data, fileName) => {
  return async (dispatch, getState) => {

    const API_BASE_URL = window._env_.REACT_APP_API_BASE_URL;
    const TENANT_HOST_URL = window._env_.REACT_APP_PUBLIC_API_BASE_URL;

    const getnewEndpoint = () => {
      let locaAPIURL = JSON.parse(localStorage.getItem('TENANT_HOST_URL'));
      return new Promise((res, rej) => {
        if (!API_BASE_URL || (endpoint && endpoint?.startsWith('undefined'))) {
          if (locaAPIURL?.REACT_APP_API_BASE_URL) {

            if (endpoint && endpoint?.includes('undefined')) {
              let newEndpoint = cloneDeep(endpoint);
              let url = newEndpoint;
              const undefinedStr = "undefined";
              // Find the index of the first occurrence of "undefined"
              let indexOfUndefined = url.indexOf("undefined");

              const startIndex = indexOfUndefined + undefinedStr?.length;

              if (startIndex !== -1) {
                // Extract the substring starting from the first occurrence of "undefined"
                let substringAfterUndefined = url.substring(startIndex);
                endpoint = `${locaAPIURL?.REACT_APP_API_BASE_URL}${substringAfterUndefined}`;

                return res(endpoint)
              } else {
                res(endpoint)

             
              }
            } else {
              return res(endpoint)
            }
          } else {
            let requestParams = {
              url: `${TENANT_HOST_URL}tenant/tenant_host/`,
              method: "GET",
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              }
            };
            instance(requestParams).then((resp) => {

              if (resp.status === 200) {
                let data = resp?.data?.data

                window._env_['REACT_APP_API_BASE_URL'] = `https://${data?.APP_HOST}/`

                window._env_['REACT_APP_VISUALIZATION_BASE_URL'] = `https://${data?.SUPERSET_HOST}/`
                window._env_['REACT_APP_TRINO_HOST_URL'] = `${data?.TRINO_HOST}`

                let host_url = {
                  'REACT_APP_API_BASE_URL': `https://${data?.APP_HOST}/`,

                  'REACT_APP_VISUALIZATION_BASE_URL': `https://${data?.SUPERSET_HOST}/`,
                  'REACT_APP_TRINO_HOST_URL': `${data?.TRINO_HOST}`,

                }
                localStorage.setItem('TENANT_HOST_URL', JSON.stringify(host_url))
                if (endpoint) {
                  let newEndpoint = cloneDeep(endpoint);
                  let url = newEndpoint;
                  const undefinedStr = "undefined";
                  // Find the index of the first occurrence of "undefined"
                  let indexOfUndefined = url.indexOf("undefined");

                  const startIndex = indexOfUndefined + undefinedStr?.length;

                  if (startIndex !== -1) {
                    // Extract the substring starting from the first occurrence of "undefined"
                    let substringAfterUndefined = url.substring(startIndex);
                    endpoint = `${data?.APP_HOST}${substringAfterUndefined}`;

                    return res(endpoint)
                  } else {
                    res(endpoint)
                  
                  }
                }
              }
            })
          }
        } else {
          res(endpoint)
        }
      })

    }

    endpoint = await getnewEndpoint();

    const state = getState();
    let requestParams = {
      url: endpoint,
      method: method,
      withCredentials: true,
    };

    let headers = {
      "Content-Type": contentType ?? "application/json;charset=UTF-8",
      // "Content-Type": "application/json;charset=UTF-8",
    };
    Object.assign(requestParams, {
      headers: {
        ...headers,
      },
    });
    Object.assign(requestParams, {
      onUploadProgress: (data) => {
        let progress = Math.round((100 * data.loaded) / data.total);
        if (progress < 100) {
          progress = progress - 30;
          // dispatch(setFileProgress(progress, fileName, state));
        }
      },
    });

    let params = data;

    if (method === "GET") {
      Object.assign(requestParams, {
        params: data,
      });
    } else if (data) {
      Object.assign(requestParams, {
        data: params,
      });
    }
    
    instance.interceptors.response.use(
      function (response) {
        localStorage.setItem("isUnAuth", false);
        return response;
      },

      function (error) {
        return new Promise((resolve, reject) => {
          const { config, response } = error;
          if (
            response &&
            response.status === 401 &&
            !window.location.pathname?.includes("/login") &&
            !window.location.pathname?.includes("/logout") &&

            count < 1
          ) {
            dispatch(logoutUser(dispatch));
            count = 1;
           
          } else {
            localStorage.setItem("isUnAuth", false);
           
            return reject(error);
          }
          return reject(error);
        });
      }
    );

    return new Promise((resolve, reject) => {
      let isUnAuth = localStorage.getItem("isUnAuth");

      // if (isUnAuth === null || isUnAuth === "false") {
      instance(requestParams)
        .then((resp) => {
          let tempList = JSON.parse(localStorage.getItem("ETagList"));
          if (resp.headers?.etag) {
            let tag = resp.headers?.etag;
            tag = tag.replace(/\\/g, "");
            tag = tag.replace(/["'\\/]/g, "");

            tempList = [...tempList, tag];
            localStorage.setItem("ETagList", JSON.stringify(tempList));
          }
          // dispatch(setFileProgress(100, fileName, state));
          if (resp.status == 200 || resp.status == 204) {
            resolve(resp);
          } else {
            reject(resp);
          }
        })
        .catch((error) => {
          reject(error?.response?.data);
        });
      // }
    });
  };
};

export const postMultiPartData = (prefix, name, size, payload) => {
  let BASE_URL = window._env_.REACT_APP_API_BASE_URL;
  const TENANT_HOST_URL = window._env_.REACT_APP_PUBLIC_API_BASE_URL;

  return (dispatch) => {
    dispatch(setLoadingStatus(true));

    return new Promise((resolve, reject) => {
      if (!BASE_URL) {
        let locaAPIURL = JSON.parse(localStorage.getItem('TENANT_HOST_URL'));
        if (locaAPIURL?.REACT_APP_API_BASE_URL) {
          BASE_URL = locaAPIURL?.REACT_APP_API_BASE_URL;
        } else {
          let requestParams = {
            url: `${TENANT_HOST_URL}tenant/tenant_host/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            }
          };
          axios(requestParams).then((resp) => {

            if (resp.status === 200) {
              let data = resp?.data?.data

              window._env_['REACT_APP_API_BASE_URL'] = `https://${data?.APP_HOST}/`

              window._env_['REACT_APP_VISUALIZATION_BASE_URL'] = `https://${data?.SUPERSET_HOST}/`
              window._env_['REACT_APP_TRINO_HOST_URL'] = `${data?.TRINO_HOST}`

              let host_url = {
                'REACT_APP_API_BASE_URL': `https://${data?.APP_HOST}/`,

                'REACT_APP_VISUALIZATION_BASE_URL': `https://${data?.SUPERSET_HOST}/`,
                'REACT_APP_TRINO_HOST_URL': `${data?.TRINO_HOST}`,

              }
              localStorage.setItem('TENANT_HOST_URL', JSON.stringify(host_url))
              BASE_URL = `https://${data?.APP_HOST}/`;
            }
          })
        }
        dispatch(
          fetchData(
            "POST",
            `${BASE_URL}file_upload/v2/multi_part/?prefix=${prefix}&name=${name}&size=${size}`,
            payload
          )
        )
          .then((resp) => {
            dispatch(setLoadingStatus(false));
            if (resp.status !== "success") {
              emitToastNotification("error", resp.message);
              reject(resp.message);
            } else {
              resolve(resp);
            }
          })
          .catch((err) => {
            dispatch(setLoadingStatus(false));

            emitToastNotification("error", err?.message);
            reject(err);
          });
      } else {
        dispatch(
          fetchData(
            "POST",
            `${BASE_URL}file_upload/v2/multi_part/?prefix=${prefix}&name=${name}&size=${size}`,
            payload
          )
        )
          .then((resp) => {
            dispatch(setLoadingStatus(false));
            if (resp.status !== "success") {
              emitToastNotification("error", resp.message);
              reject(resp.message);
            } else {
              resolve(resp);
            }
          })
          .catch((err) => {
            dispatch(setLoadingStatus(false));

            emitToastNotification("error", err?.message);
            reject(err);
          });
      }

    });
  };
};
// export const setFileProgress = (progress, fileName, state) => {
//   let res = {};
//   if (
//     state &&
//     state.CreatePODReducer &&
//     state.CreatePODReducer.fileProgress !== undefined
//   ) {
//     res = state.CreatePODReducer.fileProgress;
//   }
//   res[fileName] = progress;
//   return {
//     type: SET_FILE_PROGRESS,
//     payload: res,
//   };
// };
