import React from "react";
import { Tree } from "antd";
import { BlobServiceClient } from '@azure/storage-blob';

import { postMultiPartData } from "../../../../services/apiService";

import {
  SET_CREATEPOD_PROCEEDS,
  SET_ACTIVE_TAB_PUBLISH,
  SET_KEY,
  SET_ROW_SELECTED_INDEX,
  SET_CREATEPODFOLDER_SETSELECTEDFILES,
  SET_CREATEPOD_PAGE_DEFAULT,
  TOGGLE_NEW_CONNECTION,
  SET_CREATEPOD_TREEDATA,
  SET_CREATEPOD_DEFAULT,
  SET_CREATEPOD_SOURCETYPE,
  SET_NEWCONN_DATABASELIST,
  SET_CONNECTION_NAME,
  SET_CONNECTION_NAME2,
  SET_CREATEPODTREE_MENUITEMS,
  SET_CREATEPOD_TABSARRAY,
  SET_CREATEPOD_CUSTOMTABS,
  SET_CREATEPOD_PUBLISHPOD,
  SET_PREVIEW_DATA,
  SET_DELIMETER,
  SET_HEADER,
  SET_CONN_LIST,
  SET_CREATE_POD_TABLE_DATA_MAP,
  SET_ACTIVE_CREATE_POD_NAME,
  SET_GENERATE_QUERY,
  SET_QUERY,
  RESET_CREATE_POD_DATA,
  SET_CREATE_POD_USER_INPUT_DATA_MAP,
  SET_ACTIVE_POD_DATA_KEY,
  SET_INITIAL_TREE_DATA,
  SET_VIRTUAL_TABLES_DATA_MAP,
  SET_INITIAL_EXPLORE_TAB_DATA,
  SET_POD_TABS_LIST,
  SET_PREVIEW_TABLE_DATA,
  SET_TREE_DATA_SET,
  SET_INITIAL_SCHEMA_DATA,
  SET_FILE_NEXT_TOKEN,
  SET_IS_POD_UPDATE_VIEW,
  SET_POD_UPDATE_RIGHT_SIDE_DATA,
  SET_POD_UPDATE_LEFT_SIDE_DATA,
  SET_POD_UPDATE_TREE_DATA,
  SET_POD_RIGHT_SIDE_DATA,
  SET_POD_LEFT_SIDE_DATA,
  RESET_POD_LEFT_SIDE_DATA,
  RESET_POD_RIGHT_SIDE_DATA,
  RESET_VIRTUAL_TABLES_DATA_MAP,
  SET_FILE_PROGRESS,
  SET_UPLOAD_FILE_PROGRESS,
} from "./createPODActionTypes";
import { setLoadingStatus } from "../../common/loadingActions";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { fetchData, callS3API } from "../../../../services/apiService";
import { options } from "../../../../components/modules/common/toastrProperties";
import _, { cloneDeep } from "lodash";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { setLandingPageData } from "../landingPage/landingPageActions";

// const Path = require('path')
const Path = '';

const LANDING_PAGE_API_URL = window._env_.REACT_APP_API_BASE_URL;

toastr.options = {
  ...options,
};
export const setCreatePODProceeds = (data) => {
  return {
    type: SET_CREATEPOD_PROCEEDS,
    payload: data,
  };
};
export const setActiveTabPublish = (data) => {
  return {
    type: SET_ACTIVE_TAB_PUBLISH,
    payload: data,
  };
};
export const setSelectedIndex = (data) => {
  return {
    type: SET_ROW_SELECTED_INDEX,
    payload: data,
  };
};

export const setKey = (data) => {
  return {
    type: SET_KEY,
    payload: data,
  };
};
export const setCreatePodSourcetype = (data) => {
  return {
    type: SET_CREATEPOD_SOURCETYPE,
    payload: data,
  };
};

export const setCreatePodDefault = () => {
  return {
    type: SET_CREATEPOD_DEFAULT,
  };
};
export const setCreatePodPageDefault = () => {
  return {
    type: SET_CREATEPOD_PAGE_DEFAULT,
  };
};

export const setCreatePODPublishPOD = (data) => {
  return {
    type: SET_CREATEPOD_PUBLISHPOD,
    payload: data,
  };
};

export const setCreatePODTreeData = (data) => {
  return {
    type: SET_CREATEPOD_TREEDATA,
    payload: data,
  };
};

export const setCreatePODTabsArray = (data) => {
  return {
    type: SET_CREATEPOD_TABSARRAY,
    payload: data,
  };
};

export const updateFileUploadProgress = (data) => {
  return {
    type: SET_UPLOAD_FILE_PROGRESS,
    payload: data,
  };
}


export const setCreatePODCustomTabs = (data) => {
  return {
    type: SET_CREATEPOD_CUSTOMTABS,
    payload: data,
  };
};

export const setCreatePODTreeMenuItems = (data) => {
  return {
    type: SET_CREATEPODTREE_MENUITEMS,
    payload: data,
  };
};

export const setCreatePODFolderSetSelectedFiles = (data) => {
  return {
    type: SET_CREATEPODFOLDER_SETSELECTEDFILES,
    payload: data,
  };
};

export const setConnectionName = (data) => {
  return {
    type: SET_CONNECTION_NAME,
    payload: data,
  };
};
export const setConnectionName2 = (data) => {
  return {
    type: SET_CONNECTION_NAME2,
    payload: data,
  };
};

export const toggleNewConnection = (data) => {
  return {
    type: TOGGLE_NEW_CONNECTION,
    payload: data,
  };
};

export const setNewConnDatabaseList = (data) => {
  return {
    type: SET_NEWCONN_DATABASELIST,
    payload: data,
  };
};

export const setPreviewDataChanges = (data) => {
  return {
    type: SET_PREVIEW_DATA,
    payload: data,
  };
};

export const setConnTableList = (data) => {
  return {
    type: SET_CONN_LIST,
    payload: data,
  };
};

export const setDelimeterReduxAction = (data) => {
  return {
    type: SET_DELIMETER,
    payload: data,
  };
};

export const setHeaderReduxAction = (data) => {
  return {
    type: SET_HEADER,
    payload: data,
  };
};

export const getConnectionListCreatePODAction = (editor = false, showLoader = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${LANDING_PAGE_API_URL}connection/${editor ? '?editor=true' : ''}`))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status !== 'success') {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message);
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          reject(err)
        });
    });
  };
};

export const getDomainConnectionList = (editor = false, data_domain = '', showLoader = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${LANDING_PAGE_API_URL}connection/?data_domain_id=${data_domain}${editor ? '&editor=true' : ''}`))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status !== 'success') {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification('error', err.message);
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          reject(err)
        });
    });
  };
};

export const getDatabaseList = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}connection`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            dispatch(setNewConnDatabaseList(resp.data));
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));

        });
    });
  };
};

export const testConnection = (payload, isUpdate = false) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        isUpdate
          ? fetchData("POST", `${LANDING_PAGE_API_URL}connection/${payload?.connection_name}/test/`, payload)
          : fetchData("POST", `${LANDING_PAGE_API_URL}connection/test/`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
            emitToastNotification('success', resp.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message || err?.status);
          reject(err);
        });
    });
  };
};

export const fetchDatabaseSchemaList = (payload, showLoader = true, showToast = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}preview/v2/list_schema/`, payload)
      )
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            reject(resp?.message);
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const fetchDatabaseList = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}connection-preview`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};

export const fetchDatabaseTableList = (payload, showLoader = true, showToast = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}preview/v2/list_table/`, payload)
      )
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            reject(resp.message);
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const fetchTableList = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}connection-preview`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp?.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};

export const fetchTableDataV2 = (payload, showLoader = true, showToast = true, regex = false, autoClassify = false) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}preview/v2/${autoClassify ? '?classify=true' : regex ? '?regex=true' : ''}`, payload)
      )
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            reject(resp?.message);
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const fetchTableData = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}preview/v2`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            reject(resp?.message);
            emitToastNotification('error', resp?.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};

export const fetchCustomQuery = (payload, showLoader = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${LANDING_PAGE_API_URL}connection-preview`, payload)
      )
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          emitToastNotification('error', err.message);
          reject(err);
        });
    });
  };
};

export const fetchFileSystem = (payload, showToast = true) => {
  return (dispatch) => {

    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file-system/v2/`, payload))
        .then((resp) => {

          if (resp.status === false) {
            reject(resp.message);
            if (showToast) {
              emitToastNotification('error', resp.message);
            }
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err.message)
          }
          reject(err)

        });
    });
  };
};

export const fetchS3Buckets = (payload) => {
  return (dispatch) => {

    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file-system`, payload))
        .then((resp) => {

          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {

        });
    });
  };
};

export const fetchAzureBlobs = (payload) => {
  return (dispatch) => {

    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file-system`, payload))
        .then((resp) => {

          if (resp.status === false) {
            reject(resp.message);
            emitToastNotification('error', resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {

        });
    });
  };
};

export const pushCreatePOD = (payload, format, showToast = true) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST",
        format && format !== ''
          ? `${LANDING_PAGE_API_URL}pod_crud/v2/?excel=${format.toLowerCase() === 'xlsx'}`
          : `${LANDING_PAGE_API_URL}pod_crud/v2/`
        , payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            resolve(resp.status);
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          } else {
            dispatch(setLandingPageData([]))
            resolve(resp);
            if (showToast) {
              emitToastNotification('success', resp?.message);
            }
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};
export const updateCreatePOD = (payload, tableId, { showLoader = true, showToast = true }) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("PUT", `${LANDING_PAGE_API_URL}pod_crud/v2/${tableId}/`, payload))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            resolve(resp.status);
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          } else {
            resolve(resp);
            if (showToast) {
              emitToastNotification('success', resp?.message);
            }
          }
        })
        .catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const getPodDetails = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}pod_crud`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            resolve(resp.status);
            emitToastNotification('error', resp?.message);
          } else {
            resolve(resp);

          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          resolve(err.status);
        });
    });
  };
};

export const fetchFileSystemPreview = (payload, sheets = '', showToast = true, showLoader = true, autoClassify = false) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file-system/v2/preview/?${autoClassify ? 'classify' : 'regex'}=true${sheets !== '' ? `?sheet_name=${sheets}` : ''}`, payload))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === "success") {
            resolve(resp);
          } else {
            reject(resp.message);
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          }
        })
        .catch((err) => {

          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const fetchUnstructuredPreview = (payload, showToast = true, showLoader = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file-system/v2/preview/?regex=true&topics=true`, payload))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === "success") {
            resolve(resp);
          } else {
            reject(resp.message);
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          }
        })
        .catch((err) => {

          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const getQuickUnstructuredPreview = (prefix, name, connectionName, showToast = true, showLoader = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${LANDING_PAGE_API_URL}file_upload/v2/file_download/?prefix=${prefix}&name=${name}${connectionName ? '&connection_name=' + connectionName : ''}`,))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === "success") {
            resolve(resp);
          } else {
            reject(resp.message);
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          }
        })
        .catch((err) => {

          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const getXLSXSheets = (payload, showLoader = true, showToast = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file-system/v2/sheets/`, payload))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === "success") {
            resolve(resp);
          } else {
            reject(resp.message);
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          }
        })
        .catch((err) => {

          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const saveParq = (payload, showLoader = true, showToast = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST",
        `${LANDING_PAGE_API_URL}file-system/v2/save_parq/`,
        payload))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === "success") {
            resolve(resp);
          } else {
            reject(resp.message);
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          }
        })
        .catch((err) => {

          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const getUploadCatalog = (showLoader = true, showToast = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${LANDING_PAGE_API_URL}connection/get_upload_catalog/`))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === "success") {
            resolve(resp);
          } else {
            reject(resp.message);
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          }
        })
        .catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const fetchS3FilePreview = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file-system`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === "success") {
            resolve(resp);
          } else {
            reject(resp.message);
            emitToastNotification('error', resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    });
  };
};
export const downloadFile = async (blobClient, fileName) => {
  return new Promise(async (resolve, reject) => {


    let newFileName = fileName;
    // let newFileName = Path.basename(fileName);
    // const blobClient = containerClient.getBlobClient(newFileName);
    let extension = _.last(newFileName.split('.'));
    const downloadBlockBlobResponse = await blobClient.download();
    const downloaded = await downloadBlockBlobResponse.blobBody;

    // const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);

    const element = document.createElement("a");
    const file = new Blob([downloaded], { type: `text/${extension}` });
    element.href = URL.createObjectURL(file);
    element.download = newFileName;
    document.body.appendChild(element);
    element.click();
    resolve();
  })
}
async function blobToString(blob) {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onloadend = (ev) => {
      resolve(ev.target.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(blob);
  });
}
export const downloadAzureFile = async (url, fileName, accountName, containerName, path, podName, dispatch, showLoader = true) => {
  const sasToken = url.split('?')[1]
  const storageAccountUriString = `https://${accountName}.blob.core.windows.net?${sasToken}`;
  const blobService = new BlobServiceClient(storageAccountUriString);

  let tempName = `${path}/${podName}/${fileName}`;

  const containerClient = blobService.getContainerClient(containerName);

  const blobClient = containerClient.getBlockBlobClient(tempName);
  if (showLoader) {
    dispatch(setLoadingStatus(true))
  }
  await downloadFile(blobClient, fileName)
  if (showLoader) {
    dispatch(setLoadingStatus(false))
  }
}
export const callAzure = async (url, file, fileName, accountName, containerName, path, podName, dispatch, state, showLoader = true) => {
  if (showLoader) {
    dispatch(setLoadingStatus(true));
  }

  const sasToken = url.split('?')[1]
  const storageAccountUriString = `https://${accountName}.blob.core.windows.net?${sasToken}`;
  const blobService = new BlobServiceClient(storageAccountUriString);

  let tempName = `${path}/${podName}/${fileName}`;

  const containerClient = blobService.getContainerClient(containerName);

  const blobClient = containerClient.getBlockBlobClient(tempName);
  let res = { ...state.CreatePODReducer.fileProgress, [fileName]: 100 };
  dispatch(setFileProgress(res))

  // res[fileName]=100;

  await blobClient.uploadData(file);

  if (showLoader) {
    dispatch(setLoadingStatus(false))
  }
  return new Promise(resolve => resolve(url))
}


export const commitBlockList = async (url, file, fileName, accountName, containerName, path, podName, dispatch, state, blockId, blockIds, showLoader = true, rest) => {

  return new Promise(async (resolve, reject) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }

    const sasToken = url.split('?')[1]
    const storageAccountUriString = `https://${accountName}.blob.core.windows.net?${sasToken}`;
    const blobService = new BlobServiceClient(storageAccountUriString);

    let tempName = `${path}/${podName}/${fileName}`;

    const containerClient = blobService.getContainerClient(containerName);

    const blockBlobClient = containerClient.getBlockBlobClient(tempName);
    let res = { ...state.CreatePODReducer.fileProgress, [fileName]: 100 };
    dispatch(setFileProgress(res))

    // res[fileName]=100;
    let encodedBlockId = btoa(blockId)
  
    const uploadOptions = { blobHTTPHeaders: { blobContentType: rest?.fileContentType } }

    await blockBlobClient.commitBlockList(blockIds, uploadOptions);
    // await blobClient.uploadData(file);
    if (showLoader) {
      dispatch(setLoadingStatus(false))
    }
    resolve();
  })
}

// export const callAzureMultiPart = async (url, file, fileName, accountName, containerName, path, podName, dispatch, state, blockId, blockIds) => {

//   return new Promise(async (resolve, reject) => {

//     try {
//       const sasToken = url.split('?')[1]
//       const storageAccountUriString = `https://${accountName}.blob.core.windows.net?${sasToken}`;
//       const blobService = new BlobServiceClient(storageAccountUriString);

//       let tempName = `${path}/${podName}/${fileName}`;

//       const containerClient = blobService.getContainerClient(containerName);

//       const blockBlobClient = containerClient.getBlockBlobClient(tempName);
//       let encodedBlockId = btoa(blockId)



//       await blockBlobClient.stageBlock(encodedBlockId, file, file.size);
//       blockIds.push(encodedBlockId);
//       // await blobClient.uploadData(file);
//       resolve();
//     }
//     catch (err) {
//       reject(err ?? { status: 'error', message: 'Failed to upload' })
//     }
//   })
// }
function generateBlockID(index, padLength) {
  const rawId = `block-${index.toString().padStart(padLength, '0')}`;
  let encodedId = btoa(rawId);
  encodedId = encodedId.replace(/=+$/, ''); // Remove '=' padding
  return encodeURIComponent(encodedId); // Ensure URL safety
}



export const callAzureMultiPart = async (url, file, fileName, accountName, containerName, path, podName, dispatch, state, blockIds) => {
  return new Promise(async (resolve, reject) => {
    try {
      const abortController = new AbortController();
      let abortSignal = abortController.signal;


      const sasToken = url.split('?')[1];
      const storageAccountUriString = `https://${accountName}.blob.core.windows.net?${sasToken}`;
      const blobService = new BlobServiceClient(storageAccountUriString, null, { abortSignal });
      let tempName = `${path}/${podName}/${fileName}`;
      const containerClient = blobService.getContainerClient(containerName);
      const blockBlobClient = containerClient.getBlockBlobClient(tempName);

      const blockSize = 50 * 1024 * 1024; // 50 MB per chunk
      let tasks = [];
      let currentPosition = 0;
      let partIndex = 0;
      let uploadedBytes = 0;


      while (currentPosition < file.size && !abortSignal.aborted) {
        let isCancelBtnClick = JSON.parse(localStorage.getItem('isCancelBtnClick'));
        if (isCancelBtnClick?.aborted) {


          console.log(abortController.signal.aborted); // Initially false

          abortController.abort();
          console.log(abortController.signal.aborted); // Initially false


        }
        const blockLength = Math.min(blockSize, file.size - currentPosition);
        const block = file.slice(currentPosition, currentPosition + blockLength);
        const blockIdBase64 = generateBlockID(partIndex, 6);
        currentPosition += blockLength;

        tasks.push(uploadBlock(blockBlobClient, blockIdBase64, block, blockLength, abortSignal)
          .then(result => {
            blockIds.push(result.blockIdBase64);
            uploadedBytes += result.blockLength;
            dispatch(updateFileUploadProgress((uploadedBytes / file.size) * 100))
            // if (state && state.updateProgress) {
            //   state.updateProgress((uploadedBytes / file.size) * 100);
            // }
          }));

        if (tasks.length >= 5) {
          await Promise.all(tasks);
          tasks = []; // Clear the tasks array for next batch of uploads
        }

        partIndex++;
      }

      // Handle any remaining tasks
      if (tasks.length > 0 && !abortSignal.aborted) {
        await Promise.all(tasks);
      }

      if (!abortSignal.aborted) {
        console.log('All blocks uploaded successfully');

        resolve();
      } else {
        console.log('Upload aborted');
        reject('Aborted'); //new Error('Upload aborted by the user')
      }
    } catch (error) {
      console.error('Failed to upload file:', error);
      reject(error);
    }
  });
};


async function uploadBlock(blockBlobClient, blockIdBase64, block, blockLength, abortSignal, retries = 3) {
  try {

    blockBlobClient.stageBlock()
    const response = await blockBlobClient.stageBlock(blockIdBase64, block, blockLength, { abortSignal });

    return { blockIdBase64, blockLength };
  } catch (error) {

    if (retries > 0 && !abortSignal.aborted) {

      return uploadBlock(blockBlobClient, blockIdBase64, block, blockLength, abortSignal, retries - 1);
    } else {
      throw error;
    }
  }
}
const chunkDataSquence = (fileBlob) => {

  return new Promise((resolve, reject) => {


    let reader = new FileReader(), blobData;
    let array = [], temp = [];

    reader.readAsDataURL(fileBlob);
    let binary

    reader.onload = (e) => {

      binary = atob(e?.target?.result?.split(",")[1]);


      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      blobData = new Blob([new Uint8Array(array)]);


      resolve(blobData)

    }

  })
}

export const uploadFileToS3V2 = (payload, file, source, showLoader = true, showToast = true, abortSignal) => {
  let f_size = file.size, parts = ''
    , chunkSize = 1048576 * 50 //50MB
    , file_size = (f_size / (1024 * 1024))
    , start = 0, end = chunkSize
    , counter = 1
  if (f_size > (chunkSize * 1.9)) {
    parts = parseInt(file_size / 50)
    chunkSize = parseInt(file_size / parts) * (1024 * 1024)
    payload['size'] = parseInt(file_size)
  }
  let s3Key = "";
  let uploadId = "";
  let prefix = "";
  let tempPayload = { ...payload };

  let chunkCount = 0
  // let chunkCount = Math.floor(f_size/chunkSize) + 1
  let _totalCount = f_size % chunkSize === 0
    ? f_size / chunkSize
    : Math.floor(f_size / chunkSize) + 1
  chunkCount = _totalCount
  let uploadedBytes = 0;

  return (dispatch, getState) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData(f_size > (chunkSize * 1.9) && source !== "azure_adls" ? "GET" : "POST", `${LANDING_PAGE_API_URL}file_upload/v2/${f_size > (chunkSize * 1.9) && source !== "azure_adls" ? 'multi_part/' : 'initiate_upload/'}`, payload))
        .then(async (resp) => {
          // console.log({ resp })
          let TagList = []
          localStorage.setItem('ETagList', JSON.stringify(TagList))
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status === false) {
            if (showToast) {
              emitToastNotification('error', resp?.message);
            }
          } else {
            let preSigned_urls = "";
            if (f_size > (chunkSize * 1.9) && source !== "azure_adls") {  //Multipart
              //  preSigned_urls = resp.data.presigned_urls;
              preSigned_urls = resp.data.parts;
              s3Key = resp.data.key;

              prefix = resp.data.prefix;
              uploadId = resp.data.upload_id;
              for (let uploadCount = 1; uploadCount < chunkCount + 1; uploadCount++) {

                // console.log(start, end)
                let fileBlob;
                if (counter <= chunkCount) {
                  fileBlob = file.slice(start, end);
                  //fileBlob = file.slice(0,1048576 * 50 );

                }
                // let fileBlob = uploadCount < chunkCount ? file.slice(start, end) : file.slice(start)
                // console.log({ fileBlob })
                let blobData = await chunkDataSquence(fileBlob)

               
                let uploadResponse = await dispatch(callS3(preSigned_urls?.[uploadCount - 1].presigned_urls, resp?.data?.content_type, blobData, file.name, showLoader))

                uploadedBytes += blobData.size;
                dispatch(updateFileUploadProgress((uploadedBytes / file.size) * 100))
                counter++
                start = end;
                end = end + chunkSize
              }
            
              let eTagList = localStorage.getItem("ETagList")
              eTagList = JSON.parse(eTagList)
              let tempEtagList = eTagList.map((val, index) => { return { ETag: val, PartNumber: index + 1 } })
              let payload = { response_tag: tempEtagList, key: s3Key, upload_id: uploadId }
              dispatch(postMultiPartData(tempPayload.prefix, tempPayload.name, tempPayload.size, payload)).then((res) => {


                // console.log(res);
              })

              resolve()
            }
            else if (source === "azure_adls") {
              let data = resp.data;
              const state = getState();
              let blockIds = []
              localStorage.setItem('isCancelBtnClick', JSON
                .stringify({ aborted: false }))
                
              await callAzureMultiPart(data.response, file, file.name, data.account_name, data.container_name, data.path, data.pod, dispatch, state, blockIds, false)
              await commitBlockList(data.response, "", file.name, data.account_name, data.container_name
                , data.path, data.pod, dispatch, state, "", blockIds, showLoader
                , { fileContentType: resp?.data?.content_type } 
              )

              resolve();

            }
            else {  //Normal
              let reader = new FileReader();
              let blobData;
              reader.readAsDataURL(file);
              reader.onload = (e) => {

                let binary = atob(e?.target?.result?.split(",")[1]);
                let array = [];
                for (var i = 0; i < binary.length; i++) {
                  array.push(binary.charCodeAt(i));
                }
                blobData = new Blob([new Uint8Array(array)]);
                // console.log(blobData)
                if (source == "azure_adls") {
                  let data = resp.data;
                  const state = getState();
                  resolve(callAzure(
                    data.response,
                    file,
                    file.name,
                    data.account_name,
                    data.container_name,
                    data.path,
                    data.pod,
                    dispatch,
                    state,
                    showLoader
                  ))
                }
                else
                  resolve(dispatch(callS3(resp?.data?.response, resp?.data?.content_type, blobData, file.name, showLoader)));
              };
            }
          }
        })
        .catch((err) => {
          reject(err)
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
        });
    });
  };
};


/* export const uploadFileToS3 = (payload, file, fileName) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file_upload`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === false) {
            emitToastNotification('error', resp?.message);
          } else {

            let reader = new FileReader();
            let blobData;
            reader.readAsDataURL(file);
            reader.onload = (e) => {

              let binary = atob(e?.target?.result?.split(",")[1]);

              let array = [];

              for (var i = 0; i < binary.length; i++) {

                array.push(binary.charCodeAt(i));

              }

              blobData = new Blob([new Uint8Array(array)]);

              resolve(dispatch(callS3(resp.data, blobData, file.name)));

            };

          }
        })
        .catch((err) => {
          emitToastNotification('error', err?.message);
          dispatch(setLoadingStatus(false));
        });
    });
  };
}; */

/* export const getCallS3 = (endpoint, payload, fileName) => {

  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(callS3API("GET", endpoint, payload, fileName))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp?.status === 200 || resp?.status === 204) {
            resolve(endpoint);
          } else {
            emitToastNotification('error', resp.status);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err);
        });
    });
  };
}; */

export const callS3 = (endpoint, contentType, payload, fileName, showLoader = true) => {
  return (dispatch) => {
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    return new Promise((resolve, reject) => {
      dispatch(callS3API("PUT", endpoint, contentType, payload, fileName))
        .then((resp) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp?.status === 200 || resp?.status === 204) {
            resolve(endpoint);
          } else {
            emitToastNotification('error', resp.status);
          }
        })
        .catch((err) => {
          if (showLoader) {
            dispatch(setLoadingStatus(false));
          }
          reject(err)
          emitToastNotification('error', err);
        });
    });
  };
};

export const createFolder = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}file_upload`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status === "success") {
            resolve(resp);
            emitToastNotification('success', resp?.message);
          } else {
            reject(resp);
            emitToastNotification('error', resp?.message);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
        });
    });
  };
};

export const setCreatePodDataMap = (payload) => {
  return {
    type: SET_CREATE_POD_TABLE_DATA_MAP,
    payload,
  };
};

export const setActiveCreatePodName = (payload) => {
  return {
    type: SET_ACTIVE_CREATE_POD_NAME,
    payload,
  };
};

export const setGenerateQuery = (payload) => {
  return {
    type: SET_GENERATE_QUERY,
    payload,
  };
};

export const setQuery = (payload) => {
  return {
    type: SET_QUERY,
    payload,
  };
};

export const resetCreatePodData = () => {
  return {
    type: RESET_CREATE_POD_DATA,
  };
};

export const resetAllCreatePodData = () => {
  return (dispatch) => {
    dispatch(resetPodLeftSideData())
    dispatch(resetPodRightSideData())
    dispatch(resetVirtualTablesDataMap())
  }
}

export const resetPodLeftSideData = () => {
  return {
    type: RESET_POD_LEFT_SIDE_DATA,
  };
};

export const resetPodRightSideData = () => {
  return {
    type: RESET_POD_RIGHT_SIDE_DATA,
  };
};

const generateKeyForNewTabHandler = (podsList) => {
  let newKey;

  if (podsList.length) {
    const previousKey = +podsList[podsList.length - 1].key;
    newKey = (previousKey + 1).toString();
  } else {
    newKey = "1";
  }

  return newKey;
};

export const addNewCreatePodTab = () => (dispatch, getState) => {
  const {
    podUserInputDataMap,
    initialTreeData,
    initialExploreTabData,
    podTabsList,
    virtualTablesDataMap: dataMap,
  } = getState().CreatePODReducer;
  const newPodUserInputDataMap = cloneDeep(podUserInputDataMap);
  const virtualTablesDataMap = cloneDeep(dataMap);
  const newPodTabsList = cloneDeep(podTabsList);

  const newKey = generateKeyForNewTabHandler(podTabsList);
  const newPodTab = {
    key: newKey,
    tabTitle: `Sample Pod Name ${newKey}`,
    closable: true,
    tabContent: null,
  };

  const newTab = {
    key: newKey,
    podLeftSide: {
      podTab: {
        activeTabKey: "1",
        podDetails: {
          podName: "untitled",
          podDescription: "",
          dataDomain: "",
          dataDomainId: "",
        },
        treeData: initialTreeData,
        treeDataJSX: (props) => {
          return <Tree {...props} />;
        },
        isGenerateQueryBtnVisible: false,
        fileExplorer: {
          format: "CSV",
          delimeter: ",",
        },
      },
      connectionTab: {},
    },
    podRightSide: {
      activeTabKey: "1",
      activePODKey: "1",
      virtualTableTabs: [
        {
          key: "1",
          tabTitle: "Results",
          closable: false,
          tabContent: null,
          schemaName: "results",
        },
      ],
      virtualTableData: {},
      query: "",
      generateQuery: false,
      isSchemaTableVisible: false,
      schemaTableData: [],


      activeExploreLocation: "root",
      exploreNavigationTabs: [{ name: "root", key: "root" }],
      exploreTabData: {
        root: initialExploreTabData,
      },
      exploreTabSelectedFolderKey: "",
      exploreTabSelectedFiles: [],
      explorerTableTabs: [
        {
          key: "1",
          tabTitle: "Preview",
          closable: false,
          tabContent: null,
        },
      ],
    },
  };

  const schemaNames = initialTreeData.map((schemaData) => schemaData.key);
  virtualTablesDataMap[newKey] = {
    results: {},
    activeCreatePodName: "Results",
  };

  schemaNames.forEach((schemaName) => {
    virtualTablesDataMap[newKey][schemaName] = {};
  });

  newPodTabsList.push(newPodTab);
  newPodUserInputDataMap.push(newTab);

  dispatch(setPodTabsList(newPodTabsList));
  dispatch(setActivePodDataKey(newKey));
  dispatch(setCreatePodUserInputDataMap(newPodUserInputDataMap));
  dispatch(setVirtualTablesDataMap(virtualTablesDataMap));
};

export const setCreatePodUserInputDataMap = (payload) => {
  return {
    type: SET_CREATE_POD_USER_INPUT_DATA_MAP,
    payload,
  };
};

export const setPodTabsList = (payload) => {
  return {
    type: SET_POD_TABS_LIST,
    payload,
  };
};

export const setActivePodDataKey = (key) => {
  return {
    type: SET_ACTIVE_POD_DATA_KEY,
    payload: key,
  };
};

export const getActivePodData = () => (dispatch, getState) => {
  const {
    podUserInputDataMap,
    activePodTabDataKey,
    virtualTablesDataMap: dataMap,
  } = getState().CreatePODReducer;
  const virtualTablesDataMap = cloneDeep(dataMap);

  const activePodData = podUserInputDataMap.find(
    (pod) => pod.key == activePodTabDataKey
  );
  return [activePodTabDataKey, activePodData, virtualTablesDataMap];
};

export const setActivePodPODDetails =
  (activeTabKey, newData) => (dispatch, getState) => {
    const { podUserInputDataMap, virtualTablesDataMap, isPodUpdateView, podUpdateTreeData } = getState().CreatePODReducer;
    let newPodUserInputDataMap = cloneDeep(podUserInputDataMap);

    for (let tabData of newPodUserInputDataMap) {
      if (tabData.key === activeTabKey) {
        tabData = newData;
        break;
      }
    }




    if (isPodUpdateView && virtualTablesDataMap[1]['activeCreatePodName'] === 'Results') {

      newPodUserInputDataMap[0].podRightSide.query = virtualTablesDataMap[1]['results']?.['results']?.['query']
      newPodUserInputDataMap[0].podRightSide.queryCreatingResults = virtualTablesDataMap[1]['results']?.['results']?.['query']
    }


    dispatch(setCreatePodUserInputDataMap(newPodUserInputDataMap));
  };

export const setPodUpdateTreeData = (payload) => {
  return {
    type: SET_POD_UPDATE_TREE_DATA,
    payload,
  };
};

export const setIsPodUpdateView = (payload) => {
  return {
    type: SET_IS_POD_UPDATE_VIEW,
    payload,
  };
};

export const setPodUpdateLeftSideData = (payload) => {
  return {
    type: SET_POD_UPDATE_LEFT_SIDE_DATA,
    payload,
  };
};

export const setPodUpdateRightSideData = (payload) => {
  return {
    type: SET_POD_UPDATE_RIGHT_SIDE_DATA,
    payload,
  };
};

export const setVirtualTablesDataMap = (payload) => {
  return {
    type: SET_VIRTUAL_TABLES_DATA_MAP,
    payload,
  };
};

export const resetVirtualTablesDataMap = () => {
  return {
    type: RESET_VIRTUAL_TABLES_DATA_MAP
  };
};

export const setFileNextToken = (payload) => {
  return {
    type: SET_FILE_NEXT_TOKEN,
    payload,
  };
};

export const setFileProgress = (payload) => {
  return {
    type: SET_FILE_PROGRESS,
    payload
  }
}
export const setInitialTreeData = (payload) => {
  return {
    type: SET_INITIAL_TREE_DATA,
    payload,
  };
};

export const setInitialExploreTabData = (payload) => {
  return {
    type: SET_INITIAL_EXPLORE_TAB_DATA,
    payload,
  };
};

export const setPreviewTableDataCreatePODActions = (payload) => {
  return {
    type: SET_PREVIEW_TABLE_DATA,
    payload,
  };
};

export const setTreeDataSet = (payload) => {
  return {
    type: SET_TREE_DATA_SET,
    payload,
  };
};

export const setInitialSchemaData = (payload) => {
  return {
    type: SET_INITIAL_SCHEMA_DATA,
    payload,
  };
};

export const setPodLeftSideData = (payload) => {
  return {
    type: SET_POD_LEFT_SIDE_DATA,
    payload,
  };
};

export const setPodRightSideData = (payload) => {
  return {
    type: SET_POD_RIGHT_SIDE_DATA,
    payload,
  };
};

export const getCreatePodPreviewTable = (payload, enableShowLoader = true, showToast = true, autoClassify = false) => {
  return (dispatch) => {
    if (enableShowLoader) {
      dispatch(setLoadingStatus(true))
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${LANDING_PAGE_API_URL}preview/v2/${autoClassify ? '?classify=true' : '?regex=true'}`, payload))
        .then((resp) => {
          if (enableShowLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status != "success") {
            if (showToast) {
              emitToastNotification('error', resp.message);
            }
            reject(resp.message);
          } else {
            if (enableShowLoader) {
              dispatch(setLoadingStatus(false))
            }
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));

          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};