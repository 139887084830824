import { Dropdown, Input, Popover, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from "reactstrap";

import {
  duplicate
} from "../../../../../store/modules/dataCatalogue/dictionary/dictionaryAction";
import { addRemoveBookmarkToPOD, assignDomainTag, assignTagToPOD, getLandingPageData, openModel, removePOD, setSelectedRulePODList, unassignDomainTag } from '../../../../../store/modules/dataCatalogue/landingPage/landingPageActions.js';
import ActionPopUp from "../../../common/actionPopUp";
import { Literals } from "../../../common/literals";
import { tags } from '../landingPageTagHelper';

import "toastr/build/toastr.min.css";
import { setLoadingStatus } from "../../../../../store/modules/common/loadingActions";

import Buttons from "../../../../Common/button/Buttons";
import { CHATGPT_ICON, CLOSE, COPY, DELETEICON, FILLEDSTAR, MINUS, PLUS, SAVE, STAR, TAG, UPDATED_BY } from "../../../../Common/iconSource";
import { CreateRuleContext } from "../../../common/Rules-context";

import Highlighter from "react-highlight-words";
import { isTagListStatus, unassignTagToPOD } from "../../../../../store/actions";
import { getObjectLength, getRelativeTime, permissions, podDeleteNote, podNameRegex } from "../../../common/helperFunctions";
import SourceTypeImage from "../../../common/sourceTypeImage";
import ConnectionContext from "../../../connections/landingPage/components/ConnectionContext";
import DeleteModal from "../../../../Common/deleteModal/DeleteModal";
import { ICON_CLARISTA, ICON_LOADING_CIRCLE, ICON_MINUS, ICON_PLUS, ICON_REDIRECT, ICON_SAVE, ICON_SEARCH, ICON_USER } from "../../../../Common/newIconSource";
import ClaristaLoader from "../../../../Common/claristaLoader/ClaristaLoader";
import CheckableTag from "antd/es/tag/CheckableTag";
import { publishPod } from "../../../../../store/modules/common/GenAi/GenAiReducer";
import { setSelectedPodList } from "../../../../../store/modules/common/podSelectionModalAction.js";
import _, { isArray } from "lodash";

const catalogPermissions = permissions.dataCatalog

const LandingPagePODGrid = ({
  podDetails,
  setRefresh = () => { },
  refreshData = () => { },
  fromRoute = '',
  index = '',
  onRowSelection = () => { },
  setData = () => { },
  groupByTags = false
}) => {
  const history = useNavigate();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [renameText, setRenameText] = useState(podDetails.table_name + "_copy");
  const [visible, setVisible] = useState(false);

  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
  const dispatch = useDispatch()
  const tagList = useSelector(state => state.DataCatalogue?.LandingPage?.tagList);
  const context = useContext(CreateRuleContext)
  const connectionContext = useContext(ConnectionContext)
  const [selectedPodId, setselectedPodId] = useState([])
  let selectedPodList = useSelector(state => state.CommonComponent.PodSelectionModalReducer?.podList)

  const [tagMenu, setTagMenu] = useState([]);
  const [showPopUp,] = useState(false)
  const [showTagPop, setShowTagPop] = useState(false)
  const [optionOpen, setOptionOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [copying, setCopying] = useState(false)
  const [bookmark, setBookmark] = useState(false)
  const [bookmarkLoading, setBookmarkLoading] = useState(false)
  const [tagLoader, setTagLoader] = useState(false)

  const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

  const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false

  const checkIsEditor = () => {
    const isRoleEditor = !!podDetails?.permission?.find(v => v === catalogPermissions.editor)
    if (isRoleEditor) return true
    return undefined
  }

  useEffect(() => {
    
    if (isArray(selectedPodList) && selectedPodList?.length) {
      let ids = selectedPodList.map((item) => item.table_id)
      setselectedPodId([...selectedPodId,...ids])
    }else if(!isArray(selectedPodList) && getObjectLength(selectedPodList)){
      setselectedPodId([selectedPodList?.table_id])
    }
  }, [selectedPodList])


  const isDeletePermission = checkIsEditor() ?? !!podDetails?.permission?.find(v => v === catalogPermissions.delete)
  const isEditPermission = checkIsEditor() ?? !!podDetails?.permission?.find(v => v === catalogPermissions.edit)

  React.useEffect(() => {
    if (podDetails) {
      setBookmark(podDetails?.bookmark)
    }
  }, [podDetails])

  const addRemoveBookmark = (e) => {
    e.stopPropagation();
    e.preventDefault();

    try {
      setBookmarkLoading(true)
      dispatch(addRemoveBookmarkToPOD(podDetails?.table_id, podDetails?.bookmark)).then(
        res => {
          if (res.status === 'success') {
            setBookmark(!bookmark)
            setBookmarkLoading(false)
            setData(prev => prev?.map(v => ({ ...v, bookmark: v?.table_id === podDetails?.table_id ? !bookmark : v?.bookmark })))
            // setRefresh(true)
            // refreshData()
          }
        }
      ).catch(err => {
        setBookmarkLoading(false)
      })
    }
    catch (e) {
      setBookmarkLoading(false)
    }
  }

  const tagColor = (value) => {
    var res = value.split("#");
    let colorname = res[0];
    let color = '';
    for (let x in tags) {
      if (tags[x].id === colorname) {
        color = tags[x].color;
        break;
      }
    }
    return color+'95';
  }



  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };

  const deletePOD = () => {
    try {
      setLoading(true)
      dispatch(removePOD(podDetails?.table_id)).then(
        res => {
          if (res.status === 'success') {
            try {
              setOpenDeletePopup(false);
              refreshData()
              setLoading(false)
              // dispatch(getLandingPageData()).then((resp) => {
              //   dispatch(setLoadingStatus(false))
              // })
            }
            catch (e) {
              setLoading(false)
            }

          }
        }
      ).catch(err =>
        setLoading(false)
      )
    }
    catch (e) {

    }
  };
  const openDeleteDialog = () => {
    if (!isDeletePermission) return
    setDeletePopUpContent({
      modalClass: "delete-popup",
      heading: "Delete",
      message: podDeleteNote(podDetails?.table_id, podDetails?.source_type),
      button: [
        {
          name: "Delete",
          action: (e) => deletePOD(e),
        }
      ],
      elements: [],
      closeActionPopup: closeDeletePopUp,
    });
    setOpenDeletePopup(true);
  };



  const addRemoveTagToPOD = (tag_id, remove = false) => {
    console.log({tag_id})
    try {
      setTagLoader(true)
      let requestBody = {
        // "table": podDetails?.table_id,
        "tag": [tag_id]
      }
      if (!remove) {
        dispatch(assignDomainTag(requestBody, podDetails?.table_id)).then(
          res => {
            if (res.status === 'success') {
              try {
                setTagLoader(false)
                refreshData()
                // dispatch(getLandingPageData()).then(() => {})
              }
              catch (e) { 
                setTagLoader(false)
              }
            }
          }
        )
        .catch(() => {
          setTagLoader(false)
        })
      }
      else {
        dispatch(unassignDomainTag(tag_id, podDetails?.table_id)).then(
          res => {
            if (res.status === 'success') {
              setTagLoader(false)
              refreshData()
              // dispatch(getLandingPageData())
            }
          }
        )
        .catch(() => {
          setTagLoader(false)
        })
      }
    }
    catch (e) {

    }
  }

  const handleSaveClick = () => {
    try {
      setCopying(true)
      dispatch(duplicate({ table_name: renameText }, "pod_crud", podDetails.table_id)).then((res) => {
        if (res.status === 'success') {
          setVisible(false)
          refreshData()
          setCopying(false)
          // dispatch(getLandingPageData())
        }
      })
        .catch(() => {
          setCopying(false)
        })
    }
    catch (err) {
      setCopying(false)
    }
  }



  const redirectTo = (id) => {

    if (fromRoute !== 'podselection') {
      if (context.showAccessManagement) {
        history(Literals.links.ACCESS_MANAGEMENT_POD_DETAILS + id)
      } else if (!context.showCreateRule) {
        history(Literals.links.DATA_CATALOG_POD_DETAILS + id)
      }
    } else if (fromRoute === 'podselection') {


      onRowSelection(podDetails);
    }

  }

  useEffect(() => {
    if (showPopUp) {
      setTagSubMenu([...podDetails?.tag])
    }
  }, [showPopUp, podDetails, tagList])

  useEffect(() => {
    if (!optionOpen) {
      setShowTagPop(false)
      setVisible(false)
    }
  }, [optionOpen])

  useEffect(() => {
    if (visible) {
      setShowTagPop(false)
    }
  }, [visible])

  const closeTagPopUp = () => {
    dispatch(isTagListStatus(false))
    document.removeEventListener('click', closeTagPopUp)
  }


  const setTagSubMenu = (tags) => {
    let podTag = tags.map(t => t?.name)
    let returnList = [];
    let availableTags = _.sortBy([...tagList], [function(o) { return o?.name; }])

    availableTags?.length && availableTags.forEach((tag, x) => {
      if (!podTag.includes(tag?.name)) {
        returnList.push(
          <div onClick={(e) => { e.stopPropagation() }} key={x} className={`${x === availableTags.length - 1 ? '' : 'custom-border-bottom'} d-flex justify-content-between align-items-center padding-top-1 padding-bottom-1`}>
            <div className="d-flex align-items-center">
              <i className="bx bxs-circle" style={{ color: tagColor(tag?.color) }}></i>
              <Tooltip title={tag?.name?.toUpperCase()} placement="topLeft">
                <p className="paragraph m-0 ml-2">{tag?.name?.toUpperCase()}</p>
              </Tooltip>
            </div>
            <Buttons
              props={{
                buttonText: '',
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2 ml-2",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false);
                  addRemoveTagToPOD(tag?.id)
                },
                ImgSrc: () => tagLoader ? <ICON_LOADING_CIRCLE/> : <ICON_PLUS />,
                isDisable: tagLoader,
                buttonType: Literals.BTN_TERTIARY
              }}
            />
          </div>
        )
      } else {
        returnList.push(
          <div onClick={(e) => { e.stopPropagation() }} key={x} className='d-flex justify-content-between custom-border-bottom align-items-center padding-top-1 padding-bottom-1'>
            <div className="d-flex align-items-center">
              <i className="bx bxs-circle" style={{ color: tagColor(tag?.color) }}></i>
              <Tooltip title={tag?.name?.toUpperCase()} placement="topLeft">
                <p className="paragraph m-0 ml-2">{tag?.name?.toUpperCase()}</p>
              </Tooltip>
            </div>
            <Buttons
              props={{
                buttonText: '',
                buttonClassName: "danger-btn custom-btn btn-with-icon mr-2 ml-2 border-0",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false);
                  addRemoveTagToPOD(tag?.id, true)
                },
                ImgSrc: () => tagLoader ? <ICON_LOADING_CIRCLE/> : <ICON_MINUS />,
                isDisable: tagLoader,
                buttonType: Literals.BTN_TERTIARY
              }}
            />
          </div>
        )
      }
    })
    if (returnList.length) {

      setTagMenu([...returnList])
    } else {
      setTagMenu([])

    }
  }





  const handleVisibleChange = (visible) => {
    setShowTagPop(visible)
    if (visible) {
      setTagSubMenu(podDetails?.tag)
      dispatch(isTagListStatus(true))
      localStorage.setItem('table', podDetails?.table_id + ',');
    }
  }

  const tagPopover = {
    header: () => {
      return <>
        <div className="d-flex justify-content-between custom-border-bottom padding-2">
          <p className="section-heading m-0">Tags</p>
          <div className="d-flex">
            <Tooltip title='Close' placement="bottom">
              <button className="custom-btn-outline custom-btn btn-with-icon"
                onClick={function (e) {
                  e.stopPropagation()
                  setShowTagPop(false)
                  dispatch(isTagListStatus(false))
                }}
              >
                <CLOSE />
              </button>
            </Tooltip>
            {/*  <Buttons
              props={{
                buttonText: 'TAG',
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                tooltip: 'Add Tag',
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false)
                  openAddTag()
                },
                ImgSrc: () => <PLUS />,
                isDisable: false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
              }}
            /> */}
          </div>
        </div>
      </>
    },
    body: () => {
      return <>
        <div className="padding-2 card-tags-auto-overflow tag-popover-body" style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {
            tagMenu.length
              ? tagMenu.map((val, i) => <span key={`tag-pop-itm-${i}`}>{val}</span>)
              : <div className="py-1 text-center text-black-50">No Tag Found</div>
          }
        </div>
      </>
    }
  }


  const publish = async (podDetails) => {
    // toast.promise(
    //   new Promise(async (resolve, reject) => {
    try {
      let res = await dispatch(publishPod(!podDetails?.published ? 'GET' : 'DELETE', podDetails?.table_id));

      refreshData()

    }
    catch {

    }

  }

  var trimmedDescription = podDetails?.pod_description?.length > 86 ?
    podDetails?.pod_description?.substring(0, 86 - 3) + "..." :
    podDetails?.pod_description ?? "";

  const optionItems = () => {
    let optionList = [
      {
        label: <span className={`dbox-opt-itm`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            window.open(Literals.links.DATA_CATALOG_POD_DETAILS + podDetails?.table_id)
          }}>
          <ICON_REDIRECT />
          Open In New Tab
        </span>,
        key: '0',
        disabled: false
      },
      {
        label: <Popover placement="right"
          title={tagPopover.header}
          content={tagPopover.body}
          trigger="hover"
          autoAdjustOverflow={true}
          open={showTagPop}
          onOpenChange={handleVisibleChange}
          destroyTooltipOnHide={true}
        >
          <span className="dbox-opt-itm"
            onClick={(e) => {
              e.stopPropagation();
              setShowTagPop(true)
              setTagSubMenu(podDetails?.tag)
              dispatch(isTagListStatus(true))
              localStorage.setItem('table', podDetails?.table_id + ',');
            }}>
            <TAG />
            Tags
          </span>
        </Popover>,
        key: '1',
      },
      {
        label: <Popover
          content={
            <form onClick={(e) => {
              e.stopPropagation();
            }}
              style={{ display: "flex", padding: "10px", alignItems: "center" }}>


              <Tooltip title={renameText}>
                <Input onKeyDown={(e) => { e.stopPropagation() }} value={renameText} onChange={(e) => {
                  if (podNameRegex.test(e.target.value) || e.target.value === "") { setRenameText(e.target.value?.toLocaleLowerCase()) }
                }
                } />
              </Tooltip>
              <Buttons
                props={{
                  actionType: "submit",
                  buttonText: "",
                  buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1",
                  buttonEvent: (e) => {
                    e.preventDefault();
                    handleSaveClick();
                  },
                  ImgSrc: () => copying ? <ICON_LOADING_CIRCLE /> : <SAVE />,
                  isDisable: copying,
                  buttonType: Literals.BTN_SECONDARY,
                }}
              />
            </form>
          }
          title={
            <div onClick={(e) => {
              e.stopPropagation();
            }}
              style={{ display: "flex", padding: "10px", justifyContent: "space-between" }}><span>Create a Copy</span> <Buttons
                props={{
                  buttonText: "",
                  buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
                  buttonEvent: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setVisible(false);
                  },
                  ImgSrc: () => <CLOSE />,
                  isDisable: false,
                  buttonType: Literals.BTN_TERTIARY,
                }}
              /></div>
          }
          onOpenChange={(visible) => setVisible(visible)}
          trigger="click"
          open={isEditPermission && visible}
          destroyTooltipOnHide={true}
          placement="right"
        >
          <span className={`dbox-opt-itm ${!isEditPermission ? 'disabled' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!isEditPermission) return
              setVisible(true)
            }}>
            <COPY />
            Duplicate
          </span>
        </Popover>,
        key: '3',
        disabled: podDetails?.source_type === 'upload' || !isEditPermission
      },
      {
        label: <span className={`dbox-opt-itm danger ${!isDeletePermission ? 'disabled' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            openDeleteDialog(podDetails)
          }}>
          <DELETEICON />
          Delete
        </span>,
        key: '4',
        disabled: !isDeletePermission
      },
      // {
      //   label: <span className={`dbox-opt-itm ${!isDeletePermission ? 'disabled' : ''}`}
      //     onClick={(e) => {
      //       e.stopPropagation();
      //       e.preventDefault();
      //       publish(podDetails)
      //     }}>
      //     <ICON_SAVE  />
      //     {!podDetails?.published ? 'Published' : 'Draft'}
      //   </span>,
      //   key: '4',
      //   disabled: !isDeletePermission
      // },
    ]

    if (podDetails?.source_type === 'upload') {
      return optionList.filter(v => v.key !== '3')
    }
    return optionList
  }


  return (
    <div key={`${podDetails?.table_id}-${podDetails?.table_name}`} className="h-100 position-relative">
      <Card style={{ margin: fromRoute === 'podselection' ? '5px' : '', outline: fromRoute === 'podselection' &&  selectedPodId?.includes(podDetails?.table_id) ? '2px solid #2c83c4' : '' }} id={`podCrd${index}`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); redirectTo(podDetails?.table_id) }}>
        <CardBody>
          <div className='data-view-dbox' onClick={() => { }}>
            <div className='_src_img'>
              <SourceTypeImage dataName={podDetails.source_type} />
            </div>
            <div className='desc-box'>
              <div className="d-flex align-items-start">
                <Tooltip placement='topLeft' title={podDetails?.table_name?.toUpperCase()}>
                  <label style={{ width: 'calc(100% - 54px)' }} className='paragraph mt-1 title-txt font-w-600 text-uppercase text-with-ellipsis'>
                    <Highlighter searchWords={connectionContext.contextSearchValue && connectionContext.contextSearchValue.length ? [connectionContext.contextSearchValue?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={podDetails?.table_name.toUpperCase()} />
                    {/* {`${podDetails.table_name}`} */}
                  </label>
                </Tooltip>
                {fromRoute !== 'podselection' ? <>
                  <Buttons
                    props={{
                      buttonText: '',
                      tooltip: bookmark
                        ? Literals.REMOVE_FAVOURITES
                        : Literals.ADD_FAVOURITES,
                      buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                      buttonEvent: (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        addRemoveBookmark(e)
                      },
                      ImgSrc: () => bookmarkLoading ? <ICON_LOADING_CIRCLE /> : bookmark ? <FILLEDSTAR /> : <STAR />,
                      isDisable: bookmarkLoading,
                      buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }}
                  />
                  {/* {
                    noOpenAiAccess ? ''
                    :
                    <Buttons
                      props={{
                        buttonText: '',
                        tooltip: 'Ask Clarista',
                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1",
                        buttonEvent: (e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          history(Literals.links.DATA_CATALOG_POD_DETAILS + podDetails?.table_id + '#preview-search')
                        },
                        ImgSrc: () => <ICON_CLARISTA />,
                        isDisable: false,
                        buttonType: Literals.BTN_TERTIARY
                      }}
                    />
                  } */}


                  <Dropdown
                    menu={{
                      items: optionItems(),
                      onClick: (e) => e.domEvent.stopPropagation()
                    }}
                    trigger={["hover"]}
                    placement="bottomRight"
                    onOpenChange={(bool) => setOptionOpen(bool)}
                    overlayStyle={{ border: 'solid 1px #e4e4e4' }}
                    destroyPopupOnHide={true}
                  >
                    <span onClick={e => e.stopPropagation()} className={`dbox-opts ${optionOpen ? 'active' : ''}`}><i className="bx bx-dots-vertical-rounded"></i></span>
                  </Dropdown>
                </> : null}
              </div>
              <div>
                <Tooltip placement='topLeft' title={podDetails.pod_description}>
                  <p className='paragraph text-black-50 small desc-txt custom-ellipsis two-line-ellipsis'>
                    {podDetails.pod_description?.length > 0 ?
                      <Highlighter searchWords={connectionContext.contextSearchValue && connectionContext.contextSearchValue.length ? [connectionContext.contextSearchValue?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={trimmedDescription ?? ''} />
                      // podDetails.pod_description
                      : "No description has been given yet."}
                  </p>
                </Tooltip>
              </div>
              <ul className="landingCard-profile-list">
                <li className="landingCard-profile-listitem">
                  <UPDATED_BY width="14" height="14" />
                  <span className="label label-gray ml-1">{'Last Updated'} </span>
                  <Tooltip placement="topLeft" title={getRelativeTime(podDetails?.updated_on)}>
                    <span className="paragraph">
                      {/* <Highlighter searchWords={connectionContext.contextSearchValue && connectionContext.contextSearchValue.length ? [connectionContext.contextSearchValue?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={podDetails?.updated_by_user?.email} /> */}
                      {getRelativeTime(podDetails?.updated_on)}
                      {/* {podDetails?.updated_by_user?.email} */}
                    </span>
                  </Tooltip>
                </li>
                <li className="landingCard-profile-listitem">
                  <ICON_USER width="14" height="14" />
                  <span className="label label-gray ml-1">{Literals.UPDATED_BY} </span>
                  <Tooltip placement="topLeft" title={podDetails?.updated_by_user?.email}>
                    <span className="paragraph">
                      <Highlighter searchWords={connectionContext.contextSearchValue && connectionContext.contextSearchValue.length ? [connectionContext.contextSearchValue?.toLocaleLowerCase()] : []} autoEscape={true} textToHighlight={podDetails?.updated_by_user?.email} />

                      {/* {podDetails?.updated_by_user?.email} */}
                    </span>
                  </Tooltip>
                </li>
              </ul>
              <div className="round-tags-container position-relative"
                style={{
                  width: 'calc(100% + 48px)',
                  left: -43
                }}
              >
                {/* {
                  !groupByTags &&
                  podDetails?.tag?.map((v, i) => {

                    return <React.Fragment key={`${podDetails.table_id}-${podDetails.table_name}-tbl-tgs-${i}`}>
                      {
                        i < 2
                          ?
                          <div className={`${v?.color} round-tags-item`}>
                            <span>{<TAG />}</span>
                            <Tooltip placement="top" title={v?.name?.toUpperCase()}>
                              <span className="label text-uppercase">{v?.name}</span>
                            </Tooltip>
                          </div>
                          : ''
                      }
                      {
                        i === 2
                          ?
                          <div className={`${v?.color} round-tags-item`}>
                            <span>{<TAG />}</span>
                            <Tooltip placement="top" title={podDetails?.tag?.map(t => t?.name?.toUpperCase()).slice(2).join(',')}>
                              <span className="label">+{podDetails?.tag?.length - 2}</span>
                            </Tooltip>
                          </div>
                          : ''
                      }
                    </React.Fragment>
                  })
                } */}
                {
                  
                    <span className={`position-absolute w-100`} style={{ top: -2, display: 'grid', gridTemplateColumns: podDetails?.is_unstructured ? '100px auto 100px' : 'auto 100px' }}>
                      {/* right: 0,  */}
                      {
                        podDetails?.is_unstructured
                        ?
                        <label
                          className={'publish-tag-pod unstructured-tag fontInterSemiBold w-fit-content'}
                          style={{
                            color: '#222222',
                            borderColor: "#BFE4FF",
                            // visibility: podDetails?.is_unstructured ? 'visible' : 'hidden'
                          }}
                        >
                          {'Unstructured'}
                        </label>
                        : ''
                      }
                    <div className="d-flex align-items-center">
                      {
                        !groupByTags &&
                        podDetails?.tag?.map((v, i) => {
                          let limit = 1
                          if(!podDetails?.is_unstructured) limit = 2
                          return <React.Fragment key={`${podDetails.table_id}-${podDetails.table_name}-tbl-tgs-${i}`}>
                            {
                              i < limit
                                ?
                                <div className={`${v?.color} round-tags-item mb-0`}>
                                  <span>{<TAG />}</span>
                                  <Tooltip placement="top" title={v?.name?.toUpperCase()}>
                                    <span className="label text-uppercase">{v?.name}</span>
                                  </Tooltip>
                                </div>
                                : ''
                            }
                            {
                              i === limit
                                ?
                                <div className={`${v?.color} round-tags-item mb-0`}>
                                  {/* <span>{<TAG />}</span> */}
                                  <Tooltip placement="top" title={podDetails?.tag?.map(t => t?.name?.toUpperCase()).slice(limit).join(',')}>
                                    <span className="fontSizeExtraSmall fontInterSemiBold">+{podDetails?.tag?.length - limit}</span>
                                  </Tooltip>
                                </div>
                                : ''
                            }
                          </React.Fragment>
                        })
                      }
                    </div>
                      {
                        podDetails?.published
                        ?
                          <label
                            className={'publish-tag-pod ml-auto w-fit-content'}
                            style={{
                              borderColor: '#2c83c4',
                            }}
                          >
                            {'Published'}
                          </label>
                        : ''
                      }
                    </span>
                }
              </div>
            </div>
          </div>
        </CardBody>
      </Card>


      <DeleteModal
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        body={podDeleteNote(podDetails?.table_id, podDetails?.source_type)}
        onConfirm={deletePOD}
        loading={loading}
      />
    </div>
  );
};
export default LandingPagePODGrid;