import { Checkbox, Tree } from 'antd';
import React from 'react'
import { ICON_CLOSE, ICON_DEAD, ICON_FILE, ICON_FOLDER, ICON_HOME, ICON_REFRESH, ICON_SEARCH } from '../../../../Common/newIconSource';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { fetchFileSystem } from '../../../../../store/actions';
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader';
import { Literals } from '../../../common/literals';
import Buttons from '../../../../Common/button/Buttons';
import SearchInput from '../../../../Common/search/Search';
import { getConfirmationModal, getObjectLength, getReloadErrorTemplate } from '../../../common/helperFunctions';
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux';
import _ from 'lodash';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import Highlighter from 'react-highlight-words';

const ExplorerDirectoryTree = ({
    previewLoading = false,
    podData = {},
    isEditMode = false,
    isEditing = false,
    podRetrievedConnection,
    previewHandlerRef,
    podCreationTutorialComponentIds = {},
    setWalkThrough = () => {},
    isTutorialActive = false,
    isUnstructured = false,
    checkedElementKeys = [],
    setCheckedElementKeys = () => {},
    handleUnstructuredPreview = () => {},
    setTopicsData = () => {},
    // setCheckedFiles = () => {},
    // checkedFiles = [],
    // checkedFolders = [],
    // setCheckedFolders = () => {}
}) => {

    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)

    const [error, setError] = React.useState(undefined)
    const [empty, setEmpty] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [treeData, setTreeData] = React.useState([...reduxData?.explorerTreeData])
    const [selectedKeys, setSelectedKeys] = React.useState([...reduxData?.selectedFolder]);
    const [expandedKeys, setExpandedKeys] = React.useState([...reduxData?.explorerExpandedKeys]);
    const [openSearch, setOpenSearch] = React.useState(false);
    const [searchData, setSearchData] = React.useState('');
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [treeDisabled, setTreeDisabled] = React.useState(false);
    const [overlayLoading, setOverlayLoading] = React.useState(false);
    const [treeHeight, setTreeHeight] = React.useState(500); // For Virtual Scroll
    const [isTreeHeightCalculated, setTreeHeightCalculated] = React.useState(false); // For Virtual Scroll
    const [selectedFiles, setSelectedFiles] = React.useState([...checkedElementKeys]); // For Unstructured
    // const [selectedFiles, setSelectedFiles] = React.useState([...checkedFiles]); // For Unstructured
    const [checkedKeys, setCheckedKeys] = React.useState(
        (() => {
            if(reduxData?.selectedFolder) {
                return [...reduxData?.selectedFolder]
            }
            return []
        })()
    )

    const currentSelectedInfo = React.useRef({});

    const dispatch = useDispatch()
    const store = useStore()

    const selectedConn = useSelector((state) => {
        return state.UserManagement.ConnectionReducer.selectedConn
    })

    const connName = selectedConn?.connection_name??''

    React.useEffect(() => {
        fetchAllData()
    }, [])

    React.useEffect(() => {
        if(treeData?.length > 0 && !isTreeHeightCalculated) {
            const el = document.querySelector('.exp-dir-wrap .ant-tree.ant-tree-directory')
            if(el?.scrollHeight) {
                setTreeHeight(el?.scrollHeight)
                setTreeHeightCalculated(true)
            }
        }
    }, [treeData, isTreeHeightCalculated])

    React.useEffect(() => {
        if(isEditMode) {
            setTreeDisabled(!isEditing || previewLoading)
        }
        else {
            setTreeDisabled(previewLoading)
        }
    }, [previewLoading, isEditing])

    const treeDataMemo = React.useMemo(() => {
        const loop = (data) =>
          data.map((item) => {
            const strTitle = item.title;
            const strTitleLower = item?.title?.toLowerCase();
            const index = strTitleLower.indexOf(searchData?.toLowerCase());
            const beforeStr = strTitle.substring(0, index);
            const afterStr = strTitle.slice(index + searchData.length);
            const title =
              index > -1 ? (
                <span title={strTitle}>
                {
                    searchData === ''
                    ?   <>
                        {beforeStr}
                        <span className="site-tree-search-value">{searchData}</span>
                        {afterStr}
                    </>
                    :
                    <Highlighter
                        searchWords={[searchData?.toLocaleLowerCase()]}
                        autoEscape={true}
                        textToHighlight={item?.name}
                    >
                        <span className="">{item?.name}</span>
                    </Highlighter>
                }
                  {/* {beforeStr}
                  <span className="site-tree-search-value">{searchData}</span>
                  {afterStr} */}
                </span>
              ) : (
                <span title={strTitle}>{strTitle}</span>
              );
            // if (item.children) {
            //   return {
            //       ...item,
            //     children: loop(item.children),
            //     name: strTitle,
            //     title
            //   };
            // }
            return {
              ...item,
              name: strTitle,
              title
            };
        });

        let clone = [...treeData].filter(el => {
            if(el?.children) {
                return el?.title?.toLowerCase()?.includes(searchData?.toLowerCase()) || el?.children?.some(e => e?.title?.toLowerCase()?.includes(searchData?.toLowerCase()))
            }
            return el?.title?.toLowerCase()?.includes(searchData?.toLowerCase())
        })

        const fileTemplate = (info) => {
            let arr = []
            if(info?.children) {
                arr = info?.children?.map(el => {

                    if(el?.children) return fileTemplate(el)
                    else if(el?.isFile) {

                        const showCheckbox = () => {
                            if(selectedKeys?.length === 0) return false

                            let activeFolder = selectedKeys?.[0]
                            let activeFiles = el?.parentName

                            return activeFolder === activeFiles
                        }

                        // if(!showCheckbox()) {
                        //     return {...el}
                        // }

                        return {
                            ...el,
                            // icon: <></>,
                            title: <div className='text-with-ellipsis' title={el?.title}>
                                {/* <Checkbox
                                    onChange={e => {
                                        let chk = e?.target?.checked
                                        onSelectFile({
                                            checked: chk,
                                            ...el
                                        })
                                    }}
                                    checked={selectedFiles?.find(f => f?.key === el?.key)?.checked ?? false}
                                    // disabled={!showCheckbox()}
                                    className='mr-2'
                                /> */}
                                {el?.title}
                            </div>
                        }
                    }
                    
                    return {...el}
                })

                return {...info, children: arr}
            }

            return {...info}
        }

        if(isUnstructured) {
            clone = clone?.map(t => fileTemplate(t))
        }

        return loop(clone);
    }, [searchData, treeData, selectedFiles, selectedKeys]);
    
    const fetchAllData = async() => {
        let reduxData = store?.getState()?.DataCatalogue?.PodCreationData

        if(reduxData?.explorerTreeData?.length > 0) return
        
        setLoading(true)
        setError(undefined)
        let keysArr = []
        if(isEditMode) {
            if(podData?.source_type === 'azure_adls' || podData?.source_type === 's3') {
                const {bucket, root_prefix} = podData?.source_info
                const sourceUrl = podData?.source_detail?.[0]
                const srcType = podData?.source_type
                const srcTypePrefix = srcType === 's3' ? 's3a://' : 'abfss://'
                let folderArr = sourceUrl.split(
                    `${srcTypePrefix}${bucket}/${root_prefix}`
                ).filter(v => v !== '')
                let folderLocation = folderArr[0]
                let breadcrumb = folderLocation.split('/').filter(v => v !== '')
    
                let visited = ''
                for (let index = 0; index < breadcrumb.length; index++) {
                    if(visited !== '') {
                        visited = `${visited}/${breadcrumb[index]}`
                    }
                    else {
                        visited = breadcrumb[index]
                    }
                    keysArr.push(visited)
                }
            }

        }



        const requestBody = {
            connection_name: connName,
            prefix: "",
            next_token: ""
        };
        dispatch(fetchFileSystem(requestBody, false))
        .then(res => {
            let folders = res?.data?.folder
            folders = folders.map(f => {
                return {
                    title: f?.name,
                    name: f?.name,
                    key: `${f?.name}`,
                    icon: <ICON_FOLDER height='14' width='14' />,
                    isFolder: true,
                    location: f?.path
                }
            })

            /**
             * Selected Folder Will Stick to Top of the List In Edit Mode
             */
            // if(isEditMode) {
            //     folders = _.sortBy(folders,[(o) => o.key !== keysArr[0]])
            // }

            setEmpty(folders?.length === 0)
            setTreeData([...folders])

            reduxData.explorerTreeData = [...folders]
            setReduxData(reduxData)

            if(!isEditMode && isTutorialActive) {
                setTimeout(() => {
                    setWalkThrough(prev => {
                        prev = {...prev, run: isTutorialActive}
                        return prev
                    })
                }, 1000)
            }
            
            setLoading(false)
            if(isEditMode) {
                if(connName !== podRetrievedConnection) return
                if(isUnstructured) {
                    let paths = podData?.pod_file_info?.file_names
                    let keysToExpand = []

                    paths?.forEach(p => {
                        let str = ""
                        p?.split('/')?.forEach(v => {
                            str += "/" + v
                            // console.log({str})
                            keysToExpand?.push(str?.slice(1))
                        })
                    })
                    keysToExpand = _.uniq(keysToExpand)
                    // console.log({keysToExpand})
                    autoLoad([...keysToExpand])

                    /**
                     * Delete the below commented code after testing
                     */
                    // let keysToExpand = podData?.pod_file_info?.file_names?.filter(f => f?.split('/')?.length === 2)
                    // let rootFolders = keysArr?.filter(f => f?.split('/')?.filter(v => v !== '')?.length === 1)
                    // console.log({rootFolders})
                    /**
                     * rootFolders Contains the Root folder of the Selected Files
                     */
                    // autoLoad([...rootFolders, ...keysToExpand])
                }
                else {
                    autoLoad(keysArr)
                }
            }
        })
        .catch(err => {
            setError(getReloadErrorTemplate(
                {
                    errorMessage: err?.message,
                    onReload: fetchAllData
                }
            ))
            setLoading(false)
        })
    }

    const autoLoad = async (keys) => {
        setOverlayLoading(true)
        const sourcePath = podData?.source_detail?.[0]
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];
            await onLoadData({key})
        }

        onExpand(keys)

        if(isUnstructured) {
            let multiLevelKeys = keys?.filter(f => f?.includes('/'))
            onCheck(multiLevelKeys, {node: {location: podData?.source_detail[0], autoLoad: true}})
        }
        else {
            onSelect([_.last(keys)], {node: {location: sourcePath, autoLoad: true}})
        }

        setOverlayLoading(false)

        if(isUnstructured) {
            handleUnstructuredPreview(true)
        }
        else {
            const { bucket, root_prefix } = podData?.source_info
            const sourceUrl = podData?.source_detail?.[0]
            const srcType = podData?.source_type
            const srcTypePrefix = srcType === 's3' ? 's3a://' : 'abfss://'
            let folderArr = sourceUrl.split(
                root_prefix === ''
                ?
                `${srcTypePrefix}${bucket}/`
                :
                `${srcTypePrefix}${bucket}/${root_prefix}/`
            ).filter(v => v !== '')
    
            if(podRetrievedConnection !== connName) return
    
            previewHandlerRef?.current?.executePreview(folderArr[0])
        }

    }

    const setReduxData = (data) => {
        dispatch(setPodCreationData(data))
    }

    const onSelect = (keys, info, fromModal = false) => {

        if(treeDisabled) return
        let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
        if(!info?.node?.autoLoad && !fromModal && getObjectLength(reduxData?.tableData?.result) > 0) {
            if(reduxData?.selectedFolder?.[0] === keys[0]) return
            currentSelectedInfo.current = { keys, info }
            setOpenConfirm(true)
            return
        }
        setSelectedKeys([...keys])

        reduxData.selectedFolder = [...keys]
        reduxData.folderPath = info?.node?.location
        reduxData.tableData.result = {}
        setReduxData(reduxData)

        setSelectedFiles(prev => {
            prev = prev?.filter(f => f?.parentName === keys?.[0])
            return [...prev]
        })

    };

    const onExpand = (keys, info) => {

        if(treeDisabled) return
        let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
        setExpandedKeys([...keys])

        reduxData.explorerExpandedKeys = [...keys]
        setReduxData(reduxData)
    };

    const onConfirmModal = () => {
        if(isUnstructured) {
            onCheck(currentSelectedInfo.current?.keys, currentSelectedInfo?.current?.info, true)
        }
        else {
            onSelect(currentSelectedInfo.current?.keys, currentSelectedInfo.current?.info, true)
        }
    }

    const onCancelModal = () => {
        currentSelectedInfo.current = {}
    }

    const onCheck = (checkedKeysValue, info, fromModal = false) => {
        // console.log({checkedKeysValue})
        let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
        
        if(
            !fromModal
            &&
            getObjectLength(reduxData?.tableData?.result) > 0
            &&
            checkedKeys?.length !== checkedKeysValue?.length
        ) {
            currentSelectedInfo.current = {keys: checkedKeysValue, info}
            setOpenConfirm(true)
            return
        }

        if(info?.node?.autoLoad) {
            setCheckedKeys(podData?.pod_file_info?.file_names)
            setCheckedElementKeys(podData?.pod_file_info?.file_names)
            reduxData.selectedFolder = [...podData?.pod_file_info?.file_names]
        }
        else {
            setCheckedKeys(checkedKeysValue);
            setCheckedElementKeys(checkedKeysValue)
            reduxData.selectedFolder = [...checkedKeysValue]
        }

        reduxData.folderPath = info?.node?.location
        reduxData.tableData.result = {}
        setReduxData(reduxData)
        setTopicsData([])
    };

    // const onSelectFile = (info) => {
    //     let res = []
    //     setSelectedFiles(prev => {
    //         if(info?.checked) {
    //             res = [...prev, {...info}]
    //         }
    //         else {
    //             res = prev?.filter(f => f?.key !== info?.key)
    //         }
    //         setCheckedFiles([...res])
    //         return [...res]
    //     })
    // }
    
    const onLoadData = ({ key, children, ...rest }) => {
        // if(treeDisabled) return
        return new Promise((resolve) => {

            const parentName = key ?? rest?.name

            if (children) {
                resolve();
                return;
            }
            const requestBody = {
                connection_name: connName,
                prefix: key
            }
    
            dispatch(fetchFileSystem(requestBody))
            .then(res => {
                let reduxData = store?.getState()?.DataCatalogue?.PodCreationData

                let folders = res?.data?.folder
                let objects = res?.data?.object
                let temp = []
                folders?.forEach(f => {
                    temp.push({
                        title: f?.name,
                        key: `${parentName}/${f?.name}`,
                        path: `${parentName}/${f?.name}`,
                        icon: <ICON_FOLDER height='14' width='14' />,
                        location: f?.path,
                        isFolder: true,
                        parentName
                    })
                })
                objects?.forEach(f => {
                    temp.push({
                        title: f?.name,
                        key: `${parentName}/${f?.name}`,
                        path: `${parentName}/${f?.name}`,
                        location: f?.path, // Full Path of the Object
                        isLeaf: true,
                        disabled: !isUnstructured,
                        selectable: false,
                        isFile: true,
                        icon: <ICON_FILE height='14' width='14' />,
                        parentName
                    })
                })
                setTreeData((origin) => {
                    const newData = updateTreeData(origin, key, temp)
                    
                    reduxData.explorerTreeData = [...newData]
                    setReduxData(reduxData)
                    
                    return newData
                });
                resolve()
            })
            .catch(err => {
                
            })
        }
    )}

    return (
        <div className={`h-100 exp-dir-wrap ${isEditMode ? 'src-view' : ''}`}>
            {
                loading ? <ClaristaLoader/>
                :
                error ? error
                :   empty 
                    ?   <div className='my-5 mx-auto w-50'>
                            <div className='alert alert-info text-center fontSizeHeading'>
                                No Folders Found
                            </div>
                        </div>
                    :  
                        <div id={podCreationTutorialComponentIds?.explorerTreeId} className='position-relative'>
                            {
                                overlayLoading ? <div className='d-block position-absolute h-100 w-100' 
                                    style={{top: 0, left: 0, zIndex: 2, background: '#ffffff6e'}}>
                                        <ClaristaLoader/>
                                </div>
                                :   ''
                            }
                            <div className='tree-header-pane d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <ICON_HOME height='16' width='16' color='#2C83C4'/>
                                    <label className='mb-0 color-primary ml-2 fontInterSemiBold fontSizeHeading'
                                        style={{lineHeight: 1}}
                                    >
                                        Root
                                    </label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    {
                                        openSearch
                                        ?   <SearchInput 
                                                searchData={searchData} 
                                                setSearchData={setSearchData} 
                                                searchBoxOpen={false}
                                            />
                                        :   ''
                                    }
                                    <Buttons
                                        props={{
                                            tooltip: openSearch ? "Close Search" : "Open Search",
                                            buttonText: "",
                                            tooltipPlacement: "right",
                                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2",
                                            buttonEvent: () => {
                                                setSearchData('')
                                                setOpenSearch(!openSearch)
                                            },
                                            ImgSrc: () => openSearch ? <ICON_CLOSE/> : <ICON_SEARCH />,
                                            isDisable: false,
                                            buttonType: Literals.BTN_TERTIARY,
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                treeDataMemo.length === 0 ? <div className='text-center text-black-50 p-3 fontSizeHeading'>No Data Found</div>
                                :
                                <Tree.DirectoryTree
                                    // selectable={!treeDisabled}
                                    expandAction={!treeDisabled}
                                    selectedKeys={selectedKeys}
                                    expandedKeys={expandedKeys}
                                    onCheck={onCheck}
                                    onSelect={onSelect}
                                    onExpand={onExpand}
                                    treeData={treeDataMemo}
                                    loadData={onLoadData}
                                    checkedKeys={checkedKeys}
                                    disabled={treeDisabled}
                                    height={treeHeight}
                                    className='pod-crt-tree-comp'
                                    checkable={isUnstructured}
                                    selectable={!isUnstructured}
                                />
                            }
                            {/* {
                                treeDisabled ? <div className='position-absolute d-block h-100 w-100'
                                style={{top: 0, left: 0, zIndex: 2, background: '#ffffff6e', cursor: 'not-allowed'}}
                                >
                                </div>
                                :   ''
                            } */}
                        </div> 
            }
            {
                getConfirmationModal({
                    open: openConfirm,
                    setOpen: setOpenConfirm,
                    body: 'This will reset the preview, are you sure want to continue?',
                    onConfirm: onConfirmModal,
                    onCancel: onCancelModal
                })
            }
        </div>
    )
}

export default ExplorerDirectoryTree

const updateTreeData = (list, key, children) =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
});