const SET_SORTING_COLUMNS="SET_SORTING_COLUMNS"
const SET_FILTERING_COLUMNS="SET_FILTERING_COLUMNS"
const SET_HIDING_COLUMNS="SET_HIDING_COLUMNS"
const SET_ACTIVE_TAB_KEY="SET_ACTIVE_TAB_KEY"
const SET_PREVIEW_FLAG="SET_PREVIEW_FLAG"
const SET_ALL_COLUMNS_LIST="SET_ALL_COLUMNS_LIST"
const SET_RESPONSE_QUERY="SET_RESPONSE_QUERY"
const SET_CURRENT_TABLE_ROW_COUNT="SET_CURRENT_TABLE_ROW_COUNT"
const RESET_ALL_DATAGRIDTABLE_FILTERS="RESET_ALL_DATAGRIDTABLE_FILTERS"
const SET_TABLE_UUID="SET_TABLE_UUID"
const SET_QUERY_KILL="SET_QUERY_KILL"
const SET_ROW_COUNT_BY_UUID="SET_ROW_COUNT_BY_UUID"

export const setSortingColumns = (data) => {

    return {
        type: SET_SORTING_COLUMNS,
        payload: data
    };
};
export const setFilteringColumns = (data) => {

    return {
        type: SET_FILTERING_COLUMNS,
        payload: data
    };
};
export const setHidingColumns = (data) => {

    return {
        type: SET_HIDING_COLUMNS,
        payload: data
    };
};

export const setActiveTabKey = (data) => {

    return {
        type: SET_ACTIVE_TAB_KEY,
        payload: data
    };
};

export const setPreviewFlag = (data) => {

    return {
        type: SET_PREVIEW_FLAG,
        payload: data
    };
};

export const setAllColumnsList = (data) => {

    return {
        type: SET_ALL_COLUMNS_LIST,
        payload: data
    };
};

export const setResponseQuery = (data) => {

    return {
        type: SET_RESPONSE_QUERY,
        payload: data
    };
};

export const setCurrentTableRowCount = (data) => {
   
    return {
        type: SET_CURRENT_TABLE_ROW_COUNT,
        payload: data
    };
};

export const setTableUUID = (data) => {
    return {
        type: SET_TABLE_UUID,
        payload: data
    };
};

export const setQueryKillFlag = (data) => {
    return {
        type: SET_QUERY_KILL,
        payload: data
    };
};

export const setRowCountByUUID = ({uuid, rowCount}) => {
    return {
        type: SET_ROW_COUNT_BY_UUID,
        payload: {uuid, rowCount}
    };
};

export const resetAllDataGridTableFilters = () => {
    return {
        type: RESET_ALL_DATAGRIDTABLE_FILTERS,
        payload: initialState
    };
};

const initialState = {
    sortArray: [],
    filterArray: [],
    hiddenFieldsArray: [],
    activeTabKey: '',
    previewFlag: 'Preview',
    allColumns: [],
    query: '',
    rowCount: '',
    uuid: '',
    isQueryKill: false,
    rowCountMap: {}
}

const DataGridTable = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case SET_SORTING_COLUMNS:
            return {
                ...state,
                sortArray: [...payload],
            }

        case SET_FILTERING_COLUMNS:
            return {
                ...state,
                filterArray: [...payload],
            }

        case SET_HIDING_COLUMNS:
            return {
                ...state,
                hiddenFieldsArray: [...payload],
            }

        case SET_ACTIVE_TAB_KEY:
            return {
                ...state,
                activeTabKey: payload,
            }

        case SET_PREVIEW_FLAG:
            return {
                ...state,
                previewFlag: payload,
            }

        case SET_ALL_COLUMNS_LIST:
            return {
                ...state,
                allColumns: payload,
            }

        case SET_RESPONSE_QUERY:
            return {
                ...state,
                query: payload,
            }

        case SET_CURRENT_TABLE_ROW_COUNT:
            return {
                ...state,
                rowCount: payload,
            }

        case SET_TABLE_UUID:
            return {
                ...state,
                uuid: payload,
            }

        case SET_ROW_COUNT_BY_UUID:
            return {
                ...state,
                rowCountMap: {
                    ...state.rowCountMap,
                    [payload.uuid]: payload.rowCount
                },
            }

        case RESET_ALL_DATAGRIDTABLE_FILTERS:
            return initialState
        
        case SET_QUERY_KILL:
            return {...state,isQueryKill:payload}
        default:
            return state;
    }
}
export default DataGridTable;