import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { getBusinessTermDetails, getClassificationData, getSettingsDataCategory, registerTechnicalWithBusinessTerm, updateBusinessTermDetails } from '../../../../store/modules/dataDictionary/action';
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions';
import { TwoSections } from '../../../Common/twoSections/TwoSections';
import { Literals } from '../../common/literals';

import { AdditionalDetailsView } from './AdditionalDetailsView';
import { AlertsView } from './AlertsView';
import { BusinessTermView as BusinessTermTopDetails } from './BusinessTermView';
import { ChangeLogView } from './ChangeLogView';
import CustomTimeline from '../../../Common/timeline/CustomTimeline';
import { ClassificationsView } from './ClassificationsView';
import { GovernanceView } from './GovernanceView';
import { NotesView } from './NotesView';
import { PodsOfDomainsView as DataSources } from './PodsOfDomainsView';
import { TechnicalTermsView } from './TechnicalTermsView';
import { generateHtmlTitle, getReloadErrorTemplate } from '../../common/helperFunctions';
import { HealthView } from './HealthView';
import DataStewardsView from './DataStewardsView';
import ContainsPII from './ContainsPII';
import { Drawer, Tabs } from 'antd';
import Buttons from '../../../Common/button/Buttons';
import { CLOSE } from '../../../Common/iconSource';
import { toast } from 'react-toastify';
import { ICON_INFO, ICON_STATS } from '../../../Common/newIconSource';
import BusinessTermStats from './BusinessTermStats';
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader';
import { getUserSelectedDomain } from '../../../../store/actions';
import { v4 as uuidv4 } from "uuid";



let initialNote = "";
const DataDictionaryDetailedPage = forwardRef(({
    isEdit,
    setIsEdit,
    setDictionaryDetails = () => { },
    showChangeLogs,
    setShowChangeLogs = () => { }
}, ref) => {

    const dispatch = useDispatch()
    const { dataDictionaryId } = useParams()

    const [categories, setCategories] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [saveClick, setsaveClick] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [isFetching, setFetching] = useState(false)
    const [error, setError] = useState(undefined)

    const [classifications, setClassifications] = useState([])
    const [data, setData] = useState(null)
    const [fieldValues, setFieldValues] = useState({})
    const [openTimeline, setTimelineDrawer] = useState(false)
    const [rerender, setrerender] = useState(false)

    const [activeTabKey, setActiveTabKey] = useState('1')

    const [usersLoading, setUsersLoading] = React.useState([])
    const [usersList, setUsersList] = React.useState([])
    const [selectedOwners, setSelectedOwners] = React.useState([])
    const [selectedStewards, setSelectedStewards] = React.useState([])
    const [defaultDomain, setDefaultDomain] = React.useState(null)
    const [critUUID, setCritUUID] = React.useState('')

    React.useEffect(() => {
        fetchUserSelectedDomain()
        getUsersList()
    }, [])

    React.useEffect(() => {
        setTimelineDrawer(showChangeLogs)
    }, [showChangeLogs])

    useEffect(() => {
        if (fieldValues && fieldValues?.business_term) {
            let title = generateHtmlTitle(fieldValues?.business_term?.toUpperCase(), 'Data Dictionary')
            document.title = title
        }
        else {
            let title = generateHtmlTitle('Dictionary Details', 'Data Dictionary')
            document.title = title
        }
    }, [fieldValues])

    useEffect(() => {
        if (dataDictionaryId && dataDictionaryId.length) {
            getDetails()
        }
    }, [dataDictionaryId])

    useImperativeHandle(ref, () => (
        {
            handleSave: () => saveHandler(fieldValues.id),
            reset: () => resetDetails()
        }
    ), [fieldValues, selectedOwners, selectedStewards])

    const fetchUserSelectedDomain = () => {
        dispatch(getUserSelectedDomain())
        .then(res => {
            const domain = res?.data?.domain ?? undefined
            if (domain && domain?.id) {
                setDefaultDomain(domain)
            }
            else if (domain) {
                setDefaultDomain(domain ?? null)
            }
            else setDefaultDomain(null) 
        })
        .catch(err => {
            setDefaultDomain(null)
        })
    }

    const getUsersList = () => {
        setUsersLoading(true)
        dispatch(getUserGroupList({ method: "GET", endPoint: "profile/", payload: undefined, showLoader: false }))
        .then(res => {
            let list = res?.data?.map(ele => ({ name: `${ele?.first_name} ${ele?.last_name}`, value: ele?.id?.toString(), email: ele?.email }))
            setUsersList([...list]);
            setUsersLoading(false)
        })
        .catch(err => {
            setUsersLoading(false)
            console.error(err)
        })
    };

    const dateCriteria = {
        '=': 'date is',
        '<=': 'date before',
        '>=': 'date after',
    }

    const getDetails = () => {
        const searchParams = new URLSearchParams(document.location.search)
        const history_id = searchParams.get("history_id");
        setError(undefined)
        setFetching(true)
        dispatch(getBusinessTermDetails(dataDictionaryId, history_id))
            .then(res => {
                let uuid = uuidv4()
                setCritUUID(uuid)

                initialNote = res.data.notes
                setDictionaryDetails({ ...res.data })
                setData({ ...res.data })
                setFieldValues({
                    id: res.data.id,
                    business_term: res.data.business_term,
                    // category: _.isEmpty(res?.data?.category_detail?.name ?? '')
                    //     ? ''
                    //     : res?.data?.category_detail?.id?.toString(),
                    classification: res.data.classification_detail,
                    description: res.data.description,
                    addfield: [...res.data.addfield],
                    col_term: [...res.data.col_term],
                    tech_term: [...res.data.tech_term],
                    notes: res.data.notes ?? "",
                    owner_steward_detail: res?.data?.owner_steward_detail,
                    status: res?.data?.status,
                    status_changed_by_user: res?.data?.status_changed_by_user,
                    quality_check: (() => {
                        let list = res?.data?.quality_check ?? []
                        return list?.map(f => ['like', 'not like']?.includes(f?.criteria?.toLowerCase()) 
                            ?   ({...f, value: f?.value?.substring(1, f?.value?.length -1)})  
                            :   ({...f, criteria: f?.is_dttme ? dateCriteria[f?.criteria] : f?.criteria})
                        )
                    })(),
                    notify: res?.data?.notify,
                    pii: res?.data?.pii
                })
                setSelectedOwners(res?.data?.owner_steward_detail?.owner?.map(o => o?.id?.toString()))
                setSelectedStewards(res?.data?.owner_steward_detail?.steward?.map(s => s?.id?.toString()))
                setFetching(false)
            })
            .catch(err => {
                console.error(err)
                setError(err?.message)
                setFetching(false)
            })

        // dispatch(getSettingsDataCategory(false))
        //     .then((res) => {
        //         setAllCategories([...res.data])
        //         let temp = res.data.map(v => ({ Name: v.name, value: v?.id?.toString() }))
        //         setCategories([...temp])
        //     })
        //     .catch(() => { })

        dispatch(getClassificationData(false))
            .then((res) => {
                // let temp = res.data.map(v=>({Name:v.name, Value:`${v.id}::${v.name}`}))
                let temp = res.data
                setClassifications([...temp])
            })
            .catch(() => { })
    }

    const handleCriteria = (crit, value) => {

        if(crit === 'date is') return '='
        if(crit === 'date before') return '<='
        if(crit === 'date after') return '>='

        if(crit === 'date=' && value?.startsWith('@')) {
          return 'is'
        }
        return criteriaMap[crit] ?? crit
    }

    const criteriaMap = {
        'date=': "=",
        'date<': "<",
        'date>': ">",
        '==': "=",
        "s%": "like",
        "%s": "like"
    }

    const handleCriteriaValue = (crit, val) => {
        if(crit === "s%") return `${val}%`
        if(crit === "%s") return `%${val}`
        if(crit === "LIKE" || crit === "NOT LIKE") return `%${val}%`
        return val
    }

    const saveHandler = (id) => {
        if (!_.isEmpty(fieldValues.business_term)) {
            let payload = { ...fieldValues }
            
            let healthEmptyFieldCheck = false;
            if (payload && payload?.quality_check?.length) {
                let formGroupData = payload?.quality_check;
                let validateColumn = formGroupData.every((el) => {
                    return el?.name?.trim().length > 0
                })
                let validateCriteria = formGroupData.every((el) => {
                    return el?.criteria?.trim().length > 0 && el.criteria.trim() !== "Select Filter Criteria"
                })
                let validateValue = formGroupData.every((el) => {

                    if (typeof el?.value === 'string') {
                        return el?.value?.trim()?.length > 0
                    }
                    else if ((el?.criteria === 'IN' || el?.criteria === 'NOT IN') && el.value?.length) {
                        return true
                    }
                    else if (typeof el?.value === 'object') {
                        return el?.value?.[0]?.trim().length > 0 && el?.value?.[1]?.trim().length > 0
                    }

                })
                if ((!validateColumn || !validateCriteria || !validateValue)) {
                    emitToastNotification('warning', 'Empty field found in health criteria.')
                    healthEmptyFieldCheck = true

                } else {
                    healthEmptyFieldCheck = false
                }

            }

            
            if (!healthEmptyFieldCheck) {
                
                payload['addfield'] = payload['addfield'].map(v => ({ add_field: v.id, value: v.value }))
                payload['classification'] = payload['classification'].map(v => ({ classification: v.id }))
                payload['owner_steward'] = [
                    ...selectedOwners?.map(o => ({user: parseInt(o), user_type: "OWNER"})),
                    ...selectedStewards?.map(s => ({user: parseInt(s), user_type: "STEWARD"}))
                ]
                delete payload?.owner_steward_detail

                let techBizPayload = fieldValues?.col_term?.map(t => (
                    {
                        tech_term: t?.tech_term,
                        data_dict: fieldValues?.id,
                        connection: t?.connection?.[0] ?? null,
                        primary_key: t?.primary_key ?? false
                    }
                ))

                if(payload['addfield']?.length > 0) {
                    if(
                        payload['addfield']?.some(f => f?.value === null || f?.value === undefined || f?.value === '')
                    ){
                        emitToastNotification('warning', 'Empty value found in additional fields')
                        return
                    }
                }

                if(payload['quality_check'] && payload['quality_check']?.length > 0) {
                    payload['quality_check'] = payload['quality_check']?.map(q => (
                        {
                            ...q,
                            criteria: handleCriteria(q?.criteria, q?.value),
                            value: handleCriteriaValue(q?.criteria, q?.value),
                            is_dttme: q?.criteria?.includes('date')
                        }
                    ))
                }

                setLoading(true)
                toast.promise(
                    new Promise(async (resolve, reject) => {
                        try {
                            await dispatch(registerTechnicalWithBusinessTerm(techBizPayload, false, false))
                            dispatch(updateBusinessTermDetails(id, payload))
                                .then(() => {
                                    setLoading(false)
                                    setsaveClick(true)
                                    setIsEdit(false)
                                    getDetails()
                                    // emitToastNotification('success', Literals.UPDATE_SUCCESSFUL)
                                })
                                .catch(() => {
                                    setLoading(false)
                                    setsaveClick(false)
                                    // emitToastNotification('error', Literals.UPDATE_FAILED)
                                })
                            resolve()
                        }
                        catch(err) {
                            setLoading(false)
                            reject(err)
                        }
                    }),
                    {
                        pending: 'Updating please wait...',
                        success: 'Successfully updated Display Name',
                        error: {
                            render: ({data}) => {
                                return `Error: ${data.message}`;
                            }
                        }
                    },
                    {
                        position: 'bottom-right',
                        autoClose: 5000,
                    }
                )
            }
            // dispatch(updateBusinessTermDetails(id, payload))
            //     .then(() => {
            //         setsaveClick(true)
            //         emitToastNotification('success', Literals.UPDATE_SUCCESSFUL)
            //     })
            //     .catch(() => {
            //         setsaveClick(false)
            //         emitToastNotification('error', Literals.UPDATE_FAILED)
            //     })
        }
        else emitToastNotification('info', Literals.BUSINESS_TERM_REQUIRED)
    }

    const resetDetails = () => {
        const res = { data }
        initialNote = res.data.notes
        setFieldValues(
            {
                id: res.data.id,
                business_term: res.data.business_term,
                // category: (() => {
                //     if(res?.data?.category_detail) {
                //         let id = res?.data?.category_detail?.id?.toString()
                //         if(id === '0') return ''
                //         return id
                //     }
                //     return ''
                // })(),
                classification: res.data.classification_detail,
                description: res.data.description,
                addfield: [...res.data.addfield],
                col_term: [...res.data.col_term],
                tech_term: [...res.data.tech_term],
                notes: res.data.notes ?? "",
                owner_steward_detail: res?.data?.owner_steward_detail,

                status: res?.data?.status,
                status_changed_by_user: res?.data?.status_changed_by_user,

                quality_check: (() => {
                    let arr = res?.data?.quality_check ?? []
                    arr = arr?.map(v => ({
                        ...v, 
                        criteria: v?.is_dttme ? dateCriteria[v?.criteria] ?? v?.criteria : v?.criteria
                    }))  
                    
                    return arr
                })(), 
                notify: res?.data?.notify,
                pii: res?.data?.pii
            }
        )
        let uuid = uuidv4()
        setCritUUID(uuid)
    }

    /* const dateCriteriaObj = {
        'date is': '=',
        'date before': '<=',
        'date after': '>='
    } */

    const leftPanelContent = useMemo(() => {
        const stewards = fieldValues?.owner_steward_detail?.steward
        const owners = fieldValues?.owner_steward_detail?.owners
        return <>
            <div id='dict-detail-fram' className='data-dictionary-detail-left data-dict-metadata'>
                <div className='border-right data-dict-meta' style={{overflowY: 'auto'}}>
                    <BusinessTermTopDetails
                        fieldValues={fieldValues}
                        setFieldValues={setFieldValues}
                        categories={categories}
                        classifications={classifications}
                        isEdit={isEdit}
                        initialNote={initialNote}
                        initialData={data}
                        usersList={usersList}
                        usersLoading={usersLoading}
                        selectedOwners={selectedOwners}
                        selectedStewards={selectedStewards}
                        setSelectedOwners={setSelectedOwners}
                        setSelectedStewards={setSelectedStewards}
                    />
                </div>
                <div className='pl-1' style={{overflowY: 'auto'}}>
                    <HealthView
                        fieldValues={fieldValues}
                        setFieldValues={setFieldValues}
                        isEdit={isEdit}
                        rerender={rerender}
                        setrerender={setrerender}
                        selectedOwners={selectedOwners}
                        selectedStewards={selectedStewards}
                        critUUID={critUUID}
                    />
                    <div className='p-1'></div>
                    {/* <AdditionalDetailsView
                        fieldValues={fieldValues}
                        setFieldValues={setFieldValues}
                        isEdit={isEdit}
                    />
                    <div className='p-1'></div> */}
                    <DataSources
                        colData={fieldValues.col_term}
                        setFieldValues={setFieldValues}
                        isEdit={isEdit}
                    />
                    <div className='p-1'></div>
                </div>
                {/* {
                    fieldValues?.addfield?.length
                    ?
                    <>
                        <AdditionalDetailsView
                            fieldValues={fieldValues} 
                            setFieldValues={setFieldValues}
                            isEdit={isEdit}
                        />
                        <div className='p-1'></div>
                    </>
                    : ''
                } */}
                {/* <div className='p-1'></div> */}
                {/* <AlertsView/> */}
                {/* <NotesView
                    fieldValues={initialNote}
                    setFieldValues={setFieldValues}
                    isEdit={isEdit} /> */}
            </div>
        </>
    }, [
        data,
        fieldValues, 
        categories, 
        classifications, 
        usersList,
        usersLoading,
        selectedOwners,
        selectedStewards,
        rerender,
        isEdit
    ])

    const rightPanelContent = useMemo(() => {
        return <>
            <div className='data-dictionary-detail-right h-100 position-relative'>
                <ChangeLogView
                    id={dataDictionaryId}
                    saveClick={saveClick}
                    setShowChangeLogs={setShowChangeLogs}
                    isDictionary={true}
                />
                <span className='position-absolute' style={{ top: 12, right: 12 }}>
                    <Buttons
                        props={{
                            tooltipPlacement: 'left',
                            buttonText: "",
                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                            buttonEvent: () => {
                                setShowChangeLogs(false)
                            },
                            tooltip: "Close",
                            ImgSrc: () => <CLOSE />,
                            isDisable: false,
                            buttonType: Literals.BTN_TERTIARY,
                        }}
                    />
                </span>
            </div>
        </>
    }, [showChangeLogs, saveClick])

    const onClose = () => {
        setShowChangeLogs(false)
    }

    const onTabChange = (key) => {
        setActiveTabKey(key)
    }

    const tabItems = () => {
        return [
            {
                key: '1',
                label: <span>
                    <ICON_INFO /> DETAILS
                </span>,
                children: leftPanelContent,
            },
            {
                key: '2',
                label: <span>
                    <ICON_STATS /> HEALTH CHECKS
                </span>,
                children: <BusinessTermStats
                    dataDictionaryId={dataDictionaryId}
                    fieldValues={fieldValues}
                    setFieldValues={setFieldValues}
                    activeTabKey={activeTabKey}
                    initialData={data}
                    defaultDomain={defaultDomain}
                />,
            },
        ]
    }

    return (
        <div id='dict-detailed-pg' className={`padding-2`}>
            {
                isLoading ? <div className='dict-loading-overlay'></div>
                    : ''
            }
            {/* <div id='dict-detailed-pg' className={`padding-2 ${showChangeLogs ? 'show' : 'hide'}-change-log`}> */}
            <div className='d-flex' style={{ height: 'calc(100vh - 90px)' }}>
                <div className='w-100'>
                    {
                        isFetching ? <ClaristaLoader />
                            : error ? getReloadErrorTemplate({ errorMessage: error, onReload: () => getDetails() })
                                :
                                <Tabs
                                    className='dict-detail-tab'
                                    defaultActiveKey="1"
                                    activeKey={activeTabKey}
                                    items={tabItems()}
                                    onChange={onTabChange}
                                />
                    }
                </div>
                {/* <div className='w-25'>
                    {rightPanelContent}
                </div> */}
                {/* <Drawer
                    title=""
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    open={openTimeline}
                    children={rightPanelContent}
                    className='dict-timeline-drawer'
                /> */}
            </div>
        </div>
    )
})
export default DataDictionaryDetailedPage;