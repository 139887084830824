import React from 'react'
import './startpage.scss'
import LandingpageFirstHeader from '../common/LandingpageFirstHeader'
import PulseCompanyLogo from '../../Common/mainLandingPage/components/PulseCompanyLogo'
import { getOpenAiStatus, setUpdatedUserData, setUser } from '../../../store/userAuthentication/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { getDomainConnectionList, getLandingPageData, getUserSelectedDomain, setSidebarActiveRoute } from '../../../store/actions'
import LandingDomainTabs from '../../Common/mainLandingPage/components/LandingDomainTabs'
import PulseSearchBox from '../../Common/mainLandingPage/components/PulseSearchBox'
import { kFormatter, titleCase } from '../common/helperFunctions'
import { Carousel } from 'antd'
import { ICON_ARROW_LEFT, ICON_BELL, ICON_BINOCULAR_STROKE, ICON_CATALOG, ICON_DASHBOARD, ICON_FLOWS, ICON_LOADING_CIRCLE, ICON_REDSHIFT, ICON_SALESFORCE, ICON_SNOWFLAKE, ICON_TRACK } from '../../Common/newIconSource'
import { useNavigate } from 'react-router-dom'
import { emitToastNotification } from '../../../helpers/toast_helper'
import { Literals } from '../common/literals'
import { fetchFlowsLandingPageList } from '../../../store/modules/flows/landingPage/landingPageAction'
import { getDashboardListApi } from '../../../store/modules/analytics/dashboard/dashboardActions'
import { CustomSkeleton } from '../../Common/skeleton/CustomSkeleton'
import SourceTypeImage from '../common/sourceTypeImage'
import _ from 'lodash'
import { getTopQuestions } from '../../../store/modules/common/GenAi/GenAiReducer'
import { v4 as uuidv4 } from 'uuid';

const StartPage = () => {

    const dispatch = useDispatch()
    const history = useNavigate()

    const [activeDomain, setActiveDomain] = React.useState('')
    const [noDomainAccess, setNoDomainAccess] = React.useState(false)
    const [isDomainFailed, setDomainFailed] = React.useState(false)
    const [domains, setDomains] = React.useState([])
    const [questValue, setQuestValue] = React.useState('')
    const [activeAnimStep, setActiveAnimStep] = React.useState('')
    const [showDiscover, setShowDiscover] = React.useState(false)
    const [topQuestions, setTopQuestions] = React.useState([])
    const [startAnim, setStartAnim] = React.useState(false)
    const [hideAnim, setHideAnim] = React.useState(false)

    const [podLoading, setPodLoading] = React.useState(false)
    const [podEmpty, setPodEmpty] = React.useState(false)
    const [podData, setPodData] = React.useState([])
    
    const [flowLoading, setFlowLoading] = React.useState(false)
    const [flowEmpty, setFlowEmpty] = React.useState(false)
    const [flowData, setFlowData] = React.useState([])
    
    const [dashLoading, setDashLoading] = React.useState(false)
    const [dashEmpty, setDashEmpty] = React.useState(false)
    const [dashData, setDashData] = React.useState([])
    
    const [connLoading, setConnLoading] = React.useState(false)
    const [connEmpty, setConnEmpty] = React.useState(false)
    const [connData, setConnData] = React.useState([])

    let userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)
    let user = useSelector((state) => state.UserReducer?.user)

    const { datadomainList } = useSelector(state => state.UserManagement.DataDomain)

    React.useEffect(() => {

        fetchUserSelectedDomain()
        fetchOpenAIStatus()

        dispatch(setSidebarActiveRoute('/home'))

    }, [])

    React.useEffect(() => {
        setStartAnim(showDiscover)
        if(showDiscover) anim()
        else setActiveAnimStep(undefined)
    }, [showDiscover])
    
    const anim = () => {
        setActiveAnimStep(prev => {
            switch (prev) {
                case '': 
                    return '0-0'
                case '0-0':
                    return 0
                case 0: 
                    return '1-1'
                case '1-1': 
                    return 1
                case 1: 
                    return '2-2'
                case '2-2':
                    return 2
                case 2:
                default: 
                    return ''                
            }
        })

        setStartAnim(started => {
            if(started) {
                setTimeout(() => {
                    anim()
                }, 1000)
            }
            /**
             * TO RESET THE ANIMATION UNCOMMENT THE ELSE PART
             * */ 
            // else {
            //     setActiveAnimStep('')
            // }
            return started
        })
    }

    React.useEffect(() => {
        if(activeDomain && activeDomain !== '') {
            fetchTopQuestions()
            fetchConnections()
            fetchDashboards()
            fetchFlows()
            fetchPods()
        }
    }, [activeDomain])

    const fetchUserSelectedDomain = () => {
        dispatch(getUserSelectedDomain())
        .then(res => {
            const domain = res?.data?.domain ?? undefined
            if (domain && domain?.id) {
                setActiveDomain(domain?.id)
            }
            else if (domain) {
                setActiveDomain(domain?.id ?? 0)
            }
            else {
                setNoDomainAccess(true)
            }
        })
        .catch(err => {
            setDomainFailed(true)
            // console.error(err?.message)
        })
    }

    const fetchOpenAIStatus = () => {
        dispatch(getOpenAiStatus())
        .then(res => {
            let openai_data = res?.data
            let details = {...user}
            details.userDetails = {...user.userDetails, openAi: openai_data}
            dispatch(setUser(details))
            setUpdatedUserData({...details.userDetails, openAi: openai_data})
        })
        .catch(err => {
            console.error({err})
            let details = {...user}
            details.userDetails = {...user.userDetails, openAi: null}
            dispatch(setUser(details))
            setUpdatedUserData({...details.userDetails, openAi: null})
        })
    }

    const fetchPods = () => {
        setPodData([])
        setPodLoading(true)
        dispatch(getLandingPageData(false, false, false, activeDomain, true))
        .then(res => {

            const data = res?.data ?? []
            setPodEmpty(data?.length === 0)
            setPodLoading(false)
            setPodData([...data])

        })
        .catch(() => {
            setPodEmpty(false)
            setPodLoading(false)
        })
    }

    const fetchFlows = () => {
        setFlowData([])
        setFlowLoading(true)
        dispatch(fetchFlowsLandingPageList(activeDomain))
        .then(res => {

            const data = res?.data ?? []
            setFlowEmpty(data?.length === 0)
            setFlowLoading(false)
            setFlowData([...data])

        })
        .catch(() => {
            setFlowEmpty(false)
            setFlowLoading(false)
        })
    }

    const fetchDashboards = () => {
        setDashData([])
        setDashLoading(true)
        dispatch(getDashboardListApi(activeDomain))
        .then(res => {

            const data = res?.data ?? []
            setDashEmpty(data?.length === 0)
            setDashLoading(false)
            setDashData([...data])

        })
        .catch(() => {
            setDashEmpty(false)
            setDashLoading(false)
        })
    }

    const fetchConnections = () => {
        setConnData([])
        setConnLoading(true)
        dispatch(getDomainConnectionList(false, activeDomain))
        .then(res => {

            const data = res?.data ?? []
            setConnEmpty(data?.length === 0)
            setConnLoading(false)
            let uniqConnTypeList = _.uniqBy(data, 'source_type')
            setConnData([...uniqConnTypeList])

        })
        .catch(() => {
            setConnEmpty(false)
            setConnLoading(false)
        })
    }

    const fetchTopQuestions = () => {
        setTopQuestions([])
        dispatch(getTopQuestions(activeDomain))
        .then(res => {
            let list = res?.data
            // if(list?.length !== 0 && list?.length < 10) {
            //     for (let index = 0; index < 10; index++) {
            //         list[index] = res?.data[index] ?? res?.data[0]
            //     }
            // }
            
            setTopQuestions([...list])
        })
        .catch(() => {

        })
    }
    // console.log({podData, flowData, dashData, connData})

    const handleQuestion = () => {

        if (questValue?.trim() === '') {
            return
        } 
        if(!activeDomain || activeDomain === '' || activeDomain === undefined || activeDomain === null) {
            emitToastNotification('info', 'Please Select Domain To Proceed')
            return
        }

        history(Literals.links.PULSE_TALK + `?dm=${activeDomain}`, { state: { fromStartPage: true, query: questValue } })
    }

    const activePipeColor = '#3361FF'

    const showDiscoverButton = () => {
        if(window.screen.availWidth < 768) { 
            return false
        }
        return true
    }
    
    const domainDescription = (str) => {
        if(str === null || str === undefined) return ""
        return str.charAt(0)?.toUpperCase() + str?.slice(1, str.length)
    }

    const handleClick = (mod) => {
        if(window.screen.availWidth < 768) return
        let link;
        if(mod === 'navigator') {
            link = Literals?.links?.CLARISTA_NAVIGATOR + "#pod"
        }
        else if(mod === 'lab') {
            link = Literals?.links?.CLARISTA_LAB + "#flows"
        }
        else if(mod === 'pulse') {
            link = Literals?.links?.CLARISTA_PULSE + "#dashboards"
        }

        if(link) history(link)
    }

    const topQuestTemplate = (q) => {
        return <>
            <div className='d-flex align-items-center trend-item'
                onClick={() => {
                    // if(window?.screen?.availWidth >= 768) {
                        history(Literals.links.PULSE_TALK + '?dm=' + activeDomain + "&uuid=" + uuidv4(), { state: { fromStartPage: true, query: q?.question } })
                    // }
                }}
            >
                <div className='mr-2'>
                    <ICON_TRACK/>
                </div>
                <div>
                    <div>
                        <div>
                            <span className='fontSizeHeading fontInterSemiBold text-capitalize mb-1'>
                                {`${q?.created_by_user?.first_name} Asked`}
                            </span>
                        </div>
                        <div>
                            {<SeeMoreText text={q?.question} />}
                            {/* <p className='asked-quest custom-ellipsis fontSizeHeading mb-0 two-line-ellipsis'>
                                <span>{q?.question}</span>
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    const displayUserName = () => {
        let name = userDetailsRedux?.first_name
        if(userDetailsRedux?.last_name)
        name += " " + userDetailsRedux?.last_name
        return name ?? 'Unknown User'
    }

    return (
        <div className='talk-index-main clarista-start-main-wrap'>
            <LandingpageFirstHeader
                isPulse={true}
                hideLogo={true}
            />
            <div className='start-page-content-wrap'>
                {
                    !showDiscoverButton() ? ''
                    :
                    <span className='discover-toggle'
                        onClick={() => {
                            setShowDiscover(!showDiscover)
                        }}
                    >
                        {
                            showDiscover ? <ICON_ARROW_LEFT height='15' width='15' color='#9A9A9A' />
                            : <ICON_BINOCULAR_STROKE height='18' width='18' color='#9A9A9A'/>
                        }
                        <span className='ml-1 fontInterSemiBold'>
                            {
                                showDiscover ? 'Go Back' : 'Discover'
                            }
                        </span>
                    </span>
                }
                <div>
                    <div>
                        <div className='start-pg-logo-wrap'>
                            <PulseCompanyLogo 
                                activeDomain={activeDomain}
                            />
                        </div>
                    </div>
                    <div className='start-pg-greeting'>
                        <h2 className=''>WELCOME! <span className='fontInterSemiBold text-capitalize highlight-greet'>{displayUserName()}</span></h2>
                    </div>
                    <div className='start-pg-domain-wrap'>
                        <LandingDomainTabs
                            activeDomain={activeDomain}
                            setActiveDomain={setActiveDomain}
                            isDomainFailed={isDomainFailed}
                            setDomainFailed={setDomainFailed}
                            // activeTabKey={activeTabKey}
                            // isDataLoading={isDataLoading}
                        />
                    </div>
                    <div className='start-pg-talkdata-search'>
                        <PulseSearchBox
                            activeDomain={activeDomain}
                            setActiveDomain={setActiveDomain}
                            isDomainFailed={isDomainFailed}
                            setDomainFailed={setDomainFailed}
                            hideDomain={true}
                            searchPlaceholder={`Ask Anything For ${titleCase(domains?.find(d => d?.data_domain_id?.toString() === activeDomain?.toString())?.data_domain_name?.replaceAll("_", " ") ?? '')}...`}
                            setParentDomainList={setDomains}
                            // activeTabKey={activeTabKey}
                            searchValue={questValue}
                            setSearchValue={setQuestValue}
                            onSearch={handleQuestion}
                            onEnterCallback={handleQuestion}
                        />
                    </div>
                    {
                        showDiscover || topQuestions?.length === 0 ? ''
                        :
                        <div className={`start-pg-trend-wrap`}>
                            {
                                topQuestions?.length > 4
                                ?
                                    <Carousel 
                                        autoplay
                                        autoplaySpeed={2000} 
                                        arrows={false}
                                        dotPosition="left" 
                                        dots={false}
                                        infinite={true}
                                        style={{height: 180}}
                                    >
                                        {
                                            topQuestions
                                            ?.map((q, i) => (
                                            <React.Fragment key={`caro-${i}`}>
                                                <div>
                                                    {topQuestTemplate(q)}
                                                </div>
                                            </React.Fragment>
                                            ))
                                        }
                                    </Carousel>
                                :
                                <div className='ant-carousel'>
                                    {
                                        topQuestions
                                        ?.map((q, i) => (
                                        <React.Fragment key={`caro-${i}`}>
                                            <div>
                                                {topQuestTemplate(q)}
                                            </div>
                                        </React.Fragment>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        !showDiscover ? ''
                        :
                        <div className='start-pg-discover'>
                            <div 
                                title={domainDescription(datadomainList?.find(d => d?.data_domain_id?.toString() === activeDomain?.toString())?.data_domain_description ?? '')}
                                className='pg-discover-heading fontSizeHeading fontInterSemiBold text-center mx-auto px-3 custom-ellipsis two-line-ellipsis'>
                                {domainDescription(datadomainList?.find(d => d?.data_domain_id?.toString() === activeDomain?.toString())?.data_domain_description ?? '')}
                            </div>
                            <div className='discover-pipeline'>
                                <div>
                                    <div className='dp-conn'>
                                        <span className={`dp-con1 p-1 ${connData?.length <= 1 ? "only-conn" : connData?.length === 2 ? 'two-conn' : ''}`}>
                                            
                                            {
                                                connLoading
                                                ?
                                                <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
                                                    <ICON_LOADING_CIRCLE color='#9a9a9a' />
                                                </div>  
                                                :
                                                <SourceTypeImage dataName={connData?.[0]?.source_type ?? ''} />
                                            }
                                        </span>
                                        {
                                            connData?.length > 2
                                            ?
                                            <span className='dp-con2 p-1'>
                                                {
                                                    <SourceTypeImage dataName={connData?.[2]?.source_type ?? ''} />
                                                }
                                            </span>
                                            :   ''
                                        }
                                        {
                                            connData?.length > 1
                                            ?
                                            <span className='dp-con3 p-1'>
                                                {
                                                    SourceTypeImage({dataName: connData?.[1]?.source_type ?? '' })
                                                }
                                            </span>
                                            :   ''
                                        }
                                    </div>
                                </div>
                                <div></div>
                                <div>
                                    <div className={`dp-modules ${activeAnimStep === 0 && !hideAnim ? 'active' : ''}`}
                                        
                                        onMouseEnter={() => {
                                            setHideAnim(true)
                                        }}
                                        onMouseLeave={() => {
                                            setHideAnim(false)
                                        }}

                                        // onMouseEnter={() => {
                                        //     setStartAnim(false)
                                        // }}
                                        // onMouseLeave={() => {
                                        //     setStartAnim(true)
                                        //     anim()
                                        // }}
                                    >
                                        <div className='dp-mod-indicator navigator'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <span className='mod-ic d-flex'>
                                                    <ICON_CATALOG color='currentColor'/>
                                                </span>
                                                <span className='mod-pri-title fontInterSemiBold'>
                                                    NAVIGATOR
                                                </span>
                                            </div>
                                            <div className='text-center'>
                                                <span className='mod-sec-title'>
                                                    EXPLORE
                                                </span>
                                            </div>
                                            <div>
                                                <div className='dp-indicator-joiner'></div>
                                            </div>
                                        </div>
                                        <div className='dp-mod-card-1'
                                            onClick={() => {
                                                handleClick('navigator')
                                            }}
                                        >
                                            <div class={`dp-pipline-pipe1 pipeline-anim ${activeAnimStep === '0-0' && !hideAnim ? 'animate' : ''}`}></div>
                                            
                                            {/* <div className='dp-pipline-pipe1'>
                                                {
                                                    ConnectorIconOne(
                                                        activeAnimStep === '0-1-0' ? activePipeColor : undefined, 
                                                        activeAnimStep === '0-0-1' ? activePipeColor : undefined
                                                    )
                                                }
                                            </div> */}
                                            <p className='fontSizeLabel mb-1 custom-ellipsis two-line-ellipsis'>

                                                {
                                                    podLoading
                                                    ?
                                                    <>
                                                        <div className='mb-1'><CustomSkeleton height='10px' width='100%' /></div>
                                                        <div className='mb-1'><CustomSkeleton height='10px' width='60%' /></div>
                                                    </>
                                                    :
                                                        podData?.[0]?.pod_description 
                                                        ? podData?.[0]?.pod_description
                                                        : 'No description has been provided for the following pod yet'
                                                }
                                            </p>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span title={podData?.[0]?.table_name ?? ''} className='mb-0 text-with-ellipsis text-uppercase fontSizeHeading fontInterSemiBold top-heading navigator'>
                                                    {
                                                        podLoading
                                                        ?
                                                        <div className=''><CustomSkeleton height='18px' width='60%' /></div>
                                                        :
                                                        (podData?.[0]?.table_name ?? '[No POD Present]').replaceAll("_", " ")
                                                    }
                                                </span>
                                                <ICON_CATALOG />
                                            </div>
                                        </div>
                                        <div className='dp-mod-card-2'
                                            onClick={() => {
                                                handleClick('navigator')
                                            }}
                                        >
                                            <p className='fontSizeLabel mb-1' style={{height: 33}}></p>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span title={podData?.[1]?.table_name ?? ''} className='mb-0 text-uppercase mod-card-label text-with-ellipsis'>
                                                    {
                                                        podLoading
                                                        ?
                                                        <div className=''><CustomSkeleton height='15px' width='60%' isActive={false} /></div>
                                                        :
                                                        (podData?.[1]?.table_name ?? '').replaceAll("_", " ")
                                                    }
                                                </span>
                                                <ICON_CATALOG />
                                            </div>
                                        </div>
                                        <div className='dp-mod-card-3'
                                            onClick={() => {
                                                handleClick('navigator')
                                            }}
                                        >
                                            <p className='fontSizeLabel mb-1' style={{height: 33}}></p>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span title={podData?.[2]?.table_name ?? ''} className='mb-0 text-uppercase mod-card-label text-with-ellipsis'>
                                                    {
                                                        podLoading
                                                        ?
                                                        <div className=''><CustomSkeleton height='15px' width='60%' isActive={false} /></div>
                                                        :   
                                                        (podData?.[2]?.table_name ?? '').replaceAll("_", " ")
                                                    }
                                                </span>
                                                <ICON_CATALOG />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div></div>
                                <div>
                                    <div className={`dp-modules ${activeAnimStep === 1 && !hideAnim ? 'active' : ''}`}
                                        onMouseEnter={() => {
                                            setHideAnim(true)
                                        }}
                                        onMouseLeave={() => {
                                            setHideAnim(false)
                                        }}

                                        // onMouseEnter={() => {
                                        //     setStartAnim(false)
                                        // }}
                                        // onMouseLeave={() => {
                                        //     setStartAnim(true)
                                        //     anim()
                                        // }}
                                    >
                                        <div className='dp-mod-indicator labs'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <span className='mod-ic d-flex'>
                                                    <ICON_FLOWS color='currentColor'/>
                                                </span>
                                                <span className='mod-pri-title fontInterSemiBold'>
                                                    LAB
                                                </span>
                                            </div>
                                            <div className='text-center'>
                                                <span className='mod-sec-title'>
                                                    ENGINEER
                                                </span>
                                            </div>
                                            <div>
                                                <div className='dp-indicator-joiner'></div>
                                            </div>
                                        </div>
                                        <div className='dp-mod-card-1'
                                            onClick={() => {
                                                handleClick('lab')
                                            }}
                                        >
                                            <div class={`dp-pipline-pipe1 pipeline-anim ${activeAnimStep === '1-1' && !hideAnim ? 'animate' : ''}`}></div>
                                            {/* <div className='dp-pipline-pipe2'>
                                                {ConnectorIconTwo(
                                                    activeAnimStep === '1-1-0' ? activePipeColor : undefined, 
                                                    activeAnimStep === '1-0-1' ? activePipeColor : undefined
                                                )}
                                            </div> */}
                                            <p className='fontSizeLabel mb-1 custom-ellipsis two-line-ellipsis'>
                                                {
                                                    flowLoading
                                                    ?
                                                    <>
                                                        <div className='mb-1'><CustomSkeleton height='10px' width='100%' /></div>
                                                        <div className='mb-1'><CustomSkeleton height='10px' width='60%' /></div>
                                                    </>
                                                    :
                                                        flowData?.[0]?.description 
                                                        ? flowData?.[0]?.description 
                                                        : 'No description has been provided for the following flow yet'
                                                }
                                            </p>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span title={flowData?.[0]?.name} className='mb-0 fontSizeHeading text-uppercase fontInterSemiBold top-heading labs text-with-ellipsis'>
                                                    {
                                                        flowLoading
                                                        ?
                                                        <div className=''><CustomSkeleton height='18px' width='60%' /></div>
                                                        :
                                                        flowData?.[0]?.name ?? '[No Flow Present]'
                                                    }
                                                </span>
                                                <ICON_FLOWS />
                                            </div>
                                        </div>
                                        <div className='dp-mod-card-2'
                                            onClick={() => {
                                                handleClick('lab')
                                            }}
                                        >
                                            <p className='fontSizeLabel mb-1' style={{height: 33}}></p>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span title={flowData?.[1]?.name ?? ''} className='mb-0 text-uppercase mod-card-label text-with-ellipsis'>
                                                    {
                                                        dashLoading
                                                        ?
                                                        <div className=''><CustomSkeleton height='15px' width='60%' /></div>
                                                        :
                                                        flowData?.[1]?.name ?? ''
                                                    }
                                                </span>
                                                <ICON_FLOWS />
                                            </div>
                                        </div>
                                        <div className='dp-mod-card-3'
                                            onClick={() => {
                                                handleClick('lab')
                                            }}
                                        >
                                            <p className='fontSizeLabel mb-1' style={{height: 33}}></p>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span title={flowData?.[2]?.name ?? ''} className='mb-0 text-uppercase mod-card-label text-with-ellipsis'>
                                                    {
                                                        dashLoading
                                                        ?
                                                        <div className=''><CustomSkeleton height='15px' width='60%' /></div>
                                                        :
                                                        flowData?.[2]?.name ?? ''
                                                    }
                                                </span>
                                                <ICON_FLOWS />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div></div>
                                <div>
                                    <div className={`dp-modules ${activeAnimStep === 2 && !hideAnim ? 'active' : ''}`}

                                        onMouseEnter={() => {
                                            setHideAnim(true)
                                        }}
                                        onMouseLeave={() => {
                                            setHideAnim(false)
                                        }}

                                        // onMouseEnter={() => {
                                        //     setStartAnim(false)
                                        // }}
                                        // onMouseLeave={() => {
                                        //     setStartAnim(true)
                                        //     anim()
                                        // }}
                                    >
                                    <div className='dp-mod-indicator pulse'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <span className='mod-ic d-flex'>
                                                    <ICON_DASHBOARD color='currentColor'/>
                                                </span>
                                                <span className='mod-pri-title fontInterSemiBold'>
                                                    PULSE
                                                </span>
                                            </div>
                                            <div className='text-center'>
                                                <span className='mod-sec-title'>
                                                    ANALYZE
                                                </span>
                                            </div>
                                            <div>
                                                <div className='dp-indicator-joiner'></div>
                                            </div>
                                        </div>
                                        <div className='dp-mod-card-1'
                                            onClick={() => {
                                                handleClick('pulse')
                                            }}
                                        >
                                            <div className={`dp-pipline-pipe1 pipeline-anim ${activeAnimStep === '2-2' && !hideAnim ? 'animate' : ''}`}></div>

                                            {/* <div className='dp-pipline-pipe3'>
                                                {ConnectorIconTwo(
                                                    activeAnimStep === '2-0-1' ? activePipeColor : undefined,
                                                    activeAnimStep === '2-1-0' ? activePipeColor : undefined 
                                                )}
                                            </div> */}
                                            <p className='fontSizeLabel mb-1 custom-ellipsis two-line-ellipsis'>
                                                {
                                                    dashLoading
                                                    ?
                                                    <>
                                                        <div className='mb-1'><CustomSkeleton height='10px' width='100%' /></div>
                                                        <div className='mb-1'><CustomSkeleton height='10px' width='60%' /></div>
                                                    </>
                                                    :
                                                        dashData?.[0]?.description 
                                                        ? dashData?.[0]?.description
                                                        : 'No description has been provided for the following dashboard yet'
                                                }
                                            </p>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span title={dashData?.[0]?.name ?? ''} className='mb-0 text-uppercase fontSizeHeading fontInterSemiBold top-heading pulse text-with-ellipsis'>
                                                    {
                                                        dashLoading
                                                        ?
                                                        <div className=''><CustomSkeleton height='18px' width='60%' /></div>
                                                        :
                                                        dashData?.[0]?.name ?? '[No Dashboard Present]'
                                                    }
                                                </span>
                                                <ICON_DASHBOARD />
                                            </div>
                                        </div>
                                        <div className='dp-mod-card-2'
                                            onClick={() => {
                                                handleClick('pulse')
                                            }}
                                        >
                                            <p className='fontSizeLabel mb-1' style={{height: 33}}></p>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span title={dashData?.[1]?.name ?? ''} className='mb-0 text-uppercase mod-card-label text-with-ellipsis'>
                                                    {
                                                        dashLoading
                                                        ?
                                                        <div className=''><CustomSkeleton height='15px' width='60%' /></div>
                                                        :
                                                        dashData?.[1]?.name ?? ''
                                                    }
                                                </span>
                                                <ICON_DASHBOARD />
                                            </div>
                                        </div>
                                        <div className='dp-mod-card-3'
                                            onClick={() => {
                                                handleClick('pulse')
                                            }}
                                        >
                                            <p className='fontSizeLabel mb-1' style={{height: 33}}></p>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span title={dashData?.[2]?.name ?? ''} className='mb-0 text-uppercase mod-card-label text-with-ellipsis'>
                                                    {
                                                        dashLoading
                                                        ?
                                                        <div className=''><CustomSkeleton height='15px' width='60%' /></div>
                                                        :
                                                        dashData?.[2]?.name ?? ''
                                                    }
                                                </span>
                                                <ICON_DASHBOARD />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default StartPage

const SeeMoreText = ({
    text
}) => {

    const [showMoreButton, setShowMoreButton] = React.useState(false)
    const [showMore, setShowMore] = React.useState(false)
    
    const paraRef = React.useRef(null)
    const spanRef = React.useRef(null)

    React.useEffect(() => {
        if(paraRef?.current && spanRef?.current) {
            const para = paraRef.current
            const span = spanRef.current
            // console.log({text, para: para?.offsetHeight, span: span?.offsetHeight})
            if(span.offsetHeight > para?.offsetHeight) {
                setShowMoreButton(true)
            }
        }
    }, [text])


    return <p ref={paraRef} className='asked-quest position-relative custom-ellipsis fontSizeHeading mb-0 two-line-ellipsis' 
        style={showMore ? { WebkitLineClamp: 'unset' } : {}}>
            <span ref={spanRef}>{text}</span>
            {
                showMoreButton
                ?
                <span className={`bg-white ${showMore ? '' : 'pl-1 position-absolute'} d-flex`} style={{bottom: 0, right: 0}}>
                    {
                        showMore ? '' :
                        <span className='text-hide-gradient'></span>
                    }
                    { showMore ? '' : '...' }
                    <span className='fontInterSemiBold see-more-button'
                        onClick={(e) => {
                            e?.stopPropagation()
                            setShowMore(!showMore)
                        }}
                    >
                        {
                            showMore ? 'show less' : 'see more'
                        }
                        </span>
                </span>
                :   ''
            }
        </p>
}

const ConnectorIconOne = (
    color1 = "#EEEEEE",
    color2 = "#EEEEEE",
) => {
    return <svg width="93" height="11" viewBox="0 0 93 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="87.5" cy="5.5" r="4.75" fill="white" stroke="#DEDEDE" strokeWidth="1.5"/>
        <circle cx="5.5" cy="5.5" r="4.75" fill="white" stroke="#DEDEDE" strokeWidth="1.5"/>
        <path d="M11 6H46" stroke={color1} strokeWidth="2"/>
        <path d="M46 6H82.0039" stroke={color2} strokeWidth="2"/>
    </svg>
}

const ConnectorIconTwo = (
    color1 = "#EEEEEE",
    color2 = "#EEEEEE",
) => {
    return <svg width="134" height="57" viewBox="0 0 134 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.2858 5.5C10.2858 8.12105 8.15346 10.25 5.51792 10.25C2.88237 10.25 0.75 8.12105 0.75 5.5C0.75 2.87895 2.88237 0.75 5.51792 0.75C8.15346 0.75 10.2858 2.87895 10.2858 5.5Z" fill="white" stroke="#DEDEDE" strokeWidth="1.5"/>
        <path d="M133 51H89C81.268 51 75 44.732 75 37V29V20C75 12.268 68.732 6 61 6H11" stroke={color2} strokeWidth="2"/>
        <path d="M75 32V20C75 12.268 68.732 6 61 6H11" stroke={color1} stroke-width="2"/>
        <path d="M132.286 51.5C132.286 54.1211 130.153 56.25 127.518 56.25C124.882 56.25 122.75 54.1211 122.75 51.5C122.75 48.8789 124.882 46.75 127.518 46.75C130.153 46.75 132.286 48.8789 132.286 51.5Z" fill="white" stroke="#DEDEDE" strokeWidth="1.5"/>
    </svg>
}