import React from 'react'
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader'
import useHideSidebar from '../../../../customHooks/useHideSidebar'
import SplitPane from 'split-pane-react/esm/SplitPane'
import '../genAi.scss'
import { Avatar, Badge, Checkbox, Divider, Input, Popover, Radio, Tabs, Tooltip } from 'antd'
import { ICON_ARROW_LEFT, ICON_BAR_CHART, ICON_CHECK, ICON_CLOSE, ICON_DELETE, ICON_EDITBOX, ICON_FILTER, ICON_GRAPH_AREA, ICON_GRAPH_BAR, ICON_GRAPH_COLUMN, ICON_GRAPH_FUNNEL, ICON_GRAPH_KPI, ICON_GRAPH_LINE, ICON_GRAPH_MULTIBAR, ICON_GRAPH_MULTICOLUMN, ICON_GRAPH_MULTILINE, ICON_GRAPH_PIE, ICON_GRAPH_SCATTER, ICON_GRAPH_STACKEDAREA, ICON_GRAPH_STACKEDBAR, ICON_GRAPH_STACKEDCOLUMN, ICON_GRAPH_WORDCLOUD, ICON_GRAPH_WORDCLOUD_W, ICON_LIKE, ICON_LOADING_CIRCLE, ICON_PLUS, ICON_RESET, ICON_SEARCH, ICON_TABLE, ICON_UNLIKE, ICON_VERIFIED } from '../../../Common/newIconSource'
import { Literals } from '../../common/literals'
import Buttons from '../../../Common/button/Buttons'
import ChatContentGraph from './ChatContentGraph'
import ChatContentPreviewTable from './ChatContentPreviewTable'
import TalkDataTraceSql from './TalkDataTraceSql'
import CommonModal from '../../../Common/CommonModal/CommonModal'
import { useDispatch } from 'react-redux'
import { getConversationChartFromQuery, getFeedbackDomainCount, getTalkDataFeedback, verifyConversation } from '../../../../store/modules/common/GenAi/GenAiReducer'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { getConfirmationModal, getJourneyTime, getMonthName, getObjectLength, getQueryParams, getReloadErrorTemplate, showVerticalBarLoader } from '../../common/helperFunctions'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { useLocation, useNavigate } from 'react-router-dom'
import { ContentViewGridTable } from '../../../Common/contentGridTable/ContentViewGridTable'
import PulseSearchBox from '../../../Common/mainLandingPage/components/PulseSearchBox'
import { getCatalogListing } from '../../../../store/actions'
import _ from 'lodash'
import DebounceInput from '../../../Common/debounceInput/DebounceInput'


const emojiMap = {
    sad: 128532,
    happy: 128515
}

const TalkDataTrace = React.forwardRef((props ,ref) => {

    const dispatch = useDispatch()
    const location = useLocation()
    // const history = useNavigate()

    // const {dm: domainId} = getQueryParams(location.search)

    const [domainId, setDomainId] = React.useState(props?.parentDomain)
    const [sizes, setSizes] = React.useState(["50%", "50%"])
    const [verticalSizes, setVerticalSizes] = React.useState(["26%", "auto"])
    const [activeTabKey, setActiveTabKey] = React.useState('0')
    const [activeToggle, setActiveToggle] = React.useState('graph')
    const [feedFilterOpen, setFeedFilterOpen] = React.useState(false)
    const [variationModalOpen, setVariationModalOpen] = React.useState(false)
    const [loadingFeeds, setLoadingFeeds] = React.useState(false)
    const [feedError, setFeedError] = React.useState(undefined)
    const [feedbacks, setFeedbacks] = React.useState([])
    const [activeFeedId, setActiveFeedId] = React.useState('')
    const [query, setQuery] = React.useState('')
    const [chartError, setChartError] = React.useState(undefined)
    const [chartType, setChartType] = React.useState('bar')
    const [chartData, setChartData] = React.useState({})
    const [hideGraph, setHideGraph] = React.useState(false)
    const [chartLoader, setChartLoader] = React.useState(false)
    const [activeUUID, setActiveUUID] = React.useState('')
    const [graphData, setGraphData] = React.useState({})
    const [questVariations, setQuestVariations] = React.useState([])
    const [newQuestVar, setNewQuestVar] = React.useState('')
    const [staticQuery, setStaticQuery] = React.useState('')
    const [isChartReady, setChartReady] = React.useState(true)
    const [feedbackFilter, setFeedbackFilter] = React.useState({satisfied: false, not_satisfied: false})
    const [feedCount, setFeedCount] = React.useState(undefined)
    const [feedPageNo, setFeedPageNo] = React.useState(1)
    const [loadMoreFeeds, setLoadMoreFeeds] = React.useState(false)
    const [stopFeedsPagination, setStopFeedsPagination] = React.useState(false)
    const [confirmOpen, setConfirmOpen] = React.useState(false)
    const [currFeedDetails, setCurrFeedDetails] = React.useState({})
    const [domainList, setDomainList] = React.useState([])
    const [loadingDomains, setLoadingDomains] = React.useState(false)
    const [emptyPending, setEmptyPending] = React.useState(undefined)
    const [pubTables, setPubTables] = React.useState([])
    const [pubTablesLoading, setPubTablesLoading] = React.useState(false)
    const [pubTablesEmpty, setPubTablesEmpty] = React.useState(false)
    const [sqlTableDetails, setSqlTableDetails] = React.useState(false)
    const [feedSearchData, setFeedSearchData] = React.useState("")
    const [feedSearchOpen, setFeedSearchOpen] = React.useState(false)
    const [variationLoading, setVariationLoading] = React.useState(false)

    /**
     * States ending with suffix "Ver" is for Verified Feedback Data
     */
    const [feedbacksVer, setFeedbacksVer] = React.useState([])
    const [feedCountVer, setFeedCountVer] = React.useState(undefined)
    const [feedErrorVer, setFeedErrorVer] = React.useState(undefined)
    const [loadingFeedsVer, setLoadingFeedsVer] = React.useState(false)
    const [feedPageNoVer, setFeedPageNoVer] = React.useState(1)
    const [loadMoreFeedsVer, setLoadMoreFeedsVer] = React.useState(false)
    const [stopFeedsPaginationVer, setStopFeedsPaginationVer] = React.useState(false)
    const [emptyVerified, setEmptyVerified] = React.useState(undefined)
    const [renderSearchInp, setRenderSearchInp] = React.useState(true)

    const selectedRef = React.useRef({})
    const searchRef = React.useRef("")

    React.useImperativeHandle(ref, () => {
        // Future Use
        return {}
    }, [])

    useHideSidebar(true)

    React.useEffect(() => {
        fetchDomains()

        if(window.screen.availWidth <= 1060) {
            setVerticalSizes(["280px", "auto"])
        }
    }, [])

    React.useEffect(() => {
        setDomainId(props?.parentDomain)
    }, [props?.parentDomain])

    React.useEffect(() => {
        if(![undefined, null, ""]?.includes(domainId ?? "")) {
            if (feedPageNo !== 1) {
                setFeedPageNo(1)
            }
            if (feedPageNoVer !== 1) {
                setFeedPageNoVer(1)
            }
            setLoadingFeeds(true)
            fetchFeedbacks(true)
    
            setLoadingFeedsVer(true)
            fetchVerifiedFeedbacks(true)
    
            selectedRef.current = {}
    
            fetchPublishedPods()
        }
    }, [domainId])

    React.useEffect(() => {
        if(activeFeedId !== "") {
            setChartData({})
            fetchConversationChart()
        }
    }, [activeFeedId])

    React.useEffect(() => {
        if(!loadingFeeds && !loadMoreFeedsVer) {
            let arr = [...feedbacks, ...feedbacksVer]
            if(arr?.length > 0) {
                // let loc = getQueryParams(location.search)
                let obj = {}
                // let obj = getObjectLength(selectedRef?.current) > 0 ? selectedRef?.current : arr?.[0]

                if(
                    getObjectLength(selectedRef?.current) > 0 
                    // && selectedRef?.current?.conversation_detail?.data_domain?.toString() === loc?.dm
                ) {
                    obj = selectedRef?.current
                }
                else {
                    if(arr?.every(f => f?.is_verified)) {
                        setActiveTabKey('1')
                    }
                    obj = arr?.[0]
                }
                
                setActiveFeedId(obj?.conversation_detail?.id)
                setActiveUUID(obj?.conversation_detail?.uuid)
                setCurrFeedDetails(obj)

                let questVar = obj?.question_variation ?? []
                questVar = questVar?.map(q => ({question: q, edit: false, loading: false}))

                setQuestVariations(questVar?? [])
                setQuery(obj?.conversation_detail?.query ?? "")
                setStaticQuery(obj?.conversation_detail?.query ?? "")

                if(getObjectLength(selectedRef?.current) === 0) {
                    selectedRef.current = obj
                }
                else if(
                    getObjectLength(searchRef?.current) > 0
                    && selectedRef?.current?.conversation_detail?.data_domain?.toString() !== location.search?.dm
                ) {
                    selectedRef.current = obj
                }
            }
        }
    }, [loadingFeeds, loadMoreFeedsVer, feedbacks, feedbacksVer])

    const fetchDomains = () => {
        setLoadingDomains(true)
        dispatch(getFeedbackDomainCount(false, false, false)).then(
            res => {
                if(res?.data?.length) {
                    setDomainList([...res?.data])
                }
                setLoadingDomains(false)
            }
        )
        .catch(() => {
            setLoadingDomains(false)
        })
    }

    const fetchFeedbacks = (reset = false, activeTab = "0") => {
        setEmptyPending(undefined)
        setFeedError(undefined)
        setStopFeedsPagination(false)
        const pageNumber = reset ? 1 : feedPageNo
        const pageLimit = 10
        const search = searchRef?.current?.trim() ?? ''
        dispatch(getTalkDataFeedback(
            pageLimit,
            pageNumber,
            domainId, 
            search,
            false, 
            false
        ))
        .then((res) => {
            let dat = res?.data?.results
            setEmptyPending(dat?.length === 0)
            setFeedCount(res?.data?.count)
            if(dat) {
                setActiveTabKey(activeTab)
                setFeedbacks(prev => reset ? [...dat] : [...prev, ...dat])

                if (res?.data?.has_next === false) {
                    setStopFeedsPagination(true)
                    setLoadMoreFeeds(false)
                }
            }
            if (res?.status === "success") {
                setLoadMoreFeeds(false)
                setFeedPageNo(prev => prev + 1)
            }
            setLoadingFeeds(false)
        })
        .catch((err) => {
            setFeedError(err?.message)
            setLoadingFeeds(false)
            setLoadMoreFeeds(false)
            setEmptyPending(false)
        })
    }

    const fetchVerifiedFeedbacks = (reset = false) => {
        setEmptyVerified(undefined)
        setFeedErrorVer(undefined)
        setStopFeedsPaginationVer(false)
        const pageNumber = reset ? 1 : feedPageNoVer
        const pageLimit = 10
        const search = searchRef?.current?.trim() ?? ''
        dispatch(getTalkDataFeedback(
            pageLimit,
            pageNumber,
            domainId, 
            search,
            true, 
            false
        ))
        .then((res) => {
            let dat = res?.data?.results
            setEmptyVerified(dat?.length === 0)
            setFeedCountVer(res?.data?.count)
            if(dat) {
                setFeedbacksVer(prev => reset ? [...dat] : [...prev, ...dat])

                if (res?.data?.has_next === false) {
                    setStopFeedsPaginationVer(true)
                    setLoadMoreFeedsVer(false)
                }
            }
            if (res?.status === "success") {
                setLoadMoreFeedsVer(false)
                setFeedPageNoVer(prev => prev + 1)
            }
            setLoadingFeedsVer(false)
        })
        .catch((err) => {
            setFeedErrorVer(err?.message)
            setLoadingFeedsVer(false)
            setLoadMoreFeedsVer(false)
            setEmptyVerified(false)
        })
    }

    const fetchConversationChart = (qry) => {

        if(
            (query && query?.trim() === '')
            || activeFeedId === ''
        ) return

        setChartError(undefined)
        setChartLoader(true)   

        let payload = {
            query: qry ?? query
        }
        dispatch(getConversationChartFromQuery(activeFeedId, payload, false))
        .then(res => {
            let dat = res?.data
            dat.pod_detail = _.uniqBy(
                [
                    ...dat.pod_detail, 
                    ...pubTables?.filter(t => t?.checked)
                    ?.map(p => (
                        {
                            data_domain_id: p?.data_domain_detail?.data_domain_id,
                            data_domain_name: p?.data_domain_detail?.data_domain_name,
                            domain_table: `${p?.data_domain_detail?.data_domain_name}.${p?.table_name}`,
                            table_id: p?.table_id,
                            table_name: p?.table_name
                        }
                    ))
                ]
            , 'table_id')
            
            setGraphData(dat)

            let tbls = [...pubTables]
            tbls = tbls?.map(t => ({
                ...t, 
                checked: res?.data?.pod_detail?.map(p => p?.table_id)?.includes(t?.table_id) ? true : t?.checked
            }))
            setPubTables(tbls ?? [])

            setChartType(res?.data?.selected_chart?.toLowerCase())
            setChartData({
                ...res?.data?.chart_payload,
                type: res?.data?.selected_chart,
                possible_chart: res?.data?.possible_chart
            })
            setHideGraph(res?.data?.selected_chart?.toLowerCase() === 'table')
            if(res?.data?.selected_chart?.toLowerCase() === 'table') {
                setActiveToggle('table')
            }
            else setActiveToggle('graph')

            if(res?.data?.error_msg !== null && res?.data?.error_msg !== undefined) {
                setChartError(res?.data?.error_msg)
            }

            setChartLoader(false)
        })
        .catch(err => {
            setChartError(err?.message)
            setChartLoader(false)
        })
    }

    const fetchPublishedPods = () => {
        setPubTablesEmpty(false)
        setPubTablesLoading(true)
        dispatch(getCatalogListing(domainId, 'metadata', true, false))
        .then(res => {
            // let gData = g_data ?? graphData
            let tbls = res?.data
            tbls = tbls?.map(t => ({
                ...t, 
                checked: graphData?.pod_detail?.map(p => p?.table_id)?.includes(t?.table_id)
                // checked: getObjectLength(gData) === 0 ? false : gData?.pod_detail?.map(p => p?.table_id)?.includes(t?.table_id)
            }))

            let allColumns = []
            tbls?.forEach((d) => {
                let cols = d?.column_detail?.map(t => (
                    {
                        name: `"${t?.column_name}"`,
                        description: "",
                        type: "Column",
                        tableName: t?.table_name
                    }))
                allColumns = [...allColumns, ...cols]
            })

            allColumns = _.uniqBy(allColumns, 'name')

            let allTables = tbls?.map(t => (
                {
                    name: `${t?.data_domain_detail?.data_domain_name}.${t?.table_name}`,
                    description: "",
                    type: "Table",
                    tableName: ""
                }))

            let infoArray = [...allColumns, ...allTables]

            setSqlTableDetails([...infoArray])

            setPubTables(tbls ?? [])
            setPubTablesEmpty(res?.data?.length === 0)
            setPubTablesLoading(false)
        })
        .catch((err) => {
            setPubTablesEmpty(false)
            setPubTablesLoading(false)
        })
    }

    const tabItems = [
        {
          key: '0',
          label: <>Pending 
                <Badge 
                    size='small' 
                    count={feedCount} 
                    style={{marginLeft: 4}}
                /> 
            </>
        },
        {
          key: '1',
          label: <>Verified 
                <Badge 
                    style={{marginRight: 1, marginLeft: 4}} 
                    size='small' 
                    count={feedCountVer} 
                /> 
            </>
        }
    ];

    const chatFeedItem = (f) => {

        const readableDate = () => {
            let date = new Date(f?.updated_on)
            return `${date?.getDate()} ${getMonthName.short[date.getMonth()]} ${date?.getFullYear()}`
        }

        return <>
            <div className={`chat-feed-item ${f?.conversation_detail?.id === activeFeedId ? 'active' : ''}`}
                onClick={() => {

                    if(f?.conversation_detail?.id === currFeedDetails?.conversation_detail?.id) return

                    if(chartLoader) {
                        emitToastNotification('info', "Please wait while chart is loading")
                        return
                    }
                    
                    selectedRef.current = f

                    if(query !== staticQuery) {
                        setConfirmOpen(true)
                        return 
                    }
                    
                    setActiveToggle('graph')
                    setActiveFeedId(f?.conversation_detail?.id)
                    setActiveUUID(f?.conversation_detail?.uuid)
                    let questVar = f?.question_variation ?? []
                    questVar = questVar?.map(q => ({question: q, edit: false, loading: false}))
                    setQuestVariations(questVar?? [])
                    setQuery(f?.conversation_detail?.query ?? "")
                    setStaticQuery(f?.conversation_detail?.query ?? "")

                    setPubTables(prev => {
                        prev = prev?.map(p => ({...p, checked: false}))
                        return prev
                    })

                    setCurrFeedDetails(f)
                }}
            >
                <div className='upper'>
                    <div>
                        <Tooltip 
                            title={
                                f?.updated_by_user?.first_name
                                ?
                                `${f?.updated_by_user?.first_name} ${f?.updated_by_user?.last_name}`
                                :   'Unnamed User'
                            } 
                            placement="topLeft">
                                <span style={{lineHeight: 1.4, maxWidth: 50}} 
                                className='fontSizeHeading fontInterSemiBold text-dark text-capitalize d-block pt-1 text-with-ellipsis'>
                                {
                                    f?.updated_by_user?.first_name
                                    ?
                                    f?.updated_by_user?.first_name
                                    :   'Unnamed'
                                }
                                </span>
                            {/* <Avatar
                                size={'small'}
                                className='fontInterSemiBold text-uppercase'
                                style={{
                                    backgroundColor: 'rgb(' + [
                                            Math.round(Math.random() * 160),
                                            Math.round(Math.random() * 160),
                                            Math.round(Math.random() * 160)
                                        ].join(',') + ')',
                                }}
                            >
                                {f?.updated_by_user?.first_name?.charAt(0)}
                                {f?.updated_by_user?.last_name?.charAt(0)}
                            </Avatar> */}
                        </Tooltip>
                    </div>
                    <div className="vertical-separator position-relative" 
                        style={{
                            height: 18, 
                            width: 2,
                            margin: '0 2px',
                            top: 3
                        }}
                        ></div>
                    <Tooltip placement='right' title={f?.conversation_detail?.question}>
                        <div className='feed-quest fontSizeHeading pt-1'>
                            {f?.conversation_detail?.question}
                        </div>
                    </Tooltip>
                    {/* <div>
                        <Tooltip placement='right' title={f?.is_verified ? 'Verified' : 'Not Verified'}>
                            <span>
                                <ICON_VERIFIED 
                                    color={f?.is_verified ? '#03A932' : '#d0d0d0'} 
                                    height='22' 
                                    width='22' 
                                />
                            </span>
                        </Tooltip>
                    </div> */}
                </div>
                <div className='lower'>
                    <div>
                        <span className='chat-feed-emoji'>
                            {
                                f.feedback_type === 1 
                                ? <ICON_LIKE height='20' width='20' color='#098925' />
                                : <ICON_UNLIKE height='20' width='20' color='#B10F0F' />
                            }
                            {/* <span dangerouslySetInnerHTML={{__html: `&#${emojiMap[f.feedback_type === 1 ? 'happy' : 'sad']};`}}></span> */}
                        </span>
                    </div>
                    {
                        !f?.comment || f.comment === ''
                        ?   <div className='small text-black-50' style={{lineHeight: 1.4}}>No Comment</div>
                        :
                        <Tooltip placement='right' title={f?.comment}>
                            <div className='feed-det'>
                                {/* <strong className='mr-1 fontInterSemiBold'>{f.feedback_type === 1 ? 'Satisfied' : 'Not Satisfied'}:</strong> */}
                                {f.comment}
                            </div>
                        </Tooltip>
                    }
                </div>
                <div className='feed-chat-time text-dark fontInterSemiBold text-right'>
                    {readableDate()}, {getJourneyTime(f?.updated_on)}
                </div>
                {
                    // f?.conversation_detail?.id === activeFeedId ? <div className='chat-feed-active'></div>
                    // :   ''
                }
            </div>
        </>
    }

    const listItems1 = React.useMemo(() => {
        let list = [...feedbacks]

        if(!(feedbackFilter.satisfied && feedbackFilter.not_satisfied)) {
            list = list.filter(f => {
                if(feedbackFilter.satisfied) {
                    return (
                        f?.feedback_type?.toString() === '1'
                    )
                }
                if(feedbackFilter.not_satisfied) {
                    return (
                        f?.feedback_type?.toString() === '-1'
                    )
                }
                return true
            })
        }
        
        return list.map((f, i) => (
            <div key={`feed-${i}`} className='position-relative px-1 pt-1 pb-2' data={f}>
                {chatFeedItem(f)}
                {
                    <div className='septor'></div>
                }
            </div>
        ))
    }, [
        feedbacks,
        chartLoader, 
        feedbackFilter,
        staticQuery,
        query
    ])

    const listItems2 = React.useMemo(() => {
        let list = [...feedbacksVer]

        if(!(feedbackFilter.satisfied && feedbackFilter.not_satisfied)) {
            list = list.filter(f => {
                if(feedbackFilter.satisfied) {
                    return (
                        f?.feedback_type?.toString() === '1'
                    )
                }
                if(feedbackFilter.not_satisfied) {
                    return (
                        f?.feedback_type?.toString() === '-1'
                    )
                }
                return true
            })
        }
        
        return list.map((f, i) => (
            <div key={`feed-${i}`} className='position-relative px-1 pt-1 pb-2'>
                {chatFeedItem(f)}
                {
                    <div className='septor'></div>
                }
            </div>
        ))
    }, [
        feedbacksVer,
        chartLoader, 
        feedbackFilter,
        staticQuery,
        query
    ])

    const chartsList = React.useMemo(() => {
        let list = [
            {name: 'Bar', type: 'bar', icon: <ICON_GRAPH_BAR/>, active: true, disabled: false},
            {name: 'Line', type: 'line', icon: <ICON_GRAPH_LINE/>, active: false, disabled: false},
            {name: 'Pie', type: 'pie', icon: <ICON_GRAPH_PIE/>, active: false, disabled: false},
            {name: 'Funnel', type: 'funnel', icon: <ICON_GRAPH_FUNNEL/>, active: false, disabled: false},
            {name: 'Column', type: 'column', icon: <ICON_GRAPH_COLUMN/>, active: false, disabled: false},
            {name: 'Area', type: 'area', icon: <ICON_GRAPH_AREA/>, active: false, disabled: false},
            {name: 'Multi Line', type: 'multiline', icon: <ICON_GRAPH_MULTILINE/>, active: false, disabled: false},
            {name: 'Multi Bar', type: 'multibar', icon: <ICON_GRAPH_MULTIBAR/>, active: false, disabled: false},
            {name: 'Multi Column', type: 'multicolumn', icon: <ICON_GRAPH_MULTICOLUMN/>, active: false, disabled: false},
            {name: 'Stacked Column', type: 'stackedcolumn', icon: <ICON_GRAPH_STACKEDCOLUMN/>, active: false, disabled: false},
            {name: 'Stacked Bar', type: 'stackedbar', icon: <ICON_GRAPH_STACKEDBAR/>, active: false, disabled: false},
            {name: 'Stacked Area', type: 'stackedarea', icon: <ICON_GRAPH_STACKEDAREA/>, active: false, disabled: false},
            {name: 'Scatter', type: 'scatter', icon: <ICON_GRAPH_SCATTER/>, active: false, disabled: false},
            {name: 'KPI', type: 'kpi', icon: <ICON_GRAPH_KPI/>, active: false, disabled: false},
            {name: 'Word Cloud', type: 'wordcloud', icon: <ICON_GRAPH_WORDCLOUD/>, active: false, disabled: false},
        ]
        
        list = list?.map(v => (
            {
                ...v, 
                active: chartType === v?.type, 
                disabled: !chartData?.possible_chart?.map(v => v?.toLowerCase())?.includes(v?.type)
            }))
        return list
    }, [chartType, chartData])

    const onChange = (key) => {
        setActiveTabKey(key)
    };

    const getActiveListItem = () => {
        if(activeTabKey === "0")
        return listItems1   // Pending
        return listItems2   // Verified
    }

    const getLoadMore = () => {
        if(activeTabKey === "0")
        return loadMoreFeeds   // Pending
        return loadMoreFeedsVer   // Verified
    }

    const getStopPagination = () => {
        if(activeTabKey === "0")
        return stopFeedsPagination   // Pending
        return stopFeedsPaginationVer   // Verified
    }

    const isFeedbackLoading = () => {
        if(activeTabKey === "0")
        return loadingFeeds   // Pending
        return loadingFeedsVer   // Verified
    }

    const getError = () => {
        if(activeTabKey === "0")
        return feedError   // Pending
        return feedErrorVer   // Verified
    }

    const onScrollToBottom = (paginate) => {
        if (!getStopPagination() && !isFeedbackLoading()) {
            if (paginate && !getLoadMore()) {
                if(activeTabKey === "0") {
                    setLoadMoreFeeds(true)
                    fetchFeedbacks()
                }
                else {
                    setLoadMoreFeedsVer(true)
                    fetchVerifiedFeedbacks()
                }
            }
        }
    }

    return (
        <div className='talk-index-main talk-trace-wrapper'>
            {/* <LandingpageFirstHeader 
                title={'Clarista'} 
                isPulse={true}
                activeDomain={domainId}
                showFeedNotification={true}
            /> */}
            <div className='trace-talk-content-wrap'>
                <PulseSearchBox
                    activeDomain={domainId}
                    hideSearchBar={true}
                />
                <div className='h-100 w-100 trace-ver-frame'>
                    <SplitPane
                        split='vertical'
                        sizes={verticalSizes}
                        onChange={setVerticalSizes}
                        allowResize={false}
                    >
                        <div style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'start',
                                    background: '#FFF',
                                    width: '100%'
                                }}>
                            <div className='trace-li-sect'>
                                <Tabs
                                    className='new-custom-tabs res-tab mt-1'
                                    defaultActiveKey="1"
                                    activeKey={activeTabKey}
                                    items={tabItems}
                                    onChange={onChange}
                                    tabBarExtraContent={<>
                                        <div className='d-flex align-items-center'>
                                                <div className='feed-search-inp-pop p-1'
                                                    style={{transform: feedSearchOpen ? 'scale(1)' : 'scale(0)', transition: '0.3s'}}
                                                >
                                                {
                                                    renderSearchInp
                                                    ?
                                                    <DebounceInput
                                                        key={`${feedSearchOpen ? 'feed-sch-dbinp' : 'db-inp'}`}
                                                        onDebounce={(val) => {
                                                            setActiveToggle('graph')
                                                            searchRef.current = val?.toLowerCase()
                                                            setFeedSearchData(val)
                                                            if (feedPageNo !== 1) {
                                                                setFeedPageNo(1)
                                                            }
                                                            setLoadingFeeds(true)
                                                            fetchFeedbacks(true, activeTabKey)
                                                        
                                                            if (feedPageNoVer !== 1) {
                                                                setFeedPageNoVer(1)
                                                            }
                                                            setLoadingFeedsVer(true)
                                                            fetchVerifiedFeedbacks(true)
                                                        }}
                                                        type="text"
                                                        placeholder='Search here...'
                                                        className='custom-input-field w-100'
                                                        defaultValue={feedSearchData}
                                                        autoFocus={true}
                                                    />
                                                    :
                                                    <DebounceInput
                                                        type="text"
                                                        placeholder='Search here...'
                                                        className='custom-input-field w-100'
                                                    />
                                                }
                                                <span 
                                                title='Clear'
                                                onClick={() => {
                                                    setActiveToggle('graph')
                                                    setFeedSearchData("")
                                                    searchRef.current = ""
                                                    if (feedPageNo !== 1) {
                                                        setFeedPageNo(1)
                                                    }
                                                    setLoadingFeeds(true)
                                                    fetchFeedbacks(true, activeTabKey)
                                                
                                                    if (feedPageNoVer !== 1) {
                                                        setFeedPageNoVer(1)
                                                    }
                                                    setLoadingFeedsVer(true)
                                                    fetchVerifiedFeedbacks(true)

                                                    setRenderSearchInp(false)
                                                    setTimeout(() => setRenderSearchInp(true), 500)
                                                }}
                                                className='d-flex align-items-center justify-content-center'
                                                style={{
                                                    cursor: 'pointer',
                                                    width: 12, height: 12, padding: 3, 
                                                    borderRadius: '100%',
                                                    background: '#cecece',
                                                    transition: '.3s',
                                                    transform: feedSearchData !== "" ? 'scale(1)' : 'scale(0)'
                                                }}>
                                                    <ICON_CLOSE height='10' width='10' color='#FFF' />
                                                </span>
                                                <Buttons
                                                    props={{
                                                        buttonText: "",
                                                        tooltip: "",
                                                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon ml-1`,
                                                        buttonEvent: () => {
                                                            setFeedSearchOpen(false)
                                                        },
                                                        ImgSrc: () => <ICON_CLOSE/>,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                    }}
                                                />
                                            </div>
                                            <Buttons
                                                props={{
                                                    buttonText: "",
                                                    tooltip: "",
                                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2 ${feedSearchOpen || feedSearchData ? 'active' : ''}`,
                                                    buttonEvent: () => {
                                                        setFeedSearchOpen(true)
                                                    },
                                                    ImgSrc: () => <ICON_SEARCH/>,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                }}
                                            />
                                            <Popover 
                                                trigger={'click'} 
                                                placement="bottomRight" 
                                                title={
                                                    <div className='d-flex fontInterSemiBold fontSizeHeading 
                                                    align-items-center justify-content-between p-2'>
                                                        Filter
                                                        <div className='d-flex'>
                                                            <Buttons
                                                                props={{
                                                                    buttonText: "",
                                                                    tooltip: "",
                                                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon mr-2`,
                                                                    buttonEvent: () => {
                                                                        setFeedbackFilter({satisfied: false, not_satisfied: false})
                                                                    },
                                                                    ImgSrc: () => <ICON_RESET/>,
                                                                    isDisable: false,
                                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                                }}
                                                            />
                                                            <Buttons
                                                                props={{
                                                                    buttonText: "",
                                                                    tooltip: "",
                                                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon`,
                                                                    buttonEvent: () => {
                                                                        setFeedFilterOpen(false)
                                                                    },
                                                                    ImgSrc: () => <ICON_CLOSE/>,
                                                                    isDisable: false,
                                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                } 
                                                content={<>
                                                    <div className='p-2'>
                                                        <div className='d-flex align-items-center m-1'>
                                                            <Checkbox
                                                                checked={feedbackFilter.satisfied}
                                                                onChange={(e) => {
                                                                    setFeedbackFilter(prev => ({...prev, satisfied: e.target.checked}))
                                                                }}
                                                            >
                                                                <label className='mb-0 fontSizeHeading ml-1'>Satisfied</label>
                                                            </Checkbox>
                                                        </div>
                                                        <div className='d-flex align-items-center m-1'>
                                                            <Checkbox
                                                                checked={feedbackFilter.not_satisfied}
                                                                onChange={(e) => {
                                                                    setFeedbackFilter(prev => ({...prev, not_satisfied: e.target.checked}))
                                                                }}
                                                            >
                                                                <label className='mb-0 fontSizeHeading ml-1'>Not Satisfied</label>
                                                            </Checkbox>
                                                        </div>
                                                    </div>
                                                </>} 
                                                arrow={false}
                                                open={feedFilterOpen}
                                                onOpenChange={(bool) => {
                                                    setFeedFilterOpen(bool)
                                                }}
                                                >
                                                <Buttons
                                                    props={{
                                                        buttonText: "",
                                                        tooltip: "",
                                                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon 
                                                        ${feedbackFilter.satisfied || feedbackFilter.not_satisfied ? 'active' : ''}`,
                                                        buttonEvent: () => {
                                                            setFeedFilterOpen(!feedFilterOpen)
                                                        },
                                                        ImgSrc: () => <ICON_FILTER/>,
                                                        isDisable: loadingFeeds,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                    }}
                                                />
                                            </Popover>
                                        </div>
                                    </>}
                                />
                                <div className='trace-tab-cont'>
                                    {
                                        isFeedbackLoading() ? <ClaristaLoader/>
                                        :

                                        getError() && getError()?.length > 0
                                        ?
                                        getReloadErrorTemplate({
                                            errorMessage: getError(),
                                            onReload: () => {
                                                if(activeTabKey === "0" ) {
                                                    setFeedPageNo(1)
                                                    setLoadingFeeds(true)
                                                    fetchFeedbacks(true) 
                                                }
                                                else {
                                                    setFeedPageNoVer(1)
                                                    setLoadingFeedsVer(true)
                                                    fetchVerifiedFeedbacks(true)
                                                }
                                            }
                                        })
                                        :

                                        getActiveListItem()?.length > 0 
                                        ?   <ContentViewGridTable
                                                colsNumber={1}
                                                data={getActiveListItem()}
                                                onScrollToBottom={onScrollToBottom}
                                                loadMore={getLoadMore()}
                                                rowHeight={120}
                                            />
                                        :
                                        <div className='p-3 text-black-50 text-center'>
                                            No Feedback Yet
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{...layoutCSS}}>
                            {
                                <SplitPane
                                    split='horizontal'
                                    sizes={sizes}
                                    onChange={setSizes}
                                >
                                    <div style={{
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'start',
                                                background: '#FFF'
                                            }}>
                                        <TalkDataTraceSql
                                            setVariationModalOpen={setVariationModalOpen}
                                            data={currFeedDetails}
                                            loadingFeeds={loadingFeeds}
                                            query={query}
                                            setQuery={setQuery}
                                            executeQuery={fetchConversationChart}
                                            isQueryExecuting={chartLoader}
                                            chartData={chartData}
                                            onDeleteReload={(deletedConversationId) => {
                                                let activeTab = activeTabKey
                                                if(activeTab === '1') {
                                                    let i = 0
                                                    if(feedbacksVer?.[i]) {
                                                        if(feedbacksVer?.[i]?.conversation_detail?.id !== deletedConversationId)
                                                        selectedRef.current = feedbacksVer?.[i]
                                                        else {
                                                            if(feedbacksVer?.[i + 1])
                                                            selectedRef.current = feedbacksVer?.[i + 1]
                                                            else
                                                            activeTab = '0'
                                                        }
                                                    }
                                                }
                                                else selectedRef.current = {}

                                                setFeedPageNo(1)
                                                setLoadingFeeds(true)
                                                fetchFeedbacks(true, activeTab)
                                                
                                                setFeedPageNoVer(1)
                                                setLoadingFeedsVer(true)
                                                fetchVerifiedFeedbacks(true)
                                            }}
                                            reload={(all) => {
                                                if(all) {
                                                    setFeedPageNo(1)
                                                    setLoadingFeeds(true)
                                                    fetchFeedbacks(true, "1")
                                                    
                                                    setFeedPageNoVer(1)
                                                    setLoadingFeedsVer(true)
                                                    fetchVerifiedFeedbacks(true)
                                                    
                                                    return
                                                }
                                                if(activeTabKey === "0") {
                                                    setFeedPageNo(1)
                                                    setLoadingFeeds(true)
                                                    fetchFeedbacks(true, "0") 
                                                }
                                                else {
                                                    setFeedPageNoVer(1)
                                                    setLoadingFeedsVer(true)
                                                    fetchVerifiedFeedbacks(true)
                                                }
                                            }}
                                            afterUnverify={() => {
                                                    setFeedPageNo(1)
                                                    setLoadingFeeds(true)
                                                    fetchFeedbacks(true, "0")
                                                    
                                                    setFeedPageNoVer(1)
                                                    setLoadingFeedsVer(true)
                                                    fetchVerifiedFeedbacks(true)
                                                }
                                            }
                                            staticQuery={staticQuery}
                                            setStaticQuery={setStaticQuery}
                                            graphData={graphData}
                                            chartType={chartType}
                                            questVariations={questVariations}
                                            domainList={domainList}
                                            loadingDomains={loadingDomains}
                                            activeDomain={domainId}
                                            fetchDomains={fetchDomains}
                                            empty={emptyPending && emptyVerified}
                                            sqlTableDetails={sqlTableDetails}
                                            selectedRef={selectedRef}
                                            setParentDomain={props?.setParentDomain}
                                        />
                                    </div>
                                    <div style={{...layoutCSS}}>
                                        <div className='h-100 position-relative w-100 px-2 py-2'>
                                        <Radio.Group 
                                                className={`icon-toggler shadow-toggle cht-act-tg chat-feed-graph-tgl`}
                                                size='small' 
                                                value={activeToggle} 
                                                onChange={(e) => {
                                                    const val = e.target.value
                                                    setActiveToggle(val)
                                                }}
                                                disabled={loadingFeeds || chartLoader || getObjectLength(currFeedDetails) === 0}
                                            >
                                                {
                                                    [
                                                        {
                                                            value: 'graph', 
                                                            tooltip: 'Graph', 
                                                            icon: (() => {
                                                                let activeChartIcon = chartsList?.find(c => c?.active)?.icon
                                                                let activeChartType = chartsList?.find(c => c?.active)?.type
                                                                if(activeChartIcon) {
                                                                    return <span className='graph-type-ic'>
                                                                        {
                                                                            activeChartType === 'wordcloud'
                                                                            ?   <ICON_GRAPH_WORDCLOUD_W/>
                                                                            :   activeChartIcon
                                                                        }
                                                                    </span>
                                                                }
                                                                else return <ICON_BAR_CHART/>                                                     
                                                            })()
                                                        },
                                                        {value: 'table', tooltip: 'Preview', icon: <ICON_TABLE/>}
                                                    ]?.filter(v => hideGraph ? v.value !== 'graph' : v.value !== '')?.map((itm, i) => (
                                                        itm?.value === 'graph'
                                                        ?
                                                        <Popover 
                                                            autoAdjustOverflow
                                                            placement="leftTop" 
                                                            title={''} 
                                                            content={
                                                                <>
                                                                    <div className='chart-li-popgrid'>
                                                                    {
                                                                        chartsList?.map((c, i) => (
                                                                            <div key={`chrt-li-${i}`}
                                                                                onClick={() => {
                                                                                    if(c?.disabled) return
                                                                                    setChartType(c?.type)
                                                                                    setChartReady(false)
                                                                                    setTimeout(() => setChartReady(true), 500)
                                                                                }}
                                                                                className={`${c?.active ? 'active' : ''} ${c?.disabled ? 'disabled' : ''}`}
                                                                            >
                                                                                <div>{c?.icon}</div>
                                                                                <div className='chrt-li-lb'>{c?.name}</div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    </div>
                                                                </>
                                                            }
                                                            // open={true}
                                                            rootClassName='custom-chart-li-pop'
                                                            destroyTooltipOnHide
                                                            >
                                                            <Radio.Button title={itm.value} key={`${itm}_${i}`} value={itm?.value}>
                                                                {itm?.icon}
                                                                {
                                                                    itm?.label 
                                                                    ?   <label className={`mb-0 fontSizeLabel ${activeToggle === itm?.value ? 'text-white' : ''} cursor-pointer`}
                                                                            onClick={() => {
                                                                                setActiveToggle(itm?.value)
                                                                            }}
                                                                        >
                                                                            {itm?.label}
                                                                        </label> 
                                                                    : ''
                                                                }
                                                            </Radio.Button>
                                                        </Popover>
                                                        :
                                                        // <Tooltip title={itm?.tooltip ?? ''} placement={'left'} key={`tt_${itm}_${i}`}>
                                                            <Radio.Button title={itm.value} key={`${itm}_${i}`} value={itm?.value}>
                                                                {itm?.icon}
                                                                {
                                                                    itm?.label 
                                                                    ?   <label className={`mb-0 fontSizeLabel ${activeToggle === itm?.value ? 'text-white' : ''} cursor-pointer`}
                                                                            onClick={() => {
                                                                                setActiveToggle(itm?.value)
                                                                            }}
                                                                        >
                                                                            {itm?.label}
                                                                        </label> 
                                                                    : ''
                                                                }
                                                            </Radio.Button>
                                                        // </Tooltip>
                                                    ))
                                                }
                                            </Radio.Group>

                                                {
                                                    emptyPending && emptyVerified
                                                    ?
                                                    <div className='py-5 text-center text-black-50 d-flex 
                                                        align-items-center justify-content-center align-content-center flex-wrap' 
                                                        style={{minHeight: '100%'}}>
                                                        <div className='w-100 mb-3'>
                                                            <ICON_BAR_CHART height='50' width='50' color='#33333370' />
                                                        </div>
                                                        <div className='w-100' style={{lineHeight: 1.4}}>
                                                            No Chart Data Found
                                                        </div>
                                                    </div>
                                                    :
                                                    activeToggle === 'graph'
                                                    ?
                                                        // currFeedDetails?.conversation_detail?.uuid !== activeUUID ? ""
                                                        // :
                                                        chartData?.error_msg !== null 
                                                        && chartData?.error_msg?.length > 0
                                                        ?
                                                        getReloadErrorTemplate({errorMessage: chartData?.error_msg, hideReloadBtn: true, onReload: () => {}})
                                                        :
                                                        !isChartReady
                                                        ?
                                                        <div className='d-flex flex-wrap align-items-center justify-content-center w-100 align-content-center' style={{height: '100%'}}>
                                                            {showVerticalBarLoader()}
                                                            <div className='fontInterSemiBold mt-4 color-primary fontSizeHeading pt-2'>Loading Chart...</div>
                                                        </div>
                                                        :
                                                        <ChatContentGraph
                                                            showMinimal
                                                            chartData={chartData}
                                                            chartType={chartType}
                                                            error={chartError}
                                                            loading={chartLoader}
                                                            reload={() => {
                                                                setChartData({})
                                                                fetchConversationChart()
                                                            }}
                                                            setError={setChartError}
                                                            hideTools
                                                        />
                                                    :
                                                    <div className='h-100 feed-prv-wrap'>
                                                        {
                                                            chartLoader
                                                            ?   <ClaristaLoader />
                                                            // :   graphData?.query === "" && chartError?.length > 0
                                                            // ?   getReloadErrorTemplate({errorMessage: chartError, hideReloadBtn: true})
                                                            :   <ChatContentPreviewTable 
                                                                    data={graphData ?? {}}
                                                                    isFeedback={true}
                                                                    domainId={domainId}
                                                                    tables={pubTables}
                                                                    setTables={setPubTables}
                                                                    setTablesLoading={setPubTablesLoading}
                                                                    tablesLoading={pubTablesLoading}
                                                                    tablesEmpty={pubTablesEmpty}
                                                                    setTablesEmpty={setPubTablesEmpty}
                                                                    parentPreviewError={
                                                                        graphData?.query === "" && chartError?.length > 0
                                                                        ?   chartError
                                                                        :   undefined
                                                                    }
                                                                    hideErrorReloadBtn={graphData?.query === "" && chartError?.length > 0}
                                                                />
                                                        }
                                                    </div>
                                                }
                                        </div>
                                    </div>
                                </SplitPane>
                            }
                        </div>
                    </SplitPane>
                </div>
            </div>
            {
                getConfirmationModal({
                    open: confirmOpen,
                    setOpen: setConfirmOpen,
                    body: "Unsaved data will be lost. Do you want to proceed?",
                    onConfirm: () => {
                        let f = selectedRef.current
                        setActiveToggle('graph')
                        setActiveFeedId(f?.conversation_detail?.id)
                        setActiveUUID(f?.conversation_detail?.uuid)
                        let questVar = f?.question_variation ?? []
                        questVar = questVar?.map(q => ({question: q, edit: false, loading: false}))
                        setQuestVariations(questVar?? [])
                        setQuery(f?.conversation_detail?.query ?? "")
                        setStaticQuery(f?.conversation_detail?.query ?? "")

                        setCurrFeedDetails(f)
                    }
                })
            }
            <CommonModal
                open={variationModalOpen}
                setOpen={setVariationModalOpen}
                size='lg'
                onClose={() => {
                    setNewQuestVar('')
                    setVariationModalOpen(false)
                }}
                header={'Add Question Variants'}
                body={<>
                    <div>
                        <div>
                            <label className='fontSizeHeading mb-2'>New Question Variation</label>
                            <form onSubmit={(e) => {
                                e?.preventDefault()
                            }}>
                                <div className='d-flex' style={{gap: 10}}>
                                    <Input 
                                        size='large' 
                                        style={{borderRadius: 3}} 
                                        value={newQuestVar}
                                        onChange={e => {
                                            setNewQuestVar(e?.target?.value)
                                        }}
                                        required
                                    />
                                    <Buttons
                                        props={{
                                            actionType: 'submit',
                                            buttonText: "Add",
                                            tooltip: "",
                                            buttonClassName: `custom-btn-primary custom-btn btn-with-text h-100`,
                                            buttonEvent: () => {
                                                setVariationLoading(true)
                                                let quest = questVariations?.filter(q => q?.question !== '')?.map(q => q?.question)

                                                let payload = {
                                                    question_variation: [...quest, newQuestVar?.trim()]
                                                }

                                                /**
                                                 * Checking whether question already exist
                                                 */
                                                
                                                let q = quest?.map(q => q?.trim()?.toLowerCase())
                                                if(q?.includes(newQuestVar?.trim()?.toLowerCase())) {
                                                    emitToastNotification('error', 'Question already exist, try something different question')
                                                    setVariationLoading(false)
                                                    return
                                                }

                                                dispatch(verifyConversation(currFeedDetails?.conversation_detail?.id, payload))
                                                .then(() => {
                                                    if(currFeedDetails?.is_verified) {
                                                        setFeedbacksVer(prev => {
                                                            prev = prev?.map(d => {
                                                                if(d?.conversation_detail?.id === currFeedDetails?.conversation_detail?.id) {
                                                                    return {
                                                                        ...d,
                                                                        question_variation: [...quest, newQuestVar?.trim()]
                                                                    }
                                                                }
                                                                return {...d}
                                                            })

                                                            selectedRef.current.question_variation = [...quest, newQuestVar?.trim()]
                                                            return prev
                                                        })

                                                    }
                                                    else {
                                                        setFeedbacks(prev => {
                                                            prev = prev?.map(d => {
                                                                if(d?.conversation_detail?.id === currFeedDetails?.conversation_detail?.id) {
                                                                    return {
                                                                        ...d,
                                                                        question_variation: [...quest, newQuestVar?.trim()]
                                                                    }
                                                                }
                                                                return {...d}
                                                            })

                                                            selectedRef.current.question_variation = [...quest, newQuestVar?.trim()]
                                                            return prev
                                                        })
                                                    }

                                                    setNewQuestVar('')
                                                    setVariationLoading(false)


                                                })
                                                .catch(() => {
                                                    setVariationLoading(false)
                                                })

                                            },
                                            ImgSrc: () => <ICON_PLUS/>,
                                            isDisable: newQuestVar?.trim() === '' || variationLoading,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                        {
                            !variationLoading ? ''
                            :
                            <p className='mt-3 mb-0 text-success fontSizeLabel'>
                                <ICON_LOADING_CIRCLE height='12' width='12' color='currentColor' />
                                <span className='ml-1'>Adding Question...</span>
                            </p>
                        }
                        <Divider style={{margin: '15px 0 12px 0'}} />
                        <div className='fontSizeHeading'>
                            Following are variants for question:
                            <span className='ml-2 fontInterSemiBold color-primary' 
                                style={{
                                    wordBreak: currFeedDetails?.conversation_detail?.question && currFeedDetails?.conversation_detail?.question?.trim().split(' ')?.length === 1 ? 'break-all' : 'break-word'}}>
                                {currFeedDetails?.conversation_detail?.question}
                            </span>
                        </div>
                        <div className='quest-var-wrap'>
                            {
                                questVariations.map((v, i) => (
                                    <div key={`quest-vr-${i}`} className='d-flex align-items-center my-2'>
                                        <span className='mr-2 fontSizeHeading' style={{lineHeight: 0}}>
                                            {i+1}
                                        </span>
                                        <Tooltip title={v?.question} placement='topLeft'>
                                            <Input 
                                                value={v?.question}
                                                style={{borderRadius: 3}} 
                                                // readOnly={!v?.edit}
                                                onChange={(e) => {
                                                    setQuestVariations(prev => {
                                                        prev = prev?.map((q, ix) => {
                                                            if(ix === i) {
                                                                return {
                                                                    ...q,
                                                                    question: e?.target?.value
                                                                }
                                                            }
                                                            else {
                                                                return {...q}
                                                            }
                                                        })
                                                        
                                                        return prev
                                                    })
                                                }}
                                                readOnly={true}
                                                className='text-with-ellipsis'
                                                // readOnly={i === 0}
                                                />
                                        </Tooltip>
                                        {/* <Buttons
                                            props={{
                                                buttonText: "",
                                                tooltip: "",
                                                buttonClassName: `custom-btn-outline custom-btn btn-with-icon mx-2`,
                                                buttonEvent: () => {
                                                    setQuestVariations(prev => {
                                                        prev = prev?.map((q, ix) => (
                                                            {...q, edit: ix === i ? !q?.edit : q?.edit}
                                                        ))
                                                        
                                                        return prev
                                                    })
                                                },
                                                ImgSrc: () => v?.edit ? <ICON_CHECK/> : <ICON_EDITBOX/>,
                                                isDisable: false,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                            }}
                                        /> */}
                                        {
                                            i === 0 ? ''
                                            :
                                            <Buttons
                                                props={{
                                                    buttonText: "",
                                                    tooltip: "",
                                                    buttonClassName: `custom-btn-outline error-red custom-btn btn-with-icon ml-2`,
                                                    buttonEvent: () => {
                                                        setQuestVariations(prev => {
                                                            prev = prev?.map(q => ({...q, loading: v?.question === q?.question}))
                                                            return prev
                                                        })
                                                        let quest = questVariations?.filter(q => q?.question !== '')?.map(q => q?.question)
                                                        quest = quest?.filter(q => q !== v?.question) ?? []
                                                        let payload = {
                                                            question_variation: [...quest]
                                                        }
                                                        dispatch(verifyConversation(currFeedDetails?.conversation_detail?.id, payload))
                                                        .then(() => {
                                                            if(currFeedDetails?.is_verified) {
                                                                setFeedbacksVer(prev => {
                                                                    prev = prev?.map(d => {
                                                                        if(d?.conversation_detail?.id === currFeedDetails?.conversation_detail?.id) {
                                                                            return {
                                                                                ...d,
                                                                                question_variation: [...quest]
                                                                            }
                                                                        }
                                                                        return {...d}
                                                                    })
                                                                    
                                                                    selectedRef.current.question_variation = [...quest]
                                                                    return prev
                                                                })
                                                            }
                                                            else {
                                                                setFeedbacks(prev => {
                                                                    prev = prev?.map(d => {
                                                                        if(d?.conversation_detail?.id === currFeedDetails?.conversation_detail?.id) {
                                                                            return {
                                                                                ...d,
                                                                                question_variation: [...quest]
                                                                            }
                                                                        }
                                                                        return {...d}
                                                                    })

                                                                    selectedRef.current.question_variation = [...quest]
                                                                    return prev
                                                                })
                                                            }

                                                        })
                                                        .catch(() => {
                                                            setQuestVariations(prev => {
                                                                prev = prev?.map(q => ({...q, loading: false}))
                                                                return prev
                                                            })
                                                        })

                                                        // setQuestVariations(prev => {
                                                        //     prev = prev?.filter((q, ix) => ix !== i)
                                                            
                                                        //     return prev
                                                        // })
                                                    },
                                                    ImgSrc: () => v?.loading ? <ICON_LOADING_CIRCLE/> : <ICON_DELETE />,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                }}
                                            />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>}
                footer={''}
            />
        </div>
    )
})

const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'start',
    background: '#FFF'
};

export default TalkDataTrace