import React from 'react'
import Buttons from '../../Common/button/Buttons'
import { CLOSE } from '../../Common/iconSource'
import { Literals } from '../common/literals'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import LandingpageFirstHeader from '../common/LandingpageFirstHeader'
import Draggable from 'react-draggable'
import ClaristaCopilot from '../copilot'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardDetails } from '../../../store/modules/analytics/dashboard/dashboardActions'
import { isString } from 'lodash'
import { supersetLogin } from '../../../store/userAuthentication/userActions'
import { generateHtmlTitle, showSimpleLoading } from '../common/helperFunctions'
import { retriveSupersetAPIBaseURL } from '../../../store/modules/dataCatalogue/preview/previewActions'
import { emitToastNotification } from '../../../helpers/toast_helper'
import { useSocket } from '../../../services/SocketContextProvider'

function EditDashBoard() {

    var VISUALIZATION_BASE_URL = window._env_.REACT_APP_VISUALIZATION_BASE_URL;
    // let IframeURL = ''
    let { dashboardURL, isNew, domainId } = useLocation()?.state ?? { dashboardURL: null }
    // let url = new URLSearchParams(window.location.search);
    let { dashboardID } = useParams();


    let navigate = useNavigate()
    const dispatch = useDispatch()

    const [activeDrags, setActiveDrags] = React.useState(0)
    const [dashboardData, setDashboardData] = React.useState({})
    const [domains, setDomains] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [ready, setReady] = React.useState(true)
    const [isEditMode, setIsEditMode] = React.useState(false)
    const [IframeURL, setIframeURL] = React.useState('')

    const wrapperRef = React.useRef({})

    const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false
    const SocketContext = useSocket(null);




    React.useEffect(() => {
        // getDetails()

        function receiveMessage(event) {

            const data = event?.data;

            if (isString(data)) {
                let obj = JSON.parse(data)
                let onPage = obj.message;
                dispatch(getDashboardDetails(dashboardID))
                    .then(res => {
                        generateIframeURL(onPage);
                        setLoading(false)
                    }).catch((e) => {
                        setLoading(false)

                        if (e?.status === 404) {
                            navigate(Literals.links.PAGE_404)
                        }
                    })

                setIsEditMode(obj.edit_mode)
            }
        }
        window.addEventListener('message', receiveMessage, false);

        return () => {
            window.removeEventListener('message', receiveMessage);

        }
    }, [])

    async function generateIframeURL(onPage) {
        // if (onPage === 'login_page') {
        //     var i = document.getElementById("visualization_login");
        //     if (i) {
        //         i?.remove();
        //     }
        //     await supersetLogin();
        //     setTimeout(async () => {
        //         if (dashboardURL && dashboardID) {
        //             let IframeURL = await retriveSupersetAPIBaseURL(dashboardURL)
        //             setIframeURL(IframeURL);
        //         } else if (!dashboardURL && dashboardID) {
        //             let IframeURL = await retriveSupersetAPIBaseURL(`${VISUALIZATION_BASE_URL}superset/dashboard/${dashboardID}/?standalone=1`)
        //             setIframeURL(IframeURL)

        //         }
        //     }, 2000);
        // } else {
        setTimeout(async () => {
            if (dashboardURL && dashboardID) {
                let IframeURL = await retriveSupersetAPIBaseURL(dashboardURL)
                setIframeURL(IframeURL);
            } else if (!dashboardURL && dashboardID) {
                let IframeURL = await retriveSupersetAPIBaseURL(`${VISUALIZATION_BASE_URL}superset/dashboard/${dashboardID}/?standalone=1`)
                setIframeURL(IframeURL)

            }
        }, 2000);
        // }

    }

    React.useEffect(() => {


        if (SocketContext?.socketResponse && SocketContext?.socketResponse?.type === 'heartbeat' && SocketContext?.socketResponse?.state?.toLowerCase() !== 'running') {
            emitToastNotification('info', `Cluster is not running.`)
            navigate(Literals.links.CLARISTA_PULSE + '#dashboards');
            return;
        } else {
            if (dashboardID) {
                getDetails()
            } else if (dashboardURL) {
                generateIframeURL();
            }
        }

    }, [dashboardID, dashboardURL])

    React.useEffect(() => {
        if (IframeURL && document.getElementById('scaled-frame-dashboard')) {
            document.getElementById('scaled-frame-dashboard').onload = function () {
                var iframeDoc = this.contentDocument || this.contentWindow.document;
                iframeDoc.addEventListener('click', function (event) {
                    if (event.target.tagName === 'A' && event.target.href) {
                        event.preventDefault(); // Prevent default link action
                        window.open(event.target.href, '_blank'); // Open in new tab
                    }
                });
            };
        }


        return () => {
            if (document.getElementById('scaled-frame-dashboard')) {
                document.getElementById('scaled-frame-dashboard').onload = function () {
                    var iframeDoc = this.contentDocument || this.contentWindow.document;
                    iframeDoc.removeEventListener('click', function () {

                    });
                };
            }

        }
    }, [IframeURL])


    React.useEffect(() => {
        // SocketContext.checkConnectionState();

        if (SocketContext?.socketResponse && SocketContext?.socketResponse?.type === 'heartbeat' && SocketContext?.socketResponse?.state?.toLowerCase() !== 'running') {
            emitToastNotification('info', `Cluster is not running.`)
            navigate(Literals.links.CLARISTA_PULSE + '#dashboards');
            return;
        }
    }, [SocketContext])

    const getDetails = () => {
        setLoading(true)
        setError(undefined)
        dispatch(getDashboardDetails(dashboardID))
            .then(res => {
                let data = res?.data
                setDashboardData(data)
                setDomains([...data?.data_domain_detail]);
                generateIframeURL();
                setLoading(false)

                if (res?.data && res?.data?.name) {
                    let title = generateHtmlTitle(res?.data?.name?.toUpperCase() + ' | Dashboard', 'Pulse')
                    document.title = title
                }
            })
            .catch(err => {
                console.error({ err })
                setError(err?.message)
                setLoading(false)
                let title = generateHtmlTitle('Dashboard', 'Pulse')
                document.title = title
                if (err?.status === 404) {
                    navigate(Literals.links.PAGE_404)
                }
            })
    }

    return (

        <div style={{ height: 'calc(100vh - 0px)', position: 'relative', zIndex: 1 }} ref={wrapperRef} className={`bg-white pulse-dashboard-wrapper ${!noOpenAiAccess ? 'float-talkdata-present' : ''} w-100`}>
            <LandingpageFirstHeader title={Literals.DASHBOARD} flag={false} backRoute={`${Literals.links.CLARISTA_PULSE}#dashboards`} />


            <Buttons
                props={{
                    buttonText: '',
                    buttonClassName: "custom-btn-primary custom-btn fixed-button",
                    buttonEvent: () => {
                        navigate(`${Literals.links.CLARISTA_PULSE}#dashboards`)
                    },
                    toggleBtnValue: '',
                    ImgSrc: () => <CLOSE />,
                    isDisable: false,
                    buttonType: Literals.BTN_TERTIARY,
                    toggleBtnOption: null
                }}
            />

            {IframeURL ? (
                !ready ? ''
                    :
                    <iframe
                        className={`border-0`}
                        src={IframeURL}
                        id="scaled-frame-dashboard"
                        title="Clarista Dashboard"
                        style={{
                            zIndex: "1 !important",

                        }}
                    ></iframe>
            ) : (
                showSimpleLoading()
            )}

            {
                // domainId
                // ?
                <Draggable
                    onStart={() => setActiveDrags(prev => ++prev)}
                    onStop={() => setActiveDrags(prev => --prev)}
                    handle='.c-cpilot-sec-wrap, .cpilot-pop-head'
                    axis='x'
                    bounds={{ top: 0, left: -(wrapperRef?.current?.clientWidth ? wrapperRef?.current?.clientWidth - 400 : 700), right: 0, bottom: 0 }}
                >
                    <div style={{ zIndex: 12, position: 'absolute', bottom: 0, width: '100%' }}>
                        <ClaristaCopilot
                            type={isNew || isEditMode ? "dashboard" : "talkdata"}
                            extraProps={{
                                loading,
                                dashboardData,
                                dataDomainId: domainId ? domainId : domains?.[0]?.id,
                                dashboardId: dashboardID,
                                ready,
                                setReady
                            }}
                        />
                    </div>
                </Draggable>
                // :   ''
            }

        </div>
    )
}

export default EditDashBoard