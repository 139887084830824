import React, { forwardRef } from 'react'
import Buttons from '../../../../Common/button/Buttons'
import { ICON_ARROW_RIGHT, ICON_CSV_FILE, ICON_EYEOPEN, ICON_FOLDER, ICON_LOADING_CIRCLE, ICON_PARQUET_FILE, ICON_PLUS, ICON_TXT_FILE, ICON_XLS_FILE } from '../../../../Common/newIconSource'
import { Literals } from '../../../common/literals'
import SingleSelection from '../../../../Common/dropDown/SingleSelection'
import { unicodeList } from '../../../common/helperFunctions'
import { Input, Select, Tooltip } from 'antd'
import { CLOSE } from '../../../../Common/iconSource'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'
import { fetchFileSystemPreview } from '../../../../../store/actions'
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import _ from 'lodash'
import { v4 as uuidv4 } from "uuid";
import MultiSelection from '../../../../Common/dropDown/MultiSelection'
import FileOptionsHelpLinkModal from './FileOptionsHelpLinkModal'
import DebounceInput from '../../../../Common/debounceInput/DebounceInput'
import { setQueryKillFlag, setTableUUID } from '../../../../../store/modules/common/DataGridTable/dataGridTableRedux'

const formatList = [
    { key: 0, name: 'csv', value: 'csv', icon: <><ICON_CSV_FILE height='20' width='20' color='#5C5C5C' /></>, selected: false },
    { key: 1, name: 'text', value: 'txt', icon: <><ICON_TXT_FILE height='20' width='20' color='#5C5C5C' /></>, selected: false },
    { key: 2, name: 'parquet', value: 'parquet', icon: <><ICON_PARQUET_FILE height='20' width='20' color='#5C5C5C' /></>, selected: false },
    { key: 3, name: 'xlsx', value: 'xlsx', icon: <><ICON_XLS_FILE height='20' width='20' color='#5C5C5C' /></>, selected: false },
]

const formatListWithoutExcel = formatList?.filter(v => v.key !== 3)

const localLiterals = {
    encoding: 'encoding',
    delimeter: 'delimeter',
    format: 'format',
    headerCount: 'headerCount',
    escapeChar: 'escapeChar',
    quoteChar: 'quoteChar',
    skipFooter: 'skipFooter',
    skipRows: 'skipRows',
    sheets: 'sheets',
    nullFormat: 'nullFormat'
}

const FileOptions = forwardRef(({
    // getSelectedFormat = () => {},
    isFileUpload = false,
    setPreviewLoading = () => {},
    previewLoading = false,
    setPreviewError = () => {},
    setPreviewUUID = () => {},
    isEditMode = false,
    previewUUID,
    isEditing = false,
    podRetrievedConnection,
    fileUploadConnName = '',
    fileUploadPath = undefined,
    filesList = [],
    setPodPreviewRunning = () => {},
    podCreationTutorialComponentIds = {},
    setWalkThrough = () => {},
    walkthroughStepIndex = 0,
    autoClassifyObj = {},
    setAutoClassifyObj = () => {},
    previewError
}, ref) => {

    const [fileFormats, setFileFormats] = React.useState( isFileUpload ? [...formatList] : [...formatListWithoutExcel])
    const [activeFormat, setActiveFormat] = React.useState('')
    const [fieldDisabled, setFieldDisabled] = React.useState(false)
    const [showAdditional, setShowAdditional] = React.useState(false)
    const [openHelpModal, setHelpModal] = React.useState(false);

    const store = useStore()
    const dispatch = useDispatch()

    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)

    const reduxFileOptions = reduxData?.fileOptions
    const reduxSelectedFolderPath = reduxData?.selectedFolder

    const selectedConn = useSelector((state) => {
        return state.UserManagement.ConnectionReducer.selectedConn
    })

    const connName = selectedConn?.connection_name??''

    const { delimeter, format, headerCount, escapeChar, quoteChar, skipFooter, skipRows, encoding, nullFormat } = reduxData?.fileOptions
    const { fileUploadFiles, fileUploadFilesStatus, sheetsList, selectedSheets = [] } = reduxData

    React.useEffect(() => setActiveFormat(fileFormats?.find(el => el.selected)?.value), [fileFormats])

    // React.useEffect(() => getSelectedFormat(activeFormat), [activeFormat])

    React.useEffect(() => {
        if(isEditMode) {
            setFieldDisabled(!isEditing || previewLoading)
        }
        else {
            setFieldDisabled(previewLoading)
        }
    }, [previewLoading, isEditing, isEditMode])

    React.useEffect(() => {
        const selectedFormat = reduxFileOptions?.format
        setFileFormats(fo => fo.map(v => (
            {
                ...v,
                selected: selectedFormat === v.value
            }
        )))
    }, [reduxFileOptions])

    React.useImperativeHandle(ref, () => ({
        executePreview(selectedPath) {
            handlePreview(selectedPath);
        },
        autoSetFileFormat(formt) {
            setFileFormats(prev => {
                const newData = prev.map(v => ({...v, selected: formt === v.value}))
                
                let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
                reduxData.fileOptions.format = newData?.find(v => v.selected)?.value
                setReduxData(reduxData)
    
                return newData
            })
        },
        setPreviewTableError: (msg) => {
            setPreviewError(msg)
        }
    }))

    const handlePreview = (selectedPath) => {
        let path = selectedPath ?? reduxSelectedFolderPath?.[0]
        if (format !== '' && delimeter !== '') {
            setPreviewLoading(true)
            setPodPreviewRunning(true)
            setPreviewError(undefined)
            let requestBody = {};
            requestBody = {
              connection_name: isFileUpload ? fileUploadConnName : connName,
              prefix: isFileUpload ? fileUploadPath : path,
              file_properties: {
                format: format,
                delimeter: delimeter,
                encoding: format === "parquet" ? null : encoding,
                header_count: headerCount && headerCount !== '' ? Number(headerCount) : 1,
                escapechar: escapeChar && escapeChar !== '' ? escapeChar : undefined,
                quotechar: quoteChar && quoteChar !== '' ? quoteChar : undefined,
                skipfooter: parseInt(skipFooter) ? parseInt(skipFooter) : undefined,
                skiprows: parseInt(skipRows) ? parseInt(skipRows) : undefined
              }
            };
            let sheets = []
            if (format?.toLocaleLowerCase() === 'parquet') {
                requestBody = {
                  ...requestBody,
                  file_properties: { format }
                }
            }
            else if(format?.toLocaleLowerCase() === 'xlsx') {
                sheets = selectedSheets.map(s => {
                  let [fn, sn] = s.split(':::')
                  return {
                    file_name: fn,
                    sheet_name: sn
                  }
                })
                requestBody = {
                  ...requestBody,
                  sheet_info: [...sheets]
                }
            }

            // let uuid = uuidv4()
            // /**To Handle Cancel Preview**/
            // dispatch(setQueryKillFlag(false))
            // dispatch(setTableUUID(uuid))
            dispatch(fetchFileSystemPreview(requestBody, '', false, false))
            .then(res => {
                const data = res?.data

                /***********To Store Schema Unsaved Changes***********/
                let uuid = uuidv4();
                setPreviewUUID(uuid)
                /***********To Store Schema Unsaved Changes***********/

                setAutoClassifyObj(prev => {
                    prev = {...prev, result: reduxData?.tableData?.['result']?.autoClassify ?? false}
                    return prev
                })

                let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
                reduxData['output_regex'] = data?.output_regex??[]
                reduxData.tableData.result = {...data, autoClassify: autoClassifyObj['result'] ?? false}
                reduxData['fileSystemPayload'] = requestBody
                setReduxData(reduxData)
                setPreviewLoading(false)
                setPodPreviewRunning(false)
                setWalkThrough(prev => ({...prev, stepIndex: walkthroughStepIndex + 1}))
            })
            .catch(err => {
                let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
                reduxData['output_regex'] = []
                reduxData.tableData.result = {}
                setReduxData(reduxData)
                setPreviewError(err?.message)
                setPreviewLoading(false)
                setPodPreviewRunning(false)
                setWalkThrough(prev => ({...prev, stepIndex: walkthroughStepIndex + 1}))
            })
        }
        else {
            emitToastNotification('info', format === '' ? 'Please Select Format' : 'Please provide delimeter')
        }
    }

    const handleSheets = (sheets) => {
        reduxData['selectedSheets'] = [...sheets]
        let temp = sheets.map(v => ({file_name: v.split(':::')[0], sheet_name: v.split(':::')[1]}))
        reduxData['saveParqData'] = temp
        setReduxData(reduxData)
    }

    const handleFormat = (key) => {
        if(fieldDisabled) return
        setFileFormats(prev => {
            const newData = prev.map(v => ({...v, selected: key === v.key}))

            let reduxData = store?.getState()?.DataCatalogue?.PodCreationData
            reduxData.fileOptions.format = newData?.find(v => v.selected)?.value
            setReduxData(reduxData)

            return newData
        })
    }

    const getInputField = (field) => {
        switch (field) {
            case localLiterals.encoding:
                return  <SingleSelection
                            props={{
                                placeholder: "Select Encoding",
                                dropDownValue: reduxFileOptions[field],
                                onClickEvent: (value) => onInputChangeHandler({ key: 'encoding', data: value }),
                                optionList: unicodeList,
                                isDisable: fieldDisabled || activeFormat === 'parquet'
                            }}
                        />
            case localLiterals.sheets:
                return  <MultiSelection
                            props={{   
                                placeholder: "Select sheets",
                                dropDownValue: selectedSheets,
                                onClickEvent: handleSheets, 
                                isDisable: fieldDisabled
                            }}
                        >
                            {sheetsList && sheetsList.map((v, i) =>
                                <Select.Option key={`sheet-${i}`} value={`${v?.file_name}:::${v?.sheet_name}`}>
                                    <Tooltip className='d-block text-with-ellipsis' title={`${v?.sheet_name} of ${v?.file_name}`} placement={'right'}>
                                    {`${v?.sheet_name}`}
                                    </Tooltip>
                                </Select.Option>
                            )}
                        </MultiSelection>
            case localLiterals.headerCount:
            case localLiterals.skipFooter:
            case localLiterals.skipRows:
                return <DebounceInput
                            type={'text'}
                            className='custom-input-field'
                            defaultValue={reduxFileOptions[field]}
                            disabled={fieldDisabled || activeFormat === 'parquet'}
                            onDebounce={(val) => onInputChangeHandler({ key: field, data: val })}
                            onChangeIfCondition={(e) => {
                                let val = e?.target?.value
                                return val.match(/^[0-9]+$/) || val === ""
                            }}
                            useWithConditions={true}
                        />
            case localLiterals.delimeter:
            case localLiterals.escapeChar:
            case localLiterals.quoteChar:
            case localLiterals.nullFormat:
                return <DebounceInput
                        type={localLiterals.headerCount === field ? 'number' : 'text'}
                        className='custom-input-field'
                        defaultValue={reduxFileOptions[field]}
                        disabled={fieldDisabled || activeFormat === 'parquet'}
                        onDebounce={(val) => onInputChangeHandler({ key: field, data: val })}
                        />
            default:
                return <></>
        }
    }

    const onInputChangeHandler = ({key, data}) => {
        // if(fieldDisabled) return
        
        reduxData.fileOptions[key] = data
        setReduxData(reduxData)
    }

    const setReduxData = (data) => {
        dispatch(setPodCreationData(data))
    }

    const previewButtonVisibility = React.useMemo(() => {
        if(isFileUpload) {
            const checkAnyValidFilePresent = fileUploadFiles?.some(f => fileUploadFilesStatus[f?.name]?.isUploaded)

            if(fileUploadFiles?.some(f => fileUploadFilesStatus[f?.name]?.isUploading)) {
                return true
            }

            return (isEditMode && !isEditing) || !checkAnyValidFilePresent || previewLoading
        }
        return (isEditMode && !isEditing) || reduxSelectedFolderPath?.length === 0 || previewLoading
    }, [
        isEditMode,
        isEditing,
        reduxSelectedFolderPath,
        previewLoading,
        isFileUpload,
        fileUploadFiles,
        fileUploadFilesStatus
    ])
    
    return (
        <div className='h-100 w-100'>
            <div className='file-opts-head d-flex align-items-center justify-content-between py-2'>
                <label className='mb-0 fontSizeHeading fontInterSemiBold'>
                    File Options
                    <i className="bx bx-info-circle ml-1 text-black-50 cursor-pointer" onClick={() => setHelpModal(true)}></i>
                </label>
                <div>
                    <Buttons
                        props={{
                            buttonId: podCreationTutorialComponentIds?.previewButtonId,
                            buttonText: previewLoading ? "Previewing..." : "Preview",
                            buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                            buttonEvent: () => {
                                handlePreview()
                            },
                            ImgSrc: () => previewLoading ? <ICON_LOADING_CIRCLE/> : <ICON_EYEOPEN />,
                            isDisable: previewButtonVisibility,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                </div>
            </div>

            <div id={podCreationTutorialComponentIds?.fileOptionsId} className='fopts-cus-cont'>
                <div className='mb-3'>
                    {
                        reduxSelectedFolderPath?.length > 0
                        ?   <div className='alert alert-warning fontInterSemiBold fontSizeHeading mb-1 p-1 position-relative'>
                                <Tooltip placement='topLeft' title={`Root/${reduxSelectedFolderPath?.[0]}`} className='d-block text-with-ellipsis' style={{width: 'calc(100% - 50px)'}}>
                                    Selected Folder <span className='mr-1'><ICON_ARROW_RIGHT height='14' width='14' color='#856404'/></span>
                                    <span className='mr-2'><ICON_FOLDER height='20' width='16' color='#856404' /></span>
                                    {`Root/${reduxSelectedFolderPath?.[0]}`}
                                </Tooltip>
                            </div>
                        :   ''
                    }
                    
                    <label className='mb-2 pb-1 fontSizeHeading fontInterSemiBold text-dark'>
                        Select File Format
                    </label>
                    <div className={`format-disp-wrap ${fieldDisabled ? 'disabled' : ''}`}>
                        {
                            fileFormats?.map(f => (
                                <div 
                                    key={`frmt-${f.key}`} 
                                    className={`format-rd-box ${f.selected ? 'frmt-selected' : ''}`}
                                    onClick={() => handleFormat(f.key)}
                                    >
                                    <span className='frmt-rd-ic'>
                                        {f.icon}
                                    </span>
                                    <span className='frmt-rd-label text-uppercase'>
                                        {f.name}
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div>
                    {
                        activeFormat === 'parquet' ? ''
                        :
                        !showAdditional ? <span className='underline-button' onClick={() => setShowAdditional(true)}><ICON_PLUS/> Additional Settings</span>
                        :
                        <div className='pt-3 position-relative' style={{borderTop: 'solid 1px #d6d6d6'}}>
                            <div className='fopts-grid'>
                                <div className=''>
                                    <label className='mb-2 fontSizeHeading fontInterSemiBold text-dark'>
                                        Select File Encoding
                                    </label>
                                    <div>
                                        {getInputField(localLiterals.encoding)}
                                    </div>
                                </div>
                                {
                                    activeFormat !== 'xlsx'
                                    ?
                                    <div className=''>
                                        <label className='mb-2 fontSizeHeading fontInterSemiBold text-dark'>
                                            Delimeter
                                        </label>
                                        <div>
                                            {getInputField(localLiterals.delimeter)}
                                        </div>
                                    </div>
                                    :   ''
                                }
                                <div className=''>
                                    <label className='mb-2 fontSizeHeading fontInterSemiBold text-dark'>
                                        Header Count
                                    </label>
                                    <div>
                                        {getInputField(localLiterals.headerCount)}
                                    </div>
                                </div>
                                {
                                    format && format?.toLowerCase() === 'xlsx' && sheetsList && sheetsList?.length
                                    ? 
                                    <div className=''> 
                                        <label className='mb-2 fontSizeHeading fontInterSemiBold text-dark'>
                                            Sheets
                                        </label>
                                        <div>
                                            {getInputField(localLiterals.sheets)}
                                        </div>
                                    </div>
                                    :   ''
                                }
                                
                                <div className=''>
                                    <label className='mb-2 fontSizeHeading fontInterSemiBold text-dark'>
                                        Escape Char <span className='fontSizeLabel text-black-50'>(optional)</span>
                                    </label>
                                    <div>
                                        {getInputField(localLiterals.escapeChar)}
                                    </div>
                                </div>
                                {
                                    activeFormat === 'csv' || activeFormat === 'xlsx'
                                    ?   <div className=''>
                                            <label className='mb-2 fontSizeHeading fontInterSemiBold text-dark'>
                                                Quote Char <span className='fontSizeLabel text-black-50'>(optional)</span>
                                            </label>
                                            <div>
                                                {getInputField(localLiterals.quoteChar)}
                                            </div>
                                        </div>
                                    :   ''
                                }
                                <div className=''>
                                    <label className='mb-2 fontSizeHeading fontInterSemiBold text-dark'>
                                        Skip Footer <span className='fontSizeLabel text-black-50'>(optional)</span>
                                    </label>
                                    <div>
                                        {getInputField(localLiterals.skipFooter)}
                                    </div>
                                </div>
                                <div className=''>
                                    <label className='mb-2 fontSizeHeading fontInterSemiBold text-dark'>
                                        Skip Rows <span className='fontSizeLabel text-black-50'>(optional)</span>
                                    </label>
                                    <div>
                                        {getInputField(localLiterals.skipRows)}
                                    </div>
                                </div>
                                {
                                    activeFormat === 'txt'
                                    ?
                                    <div className=''>
                                        <label className='mb-2 fontSizeHeading fontInterSemiBold text-dark'>
                                            Null Format
                                        </label>
                                        <div>
                                            {getInputField(localLiterals.nullFormat)}
                                        </div>
                                    </div>
                                :   ''
                                }
                                <span className='file-opts-close-btn'>
                                    <Buttons
                                        props={{
                                            tooltip: "Close",
                                            tooltipPlacement: "left",
                                            buttonText: "",
                                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                                            buttonEvent: () => {
                                                setShowAdditional(false)
                                            },
                                            ImgSrc: () => <CLOSE />,
                                            isDisable: false,
                                            buttonType: Literals.BTN_TERTIARY,
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <FileOptionsHelpLinkModal openModal={openHelpModal} onClose={() => setHelpModal(false)}/>
        </div>
    )
})

export default FileOptions