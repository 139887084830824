import { Input, Switch, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import Buttons from '../../../Common/button/Buttons';
import { Literals } from '../../common/literals';
import { CHECK, CLOSE, USERTAG,PLUS,MINUS } from '../../../Common/iconSource';
import { ClassificationsModal } from './ClassificationsModal'

import SingleSelection from '../../../Common/dropDown/SingleSelection'
import { LandingSimpleTable } from '../../../Common/landingSimpleTable/LandingSimpleTable'
import { showSimpleLoading } from '../../common/helperFunctions'
import AdditionalDetailsModal from './AdditionalDetailsModal';
import { isArray } from 'lodash';
import { ICON_DELETE, ICON_INFO, ICON_INFO_FILLED, ICON_PLUS } from '../../../Common/newIconSource';
export const AdditionalDetailsView = ({
    fieldValues,
    setFieldValues = () => {},
    isEdit,
    hideHeaderText = false,
    renderInTable = true
}) => {

    const [data, setData] = useState([])
    const [openAdditionalViewModal, setOpenAdditionalViewModal] = useState(false)
    const [selected,setSelected]=React.useState([]);

    useEffect(()=>{
        if(fieldValues?.addfield && fieldValues?.addfield?.length) {
            let temp = generateRow(fieldValues.addfield)
            setData([...temp])
        }
        else setData([])
    },[fieldValues, isEdit])

    const onRemoveAdditionalFields=(id)=>{

        setFieldValues(prev=>{
            return {
                ...prev,
                addfield: [...prev.addfield.filter(v=>v.id!==id)],
                // tech_term: [...prev.tech_term.filter(v=>v.id!==id)],


            }
        })
    }
    const generateRow = (data) => {
        let temp = data.map((item, index) => {
            return {
                id:item.id,
                field_name: listTemplate.fieldName(item.name),
                description: listTemplate.fieldDescription(item?.description ?? ''),
                field_input: listTemplate.fieldInput(item, index),
                action: listTemplate.action(()=>onRemoveAdditionalFields(item.id), isEdit),
                data: item
            }
        })
        return temp
    }

    const handleInput = (value, index) => {
        setFieldValues(prev => {
            prev.addfield[index]['value'] = value
            return (
                {
                    ...prev,
                    addfield: [...prev.addfield]
                }
            )
        })
    }
    
    const getInputField = (obj, index) => {
        
        switch(obj.type) {
            case 'TEXT':
            case 'NUMERIC':
            case 'DATE':
                return <Input
                            type={
                                isEdit && obj.type.toLowerCase() === 'date' ? 'text'
                                :
                                obj.type==='NUMERIC'?'number':obj.type.toLowerCase()??'text'
                            }
                            value={obj.value}
                            onChange={(e) => handleInput(e.target.value, index)}
                            className={`dict-color-dark ${isEdit ? 'input-readyonly text-with-ellipsis' : 'custom-input-field'}`}
                            readOnly={isEdit}
                            style={{colorScheme: 'none'}}
                            title={obj?.value}
                        />
            case 'TOGGLE':
                return <Switch
                            className='custom-switch-btn'
                            checkedChildren="Yes" unCheckedChildren="No" 
                            checked={obj.value}
                            onChange={(check)=>handleInput(check, index)}
                            disabled={isEdit}
                        />
            case 'DROPDOWN':
                return <SingleSelection
                            props={{
                                placeholder: "Select",
                                dropDownValue: isArray(obj.value) ? '' : obj.value,
                                onClickEvent: (val) => handleInput(val, index),
                                optionList: obj?.options 
                                            ? obj?.options?.map(v=>({Name:v, value:v}))
                                            : [],
                                isDisable: isEdit,
                                selectClassName: 'w-100'
                            }}
                        />
            default: return <></> 
        }
    }

    const saveAdditionalDetails = (selected) => {
        // let temp = generateRow(selected)
        let temp = selected.map(v => {
            if(v.type === 'DROPDOWN') {
                return ({
                    ...v, 
                    options: v?.options ?? v?.value,
                    value: fieldValues?.addfield?.find(a => a?.id === v?.id)?.value ?? v?.default_value
                }) 
            }
            else {
                return ({
                    ...v, 
                    value: fieldValues?.addfield?.find(a => a?.id === v?.id)?.value ?? v?.default_value
                })
            }
        })
        setFieldValues({...fieldValues,addfield:[...temp]})

    }
    const listTemplate = {
        fieldName: (text = '') => {
            return <>
                <Tooltip placement='topLeft' title={text}>
                    <div className='text-with-ellipsis dict-color-dark'>
                        {text}
                    </div>
                </Tooltip>
            </>
        },
        fieldDescription: (text = '') => {
            if(text?.trim() === '') return <p className='mb-0 text-muted paragraph'>No Description</p>
            return <>
                <Tooltip placement='topLeft' title={text}>
                    <div className='text-with-ellipsis dict-color-dark'>
                        {text}
                    </div>
                </Tooltip>
            </>
        },
        fieldInput: (field = {}, index) => {
            return <>
                    <div className='d-flex align-items-center h-100'>
                       {getInputField(field, index)}
                    </div>
                </>
        },
        action: (onClick = () => {}, isEdit) => {
            return <>
                {
                    isEdit ? ''
                    :
                    <div className='d-flex justify-content-end align-items-center h-100'>
                        <Buttons
                            props={{
                                tooltipPlacement: 'right',
                                buttonText: "",
                                tooltip: "Remove",
                                buttonClassName: `custom-btn-outline custom-btn btn-with-icon error-red`,
                                buttonEvent: ()=>{
                                    onClick()
                                },
                                ImgSrc: () => <ICON_DELETE />,
                                isDisable: isEdit,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    </div>
                }
            </>
        }
    }
    const onRowClick=()=>{}
    return (
        <div className='px-2'>
            <Card className='card custom-card p-0 border-0 shadow-none'>
                <CardHeader id='addFieldHead' className={`${'p-0'} mx-n2 dict-header-bg border-0 add-field-header ${!isEdit ? !renderInTable ? 'mb-2' : '' : ''}`}>
                    <div className='dict-title-strip d-flex align-items-center justify-content-between dict-custom-hd-ln mb-2'>
                        {
                            // hideHeaderText ? ''
                            // :
                            <label className='mb-0 d-flex align-items-center section-heading-dark font-weight-bold'>
                                Additional Details
                            </label>
                        }
                        <div className='d-flex'>
                          {
                            !isEdit
                            ?   <span className='underline-button only-text fontInterSemiBold' onClick={() => setOpenAdditionalViewModal(true)}>
                                    <ICON_PLUS/> 
                                    <span>Add Details</span>
                                </span>
                            // ?   <Buttons
                            //         props={{
                            //             buttonText: 'Add Attributes',
                            //             tooltip: '',
                            //             buttonClassName: "custom-btn-outline custom-btn btn-with-text",
                            //             buttonEvent: () => { setOpenAdditionalViewModal(true)},
                            //             ImgSrc: () => <PLUS/>,
                            //             isDisable: false,
                            //             buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                            //         }}
                            //     />
                            :""
                            }
                        </div>
                    </div>
                </CardHeader>
                <CardBody className='p-0' style={{height: renderInTable && data && data.length > 0 ? (data?.length * 41) + 41 : '100%'}}>
                    <div className={`h-100 dict-detail-table ${!renderInTable ? '' : 'add-field-tbl'}`}>
                    {
                        !renderInTable
                        ?
                        <>
                            <div>
                                {
                                    data?.map(d => (
                                        <div className='d-flex align-items-center' style={{marginBottom: d?.data?.type?.toLowerCase() === 'dropdown' ? isEdit ? 14 : 20 : 20}}>
                                            <Tooltip 
                                                color='#FFF'
                                                title={<>
                                                    <div className='text-dark fontInterSemiBold'>{`${d?.data?.name}`}</div>
                                                    <span className='fontSizeLabel text-black-50'>{`${d?.data?.description}`}</span>
                                                </>
                                            }
                                                placement='topLeft'
                                            >
                                                <label className='m-0 grey-color label text-with-ellipsis pr-2' style={{width: 95}}>
                                                    {d?.data?.name}
                                                </label>
                                            </Tooltip>
                                            <div style={{flex: '0 0 calc(100% - 135px)'}}>
                                                {d?.field_input}
                                            </div>
                                            {
                                                isEdit ? ''
                                                :
                                                <span className='ml-3'>
                                                    {d?.action}
                                                </span>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                        :
                        <>
                        {
                            data.length
                            ?
                            <LandingSimpleTable
                                rowsData={data}
                                cols={columns}
                                tableHeight={'100%'}
                                showCursor={false}
                            />
                            :
                            <div className='p-3 text-center text-black-50'>No Additional Details Found</div>
                        }
                        </>
                    }
                    </div>
                </CardBody>
            </Card>
            <AdditionalDetailsModal
                present={fieldValues.addfield}
                isModalOpen={openAdditionalViewModal} 
                onClose={() => setOpenAdditionalViewModal(false)}
                save={saveAdditionalDetails} 
                selected={selected}
                setSelected={setSelected}
                isEdit={isEdit}
                reset={() => {}} 
            />
          
        </div>
    )
}

const columns = [
    {
        name: <label className='mt-0 grey-color label'> Field Name</label>,
        key: "field_name",
        width: 95,

        sortable: false,
        resizable: false
    },
    // {
    //     name: <label className='mt-0 grey-color label'> Field Description</label>,
    //     key: "description",
    //     width: 400,

    //     sortable: false,
    //     resizable: false
    // },
    {
        name: <label className='mt-0 grey-color label'> Field Input</label>,
        key: "field_input",
        width: 'auto',
        sortable: false,
        resizable: false
    },
    {
        name: <label className='mt-0 grey-color label'></label>,
        key: "action",
        sortable: false,
        resizable: false,
        width: 80
    }
]