import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Switch } from "antd";
import SingleSelection from "../../../../Common/dropDown/SingleSelection";
import Buttons from "../../../../Common/button/Buttons";
import { CLOSE, PERSON, TICK } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import {
  createEditUser,
  setUsersListRefresh,
} from "../../../../../store/modules/UserManagement/users/usersActions";
import { encryptMessage, setUser, updateUserDetails } from "../../../../../store/userAuthentication/userActions";
import DATA_ANALYST from "../../../../../assets/images/persona/data_analyst.svg";
import DATA_CONSUMER from "../../../../../assets/images/persona/data_consumer.svg";
import DATA_SCIENTIST from "../../../../../assets/images/persona/data_scientist.svg";
import AutoCreatePassword from "./AutoCreatePassword";
import { ICON_CHECK, ICON_LOADING_CIRCLE, ICON_SAVE } from "../../../../Common/newIconSource";
import { emitToastNotification } from "../../../../../helpers/toast_helper";

const CreateNewUserModal = ({
  isModalOpen,
  closeModal,
  viewMode,
  onChange,
  userData,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.UserManagement.Users);
  const currentUserDetails = useSelector(state => state.UserReducer.user)

  const isSuperUser = useSelector(state => state.UserReducer.user.userDetails.is_superuser)
  const [selectedPersona, setselectedPersona] = useState(0);
  const [newPassword, setNewPassword] = React.useState('')
  const [saveBtnVisibility, setSaveBtnVisibility] = React.useState(viewMode === 'edit')
  const [isAutoCreate, setIsAutoCreate] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setSaveBtnVisibility(viewMode === 'edit')
  }, [viewMode])

  const createUserHandler = async () => {
    try {
      let endPoint;

      if (userData.isSuperUser) {
        endPoint = "auth/createsuperuser";
      } else {
        endPoint = "auth/createuser";
      }

      if(!userData.firstName || userData.firstName.trim() === "") {
        emitToastNotification('error', 'Please Provide First Name')
        return
      }

      // if(!userData.lastName || userData.lastName.trim() === "") {
      //   emitToastNotification('error', 'Please Provide Last Name')
      //   return
      // }

      if(!userData.email || userData.email.trim() === "") {
        emitToastNotification('error', 'Please Provide Valid Email')
        return
      }

      let payload = {
        email: userData?.email?.trim(),
        first_name: userData?.firstName?.trim(),
        last_name: userData?.lastName?.trim() ?? null,
        designation: userData.designation,
        is_superuser: userData.isSuperUser === true ? true : false,
      };

      if(userData?.lastName?.trim() === "") {
        payload.last_name = null
      }

      if(!isAutoCreate) {
        payload = {
          ...payload,
          password: newPassword
          // password: encryptMessage(newPassword)
        }
      }

      const response = await dispatch(
        createEditUser({
          method: "POST",
          endPoint,
          payload: payload,
        })
      );



      if (response?.status === "success") {
        if (onSuccess) {
          onSuccess();
        } else {
          dispatch(setUsersListRefresh(true));
        }

        closeModal();
      }
    } catch (error) {
      console.error("Error creating user", error.message);
    }
  };

  const editUserHandler = async () => {

    if(!userData.firstName || userData.firstName.trim() === "") {
      emitToastNotification('error', 'Please Provide First Name')
      return
    }

    // if(!userData.lastName || userData.lastName.trim() === "") {
    //   emitToastNotification('error', 'Please Provide Last Name')
    //   return
    // }

    if(!userData.email || userData.email.trim() === "") {
      emitToastNotification('error', 'Please Provide Valid Email')
      return
    }

    try {
      const payload = {
        email: userData?.email?.trim(),
        first_name: userData?.firstName?.trim(),
        last_name: userData?.lastName?.trim() ?? "",
        designation: userData.designation,
        is_superuser: userData.isSuperUser === true ? true : false

      };
      const response = await dispatch(updateUserDetails(payload, userData.id));
      if (response.status === "success") {

        if (currentUserDetails?.userDetails?.id === userData?.id) {
          currentUserDetails.userDetails = response.data
          dispatch(setUser(currentUserDetails))
        }


        if (onSuccess) {
          onSuccess();
        } else {
          dispatch(setUsersListRefresh(true));
        }

        closeModal();
      }
    } catch (error) {
      console.error("Error editing user", error.message);
    }
  };

  useEffect(() => {

    if (userData.designation === Literals.DATA_ANALYST) {


      setselectedPersona(0)


    } else if (userData.designation === Literals.DATA_SCIENTIST) {

      setselectedPersona(1)
    } else if (userData.designation === Literals.DATA_CONSUMER) {

      setselectedPersona(2)

    } else {

      setselectedPersona(0);
      if (userData.designation === '') {
        onChange("designation", Literals.DATA_ANALYST)
      }
    }
  }, [userData])

  const setDesignationValue = (value) => {
    if (value === 0) {

      onChange("designation", Literals.DATA_ANALYST)
    } else if (value === 1) {

      onChange("designation", Literals.DATA_SCIENTIST)
    } else if (value === 2) {

      onChange("designation", Literals.DATA_CONSUMER)
    }
    setselectedPersona(value)
  }

  const getPasswordStatus = (status) => {
    setSaveBtnVisibility(status === 100)
  }

  const getAutoCreateStatus = (status) => {
    setIsAutoCreate(status)
  }

  return (
    <Modal
      size="lg"
      toggle={closeModal}
      isOpen={isModalOpen}
      className="custom-modal add-user-modal"
      centered={true}
    >
      <form className="add-user-form" onSubmit={(e) => {
        e.stopPropagation()
        e.preventDefault()
        if (viewMode.includes("create")) {
          createUserHandler();
        } else {
          editUserHandler();
        }
      }}>
        <ModalHeader>
          <div className="d-flex align-items-center">
            <PERSON />
            <h2 className="section-heading">
              {viewMode.includes("edit") ? "Edit" : "Create"} User
            </h2>
          </div>
          <Buttons
            props={{
              buttonText: "",
              buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2",
              buttonEvent: () => {
                closeModal();
              },
              ImgSrc: () => <CLOSE />,
              isDisable: false,
              buttonType: Literals.BTN_TERTIARY,
            }}
          />
        </ModalHeader>
        <ModalBody className="px-3 py-2">
          <div className="add-user-form-dual-fields">
            <div className="form-group">
              <label className="label mb-1">First Name <sup>*</sup></label>
              <input
                value={userData.firstName}
                placeholder="Enter First Name"
                className="custom-input-field"
                disabled={false}
                onChange={(e) => {
                  onChange("firstName", e.target.value);
                }}
                required
              ></input>
            </div>
            <div className="form-group">
              <label className="label mb-1">Last Name</label>
              <input
                value={userData.lastName}
                placeholder="Enter Last Name"
                className="custom-input-field"
                disabled={false}
                onChange={(e) => {
                  onChange("lastName", e.target.value);
                }}
                // required
              ></input>
            </div>
          </div>
          <div className="form-group">
            <label className="label mb-1">Email Address <sup>*</sup></label>
            <input
              value={userData.email}
              placeholder="Enter Email Id"
              className="custom-input-field"
              disabled={viewMode.includes("edit") ? true : false}
              pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
              title="Enter valid email"
              onChange={(e) => {
                onChange("email", e?.target?.value?.trim());
              }}
              required
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputtext3" className="label label-color">Persona </label>
            <div className='d-flex align-items-center m-0 p-0 w-100 justify-content-center'>
              <div onClick={() => setDesignationValue(0)} className={` cursor-pointer ${selectedPersona === 0 ? 'persona-selected' : 'persona-container'}`}>
                <img src={DATA_ANALYST} alt={Literals.DATA_ANALYST} height={150} width={150}></img>
                <label className='label text-center  cursor-pointer w-100 persona-title'>{Literals.DATA_ANALYST}</label>
              </div>
              <div onClick={() => setDesignationValue(1)} className={`cursor-pointer ${selectedPersona === 1 ? 'persona-selected' : 'persona-container'}`}>
                <img src={DATA_SCIENTIST} alt={Literals.DATA_SCIENTIST} height={150} width={150}></img>
                <label className='label text-center  cursor-pointer w-100 persona-title'>{Literals.DATA_SCIENTIST}</label>
              </div>
              <div onClick={() => setDesignationValue(2)} className={`cursor-pointer ${selectedPersona === 2 ? 'persona-selected' : 'persona-container'}`}>
                <img src={DATA_CONSUMER} alt={Literals.DATA_CONSUMER} height={150} width={150}></img>
                <label className='label text-center  cursor-pointer w-100 persona-title'>{Literals.DATA_CONSUMER}</label>
              </div>
            </div>
            {/* <label className="label">Designation <sup>*</sup></label>
            <input
              value={userData.designation}
              placeholder="Enter Designation"
              className="custom-input-field"
              disabled={false}
              onChange={(e) => {
                onChange("designation", e.target.value);
              }}
              required
            ></input> */}
          </div>
          {/* <div className="form-group">
            <label className="label">Login</label>
            <SingleSelection
              props={{
                isSortOptionDisable: true,
                label: "",
                placeholder: "Select Login Type",
                dropDownValue: userData.auth_provider,
                onClickEvent: (value) => { onChange("auth_provider", value);},
                optionList: loginTypes,
                isDisable: false,
              }}
            />
          </div> */}
          <div className="form-group add-user-form-checkbox">
            <div className="d-flex align-items-center mb-1">
                <Switch
                  className='custom-switch-btn'
                  checkedChildren="Yes" unCheckedChildren="No" 
                  checked={userData.isSuperUser}
                  onChange={(checked) => onChange("isSuperUser", checked)}
                  disabled={!(isSuperUser)}
                />
                <span className="mb-0 fontSizeHeading ml-2 text-dark fontInterSemiBold">
                  Super User
                </span>
            </div>
          </div>
          {
            viewMode.includes("create")
            ?
            <AutoCreatePassword
              password={newPassword}
              setPassword={setNewPassword}
              getPasswordStatus={getPasswordStatus}
              getAutoCreateStatus={getAutoCreateStatus}
            />
            : ''
          }
        </ModalBody>
        <ModalFooter>
          <Buttons
              props={{
                  actionType: 'submit',
                  buttonText: viewMode.includes("edit") ? "Save" : "Create",
                  buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                  buttonEvent: () => {},
                  ImgSrc: () => loading ? <ICON_LOADING_CIRCLE/> : <ICON_CHECK/>,
                  isDisable: loading || isAutoCreate ? !isAutoCreate : !saveBtnVisibility,
                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
          />
          {/* <button type="submit" className="custom-btn-primary custom-btn btn-with-text ml-2">
            <TICK />
            {
              viewMode.includes("edit") ? "Save" : "Create"
            }
          </button> */}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateNewUserModal;
