import { Select, Tooltip } from 'antd';
import moment from 'moment-timezone';
import React from 'react'

// const tzAndPlaces = {
//     "Etc/GMT+12": "International Date Line West",
//     "Pacific/Midway": "Midway Island, Samoa",
//     "Pacific/Honolulu": "Hawaii",
//     "America/Juneau": "Alaska",
//     "America/Dawson": "Pacific Time (US and Canada), Tijuana",
//     "America/Boise": "Mountain Time (US and Canada)",
//     "America/Chihuahua": "Chihuahua, La Paz, Mazatlan",
//     "America/Phoenix": "Arizona",
//     "America/Chicago": "Central Time (US and Canada)",
//     "America/Regina": "Saskatchewan",
//     "America/Mexico_City": "Guadalajara, Mexico City, Monterrey",
//     "America/Belize": "Central America",
//     "America/Detroit": "Eastern Time (US and Canada)",
//     "America/Indiana/Indianapolis": "Indiana (East)",
//     "America/Bogota": "Bogota, Lima, Quito",
//     "America/Glace_Bay": "Atlantic Time (Canada)",
//     "America/Caracas": "Caracas, La Paz",
//     "America/Santiago": "Santiago",
//     "America/St_Johns": "Newfoundland and Labrador",
//     "America/Sao_Paulo": "Brasilia",
//     "America/Argentina/Buenos_Aires": "Buenos Aires, Georgetown",
//     "America/Godthab": "Greenland",
//     "Etc/GMT+2": "Mid-Atlantic",
//     "Atlantic/Azores": "Azores",
//     "Atlantic/Cape_Verde": "Cape Verde Islands",
//     "Etc/GMT": "Dublin, Edinburgh, Lisbon, London",
//     "Africa/Casablanca": "Casablanca, Monrovia",
//     "Atlantic/Canary": "Canary Islands",
//     "Europe/Belgrade": "Belgrade, Budapest, Ljubljana, Prague",
//     "Europe/Sarajevo": "Sarajevo, Skopje, Warsaw, Zagreb",
//     "Europe/Brussels": "Brussels, Copenhagen, Madrid, Paris",
//     "Europe/Amsterdam": "Amsterdam, Berlin, Bern, Rome, Stockholm",
//     "Africa/Algiers": "West Central Africa",
//     "Europe/Bucharest": "Bucharest",
//     "Africa/Cairo": "Cairo",
//     "Europe/Helsinki": "Helsinki, Kiev, Riga, Sofia, Tallinn",
//     "Europe/Athens": "Athens, Istanbul, Minsk",
//     "Asia/Jerusalem": "Jerusalem",
//     "Africa/Harare": "Harare, Pretoria",
//     "Europe/Moscow": "Moscow, St. Petersburg, Volgograd",
//     "Asia/Kuwait": "Kuwait, Riyadh",
//     "Africa/Nairobi": "Nairobi",
//     "Asia/Baghdad": "Baghdad",
//     "Asia/Tehran": "Tehran",
//     "Asia/Dubai": "Abu Dhabi, Muscat",
//     "Asia/Baku": "Baku, Tbilisi, Yerevan",
//     "Asia/Kabul": "Kabul",
//     "Asia/Yekaterinburg": "Ekaterinburg",
//     "Asia/Karachi": "Islamabad, Karachi, Tashkent",
//     "Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
//     "Asia/Kathmandu": "Kathmandu",
//     "Asia/Dhaka": "Astana, Dhaka",
//     "Asia/Colombo": "Sri Jayawardenepura",
//     "Asia/Almaty": "Almaty, Novosibirsk",
//     "Asia/Rangoon": "Yangon Rangoon",
//     "Asia/Bangkok": "Bangkok, Hanoi, Jakarta",
//     "Asia/Krasnoyarsk": "Krasnoyarsk",
//     "Asia/Shanghai": "Beijing, Chongqing, Hong Kong SAR",
//     "Asia/Kuala_Lumpur": "Kuala Lumpur, Singapore",
//     "Asia/Taipei": "Taipei",
//     "Australia/Perth": "Perth",
//     "Asia/Irkutsk": "Irkutsk, Ulaanbaatar",
//     "Asia/Seoul": "Seoul",
//     "Asia/Tokyo": "Osaka, Sapporo, Tokyo",
//     "Asia/Yakutsk": "Yakutsk",
//     "Australia/Darwin": "Darwin",
//     "Australia/Adelaide": "Adelaide",
//     "Australia/Sydney": "Canberra, Melbourne, Sydney",
//     "Australia/Brisbane": "Brisbane",
//     "Australia/Hobart": "Hobart",
//     "Asia/Vladivostok": "Vladivostok",
//     "Pacific/Guam": "Guam, Port Moresby",
//     "Asia/Magadan": "Magadan, Solomon Islands, New Caledonia",
//     "Pacific/Fiji": "Fiji Islands, Kamchatka, Marshall Islands",
//     "Pacific/Auckland": "Auckland, Wellington",
//     "Pacific/Tongatapu": "Nuku'alofa",
//     "UTC":"UTC"
// };

const tzAndPlaces = {
    "Etc/GMT+12": "Etc/GMT+12",
    "Etc/GMT": "Etc/GMT",
    "US/Eastern": "US/Eastern",
    "US/Central": "US/Central",
    "US/Mountain": "US/Mountain",
    "US/Pacific": "US/Pacific",
    "Europe/London": "Europe/London",
    "Europe/Paris": "Europe/Paris",
    "Europe/Berlin": "Europe/Berlin",
    "Europe/Istanbul": "Europe/Istanbul",
    "Europe/Moscow": "Europe/Moscow",
    "Asia/Calcutta": "Asia/Calcutta",
    "Asia/Shanghai": "Asia/Shanghai",
    "Asia/Tokyo": "Asia/Tokyo",
    "Australia/Sydney": "Australia/Sydney",
    "Pacific/Auckland": "Pacific/Auckland",
    "Asia/Dubai": "Asia/Dubai",
    "Asia/Singapore": "Asia/Singapore",
    "Asia/Hong_Kong": "Asia/Hong_Kong",
    "Asia/Bangkok": "Asia/Bangkok",
    "Asia/Seoul": "Asia/Seoul",
    "Asia/Beirut": "Asia/Beirut",
    "Asia/Manila": "Asia/Manila",
    "Asia/Jakarta": "Asia/Jakarta",
    "Asia/Kuala_Lumpur": "Asia/Kuala_Lumpur",
    "Europe/Madrid": "Europe/Madrid",
    "Europe/Rome": "Europe/Rome",
    "Europe/Amsterdam": "Europe/Amsterdam",
    "Europe/Zurich": "Europe/Zurich",
    "Europe/Stockholm": "Europe/Stockholm",
    "Europe/Oslo": "Europe/Oslo",
    "Europe/Vienna": "Europe/Vienna",
    "Europe/Brussels": "Europe/Brussels",
    "Europe/Prague": "Europe/Prague",
    "Europe/Warsaw": "Europe/Warsaw",
    "Europe/Helsinki": "Europe/Helsinki",
    "Africa/Cairo": "Africa/Cairo",
    "Africa/Johannesburg": "Africa/Johannesburg",
    "Africa/Lagos": "Africa/Lagos",
    "America/New_York": "America/New_York",
    "America/Chicago": "America/Chicago",
    "America/Denver": "America/Denver",
    "America/Los_Angeles": "America/Los_Angeles",
    "America/Toronto": "America/Toronto",
    "America/Vancouver": "America/Vancouver",
    "America/Mexico_City": "America/Mexico_City",
    "America/Bogota": "America/Bogota",
    "America/Lima": "America/Lima",
    "America/Santiago": "America/Santiago",
    "America/Buenos_Aires": "America/Buenos_Aires",
    "UTC": "UTC"
}


function TimeZoneDropdown({ selectedTimeZone, disabled, fromRoute = 'scheduler', setselectedTimeZone }) {


    const buildTZ = (id) => {
        const momentTz = moment.tz(id);
        const offset = momentTz.utcOffset();
        const places = tzAndPlaces[id] ? tzAndPlaces[id] : "";
        const name = `${id}`;
        const gmt = `UTC${offset ? momentTz.format("Z") : ""}`;
        const fullName = `${gmt} - ${name}`;
        return { id, fullName, gmt, offset };
    };

    const timezones = moment.tz
        .names()
        .filter((tz) => tzAndPlaces[tz])
        .reduce((acum, id) => [...acum, buildTZ(id)], [])
        .sort((a, b) => a.offset - b.offset)
    // .concat({
    //     id: moment.tz.guess(),
    //     fullName: `Let my browser decide - Currently ${buildTZ(moment.tz.guess()).gmt
    //         }`
    // },
    //     {
    //         id: 'UTC',
    //         fullName: `UTC`
    //     });

    function onChange(value) {

        setselectedTimeZone(value)
    }

    return (
        <div className={`custom-select-dropdown ${fromRoute === 'scheduler' ? 'pl-0 col-5 TZ-Select IN-Select' : ''}`}>
            <label className='label'>Select Timezone</label>
            <Select
                showSearch
                placeholder="Select a timezone"
                className="custom-select-dropdown"
                optionFilterProp="gmt"
                onChange={onChange}
                value={selectedTimeZone}
                disabled={disabled}
                filterOption={(input, option) =>
                    option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {timezones.map((tz) => (
                   <Select.Option key={tz.id} value={tz.id}>
                        {" "}
                        {tz.fullName}
                    </Select.Option>
                ))}
            </Select></div>
    )
}

export default TimeZoneDropdown