import { Checkbox, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Buttons from '../../../Common/button/Buttons';
import { CHECK, CLOSE, USERTAG } from '../../../Common/iconSource';
import { LandingSimpleTable } from '../../../Common/landingSimpleTable/LandingSimpleTable';
import SearchInput from '../../../Common/search/Search';
import {getRelativeTime,getObjectLength } from '../../common/helperFunctions';
import { Literals } from '../../common/literals';

export const ClassificationsModal = ({
    openModal = false,
    setOpenModal = () => { },
    classifications,
    fieldValues,
    setFieldValues = () => { },
    isEdit,
    activeClassifier
}) => {

    const [data, setData] = useState([])
    const [rawData, setRawData] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [selectedData, setSelectedData] = useState({ ...fieldValues })
    const [selectedIndex, setSelectedIndex] = React.useState(null)
    const [searchData, setSearchData] = useState('')

    useEffect(() => {
        if (getObjectLength(fieldValues) && openModal) {
            setSelectedData({ ...fieldValues })
            setSelectedIndex(activeClassifier?.id)
        }
    }, [fieldValues, openModal])
    
    useEffect(() => {
        if (data !== null && openModal) {
            const FILTER_COLUMNS = ['name', 'description', 'created_on', 'updated_on']
            let clone = [...rawData]
            let newData = clone.filter(item => FILTER_COLUMNS.some(key => {
                if (key === 'created_on' || key === 'updated_on') {
                    item[key] = getRelativeTime(item[key])
                }
                return item[key].toLowerCase().includes(searchData.toLowerCase())
            }))
            let temp = newData.map((item) => createListFormat(item))
            setData([...temp])
        }
    }, [searchData, rawData, openModal])

    useEffect(() => {
        let classificationsList = []
        if (fieldValues?.classification?.length) {
            let classData = fieldValues?.classification;
            classificationsList = classifications.map(itm => ({ ...itm, is_selected: classData.some(v => v.id === itm.id) }))
        }
        else {
            classificationsList = classifications.map(itm => ({ ...itm, is_selected: false }))
        }
        let temp = classificationsList.map((item) => createListFormat(item))
        setRawData([...classificationsList])
        setData([...temp])
    }, [classifications, fieldValues, isEdit])

    useEffect(() => {
        let isAllSelected = rawData.every(item => item?.is_selected === true)
        setSelectAll(isAllSelected)
    }, [rawData])

    const createListFormat = (obj) => {
        return {
            key: obj?.id,
            id: obj?.id,
            // is_selected: listTemplate.selected_status(obj.is_selected, onCheckHandler, obj.id, isEdit),
            name: listTemplate.name(obj.name),
            description: listTemplate.description(obj.description),
            pattern: listTemplate.pattern(obj.pattern ?? ''),
            created_on: listTemplate.created_on(getRelativeTime(obj.created_on)),
            updated_on: listTemplate.updated_on(getRelativeTime(obj.updated_on)),
            data: obj
        }
    }

    const onCheckHandler = (e, id) => {
        setRawData(prev => {
            prev = prev.map(item => ({ ...item, is_selected: item.id === id ? e.target.checked : item.is_selected }))
            setSelectedData({
                ...fieldValues,
                classification: prev.filter(v => v.is_selected).map(item => ({ id: item.id, name: item.name }))
            })
            return [...prev]
        })
    }

    const onSelectAllHandler = (e) => {
        let el = document.querySelector('#classification-modal-search .custom-search-input')
        if (el) el.value = ''
        setSearchData('')
        let isChecked = e.target.checked
        setRawData(prev => {
            prev = prev.map(v => ({ ...v, is_selected: isChecked }))
            setSelectedData({
                ...fieldValues,
                classification: prev.filter(v => v.is_selected).map(item => ({ id: item.id, name: item.name }))
            })
            return [...prev]
        })
        setSelectAll(isChecked)
    }

    const isDoneButtonDisable = () => {
        return !selectedIndex || isEdit
    }

    const onDoneHandler = () => {
        setFieldValues({ ...selectedData })
        setOpenModal(false)
        setData([...rawData.map((item) => createListFormat(item))])
        setSearchData('')
    }

    const onCloseModal = () => {
        setOpenModal(false)
        setSearchData('')
        setData([...rawData.map((item) => createListFormat(item))])
    }

    const onRowClick = (record) => {
        setSelectedData(prev => {
            return {...prev, classification: [record?.data]}
        });
        setSelectedIndex(record?.id);
        activeClassifier = record?.id
    }

    const columns = [
        // {
        //     name: <Tooltip placement='top' title={selectAll ? 'Unselect All' : 'Select All'}><Checkbox checked={selectAll} onChange={onSelectAllHandler} disabled={isEdit} /></Tooltip>,
        //     key: "is_selected",
        //     sortable: false,
        //     resizable: false,
        //     width: 40
        // },
        {
            name: 'Name',
            key: "name",
            sortable: false,
            resizable: false,
            width:150

        },
        {
            name: 'Description',
            key: "description",
            sortable: false,
            resizable: false,
            width: 300
        },
        {
            name: 'Pattern',
            key: "pattern",
            sortable: false,
            resizable: false,
            width: 'auto'
        },
        // {
        //     name: 'Created On',
        //     key: "created_on",
        //     sortable: false,
        //     resizable: false,
        //     width: 'auto'

        // },
        {
            name: 'Updated On',
            key: "updated_on",
            sortable: false,
            resizable: false,
            width: 'auto'

        },
    ]

    return (
        <>
            <Modal size="lg" isOpen={openModal} className="custom-modal" centered={true} onClosed={() => onCloseModal()}>
                <ModalHeader className='d-flex align-items-center' close={<Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />}>
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <div className='d-flex'>
                            <USERTAG height='20' width='20' />
                            <h2 className="section-heading fontInterSemiBold">Select Classifications</h2>
                        </div>
                        <div id='classification-modal-search' className='mr-2'>
                            <SearchInput searchData={searchData} setSearchData={setSearchData} />
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className="classification-modal" style={{ height: '400px' }}>
                    <LandingSimpleTable
                        cols={columns}
                        rowsData={data}
                        tableHeight={'100%'}
                        onRowClick={onRowClick}
                        selectedRowIndex={selectedIndex}
                    />
                </ModalBody>
                <ModalFooter>
                    <Buttons props={{
                        buttonText: 'Done',
                        buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                        buttonEvent: () => onDoneHandler(),
                        ImgSrc: () => <CHECK />,
                        isDisable: isDoneButtonDisable(),
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }}
                    />
                </ModalFooter>
            </Modal>
        </>
    )
}

const listTemplate = {
    selected_status: (checked = false, onChange = () => { }, index, isEdit) => {
        return <>
            <div className='d-flex h-100 align-items-center'>
                <Checkbox
                    checked={checked}
                    onChange={(val) => onChange(val, index)}
                    disabled={isEdit}
                />
            </div>
        </>
    },
    name: (text = '') => {
        return <>
            <div style={{ lineHeight: '1.4' }} className='classificationTag text-uppercase'>
                <Tooltip title={text} placement="topLeft">
                    {text}
                </Tooltip>
            </div>
        </>
    },
    description: (text = '') => {
        return <>
            <div className='text-with-ellipsis'>
                {text}
            </div>
        </>
    },
    pattern: (text = '') => {
        return <>
        {
            text === ''
            ?
            <div className='text-black-50'>
                N/A
            </div>
            :
            <div className='text-with-ellipsis'>
                {text}
            </div>
        }
        </>
    },
    created_on: (text = '') => {
        return <>
            <div className=''>
                {text}
            </div>
        </>
    },
    updated_on: (text = '') => {
        return <>
            <div className=''>
                {text}
            </div>
        </>
    }
}
