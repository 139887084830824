import { Switch, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { MultipleColsDndList } from './MultipleColsDndList'

import { getColumnDataType, getObjectLength, showSimpleLoading } from '../../../../common/helperFunctions'
import { ICON_ARROW_RIGHT, ICON_CLOSE } from '../../../../../Common/newIconSource'
import ColumnSelectPopover from './ColumnSelectPopover'
import { cloneDeep } from 'lodash'
import { useSelector, useStore } from 'react-redux'
import { fetchPreviewTable } from '../../../../../../store/modules/common/VirtualDataTable/action'
import { v4 as uuidv4 } from 'uuid'
import { getPreviewResultColumns } from '../FlowsHelperFunction'

const DndTargetColumnList = ({ actualData, isExecutePermission, currentStepKey, currentConditionTypeRef, setisrunDisabled, setopenWarningModal, resetBtnClick, setresetBtnClick, setshowResetColumns, NewFlowsRedux, setNewFlowsRedux, isAutoMapping, isdropTable, originalColumnList, settargetColumns, }) => {

    const [demo, setDemo] = useState([])
    const [parentActualData, setparentActualData] = useState([])
    const [columns, setColumns] = useState([])
    const [selectedColumn, setselectedColumn] = useState('');
    const [dropdownList, setdropdownList] = useState([...originalColumnList]);
    const [clickIndex, setclickIndex] = useState(-1);
    const [selectedTypeofTable, setselectedTypeofTable] = useState('Existing');
    const [showLoader, setshowLoader] = useState(false);
    const currentTransformer = useSelector(state => state.Lab.currentTransformer)
    const flowsElements = useSelector(state => state.Lab.flowsElements)
    const currentTargetCol = useRef([])
    const store = useStore()
    const selectedConn = useSelector((state) => state.LabTransformer.TargetTransformer.connectionDetails)

    const [colsList, setColsList] = useState([

        {
            key: 'frame', title: <div className='dnd-target-table-header1 d-flex align-items-center justify-centent-between'><span className='paragraph text-small col-10'>Columns</span>
                {selectedConn?.source_type === 's3' || selectedConn?.source_type === 'azure_adls' ? <span className='paragraph text-small col-2'>Enable Partition</span> : null}
            </div>, resizable: false, isRequired: false, disabled: false, visible: true, width: 90
        },
        { key: 'main_target', title: selectedConn?.source_type !== 's3' && selectedConn?.source_type !== 'azure_adls' ? <span className='paragraph text-small'>Target Table Schema</span> : null, resizable: false, isRequired: false, disabled: false, visible: true, width: 190 },
    ])

    const currentActivePopoverIndex = useRef(-1)

    const closePopup = () => {
        let dataCopy = [...parentActualData];
        currentActivePopoverIndex.current = -1;
        setclickIndex(-1);
        // settargetColumns([...dataCopy]);
        setparentActualData([...dataCopy]);


    }

    const onResetEvent = () => {

        currentConditionTypeRef.current = {
            message: 'Columns that are not found in input source that will be removed',
            onConfirm: () => {
                let cloneCols = cloneDeep(parentActualData);

                cloneCols = cloneCols.filter((item) => !item.showError && !item.showTargetColError);
                setparentActualData([...cloneCols])
                setMappedColumnsinRedux([...cloneCols])
                setopenWarningModal(false);
                setshowResetColumns(false);
                setisrunDisabled(false)
                setresetBtnClick(false)

                return true
            },
            onCancel: () => {
                setopenWarningModal(false);
                setresetBtnClick(false)
                return false
            }
        }
        setopenWarningModal(true)
    }

    useEffect(() => {
        if (resetBtnClick) {

            onResetEvent()
        }
    }, [resetBtnClick])

    const setMappedColumnsinRedux = (columns) => {
        let cloneDeepFlowsElements = {}
        if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes.length) {
            cloneDeepFlowsElements = cloneDeep(NewFlowsRedux)

        } else {
            cloneDeepFlowsElements = cloneDeep(flowsElements)

        }

        let finalNodes = cloneDeepFlowsElements?.nodes?.find((item) => item.id === currentTransformer.id);

        finalNodes['content']['mappedColumns'] = columns;

        cloneDeepFlowsElements?.nodes?.forEach((item) => {
            if (item.id === currentTransformer.id) {
                item = finalNodes
            }
            return item;
        });
        setNewFlowsRedux(cloneDeepFlowsElements);
    }

    const saveClick = () => {

        let dataCopy = [...parentActualData]
        let column = originalColumnList.find(item => item.Name === selectedColumn);

        dataCopy[currentActivePopoverIndex.current]['frame'] = selectedColumn;
        dataCopy[currentActivePopoverIndex.current]['selectedDataType'] = column?.Type;
        if (selectedTypeofTable?.toLowerCase() === 'new' || isdropTable) {
            dataCopy[currentActivePopoverIndex.current]['main_target'] = selectedColumn;
            dataCopy[currentActivePopoverIndex.current]['Type'] = column?.Type;
        }
        currentActivePopoverIndex.current = -1
        setclickIndex(-1);

        let clonedropdownList = cloneDeep(dropdownList);
        let selectedColumnNames = dataCopy.map((item) => item?.frame)
        clonedropdownList.forEach((item) => {
            if (selectedColumnNames?.length && selectedColumnNames?.includes(item.Name)) {
                item.isDisableOption = true;
            } else {
                item.isDisableOption = false;

            }
        });

        setparentActualData([...dataCopy]);
        setMappedColumnsinRedux(dataCopy)
    }

    const openPopover = (index) => {
        if (!isExecutePermission) {
            return;
        } else {


            let dataCopy = [...parentActualData];

            setselectedColumn(dataCopy[index]['frame'])
            currentActivePopoverIndex.current = index;

            setclickIndex(index);

            // settargetColumns([...dataCopy]);
            let clonedropdownList = cloneDeep(dropdownList);
            let selectedColumnNames = dataCopy.map((item) => item?.frame)
            clonedropdownList.forEach((item) => {
                if (selectedColumnNames?.length && selectedColumnNames?.includes(item.Name)) {
                    item.isDisableOption = true;
                } else {
                    item.isDisableOption = false;
                }
            });
            // setdropdownList(clonedropdownList);
            setparentActualData([...dataCopy]);

        }

    }

 

    useEffect(() => {


        const checkisDrop = async () => {

            let apiColumn = []
            if (selectedConn?.source_type?.toLowerCase() !== 's3' && selectedConn?.source_type?.toLowerCase() !== 'azure_adls') {

                apiColumn = await getTargetColumns();
            }
            currentTargetCol.current = apiColumn;
            let cloneDeepFlowsElements = cloneDeep(NewFlowsRedux)
            let finalNodes = cloneDeepFlowsElements?.nodes?.find((item) => item.id === currentTransformer.id);
            let transformerContent = finalNodes?.content;
            // console.log(apiColumn, isdropTable, transformerContent);

            if (isdropTable) {
                // if (window.confirm('Your mapping will be lost, are you sure want to continue?')) {
                let newlist = cloneDeep(originalColumnList);
                let newtargetColumns = newlist?.map((col) => {
                    col['frame'] = col.Name;
                    col['main_target'] = col.Name;
                    col['selectedDataType'] = col.Type;
                    col['target_column_datatype'] = col.Type
                    if ((selectedConn?.source_type?.toLowerCase() === 's3' || selectedConn?.source_type?.toLowerCase() === 'azure_adls')) {

                        col['is_partitioned'] = col.is_partitioned ?? false
                    }
                    return col;
                })
                setparentActualData(newtargetColumns);
                setMappedColumnsinRedux(newtargetColumns);
                setshowLoader(false)
                setisrunDisabled(false)

                // }

            } else {

                if (transformerContent?.mappedColumns?.length) {  /// if already mapping done then check selected frame and target columns present in new list or not
                    let newlist = cloneDeep(apiColumn);

                    let mappedColumns = cloneDeep(transformerContent?.mappedColumns);
                    let selectedFrameList = originalColumnList?.map((item) => item.Name)?.filter(ele => ele) ///input column name 
                    let selectedMain_target = newlist?.map((item) => item.main_target)?.filter(ele => ele) /// new target column name
                    let newtargetColumns = []
                    if (transformerContent?.targetTableDetails?.typeOfTable?.toLowerCase() === 'new') {
                        let array = []
                        if (newlist?.length) {
                            array = newlist
                        } else {
                            array = mappedColumns
                        }
                        newtargetColumns = array?.map((col, index) => {
                            col['frame'] = col?.Name ?? '';
                            col['main_target'] = col.Name;
                            col['selectedDataType'] = col?.Type ?? '';
                            col['target_column_datatype'] = col.Type
                            if ((selectedConn?.source_type?.toLowerCase() === 's3' || selectedConn?.source_type?.toLowerCase() === 'azure_adls')) {

                                col['is_partitioned'] = col.is_partitioned ?? false
                            }
                            if (col?.frame && selectedFrameList?.length && !selectedFrameList.includes(col?.frame)) {
                                col['showError'] = true;
                                setshowResetColumns(true)
                                setisrunDisabled(true)

                            } else {
                                col['showError'] = false;
                            }


                            return col;
                        });

                        let cloneTargetCol = [];

                        if (newlist?.length) {
                            cloneTargetCol = cloneDeep(newlist)
                        } else {
                            cloneTargetCol = cloneDeep(originalColumnList);
                        }


                        cloneTargetCol?.forEach((col, index) => {

                            let isColPresent = newtargetColumns.findIndex(item => item?.Name === col?.Name)
                            col['frame'] = col?.Name ?? '';
                            col['main_target'] = col.Name;
                            col['selectedDataType'] = col?.Type ?? '';
                            col['target_column_datatype'] = col.Type
                            if ((selectedConn?.source_type?.toLowerCase() === 's3' || selectedConn?.source_type?.toLowerCase() === 'azure_adls')) {

                                col['is_partitioned'] = col.is_partitioned ?? false
                            }
                            if (isColPresent === -1) {

                                newtargetColumns.push(col);
                            }
                        })
                    } else {
                        newtargetColumns = mappedColumns?.map((col, index) => {

                            if (col?.frame && selectedFrameList?.length && !selectedFrameList.includes(col?.frame)) {
                                col['showError'] = true;
                                setshowResetColumns(true)
                                setisrunDisabled(true)

                            } else {
                                col['showError'] = false;
                            }
                            if (col?.main_target && selectedMain_target?.length && !selectedMain_target.includes(col?.main_target)) {
                                col['showTargetColError'] = true;
                                setshowResetColumns(true)
                                setisrunDisabled(true)


                            } else {
                                col['showTargetColError'] = false;
                            }

                            return col;
                        });

                        newlist?.forEach((col, index) => {

                            let newmatchCol = originalColumnList.find(item => item?.Name === col?.Name);
                            let isColPresent = newtargetColumns.findIndex(item => item?.Name === col?.Name)
                            col['frame'] = newmatchCol?.Name ?? '';
                            col['main_target'] = col.Name;
                            col['selectedDataType'] = newmatchCol?.Type ?? '';
                            col['target_column_datatype'] = col.Type
                            if ((selectedConn?.source_type?.toLowerCase() === 's3' || selectedConn?.source_type?.toLowerCase() === 'azure_adls')) {

                                col['is_partitioned'] = col.is_partitioned ?? false
                            }
                            if (isColPresent === -1) {

                                newtargetColumns.push(col);
                            }
                        })

                    }

                    if (newtargetColumns?.length) {
                        setparentActualData(newtargetColumns);
                        setMappedColumnsinRedux(newtargetColumns)
                        setshowLoader(false)
                        setisrunDisabled(false)

                    }

                } else {
                    // || selectedConn?.source_type === 's3' || selectedConn?.source_type === 'azure_adls'
                    if ((transformerContent?.targetTableDetails?.targetColumns?.length) && apiColumn?.length) {
                        let newlist = cloneDeep(apiColumn);
                        let oldTargetNameList = transformerContent?.targetTableDetails?.targetColumns?.map((item) => item.Name);
                        let newtargetColumns = newlist?.map((col) => {
                            let matchCol = originalColumnList.find(item => item?.Name === col?.Name);
                            col['frame'] = matchCol?.Name ?? '';
                            col['main_target'] = col.Name;
                            col['selectedDataType'] = matchCol?.Type ?? '';
                            col['target_column_datatype'] = col?.Type
                            if ((selectedConn?.source_type?.toLowerCase() === 's3' || selectedConn?.source_type?.toLowerCase() === 'azure_adls')) {

                                col['is_partitioned'] = col.is_partitioned ?? false
                            }
                            if (oldTargetNameList?.length && !oldTargetNameList?.includes(col.Name)) {
                                col['showTargetColError'] = true;
                                setshowResetColumns(true)
                                setisrunDisabled(true)

                            } else {
                                col['showTargetColError'] = false;
                            }
                            return col;
                        })

                        setparentActualData(newtargetColumns);
                        setMappedColumnsinRedux(newtargetColumns)
                        setshowLoader(false)
                        setisrunDisabled(false)


                    } else if (!apiColumn?.length) {
                        let newlist = cloneDeep(originalColumnList);
                        let newtargetColumns = newlist?.map((col) => {
                            col['frame'] = col.Name;
                            col['main_target'] = col.Name;
                            col['selectedDataType'] = col.Type;
                            col['target_column_datatype'] = col.Type
                            if ((selectedConn?.source_type?.toLowerCase() === 's3' || selectedConn?.source_type?.toLowerCase() === 'azure_adls')) {

                                col['is_partitioned'] = col.is_partitioned ?? false
                            }
                            return col;
                        })
                        setparentActualData(newtargetColumns);
                        setMappedColumnsinRedux(newtargetColumns);
                        setshowLoader(false)
                        setisrunDisabled(false)

                    }
                }
            }
        }
        checkisDrop()
    }, [isdropTable,selectedConn])


    const getFieldTemplate = (key, index, value = '', additionalClasses = '', isDisable = false, column) => {

        switch (key) {
            case 'frame': return (
                <div className='d-flex align-items-center'>
                    <div className={`${selectedConn?.source_type === 's3' || selectedConn?.source_type === 'azure_adls' ? 'col-10' : 'col-12'} nd-list-content ${column?.showError ? 'invalid-column-present' : ''}`}>
                        <div className="align-items-center d-flex h-100 w-100">
                            {/* <div onClick={() => removeSelectedColumn(index)} className="cursor-pointer mr-1" style={{ width: '23px', height: '30px', borderRight: "1px solid #d3d3d3", display: "inline-flex", alignItems: "center" }}>
                            {" "}
                            <ICON_CLOSE color="#17171a" height="10" width="10" />
                        </div> */}
                            {getColumnDataType(column?.selectedDataType)}
                            {value?.trim()?.length > 0 ? <label className="mb-0 label text-with-ellipsis ml-1">
                                <Tooltip title={value}>{value}</Tooltip>
                            </label> :
                                <span style={{ fontSize: "10px", cursor: "pointer" }} onClick={() => { openPopover(index) }} className="mb-0 text-muted text-sm text-with-ellipsis ml-1">
                                    + Click to add column
                                </span>
                            }
                        </div>
                        <div className="d-flex align-items-center justify-content-end">


                            <div className="cursor-pointer" onClick={() => { openPopover(index) }} style={{ width: '23px', height: '30px', borderLeft: "1px solid #d3d3d3", display: "inline-flex", alignItems: "center", justifyContent: "end" }}>
                                <ICON_ARROW_RIGHT color="#17171a" height="12" width="12" />
                            </div>
                            <ColumnSelectPopover index={index} clickIndex={clickIndex} selectedColumn={selectedColumn} setselectedColumn={setselectedColumn} originalColumnList={dropdownList} closePopup={closePopup} saveClick={saveClick} >
                            </ColumnSelectPopover>


                        </div>
                    </div>
                    {(selectedConn?.source_type?.toLowerCase() === 's3' || selectedConn?.source_type?.toLowerCase() === 'azure_adls') ? <Switch
                        className='custom-switch-btn ml-4 '
                        checkedChildren="Yes" unCheckedChildren="No"
                        checked={column.is_partitioned}
                        onChange={(check) => {
                            let dataCopy = [...parentActualData]
                            dataCopy[index]['is_partitioned'] = check;
                            setparentActualData([...dataCopy]);
                            setMappedColumnsinRedux(dataCopy)
                        }}
                        disabled={false}
                    /> : null}
                </div>

            );
            case 'main_target':
                return <>

                    {!isdropTable && selectedTypeofTable?.toLowerCase() !== 'new' && selectedConn?.source_type !== 's3' && selectedConn?.source_type !== 'azure_adls' ? <div className={`list-without-drag custom-border-dashed-right ${column?.showTargetColError ? 'invalid-column-present' : ''} margin-left-2`}>
                        <div className="d-flex align-items-center w-50 ">
                            {getColumnDataType(column?.Type)}
                            <span className="paragraph">{column?.Name}</span>
                        </div>

                    </div> : null}

                </>
            default:
                break;
        }
    }

    const generateColumns = (columns_list) => {
        let cols = [], colsListCopy = [...columns_list]
        cols = colsListCopy.filter(v => v.visible === true).map(v => {
            return ({
                name: <label className='font-w-600 small mb-0'>
                    {v.title}
                    {v.isRequired ? <span><sup>*</sup></span> : ''}
                </label>,
                key: v.key,
                sortable: false,
                resizable: false,
                width: v.width,
                frozen: v.frozen ?? false
            })
        })

        return cols
    }

    const createRequestBodyHandler = (schemaName, tableName, connectionName) => {
        let id = uuidv4();

        let requestBody = {
            uuid: id,
            connection_name: connectionName,
            schema_name: schemaName,
            table_name: tableName,
            page: 0,
            do_count: false,
            sort_body: [],
            filter_body: [],
            table_ids: [],
            connectionName: connectionName,
            podName: tableName,
            dataDomain: schemaName,
            podIds: [],

        }

        return requestBody;
    }

    const getTargetColumns = async () => {
        setshowLoader(true)
        setisrunDisabled(true)
        let cloneDeepFlowsElements = cloneDeep(NewFlowsRedux)
        let finalNodes = cloneDeepFlowsElements?.nodes?.find((item) => item.id === currentTransformer.id);
        let transformerContent = finalNodes?.content;

        if (getObjectLength(transformerContent?.targetTableDetails)) {
            if (transformerContent?.targetTableDetails?.typeOfTable?.toLowerCase() !== selectedTypeofTable?.toLowerCase()) {
                setselectedTypeofTable(transformerContent?.targetTableDetails?.typeOfTable)
            }
        }

        try {
            const requestBody = createRequestBodyHandler(transformerContent?.targetTableDetails?.schemaName, transformerContent?.targetTableDetails?.tableName, transformerContent?.targetTableDetails?.connectionName);

            let column = [];
            let newTargetCol = [];
            column = await store.dispatch(getPreviewResultColumns(requestBody, false, false));

            if (column?.length) {
                column?.forEach(
                    (item, index) => {
                        newTargetCol.push({ id: index, Name: item?.Name, Type: item?.Type, checked: false, frame: '', main_target: item?.Name, selectedDataType: '', target_column_datatype: item?.Type })
                    }
                )
            } else {
                setshowLoader(false)
            }

            return newTargetCol;

        }
        catch (error) {
            setshowLoader(false)
            setisrunDisabled(false)

            return []
        }


    }






    useEffect(() => {
        let temp = [], cols = [], obj = {};
        let rows = [...parentActualData]
        temp = rows.map((v, i) => {

            Object.keys(v).forEach(key => obj[key] = getFieldTemplate(key, i, v[key], '', key === 'old_name' ? true : v[key]?.disabled, v))
            return { ...obj }

        })
        setDemo([...temp])
        cols = [...generateColumns(colsList)]
        setColumns([...cols])

    }, [parentActualData, selectedColumn, clickIndex, selectedTypeofTable, selectedConn, dropdownList])



    return (
        <>
            <div className='custom-card padding-2 bg-white pb-0' style={{ height: '100%', overflow: 'hidden' }}>
                <div className='merge-dnd-list' style={{ overflow: 'auto', height: 'calc(100vh - 350px)' }}>
                    {!showLoader ? <MultipleColsDndList
                        setreOrderRender={() => { }}
                        colsList={columns}
                        rowsList={demo}
                        stopDraggingRow={true}
                        setData={() => {

                        }}
                        headerRowHeight={34}
                        rawData={parentActualData}
                    /> : showSimpleLoading('Please wait columns are loading')}
                </div>
            </div>
        </>
    )
}

export default DndTargetColumnList;
