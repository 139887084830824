import React from 'react'
import { useStore } from "react-redux"

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Buttons from '../../../Common/button/Buttons';
import { CHECK, CLOSE } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';
import { SET_FLOWS_ALERT_TRANSFORMER_LIST } from '../../../../store/modules/alert/createAlert/createAlertActiontype';
import { ICON_FLOWS } from '../../../Common/newIconSource';

import PODSelectionList from '../../flowsNew/transformers/dataSource/PODSelectionList';
import { cloneDeep } from 'lodash';
import { setSelectedPodList } from '../../../../store/modules/common/podSelectionModalAction';




const PODSelectionNewModal = ({ selectedPodDetails, setselectedPodDetails, isModalOpen, onClose, fromRoute = '', save, onRowSelection, data }) => {

    const store = useStore();

    const checkedSelectedRow = (record) => {


        setselectedPodDetails((selectedPODsList) => {
            const newList = [...selectedPODsList];
         
            const index = newList.findIndex((ele) => ele?.table_id === record?.table_id);

            if (index === -1) {
                newList.push(record)
            }
            store.dispatch(setSelectedPodList(newList));

            return newList;
        });

        onRowSelection(record);


    }

    return (
        <Modal size="xl" isOpen={isModalOpen} className="custom-modal scheduler-flow-modal" centered={true}>
            <ModalHeader>

                <div className="paragraph font-w-600">Select POD</div>
                <Buttons props={{ tooltip: 'Close', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon float-right", buttonEvent: () => { onClose() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />

            </ModalHeader>
            <ModalBody className='overflow-hidden'>
                <div className='pod-select-modal'>
                    <PODSelectionList onRowSelection={checkedSelectedRow} data={data} fromRout={fromRoute} />
                </div>
            </ModalBody>
            <ModalFooter>
                <Buttons props={{ tooltip: 'Done', buttonText: 'Done', buttonClassName: " custom-btn-primary custom-btn btn-with-text float-right", buttonEvent: () => { save(selectedPodDetails); onClose() }, ImgSrc: () => <CHECK />, isDisable: data && data?.table_id ? false : true, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />

            </ModalFooter>

        </Modal >
    )
}

export default PODSelectionNewModal
