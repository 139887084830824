import React, { useEffect, useMemo, useRef, useState } from "react";


import { connect, useDispatch, useSelector, useStore } from "react-redux";


import { useParams } from "react-router";

import { checkOutcomeColumnPresent, getInputTransformerDetails, orderRetainCheck } from "../commonFunctions/FlowsHelperFunction";
import _, { cloneDeep, update } from "lodash";
import { getColumnDataType, getConfirmationModal, getObjectLength, mergeArrayByProperty, showSimpleLoading } from "../../../common/helperFunctions";
import { SCRIPTDEFAULTVIEW } from "../commonFunctions/ScriptDefaultInfography";
import { DATASET, INPUT, RESETFILTER, TABLEICON } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import Buttons from "../../../../Common/button/Buttons";
import TargetStepsContainer from "./TargetStepsContainer";
import { getConnectionListCreatePODAction } from "../../../../../store/actions";
import { setConnectionDataList, setConnectionSelectedConn } from "../../../../../store/modules/UserManagement/connection/connectionActions";
import { ConnectionProvider } from "../../../connections/landingPage/components/ConnectionContext";
import ConnectionSelectStep1 from "./ConnectionSelectStep1";
import TargetConnectionModalStep2 from "./TargetConnectionModalStep2";

import '../../Newflow.scss'
import { resetTargetRedux, setConnectionDetails, setTargetTableDetails } from "../../../../../store/modules/flows/NewFlowsRedux/targetTransformer/targetTransformer";

import SourceTypeImage from "../../../common/sourceTypeImage";
import DndTargetColumnList from "../commonFunctions/dndMultiColumnsVirtualList/DndTargetColumnList";

import { updateFlowsElements } from "../../../../../store/modules/flows/NewFlowsRedux/flowsActions";
import { Tooltip } from "antd";

const TargetIndex = ({
  flowsElements,
  setshowOverlay,
  currentTransformer,
  setTabsList,
  TabsMenuList,
  updatedFlowRedux,
  setupdatedFlowRedux,
  isExecutePermission,
  setisrunDisabled,
  sethideExploreTab,
  setshowCloseWarning,
  ...props
}) => {
  const [columnList, setcolumnList] = useState([]);
  const [originalColumnList, setoriginalColumnList] = useState([]);
  const [currentStepKey, setcurrentStepKey] = useState();
  const [reOrderRender, setreOrderRender] = useState(true)
  const [searchValue, setsearchValue] = useState('')

  const [openWarningModal, setopenWarningModal] = useState(false)
  const [showResetColumns, setshowResetColumns] = useState(false)
  const [resetBtnClick, setresetBtnClick] = useState(false)
  const [isdropTable, setisdropTable] = useState(false);
  const [isAutoMapping, setisAutoMapping] = useState(false);
  const [toggleValue, setToggleValue] = useState("overwrite");
  const connectionType = useSelector((state) => state.LabTransformer.TargetTransformer.connectionDetails)?.source_type;
  const [NewFlowsRedux, setNewFlowsRedux] = useState({});
  const [targetColumns, settargetColumns] = useState([]);
  const [showLoader, setshowLoader] = useState(false);

  const [schemaName, setschemaName] = useState("");
  const [sourceType, setsourceType] = useState("");
  const [tableName, settableName] = useState("");
  const [connectionName, setconnectionName] = useState("");


  const store = useStore();
  const { flowId } = useParams();

  const dispatch = useDispatch();

  const [toggleDefaultView, setToggleDefaultView] = useState(false);

  useEffect(() => {

    if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes?.length) {

      setupdatedFlowRedux(NewFlowsRedux);


      let content = NewFlowsRedux?.nodes?.find(
        (ele) => ele.id === currentTransformer.id
      )?.content;


      if (content?.mode === 'update') {
        sethideExploreTab(true);

      } else {
        sethideExploreTab(false);
      }


      let targetTableDetails = content?.targetTableDetails;

      if (
        targetTableDetails &&
        targetTableDetails.targetColumns?.length
      ) {
        let targetCol = cloneDeep(targetTableDetails.targetColumns)
        let newtargetColumns = targetCol?.map((col) => {
          col['frame'] = ''
          col['main_target'] = col.Name
          col['target_column_datatype'] = col.Type
          return col;
        })


        // settargetColumns(newtargetColumns);
        // setschemaName(targetTableDetails.schemaName);
        // setsourceType(targetTableDetails.sourceTypeImage);
        // settableName(targetTableDetails.tableName);
        // setconnectionName(targetTableDetails.connectionName);
      }

      setschemaName(targetTableDetails?.schemaName ?? '');
      setsourceType(targetTableDetails?.sourceTypeImage ?? '');
      settableName(targetTableDetails?.tableName ?? '');
      setconnectionName(targetTableDetails?.connectionName ?? '');
    }
  }, [NewFlowsRedux])

  useEffect(() => {
    if (toggleValue !== 'update' && currentStepKey !== 2) {
      setisrunDisabled(true)
    }
    if (toggleValue === 'update' && currentStepKey === 1) {
      sethideExploreTab(true)
    }
  }, [toggleValue, currentStepKey])


  useEffect(() => {
    if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {
      setNewFlowsRedux(updatedFlowRedux);
    }
  }, [])


  const Selectall = (checked) => {
    if (checked) {
      let temp = columnList.map((ele) => {
        ele.checked = true;
        return ele;
      });
      setshowCloseWarning(true)

      setcolumnList([...temp]);
    } else {
      let temp = columnList.map((ele) => {

        ele.checked = false;
        return ele;
      });
      setshowCloseWarning(true)

      setcolumnList([...temp]);
    }
  };

  useEffect(() => {
    if (currentStepKey === 2) {
      if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes?.length) {
        store.dispatch(updateFlowsElements(NewFlowsRedux))

        let content = NewFlowsRedux?.nodes?.find(
          (ele) => ele.id === currentTransformer.id
        )?.content;

        let targetTableDetails = content?.targetTableDetails;

        if (
          targetTableDetails &&
          targetTableDetails.targetColumns?.length
        ) {
          let targetCol = cloneDeep(targetTableDetails.targetColumns)
          let newtargetColumns = targetCol?.map((col) => {
            col['frame'] = ''
            col['main_target'] = col.Name
            col['target_column_datatype'] = col.Type
            return col;
          })
          settargetColumns(newtargetColumns);
          setisrunDisabled(false);
          let cloneTabs = cloneDeep(TabsMenuList);

          cloneTabs[1].disabled = false;

          setTabsList(cloneTabs);

        }
        if (getObjectLength(targetTableDetails)) {
          setschemaName(targetTableDetails?.schemaName ?? '');
          setsourceType(targetTableDetails?.sourceTypeImage ?? '');
          settableName(targetTableDetails?.tableName ?? '');
          setconnectionName(targetTableDetails?.connectionName ?? '');

        }

        if (connectionType === 's3' || connectionType === 'azure_adls') {
          setisrunDisabled(false);
          let cloneTabs = cloneDeep(TabsMenuList);

          cloneTabs[1].disabled = false;

          setTabsList(cloneTabs);


        }


      }
    } else {
      setisrunDisabled(true);

      let cloneTabs = cloneDeep(TabsMenuList);
      cloneTabs[1].disabled = true;
     
      setTabsList(cloneTabs)

    }

    // if (currentStepKey === 1) {
    //   if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes?.length) {
    //     store.dispatch(updateFlowsElements(NewFlowsRedux))

    //     let content = NewFlowsRedux?.nodes?.find(
    //       (ele) => ele.id === currentTransformer.id
    //     )?.content;

    //     if (content?.mode === 'update') {
    //       let cloneTabs = cloneDeep(TabsMenuList);
    //       cloneTabs[1].disabled = false;
    //       setTabsList(cloneTabs)

    //     }

    //   }


    // }

  }, [currentStepKey, TabsMenuList])

  const getDataType = (actualDataType = '', index) => {

    if (actualDataType.includes('(')) {
      return actualDataType.split('(')[0]
    } else {
      return actualDataType
    }

  }
  const getDataTypeFormat = (actualDataType = '', index) => {

    if (actualDataType.includes('(')) {
      let splitStr = actualDataType.split('(');

      return splitStr[1]?.split(')')[0];
    } else {
      return ''
    }

  }

  const createColumnsList = (target = [], source = [], prop) => {

    let newColumnList = [];
    if (!target?.length) {
      source.forEach(sourceElement => {
        sourceElement['showError'] = false
        sourceElement['old_name'] = sourceElement.Name
        sourceElement['new_name'] = sourceElement.Name
        sourceElement['new_datatype'] = getDataType(sourceElement?.Type)
        sourceElement['dataType'] = getDataType(sourceElement?.Type)
        sourceElement['format'] = getDataTypeFormat(sourceElement?.Type)
        sourceElement['text'] = sourceElement.text
        newColumnList.push(sourceElement);
      })
      return newColumnList;
    } else {

      let elementFound = iselementFound();
      let noneFindElement = isnoneFindElement();


      if (elementFound?.length && noneFindElement?.length) {
        newColumnList = [...elementFound, ...noneFindElement]
        // setshowResetColumns(true);
        // setisrunDisabled(true)
      }
      else if (elementFound?.length) {
        newColumnList = [...elementFound]

      } else if (noneFindElement?.length) {
        newColumnList = [...noneFindElement]
        // setshowResetColumns(true);
        // setisrunDisabled(true)


      } else {
        // setshowResetColumns(false)
        // setisrunDisabled(false)



      }
      let cloneSource = cloneDeep(source)

      const getChecked = (column) => {
        if (elementFound?.length) {
          return elementFound.find(item => item?.Name === column?.Name)?.checked
        } else if (target?.length) {

          return target.find(item => item?.Name === column?.Name)?.checked
        }
      }
      cloneSource = cloneSource.map(sourceElement => {

        sourceElement['showError'] = false;
        sourceElement['checked'] = getChecked(sourceElement) ?? false;
        sourceElement['old_name'] = sourceElement.Name
        sourceElement['new_name'] = sourceElement.Name
        sourceElement['new_datatype'] = getDataType(sourceElement?.Type)
        sourceElement['dataType'] = getDataType(sourceElement?.Type)
        sourceElement['format'] = getDataTypeFormat(sourceElement?.Type)
        sourceElement['text'] = sourceElement?.text

        return sourceElement;
      })

      // return (mergeArrayByProperty(cloneSource, newColumnList, 'Name', true))

      let mergeList = (mergeArrayByProperty(cloneSource, newColumnList, 'Name', true))  //----------------------------this will return unique column remove duplicates from source columns i.e if duplicate found it will take newColumnList column
      let newcurrentTransformer = {}

      if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

        newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id)
      } else {
        newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

      }

      let orderList = newcurrentTransformer?.content?.orderList;
      return orderRetainCheck(orderList, mergeList);
    }

    function isnoneFindElement() {

      if (target?.length) {
        let targetElement = cloneDeep(target)
        source.forEach(sourceElement => {
          targetElement = targetElement.filter((item) => {
            if (item['Name'] !== sourceElement['Name']) {
              item['showError'] = true;
              item['checked'] = true;
              item['text'] = sourceElement['text']

              return item;
            }
          })
        })
        return targetElement
      }

    }

    function iselementFound() {

      if (target?.length) {
        let targetElement = cloneDeep(target)
        let foundEleList = []
        source.forEach(sourceElement => {
          targetElement.forEach((item) => {

            if (item['Name'] === sourceElement['Name']) {
              let obj = cloneDeep(sourceElement)
              obj['showError'] = false;
              obj['old_name'] = item.old_name
              obj['new_name'] = item.new_name
              obj['new_datatype'] = getDataType(item?.new_datatype)
              obj['format'] = item?.format
              obj['checked'] = true;
              obj['text'] = item['text']

              foundEleList.push(obj)
            }
          })
        })
        return foundEleList


      }

    }



  }


  useEffect(() => {

    const getColumn = async () => {
      let InputTransformer = dispatch(getInputTransformerDetails());

      // let inputColumns = InputTransformer?.length ? cloneDeep(InputTransformer[0]?.content?.outcomeColumns) : [];
      let inputColumns = [];

      if (InputTransformer && InputTransformer?.length) {
        setshowLoader(true)
        inputColumns = await dispatch(checkOutcomeColumnPresent(InputTransformer[0]));

      }

      let newcurrentTransformer = {}

      if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

        newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id)
      } else {
        newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

      }

      let transformerContent = newcurrentTransformer.content;

      setshowLoader(false);

      if (transformerContent?.isdropTable === true) {
        setisdropTable(transformerContent?.isdropTable);
      }
      if (transformerContent?.mode) {
        setToggleValue(transformerContent?.mode?.toLowerCase())
      }

      if (
        transformerContent &&
        !_.isEmpty(transformerContent) &&
        "targetTableDetails" in transformerContent &&
        "connectionDetails" in transformerContent
      ) {
        ///////////////////////////////This logic for old target compatiable/////////////////////
        if (transformerContent?.orderList?.length && transformerContent?.targetTableDetails?.targetColumns?.length) {


          setcurrentStepKey(2)
          store.dispatch(
            setConnectionDetails(transformerContent.connectionDetails)

          );
          store.dispatch(setConnectionSelectedConn(transformerContent.connectionDetails));

          store.dispatch(
            setTargetTableDetails(transformerContent.targetTableDetails)
          );

          let orderList = transformerContent?.orderList;
          let newtargetColumns = transformerContent?.targetTableDetails.targetColumns?.map((col, index) => {
            col['frame'] = orderList[index]?.Name;
            col['main_target'] = col.Name;
            col['target_column_datatype'] = col.Type;
            col['selectedDataType'] = orderList[index]?.Type;
            return col;
          })

          let cloneDeepFlowsElements = cloneDeep(flowsElements);
          cloneDeepFlowsElements['nodes'] = cloneDeepFlowsElements?.nodes?.map((item) => {
            if (item?.id?.toString() === currentTransformer?.id?.toString()) {
              item['content']['mappedColumns'] = newtargetColumns;
            }
            return item
          })


          if (!getObjectLength(NewFlowsRedux)) {
            setNewFlowsRedux(cloneDeepFlowsElements);
          }



        } else {
          store.dispatch(
            setConnectionDetails(transformerContent.connectionDetails)

          );
          store.dispatch(setConnectionSelectedConn(transformerContent.connectionDetails));

          store.dispatch(
            setTargetTableDetails(transformerContent.targetTableDetails)
          );

          if (!getObjectLength(NewFlowsRedux)) {
            setNewFlowsRedux(flowsElements);
          }
          if (transformerContent?.mode === 'update') { //if mode update then enable the preview tab
            setcurrentStepKey(1)
          }
          if (transformerContent?.connectionDetails?.source_type === 's3' || transformerContent?.connectionDetails?.source_type === 'azure_adls') {
            setcurrentStepKey(2)

          }
          if (transformerContent?.targetTableDetails?.targetColumns?.length) {
            setcurrentStepKey(2)

          }

        }


      } else {
        store.dispatch(resetTargetRedux());
        setcurrentStepKey(0)
        dispatch(setConnectionSelectedConn([]));

      }

      if (InputTransformer && InputTransformer?.length && inputColumns?.length) {

        let column = []


        // if (!newcurrentTransformer?.content?.appliedFilterCriteria && newcurrentTransformer?.content?.outcomeColumns && newcurrentTransformer?.content?.outcomeColumns[0]?.text) {//// this need to remove after some time its used for backword compatability
        //   column = createColumnsList(newcurrentTransformer?.content?.outcomeColumns, inputColumns, 'Name')

        // } else {

        column = createColumnsList([], inputColumns, 'Name')
        // }

        if (column?.length) {

          // setcolumnList([...column])
          setoriginalColumnList([...column])

          setToggleDefaultView(false);
          setshowOverlay(false)

        } else {

          // setcolumnList([])
          setoriginalColumnList([])

          setshowOverlay(true)

        }


      } else if (!InputTransformer?.length) {
        setToggleDefaultView(true);

      } else if (!inputColumns?.length) {
        setshowOverlay(true);
      }
    }
    getColumn()

    return () => {
      dispatch(setConnectionSelectedConn([]));

    }

  }, []);

  useEffect(() => {
    if (showResetColumns) {
      setisrunDisabled(true)
    }
  }, [showResetColumns])

  const currentConditionTypeRef = useRef(null)

  const listAllConnectionsHandler = () => {
    try {
      store.dispatch(getConnectionListCreatePODAction(true)).then((res) => {
        store.dispatch(setConnectionDataList(res.data));
      });
    } catch (error) {
      console.error("Error Fetching Connections List", error.message);
    }
  };

  useEffect(() => {
    listAllConnectionsHandler();

  }, []);

  const getStepContent = useMemo(() => {
    switch (currentStepKey) {
      case 0:
        return <ConnectionProvider>
          <ConnectionSelectStep1 isExecutePermission={isExecutePermission} searchValue={searchValue} />
        </ConnectionProvider>
      case 1:
        return <>
          <TargetConnectionModalStep2 isExecutePermission={isExecutePermission} NewFlowsRedux={NewFlowsRedux} selectedMode={toggleValue}
            setNewFlowsRedux={setNewFlowsRedux} setisrunDisabled={setisrunDisabled} steps={currentStepKey} onClose={() => { }} connectionType={connectionType} />
        </>
      case 2:
        return <>
          <>
            <div className="d-flex bg-white  section-with-drag">
              <div className="w-50 custom-border-right">
                <div className="d-flex justify-content-between padding-2 py-1 custom-border-bottom">
                  <div className="d-flex align-items-center">
                    <INPUT />
                    <p className="paragraph ml-2 one-line-ellipsis custom-ellipsis">
                      Input Columns
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    {/* <div className="content-with-checkbox">
                                <Checkbox />
                                <p className="custom-ellipsis one-line-ellipsis">Distinct Records</p>
                            </div> */}
                    {/* <Buttons props={{ buttonText: '', buttonClassName: "custom-btn ml-2 custom-btn-outline btn-with-icon", buttonEvent: () => { onAllChecked() }, ImgSrc: () => <SELECTALL />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} /> */}
                  </div>
                </div>
                {toggleValue === "overwrite" && connectionType !== 's3' && connectionType !== 'azure_adls' ? ( //hide for s3 adls
                  <div className="d-flex justify-content-end align-items-center">

                    <div
                      className="d-flex align-items-center mt-2 justify-content-center"
                      style={{ padding: "6px" }}
                    >
                      <input
                        type="checkbox"
                        className="cursor-pointer"
                        disabled={
                          window.location.href.includes("viewFlows") || !isExecutePermission
                            ? true
                            : false
                        }
                        checked={isdropTable}
                        onChange={(e) => {
                          if (window.confirm('Your mapping will be lost, are you sure want to continue?')) {
                            let cloneDeepFlowsElements = {}
                            if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes.length) {
                              cloneDeepFlowsElements = cloneDeep(NewFlowsRedux)

                            } else {
                              cloneDeepFlowsElements = cloneDeep(flowsElements)

                            }
                            cloneDeepFlowsElements?.nodes.forEach((item) => {
                              if (item.id === currentTransformer.id) {
                                item.content.isdropTable = e.target.checked;
                              }

                            });
                            // cloneDeepFlowsElements['nodes'] = finalFlowsElements;
                            setNewFlowsRedux(cloneDeepFlowsElements)
                            setisdropTable(e.target.checked)
                          }
                        }}
                      />
                      <span className="paragraph ml-1 font-weight-bold">
                        Drop table and re-create schema
                      </span>
                    </div>

                  </div>
                ) : null}


              </div>
              <div className="w-50">
                <div className="d-flex justify-content-between custom-border-bottom padding-2 py-1">
                  <div className="d-flex align-items-center">
                    <DATASET width="18" height="18" />
                    <p className="paragraph ml-2">Target Table</p>
                  </div>

                </div>

                <div className="d-flex custom-border-bottom padding-2 height-60">
                  <div className="d-flex w-50">
                    <SourceTypeImage dataName={sourceType} />
                    <div className="pl-1 custom-flex-width">
                      <p className="label">Connection Name</p>
                      <p className="text-uppercase paragraph custom-ellipsis one-line-ellipsis">
                        <Tooltip title={connectionName}> {connectionName} </Tooltip>
                      </p>
                    </div>
                  </div>

                  <div className="d-flex w-50">
                    <span style={{ marginRight: "10px" }}>
                      <TABLEICON width={"18"} height={"18"} />
                    </span>
                    <div className="pl-1 custom-flex-width">
                      <p className="label">Table Name</p>
                      <p className="text-uppercase paragraph custom-ellipsis one-line-ellipsis">
                        <Tooltip title={tableName}>{tableName}</Tooltip>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DndTargetColumnList isExecutePermission={isExecutePermission} currentStepKey={currentStepKey} actualData={targetColumns} NewFlowsRedux={NewFlowsRedux} setshowResetColumns={setshowResetColumns} resetBtnClick={resetBtnClick} setresetBtnClick={setresetBtnClick} currentConditionTypeRef={currentConditionTypeRef} setisrunDisabled={setisrunDisabled} setopenWarningModal={setopenWarningModal}
              setNewFlowsRedux={setNewFlowsRedux} isAutoMapping={isAutoMapping} isdropTable={isdropTable} settargetColumns={settargetColumns} originalColumnList={originalColumnList} />

          </>
        </>
      case 3:
        return <></>
      default:
        break;
    }
  }, [NewFlowsRedux, toggleValue, resetBtnClick, currentStepKey, targetColumns]);


  return (
    <>
      {
        getConfirmationModal({
          open: openWarningModal,
          setOpen: setopenWarningModal,
          body: currentConditionTypeRef?.current?.message,
          onConfirm: currentConditionTypeRef?.current?.onConfirm,
          onCancel: currentConditionTypeRef?.current?.onCancel
        })
      }
      <div className="transformer-details-maindiv ">
        {/* pt-2 */}
        {showResetColumns && currentStepKey === 2 ? (
          <Buttons
            props={{
              tooltip: 'Reset Columns',
              buttonText: "Reset Columns",
              buttonClassName: "custom-btn-outline btn-outline reset-col-btn custom-btn btn-with-text ml-2 mr-2",
              buttonEvent: () => { setresetBtnClick(true) },
              toggleBtnValue: "",
              ImgSrc: () => <RESETFILTER />,
              isDisable: !isExecutePermission,
              buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              toggleBtnOption: null,
            }}
          />
        ) : null}
        <>
          {!toggleDefaultView ? (
            showLoader ? showSimpleLoading('Please wait target is loading...') : <div className="target-transformer-main-div h-100">
              <TargetStepsContainer
                {...{
                  flowsElements,
                  setshowOverlay,
                  currentTransformer,
                  NewFlowsRedux,
                  setNewFlowsRedux,
                  setisrunDisabled,
                  isExecutePermission,
                  setshowCloseWarning,
                  setisdropTable,
                  currentStepKey,
                  isdropTable,
                  toggleValue,
                  setToggleValue,
                  showResetColumns,
                  setcurrentStepKey,
                }}
              />
              {getStepContent}
            </div>
          ) : (
            <div className="source-not-found">
              <SCRIPTDEFAULTVIEW />
            </div>
          )}
        </>

      </div>



    </>
  );
};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.Lab.flowsElements,
    currentTransformer: state.Lab.currentTransformer,
  };
};
export default connect(mapStateToProps)(TargetIndex);
