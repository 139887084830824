import React ,{useEffect,useState} from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Buttons from '../../../Common/button/Buttons';
import { CLOSE, USERTAG,CHECK, PLUS } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';
import { getRelativeTime, getTime, preventSpecialCharsRegex } from '../../common/helperFunctions';
import { createClassificationData, updateClassificationData } from "../../../../store/modules/dataDictionary/action";
import { emitToastNotification } from '../../../../helpers/toast_helper';
import { useDispatch } from 'react-redux';
import _ from 'lodash'
import { ICON_CHECK, ICON_CLOSE, ICON_EDITBOX, ICON_SAVE, ICON_WARNING } from '../../../Common/newIconSource';
import { Switch } from 'antd';

const initialFieldValue = {
    name: '',
    description:'',
    pattern: '',
    pii: false
}
const SettingsClassificationModal = ({
        openModal=false, 
        setOpenModal=()=>{}, 
        getList=()=>{},
        viewOnly=false,
        setViewOnly=()=>{},
        selectedData={},
        isEdit=false,
        setIsEdit=()=>{}
}) => {
    const dispatch = useDispatch();

    const [fieldsValue, setFieldsValue] = useState(
        {
            ...initialFieldValue
        }
    )
    const [isLoading, setIsLoading] = useState(false)
    const [regexPassed, setRegexPassed] = React.useState(null)
    const [regexTestInput, setRegexTestInput] = React.useState('')

    useEffect(()=>{
        if(isEdit) {
            setFieldsValue(
                {
                    name: selectedData?.name?.toUpperCase(),
                    description: selectedData?.description,
                    pattern: selectedData?.pattern,
                    pii: selectedData?.pii
                }
            )
        }
    },[isEdit, selectedData])

    const handleFields = (value, key) => {
        if(key === 'name') {
            value = value.toUpperCase()
        };
        setFieldsValue(prev=>({...prev, [key]: value}))
    }

    const handleAdd = () => {
        setIsLoading(true)
        let payload = {...fieldsValue}
        dispatch(createClassificationData(payload))
        .then(()=>{
            getList()
            setIsLoading(false)
            setFieldsValue({...initialFieldValue})
            emitToastNotification('success', Literals.ADD_SUCCESSFUL)
            setOpenModal(false)
        })
        .catch(()=>{
            emitToastNotification('error', Literals.ADD_FAILED)
            setIsLoading(false)
        })
    }
    
    const handleUpdate = () => {
        setIsLoading(true)
        let payload = {...fieldsValue}
        dispatch(updateClassificationData(selectedData.id, payload))
        .then(()=>{
            getList()
            setIsLoading(false)
            setOpenModal(false)
            emitToastNotification('success', Literals.UPDATE_SUCCESSFUL)
        })
        .catch(()=>{
            emitToastNotification('error', Literals.UPDATE_FAILED)
            setIsLoading(false)
        })
    }

    const handleRegexResult = (val, currentRegex) => {
        try {
            const currRegex = currentRegex ?? fieldsValue?.pattern
            if(val?.trim() === '' || currRegex?.trim() === '') {
                setRegexPassed(null)
                return
            }
            
            const regex = new RegExp(currRegex, 'i')
            setRegexPassed(regex?.test(val))
        }
        catch(err) {
            console.error("INVALID REGEX ERROR: ", err)
            setRegexPassed('invalid')
        }
    }

    const submitButton = {
        getText: () => {
            if(isEdit) {
                if(isLoading) return 'Updating...'
                else if(viewOnly) return 'Edit'
                return 'Update'
            }
            else {
                if(isLoading) return 'Saving...'
                return 'Save'
            }
        },
        onClick: () => {
            if(isEdit) {
                if(viewOnly) setViewOnly(false)
                else handleUpdate()
            }
            else handleAdd()
        },
        getIcon: () => {
            if(isLoading) return <i className='bx bx-loader-alt bx-spin mr-1'></i>
            else if(isEdit) {
                if(viewOnly) return <ICON_EDITBOX/>
                else return <CHECK/>
            }
            else return <ICON_SAVE/>
        },
        getDisableStatus: () => {
            return _.isEmpty(fieldsValue.name.trim()) || isLoading
        }
    }

    const handleClose = () => {
        setOpenModal(false)
        setViewOnly(false)
        setIsEdit(false)
        setFieldsValue({...initialFieldValue})
        setRegexPassed(null)
        setRegexTestInput('')
    }
    
    return (
    <Modal size="lg" isOpen={openModal} className="custom-modal" centered={true} onClosed={() => handleClose()}>
        <ModalHeader className='d-flex align-items-center' close={<Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType:Literals.BTN_TERTIARY}} />}>
            <div className='d-flex align-items-center justify-content-between w-100'>
                <div className='d-flex'>
                    <USERTAG height='18' width='18' />
                    <h2 className="section-heading">
                        {

                            viewOnly?"View Classification":
                            isEdit
                            ?
                            'Edit Classification'
                            :
                            'Create Classification'
                        }
                    </h2>
                </div>
            </div>                  
        </ModalHeader>
        <ModalBody className='position-relative' style={{overflow: 'hidden'}}>
            <div className='px-2'>
                <div className='row'>
                    <div className='col-4 mb-2'>
                        <label className='font-w-600 grey-color label'>Classification Name</label>
                        <div>
                            <input
                                required
                                placeholder=''
                                type="text"
                                value={fieldsValue.name}
                                onChange={(e) => {
                                    if(preventSpecialCharsRegex.test(e.target.value) || e.target.value === '') {
                                        handleFields(e.target.value, 'name') 
                                    }
                                }}
                                className={`custom-input-field`}
                                disabled={viewOnly?true: false}
                            />
                        </div>
                    </div>
                    <div className='col-8 mb-2'>
                        <label className='font-w-600 grey-color label'>Description</label>
                        <div>
                            <input
                                required
                                placeholder=''
                                type="text"
                                value={fieldsValue.description}
                                onChange={(e) => handleFields(e.target.value, 'description')}
                                className={`custom-input-field`}
                                disabled={viewOnly?true: false}
                            />
                        </div>
                    </div>
                    <div className='col-12 mb-2 mt-2'>
                        {/* <div className='col-12 justify-content-between d-flex align-items-center px-0 mb-2'>
                            <span className='fontInterSemiBold fontSizeHeading'>Regex Pattern</span>
                        </div> */}
                        <div className='col-12 px-0'>
                            <div className='row'>
                                {/* <div className='col-4 mb-2'>
                                    <label className='font-w-600 grey-color label'>Data Owners</label>
                                    <div>
                                        <input
                                            required
                                            // placeholder='regex pattern'
                                            type="text"
                                            // value={fieldsValue?.pattern}
                                            onChange={(e) => {
                                                let val = e?.target?.value
                                            }}
                                            className={`custom-input-field`}
                                            disabled={viewOnly}
                                        />
                                    </div>
                                </div>
                                
                                <div className='col-4 mb-2'>
                                    <label className='font-w-600 grey-color label'>Data Stewards</label>
                                    <div>
                                        <input
                                            required
                                            // placeholder='regex pattern'
                                            type="text"
                                            // value={fieldsValue?.pattern}
                                            onChange={(e) => {
                                                let val = e?.target?.value
                                            }}
                                            className={`custom-input-field`}
                                            disabled={viewOnly}
                                        />
                                    </div>
                                </div> */}
                                
                                <div className='col-4 mb-2'>
                                    <label className='font-w-600 grey-color label'>PII</label>
                                    <div>
                                        <Switch
                                            checked={fieldsValue?.pii}
                                            onChange={bool => {
                                                handleFields(bool, 'pii')
                                            }}
                                            disabled={viewOnly}
                                        />
                                    </div>
                                </div>
                                
                            </div>

                            
                        </div>
                    </div>
                    {
                            isEdit
                            ?
                            <>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Created by</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={selectedData.created_by_user.username}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Created on</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={`${getRelativeTime(selectedData.created_on)}, ${getTime(selectedData.created_on)}`}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Updated by</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={selectedData.updated_by_user.username}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 mb-2'>
                                    <label className='font-w-600 grey-color label'>Updated on</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={`${getRelativeTime(selectedData.updated_on)}, ${getTime(selectedData.updated_on)}`}
                                            className={`custom-input-field border`}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </>
                            :   ''
                        }
                </div>
            </div>
        </ModalBody>
        <ModalFooter>
           {
                // viewOnly ? ""
                // :   
                <Buttons props={{ 
                        buttonText: submitButton.getText(), 
                        buttonClassName: "custom-btn-primary custom-btn btn-with-text", 
                        buttonEvent: () => submitButton.onClick(), 
                        ImgSrc: () => submitButton.getIcon(), 
                        isDisable: submitButton.getDisableStatus(),
                        buttonType:Literals.BTN_PRIMARY_WITH_TEXT
                    }} 
                />
            }
        </ModalFooter>
    </Modal>
  )
}

export default SettingsClassificationModal