import { Badge, Checkbox, Radio, Tooltip, Tree } from 'antd';
import { cloneDeep } from "lodash";
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";



import { DATASET, DOMAIN_SMALL } from '../../../../Common/iconSource';

import { v4 as uuidv4 } from 'uuid';
import { fetchDatabaseSchemaList, fetchDatabaseTableList, setSearchResults } from '../../../../../store/actions';
import { fetchPreviewTable } from '../../../../../store/modules/common/VirtualDataTable/action';
import { setSchemaTreeMap, setTargetTableDetails } from '../../../../../store/modules/flows/NewFlowsRedux/targetTransformer/targetTransformer';
import { LandingSimpleTable, LandingSimpleTableInner } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import { showSimpleLoading } from '../../../common/helperFunctions';
import { ICON_CATALOG_DOMAIN } from '../../../../Common/newIconSource';
import SearchInput from '../../../../Common/search/Search';

const SchemaTree = ({
    connectionName,
    setSchemaDropdownValue,
    settargetTableData,
    setnewTableName,
    setactiveTabKey = () => { },
    selectedSchema,
    targetTableData,
    isExecutePermission,
    setIsPreviewDataLoading = () => { },
    treeData = [],
    setTreeData = () => { }
}) => {
    const store = useStore();
    const dispatch = useDispatch();
    const { schemaTreeMap } = useSelector(state => state.LabTransformer.TargetTransformer);
    const sourceDropdownList = [

    ];
    const targetTableDetails = useSelector(state => state.LabTransformer.TargetTransformer.targetTableDetails)

    const [data, setData] = useState([...treeData]);
    const [searchResultData, setsearchResultData] = useState([...treeData]);
    const [searchValue, setsearchValue] = useState('');
    const [, setCheckedItems] = useState([]);
    const [showLoader, setshowLoader] = useState(false)
    const [, setIsGenerateQueryBtnVisible] = useState(false);

    useEffect(() => {
        setData([...treeData])
        if (treeData?.length) {
            treeData.forEach((ele) => {
                if (ele?.isExpanded && ele?.children?.length) {
                    ele?.children?.forEach((item) => {
                        if (item.isChecked) {
                            getName(item.parentName, item.name, item.isChecked, item.parentId)
                        }
                    })
                }
            })
        }
    }, [treeData])

    const fetchSchemaList = () => {
        setshowLoader(true)

        try {
            const requestBody = {

                "connection_name": connectionName
            }

            dispatch(fetchDatabaseSchemaList(requestBody)).then(
                res => {
                    if (res) {

                        const data = res.data.data_record;
                        const schemaNames = data.flat();
                        let temp = schemaNames.map((v, i) => (
                            {
                                name: v,
                                rawName: v,
                                id: i,
                                isExpanded: targetTableDetails?.schemaName === v ? true : false,
                                isLoaded: false,
                                children: []
                            }
                        ));
                        setData([...temp]);

                        if (temp?.length) {
                            temp.forEach((item, i) => {
                                if (item.isExpanded && !item?.children?.length) {
                                    onLoadData({ key: item?.name, children: item.children, id: item?.id, completeTree: temp })
                                }
                            })
                        }

                        // setData(() => {
                        //     let temp = schemaNames.map((v, i) => (
                        //         {
                        //             name: v,
                        //             rawName: v,
                        //             id: i,
                        //             isExpanded: false,
                        //             isLoaded: false,
                        //             children: []
                        //         }
                        //     ))
                        //     return temp
                        // })

                        const { schemaTreeMap } = store.getState().LabTransformer.TargetTransformer;
                        const newschemaTreeMap = cloneDeep(schemaTreeMap);

                        schemaNames.forEach((schemaName, index) => {
                            sourceDropdownList.push({ key: index, Name: schemaName, Type: "", value: schemaName })
                            newschemaTreeMap[schemaName] = {};
                        })
                        setSchemaDropdownValue([...sourceDropdownList])
                        dispatch(setSchemaTreeMap(newschemaTreeMap));
                        setshowLoader(false)

                    }
                }
            )
        }
        catch (error) {
            setshowLoader(false)

        }
    }


    const onLoadData = ({ key, children, id, completeTree }) => {
        const schemaName = key;
        let temp = [...data]
        return new Promise((resolve) => {
            if (children.length) {
                resolve();
                toggleSubRow(data, id)
                return;
            }

            setData(prev => {
                prev = prev.map(item => {
                    return (
                        {
                            ...item,
                            isLoading: item.name === schemaName
                        }
                    )
                })
                temp = [...prev]
                return prev
            })

            try {
                let requestBody = {

                    "connection_name": connectionName,
                    "schema_name": schemaName
                }

                dispatch(fetchDatabaseTableList(requestBody, false)).then(
                    res => {
                        if (res) {
                            let targetDetails = { ...targetTableDetails }
                            targetDetails['connnectionTableList'] = res.data.data_record
                            let temp = []
                            if (completeTree?.length) {
                                let newcompleteTree = completeTree.map((item, i) => {
                                    return (
                                        item.name === schemaName
                                            ? {
                                                ...item,
                                                isLoading: false,
                                                isLoaded: true,
                                                children:
                                                    targetDetails.connnectionTableList.map((d, c_idx) => (
                                                        {
                                                            name: d[0],
                                                            rawName: d[0],
                                                            isChecked: targetTableDetails?.tableName === d[0] ? true : false,
                                                            isChild: true,
                                                            id: `${i}-${c_idx}`,
                                                            parentId: item.id,
                                                            parentName: item.name
                                                        }
                                                    ))
                                            }
                                            : { ...item }
                                    )
                                })
                                temp = [...newcompleteTree]
                                setData([...newcompleteTree])
                                toggleSubRow(temp, id)


                            } else {
                                setData(prev => {
                                    prev = prev.map((item, i) => {
                                        return (
                                            item.name === schemaName
                                                ? {
                                                    ...item,
                                                    isLoading: false,
                                                    isLoaded: true,
                                                    children:
                                                        targetDetails.connnectionTableList.map((d, c_idx) => (
                                                            {
                                                                name: d[0],
                                                                rawName: d[0],
                                                                isChecked: targetTableDetails?.tableName === d[0] ? true : false,
                                                                isChild: true,
                                                                id: `${i}-${c_idx}`,
                                                                parentId: item.id,
                                                                parentName: item.name
                                                            }
                                                        ))
                                                }
                                                : { ...item }
                                        )
                                    })
                                    temp = [...prev]
                                    return prev
                                })
                                toggleSubRow(temp, id)


                            }



                            store.dispatch(setTargetTableDetails(targetDetails))

                            if (!schemaName.includes("results")) {
                                const { schemaTreeMap } = store.getState().LabTransformer.TargetTransformer;
                                const newschemaTreeMap = cloneDeep(schemaTreeMap);
                                // newschemaTreeMap[schemaName].tableCount = res.data.data_record.length;
                                dispatch(setSchemaTreeMap(newschemaTreeMap));
                            }
                        }

                    }
                )
            }
            catch (e) {

            }
            resolve();

        });
    }

    const onChildClick = (schemaName, tableName, isChecked, id) => {
        if (!isChecked) {
            importPodInTable(schemaName, tableName, id);
        }
        else {
            removePodFromTable(schemaName, tableName, id);
        }
    };

    const getName = async (schemaName, tableName, isChecked, id) => {
        try {
            if (isChecked && !targetTableData?.data_record?.length) {
                const requestBody = createRequestBodyHandler(schemaName, tableName);
                setIsPreviewDataLoading(true)
                const { data } = await dispatch(fetchPreviewTable(requestBody, false));
                settargetTableData(data.result);
                setIsPreviewDataLoading(false)
                setnewTableName(tableName)
                setactiveTabKey(1)
            }
        } catch (error) {
            setIsPreviewDataLoading(false)

        }


    };

    function toggleSubRow(rows, id) {

        const rowIndex = rows.findIndex((r) => r.id === id);
        const row = rows[rowIndex];
        const { children } = row;

        if (!children) return rows;

        const newRows = [...rows];
        newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };

        if (!row.isExpanded) {
            newRows.splice(rowIndex + 1, 0, ...children);
        } else {
            newRows.splice(rowIndex + 1, children.length);

        }
        // console.log(newRows, row)

        setData([...newRows])
        setTreeData([...newRows])
    }

    function handleSubRow(rows, id) {

        const row = rows.find((r) => r.id === id);
        if (!row || !row.parentId) {
            setData([...rows])
        }

        const newRows = rows.map(r => ({ ...r, isChecked: r.id === id ? true : false }));

        const parentRowIndex = newRows.findIndex((r) => r.id === row.parentId);

        const { children } = newRows[parentRowIndex];

        if (children) {
            const newChildren = children.map((sr) => ({ ...sr, isChecked: sr.id === id ? true : false }));
            newRows[parentRowIndex] = { ...newRows[parentRowIndex], children: newChildren };
        }

        newRows.forEach((item) => {
            if (item.id !== row.parentId && item.children?.length) {
                item?.children.forEach((child) => child.isChecked = false)

            }
        })

        setData([...newRows]);

        setTreeData([...newRows])
    }


    const createRequestBodyHandler = (schemaName, tableName) => {
        let id = uuidv4();

        let requestBody = {
            uuid: id,
            connection_name: connectionName,
            schema_name: schemaName,
            table_name: tableName,
            page: 0,
            do_count: false,
            sort_body: [],
            filter_body: [],
            table_ids: []

        }

        return requestBody;
    }


    const importPodInTable = async (schemaName, tableName, id) => {
        let dataList = [...data]
        try {
           
            settargetTableData({})
            const requestBody = createRequestBodyHandler(schemaName, tableName);
            const { data } = await dispatch(fetchPreviewTable(requestBody, false));
            settargetTableData(data.result)
            setactiveTabKey(1)
            handleSubRow(dataList, id)
            selectedSchema(schemaName)
            setnewTableName(tableName)
        }
        catch (error) {

        }
    }

    const removePodFromTable = (schemaName, tableName, id) => {
        const { schemaTreeMap } = store.getState().LabTransformer.TargetTransformer;
        const newschemaTreeMap = cloneDeep(schemaTreeMap);

        const tableCount = newschemaTreeMap[schemaName]?.tableCount;

        const allAvailableTables = extractAllAvailableTableNames(newschemaTreeMap);
        let currentTableIndex, newActiveTableName;


        if (tableName) {
            currentTableIndex = allAvailableTables.indexOf(tableName);
            delete newschemaTreeMap[schemaName][tableName];
        }
        else {
            newschemaTreeMap[schemaName] = {
                tableCount
            };
        }


        if (allAvailableTables[currentTableIndex + 1]) {
            newActiveTableName = `${schemaName}-${allAvailableTables[currentTableIndex + 1]}`
        }
        else if (allAvailableTables[currentTableIndex - 1]) {
            newActiveTableName = `${schemaName}-${allAvailableTables[currentTableIndex - 1]}`
        }
        else {
            newActiveTableName = "Results";
        }


        // dispatch(setSchemaTreeMap(newschemaTreeMap));
        handleSubRow(data, id)
    }


    const extractAllAvailableTableNames = (schemaTreeMap) => {
        let availableTableNames = [];

        Object.values(schemaTreeMap).forEach((schemaData) => {
            availableTableNames = availableTableNames.concat(Object.keys(schemaData).filter((tableKey) => tableKey !== "tableCount"));
        })


        return availableTableNames;
    }

    const setCheckedItemsHandler = () => {
        const checkedItems = [];

        for (let key in schemaTreeMap) {
            const schemaName = key;
            const schemaTableNames = Object.keys(schemaTreeMap[schemaName]).filter((tableKeys) => tableKeys !== "tableCount");

            const tableCount = schemaTreeMap[schemaName].tableCount;

            if (schemaTableNames.length === tableCount) {
                checkedItems.push(schemaName);
            }
            else {
                schemaTableNames.forEach((tableName) => {
                    checkedItems.push(`${schemaName}-${tableName}`);
                })
            }


        }
        setCheckedItems(checkedItems);
    }



    useEffect(() => {
        if (connectionName && treeData.length === 0) {
            fetchSchemaList();
        }
    }, [connectionName, treeData]);


    useEffect(() => {
        setCheckedItemsHandler();
    }, [schemaTreeMap])

    useEffect(() => {
        const schemaNames = Object.keys(schemaTreeMap);

        if (schemaNames.length > 1) {
            for (let schemaName of schemaNames) {
                if (Object.keys(schemaTreeMap[schemaName]).length > 1) {
                    setIsGenerateQueryBtnVisible(true);
                }
            }
        }
    }, [schemaTreeMap])

    const parentNameTemplate = (val, isOpen = false, id, row) => {
        let anyChildActive = row?.children.some(v => v.isChecked)
        let colorChange = (isOpen || anyChildActive)
        let isEmpty = (row.isLoaded && row.children.length === 0)



        return (
            <div className={`create-pod-tree-schema ${colorChange ? 'color-primary' : ''}`}>
                <i onClick={() => {
                    if (!isOpen && !row.isLoaded) onLoadData({ key: val, children: row.children, id, completeTree: data, val })
                    else toggleSubRow(data, id)
                }}
                    className={`cursor-pointer h6 mb-0 mr-1 bx bx-${row?.isLoading ? 'loader-alt bx-spin' : !isOpen ? 'caret-right' : 'caret-down'}`}></i>

                <ICON_CATALOG_DOMAIN height='15' width='15' />
                <Tooltip title={val.toUpperCase()} placement={'right'}>
                    <p style={{ maxWidth: `calc(100% - ${!isEmpty ? '57' : '76'}px)` }} className={`w-auto text-with-ellipsis mb-0 font-w-600 paragraph text-uppercase pl-1 ${colorChange ? 'color-primary' : ''}`}>
                        {`${val}`}
                    </p>
                </Tooltip>
                {
                    isEmpty
                        ?
                        <Badge style={{ backgroundColor: '#fbad58' }} title={`No Tables Found`} className="custom-tree-badge" count={'Empty'} />
                        : ''
                }
            </div>
        )
    }

    const childNameTemplate = ({ name, isChecked = false, ...row }) => {

        const parentName = row.parentId !== undefined
            && data.find(
                item => (item.id === row.parentId)
            )?.name
        return (
            <React.Fragment>

                <p
                    className={`${isChecked ? 'color-primary font-w-600' : ''} paragraph mb-0 mx-n2 text-with-ellipsis position-relative`}

                    onClick={() => {
                        if (!isExecutePermission) {
                            return
                        } else {
                            handleSubRow(data, row.id)

                            onChildClick(parentName, name, isChecked, row.id)
                        }
                    }}
                    style={{ paddingLeft: '51px' }}
                >
                    <i style={{
                        position: 'absolute',
                        top: '4px',
                        fontSize: '24px',
                        left: '22px',
                        cursor: !isExecutePermission ? "default" : "pointer"
                    }}
                        className={`bx bx-radio-circle${isChecked ? '-marked color-primary' : ' text-black-50'}`}></i>
                    <DATASET height='15' width='15' /><span className="p-1"></span>
                    <Tooltip title={name} placement="right"> {name}</Tooltip>
                </p>

            </React.Fragment>
        )
    }

    const columns = [
        {
            name: 'Tables',
            key: "name",
            sortable: false,
            resizable: false,
            renderCell: ({ row }) => {

                const hasChildren = row.children !== undefined;
                return (
                    <>
                        {hasChildren && (
                            parentNameTemplate(row.name, row.isExpanded, row.id, row)
                        )}
                        <div className="rdg-cell-value">
                            {

                                !hasChildren && (childNameTemplate(row))
                            }
                        </div>
                    </>
                );
            }
        }
    ];

    const treeViewData = useMemo(() => {

        if (searchValue?.trim()?.length > 0 && searchResultData.length === 0) return []
        if (searchResultData.length) return searchResultData
        return data
    }, [data, searchResultData, searchValue])

    useEffect(() => {
        if (searchValue.trim().length > 0) {
            let tempData = [...data]
            let resultList = tempData.filter(
                item => {
                    return (
                        item?.children?.length
                        && item?.children?.some(v => v?.name?.includes(searchValue?.toLowerCase()))
                    )
                        || item?.name?.includes(searchValue?.toLowerCase())
                        || item?.parentName?.includes(searchValue?.toLowerCase())
                }
            )

            setsearchResultData([...resultList])
        } else setsearchResultData([])
    }, [searchValue, data])


    return (
        <div className="create-pod-table-container pt-2 custom-dgt-tree" style={{ height: 'calc(100% - 83px)' }}>
            <div className="create-pod-table-header">
                <SearchInput placeholder='Search Tables..' searchData={searchValue} setSearchData={setsearchValue} />
                {/* <h3>Search Tables...</h3> */}
            </div>
            {data?.length && !showLoader ? <div className='pl-3'>
                <LandingSimpleTable
                    cols={columns}
                    rowsData={treeViewData}
                    showCursor={false}
                    tableHeight={'calc(100vh - 398px)'} //added overflow scroll
                    rowHeight={30}
                    headerRowHeight={0}
                />
            </div> : !showLoader ? <span className='label' style={{ justifyContent: "center", display: "flex", color: "#d3d3d3", font: "30px" }}>No Tables Found</span> : ''
            }
            {showLoader ? showSimpleLoading("Please Wait Loading Tables List...") : null}
        </div>
    )
}

export default SchemaTree
