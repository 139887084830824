import { Drawer, Input, Select, Tooltip, message } from 'antd'
import './pulseSearchBox.scss'
import React from 'react'
import { ICON_CATALOG, ICON_CATALOG_DOMAIN, ICON_MIC_FILLED, ICON_MIC_MUTE, ICON_PLANE_FILLED } from '../../newIconSource'
import { useDispatch, useSelector } from 'react-redux'
import { getCatalogListing, getDataDomainList, setDataDomainList, setUserSelectedDomain } from '../../../../store/actions'
import { getReloadErrorTemplate, showOneLineError } from '../../../modules/common/helperFunctions'
import LeftSidePanelToggler from '../../leftSidePanelToggler/LeftSidePanelToggler'
import SearchInput from '../../search/Search'
import ClaristaLoader from '../../claristaLoader/ClaristaLoader'
import { LandingSimpleTableInner } from '../../landingSimpleTable/LandingSimpleTable'
import { Literals } from '../../../modules/common/literals'
import Buttons from '../../button/Buttons'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import TalkDataSuggestBar from '../../../modules/genAi/components/TalkDataSuggestBar'
import { useNavigate } from 'react-router-dom'

const PulseSearchBox = React.forwardRef(({
    activeTabKey,
    activeDomain,
    setActiveDomain = () => {},
    setDomainFailed = () => {},
    isDomainFailed = false,
    setFocus = () => {},
    searchValue = '',
    setSearchValue = () => {},
    onSearch = () => {},
    disableDomain = false,
    onEnterCallback = () => {},
    disableInput = false,
    hideSearchBar = false,
    hideDomain = false,
    searchPlaceholder = 'Start typing your question here...',
    setParentDomainList = () => {}
    // suggestionData = [],
    // onSuggestBarClick = () => {},
    // suggestVisibility = false,
    // showSuggestion = false
}, ref) => {
    const dispatch = useDispatch()
    const history = useNavigate()

    const [loadingDomains, setLoadingDomains] = React.useState(false)
    const [domainError, setDomainError] = React.useState(undefined)
    const [domains, setDomains] = React.useState([])
    const [openColDrawer, setOpenColDrawer] = React.useState(false)
    const [colsData, setColsData] = React.useState([])
    const [colsLoading, setColsLoading] = React.useState(false)
    const [colsError, setColsError] = React.useState(undefined)
    const [colsSearchData, setColsSearchData] = React.useState('')
    const [colsEmpty, setColsEmpty] = React.useState(false)
    const [drawerVisible, setDrawerVisible] = React.useState(false)

    const autoGrowInpRef = React.useRef({})

    const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    React.useImperativeHandle(ref, () => {
        return {
            focus: () => {
                autoGrowInpRef?.current?.focus()
            }
        }
    }, [])

    React.useEffect(() => {
        if (!listening && transcript !== "") {
            autoGrowInpRef.current.innerText = transcript
            setSearchValue(transcript);

            if(autoGrowInpRef?.current?.focus) {
                autoGrowInpRef?.current?.focus()
            }
            
            message.info({
                content: "Now Press Enter!"
            })
            message.config({
                top: 50,
                duration: 1
            })

            resetTranscript();
          // onSearchHandle(transcript);
        }
        if(listening) {
            autoGrowInpRef.current.innerText = "Speak Now, I'm Listening..."
        }
        else {
            autoGrowInpRef.current.innerText = transcript
        }
    }, [listening])

    React.useEffect(() => {

        if(autoGrowInpRef?.current?.focus) {
            autoGrowInpRef?.current?.focus()
        }

        if(!hideSearchBar) {
            setDomainError(undefined)
            setLoadingDomains(true)
            dispatch(getDataDomainList(false, false))
            .then(
                res => {
                    if(res?.data?.length) {
                        setDomains([...res?.data])
                        setParentDomainList([...res?.data])
                    }
                    if(activeDomain === null || activeDomain === 0 || activeDomain === isNaN) {
                        setActiveDomain(res?.data?.[0]?.data_domain_id)
                    }
                    dispatch(setDataDomainList(res.data))
                    setLoadingDomains(false)
                }
            )
            .catch((err) => {
                setLoadingDomains(false)
                setDomainError(err?.message)
            })
        }
    }, [])

    React.useEffect(() => {
        if(!openColDrawer) {
            setColsData([])
            return
        }
        if(activeDomain && activeDomain !== '') {
            setTimeout(() => {
                fetchColsOfPods()
            }, 1000)
        }
    }, [activeDomain, openColDrawer])

    React.useEffect(() => {
        if (isDomainFailed && domains && domains?.length) {
            setActiveDomain(domains?.[0]?.data_domain_id)
        }
    }, [isDomainFailed, domains])

    const domainOptions = React.useMemo(() => {
        let list = domains?.map((item) => {
            return {
                key: item.data_domain_id,
                value: item.data_domain_id,
                Name: item?.data_domain_name?.replaceAll("_"," ")?.toUpperCase(),
                domain: item?.data_domain_name,
            }
        })
        return list
    }, [domains, activeTabKey])

    const colsOfPodDataArr = React.useMemo(() => {
        const clone = [...colsData]?.map(c => (
            {
                ...c,
                column_detail: c?.column_detail?.filter(v => v?.published && v?.column_name?.toLowerCase()?.includes(colsSearchData?.toLowerCase()))
            }
            ))
        let arr = []
        clone.forEach((v, idx) => {
            
            const el = clone[idx]
            arr = [
                ...arr,
                { 
                    isHeading: true,
                    data: {
                        table_id: el?.table_id,
                        table_name: el?.table_name
                    },
                    element: <div 
                        style={{opacity: el?.column_detail?.length === 0 ? '0.5' : '1'}}
                        className='fontSizeHeading fontInterSemiBold d-flex align-items-center'
                        >
                        <ICON_CATALOG width='20' height='20' />
                        <span className='p-1'></span>
                        <Tooltip title={el?.table_name?.toUpperCase()} placement='right'>
                            <label className='mb-0 text-uppercase text-with-ellipsis' style={{width: 200}}>
                                {el?.table_name}
                            </label>
                        </Tooltip>
                    </div>
                },
                ...[
                    ...el.column_detail?.map(c => (
                        {
                            isHeading: false,
                            data: {
                                ...c, 
                                table_id: el?.table_id, 
                                table_name: el?.table_name,
                                data_domain_detail: el?.data_domain_detail,
                                source_type: el?.source_type
                            },
                            element: 
                            <Tooltip title={c?.column_name} placement='right'>
                                <div
                                className='fontSizeHeading pl-4 text-with-ellipsis' style={{width: 226}}>
                                    {c?.column_name}
                                </div>
                            </Tooltip>
                        }
                    ))
                ]
            ]
        })
        return arr ?? []
    }, [colsData, colsSearchData])

    const fetchColsOfPods = () => {
        setColsEmpty(false)
        setColsError(undefined)
        setColsLoading(true)
        dispatch(getCatalogListing(activeDomain, 'metadata', true, false))
        .then(res => {
            setColsData(res?.data ?? [])
            setColsEmpty(res?.data?.length === 0)
            setColsLoading(false)
        })
        .catch((err) => {
            setColsEmpty(false)
            setColsError(err?.message)
            setColsLoading(false)
        })
    }

    const onColsRowClick = (record) => {
        if(window.screen.availWidth <= 640) {
            return
        }
        if(record?.isHeading) {
            if(record?.data?.table_id)
            window.open(Literals?.links?.DATA_CATALOG_POD_DETAILS + record?.data?.table_id)
        }
        else {
            if(record?.data?.data_dict)
            window.open(Literals?.links?.DATA_DICTIONARY_VIEW + record?.data?.data_dict)
        }
    }

    const variableRowHeight = (record) => {
        if(record?.row?.isHeading) return 34
        return 28
    }

    const onChange = (key) => {
        key = parseInt(key)
        
        setActiveDomain(key)

        setDomainFailed(false)
        
        dispatch(setUserSelectedDomain({ data_domain_id: key === 0 ? null : key }, false))
        .catch(err => console.error(err?.message))
    };

    const onEnterPress = (e) => {
        e.preventDefault()
        onEnterCallback()
    }

    const isSearchDisabled = React.useMemo(() => {
        return listening || disableInput || domainOptions?.length === 0 || noOpenAiAccess
    }, [listening, disableInput, domainOptions])

    const showShadow = () => {
        let hght = autoGrowInpRef.current?.clientHeight
        return hght && hght > 40
    }

    return (
        <>
            <div className='pulse-search-container'>
                <div className={`pulse-search-comp ${showShadow() ? 'show-pulse-exp-eff' : ''} ${hideSearchBar ? 'p-0 border-0' : ''}`}
                    style={drawerVisible ? {zIndex: 'auto'} : {}}
                >
                    {
                        hideSearchBar ? ''
                        :
                        <>
                            {
                                hideDomain ? ''
                                :
                                <>
                                    <Select
                                        disabled={disableDomain}
                                        placeholder="Select Domain"
                                        showSearch={true}
                                        optionFilterProp="children"
                                        loading={loadingDomains}
                                        value={
                                            domainError    ? 'Error!' :
                                            loadingDomains ? 'Loading...' :
                                            domainOptions?.find(d => d?.key?.toString() === activeDomain?.toString())?.value ?? 'Select Domain'
                                        }
                                        onChange={onChange}
                                        filterOption={(input, option) => {
                                                const name = domainOptions?.find(d => d?.value === option?.value)?.Name ?? ''
                                                return name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                            }
                                        }
                                        filterSort={(optionA, optionB) => {
                                                const nmA = domainOptions?.find(d => d?.value === optionA?.value)?.Name ?? ''
                                                const nmB = domainOptions?.find(d => d?.value === optionB?.value)?.Name ?? ''
                                                return nmA?.toString()?.toLowerCase()?.localeCompare(nmB?.toString()?.toLowerCase())
                                            }
                                        }
                                        >
                                        {
                                            domainOptions?.map((ele, index) => (
                                                <Select.Option key={index} value={ele.value ? ele.value : ele.Name}>
                                                    <div title={ele?.Name} className='align-items-center d-flex px-1'>
                                                        <span className='mr-1'><ICON_CATALOG_DOMAIN width='18' height='18' /></span>
                                                        <span className='fontSizeHeading ml-2 text-with-ellipsis text-uppercase'>{ele.Name}</span>
                                                    </div>
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                    <span className='vertical-separator'></span>
                                </>
                            }
                            <form onSubmit={onEnterPress} className={`w-100 position-relative ${hideDomain ? 'pl-2' : ''}`} style={{top: 0}}>
                                <div
                                    className={`pls-input pulse-autogrow-inp ${isSearchDisabled ? 'disabled' : ''}`}
                                    contentEditable={!isSearchDisabled}
                                    id='pulseTalkSearchInp'
                                    ref={autoGrowInpRef}
                                    data-placeholder={
                                        noOpenAiAccess 
                                        ? 'You do not have access to OpenAI' 
                                        : searchPlaceholder
                                    }
                                    onFocus={() => {
                                        setFocus(true)
                                    }}
                                    onBlur={() => {
                                            if(searchValue?.trim() === '') {
                                                autoGrowInpRef.current.innerHTML = ''
                                                autoGrowInpRef.current.innerText = ''
                                                setSearchValue('')
                                            }
                                            setTimeout(() => {
                                                setFocus(false)
                                            }, 1000)
                                        }
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key?.toLowerCase() === 'enter' && e.shiftKey) {
                                            const selection = window.getSelection();
                                            const range = selection.getRangeAt(0);
                                            const textNode = document.createTextNode('\n'); // Create a text node with a newline character
                                            range.insertNode(textNode);
                                            range.setStartAfter(textNode);
                                            range.setEndAfter(textNode);
                                            selection.removeAllRanges();
                                            selection.addRange(range);
                    
                                        }
                                        else if(e?.key?.toLowerCase() === 'enter' && !e.shiftKey) {
                                            if(!isSearchDisabled && searchValue?.trim() !== '')
                                            onEnterPress(e)
                                        }
                                    }}
                                    onInput={(e) => {
                                        e.preventDefault()
                                        if(!isSearchDisabled) {
                                            let val = e?.target?.innerText
                                            setSearchValue(val)
                                            // if(!val.match(/\n/g)) {
                                            //     setSearchValue(val)
                                            // }
                                            // else {
                                            //     setSearchValue(searchValue)
                                            //     autoGrowInpRef.current.innerText = searchValue
                                            // }
                                        }
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault()
                                        let text = e.clipboardData.getData("text/plain");
                                        /**
                                         * Removing line breaks
                                         */
                                        // text = text.replace(/(\r\n|\n|\r)/gm,"") // uncomment to use
                                        document.execCommand('insertText', false, text)

                                        /**
                                         * In case execCommand is removed by Javscript in new version
                                         * use this below code
                                         */
                                        /* text = text.replace(/(\r\n|\n|\r)/gm,"")
                                        const selection = window.getSelection();
                                        if (!selection.rangeCount) return;
                                        selection.deleteFromDocument();
                                        selection.getRangeAt(0).insertNode(document.createTextNode(text));
                                        setSearchValue(selection?.anchorNode?.innerText)
                                        selection.collapseToEnd() */
                                    }}
                                >
                                </div>
                                {/* <Input
                                    id='pulseTalkSearchInp'
                                    placeholder='Start typing your question here...'
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => 
                                        setTimeout(() => {
                                            setFocus(false)
                                        }, 1000)
                                    }
                                    value={listening ? "Speak Now, I'm Listening..." : searchValue}
                                    onChange={(e) => setSearchValue(e?.target?.value)}
                                    disabled={listening || disableInput}
                                /> */}
                                {/* {
                                    showSuggestion
                                    ?
                                    <TalkDataSuggestBar
                                        data={suggestionData}
                                        onClick={onSuggestBarClick}
                                        visibility={suggestVisibility}
                                    />
                                    :   ''
                                } */}
                            </form>
                            {
                                browserSupportsSpeechRecognition
                                ?
                                <span className='d-flex align-items-center pr-1 pl-2 pulse-act-btn'>
                                    <Buttons
                                        props={{
                                            buttonText: '',
                                            tooltip: '',
                                            buttonClassName: `${listening ? 'bx-flashing text-danger' : ''} custom-btn-outline custom-btn btn-with-icon btn-large bg-transparent`,
                                            buttonEvent: (e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                
                                                if(!listening) {
                                                    SpeechRecognition.startListening()
                                                }
                                                else {
                                                    setSearchValue("")
                                                    autoGrowInpRef.current.innerText = ""
                                                    SpeechRecognition.stopListening()
                                                }
                                            },
                                            ImgSrc: () => 
                                                listening
                                                ?
                                                <ICON_MIC_MUTE width='20' height='20' color='#dc3545' />
                                                :
                                                <ICON_MIC_FILLED width='20' height='20' color='#333333' />,
                                            isDisable: disableInput || domainOptions?.length === 0 || noOpenAiAccess,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                        }}
                                    />
                                    {/* <ICON_MIC_FILLED width='20' height='20' color='#33333370' /> */}
                                </span>
                                :   ''
                            }
                            <span className='d-flex align-items-center px-1 pulse-act-btn'>
                                <Buttons
                                    props={{
                                        buttonText: '',
                                        tooltip: '',
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large bg-transparent",
                                        buttonEvent: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            onSearch()
                                        },
                                        ImgSrc: () => <ICON_PLANE_FILLED width='20' height='20' color='#333333' />,
                                        isDisable: disableInput || domainOptions?.length === 0 || noOpenAiAccess,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                    }}
                                />
                                {/* <ICON_PLANE_FILLED width='20' height='20' color='#33333370' /> */}
                            </span>
                        </>
                    }
                </div>
                {
                    domainError ? showOneLineError(`Domain Error: ${domainError}`) : ''
                }
                <Drawer
                    title={
                        <>
                            <div className='pod-col-drawer-srch'>
                                <SearchInput
                                    placeholder='Search column...'
                                    searchData={colsSearchData}
                                    setSearchData={setColsSearchData}
                                />
                            </div>
                        </>
                    }
                    placement={'left'}
                    closable={false}
                    
                    onClose={() => {
                        setColsSearchData('')
                        setOpenColDrawer(false)
                    }}
                    open={openColDrawer}
                    rootClassName={`pulse-col-draw ${window?.location?.href && window?.location?.href?.includes('/home') ? 'home-page' : ''}`}       
                    width={250}       
                    destroyOnClose={true}
                    afterOpenChange={(visibility) => {
                        setDrawerVisible(visibility)
                    }}
                >
                    {
                        colsError ? getReloadErrorTemplate({
                            errorMessage: colsError,
                            onReload: () => fetchColsOfPods()
                        })
                        :   colsLoading ? <ClaristaLoader/>
                            :
                            <div className='h-100 col-li-panel'>
                                {
                                    colsEmpty ? <div className='p-3 text-center text-black-50'>No Pods Published</div> 
                                    :
                                    colsOfPodDataArr.length
                                        ? <LandingSimpleTableInner
                                            cols={columns}
                                            onRowClick={onColsRowClick}
                                            rowsData={colsOfPodDataArr}
                                            tableHeight={'100%'}
                                            headerRowHeight={0}
                                            rowHeight={variableRowHeight}
                                        />
                                        : ''
                                }
                            </div>
                    }
                </Drawer>
            </div>
            {
                /**
                 * In Case Want Arrow Button Toggler Use <LeftSidePanelToggler/>
                 */
                true 
                ?
                    !hideSearchBar && domainOptions?.length === 0 ? ""
                    :
                    <div className='pulse-col-strip' 
                        onClick={() => {
                            if(!openColDrawer) {
                                setDrawerVisible(true)
                            }
                            setOpenColDrawer(!openColDrawer)
                        }}
                        >
                        {
                            openColDrawer ? 'Hide Columns' : 'Show Columns'
                        }
                    </div>
                :
                <LeftSidePanelToggler
                    customTooltip='Published Table(s)'
                    open={openColDrawer}
                    setOpen={setOpenColDrawer}
                />
                /**
                 * End of above comment
                 */
            }
        </>
    )
})

const columns = [
    {
        name: '',
        key: "element",
        sortable: false,
        resizable: false,
    }
]

export default PulseSearchBox