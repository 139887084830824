import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { connect } from 'react-redux'

import { addEdges, EditFlowsAPI, fetchFlowsDetails, setExecutedTransformerPayload, setToDefault } from '../../../store/modules/flows/flowsActions'



import { Literals } from '../../modules/common/literals'
import LoadingComponent from '../../modules/common/loadingComponent'

import FlowsCardView from '../../modules/flows/landingPage/FlowsCardView'

import { useNavigate } from 'react-router-dom'
import { Space, Tooltip, Popover, Input, Collapse } from 'antd'
import Highlighter from 'react-highlight-words'

import _, { sortBy } from 'lodash'

import { createBookmark, fetchFlowsLandingPageList } from '../../../store/modules/flows/landingPage/landingPageAction'
import NoSearchResultFound from '../../modules/common/NoSearchResultFound'
import { resetTargetRedux } from '../../../store/modules/flows/targetTransformer/targetTransformer'
import { resetScriptRedux } from '../../../store/modules/flows/scriptTransformer/scriptAction'
import { resetVirtualTableActions, resetVirtualTableRedux } from '../../../store/modules/common/VirtualDataTable/action'
import { resetAllDataGridTableFilters } from '../../../store/modules/common/DataGridTable/dataGridTableRedux'
import { duplicate, getConnectionListV2, getDomainTags, resetGraphSampleSelected, setTagList } from '../../../store/actions'
import { getGlobalConnectionCache } from '../../../store/modules/UserManagement/connection/connectionActions'
import DeleteModal from '../deleteModal/DeleteModal'
import { DeleteNoteContent, showSimpleLoading, NoDataComponent, ErrorComponent, permissions, getRelativeTime, getReloadErrorTemplate } from '../../modules/common/helperFunctions'
import { SMALLFLOWS, DELETEICON, EDIT, PLUS, STAR, FILLEDSTAR, SAVE, CLOSE, COPY, FLOWS, TAG } from '../iconSource'
import Buttons from '../button/Buttons'
import ClaristaLoader from '../claristaLoader/ClaristaLoader'
import { ContentViewGridTable } from '../contentGridTable/ContentViewGridTable'
import { SHOW_BRICKS_MENU } from '../../../store/modules/flows/flowsTypes'
import { emitToastNotification } from '../../../helpers/toast_helper'
import { setLoadingStatus } from '../../../store/modules/common/loadingActions'
import NewFlowsPopUp from '../../modules/flowsNew/createFlows/NewFlowsPopUp'
import { checkIsEditorFlow } from '../../modules/flowsNew/transformers/commonFunctions/FlowsHelperFunction'


const flowsPermissions = permissions.flows

function FlowsGridListIndex(props) {
    let { fromRoute = 'Flows', loadingStatus, isModal = false,
        activeDomain = '', searchTerm = '', showFavourite, setDataLoading = () => { }, groupByTags = true } = props

    const [openModal, setOpenModal] = useState(false)
    const history = useNavigate()
    const store = useStore()

    const [showLoadingMsg, setshowLoadingMsg] = useState(true)
    const [bookmark, setbookmark] = useState(false);

    const [errMsg, setErrorMsg] = useState("");
    const [responseData, setresponseData] = useState([])


    const [openDeletePopup, setOpenDeletePopup] = useState(false);

    const [listData, setlistData] = useState([])
    const [renameText, setRenameText] = useState({});
    const [visible, setVisible] = useState({});
    const [noConnections, setNoConnections] = React.useState(true);

    const [selectedFilters, setselectedFilters] = useState([])

    const dispatch = useDispatch();
    const [empty, setEmpty] = useState(false);
    const [loading, setLoading] = React.useState(false)
    const [showSelectedCardId, setshowSelectedCardId] = useState(null)
    const [activeCollKeys, setActiveCollKeys] = React.useState([])

    const tagData = useSelector(state => state.DataCatalogue?.LandingPage?.tagList)

    const currentFlowIdRef = React.useRef({ current: {} })



    useEffect(() => {
        dispatch(getGlobalConnectionCache())
            .then(res => {
                let data = res?.data
                if (!data || _.isEmpty(data)) {
                    setNoConnections(true)
                }
                else setNoConnections(false)
            })
            .catch(() => {
                setNoConnections(true)
            })

        dispatch(getConnectionListV2()).then((res) => {
            if (res?.data?.length) {
                let bricksExist = res.data.findIndex((ele) => ele.source_type?.toLowerCase() === 'databricks')
                if (bricksExist > -1) {
                    dispatch({ type: SHOW_BRICKS_MENU, payload: true })
                } else {

                    dispatch({ type: SHOW_BRICKS_MENU, payload: false })

                }
            }
        })


    }, [])

    useEffect(() => {

        if (activeDomain !== '') {

            setshowLoadingMsg(true)
            callTagListAPI()
            setlistData([])
            setresponseData([])
            getUpdatedFlowsList(activeDomain)
        }

    }, [activeDomain])



    const openDeleteDialog = (id) => {

        currentFlowIdRef.current = id
        setOpenDeletePopup(true);
    };
    const bookmarkData = (id, bookmarkStatus = false) => {
        store.dispatch(createBookmark(id, bookmarkStatus)).then((resp) => {
            if (resp.status === 'success') {
                getUpdatedFlowsList();
                // setTimeout(() => {

                //     setbookmark(!bookmark)
                // }, 1000)
            }
        })
    }

    const handleSaveClick = (val) => {
        try {

            dispatch(duplicate({ name: renameText[val.id] ?? `${val.name}_copy` }, "flow", val.id)).then((res) => {
                if (res.status === 'success') {
                    getUpdatedFlowsList()

                }
            })
        }
        catch (err) {

        }
    }
    const setData = (data) => {

        if (isModal) {
            // Filtering for editor only access
            data = [...data].filter(el => !!el?.permission?.find(v => v === permissions.flows.editor))
        }

        let data_arr = data.map((ele, i) => {

            const checkIsEditor = () => {
                const isRoleEditor = !!ele?.permission?.find(v => v === flowsPermissions.editor)
                if (isRoleEditor) return true
                return undefined
            }

            const isDelete = checkIsEditor() ?? !!ele?.permission?.find(v => v === flowsPermissions.delete)
            const isEdit = checkIsEditor() ?? !!ele?.permission?.find(v => v === flowsPermissions.edit)
            return {
                flow_name: <div className='flexible-search-wrap d-flex align-items-center font-w-600 text-uppercase'><label className='pr-4 mb-0'>   <span className="flows-icon-in-list">  <FLOWS /></span></label><Tooltip placement="bottom" title={ele?.name}>< Highlighter searchWords={[searchTerm?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.name ? ele?.name : ''} /></Tooltip></div>,
                flow_name_only: <div className='text-with-ellipsis'><Tooltip placement="topLeft" title={ele?.name}><Highlighter searchWords={[searchTerm?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.name ? ele?.name : ''} /></Tooltip></div>,
                description: ele.description ? <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={`${ele?.description?.slice(0, 300)}...`}>< Highlighter searchWords={[searchTerm?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.description ? ele?.description : ''} /></Tooltip></span> : <span style={{ marginLeft: "0px" }}>No description has been given yet.</span>,
                // created_on: <Tooltip placement="top" title={getRelativeTime(ele?.created_on)}>{getRelativeTime(ele?.created_on)}</Tooltip>,
                // created_on: <Tooltip placement="right" title={getRelativeTime(ele?.created_on)}>< Highlighter searchWords={[searchTerm?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={getRelativeTime(ele?.created_on) ? getRelativeTime(ele?.created_on) : ''} /></Tooltip>,
                created_by: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={ele?.created_by_user?.email}>< Highlighter searchWords={[searchTerm?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.created_by_user?.email ? ele?.created_by_user?.email : ''} /></Tooltip></span>,
                updated_on: getRelativeTime(ele?.updated_on),
                updated_by: <Tooltip placement="bottom" title={ele?.updated_by_user?.email}>< Highlighter searchWords={[searchTerm?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.updated_by_user?.email ? ele?.updated_by_user?.email : ''} /></Tooltip>,
                icon: fromRoute !== 'alert' && fromRoute !== 'scheduler' ? <div className='text-center'>

                </div> : undefined,
                key: ele.id,
                bookmark: ele.bookmark,
                allData: ele,
                thumbnail: ele?.thumbnail,
                tag: ele?.tag?.filter(t => t?.data_domain?.toString() === activeDomain?.toString())
            }

        })
        setlistData([...data_arr])
    }

    const getUpdatedFlowsList = (domain_name) => {

        setLoading(true)
        setDataLoading(true)
        let prevData = [...responseData]
        setresponseData([])
        setlistData([])
        store.dispatch(fetchFlowsLandingPageList(activeDomain)).then((resp) => {
            let currentActiveTab = store.getState().FlowsReducer.currentActiveTabFlowLanding
            setshowLoadingMsg(true)

            if (resp.data?.length <= 0) {
                setEmpty(true);
                setErrorMsg('')

            }
            if (resp && resp.data?.length) {
                setEmpty(false);
                setshowLoadingMsg(false)
                setErrorMsg('')

                // let favouriteList = resp.data.filter((ele) => ele?.bookmark === true)


                // if (favouriteList && favouriteList.length && showFavourite) {
                //     setData(favouriteList)
                // } else if (!showFavourite) {
                //     setData(resp.data)
                // } else {
                //     setData([])

                // }


                setresponseData(resp.data)


            } else if (resp && resp.data?.length === 0) {
                setshowLoadingMsg(false)
                setEmpty(true);
                setErrorMsg('')

                setlistData([])
                setresponseData([])
            }
            setLoading(false)
            setDataLoading(false)

        }).catch((err) => {
            setshowLoadingMsg(false)
            setEmpty(false);
            setLoading(false)
            setDataLoading(false)
            setresponseData([...prevData])
            setErrorMsg(err.message)

        })


    }
    const deleteFlow = (id) => {
        setLoading(true)
        try {
            dispatch(fetchFlowsDetails({ method: "DELETE", endPoint: `flow/${id}` })).then((resp) => {
                if (resp.status === 'success') {

                    emitToastNotification('success', 'Flow deleted successfully')
                    getUpdatedFlowsList();
                    setLoading(false)
                    setOpenDeletePopup(false);
                }
            }).catch((error) => {
                setLoading(false)// emitToastNotification('error', 'Flow not deleted, please retry once')
            })
        } catch (error) { }

    }

    const filterData = () => {
        let oldObj = [...responseData]
        if (showFavourite && responseData?.length) {

            oldObj = oldObj.filter((ele) => ele?.bookmark === true);

        } else {


        }
        if (showFavourite) {
            return (oldObj)
        }

        else {
            return (responseData)
        }


    }
    useEffect(() => {
        store.dispatch(setToDefault(``));
        store.dispatch(setExecutedTransformerPayload([]))
        store.dispatch(addEdges([]))
        store.dispatch(resetScriptRedux(''))
        store.dispatch(resetTargetRedux({}))
        store.dispatch(resetVirtualTableRedux())
        store.dispatch(resetVirtualTableActions()) //check hang screen check hang screen

        store.dispatch(resetAllDataGridTableFilters())
        store.dispatch(resetGraphSampleSelected())

        // store.dispatch(fetchFlowsLandingPageList()).then((resp, reject) => {

        //     if (resp.data?.length <= 0) {
        //         setEmpty(true);
        //     }
        //     if (resp && resp.data.length) {
        //         setshowLoadingMsg(false)
        //         setEmpty(false);

        //         setresponseData(resp.data)

        //         setData(resp.data)
        //     } else {
        //         setshowLoadingMsg(false)
        //         setEmpty(true);

        //     }

        // }).catch((err) => {
        //     setshowLoadingMsg(false)
        //     setEmpty(false);

        //     setErrorMsg(err.message)
        // })
    }, [])
    // data?.created_by_user?.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
    useEffect(() => {
        if (filterData().length && searchTerm !== '') {
            let filteredData = filterData().filter((data) => {
                return (data?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    data?.description?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    getRelativeTime(data?.created_on)?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    getRelativeTime(data?.updated_on)?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    data?.updated_by_user?.email?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
                    data?.pod_detail?.find((item, i) => {

                        return (item?.data_domain_name?.toLowerCase().includes(searchTerm?.toLowerCase()) || item?.table_name?.toLowerCase().includes(searchTerm?.toLowerCase()))

                    });
            })
            setData(filteredData)
        } else {
            setData(filterData())

        }


        // getVirtualTableRecords(data,columns)
    }, [searchTerm, activeDomain, showFavourite, responseData, selectedFilters, visible, renameText])

    /* useEffect(() => {

        let temp = listData.map((data, index) =>
            <div className="flow-card-view-gridtable " style={{ background: fromRoute === 'alert' ? "#FFFFFF" : '', height: fromRoute === 'alert' ? "405px" : '' }}>
                <FlowsCardView
                    key={index}
                    getUpdatedFlowsList={getUpdatedFlowsList}
                    setshowSelectedCardId={setshowSelectedCardId}
                    showSelectedCardId={showSelectedCardId}
                    bookmarkData={bookmarkData}
                    data={data}
                    listData={listData}
                    setData={setlistData}
                    handleDelete={openDeleteDialog}

                    fromRoute={fromRoute} />
            </div>)
        if (temp?.length) {
            setdataToView(temp)
        }

    }, [listData]) */

    const callTagListAPI = () => {
        // if (tagData?.length > 0) return

        dispatch(getDomainTags(activeDomain))
            .then(res => {
                const data = res?.data ?? []
                dispatch(setTagList([...data]))
            })
            .catch(() => { })
    };

    const dataListObj = React.useMemo(() => {

        if (
            _.isEmpty(searchTerm) && !showFavourite
        ) {
            setActiveCollKeys([])
        }

        const dat = [...listData]
        let list = [...dat?.map(d => d?.tag)?.flat()]

        let tags = new Set([...list])
        // let tags = _.uniqBy([...dat?.map(d => d?.tag)?.flat()], 'name')

        let tagWiseFlow = [...tags]?.reduce((prev, curr) => {
            return {
                ...prev,
                [curr?.name]: {
                    data: dat?.filter(d => d?.tag?.map(t => t?.name)?.includes(curr?.name)),
                    tagDetail: { ...curr }
                }
            }
        }, {})

        // console.log({tagWiseFlow})

        let tagsFlow = []
        for (const key in tagWiseFlow) {
            const element = tagWiseFlow[key];
            tagsFlow.push({
                title: key,
                tagDetail: element?.tagDetail,
                data: element?.data
            })
        }

        tagsFlow = sortBy(tagsFlow, [o => o?.title])

        if (
            !_.isEmpty(searchTerm) || showFavourite
        ) {
            setActiveCollKeys(tagsFlow?.map(d => d?.title))
        }

        return { tagsFlow, allData: dat }
    }, [listData])

    // console.log({dataListObj})
    const getOpenLink = (data) => {
        if (fromRoute !== 'scheduler') { // added condition for scheduler modal
            const isEditPermission = checkIsEditorFlow() ?? !!data?.allData?.permission?.find(v => v === flowsPermissions.edit)

            if (!isEditPermission) return
            store.dispatch(EditFlowsAPI({ ApiType: 'GET', flow_Id: data.key })).then((res) => {
                if (res.status === 'success') {
                    history(`${Literals.links.FLOWS}/${data.key}`);
                }
            });
        }

    }

    return (
        <React.Fragment>

            {fromRoute !== 'scheduler' ? <Buttons props={{
                tooltip: 'Create Flow',
                buttonText: '', buttonClassName: "custom-btn-primary custom-btn fixed-button", buttonEvent: () => {
                    setOpenModal(true)
                    store.dispatch(setToDefault(``))
                }, ImgSrc: () => <PLUS />,
                isDisable: noConnections,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
            }} /> : null}
            {
                loading ? <ClaristaLoader height='200px' />
                    :
                    errMsg?.length > 0 && listData?.length === 0 ? getReloadErrorTemplate({ errorMessage: errMsg, onReload: () => getUpdatedFlowsList(activeDomain) })
                        :
                        empty ? <NoDataComponent logo={<SMALLFLOWS
                            width="60" height="60" color="black" />}
                            message=" Click On (+) To Create Flows" />
                            :
                            (searchTerm?.length > 0 || showFavourite) && listData?.length === 0 ? <NoSearchResultFound />
                                :

                                <div className="">
                                    {
                                        groupByTags
                                            ?
                                            <>
                                                {
                                                    dataListObj?.allData?.length === 0 ? ''
                                                        :
                                                        <Collapse
                                                            key={`catalog-grid-collapse-${activeCollKeys?.length}`}
                                                            className='catalog-grid-collapse'
                                                            collapsible='header'
                                                            ghost={true}
                                                            defaultActiveKey={activeCollKeys}
                                                            // defaultActiveKey={
                                                            //     tagData?.map(t => t?.name)
                                                            //     // dataListObj?.tagsFlow?.map(t => t?.title)
                                                            // }
                                                            // activeKey={dataListObj?.tagsPod?.map(t => t?.title)}
                                                            items={
                                                                dataListObj?.tagsFlow?.map(t => ({
                                                                    key: t?.title,
                                                                    label: <>
                                                                        <div className='round-tags-container nav-landing'>
                                                                            <div className={`${t?.tagDetail?.color} round-tags-item`}>
                                                                                <span>{<TAG />}</span>
                                                                                <span>{t?.title}</span>
                                                                            </div>
                                                                        </div>
                                                                    </>,
                                                                    children: <div className="flow-card-view" style={{ background: fromRoute === 'alert' ? "#FFFFFF" : '', height: fromRoute === 'alert' ? "405px" : '' }}>
                                                                        {
                                                                            t?.data?.map((d, i) => (
                                                                                <a className='flows-landing-cards-link' href={fromRoute !== 'scheduler'?`${Literals.links.FLOWS}/${d.key}`:"#"} onClick={(e) => { e.preventDefault(); getOpenLink(d) }}>
                                                                                    <FlowsCardView
                                                                                        key={i}
                                                                                        getUpdatedFlowsList={getUpdatedFlowsList}
                                                                                        setshowSelectedCardId={setshowSelectedCardId}
                                                                                        showSelectedCardId={showSelectedCardId}
                                                                                        bookmarkData={bookmarkData}
                                                                                        data={d}
                                                                                        listData={listData}
                                                                                        setData={setlistData}
                                                                                        handleDelete={openDeleteDialog}
                                                                                        setLoading={setLoading}
                                                                                        fromRoute={fromRoute}
                                                                                    />
                                                                                </a>

                                                                            ))
                                                                        }
                                                                    </div>
                                                                }))
                                                            }
                                                        />
                                                }
                                                {
                                                    dataListObj?.allData?.filter(d => d?.tag?.length === 0)?.length !== 0
                                                        ?
                                                        <>
                                                            <div className='bg-light px-3 py-2 fontInterSemiBold rounded border mb-2 fontSizeHeading'>
                                                                {`Untagged`}
                                                            </div>
                                                            <div className="flow-card-view" style={{ background: fromRoute === 'alert' ? "#FFFFFF" : '', height: fromRoute === 'alert' ? "405px" : '' }}>
                                                                {
                                                                    dataListObj?.allData?.filter(d => d?.tag?.length === 0)?.map((d, i) => (
                                                                        <a className='flows-landing-cards-link' href={fromRoute !== 'scheduler'?`${Literals.links.FLOWS}/${d.key}`:"#"}  onClick={(e) => { e.preventDefault(); getOpenLink(d) }}>
                                                                            <FlowsCardView
                                                                                key={i}
                                                                                getUpdatedFlowsList={getUpdatedFlowsList}
                                                                                setshowSelectedCardId={setshowSelectedCardId}
                                                                                showSelectedCardId={showSelectedCardId}
                                                                                bookmarkData={bookmarkData}
                                                                                data={d}
                                                                                listData={listData}
                                                                                setData={setlistData}
                                                                                handleDelete={openDeleteDialog}
                                                                                setLoading={setLoading}
                                                                                fromRoute={fromRoute}
                                                                            />
                                                                        </a>
                                                                    ))
                                                                }
                                                            </div>
                                                        </>
                                                        : ''
                                                }
                                            </>
                                            :
                                            <div className="flow-card-view" style={{ background: fromRoute === 'alert' ? "#FFFFFF" : '', height: fromRoute === 'alert' ? "405px" : '' }}>{
                                                listData.map((data, index) =>
                                                    <a className='flows-landing-cards-link' href={fromRoute !== 'scheduler'?`${Literals.links.FLOWS}/${data.key}`:"#"}  onClick={(e) => {e.preventDefault();getOpenLink(data)}}>
                                                        <FlowsCardView
                                                            key={index}
                                                            getUpdatedFlowsList={getUpdatedFlowsList}
                                                            setshowSelectedCardId={setshowSelectedCardId}
                                                            showSelectedCardId={showSelectedCardId}
                                                            bookmarkData={bookmarkData}
                                                            data={data}
                                                            listData={listData}
                                                            setData={setlistData}
                                                            handleDelete={openDeleteDialog}
                                                            setLoading={setLoading}
                                                            fromRoute={fromRoute} />
                                                    </a>
                                                )}

                                            </div>
                                    }
                                </div>
            }


            <NewFlowsPopUp openModal={openModal} setOpenModal={setOpenModal} />


            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={DeleteNoteContent(currentFlowIdRef?.current, 'Flow', ` Deleting this Flow might affect Alert, Scheduler.`)}
                onConfirm={() => { deleteFlow(currentFlowIdRef?.current); setOpenDeletePopup(false) }}
            />

        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        loadingStatus: state.LoadingReducer.loadingStatus,
    };
};
export default connect(mapStateToProps)(FlowsGridListIndex)
