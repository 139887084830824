import React, { useEffect, useState } from 'react';
import CreateNewConn from './createNewConn';
import { Checkbox, List, Popover, Tabs, Tooltip } from "antd";
import { Literals } from "../../../common/literals";
import { setConnectionSelectedConn, setConnectionDynamicFields, openModelConnection, setConnectionOptionalDynamicFields } from '../../../../../store/modules/UserManagement/connection/connectionActions'
import { useDispatch, useSelector } from 'react-redux'
import SearchInput from '../../../../Common/search/Search';
import Buttons from '../../../../Common/button/Buttons';
import { FILTER, LISTVIEW, PLUS, TABVIEW, CONNECTIONS, CLOSE, GUIDEICON, RESETFILTER } from '../../../../Common/iconSource';
import { openModel } from '../../../../../store/actions';
import Filter from '../../../../Common/filter/Filter';
import { getObjectLength } from '../../../common/helperFunctions';

import { setViewMode } from '../../../../../store/app/appAction';
import { ICON_CHECK, ICON_GUIDE } from '../../../../Common/newIconSource';
import _ from 'lodash';
import SourceTypeImage from '../../../common/sourceTypeImage';

const ConnectionsHeader = ({
    tabsMenuList,
    activeTab,
    setActiveTab = () => { },
    searchValue,
    activeStep = 1,
    setSearchValue,
    layout,
    setLayout,
    setShowFilter,
    showFilter,
    selectedFilters,
    setselectedFilters,
    route,
    onClose,
    outboundRef = {},
    hideRightSideOptions = false,
    isAddButtonDisable = false,
    openGuideModal = false,
    isOptionsDisable = false,
    filterList = [],
    setFilterList = () => {}
}) => {

    const dispatch = useDispatch();
    const openModel1 = useSelector(state => state.UserManagement?.ConnectionReducer?.openModel)
    const [openCreateConnection, setOpenCreateConnection] = useState(false);
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [showFilterPop, setFilterPop] = React.useState(false);
    const [localFilterList, setLocalFilterList] = React.useState({sources: [], domains: []});
    const [filterSearchData, setFilterSearchData] = React.useState('');

    const isSuperUser = useSelector(state => state.UserReducer.user.userDetails.is_superuser)

    React.useEffect(() => {
        setLocalFilterList({sources: filterList.sources, domains: filterList.domains})
    }, [filterList])

    const closeCreateConnection = () => {
        setOpenCreateConnection(false);
    };
    const toggleBtnOption = [
        { label: <TABVIEW />, value: 'grid' },
        { label: <LISTVIEW />, value: 'list' },
    ];
    const setView = (view) => {

        // dispatch(setViewMode({
        //     module: "connection",
        //     view_type: view
        // })).then(() => {
        //     setLayout(view);

        // })

    }

    // useEffect(() => {
    //     let filters = [...selectedFilters],
    //         status = null
    //     if (filters.length === 0) status = false
    //     else if (filters.length > 0) {
    //         status = !filters.map(v => v[Object.keys(v)[0]].length > 0).every(v => v === false)
    //     }
    //     setFilterApplied(status)
    // }, [selectedFilters])
    const filterTabItems = React.useMemo(() => {
        const sources = [...localFilterList.sources]
        const domains = [...localFilterList.domains]
    
        const selectedSources = filterList?.sources?.filter(v => v.selected)
        const selectedDomains = filterList?.domains?.filter(v => v.selected)

        const searchTerm = filterSearchData?.toLowerCase()
        return [
            { 
                label: <span className="align-items-center d-flex small">
                    Sources
                    {
                        selectedSources?.length
                        ?
                        <span className="ml-2 badge badge-pill bg-primary text-white">{selectedSources?.length}</span>
                        : ''
                    }
                </span>, 
                key: 'item-1', 
                children: <div className="comm-flt-li">
                        {
                            sources?.length === 0
                            ?
                            <p className='mb-0 text-black-50 text-center p-3'>No Sources</p>
                            :
                            <List
                                size="small"
                                dataSource={
                                    _.sortBy(
                                        sources?.filter(v => v?.name?.toLowerCase()?.includes(searchTerm)),
                                        [(o) => o?.name?.toLowerCase()]
                                    )
                                }
                                renderItem={(item) => <List.Item>
                                        <Checkbox 
                                            className="mr-2" 
                                            checked={item.selected}
                                            disabled={false} 
                                            onChange={(e) => handleFilterChange('sources', item.name, e.target.checked)} 
                                        /> 
                                        <SourceTypeImage extraClassName={'d-flex'} dataName={item?.name} />
                                        <span className='ml-2'>{item.name}</span>
                                    </List.Item>
                                }
                            />
                        }
                    </div> 
            },
            { 
                label: <span className="align-items-center d-flex small">
                    Domains
                    {
                        selectedDomains.length
                        ?
                        <span className="ml-2 badge badge-pill bg-primary text-white">{selectedDomains.length}</span>
                        : ''
                    }
                </span>, 
                key: 'item-2', 
                children: <div className="comm-flt-li">
                        {
                            domains.length === 0
                            ?
                            <p className='mb-0 text-black-50 text-center p-3'>No Domains</p>
                            :
                            <List
                                size="small"
                                dataSource={
                                    _.sortBy(
                                        domains?.filter(v => v?.name?.toLowerCase()?.includes(searchTerm)),
                                        [(o) => o?.name?.toLowerCase()]
                                    )
                                }
                                renderItem={(item) => <List.Item>
                                        <Checkbox 
                                            className="mr-2" 
                                            checked={item.selected}
                                            disabled={false} 
                                            onChange={(e) => handleFilterChange('domains', item.name, e.target.checked)} 
                                        /> 
                                        {item.name}
                                    </List.Item>
                                }
                            />
                        }
                    </div> 
            }
        ]
    }, [filterList, localFilterList, filterSearchData]) 

    const handleFilterChange = (key, name, checked) => {
        const prevFunc = prev => (
            {
              ...prev,
              [key]: prev[key].map(v => ({...v, selected: name === v.name ? checked : v.selected }))
            }
        )
        
        setLocalFilterList(prevFunc)
    }

    const handleApply = () => {
        setFilterList({...localFilterList})

        // onApply(localFilterList)
    }

    const filterPopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Filters</p>
                    <div className="d-flex">
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon mr-2 border-0",
                                buttonEvent: () => {
                                    setFilterList(prev => (
                                        {
                                        sources: prev.sources.map(v => ({...v, selected: false })),
                                        domains: prev.domains.map(v => ({...v, selected: false }))
                                        }
                                    ))
                                    setFilterSearchData('')
                                    // onReset()
                                },
                                ImgSrc: () => <RESETFILTER />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon border-0",
                                buttonEvent: () => {
                                    setFilterPop(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div className='' style={{ width: '302px' }}>
                    <Tabs type="card" className="dict-filter-tabs" items={filterTabItems} />
                    {
                        <div className='p-2 d-flex align-items-center justify-content-between border-top' style={{gap: 10}}>
                            <div>
                                <SearchInput searchData={filterSearchData} setSearchData={setFilterSearchData} />
                            </div>
                            <Buttons
                                props={{
                                    buttonText: "Apply",
                                    buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                    buttonEvent: handleApply,
                                    ImgSrc: () => <ICON_CHECK />,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    isDisable: false,
                                    toggleBtnOption: null,
                                }}
                            />
                        </div>
                    }
                </div>
            </>
        }
    }

    const handleFilterVisibleChange = (visible) => {
        setFilterPop(visible)
    }

    return (
        <>
            <div className="sub-header px-0 connection-header-wrap">
                {route === "data-catalogue" ?
                    <div className="connection-heading">
                        <CONNECTIONS />
                        <h2 className="section-heading">Select a Connection</h2>
                    </div>
                    :
                    route === "target-transformer" ?
                        <div className='d-flex align-items-center' > <div className="connection-heading">
                            <CONNECTIONS />
                            <h2 className="section-heading">Select a Connection</h2>
                        </div>
                            <div className='form-step'>
                                <div className={activeStep === 1
                                    || activeStep === 2 ? 'active-step' : 'step-number'}>1</div>
                                <div class="line"></div>
                                <div className={activeStep === 2 ? 'active-step' : 'step-number'}>2</div>
                            </div>
                        </div> :
                        <div className="sub-header-left">
                            {/* <p>{Literals.CONNECTIONS}</p> */}
                            <div className="conn-tab-menu tab-menu">
                                {tabsMenuList.map((menu, i) =>
                                    <p key={`${i}-${menu}`} onClick={() => { setActiveTab(menu.key); menu.onClickEvent(menu.key) }} className={activeTab?.toLowerCase() === menu?.key?.toLowerCase() ? 'active subtitle' : 'subtitle'}>{menu.Icon()} {menu.title}</p>
                                )}

                            </div>
                        </div>
                }
                {
                    hideRightSideOptions ? <div style={{ position: 'relative' }}>
                        <Tooltip placement="topLeft" title={'Guide'}>
                            {/* <Buttons
                                props={{
                                    buttonText: '',
                                    buttonClassName: ` custom-btn-outline custom-btn btn-with-icon ml-2 mr-2`,
                                    buttonEvent: () => {
                                        dispatch(openModelConnection('filter'))
                                    },
                                    ImgSrc: () => <GUIDEICON />,
                                    isDisable: isOptionsDisable,
                                    buttonType: Literals.BTN_TERTIARY
                                }}
                            /> */}
                            <Buttons
                                props={{
                                    buttonText: 'Guide',
                                    tooltip: 'Guide',
                                    buttonClassName: `custom-btn-outline custom-btn btn-with-text`,
                                    buttonEvent: () => {
                                        openGuideModal(true)
                                    },
                                    ImgSrc: () => <ICON_GUIDE/>,
                                    isDisable: false,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                            {/* <div className='cursor-pointer' onClick={() => openGuideModal(true)}>
                                <GUIDEICON />

                            </div> */}
                        </Tooltip>
                    </div> :
                        <div className="sub-header-right d-flex">
                            {
                                activeStep === 1 ? 
                                <>  <Tooltip placement="topLeft" title={'Search Connection'}>
                                        <SearchInput isDisable={isOptionsDisable} searchData={searchValue} setSearchData={setSearchValue} />
                                    </Tooltip>
                                    <div style={{ position: 'relative' }}>
                                    <Popover 
                                        arrow={false}
                                        placement="bottomLeft"
                                        title={filterPopover.header}
                                        content={filterPopover.body}
                                        trigger="click"
                                        autoAdjustOverflow={true}
                                        open={showFilterPop}
                                        onOpenChange={handleFilterVisibleChange}
                                    >
                                        <Tooltip placement='bottom' title='Filter'>
                                            <button 
                                                className={`
                                                    ${showFilterPop ? "custom-btn-primary" : "custom-btn-outline"} 
                                                    custom-btn btn-with-icon border-0 ml-2 ${(filterList.sources?.filter(v => v.selected)?.length > 0 || filterList.domains?.filter(v => v.selected)?.length > 0) ? 'rdg-filter-active' : ''}`
                                                }
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setFilterPop(true)
                                                    }
                                                }
                                            >
                                                <FILTER />
                                            </button>
                                        </Tooltip>
                                    </Popover>



                                        {/* <Tooltip placement="topLeft" title={Literals.ICON_FILTER}>

                                            <Buttons
                                                props={{
                                                    buttonText: '',
                                                    buttonClassName: `${isFilterApplied ? 'rdg-filter-active' : ''} custom-btn-outline custom-btn btn-with-icon ml-2 mr-2`,
                                                    buttonEvent: () => {
                                                        // dispatch(openModelConnection('filter'))
                                                    },
                                                    ImgSrc: () => <FILTER />,
                                                    isDisable: isOptionsDisable,
                                                    buttonType: Literals.BTN_TERTIARY
                                                }}
                                            />
                                        </Tooltip> */}
                                    </div>
                                    </> : null
                            }
                            {/* {
                                route !== "data-catalogue" && route !== "target-transformer" && <Buttons props={{ buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: layout, buttonClassName: "toggle-btn custom-btn btn-with-icon pb-1 ", buttonEvent: setView, ImgSrc: () => <></>, isDisable: isOptionsDisable, buttonType: Literals.BTN_TOGGLE }} />
                            } */}
                            {
                                (route === "data-catalogue" || route === "target-transformer") && <Buttons props={{
                                    tooltip: 'Close',
                                    buttonText: '', buttonClassName: "custom-btn custom-btn-outline btn-with-icon", buttonEvent: () => {
                                        onClose()
                                    }, ImgSrc: () => <CLOSE />, isDisable: isOptionsDisable, buttonType: Literals.BTN_SECONDARY
                                }} />
                            }
                        </div>
                }
                {/* {openModel1 ?
                    <Filter props={{
                        filterList: getFormattedData(dbDataWithDbClone)?.length ? getFormattedData(dbDataWithDbClone) : [],
                        showPopover: 'old',
                        type: "connection",
                        selectedFilters: selectedFilters,
                        setselectedFilters: setselectedFilters,
                        setshowFilter: () => dispatch(openModelConnection(''))
                    }} /> : null} */}

                {<CreateNewConn
                    closeConnection={closeCreateConnection}
                    type={"create"}
                    setOpenCreateConnection={setOpenCreateConnection}
                    openModal={openCreateConnection}
                    setOpenModal={setOpenCreateConnection}
                />
                }
            </div>
            <Buttons
                props={{
                    buttonText: '',
                    buttonClassName: "custom-btn-primary custom-btn fixed-button addbutton_plusicon",
                    buttonEvent: () => {
                        if (activeTab === 'inbound') {
                            dispatch(setConnectionDynamicFields([]))
                            dispatch(setConnectionOptionalDynamicFields([]))
                            dispatch(setConnectionSelectedConn([]))
                            setOpenCreateConnection(true)
                        }
                        else if (activeTab === 'outbound') {
                            outboundRef.openModal()
                        }
                    },
                    tooltip: 'Add Connection',
                    tooltipPlacement: 'left',
                    ImgSrc: () => <PLUS />,
                    isDisable: activeTab === 'outbound' ? isAddButtonDisable : !isSuperUser,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                }} />
        </>
    )
}

export default ConnectionsHeader;