import { Dropdown, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";

import { useDispatch, useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from "reactstrap";
import { getLandingPageData, openModel, removePOD } from '../../../../../store/modules/dataCatalogue/landingPage/landingPageActions.js';
import ActionPopUp from "../../../common/actionPopUp";
import { Literals } from "../../../common/literals";
import { resendEmail } from "../../../../../store/modules/UserManagement/users/usersActions.js";

import Highlighter from "react-highlight-words";
import "toastr/build/toastr.min.css";
import { setLoadingStatus } from "../../../../../store/modules/common/loadingActions";
import Buttons from "../../../../Common/button/Buttons";
import { CRON, DELETEICON, DESIGNATION, EDIT, EMAIL, USERACTIVE, USERINACTIVE,RESEND_EMAIL } from "../../../../Common/iconSource";
import { CreateRuleContext } from "../../../common/Rules-context";
import './userPage.scss';
import { ICON_SUPERUSER } from "../../../../Common/newIconSource.js";
import { emitToastNotification } from "../../../../../helpers/toast_helper.js";
const UserCard = ({ CurrentUserDetails, userDetails, searchData, selectedDomainList, clickEdit, clickDelete, icon, tabName }) => {
  const history = useNavigate();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deletePopUpContent, setDeletePopUpContent] = useState(undefined);
  const dispatch = useDispatch()
  
  const tagList = useSelector(state => state.DataCatalogue?.LandingPage?.tagList);
  const context = useContext(CreateRuleContext)
  const store = useStore()
  const [showPopUp, setShowPopUp] = useState(false)
  const [optionOpen, setOptionOpen] = useState(false)

  const userStatus = userDetails?.status.toLowerCase() === 'active'
  
  const resendEmailFunc=()=>{

    dispatch(resendEmail({id:userDetails.key}))
  }


  const closeDeletePopUp = () => {
    setOpenDeletePopup(false);
  };
  const deletePOD = () => {
    setOpenDeletePopup(false);
    try {
      let requestBody = {
        "action": "delete_pod",
        "table_name": userDetails[1],
        "data_domain_id": userDetails[2],
        "data_domain_name": userDetails[3]
      }
      dispatch(removePOD(requestBody)).then(
        res => {
          if (res.status === 'success') {
            try {
              dispatch(getLandingPageData()).then((resp) => {
                dispatch(setLoadingStatus(false))
              })
            }
            catch (e) {

            }

          }
        }
      ).catch(err => err

      )
    }
    catch (e) {

    }
  };

  const openDeleteDialog = () => {

    setDeletePopUpContent({
      modalClass: "delete-popup",
      heading: "Delete",
      message: "This action will delete POD permanently.",
      button: [
        {
          name: "Delete",
          action: (e) => deletePOD(e),
        }
      ],
      elements: [],
      closeActionPopup: closeDeletePopUp,
    });
    setOpenDeletePopup(true);
  };

  const openAddTag = () => {
    dispatch(openModel('tag'))
  }




  const redirectTo = (id) => {
    if(id !== CurrentUserDetails?.id && !CurrentUserDetails?.is_superuser) {
      emitToastNotification('info', "You do not have permission")
      return
    }
    history(Literals.links.USER_MANAGEMENT_USER + id, {state: { tabName: Literals.USERS } })
    window.history.replaceState({ state: { tabName: Literals.USERS } }, '')
  }

  useEffect(() => {
    if (showPopUp) {

    }
  }, [showPopUp, userDetails, tagList])

  const optionList = [
    {
      label: <span className={`dbox-opt-itm ${CurrentUserDetails?.id !== userDetails?.key && !CurrentUserDetails?.is_superuser ? 'disabled' : ''}`} 
                  onClick={(e) => { 
                    e.stopPropagation();
                    e.preventDefault();
                    if(CurrentUserDetails?.id !== userDetails?.key && !CurrentUserDetails?.is_superuser)
                    {
                      return
                    }
                    clickEdit(userDetails)
                    }}>
                  <EDIT />
                  Edit
                </span>,
      key: '0',
      disabled: CurrentUserDetails?.id !== userDetails?.key && !CurrentUserDetails?.is_superuser
    },
    {
      label: <span className={`dbox-opt-itm danger ${CurrentUserDetails?.id === userDetails?.key || !CurrentUserDetails?.is_superuser ? 'disabled' : ''}`} 
          onClick={(e) => { 
            e.stopPropagation(); 
            e.preventDefault();
            if(CurrentUserDetails?.id === userDetails?.key || !CurrentUserDetails?.is_superuser) return
            clickDelete(userDetails)
          }}>
        <DELETEICON />
        Delete
      </span>,
      key: '1',
      disabled: CurrentUserDetails?.id === userDetails?.key || !CurrentUserDetails?.is_superuser
    },
  ]


  return (
    <div key={userDetails.key} className="h-100 position-relative user-card">
      <Card onClick={(e) => { e.preventDefault(); e.stopPropagation(); redirectTo(userDetails.key) }}>
        <CardBody>
          <div className='data-view-dbox' onClick={() => {}}>
            <div className='_src_img'>
              <div className="usr-icon-box">
                {icon}
              </div>
            </div>
            <div className='desc-box'>
                <div className="d-flex align-items-start">
                    <Tooltip placement='topLeft' title={`${userDetails?.firstName} ${userDetails?.lastName}`}>
                        <label style={{width: 'calc(100% - 52px)'}} className=' small mt-1 title-txt font-w-600 text-uppercase text-with-ellipsis'>
                          <Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={`${userDetails?.firstName} ${userDetails?.lastName}` ? `${userDetails?.firstName} ${userDetails?.lastName}` : ''}> {`${userDetails?.firstName} ${userDetails?.lastName}`}</Highlighter>
                        </label>
                    </Tooltip>
                    <div className="px-1 mr-1">
                      {
                        userDetails?.superuser?.toLocaleLowerCase() === 'yes' 
                        ? <Tooltip title="Super User" placement="top">
                            <span className="position-relative" style={{right: 3}}><ICON_SUPERUSER height="20" /></span>
                          </Tooltip> 
                        : null
                      }
                    </div>
                    
                    <Dropdown
                      menu={{
                        items: optionList,
                        onClick: (e) => e.domEvent.stopPropagation()
                      }}
                      trigger={[ "hover" ]}
                      placement="bottomRight"
                      onOpenChange={(bool) => setOptionOpen(bool)}
                      overlayStyle={{border:'solid 1px #e4e4e4'}}
                    >
                      <span onClick={e => e.stopPropagation()} className={`dbox-opts ${optionOpen ? 'active' : ''}`}><i className="bx bx-dots-vertical-rounded"></i></span>
                    </Dropdown>
                      
                </div>
                {/* <div>
                    <Tooltip placement='topLeft' title={userDetails.description}>
                    <p className='mb-1 paragraph text-black-50 small desc-txt custom-ellipsis two-line-ellipsis'>
                        {userDetails.description?.length>0? 
                        <Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={userDetails?.description ? userDetails?.description : ''}>{userDetails?.description}</Highlighter>
                        :"No description has been given yet."}
                    </p>
                    </Tooltip>
                </div> */}
                <section className="list-section">
                  <div className="list-column-first"><EMAIL /></div>
                  <div className="list-column-second"><span className="label">{Literals.EMAIL_ID}</span></div>
                  <div className="list-column"><Tooltip placement="topLeft" title={userDetails.emailAddress}>
                    <div className="paragraph d-block text-with-ellipsis">
                      {userDetails.emailAddress}
                      {/* < Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={userDetails.emailAddress} />   */}
                      </div>

                  </Tooltip></div>
                </section>
                <section className="list-section">
                  <div className="list-column-first"><DESIGNATION /></div>
                  <div className="list-column-second"><span className="label">{Literals.PERSONA}</span></div>
                  <div className="list-column"><Tooltip placement="topLeft" title={userDetails.designation}>
                    <span className="paragraph d-block text-with-ellipsis">
                      {userDetails.designation}
                      {/* < Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={userDetails.designation} /> */}
                    </span>
                </Tooltip></div>
                </section>
                <div className="d-flex">
                  <div className="d-flex align-items-center small px-1 rounded-pill" style={{background: userStatus ? '#d5f1dc' : '#f5dbdb'}}>
                      <span className={`sphere mr-1 ${userStatus ? 'bg-success' : 'bg-danger'}`}></span>
                      <label className={`text-capitalize mb-0 small font-w-600 ${userStatus ? 'text-success' : 'text-danger'}`}>
                        {!userStatus ? 'In' : ''}active
                      </label>
                  </div>
                  {
                    CurrentUserDetails?.id === userDetails?.key
                    ? <span className="fontSizeLabel bg-warning text-dark px-1 rounded text-uppercase fontInterSemiBold ml-auto">me</span> 
                    : ''
                  }
                </div>
            </div>
        </div>

        </CardBody>
      </Card>

      <ActionPopUp
        openModal={openDeletePopup}
        actionData={deletePopUpContent}
      />
    </div>
  );
};
export default UserCard;