import { fetchData } from "../../../../services/apiService";
import {
  RESET_NEXT_PAGE_COUNTER,
  RESET_NEXT_RECORD_TOKEN,
  RESET_TABLE_RECORDS,
  SET_ALL_DATA_LOADED,
  SET_ANALYZE_MODAL_VISIBILITY,
  SET_CORELATION_GRAPH,
  SET_IS_API_ERROR,
  SET_NEXT_PAGE_COUNTER,
  SET_PREVIEW_DATA_MAP,
  SET_PREVIEW_FILTERS,
  SET_PREVIEW_FLAG,
  SET_PREVIEW_INITIAL_STATE,
  SET_PREVIEW_NEXT_URI,
  SET_PREVIEW_PAGE_DEFAULT,
  SET_PREVIEW_QUERY,
  SET_PREVIEW_SEARCH_TERM,
  SET_PREVIEW_SEARCH_TEXT,
  SET_PREVIEW_SORT,
  SET_PREVIEW_TABLE,
  SET_PREVIEW_TABLE_RECORDS,
  SET_PREVIEW_TABLE_ROWSET,
  SET_QUERY_ERROR,
  SET_ROW_COUNT,
  SET_SAVED_QUERY_SELECTED_INDEX,
  SET_SAVE_SQL_TRIGGER,
  SET_SEARCH_MODE,
  SET_CHATGPT
} from "./previewActiontypes";

import "toastr/build/toastr.min.css";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import {
  setCurrentTableRowCount,
  setQueryKillFlag,
  setResponseQuery,
  setRowCountByUUID,
  setTableUUID,
} from "../../common/DataGridTable/dataGridTableRedux";
import { setLoadingStatus } from "../../common/loadingActions";
import { cloneDeep } from "lodash";

const BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const setPreviewTableData = (data) => {
  return {
    type: SET_PREVIEW_TABLE,
    payload: data,
  };
};

export const saveSQlTrigger = (data) => {
  return {
    type: SET_SAVE_SQL_TRIGGER,
    payload: data,
  };
};

export const setPreviewDataMap = (data) => {
  return {
    type: SET_PREVIEW_DATA_MAP,
    payload: data,
  };
};

export const setPreviewApiError = (data) => {
  return {
    type: SET_IS_API_ERROR,
    payload: data,
  };
};
export const setPreviewNextPageCounter = () => {
  return {
    type: SET_NEXT_PAGE_COUNTER,
    payload: {},
  };
};

export const resetPreviewNextPageCounter = () => {
  return {
    type: RESET_NEXT_PAGE_COUNTER,
    payload: {},
  };
};

export const resetNextRecordToken = (data) => {
  return {
    type: RESET_NEXT_RECORD_TOKEN,
    payload: data,
  };
};
export const setPreviewTableSearchTerm = (data) => {
  return {
    type: SET_PREVIEW_SEARCH_TERM,
    payload: data,
  };
};

export const setPreviewTableFilters = (data) => {
  return {
    type: SET_PREVIEW_FILTERS,
    payload: data,
  };
};
export const setPreviewTableSort = (data) => {
  return {
    type: SET_PREVIEW_SORT,
    payload: data,
  };
};
export const setPreviewTableQuery = (data) => {
  return {
    type: SET_PREVIEW_QUERY,
    payload: data,
  };
};
export const setPreviewTableFlag = (data) => {
  return {
    type: SET_PREVIEW_FLAG,
    payload: data,
  };
};
export const setPreviewTableRecords = (records, replacetableRecords) => {
  return {
    type: SET_PREVIEW_TABLE_RECORDS,
    payload: records,
    replacetableRecords: replacetableRecords,
  };
};

export const resetPreviewTableRecords = () => {
  return {
    type: RESET_TABLE_RECORDS,
    payload: {},
  };
};
export const setPreviewTableRowset = (data) => {
  return {
    type: SET_PREVIEW_TABLE_ROWSET,
    payload: data,
  };
};

export const savedQuerySelectedIndex = (data) => {
  return {
    type: SET_SAVED_QUERY_SELECTED_INDEX,
    payload: data,
  };
};

export const setSearchMode = (data) => {
  return {
    type: SET_SEARCH_MODE,
    payload: data,
  };
};
export const setNextUri = (data) => {
  return {
    type: SET_PREVIEW_NEXT_URI,
    payload: { next_uri: "", query: data.query },
  };
};

export const setAllDataLoaded = (data) => {
  return {
    type: SET_ALL_DATA_LOADED,
    payload: data,
  };
};

export const resetPreviewTableState = () => {
  return {
    type: SET_PREVIEW_INITIAL_STATE,
    payload: {},
  };
};
export const setRowCount = (count) => {
  return {
    type: SET_ROW_COUNT,
    payload: count,
  };
};
export const setPreviewPageDefault = () => {
  return {
    type: SET_PREVIEW_PAGE_DEFAULT,
  };
};
export const getPreviewTableRowCount = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let newPayload = { ...payload };
      newPayload["do_count"] = true;

      dispatch(fetchData("POST", `${BASE_URL}preview/v2/`, newPayload))
        .then((resp) => {
          if (resp.status !== "success") {
            reject(resp);
          } else {
            dispatch(setTableUUID(resp?.data?.uuid));
            dispatch(setRowCountByUUID({ uuid: resp?.data?.uuid, rowCount: resp?.data?.result?.data_record[0] }));
            dispatch(
              setCurrentTableRowCount(resp?.data?.result?.data_record[0])
            );
            resolve(resp);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const setChatGpt = (val) => {
  return {
    type: SET_CHATGPT,
    payload: val
  }
}
export const getPreviewTable = (
  payload,
  enableShowLoader = true,
  count = true,
  isSearch = false,
  showToast = true
) => {
  return (dispatch, getState) => {
    if (enableShowLoader) {
      dispatch(setLoadingStatus(true));
    }
    dispatch(setTableUUID(payload.uuid))
    dispatch(setQueryKillFlag(false));
    return new Promise((resolve, reject) => {
      if (count) dispatch(getPreviewTableRowCount(payload)).catch((e) => { });

      dispatch(fetchData("POST", `${BASE_URL}preview/v2/`, payload))
        .then((resp) => {
          if (enableShowLoader) {
            dispatch(setLoadingStatus(false));
          }
          dispatch(setPreviewApiError(false));

          if (resp.status != "success") {
            if (showToast) {
              emitToastNotification("error", resp.message);
            }
            reject(resp.message);
          } else {
            if (resp && resp.data && resp.data.result.data_record?.length) {
              dispatch(setPreviewTableData(resp.data.result));

              const existingPreviewData = {
                ...getState().DataCatalogue.PreviewPage.previewDataMap,
              };

              let payload_copy = { ...payload };
              if (payload?.fromProgressBar === true) {
                delete payload_copy.query;
              }
              existingPreviewData[
                Object.keys(payload_copy).includes("query")
                  ? "execute_query"
                  : "preview_query"
              ] = resp.data.result;
              dispatch(setPreviewDataMap(existingPreviewData));

              dispatch(
                setPreviewTableRecords(resp.data.result.data_record, false)
              );
            }
            else if (resp.data.result.data_record?.length === 0) {
              const existingPreviewData = {
                ...getState().DataCatalogue.PreviewPage.previewDataMap,
              };

              let payload_copy = { ...payload };
              if (payload?.fromProgressBar === true) {
                delete payload_copy.query;
              }
              if (Object.keys(payload_copy).includes("query")) {
                existingPreviewData["execute_query"] = resp.data.result;
              }
              // console.log({existingPreviewData})
              dispatch(setPreviewDataMap(existingPreviewData));
            }
            dispatch(setNextUri(resp?.data?.result));
            dispatch(setResponseQuery(resp.data.query));
            dispatch(setTableUUID(resp?.data?.uuid));
            if (enableShowLoader) {
              dispatch(setLoadingStatus(false));
            }
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));

          dispatch(setPreviewApiError(true));
          if (!isSearch) {
            if (showToast) {
              emitToastNotification("error", err?.message);
            }
          }
          reject(err);
        });
    });
  };
};

export const getSavedQueryList = (tableId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}query/v2/?table_id=${tableId}`))
        .then((resp) => {
          if (resp.status != "success") {
            emitToastNotification("error", resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

export const publishQueryAPI = (apiType, queryId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData(apiType, `${BASE_URL}query/v2/${queryId}/publish/`)
      )
        .then((resp) => {
          if (resp.status !== "success") {
            emitToastNotification("error", resp.message);
            reject(resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};
export const SaveQueryAPI = (payload, tableId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${BASE_URL}query/v2/?table_id=${tableId}`, payload)
      )
        .then((resp) => {
          if (resp.status !== "success") {
            emitToastNotification("error", resp.message);
            reject(resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};
export const UpdateQueryAPI = (payload, queryId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("PATCH", `${BASE_URL}query/v2/${queryId}/`, payload))
        .then((resp) => {
          if (resp.status !== "success") {
            emitToastNotification("error", resp.message);
            reject(resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

export const DeleteQueryAPI = (queryId) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("DELETE", `${BASE_URL}query/v2/${queryId}/`))
        .then((resp) => {
          if (resp.status !== "success") {
            emitToastNotification("error", resp.message);
            reject(resp.message);
            dispatch(setLoadingStatus(false));
          } else {
            resolve(resp);
            dispatch(setLoadingStatus(false));
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};
export const CancelQueryAPI = (queryId, showToast = false) => {
  return (dispatch) => {
    // dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("DELETE", `${BASE_URL}pod_summary/kill/1/?query_uuid=${queryId}`))
        .then((resp) => {
          if (resp.status !== "success") {
            // emitToastNotification("error", resp.message, "", {
            //   timeOut: 10000,
            // });
            reject(resp.message);
            // dispatch(setLoadingStatus(false));
          } else {
            dispatch(setQueryKillFlag(true))
            resolve(resp);

            // dispatch(setLoadingStatus(false));
          }
        })
        .catch((err) => {
          // dispatch(setLoadingStatus(false));
          if (showToast) {
            emitToastNotification("error", err.message);
          }
          reject(err);
        });
    });
  };
};


export const DownloadQueryResult = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));

    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}query_download`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          resolve(resp);
        })
        .catch((err) => {
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

export const CheckQueryStatusV2 = (payload, obj) => {
  return (dispatch) => {
    // dispatch(setLoadingStatus(true));

    return new Promise((resolve, reject) => {
      dispatch(
        fetchData(
          "GET",
          `${BASE_URL}query_download/v2/check_status/?file_name=${payload?.file_name}`,
          obj
        )
      )
        .then((resp) => {
          // dispatch(setLoadingStatus(false));
          resolve(resp);
        })
        .catch((err) => {
          // dispatch(setLoadingStatus(false));
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

function downloadFile(apiUrl, query) {


  // Define your query parameters
  const params = {
    query: query
  };

  // Create a form element to submit the POST request
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = apiUrl;
  form.target = '_blank'; // Open response in a new tab/window

  // Add input fields for each parameter
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = params[key];
      form.appendChild(input);
    }
  }

  // Add the form to the document body and submit it
  document.body.appendChild(form);
  form.submit();

  // Clean up: remove the form from the document body after submission
  document.body.removeChild(form);
}

export const retriveAPIBaseURL = (endpoint) => {
  const API_BASE_URL = window._env_.REACT_APP_API_BASE_URL;
  const TENANT_HOST_URL = window._env_.REACT_APP_PUBLIC_API_BASE_URL;
  let locaAPIURL = JSON.parse(localStorage.getItem('TENANT_HOST_URL'));
  return new Promise((res, rej) => {
    if (!API_BASE_URL || (endpoint && endpoint?.startsWith('undefined'))) {
      if (locaAPIURL?.REACT_APP_API_BASE_URL) {

        if (endpoint && endpoint?.includes('undefined')) {
          let newEndpoint = cloneDeep(endpoint);
          let url = newEndpoint;
          const undefinedStr = "undefined";
          // Find the index of the first occurrence of "undefined"
          let indexOfUndefined = url.indexOf("undefined");

          const startIndex = indexOfUndefined + undefinedStr?.length;

          if (startIndex !== -1) {
            // Extract the substring starting from the first occurrence of "undefined"
            let substringAfterUndefined = url.substring(startIndex);
            endpoint = `${locaAPIURL?.REACT_APP_API_BASE_URL}${substringAfterUndefined}`;

            return res(endpoint)
          } else {
            res(endpoint)

            console.log("The string 'undefined' was not found in the URL.");
          }
        } else {
          return res(endpoint)
        }
      } else {
        // let requestParams = {
        //   url: `${TENANT_HOST_URL}tenant/tenant_host/`,
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json;charset=UTF-8",
        //   }
        // };
        window.fetch(
          `${TENANT_HOST_URL}tenant/tenant_host/`,
          {
            method: 'POST',
            data: JSON.stringify({
              "UI_HOST": "dev.claristaconnect.com"
            }),
            headers:
            {
              "Content-Type": "application/json;charset=UTF-8",
            }
          }

        ).then((resp) => {

          if (resp.status === 200) {
            let data = resp?.data?.data

            window._env_['REACT_APP_API_BASE_URL'] = `https://${data?.APP_HOST}/`

            window._env_['REACT_APP_VISUALIZATION_BASE_URL'] = `https://${data?.SUPERSET_HOST}/`
            window._env_['REACT_APP_TRINO_HOST_URL'] = `${data?.TRINO_HOST}`

            let host_url = {
              'REACT_APP_API_BASE_URL': `https://${data?.APP_HOST}/`,

              'REACT_APP_VISUALIZATION_BASE_URL': `https://${data?.SUPERSET_HOST}/`,
              'REACT_APP_TRINO_HOST_URL': `${data?.TRINO_HOST}`,

            }
            localStorage.setItem('TENANT_HOST_URL', JSON.stringify(host_url))
            if (endpoint) {
              let newEndpoint = cloneDeep(endpoint);
              let url = newEndpoint;
              const undefinedStr = "undefined";
              // Find the index of the first occurrence of "undefined"
              let indexOfUndefined = url.indexOf("undefined");

              const startIndex = indexOfUndefined + undefinedStr?.length;

              if (startIndex !== -1) {
                // Extract the substring starting from the first occurrence of "undefined"
                let substringAfterUndefined = url.substring(startIndex);
                endpoint = `${data?.APP_HOST}${substringAfterUndefined}`;

                return res(endpoint)
              } else {
                res(endpoint)

              }
            }
          }
        })
      }
    } else {
      res(endpoint)
    }
  })
}

export const retriveSupersetAPIBaseURL = (endpoint) => {
  const API_BASE_URL = window._env_.REACT_APP_VISUALIZATION_BASE_URL;
  const TENANT_HOST_URL = window._env_.REACT_APP_PUBLIC_API_BASE_URL;
  let locaAPIURL = JSON.parse(localStorage.getItem('TENANT_HOST_URL'));
  return new Promise((res, rej) => {
    if (!API_BASE_URL || (endpoint && endpoint?.startsWith('undefined'))) {
      if (locaAPIURL?.REACT_APP_VISUALIZATION_BASE_URL) {

        if (endpoint && endpoint?.includes('undefined')) {
          let newEndpoint = cloneDeep(endpoint);
          let url = newEndpoint;
          const undefinedStr = "undefined";
          // Find the index of the first occurrence of "undefined"
          let indexOfUndefined = url.indexOf("undefined");

          const startIndex = indexOfUndefined + undefinedStr?.length;

          if (startIndex !== -1) {
            // Extract the substring starting from the first occurrence of "undefined"
            let substringAfterUndefined = url.substring(startIndex);
            endpoint = `${locaAPIURL?.REACT_APP_VISUALIZATION_BASE_URL}${substringAfterUndefined}`;

            return res(endpoint)
          } else {
            res(endpoint)

            console.log("The string 'undefined' was not found in the URL.");
          }
        } else {
          return res(endpoint)
        }
      } else {
        // let requestParams = {
        //   url: `${TENANT_HOST_URL}tenant/tenant_host/`,
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json;charset=UTF-8",
        //   }
        // };
        window.fetch(
          `${TENANT_HOST_URL}tenant/tenant_host/`,
          {
            method: 'POST',
            data: JSON.stringify({
              "UI_HOST": "dev.claristaconnect.com"
            }),
            headers:
            {
              "Content-Type": "application/json;charset=UTF-8",
            }
          }

        ).then((resp) => {

          if (resp.status === 200) {
            let data = resp?.data?.data

            window._env_['REACT_APP_API_BASE_URL'] = `https://${data?.APP_HOST}/`

            window._env_['REACT_APP_VISUALIZATION_BASE_URL'] = `https://${data?.SUPERSET_HOST}/`
            window._env_['REACT_APP_TRINO_HOST_URL'] = `${data?.TRINO_HOST}`

            let host_url = {
              'REACT_APP_API_BASE_URL': `https://${data?.APP_HOST}/`,

              'REACT_APP_VISUALIZATION_BASE_URL': `https://${data?.SUPERSET_HOST}/`,
              'REACT_APP_TRINO_HOST_URL': `${data?.TRINO_HOST}`,

            }
            localStorage.setItem('TENANT_HOST_URL', JSON.stringify(host_url))
            if (endpoint) {
              let newEndpoint = cloneDeep(endpoint);
              let url = newEndpoint;
              const undefinedStr = "undefined";
              // Find the index of the first occurrence of "undefined"
              let indexOfUndefined = url.indexOf("undefined");

              const startIndex = indexOfUndefined + undefinedStr?.length;

              if (startIndex !== -1) {
                // Extract the substring starting from the first occurrence of "undefined"
                let substringAfterUndefined = url.substring(startIndex);
                endpoint = `${data?.SUPERSET_HOST}${substringAfterUndefined}`;

                return res(endpoint)
              } else {
                res(endpoint)

              }
            }
          }
        })
      }
    } else {
      res(endpoint)
    }
  })
}

export const PrepareQueryResultV2 = async (payload) => {
  const apiUrl = await retriveAPIBaseURL(`${BASE_URL}query_download/stream_download/`);


  const query = payload.query;
  downloadFile(apiUrl, query)  //download file in background logic added

  // return (dispatch) => {

  //   dispatch(setLoadingStatus(true));

  //   return new Promise((resolve, reject) => {
  //     dispatch(
  //       fetchData(
  //         "POST",
  //         `${BASE_URL}query_download/v2/prepare_result/`,
  //         payload
  //       )
  //     )
  //       .then((resp) => {
  //         dispatch(setLoadingStatus(false));
  //         resolve(resp);
  //       })
  //       .catch((err) => {
  //         dispatch(setLoadingStatus(false));
  //         emitToastNotification("error", err.message);
  //         reject(err);
  //       });
  //   });
  // };
};
export const GetSearchResult = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        // fetchData("POST", `${BASE_URL}search/ask_suggest_query`, payload)
        fetchData("POST", `${BASE_URL}copilot/chat`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          resolve(resp);
        })
        .catch((err) => {
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

export const DownloadQueryResultV2 = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));

    return new Promise((resolve, reject) => {
      dispatch(
        fetchData(
          "GET",
          `${BASE_URL}query_download/v2/download/?file_name=${payload?.file_name}`,
          { download: true }
        )
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          resolve(resp);
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

export const CheckPreviewAPIStatus = (payload, isSearchPaylaod) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}preview`, payload))
        .then((resp) => {
          if (resp.status !== "success") {
            emitToastNotification("error", resp.message);

            reject(resp.message);
          } else {
            if (isSearchPaylaod) {
              dispatch(resetPreviewTableRecords());
            }
            if (resp && resp.data && resp.data.data_record?.length) {
              dispatch(setPreviewTableData(resp.data));
              dispatch(setPreviewTableRecords(resp.data.data_record, false));
            } else if (resp && resp.next_uri) {
            }
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification("error", err?.message);
          reject(err);
        });
    });
  };
};

export const GetNextPreviewTableRecord = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}preview`, payload))
        .then((resp) => {
          if (resp.status !== "success") {
            emitToastNotification("error", resp.message);
            reject(resp.message);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

export const SearchQueryPreview = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}preview`, payload))
        .then((resp) => {
          if (resp.status !== "success") {
            emitToastNotification("error", resp.message);
            reject(resp.message);
          } else {
            dispatch(setPreviewTableData(resp.data));
            dispatch(setPreviewTableRecords(resp.data.data_record));

            resolve(resp);
          }
        })
        .catch((err) => {
          emitToastNotification("error", err.message);
          reject(err);
        });
    });
  };
};

export const getPreview = (payload, enableShowLoader = true, showToast = true) => {
  return (dispatch) => {
    if (enableShowLoader) {
      dispatch(setLoadingStatus(true))
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}preview/v2/`, payload))
        .then((resp) => {
          if (enableShowLoader) {
            dispatch(setLoadingStatus(false));
          }
          if (resp.status !== "success") {
            if (showToast) {
              emitToastNotification('error', resp.message);
            }
            reject(resp.message);
          } else {
            if (enableShowLoader) {
              dispatch(setLoadingStatus(false))
            }
            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));

          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};


export const describeTable = (payload, showToast = true) => {
  return (dispatch) => {
      return new Promise((resolve, reject) => {
          dispatch(
              fetchData('POST', `${BASE_URL}preview/v2/describe_table/`, payload)
          )
              .then((resp) => {
                  if (showToast) emitToastNotification("success", resp.message);

                  resolve(resp);
              })
              .catch((err) => {
                  if (showToast) emitToastNotification("error", err.message);

                  reject(err);
              });
      });
  };
};

export const SetCorelationGraph = (payload) => {
  return {
    type: SET_CORELATION_GRAPH,
    payload: payload,
  };
};

export const setQueryError = (payload) => {
  return {
    type: SET_QUERY_ERROR,
    payload: payload,
  };
};

export const setPreviewSearchText = (payload) => {
  return {
    type: SET_PREVIEW_SEARCH_TEXT,
    payload,
  };
};

export const setAnalyzeModalVisibility = (isVisible) => {
  return {
    type: SET_ANALYZE_MODAL_VISIBILITY,
    payload: isVisible,
  };
};
