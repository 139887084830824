
import "toastr/build/toastr.min.css";
import {
  ADD_EDGES, ADD_EDITOR_TEXT, SAVE_NOTE_CLICK, SET_CURRENT_EXECUTING_TRANSFORMER_ID, SET_CURRENT_SCRIPTOUTPUT_NODE, SET_DATASOURCE_PODS, SET_FILTER_TRANSFORMER_CRITERIA, SET_FLOWS_CURRENTTRANSFORMER, SET_FLOWS_SHOWLEFTSIDEDETAILS, SET_SQL_EDIT_MODE, SET_SQL_TRANSFORMER_QUERY, SET_TO_DEFAULT, SET_TRANSFORMERLOADING_STATUS, SET_TRANSFORMER_MAXID,
  SET_TRANSFORMER_NAME, SET_TRANSFORMER_PAYLOAD, SET_TRANSFORMER_PROGRESS, SHOW_FLOWS_DATABRICKSNOTEBOOK,
  SET_TRANSFORMER_STATUS_CHANGE, SHOW_FLOWS_ATTOP, SHOW_FLOWS_JUPYTERNOTEBOOK, SHOW_FLOWS_LOGS_LOWERSECTION, SHOW_FLOWS_LOWERSECTION,
  SHOW_FLOWS_TRANSFORMDETAILS, UPDATE_FLOWS_BASICDETAILS, UPDATE_FLOWS_ELEMENTS
  , SHOW_FLOWS_TIMELINE_LOWERSECTION,
  SET_FLOWS_PERMISSIONS,
  SAVE_FLOW_CLICK,
  SET_ENTIREFLOW_EXECUTE_START
} from './flowsTypes';
import { getConnectedEdges } from 'reactflow';
import { cloneDeep, isNumber } from 'lodash';
import { fetchData } from "../../../../services/apiService";
import { setLoadingStatus } from "../../common/loadingActions";
import { convertDOMElementToImage } from "../../../../components/modules/common/helperFunctions";
import { emitToastNotification } from "../../../../helpers/toast_helper";
import { resetScriptRedux, setBricksOutputDataset, setScriptOutputDataset } from "../scriptTransformer/scriptAction";
var BASE_URL = window._env_.REACT_APP_API_BASE_URL;


export const showFlowsLowerSection = (data) => {
  return {
    type: SHOW_FLOWS_LOWERSECTION,
    payload: data
  };
}
export const showTimelineLowerSection = (data) => {
  return {
    type: SHOW_FLOWS_TIMELINE_LOWERSECTION,
    payload: data
  };
}

export const showFlowsLogsLowerSection = (data) => {
  return {
    type: SHOW_FLOWS_LOGS_LOWERSECTION,
    payload: data
  };
}
export const saveTransformerNoteClick = (data) => {
  return {
    type: SAVE_NOTE_CLICK,
    payload: data
  };
}

export const showJupyterNoteBook = (data) => {

  return {
    type: SHOW_FLOWS_JUPYTERNOTEBOOK,
    payload: data
  };
}

export const showDatabricksNoteBook = (data) => {
  return {
    type: SHOW_FLOWS_DATABRICKSNOTEBOOK,
    payload: data
  };
}

export const showFlowsAttop = (data) => {
  return {
    type: SHOW_FLOWS_ATTOP,
    payload: data
  };
}
export const setSQlTransformerQuery = (data) => {
  return {
    type: SET_SQL_TRANSFORMER_QUERY,
    payload: data
  };
}
export const showFlowsTransformdetails = (data) => {
  return {
    type: SHOW_FLOWS_TRANSFORMDETAILS,
    payload: data
  };
}

export const setSQLeditMode = (data) => {
  return {
    type: SET_SQL_EDIT_MODE,
    payload: data
  };
}
export const updateFlowsElements = (data) => {
  return {
    type: UPDATE_FLOWS_ELEMENTS,
    payload: data
  };
}

export const updateFlowsBasicDetails = (data) => {


  return {
    type: UPDATE_FLOWS_BASICDETAILS,
    payload: data
  };
}
export const setFlowsCurrenttransformer = (data) => {
  return {
    type: SET_FLOWS_CURRENTTRANSFORMER,
    payload: data
  };
}
export const setCurrentScriptoutputNode = (data) => {
  return {
    type: SET_CURRENT_SCRIPTOUTPUT_NODE,
    payload: data
  };
}
export const setDataSourcePodDetails = (data) => {
  return {
    type: SET_DATASOURCE_PODS,
    payload: data
  };
}

export const setToDefault = (data) => {
  return {
    type: SET_TO_DEFAULT,
    payload: data
  };
}

export const setFilterTransformerFilterCriteria = (data) => {
  return {
    type: SET_FILTER_TRANSFORMER_CRITERIA,
    payload: data
  };
}
export const setTransformerLoadingStatus = (data) => {
  return {
    type: SET_TRANSFORMERLOADING_STATUS,
    payload: data
  };
}

export const setExecutedTransformerPayload = (data) => {
  return {
    type: SET_TRANSFORMER_PAYLOAD,
    payload: data
  };
}


export const addEdges = (data) => {
  return {
    type: ADD_EDGES,
    payload: data
  };
}

export const setTransformerMaxId = (data) => {
  return {
    type: SET_TRANSFORMER_MAXID,
    payload: data
  };
}
export const setTransformerNames = (data) => {
  return {
    type: SET_TRANSFORMER_NAME,
    payload: data
  };
}
export const addEditorText = (data) => {
  return {
    type: ADD_EDITOR_TEXT,
    payload: data
  };
}

export const setFlowsPermissions = (data) => {
  return {
    type: SET_FLOWS_PERMISSIONS,
    payload: data
  };
}

const removeElements = (elementToRemove, flowsElements) => {

  const copyFlowsElements = cloneDeep(flowsElements)

  const initialEdges = flowsElements?.edges;
  const Edges = getConnectedEdges(elementToRemove, initialEdges)?.map((item) => item?.id)
  const removeElementId = elementToRemove.map((item) => item.id)

  if (Edges?.length) {
    copyFlowsElements['nodes'] = copyFlowsElements?.nodes.filter((item) => !removeElementId.includes(item.id))
    copyFlowsElements['edges'] = copyFlowsElements?.edges.filter((item) => !Edges.includes(item.id))

  } else {
    copyFlowsElements['nodes'] = copyFlowsElements?.nodes.filter((item) => !removeElementId.includes(item.id))
  }
  return copyFlowsElements
}

export const flowsExitMode = (flowId) => {
  return (dispatch) => {

    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("DELETE", `${BASE_URL}flow/${flowId}/edit_mode/`, {})
      )
        .then((resp) => {

          if (resp.status !== 'success') {
            emitToastNotification('error', resp.message)

          } else {

            resolve(resp);
            dispatch(setLoadingStatus(false));

          }

        })
        .catch((err) => {

          reject(err);
          dispatch(setLoadingStatus(false));
        })
    });
  };
};

export const getAlertAssigneeList = (flowId) => {
  return (dispatch) => {

    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${BASE_URL}flow/${flowId}/recipients/`, {})
      )
        .then((resp) => {

          if (resp.status !== 'success') {
            emitToastNotification('error', resp.message)

          } else {

            resolve(resp);
            dispatch(setLoadingStatus(false));

          }

        })
        .catch((err) => {

          reject(err);
          dispatch(setLoadingStatus(false));
        })
    });
  };
};
export const getSchedulerParametersList = (schedulerId) => {
  return (dispatch) => {

    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${BASE_URL}scheduler/${schedulerId}/param_secret/`, {})
      )
        .then((resp) => {

          if (resp.status !== 'success') {
            emitToastNotification('error', resp.message)

          } else {

            resolve(resp);
            dispatch(setLoadingStatus(false));

          }

        })
        .catch((err) => {

          reject(err);
          dispatch(setLoadingStatus(false));
        })
    });
  };
};

export const updateSchedulerParametersList = (schedulerId,payload) => {
  return (dispatch) => {

    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${BASE_URL}scheduler/${schedulerId}/param_secret/`, payload)
      )
        .then((resp) => {

          if (resp.status !== 'success') {
            emitToastNotification('error', resp.message)

          } else {

            resolve(resp);
            dispatch(setLoadingStatus(false));

          }

        })
        .catch((err) => {

          reject(err);
          dispatch(setLoadingStatus(false));
        })
    });
  };
};

export const getParametersList = (flowId) => {
  return (dispatch) => {

    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${BASE_URL}flow/variable/?flow_id=${flowId}`, {})
      )
        .then((resp) => {

          if (resp.status !== 'success') {
            emitToastNotification('error', resp.message)

          } else {

            resolve(resp);
            dispatch(setLoadingStatus(false));

          }

        })
        .catch((err) => {

          reject(err);
          dispatch(setLoadingStatus(false));
        })
    });
  };
};

export const updateParametersList = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("POST", `${BASE_URL}flow/variable/bulk_create_update/`, payload)
      )
        .then((resp) => {

          if (resp.status !== 'success') {
            emitToastNotification('error', resp.message)

          } else {
            emitToastNotification('success', resp.message)

            resolve(resp);
            dispatch(setLoadingStatus(false));

          }

        })
        .catch((err) => {
          emitToastNotification('error', err.message)

          reject(err);
          dispatch(setLoadingStatus(false));
        })
    });
  };
};

export const deleteParameterDetails = (parameterID) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("DELETE", `${BASE_URL}flow/variable/${parameterID}`, {})
      )
        .then((resp) => {

          if (resp.status !== 'success') {
            emitToastNotification('error', resp.message)

          } else {
            emitToastNotification('success', resp.message)

            resolve(resp);
            dispatch(setLoadingStatus(false));

          }

        })
        .catch((err) => {

          reject(err);
          dispatch(setLoadingStatus(false));
        })
    });
  };
};


export const deleteTransformer = (transformerId, flowId) => {
  return (dispatch, getState) => {


    let flowsElements = cloneDeep(getState().Lab.flowsElements)
    let elementToRemove = flowsElements?.nodes.find((ele) => ele.id?.toString() === transformerId?.toString())

    dispatch(setLoadingStatus(true));

    dispatch(fetchData(`DELETE`, `${BASE_URL}flow/${flowId}/delete_transformer/?transformer=${elementToRemove?.transformerName ? elementToRemove?.transformerName : 'null'}`, ''))
      .then((resp) => {
        if (resp.status === "success") {
          dispatch(setLoadingStatus(false));
        } else {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', resp?.message);
        }
      }).catch((err) => {
        dispatch(setLoadingStatus(false));
        emitToastNotification('error', err?.message);

      })
    if (flowsElements && flowsElements?.nodes?.length) {


      if (elementToRemove && (elementToRemove.type === 'script' || elementToRemove.type === 'databricks')) {

        let scriptPairedOutputNode = elementToRemove.paired;
        let tempNode = []
        if (scriptPairedOutputNode && scriptPairedOutputNode?.length) {
          for (let index = 0; index < scriptPairedOutputNode?.length; index++) {
            let findNode = flowsElements?.nodes.find((ele) => ele.id === scriptPairedOutputNode[index])
            if (findNode && findNode.id) {
              tempNode.push(findNode)
            }
          }

        }
        let newFlowsElements = removeElements([...tempNode, elementToRemove], flowsElements)


        let transformerNameList = getState().Lab.transformerName




        for (let index = 0; index < scriptPairedOutputNode?.length; index++) {
          const node = scriptPairedOutputNode[index];
          delete transformerNameList[node]
        }
        for (const key in transformerNameList) {

          if (key === transformerId) {
            delete transformerNameList[key]
          }
        }

        dispatch(setTransformerNames(transformerNameList))

        dispatch(updateFlowsElements(newFlowsElements))

      } else {
        let newFlowsElements = removeElements([elementToRemove], flowsElements)
        dispatch(updateFlowsElements(newFlowsElements))

      }



    }




  }
}

export const generateFlowIDApi = (payload) => {

  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(`POST`, `${BASE_URL}flow/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));


            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));

          if (err.message) {

            emitToastNotification('error', err?.message);
          }
          emitToastNotification('error', err?.data?.message);
          reject(err);
        })
    })
  }
}

export const executeEntireFlow = (id, payload) => {
  return (dispatch, getState) => {

    dispatch(setLoadingStatus(true))
    dispatch(setTransformerLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(`POST`, `${BASE_URL}flow/${id}/execute_transformer/`, payload))
        .then((resp) => {
          dispatch(setLoadingStatus(false))

          if (resp.status === "success") {
            dispatch(setTransformerLoadingStatus(false));
            emitToastNotification('success', resp.message)
            resolve(resp)
          } else {
            dispatch(setTransformerLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false))

          dispatch(setTransformerLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        })
    })
  }
}


export const getOutcomeColumns = (payload, showLoader = false) => {

  return (dispatch) => {
    // if (showLoader) {
    //   dispatch(setLoadingStatus(true));

    // }

    return new Promise((resolve, reject) => {

      dispatch(
        fetchData("POST", `${BASE_URL}preview/v2/?get_columns=true`, payload)
      )
        .then((resp) => {
          dispatch(setLoadingStatus(false));
          if (resp.status !== 'success') {
            // emitToastNotification('error', resp.message)
            reject(resp.message)
          } else {

            resolve(resp);
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          // emitToastNotification('error', err?.message);
          reject(err);
        })
    });
  };
};

export const executeTransformerApi = (id, transformerName, currentTransformerID) => {
  return (dispatch, getState) => {

    let newPayload = {

      task_name: transformerName
    }


    dispatch(setTransformerLoadingStatus(true));
    let apiPayload = {
      page: '',
      limit: '',
      execution_id: '',
      flow_Id: id
    }

    dispatch({ type: SET_CURRENT_EXECUTING_TRANSFORMER_ID, payload: currentTransformerID, })
    return new Promise((resolve, reject) => {
      dispatch(fetchData(`POST`, `${BASE_URL}flow/${id}/execute_transformer/`, newPayload))
        .then((resp) => {

          dispatch(setTransformerLoadingStatus(false));

          if (resp.status === "success") {
            dispatch(setTransformerLoadingStatus(false));
            dispatch(RetriveExecutionFlows(apiPayload))

            dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: false });
            resolve(resp)
          } else {
            dispatch(setTransformerLoadingStatus(false));
            dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: false });

            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {

          dispatch({ type: SET_ENTIREFLOW_EXECUTE_START, payload: false });

          dispatch(setTransformerLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        })
    })
  }
}

export const getAlertLogsGraphData = (id, transformer_id) => {
  return (dispatch) => {
    // dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData("GET", `${BASE_URL}flow/${id}/alert_log/?transformer_id=${transformer_id}`, {})
      )
        .then((resp) => {
          // dispatch(setLoadingStatus(false));

          if (resp.status !== 'success') {
            emitToastNotification('error', resp.message)

          } else {

            resolve(resp);
          }

        })
        .catch((err) => {
          // dispatch(setLoadingStatus(false));

          reject(err);
        })
    });
  };
};

export const saveFlow = (id, showLoader = false, endActivity = false, canvasImage) => {
  return (dispatch, getState) => {

    let newFlowsElements = getState().Lab.flowsElements;
 



    // if (!nodes?.length && !edges?.length) {
    //   dispatch(setLoadingStatus(false));
    //   if (showLoader) {   //msg will display for manual save not for auto save
    //     emitToastNotification('warning', 'Please add atleast one transformer to save the flow');
    //   }

    //   return;
    // } else {  
    // if atleast one node on canvas then only save will call
    if (showLoader) {
      dispatch(setLoadingStatus(true));
    }
    let payload = {
      "name": "string",
      "description": "string",
      "priority": "string",
      "scheduled": false,
      "schedule_body": {},
      // "flow_body": {
      //   nodes: [],
      //   edges: [],

      // },
      "flow_redux": {

      },
      "pod_ids": [],

      "thumbnail": canvasImage//await convertDOMElementToImage(".react-flow__viewport", "exact") ///: undefined
    }

    let pod_ids = newFlowsElements?.nodes.map((ele, i) => ele?.type === 'datasource' && ele?.content?.table_id).filter((ele) => ele)


    payload['flow_redux']['flowsElements'] = getState().Lab.flowsElements;
    payload['flow_redux']['maxTransformerId'] = getState().Lab.maxTransformerId;
    payload['name'] = getState().Lab.name
    payload['description'] = getState().Lab.description
    payload['pod_ids'] = pod_ids
    // payload['flow_body']['nodes'] = nodes ? nodes : []
    // payload['flow_body']['edges'] = edges ? edges : []


    return new Promise((resolve, reject) => {
      dispatch(fetchData(`PUT`, `${BASE_URL}flow/${id}/?end_activity=${endActivity ? true : false}`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            dispatch({ type: SAVE_FLOW_CLICK, payload: false })

            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            dispatch({ type: SAVE_FLOW_CLICK, payload: false })

            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          dispatch({ type: SAVE_FLOW_CLICK, payload: false })

          reject(err);
        })
    })
    // }

  }
}

export const fetchFlowsDetails = ({ method, endPoint, payload }) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData(method, `${BASE_URL}${endPoint}/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}

export const flowsDomainFilter = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}flow/?data_domain_id=${payload}`))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}

export const RetriveExecutionFlows = (payload) => {
  return (dispatch, getState) => {

    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}flow/${payload.flow_Id}/latest_status/`))


        .then((resp) => {
          if (resp.status === "success") {

            if (resp?.data?.length) {
              if (resp?.data?.length) {
                let data = resp.data.map((ele) => {
                  ele.state = ele.state ? ele.state?.toLowerCase() : ''
                  return ele
                })
                dispatch({ type: SET_TRANSFORMER_PROGRESS, payload: data })


              }
              dispatch({ type: SET_TRANSFORMER_STATUS_CHANGE, payload: { status: true } })


            }

            resolve(resp)
          } else {

            dispatch({ type: SET_TRANSFORMER_STATUS_CHANGE, payload: { status: false } })

            // emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch({ type: SET_TRANSFORMER_STATUS_CHANGE, payload: false })

          // emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}

export const EditFlowsAPI = (payload) => {
  return (dispatch, getState) => {

    return new Promise((resolve, reject) => {
      dispatch(fetchData(payload.ApiType, `${BASE_URL}flow/${payload.flow_Id}/edit_mode/`))


        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp)
          } else {


            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch({ type: SET_TRANSFORMER_STATUS_CHANGE, payload: false })

          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}
export const editFlowNameApiCall = (id, payload) => {
  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    return new Promise((resolve, reject) => {
      dispatch(fetchData('PATCH', `${BASE_URL}flow/${id}/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}
export const getFlowDetails = (id) => {
  const searchParams = new URLSearchParams(document.location.search)
  const history_id = searchParams.get("history_id");
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");

  return (dispatch) => {
    dispatch(setLoadingStatus(true));
    let apiPayload = {
      page: '',
      limit: '',
      execution_id: '',
      flow_Id: id
    }
    return new Promise((resolve, reject) => {
      dispatch(fetchData('GET', `${BASE_URL}flow/${id}/${history_id ? `?history_id=${history_id}&start_date=${start_date}&end_date=${end_date}` : ""}`))
        .then((resp) => {
          if (resp.status === "success") {
            dispatch(setLoadingStatus(false));
            dispatch(RetriveExecutionFlows(apiPayload))
            resolve(resp)
          } else {
            dispatch(setLoadingStatus(false));
            emitToastNotification('error', resp?.message);
            reject(resp?.message);
          }
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
          emitToastNotification('error', err?.message);
          reject(err);
        });
    })
  }
}

export const assignFlowTag = (payload, flowId, showToast = true) => {
  return (dispatch) => {
      return new Promise((resolve, reject) => {
          dispatch(
              fetchData('POST', `${BASE_URL}flow/${flowId}/tag/`, payload)
          )
              .then((resp) => {
                  if (showToast) emitToastNotification("success", resp.message);

                  resolve(resp);
              })
              .catch((err) => {
                  if (showToast) emitToastNotification("error", err.message);

                  reject(err);
              });
      });
  };
};

export const unassignFlowTag = (tagId, flowId, showToast = true) => {
  return (dispatch) => {
      return new Promise((resolve, reject) => {
          dispatch(
              fetchData('DELETE', `${BASE_URL}flow/${flowId}/tag/?tag=${tagId}`)
          )
              .then((resp) => {
                  if (showToast) emitToastNotification("success", resp.message);

                  resolve(resp);
              })
              .catch((err) => {
                  if (showToast) emitToastNotification("error", err.message);

                  reject(err);
              });
      });
  };
};

export const setFlowsShowleftsidedetails = (data) => {
  return {
    type: SET_FLOWS_SHOWLEFTSIDEDETAILS,
    payload: data
  };
}
