import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import LoadingComponent from '../../../common/loadingComponent';
import NoSearchResultFound from '../../../common/NoSearchResultFound';
import UserManagementCard from '../../common/UserManagementCard';
import { CONNECTIONS, DATADOMAIN, LISTVIEW, PODICON, TABVIEW } from "../../../../Common/iconSource";
import SearchInput from '../../../../Common/search/Search';
import DataDomainPODGrid from './DataDomainPODGrid';
import { getDataDomainPODList } from '../../../../../store/actions';
import { setLoadingStatus } from '../../../../../store/modules/common/loadingActions';
import SourceTypeImage from '../../../common/sourceTypeImage';
import { Tooltip } from 'antd';
import { getRelativeTime } from '../../../common/helperFunctions';
import Highlighter from 'react-highlight-words';
import { Literals } from '../../../common/literals';
import { LandingSimpleTable } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import { useNavigate } from 'react-router-dom';
import Buttons from '../../../../Common/button/Buttons';
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader';
import { ICON_CATALOG } from '../../../../Common/newIconSource';



const PodList = ({ id }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const loadingStatus = useSelector(state => state.LoadingReducer.loadingStatus);
  const [searchData, setSearchData] = useState("");
  const [PODListData, setPODListData] = useState([]);
  const [layout, setlayout] = useState('list')
  const [listData, setlistData] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const history = useNavigate()

  useEffect(() => {
    setshowLoader(true)
    getPODListByDataDomain()
  }, [])
  function getPODListByDataDomain() {
    try {
      let requestBody = {
        "action": "list_pod",
        "data_domain_id": id
      }
      dispatch(getDataDomainPODList(requestBody)).then((resp) => {
        if (resp.status === "success") {
          setPODListData(resp.data);
          setshowLoader(false)

        } else {
          setshowLoader(false)

        }
        dispatch(setLoadingStatus(false))

      })
    }
    catch (e) {
      setshowLoader(false)

    }
  }

  const filterData = PODListData && PODListData.filter((data) => {
    return (
      data.table_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
      data.source_type?.toLowerCase().includes(searchData?.toLowerCase()) ||
      data.pod_description?.toLowerCase().includes(searchData?.toLowerCase()) ||
       getRelativeTime(data.created_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
      data.data_domain_detail.data_domain_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
      data.created_by_user.email?.toLowerCase().includes(searchData?.toLowerCase())
    )
  })
  const getFilterData = () => {
    return PODListData && PODListData.filter((data) => {
      return (
        data.table_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
        data.source_type?.toLowerCase().includes(searchData?.toLowerCase()) ||
        data.pod_description?.toLowerCase().includes(searchData?.toLowerCase()) ||
         getRelativeTime(data.created_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
        data.data_domain_detail.data_domain_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
        data.created_by_user.email?.toLowerCase().includes(searchData?.toLowerCase())
      )
    })
  }

  function getCardView() {
    if (filterData && filterData.length > 0) {
      return (
        filterData.map((ele, i) =>
          <DataDomainPODGrid key={i} podDetails={ele} selectedRulesPODList={filterData} />
        )
      )
    }
  }

  useEffect(() => {

    if (PODListData?.length && getFilterData()?.length) {
      let records = [...getFilterData()]
      let temp = []
      for (let i = 0; i < records.length; i++) {

        temp.push(
          {
            key: records[i],
            connection_name: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].table_name?.toUpperCase()}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].table_name ? records[i].table_name?.toUpperCase() : ''} /></Tooltip></span>,
            description: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].pod_description}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].pod_description ? records[i].pod_description : ''} /></Tooltip></span>,
            database_name: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].data_domain_detail?.data_domain_name}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].data_domain_detail?.data_domain_name ? records[i].data_domain_detail?.data_domain_name : ''} /></Tooltip></span>,
            sources: <div className='text-center'><SourceTypeImage dataName={records[i].source_type} align="center" /></div>,
            createdOn: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={getRelativeTime(records[i].created_on)}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={getRelativeTime(records[i].created_on) ? getRelativeTime(records[i].created_on) : ''} /></Tooltip></span>,
            createdBy: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].created_by_user.email}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].created_by_user.email ? records[i].created_by_user.email : ''} /></Tooltip></span>,

          }
        )
      }
      setlistData([...temp])
    }
  }, [PODListData, searchData])

  const columns = [
    {
      name: 'Pod Name',
      key: "connection_name",

    },
    {
      name: <div className='text-center'>{Literals.SOURCES}</div>,
      key: "sources",
      resizable: false,
      sortable: false,

    },
    {
      name: Literals.DESCRIPTION,
      key: "description",
      width: 350,
      resizable: true,
      sortable: false,


    },

    {
      name: Literals.CREATED_ON,
      key: "createdOn",
      resizable: false,


    },
    {
      name: Literals.CREATED_BY,
      key: "createdBy",
      resizable: false,



    },

  ];


  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case 'connection_name':
      case 'database_name':
      case 'createdOn':
      case 'createdBy':
        return (a, b) => a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight.localeCompare(b[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight) : null
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }
  const toggleBtnOption = [
    { label: <TABVIEW />, value: 'grid' },
    { label: <LISTVIEW />, value: 'list' },
  ];
  const setView = (view) => {
    setlayout(view);
  }


  return (
    <Fragment>
      <div className="dataDomain-lower-part mt-3">
        <div className="lower-subheader">
          <div className="sub-header-left">
            <div className="heading-content">
              <span className="domain-icon"><ICON_CATALOG height='20' width='20' /></span>
              <p>List Of POD</p>
            </div>
          </div>
          <div className="sub-header-right d-flex">
            <SearchInput searchData={searchData} setSearchData={setSearchData} />
            {/* <div className='ml-2'>
              <Buttons props={{ buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: layout, buttonClassName: "toggle-btn custom-btn btn-with-icon pb-1 ", buttonEvent: setView, ImgSrc: () => <></>, isDisable: false, buttonType: Literals.BTN_TOGGLE }} />
            </div> */}
            {/* <ul className="landingPage_navbar-nav-right">
              <li className="landingPage_nav-item mr-2">
              </li>
              <li className="landingPage_nav-item">
              </li>
            </ul> */}
          </div>
          {/* <div className="sub-header-right">
            <ul className="landingPage_navbar-nav-right">
              <li className="landingPage_nav-item mr-2">
                <SearchInput setSearchData={setSearchData} />
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <div>
        {filterData?.length === 0 ? showLoader ? <ClaristaLoader isCancel={false} showErrorToast={false} width='100%' height='100%'></ClaristaLoader> : <div className='mt-20'><NoSearchResultFound showSubMsg={false} width={'100'} height={'100'} /></div> : layout === 'grid' ? <div className="data-domain-gridview"> {getCardView()}</div> :
          <LandingSimpleTable
            cols={columns}
            autoWidth={true}
            rowsData={listData}
            getComparator={getComparator}
            onRowClick={(e) => { history(Literals.links.DATA_CATALOG_POD_DETAILS + e.key.table_id) }}
            tableHeight={(listData?.length * 41) + 41}
          />}
      </div>
    </Fragment>
  )

}

export default PodList;