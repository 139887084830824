import React, { useEffect, useState } from 'react'
import Buttons from '../../../Common/button/Buttons'
import { Literals } from '../../common/literals'
import { ICON_DASHBOARD } from '../../../Common/newIconSource'
import { CLOCK, FLOWS, PLUS, REDIRECTJOBS } from '../../../Common/iconSource'
import FlowSelectionModal from '../../alert/createAlert/FlowSelectionModal'
import { useSelector, useStore } from 'react-redux'
import { SetSchedulerPayload } from '../../../../store/modules/scheduler/action'
import { cloneDeep } from 'lodash'
import { Input, Radio, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import { getObjectLength } from '../../common/helperFunctions'
import DashboardSelectionModal from '../../alert/createAlert/DashboardSelectionModal'


function ScheduleModelSelection({ isExecutePermission,setshowParameterBtn,setrefreshUserList }) {
    // const toggleBtnOption = ['FLOW', 'DASHBOARD'];

    let toggleBtnOption = [{ label: <div className='d-flex align-items-center justify-content-center'><div className='col-1 p-0'><CLOCK /></div><div className='col-1 p-0'></div>Flow</div>, value: 'FLOW', disabled: !isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view') ? true : false }, { label: <div className='d-flex align-items-center justify-content-center'><div className='col-1 p-0'><CLOCK /></div><div className='col-1 p-0'></div>Dashboard</div>, value: 'DASHBOARD', disabled: !isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view') ? true : false },]
    const [toggleValue, settoggleValue] = useState('FLOW')

    const flowsAlertTransformerList = useSelector(state => state.Alert.CreateAlert?.flowsAlertTransformerList)
    const selectedScheduledDashboard = useSelector(state => state.Scheduler?.selectedScheduledDashboard)

    const [openFlowSelectionModal, setopenFlowSelectionModal] = useState(false)
    const [openDashboardSelectionModal, setopenDashboardSelectionModal] = useState(false)
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)
    const [selectedTypeName, setselectedTypeName] = useState('');

    const navigate = useNavigate()

    const store = useStore();



    useEffect(() => {
        const newPayload = cloneDeep(schedulerPayload);

        if (newPayload['task_type']?.toLowerCase() === 'flow') {
            if (getObjectLength(flowsAlertTransformerList)) {
                setselectedTypeName(flowsAlertTransformerList?.selectedFlowDetails?.name)
                newPayload['flow'] = flowsAlertTransformerList?.selectedFlowDetails?.id;
            } else {
                setselectedTypeName('')
                newPayload['flow'] = null;
            }
        }
        else if (newPayload['task_type']?.toLowerCase() === 'dashboard') {
            if (getObjectLength(selectedScheduledDashboard)) {
                setselectedTypeName(selectedScheduledDashboard?.selectedDashboardDetails?.name)
                newPayload['dashboard'] = selectedScheduledDashboard?.selectedDashboardDetails?.id;

            } else {
                setselectedTypeName('')
                newPayload['dashboard'] = null;

            }
        }


        store.dispatch(SetSchedulerPayload(newPayload))

    }, [flowsAlertTransformerList, selectedScheduledDashboard]);


    useEffect(() => {
        settoggleValue(schedulerPayload?.task_type)
        if(schedulerPayload?.task_type.toLowerCase() === 'dashboard'){
            setshowParameterBtn(false)
        }
    }, [schedulerPayload])


    const onToggleChange = (value) => {
        // settoggleValue(value);
        const newPayload = cloneDeep(schedulerPayload);
        newPayload['task_type'] = value;

        store.dispatch(SetSchedulerPayload(newPayload));

        if (value.toLowerCase() === 'flow') {
            if (getObjectLength(flowsAlertTransformerList)) {
                setselectedTypeName(flowsAlertTransformerList?.selectedFlowDetails?.name)

            } else {
                setselectedTypeName('')

            }
        }
        else if (value.toLowerCase() === 'dashboard') {
            setshowParameterBtn(false)
            if (getObjectLength(selectedScheduledDashboard)) {
                setselectedTypeName(selectedScheduledDashboard?.selectedDashboardDetails?.name)  // dashboard scheduler integration done
            } else {
                setselectedTypeName('')


            }
        }
    }




    return (
        <>

            <div className="d-flex justify-content-between align-items-center">
                <div className='col-5 '>
                    <label className='mt-0 label'>Schedule On</label>
                    <div className="toggle-btn-with-text Scheduler-Trigger-Section w-100 m-0">
                        <Buttons props={{ buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: toggleValue, buttonClassName: "", buttonEvent: (value) => { onToggleChange(value);setrefreshUserList(value) }, ImgSrc: () => <></>, isDisable: !isExecutePermission, buttonType: Literals.BTN_TOGGLE }} />
                    </div>
                    {/* <div className='row pl-3'>
                        <Radio.Group disabled={!isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view')} options={toggleBtnOption} onChange={(e) => settoggleValue(e.target.value)} value={schedulerPayload['task_type']} />
                    </div> */}
                </div>


                <div className={'col-7 pl-1 m-0'} >

                    <label style={{ textTransform: "capitalize" }} className='mt-0 label'>Selected {toggleValue?.toLowerCase()}</label>


                    <div className="d-flex align-items-center p-0 m-0"  >

                        <div className='select-model-input col-8 m-0 '>{selectedTypeName ? <div className='d-flex align-items-center w-100 justify-content-between'>
                            <span>{selectedTypeName}</span>
                            <span className='cursor-pointer' onClick={(e) => {
                                if (selectedTypeName?.length && toggleValue?.toLowerCase() === 'flow') { window.open(`${Literals.links.FLOWS}/${flowsAlertTransformerList?.selectedFlowDetails?.id}`, '_blank') } else {
                                    window.open(`${Literals.links.DASHBOARD}/${selectedScheduledDashboard?.selectedDashboardDetails?.id}`, '_blank')
                                }
                            }}><REDIRECTJOBS /></span></div>
                            : <div onClick={(e) => {

                                !selectedTypeName?.length && toggleValue?.toLowerCase() === 'flow' ? setopenFlowSelectionModal(true) : setopenDashboardSelectionModal(true)

                            }} className='cursor-pointer'>
                                <span>{toggleValue?.toLowerCase() === 'flow' ? 'Click here to add flow' : 'Click here to add dashboard'} </span>
                            </div>
                        }
                        </div>
                        <div className={`col-4 w-100 m-0 p-0`}>
                            <Buttons
                                props={{
                                    buttonText: toggleValue?.toLowerCase() === 'flow' ? 'Select Flow' : "Select Dashboard",
                                    buttonClassName: "custom-btn-primary select-model-btn custom-btn btn-with-text w-100",
                                    buttonEvent: (e) => {
                                        toggleValue?.toLowerCase() === 'flow' ? setopenFlowSelectionModal(true) : setopenDashboardSelectionModal(true)

                                        e.stopPropagation();

                                    },
                                    ImgSrc: () => <PLUS />,
                                    isDisable: !isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view'),
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        </div>
                    </div>

                </div>


            </div>
            <FlowSelectionModal isModalOpen={openFlowSelectionModal} onClose={() => setopenFlowSelectionModal(false)} fromRoute='scheduler' />
            <DashboardSelectionModal isModalOpen={openDashboardSelectionModal} onClose={() => setopenDashboardSelectionModal(false)} fromRoute='scheduler' />

        </>



    )
}

export default ScheduleModelSelection