import { Input, Tooltip } from 'antd'
import React from 'react'
import SingleSelection from '../../../../Common/dropDown/SingleSelection'
import Buttons from '../../../../Common/button/Buttons'
import { ICON_INFO_FILLED, ICON_LOADING_CIRCLE, ICON_SAVE } from '../../../../Common/newIconSource'
import { Literals } from '../../../common/literals'
import { useDispatch, useSelector } from 'react-redux'
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'
import { getSimpleTutorialMessageTemplate, permissions, podNameRegex } from '../../../common/helperFunctions'
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import { createBussinessTerm, registerTechnicalWithBusinessTerm } from '../../../../../store/modules/dataDictionary/action'
import { pushCreatePOD, refreshRowCount, saveParq, updateCreatePOD } from '../../../../../store/actions'
import { useNavigate } from 'react-router-dom'
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader'
import _ from 'lodash'
import Walkthrough from '../../../../Common/walkthrough/Walkthrough'
import { setNavigatorTutorialStart } from '../../../../../store/modules/common/tutorialRedux'

const PodCreationSaveDetails = ({
    newBusinessTerms = [],
    isEditMode = false,
    switchPageContainerTabTo = () => {},
    fetchDictionaryTableData = () => {},
    isEditing = false,
    isFileUpload = false,
    fileUploadUUID = '',
    isPodOfPod = false,
    setPodSaveRunning = () => {},
    tutorialStepsIdMap = {},
    podCreationTutorialComponentIds = {},
    isTutorialActive = false,
    isCustomQuery = false,
    currentActiveTabKey = '',
    defaultDomain = null,
    isUnstructured = false
}) => {
    const history = useNavigate()
    const [errMsg, setErrMsg] = React.useState(undefined)
    const [loading, setLoading] = React.useState(false)

    const dispatch = useDispatch()

    const walkthroughSteps = [
        {
            content: <>
                <h4 className='fontSizeHeading font-w-600 color-primary'>Final Step</h4>
                <p className='text-dark mb-0 paragraph'>
                    Almost there at the end
                </p>
            </>,
            locale: { skip: <strong>SKIP</strong> },
            placement: "center",
            target: "body"
        },
        {
            content: <>
                {getSimpleTutorialMessageTemplate('Pod Name', 
                'Enter name of your pod, only lowercase are allowed with underscore and with no starting number also ensure that it should not contain any special characters and spaces')}
            </>,
            placement: "bottom",
            target: tutorialStepsIdMap.step8,
            title: "Step 1"
        },
        {
            content: <>
                {getSimpleTutorialMessageTemplate('Selecting Domain', 
                "Here you can see the list of the domain's which are included in the selected connection, select your desire domain")}
            </>,
            placement: "bottom",
            target: tutorialStepsIdMap.step9,
            title: "Step 2"
        },
        {
            content: <>
                {getSimpleTutorialMessageTemplate('Pod Description', 
                "Describe something about your Pod")}
            </>,
            placement: "bottom",
            target: tutorialStepsIdMap.step10,
            title: "Step 3"
        },
        {
            content: <>
                {getSimpleTutorialMessageTemplate('Save', 
                "Click on this button to save your Pod")}
            </>,
            placement: "bottom",
            target: tutorialStepsIdMap.step11,
            title: "All Done",
            locale: { last: "Yes, I Know" }
        }
    ]

    const [{run, steps}, setWalkThrough] = React.useState({
        run: !isEditMode,
        steps: [...walkthroughSteps]
    })

    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)
    const selectedConn = useSelector((state) => state.UserManagement.ConnectionReducer.selectedConn)
    const podData = useSelector(state => state.DataCatalogue.Dictionary.dictionaryPodData)
    const reduxPodDetails = reduxData?.podDetails
    const schemaTableDataPayload = reduxData?.schemaTableDataPayload??[]
    const locationPath = reduxData?.folderPath
    const { delimeter, format, headerCount, escapeChar, quoteChar, skipFooter, skipRows, encoding, nullFormat } = reduxData?.fileOptions
    const { saveParqData, fileUploadConnection, schemaQueryMap, parameters, tableData, clarista_engine } = reduxData

    const { datadomainList } = useSelector(
        (state) => state.UserManagement.DataDomain
    );

    React.useEffect(() => {
        if(isEditMode) {
            if((isFileUpload || isPodOfPod) && datadomainList?.find(d => d?.data_domain_id?.toString() === podData?.data_domain_detail?.data_domain_id?.toString())) {
                reduxData.podDetails['domain'] = podData?.data_domain_detail?.data_domain_name
                dispatch(setPodCreationData(reduxData))
            }
            else if(
                !selectedConn?.data_domain?.map(d => d?.data_domain_name)?.includes(reduxData?.podDetails?.domain)
            ) {
                reduxData.podDetails['domain'] = selectedConn?.data_domain?.[0]?.data_domain_name ?? ""
                dispatch(setPodCreationData(reduxData))
            }
            else {
                if(reduxData.podDetails['domain'] === "") {
                    reduxData.podDetails['domain'] = podData?.data_domain_detail?.data_domain_name
                    dispatch(setPodCreationData(reduxData))
                }
            }
        }
        else if(reduxData.podDetails['domain'] === "") {
            if(!isFileUpload && !isPodOfPod) {
                if(getEditorsDomainList()?.find(d => d?.data_domain_id?.toString() === defaultDomain?.id?.toString())) {
                    reduxData.podDetails['domain'] = defaultDomain?.name ?? ""
                }
                else {
                    reduxData.podDetails['domain'] = getEditorsDomainList()?.[0]?.data_domain_name ?? ""
                }
                dispatch(setPodCreationData(reduxData))
            }
            else {
                if(datadomainList?.find(d => d?.data_domain_id?.toString() === defaultDomain?.id?.toString())) {
                    reduxData.podDetails['domain'] = defaultDomain?.name ?? ""
                }
                else {
                    reduxData.podDetails['domain'] = datadomainList?.[0]?.data_domain_name ?? ""
                }
                dispatch(setPodCreationData(reduxData))
            }
        }
    }, [selectedConn, podData, isEditMode, datadomainList, defaultDomain])

    React.useEffect(() => {
        startWalkthrough(isTutorialActive)
    }, [isTutorialActive])

    const startWalkthrough = (bool) => {
        setWalkThrough({steps: walkthroughSteps, run: bool })
    }
    
    const walkthroughCallback = (d) => {
        if(d?.status === 'ready') {
            if(!isTutorialActive) return
            dispatch(setNavigatorTutorialStart('create_pod', false))
            dispatch(setNavigatorTutorialStart('file_system', false))
            dispatch(setNavigatorTutorialStart('file_upload', false))
            dispatch(setNavigatorTutorialStart('pod_of_pod', false))
        }
    }
    const getEditorsDomainList = () => {
        const editorDomainObj = datadomainList?.reduce((acc, curr) => {
            return ({...acc, 
                [curr?.data_domain_id]: !!curr?.permission?.find(d => d === permissions.domain.editor) 
            })
        }, {})

        if(datadomainList.length === 0) {
            return selectedConn?.data_domain
        }
        return _.sortBy(selectedConn?.data_domain?.filter(v => editorDomainObj[v?.data_domain_id]), 'data_domain_name')
    }

    const domainList = isFileUpload || isPodOfPod ? datadomainList : getEditorsDomainList()
    
    const domainListSelect = domainList?.map(v => ({
        Name: v?.data_domain_name?.toUpperCase(),
        value: v?.data_domain_name,
        id: v?.data_domain_id
    }))

    const onInputChangeHandler = ({key, value}) => {
        reduxData.podDetails[key] = value
        dispatch(setPodCreationData(reduxData))
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if(reduxPodDetails?.domain === null || reduxPodDetails?.domain === undefined || reduxPodDetails?.domain === '')
        {
            emitToastNotification('info', "Please Select Data Domain")
            return
        }
        setErrMsg(undefined)
        validatePod()
    }

    const validatePod = () => {
        setLoading(true)
        setPodSaveRunning(true)

        let payload = [...newBusinessTerms]
        if (payload.length > 0) {
            dispatch(createBussinessTerm(payload, false, false))
            .then(res => {
                if (res.status === 'success') {
                    let newBizTerms = [...res.data]
                
                    let newPayload = schemaTableDataPayload.map(item => (
                        (item?.data_dict === '' || item?.data_dict === null || item?.data_dict === undefined)
                        ? {
                            ...item,
                            data_dict: newBizTerms.find(term => {
                            let v1 = term.business_term.toLocaleLowerCase()
                            let v2 = item.column_name.toLocaleLowerCase()
                            return v1 === v2
                            }).id
                        }
                        : { ...item }
                    ))
                    //Creating POD
                    savePod(newPayload)
                }
            })
            .catch(err => {
                setErrMsg(err?.message)
                emitToastNotification('error', Literals.BUSSINESS_TERM_SAVE_FAILED)
                setLoading(false)
                setPodSaveRunning(false)
            })
        }
        else savePod(schemaTableDataPayload)
    }

    const savePod = (schemaPayload) => {
        let clone = [...schemaPayload]
        let techBizPayload = clone.map(item => (
            { 
                tech_term: item.column_source_name, 
                data_dict: item.data_dict,
                connection: selectedConn?.connection_name
            }
            ))
        dispatch(registerTechnicalWithBusinessTerm(techBizPayload, false, false))
        .then(res => {
            if(res?.status === 'success') {
                let columnPayload = [...schemaPayload]
                let requestBody;
                requestBody= {
                    table_name: reduxPodDetails?.name,
                    data_domain: domainListSelect?.find(v => v?.value === reduxPodDetails?.domain)?.id, 
                    pod_description: reduxPodDetails?.description,
                    column: columnPayload,
                    source_type: isFileUpload ? 'upload' : isPodOfPod ? Literals.POD_OF_POD_API : selectedConn?.source_type,
                }
                if (isFileUpload || selectedConn?.file_system) {
                    /**
                     * No Longer Required
                     */
                    /* if (format?.toLocaleLowerCase() === 'csv' || format?.toLocaleLowerCase() === 'txt') {
                        columnPayload = columnPayload.map(item => {
                        item['column_source_datatype'] = 'varchar'
                        return item
                      })
                    } */
                    
                    requestBody = {
                        ...requestBody,
                        source: [
                            isFileUpload
                            ?   `upload@${fileUploadUUID}`
                            :   `${selectedConn?.connection_name}@${locationPath}`
                        ],
                        original_query: "",
                        pod_file_info: isUnstructured
                                        ?
                                        {
                                            location: isFileUpload ? fileUploadUUID : locationPath,
                                            format: "unstructured",
                                            file_names: isFileUpload 
                                                ? reduxData?.fileUploadFiles?.map(f => fileUploadUUID + "/" + f?.path)
                                                : reduxData?.selectedFolder
                                        }
                                        :   
                                        format.toLocaleLowerCase() === 'parquet'
                                        ?   {
                                                location: isFileUpload ? fileUploadUUID : `${locationPath}`,
                                                format: format,
                                                encoding
                                            }
                                        :   {
                                                location: isFileUpload ? fileUploadUUID : `${locationPath}`,
                                                delimeter: delimeter,
                                                header_count: parseInt(headerCount) ? parseInt(headerCount) : 1,
                                                format: format,
                                                escapechar: escapeChar && escapeChar !== '' ? escapeChar : undefined,
                                                quotechar: quoteChar && quoteChar !== '' ? quoteChar : undefined,
                                                skipfooter: parseInt(skipFooter) ? parseInt(skipFooter) : undefined,
                                                skiprows: parseInt(skipRows) ? parseInt(skipRows) : undefined,
                                                encoding
                                            },
                        pod_custom_query_flag: false
                    };

                    if(isUnstructured) {
                        requestBody['topic_info'] = reduxData?.tableData?.result?.topic_info
                    }

                    if(isEditMode) {
                        requestBody['table_id'] = podData?.table_id
                    }

                    if(format.toLowerCase() === 'txt') {
                        requestBody['pod_file_info'] = {...requestBody.pod_file_info, null_format: nullFormat ?? ''}
                    }

                    if(format.toLowerCase() === 'xlsx') {
                        requestBody['sheet_info'] = saveParqData ? [...saveParqData] : undefined

                        let payload = {
                            connection_name: fileUploadConnection,
                            prefix: fileUploadUUID,
                            file_properties: {
                                delimeter: delimeter,
                                header_count: parseInt(headerCount) ? parseInt(headerCount) : 1,
                                format: format,
                                escapechar: escapeChar && escapeChar !== '' ? escapeChar : undefined,
                                quotechar: quoteChar && quoteChar !== '' ? quoteChar : undefined,
                                skipfooter: parseInt(skipFooter) ? parseInt(skipFooter) : undefined,
                                skiprows: parseInt(skipRows) ? parseInt(skipRows) : undefined
                            },
                            sheet_info : saveParqData ? [...saveParqData] : undefined
                        }
                        dispatch(saveParq(payload, false))
                        .then(res => {
                            if(res) {
                                if(isEditMode) {
                                    updatePod(requestBody)
                                }
                                else {
                                    savePodApi(requestBody)
                                }
                            }
                        })
                        .catch(err => {
                            setLoading(false)
                            setPodSaveRunning(false)
                            emitToastNotification('error', err.message)
                        })
                        return
                    }

                    if(isEditMode) {
                        updatePod(requestBody)
                        return
                    }

                    savePodApi(requestBody)
                }
                else {
                    requestBody = {
                        ...requestBody,
                        source: getSourceArray(),
                        original_query: isCustomQuery ? schemaQueryMap?.result : null,
                        default_param: getParams(),
                        clarista_engine: clarista_engine ?? true,
                        pod_file_info: {},
                        pod_custom_query_flag: isCustomQuery
                    }
                    if(isEditMode) {
                        requestBody['table_id'] = podData?.table_id
                        updatePod(requestBody)
                        return
                    }

                    savePodApi(requestBody)
                }
            }
            else {
                emitToastNotification('error', Literals.SOMETHING_WENT_WRONG)
                setLoading(false)
                setPodSaveRunning(false)
            }
        })
        .catch(err => {
            setErrMsg(err?.message)
            emitToastNotification('error', Literals.SOMETHING_WENT_WRONG)
            setLoading(false)
            setPodSaveRunning(false)
        })
    }

    const updatePod = (requestBody) => {
        dispatch(updateCreatePOD(requestBody, requestBody?.table_id, {showLoader: false, showToast: false}))
        .then((res) => {
            if(res.status === 'success') {

                // fetchRowCount(res?.data?.table_id)  // to activate row count
                
                setPodSaveRunning(false)
                fetchDictionaryTableData()
                switchPageContainerTabTo('Dictionary')
            }
        })
        .catch(err => {
            setErrMsg(err?.message)
            setLoading(false)
            setPodSaveRunning(false)
        })
    }

    const savePodApi = (requestBody) => {
        dispatch(pushCreatePOD(requestBody,'' , false))
        .then(res => {
            if (res.status === 'success') {

                // fetchRowCount(res?.data?.table_id)  // to activate row count

                setLoading(false)
                setPodSaveRunning(false)
                emitToastNotification('success', 'Pod created successfully')
                history(Literals.links.CLARISTA_NAVIGATOR + '#pod');
            }
        })
        .catch(err => {
            setErrMsg(err?.message)
            setLoading(false)
            setPodSaveRunning(false)
        })
    }

    // const fetchRowCount = (table_id) => {
    //     if(!table_id) return

    //     let payload = {
    //         sample: 1000,
    //         force: true,
    //         table_id
    //     }
    //     dispatch(refreshRowCount(payload, table_id))
    //     .then(() => {})
    //     .catch((err) => {
    //         console.error(err)
    //     })
    // }

    const getSourceArray = () => {
        if(tableData) {
            let arr = Object.keys(tableData).filter(v => v !== 'result')
            let schList = reduxData?.schemaListData
            if(isPodOfPod || !selectedConn?.file_system) {
                let newArr = arr?.filter(t => {
                    let [schema, table] = t?.split('.')
                    let obj = schList?.find(s => s?.name === schema)
                    if(
                        obj 
                        && obj?.children?.find(t => t?.name === table)?.isChecked
                    ) {
                        return true
                    }
                    return false
                })
                
                arr = [...newArr]
            }
            if(!isCustomQuery) {
                arr = [currentActiveTabKey]
            }
            if(!isPodOfPod) {
                arr = arr.map(v => `${selectedConn?.connection_name}.${v}`)
            }
            
            return arr
        }
        return []
    }

    const getParams = () => {
        if(parameters?.length === 0) return undefined
        return _.uniqBy(parameters, 'name')?.reduce((acc, curr) => ({...acc, [curr?.name]: curr?.value}), {})
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='mx-auto py-3 position-relative' style={{width: '50%', maxWidth: 600}}>
                {
                    loading 
                    ?   <div className='position-absolute d-block h-100 w-100' 
                            style={{top: 0, left: 0, zIndex: 1, background: '#ffffff6e'}}>
                            <ClaristaLoader/>
                        </div>
                    :   ''
                }
                <div className='my-3'>
                    {
                        isEditMode && !isEditing ? ''
                        :   <p className='fontSizeHeading text-center'>
                                Please fill in the details below in order to save your information.
                            </p>
                    }
                </div>
                <div className='py-2'>
                    <div className='row pod-crt-save-details'>
                        <div className='col-6 mb-2'>
                            <label className="align-items-center d-flex fontSizeHeading label label-color">Pod Name 
                                <Tooltip placement={'right'} title={'Only lowercase letters, numbers and underscore are allowed. Pod names should only start with a letter'}>
                                    <span className='ml-1 d-flex'><ICON_INFO_FILLED width='11' height='11' /></span>
                                </Tooltip>
                            </label> 
                            <Input
                                id={podCreationTutorialComponentIds?.podNameId}
                                title={reduxPodDetails?.name ?? ''}
                                placeholder='Pod Name'
                                className='custom-input-field'
                                value={reduxPodDetails?.name}
                                onChange={(e) => {
                                    if (podNameRegex.test(e.target.value) || e.target.value === "") {
                                        onInputChangeHandler({key: 'name', value: e?.target?.value?.toLocaleLowerCase()})
                                    }
                                }}
                                required
                                disabled={isEditMode ? isEditing === false ? true : loading : loading}
                            />
                        </div>
                        <div className='col-6 mb-2'>
                            <label className="d-flex label label-color">Data Domain</label> 
                            <SingleSelection
                                props={{
                                    id: podCreationTutorialComponentIds?.podDomainId,
                                    placeholder: "Select Domain",
                                    dropDownValue: reduxPodDetails?.domain,
                                    onClickEvent: (value) => {
                                        onInputChangeHandler({key: 'domain', value})
                                    },
                                    optionList: domainListSelect,
                                    isDisable: isEditMode ? isEditing === false ? true : loading : loading
                                }}
                            />
                        </div>
                        <div className='col-12'>
                            <label className="label label-color">Description</label>
                            <Input.TextArea
                                id={podCreationTutorialComponentIds?.podDescriptionId}
                                title={reduxPodDetails?.description ?? ''}
                                placeholder='Some Description'
                                className='custom-input-field'
                                rows={5}
                                style={{minHeight: 100, lineHeight: '1.4'}}
                                defaultValue={reduxPodDetails?.description}
                                onBlur={(e) => {
                                    onInputChangeHandler({key: 'description', value: e?.target?.value})
                                }}
                              
                                disabled={isEditMode ? isEditing === false ? true : loading : loading}
                            />
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center pt-3'>
                    {
                        isEditMode && !isEditing ? ''
                        :
                        <Buttons
                            props={{
                                buttonId: podCreationTutorialComponentIds?.podSaveButtonId,
                                actionType: 'submit',
                                buttonText: loading ? "Saving..." : "Save",
                                buttonClassName: "custom-btn-primary custom-btn btn-with-text pod-crt-save-btn",
                                buttonEvent: () => {},
                                ImgSrc: () => loading ? <ICON_LOADING_CIRCLE/> : <ICON_SAVE/>,
                                isDisable: (isEditMode && !isEditing) || loading,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    }
                </div>
                {
                    errMsg 
                    ?   <code style={{maxWidth: 500}} className='d-block mt-3 alert alert-danger text-center mx-auto w-50 fontSizeLabel p-1'>
                            {errMsg}
                        </code>
                    :   ''
                }
            </div>
            <Walkthrough
                walkthrough={{run, steps}}
                spotlightClicks={true}
                showSkipButton={true}
                disableOverlayClose={true}
                callback={walkthroughCallback}
            />
        </form>
    )
}

export default PodCreationSaveDetails