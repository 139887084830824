import { Tooltip } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from "reactstrap";
import { readPolicy } from '../../../../../store/actions';
import { ACCESSMANAGMENT, CONNECTIONS } from "../../../../Common/iconSource";
import { LandingSimpleTable, LandingSimpleTableInner } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import SearchInput from '../../../../Common/search/Search';

import { Literals } from '../../../common/literals';
import NoSearchResultFound from '../../../common/NoSearchResultFound';
import { getRelativeTime } from '../../../common/helperFunctions';

const AccessPolicyList = ({ id }) => {
  const dispatch = useDispatch();
  const [AccessPolicyList, setAccessPolicyList] = useState([]);

  const [searchData, setSearchData] = useState("");
  const [layout, setlayout] = useState('list')
  const [listData, setlistData] = useState([])
  const data = [];
  const [, setOpenModal] = useState(false)

  useEffect(() => {
    getAccessPolicyList();
  }, [])

  const getAccessPolicyList = async () => {
    try {
      dispatch(readPolicy(`policy/?group_id=${id}`)).then(
        res => {
          if (res.status === "success" && res?.data?.length > 0) {
            setAccessPolicyList(res.data);
          }
        }
      )
    }
    catch (e) {

    }
  }





  const filterData = AccessPolicyList && AccessPolicyList.filter((data) => {
    return (
      data.name?.toLowerCase().includes(searchData?.toLowerCase()) ||
      data.updated_on?.toLowerCase().includes(searchData?.toLowerCase()) ||
      data.pod_detail?.table_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
      data.description?.toLowerCase().includes(searchData?.toLowerCase()),
      data.created_by_user?.email?.toLowerCase().includes(searchData?.toLowerCase())
    )
  })

  const getFilterData = () => {
    return AccessPolicyList && AccessPolicyList.filter((data) => {

      return (
        data.name?.toLowerCase().includes(searchData?.toLowerCase()) ||
        data.created_by_user.email?.toLowerCase().includes(searchData?.toLowerCase()) ||
        data.pod_detail?.table_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
        data.description?.toLowerCase().includes(searchData?.toLowerCase()) ||
        data.updated_on?.toLowerCase().includes(searchData?.toLowerCase())
      )
    })
  }
  if (filterData !== undefined) {

    for (let i = 0; i < filterData.length; i++) {

      data.push({
        key: filterData[i],
        name: (
          <Tooltip placement="topLeft" title={filterData[i].name}>
            {filterData[i].name}
          </Tooltip>
        ),
        description: (
          <Tooltip placement="topLeft" title={filterData[i].description}>
            {filterData[i].description}
          </Tooltip>
        ),
        database_name: (
          <Tooltip placement="topLeft" title={filterData[i].pod_detail?.table_name}>
            {filterData[i].pod_detail?.table_name}
          </Tooltip>
        ),

        updated_on: (
          <Tooltip placement="topLeft" title={getRelativeTime(filterData[i].updated_on)}>
            {getRelativeTime(filterData[i].updated_on)}
          </Tooltip>
        ),
        created_by_user: (
          <Tooltip placement="topLeft" title={filterData[i].created_by_user.email}>
            {filterData[i].created_by_user.email}
          </Tooltip>
        ),
        data: filterData,
      });
    }
  }
  const history = useNavigate();

  const handleSelection = (record) => {

    history(`${Literals.links.ACCESS_MANAGEMENT_VIEW_POLICY}${record?.key?.pod_detail?.table_id}?policyId=${record?.key?.id}`, { state: { tabName: Literals.ACCESS, activeTab: Literals.ACCESS } });

    // history.push({ pathname: "/dataCatalogue/podDetails/" + record?.key?.pod_detail?.table_id, state: { tabName: Literals.ACCESS, activeTab: Literals.ACCESS } });

  };


  function getCardView() {
    if (data && data.length > 0) {
      return (
        data.map((dataObj, i) =>
          <div key={i} className="data-connectiongridview-responsive cursor-pointer" onClick={() => {
            setOpenModal(true)
            handleSelection(dataObj);
          }}>
            <Card >
              <CardBody>
                <div className="connectionsCard-circle">
                  <div className="icon-box"><ACCESSMANAGMENT /></div>
                </div>
                <div className="connectionsCard-description">
                  <p className="paragraph m-0 custom-ellipsis two-line-ellipsis connection-heading">
                    {dataObj.name}
                  </p>
                  <p className="paragraph grey-text m-0 custom-ellipsis two-line-ellipsis">
                    {dataObj.description}
                  </p>
                  <p className="paragraph grey-text m-0 custom-ellipsis two-line-ellipsis">
                    {dataObj.created_by_user}
                  </p>
                </div>
              </CardBody>
            </Card>
          </div>
        )
      )
    }
  }


  useEffect(() => {

    if (AccessPolicyList?.length && getFilterData()?.length) {
      let records = [...getFilterData()]
      let temp = []
      for (let i = 0; i < records.length; i++) {
        temp.push(
          {
            key: records[i],
            name: <div className='d-flex w-100 h-100 align-items-center'><ACCESSMANAGMENT height='16' width='16' color='#2c83c4' /><span className='ml-1 text-uppercase text-with-ellipsis' style={{width: 'calc(100% - 100px)'}}> <Tooltip placement="topLeft" title={records[i].name}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].name ? records[i].name : ''} /></Tooltip></span></div>,
            description: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].description}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].description ? records[i].description : ''} /></Tooltip></span>,
            podName:<span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i]?.pod_detail?.table_name}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i]?.pod_detail?.table_name ? records[i]?.pod_detail?.table_name : ''} /></Tooltip></span>,
            data: records[i],
            updated_on: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={getRelativeTime(records[i].updated_on)}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={getRelativeTime(records[i].updated_on) ? getRelativeTime(records[i].updated_on) : ''} /></Tooltip></span>,
            created_by_user: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].created_by_user.email}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].created_by_user.email ? records[i].created_by_user.email : ''} /></Tooltip></span>,
            entitlement: ''
          }
        )
      }


      setlistData([...temp])
    } else {
      setlistData([])
    }
  }, [AccessPolicyList, searchData])

  const columns = [
    {
      name: Literals.POLICY_COL_NAME,
      key: "name",
      resizable: false
    },



    {
      name: 'Pod Name',
      key: "podName",
      width: 150,
      resizable: false,
      sortable: false,


    },



    {
      name: Literals.DESCRIPTION,
      key: "description",
      width: 200,
      resizable: false,
      sortable: false,


    },

    {
      name: Literals.UPDATED_ON,
      key: "updated_on",
      resizable: false,


    },
    {
      name: Literals.CREATED_BY,
      key: "created_by_user",
      resizable: false,




    },
    {

      name: '',
      dataIndex: "entitlement",
      key: "entitlement",
      sortable: false,
      resizable: false,


    }
  ];


  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case 'name':
      case 'database_name':
      case 'updated_on':

        return (a, b) => a.data[sortColumn] !== undefined ? a.data[sortColumn].localeCompare(b.data[sortColumn]) : null
      case 'created_by_user':
        return (a, b) => a.data[sortColumn]?.email !== undefined ? a.data[sortColumn]?.email.localeCompare(b.data[sortColumn]?.email) : null
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }

  const setView = (view) => {
    setlayout(view);
  }


  return (
    <Fragment>
      <div className="dataDomain-lower-part policy-table">
        <div className="lower-subheader">
          <div className="sub-header-left">
            <div className="heading-content">
              <span className="domain-icon"><ACCESSMANAGMENT height='18' width='18' /></span>
              <p>List of Access Policies</p>
            </div>
          </div>

          <div className="sub-header-right d-flex">
            <SearchInput searchData={searchData} setSearchData={setSearchData} />

          </div>

        </div>
        {!listData.length && searchData !== "" ? <div className='mt-20'><NoSearchResultFound showSubMsg={false} width={'100'} height={'100'} /></div> : layout === 'grid' ? <div className="data-domain-gridview"> {getCardView()}</div> :
          <LandingSimpleTableInner
            cols={columns}
            rowsData={listData}
            autoWidth={true}
            getComparator={getComparator}
            onRowClick={(dataObj) => {
              setOpenModal(true);
              handleSelection(dataObj)
            }}
            tableHeight={(listData?.length * 41) + 41}
          />}
      </div>

    </Fragment>
  )

}

export default AccessPolicyList;