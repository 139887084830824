import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import { getPreviewTable, resetPreviewNextPageCounter } from '../../../../store/modules/dataCatalogue/preview/previewActions';
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader';
import { getObjectLength, getReloadErrorTemplate } from '../../common/helperFunctions';
import DataGridTable from '../../../Common/dataGridTable/DataGridTable';
import { Checkbox, Popover, Space, Tabs } from 'antd';
import { setCurrentTableRowCount, setTableUUID } from '../../../../store/modules/common/DataGridTable/dataGridTableRedux';
import { ICON_ARROW_RIGHT, ICON_CLOSE, ICON_PLUS } from '../../../Common/newIconSource';
import { Literals } from '../../common/literals';
import Buttons from '../../../Common/button/Buttons';
import { getCatalogListing } from '../../../../store/actions';

const ChatContentPreviewTable = ({
    data = {},
    isTracker = false,
    isFeedback = false,
    domainId,
    tables = [],
    tablesLoading = false,
    setTables = () => {},
    setTablesLoading = () => {},
    tablesEmpty = false,
    setTablesEmpty = () => {},
    parentPreviewError = undefined,
    hideErrorReloadBtn = false,
    chartRefMap = {}
}) => {
    
    const dispatch = useDispatch()
    const history = useNavigate()

    const [previewError, setPreviewError] = React.useState(parentPreviewError)
    const [previewLoading, setPreviewLoading] = React.useState(false)
    const [uniqUUID, setUniqUUID] = React.useState('')
    const [previewData, setPreviewData] = React.useState({})
    const [activeTab, setActiveTab] = React.useState('result')
    // const [tables, setTables] = React.useState([])
    // const [tablesLoading, setTablesLoading] = React.useState(false)
    const [tablesErr, setTablesErr] = React.useState(undefined)
    // const [tablesSearch, setTablesSearch] = React.useState('')
    // const [tablesEmpty, setTablesEmpty] = React.useState(false)
    const [tableTabs, setTableTabs] = React.useState([])
    const [showErrReloadBtn, setShowErrReloadBtn] = React.useState(true)
    const [queryKilled, setQueryKilled] = React.useState(false)

    const uuidRef = React.useRef('')

    // React.useEffect(() => {
    //     fetchPublishedPods()
    // }, [])

    React.useEffect(() => {
        if(data?.query && data?.query?.trim() !== '') {
            fetchPreview()
        }
        if(data?.pod_detail) {
            let podlist = data?.pod_detail?.map((item) => {
                return {
                    table_id: item?.table_id,
                    table_name: item?.table_name,
                }
            })
            setTableTabs([...podlist])
        }
    }, [data])

    const fetchPreview = (key = 'result') => {

        if(key === 'result' && data?.query === "") return

        dispatch(resetPreviewNextPageCounter());
        setShowErrReloadBtn(true)
        setPreviewLoading(true)
        setPreviewError(undefined)
        setQueryKilled(false)
        let uuid = uuidv4()
        setUniqUUID(uuid)
        uuidRef.current = uuid
        let request = {
            uuid: uuid,
            page: 0,
            do_count: false,
            sort_body: [],
            filter_body: [],
            query: data?.query
        }

        if(key !== 'result') {
            const podData = (() => {
                if(isFeedback) {
                    let da = tables?.find(t => t?.table_id?.toString() === key?.toString())
                    if(da !== undefined && da !== null)
                    return {
                        data: da
                    }
                    return {
                        data: {unpublished: true}
                    }
                }
                return items?.find(t => t?.key?.toString() === key?.toString())
            })()

            if(podData?.data?.unpublished === true) {
                setPreviewLoading(false)
                setPreviewError('The pod you are trying to use is no longer published, publish this pod or use some other pod')
                setShowErrReloadBtn(false)
                return
            }

            delete request.query
            request = {
                ...request,
                table_ids: [podData?.data?.table_id],
                table_name: podData?.data?.table_name
            }
        }
        dispatch(setCurrentTableRowCount(''))
        dispatch(getPreviewTable(request, false, true, false, false))
        .then((res) => {
            setPreviewData(res?.data?.result ?? {})
            setPreviewLoading(false)
        })
        .catch((err) => {
            setPreviewError(err?.message ?? 'Error!')
            setPreviewLoading(false)
        })
    }

    /* const fetchPublishedPods = () => {
        setTablesEmpty(false)
        setTablesErr(undefined)
        setTablesLoading(true)
        dispatch(getCatalogListing(domainId, 'minimal', true, false))
        .then(res => {
            let tbls = res?.data
            tbls = tbls?.map(t => ({...t, checked: data?.pod_detail?.map(p => p?.table_id)?.includes(t?.table_id)}))
            setTables(tbls ?? [])
            setTablesEmpty(res?.data?.length === 0)
            setTablesLoading(false)
        })
        .catch((err) => {
            setTablesEmpty(false)
            setTablesErr(err?.message)
            setTablesLoading(false)
        })
    } */

    const items = React.useMemo(() => {
        let list = [
            {
                key: 'result',
                label: <span className='text-uppercase'>Result</span>,
                disabled: previewLoading
            }
        ]
        let podlist = tableTabs?.map((item) => {
            return {
                key: item?.table_id,
                label: <span className='text-uppercase'>{item?.table_name}</span>,
                data: item,
                disabled: previewLoading
            }
        })

        if(podlist)
        list = [...list, ...podlist]

        return list
    }, [data, tableTabs, previewLoading])

    const tablesList = React.useMemo(() => {
        let demo = [...tables]
        demo = demo?.map(v => ({...v, checked: !!v?.checked}))
        return demo
    }, [tables])

    const onTabChange = (key) => {
        // dispatch(
        //     setCurrentTableRowCount('') // This is not Working commented for Future understanding
        // );
        if(parentPreviewError && parentPreviewError?.length > 0 && key === "result") {
            setPreviewError(parentPreviewError)
        }
        setActiveTab(key)
        fetchPreview(key)
    }

    return (
        <div>
            <Tabs
                className='genai-pod-tab'
                activeKey={activeTab}
                items={items}
                onChange={onTabChange} 
                tabBarExtraContent={
                    isFeedback
                    ?
                    <>
                        <Popover
                            trigger={'hover'}
                            placement='bottomRight'
                            arrow={false}
                            title={
                                <>
                                    <div className='d-flex fontInterSemiBold fontSizeHeading 
                                    align-items-center justify-content-between p-2'>
                                        Published Pod's
                                    </div>
                                </>
                            }
                            content={
                                <>
                                    {
                                        tablesLoading ? <ClaristaLoader height='145px' />   
                                        :
                                        tablesList?.length === 0 ?
                                        <div className='p-3 text-center text-black-50 fontSizeHeading'>
                                            No Tables Found
                                        </div>
                                        :
                                        <div className='p-2' style={{maxHeight: 145, overflowY: 'auto'}}>
                                        {
                                            tablesList?.map(({table_name, table_id, checked}, i) => (
                                                <div key={`${table_id}-${table_name}-${i}`} className='d-flex align-items-center m-1'>
                                                    <Checkbox
                                                        checked={checked}
                                                        onChange={(e) => {
                                                            let chk = e?.target?.checked
                                                            
                                                            setTables(prev => {
                                                                prev = prev?.map(v => ({...v, checked: v?.table_id === table_id ? chk : !!v?.checked}))
                                                                return prev
                                                            })
                                                            setTableTabs(prev => {
                                                                if(chk) {
                                                                    prev = [...prev, {table_id, table_name}]
                                                                }
                                                                else {
                                                                    prev = prev?.filter(f => f?.table_id !== table_id) 
                                                                }
                                                                return prev
                                                            })

                                                            if(!previewLoading) {
                                                                if(!chk && activeTab === table_id) {
                                                                    setActiveTab('result')
                                                                    setPreviewLoading(true)
                                                                    onTabChange('result')
                                                                }
                                                                else if(chk) {
                                                                    setActiveTab(table_id)
                                                                    setPreviewLoading(true)
                                                                    onTabChange(table_id)
                                                                }
                                                            }

                                                        }}
                                                        // disabled={activeTab === table_id}
                                                    >
                                                        <label className='mb-0 fontSizeHeading ml-1 text-uppercase'>
                                                            {table_name}
                                                        </label>
                                                    </Checkbox>
                                                </div>
                                            ))
                                        }
                                        </div>
                                    }
                                </>
                            }
                        >
                            <a className="ml-3 clst-anchor fontSizeHeading" onClick={(e) => e.preventDefault()}>
                                <Space>
                                    Add Table
                                    <span className='d-block bx-rotate-90'>
                                    <ICON_ARROW_RIGHT color='currentColor' height='12' width='12' />
                                    </span>
                                </Space>
                            </a>
                        </Popover>
                    </>
                    :   ''
                }
                />
            {
                previewLoading
                ?   <ClaristaLoader isCancel={true} setKillQueryDone={setQueryKilled} height='234px' />
                :   previewError 
                    ?   getReloadErrorTemplate({
                            errorMessage: previewError,
                            onReload: () => fetchPreview(activeTab),
                            hideReloadBtn: hideErrorReloadBtn || !showErrReloadBtn
                        })
                    :
                    <div className='chat-content-prev-tbl custom-virtual-data-table px-0 pb-3'>
                        {
                            activeTab === 'result'
                            ?
                            getObjectLength(previewData) === 0 ? ''
                            :
                            <DataGridTable
                                resp={previewData}
                                showDictionaryDetails={false}
                                stopColumnReorder={true}
                                uniqUUID={uniqUUID}
                                parentQuery={data?.query}
                                setUniqUUID={(uuid) => {
                                    setUniqUUID(uuid)
                                    uuidRef.current = uuid
                                }}
                                isShowHeader={!isTracker}
                                CurrentDomainName={() => data?.data_domain_name}
                                CurrentPODName={() => data?.pod_detail?.[0]?.table_name}
                                requestedTableId={data?.pod_detail?.[0]?.table_id}
                                isCustomSql={true}
                                // isSmallTable={!isFeedback}
                                isSmallTable={true}
                                smallRowHeight={!isFeedback}
                                manageMultiple={true}
                                isPreviewDataAlreadyLoading={true}
                                parentQueryKilled={queryKilled}
                            />
                            :
                            <DataGridTable
                                resp={previewData}
                                requestedTableId={items?.find(t => t?.key?.toString() === activeTab?.toString())?.data?.table_id}
                                CurrentPODName={()=> {return items?.find(t => t?.key?.toString() === activeTab?.toString())?.data?.table_name}}
                                CurrentDomainName={() => data?.data_domain_name}
                                uniqUUID={uniqUUID}
                                setUniqUUID={(uuid) => {
                                    setUniqUUID(uuid)
                                    uuidRef.current = uuid
                                }}
                                showDictionaryDetails={false}
                                isSmallTable={true}
                                // isSmallTable={isTracker || isFeedback}
                                isShowHeader={!isTracker}
                                smallRowHeight={isTracker || !isFeedback}
                                manageMultiple={true}
                                isPreviewDataAlreadyLoading={true}
                                parentQueryKilled={queryKilled}
                            />
                        }
                    </div>
            }
        </div>
    )
}

export default ChatContentPreviewTable