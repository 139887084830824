import React from 'react'
import { useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SetSchedulerPayload } from '../../../../store/modules/scheduler/action';
import { cloneDeep } from 'lodash';

function SchedulerBasicDetails({isEditor}) {
 

    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)

    const store = useStore()
    const setpayload = (value, inputType) => {
        const newPayload = cloneDeep(schedulerPayload);
        if (inputType === 'name') {

            newPayload['name'] = value
        } else if (inputType === 'description') {

            newPayload['description'] = value


        }
        store.dispatch(SetSchedulerPayload(newPayload))

    }

    
    return (
        <div className='row'>
            <div className="col-6 pr-0">
                <label htmlFor="schedulerName" className='mt-0 label'>Name</label>
                <input
                    required
                    id="schedulerName"
                    type="text"
                    value={schedulerPayload['name']}
                    onChange={(e) => setpayload(e.target.value, 'name')}
                    className={`custom-input-field`}
                    disabled={!isEditor || window?.location?.href?.toLowerCase().includes('scheduler/view')}
                />
            </div>
            <div className="col-6 pr-0">
                <label htmlFor="schedulerDesc" className='mt-0 label'>Description <span style={{ fontSize: "10px", fontStyle: "italic", }}>{"(optional)"}</span></label>
                <input
                    id="schedulerDesc"
                    value={schedulerPayload['description']}
                    type="text"
                    onChange={(e) => setpayload(e.target.value, 'description')}
                    disabled={!isEditor || window?.location?.href?.toLowerCase().includes('scheduler/view')}
                    className="custom-input-field"
                />
            </div>
        </div>
    )
}

export default SchedulerBasicDetails