import React, { useState, useEffect, Fragment } from 'react';
import {  getRelativeTime } from '../../../common/helperFunctions';
import { useDispatch, useSelector, useStore } from "react-redux";
import { getDataDomainConnectionList } from '../../../../../store/modules/UserManagement/dataDomains/dataDomainActions';
import NoSearchResultFound from '../../../common/NoSearchResultFound';
import { CONNECTIONS, LISTVIEW, TABVIEW } from "../../../../Common/iconSource";
import SearchInput from '../../../../Common/search/Search';
import { Card, CardBody } from "reactstrap";
import SourceTypeImage from '../../../common/sourceTypeImage';
import { Tooltip } from 'antd';
import CreatePODNewConn from '../../../connections/landingPage/components/createNewConn';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { getConnectionProperties, setConnectionDynamicFields, setConnectionOptionalDynamicFields, setConnectionSelectedConn } from '../../../../../store/modules/UserManagement/connection/connectionActions';
import Highlighter from 'react-highlight-words';
import { Literals } from '../../../common/literals';
import Buttons from '../../../../Common/button/Buttons';
import { LandingSimpleTable } from '../../../../Common/landingSimpleTable/LandingSimpleTable';

const ConnectionList = ({ id }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const [connectionList, setConnectionList] = useState([]);
  const loadingStatus = useSelector(state => {

    return state.LoadingReducer.loadingStatus
  });
  const [searchData, setSearchData] = useState("");
  const [layout, setlayout] = useState('list')
  const [openCreateConnection, setOpenCreateConnection] = useState(false)
  const [listData, setlistData] = useState([])
  const data = [];
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    getConnectionList();
  }, [])
  const closeCreateConnection = () => {

    setOpenCreateConnection(false);
  };
  const getConnectionList = async () => {
    try {
      let requestBody = {
        "action": "list_connection",
        'data_domain_id': id
      }
      dispatch(getDataDomainConnectionList(requestBody)).then(
        res => {
          if (res.status === "success") {
            setConnectionList(res.data);
          }
        }
      )
    }
    catch (e) {

    }
  }





  const filterData = connectionList && connectionList.filter((data) => {
    return (
      data.connection_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
      getRelativeTime(data.updated_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
      data.connection_description?.toLowerCase().includes(searchData?.toLowerCase())||
      data.created_by_user?.email?.toLowerCase().includes(searchData?.toLowerCase())
    )
  })

  const getFilterData = () => {

    return connectionList && connectionList.filter((data) => {
     
      return (
        data.connection_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
        getRelativeTime(data.updated_on)?.toLowerCase().includes(searchData?.toLowerCase()) ||
        data.connection_description?.toLowerCase().includes(searchData?.toLowerCase())||
        data.created_by_user?.email?.toLowerCase().includes(searchData?.toLowerCase())

      )
    })
  }
  if (filterData !== undefined) {

    for (let i = 0; i < filterData.length; i++) {

      data.push({
        key: filterData[i],
        connection_name: (
          <Tooltip placement="topLeft" title={filterData[i].connection_name}>
            {filterData[i].connection_name}
          </Tooltip>
        ),
        description: (
          <Tooltip placement="topLeft" title={filterData[i].connection_description}>
            {filterData[i].connection_description}
          </Tooltip>
        ),
        database_name: (
          <Tooltip placement="topLeft" title={filterData[i].data_domain.data_domain_name?.database}>
            {filterData[i].data_domain.data_domain_name?.database}
          </Tooltip>
        ),
        sources: <SourceTypeImage dataName={filterData[i].source_type} />,
        updated_on: (
          <Tooltip placement="topLeft" title={getRelativeTime(filterData[i].updated_on)}>
            {getRelativeTime(filterData[i].updated_on)}
          </Tooltip>
        ),
        createdBy: (
          <Tooltip placement="topLeft" title={filterData[i].created_by_user.email}>
            {filterData[i].created_by_user.email}
          </Tooltip>
        ),
        data: filterData,
      });
    }
  }
  const handleSelection = (record) => {

    if (openCreateConnection) {
      emitToastNotification('info', Literals.PLEASE_CLOSE_ALREADY_OPENED_CONNECTION_PANEL

      );
    } else {
      localStorage.setItem("selectedConn", JSON.stringify(record.key.source_type));
      try {
        let requestBody = {
          action: "show_connector_properties",
          connector_name: record.key.source_type,
        };
        dispatch(getConnectionProperties(record.key.source_type)).then((res) => {
          if (res?.data?.connection_properties !== undefined) {
            dispatch(
              setConnectionDynamicFields(res?.data?.connection_properties)
            );
            dispatch(
              setConnectionOptionalDynamicFields(res?.data?.optional_properties)
            );
            dispatch(setConnectionSelectedConn(record.key));
            closeCreateConnection();
            setOpenCreateConnection(true);
          } else {
            dispatch(setConnectionDynamicFields([]));
            dispatch(setConnectionOptionalDynamicFields([]));
          }
        });
      } catch (e) { }
    }
  };


  function getCardView() {
    if (data && data.length > 0) {
      return (
        data.map((dataObj, i) =>
          <div key={i} className="data-connectiongridview-responsive cursor-pointer" onClick={() => {
            setOpenModal(true)
            handleSelection(dataObj);
          }}>
            <Card >
              <CardBody>
                <div className="connectionsCard-circle">
                  <div className="icon-box">{dataObj.sources}</div>
                </div>
                <div className="connectionsCard-description">
                  <p className="m-0 custom-ellipsis two-line-ellipsis connection-heading landingCard-header text-dark">
                    {dataObj.connection_name}
                  </p>
                  <p className="paragraph grey-text m-0 custom-ellipsis two-line-ellipsis">
                    {dataObj.description}
                  </p>
                </div>
              </CardBody>
            </Card>
          </div>
        )
      )
    }
  }


  useEffect(() => {

    if (connectionList?.length && getFilterData()?.length) {
      let records = [...getFilterData()]
      let temp = []
      for (let i = 0; i < records.length; i++) {
        temp.push(
          {
            key: records[i],
            connection_name: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].connection_name?.toUpperCase()}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].connection_name ? records[i].connection_name?.toUpperCase() : ''} /></Tooltip></span>,
            description: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].connection_description}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].connection_description ? records[i].connection_description : ''} /></Tooltip></span>,

            sources: <div className='text-center'><SourceTypeImage dataName={records[i].source_type} align="center" /></div>,
            updated_on: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={getRelativeTime(records[i].updated_on)}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={getRelativeTime(records[i].updated_on) ? getRelativeTime(records[i].updated_on) : ''} /></Tooltip></span>,
            createdBy: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={records[i].created_by_user.email}><Highlighter searchWords={[searchData.toLocaleLowerCase()]} autoEscape={false} textToHighlight={records[i].created_by_user.email ? records[i].created_by_user.email : ''} /></Tooltip></span>,

          }
        )
      }
      setlistData([...temp])
    }
  }, [connectionList, searchData])

  const columns = [
    {
      name: Literals.CONNECTION_NAME,
      key: "connection_name",

    },
    {
      name: <div className='text-center'>{Literals.SOURCES}</div>,
      key: "sources",
      resizable: false,
      sortable: false,

    },
    {
      name: Literals.DESCRIPTION,
      key: "description",
      width: 350,
      resizable: true,
      sortable: false,


    },

    {
      name: Literals.UPDATED_ON,
      key: "updated_on",
      resizable: false,


    },
    {
      name: Literals.CREATED_BY,
      key: "createdBy",
      resizable: false,



    },

  ];


  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case 'connection_name':
      case 'database_name':
      case 'updated_on':
      case 'createdBy':
        return (a, b) => a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight !== undefined ? a[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight.localeCompare(b[sortColumn]?.props?.children?.props?.children?.props?.textToHighlight) : null
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }
  const toggleBtnOption = [
    { label: <TABVIEW />, value: 'grid' },
    { label: <LISTVIEW />, value: 'list' },
  ];
  const setView = (view) => {
    setlayout(view);
  }


  return (
    <Fragment>
      <div className="dataDomain-lower-part mt-20">
        <div className="lower-subheader">
          <div className="sub-header-left">
            <div className="heading-content">
              <span className="domain-icon"><CONNECTIONS height='20' width='20' /></span>
              <p>List Of Connections</p>
            </div>
          </div>

          <div className="sub-header-right d-flex">
            <SearchInput searchData={searchData} setSearchData={setSearchData} />
            {/* <div className='ml-2'>
              <Buttons props={{ buttonText: '', toggleBtnOption: toggleBtnOption, toggleBtnValue: layout, buttonClassName: "toggle-btn custom-btn btn-with-icon pb-1 ", buttonEvent: setView, ImgSrc: () => <></>, isDisable: false, buttonType: Literals.BTN_TOGGLE }} />
            </div> */}
            {/* <ul className="landingPage_navbar-nav-right">
              <li className="landingPage_nav-item mr-2">
              </li>
              <li className="landingPage_nav-item">
              </li>
            </ul> */}
          </div>

        </div>
        {filterData?.length === 0 ? <div className='mt-20'><NoSearchResultFound showSubMsg={false} width={'100'} height={'100'} /></div> : layout === 'grid' ? <div className="data-domain-gridview"> {getCardView()}</div> :
          <LandingSimpleTable
            cols={columns}
            rowsData={listData}
            autoWidth={true}
            getComparator={getComparator}
            onRowClick={(dataObj) => {
              setOpenModal(true);
              handleSelection(dataObj)
            }}
            tableHeight={(listData?.length * 41) + 41}
          />}
      </div>
      {openCreateConnection && (
        <CreatePODNewConn
          closeConnection={closeCreateConnection}
          type={"view"}
          fromRoute="DomainList"
          openDeleteDialog={() => { }}
          enableEdit={() => { }}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}
    </Fragment>
  )

}

export default ConnectionList;