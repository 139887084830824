import { Checkbox, List, Popover, Tabs, Tooltip } from 'antd'
import React from 'react'
import { Literals } from '../../modules/common/literals';
import Buttons from '../button/Buttons'
import { CHECK, CLOSE, FILTER, RESETFILTER } from '../iconSource';
import SearchInput from '../search/Search';
import _ from 'lodash';

const DictionaryTableFilter = ({
    filterList,
    setFilterList = () => {},
    showApplyButton = true,
    onApply = () => {},
    onReset = () => {},
    disabled = false
}) => {
    const [showFilterPop, setFilterPop] = React.useState(false);
    const [localFilterList, setLocalFilterList] = React.useState({category: [], classifiers: []});
    const [searchData, setSearchData] = React.useState('');

    React.useEffect(() => {
        setLocalFilterList({category: filterList.category, classifiers: filterList.classifiers})
    }, [filterList])

    const filterTabItems = React.useMemo(() => {
        const categories = showApplyButton ? [...localFilterList.category] : [...filterList.category]
        const classifiers = showApplyButton ? [...localFilterList.classifiers] : [...filterList.classifiers]
    
        const selectedCats = filterList?.category?.filter(v => v.selected)
        const selectedClass = filterList?.classifiers?.filter(v => v.selected)

        const searchTerm = searchData?.toLowerCase()
        return [
        //   { 
        //     label: <span className="align-items-center d-flex small"><span className='sphere category mr-2'></span>Category 
        //     {
        //       selectedCats.length
        //       ?
        //       <span className="ml-2 badge badge-pill bg-primary text-white">{selectedCats.length}</span>
        //       : ''
        //     }
        //     </span>, 
            
        //     key: 'item-1', 
        //     children: <div className="category-flt-li">
        //                 {
        //                     categories.length === 0
        //                     ?
        //                     <p className='mb-0 text-black-50 text-center p-3'>No Category</p>
        //                     :
        //                     <List
        //                         size="small"
        //                         dataSource={
        //                             _.sortBy(
        //                                 categories?.filter(v => v?.name?.toLowerCase()?.includes(searchTerm)),
        //                                 [(o) => o?.name?.toLowerCase() !== '(blank)']
        //                             )
        //                         }
        //                         renderItem={(item) => <List.Item><Checkbox className="mr-2" checked={item.selected} 
        //                             disabled={disabled}
        //                             onChange={(e) => handleFilterChange('category', item.name, e.target.checked)} /> {item.name}</List.Item>}
        //                         />
        //                 }
        //               </div> 
        //   },
          { label: <span className="align-items-center d-flex small"><span className='sphere classifier mr-2'></span>Classifier
          {
              selectedClass.length
              ?
              <span className="ml-2 badge badge-pill bg-primary text-white">{selectedClass.length}</span>
              : ''
            }
          </span>, 
          key: 'item-2', 
            children: <div className="classifier-flt-li">
                        {
                            classifiers.length === 0
                            ?
                            <p className='mb-0 text-black-50 text-center p-3'>No Classifier</p>
                            :
                            <List
                                size="small"
                                dataSource={
                                    _.sortBy(
                                        classifiers?.filter(v => v?.name?.toLowerCase()?.includes(searchTerm)),
                                        [(o) => o?.name?.toLowerCase() !== '(blank)']
                                    )
                                }
                                renderItem={(item) => <List.Item><Checkbox className="mr-2" checked={item.selected}
                                    disabled={disabled} 
                                    onChange={(e) => handleFilterChange('classifiers', item.name, e.target.checked)} /> {item.name}</List.Item>}
                            />
                        }
                      </div> 
          }
        ]
    }, [filterList, localFilterList, searchData, disabled]) 
    
    const handleFilterChange = (key, name, checked) => {
        const prevFunc = prev => (
            {
              ...prev,
              [key]: prev[key].map(v => ({...v, selected: name === v.name ? checked : v.selected }))
            }
        )
        
        if(showApplyButton) {
            setLocalFilterList(prevFunc)
            return
        }
        setFilterList(prevFunc)
    }

    const handleApply = () => {
        setFilterList({...localFilterList})

        onApply(localFilterList)
    }
    
    const filterPopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Filters</p>
                    <div className="d-flex">
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon mr-2",
                                buttonEvent: () => {
                                  setFilterList(prev => (
                                    {
                                      category: prev['category'].map(v => ({...v, selected: false })),
                                      classifiers: prev['classifiers'].map(v => ({...v, selected: false }))
                                    }
                                  ))
                                  setSearchData('')
                                  onReset()
                                },
                                ImgSrc: () => <RESETFILTER />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    setFilterPop(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div className='' style={{ width: '302px' }}>
                    <Tabs type="card" className="dict-filter-tabs" items={filterTabItems} />
                    {
                        showApplyButton
                        ?   <div className='p-2 d-flex align-items-center justify-content-between border-top' style={{gap: 10}}>
                                <div>
                                    <SearchInput searchData={searchData} setSearchData={setSearchData} />
                                </div>
                                <Buttons
                                    props={{
                                    buttonText: "Apply",
                                    buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                    buttonEvent: handleApply,
                                    ImgSrc: () => <CHECK />,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    isDisable: disabled,
                                    toggleBtnOption: null,
                                    }}
                                />
                            </div>
                        :   ''
                    }
                </div>
            </>
        }
    }
    const handleFilterVisibleChange = (visible) => {
        if(!disabled) {
            setFilterPop(visible)
        }
    }

    return (
        <>
            <Popover placement="bottomLeft"
                title={filterPopover.header}
                content={filterPopover.body}
                trigger="click"
                autoAdjustOverflow={true}
                open={showFilterPop && !disabled}
                onOpenChange={handleFilterVisibleChange}
            >
                <Tooltip placement='bottom' title='Filter'>
                    <button 
                        id='dictTableFilterBtn'
                        className={`
                        ${disabled ? 'disabled' : ''}
                        ${showFilterPop ? "custom-btn-primary" : "custom-btn-outline"
                        } dict-tbl-filter-btn custom-btn back-transparent btn-with-icon border-0 ml-2 mr-1 ${(filterList.category?.filter(v => v.selected)?.length > 0 || filterList.classifiers?.filter(v => v.selected)?.length > 0) ? 'rdg-filter-active' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            if(!disabled) {
                                setFilterPop(true)
                            }
                        }
                        }
                    >
                        <FILTER />
                    </button>
                </Tooltip>
            </Popover>
        </>
    )
}

export default DictionaryTableFilter