import React, { useEffect, useRef, useState } from "react";
import Buttons from "../../../../Common/button/Buttons";
import { Literals } from "../../../common/literals";
import {
  CLOSE,
  EDIT,
  EDITOR,
  MERGEKEYS,
  MINUS,
  PLAY,
  PLUS,
  PROPERTIES,

} from "../../../../Common/iconSource";

import { v4 as uuidv4 } from "uuid";
import { connect, useDispatch, useStore } from "react-redux";
import { fetchPreviewTable, setActivePodDetails } from "../../../../../store/modules/common/VirtualDataTable/action";
import {
  executeTransformerApi,
  saveTransformerNoteClick,
  setFilterTransformerFilterCriteria,
  setFlowsCurrenttransformer,
  showFlowsTransformdetails,
  updateFlowsElements,
} from "../../../../../store/modules/flows/flowsActions";
import { useParams } from "react-router";
import _, { cloneDeep } from "lodash";
import { Tabs, Tooltip } from "antd";

import EditorIndex from "../DataSource/editor/EditorIndex";
import { columnNameRegex, columnNameRegexExp, removeStartingUnderscores } from "../../../common/helperFunctions";
import DatasetOutputNameInput from "../commonFunctions/useDatasetNameInput";

import aggregate from "../../../../../assets/icons/aggregateIcon.svg";
import SingleSelection from "../../../../Common/dropDown/SingleSelection";
import { SCRIPTDEFAULTVIEW } from "../script/ScriptDefaultInfography";
import { LandingSimpleTable } from "../../../../Common/landingSimpleTable/LandingSimpleTable";
import { emitToastNotification } from "../../../../../helpers/toast_helper";
import { ICON_SAVE } from "../../../../Common/newIconSource";
import { getInputFrame, getInputTransformerDetails } from "../FlowsHelperFunction";

const { TabPane } = Tabs;
const dummyData = {
  old_name: "",
  aggregate_function: "",
  new_name: "",
  id: "",
  dataType: "",
  originalColumnName: "",
};

const groupKeysData = {
  key_name: "",
  id: "",
  dataType: "",
};

const AggregateTransformerIndex = ({
  flowsElements,
  transformerLoading,
  transformerNameList,
  currentTransformer,
  appliedFilterCriteria,
  isExecutePermission,
  ...props
}) => {


  const [showSampleRow, setshowSampleRow] = useState(false);
  const [disableExcute, setdisableExcute] = useState(false);
  const [outcomecolumnList, setoutcomecolumnList] = useState([]);
  const [groupbyColumnList, setgroupbyColumnList] = useState([]);

  const [activeKey, setActiveKey] = useState("properties");
  const [editMode, setEditMode] = useState(false);
  const [transformerName, settransformerName] = useState("");
  const [data, setData] = useState([dummyData]);
  const [groupKeys, setgroupKeys] = useState([]);
  const [demo, setDemo] = useState([]);

  const [showRedBtn, setshowRedBtn] = useState(false);

  const [showBtnLoading, setShowBtnLoading] = useState(false);

  const store = useStore();
  const { flowId } = useParams();

  const dispatch = useDispatch();


  const [toggleDefaultView, setToggleDefaultView] = useState(true);

  useEffect(() => {
    if (transformerName === '') {
      setshowRedBtn(true)
    }
  }, [transformerName])

  const isfieldEmpty = () => {
    let validateColumn = data.every((el) => {
      return el.originalColumnName.trim().length > 0;
    });
    let validateCriteria = data.every((el) => {
      return el.aggregate_function.trim().length > 0;
    });
    let validateValue = data.every((el) => {
      if (typeof el?.new_name === "string") {
        return el?.new_name?.trim()?.length > 0;
      } else if (typeof el?.new_name === "object") {
        return (
          el?.new_name?.[0]?.trim().length > 0 &&
          el?.new_name?.[1]?.trim().length > 0
        );
      }
    });
    if (!validateColumn || !validateCriteria || !validateValue) {
      emitToastNotification(
        "info",
        Literals.EMPTY_FIELD_FOUND_IN_AGGREGATE_TRANSFORMER
      );
      return true;
    } else {
      return false;
    }
  };

  const getAggregateFunc = (selectedColumnType) => {
    console.log(selectedColumnType)
    let extractDatatype = selectedColumnType?.split("(")[0];

    switch (extractDatatype?.toLowerCase()) {
      case "string":
      case "varchar":
      case "char":
      case "date":
      case "time":
      case "timestamp":
        return [
          { Name: "Max", value: "max" },
          { Name: "Min", value: "min" },
          { Name: "Arbitrary", value: "arbitrary" },
          { Name: "Count", value: "count" },
          { Name: "Count distinct", value: "count_distinct" },
        ];

        break;
      case "integer":
      case "number":
      case "smallint":
      case "int":
      case "float":
      case "double":
      case "decimal":
      case "tinyint":
      case "bigint":
      case "real":
        return [
          { Name: "Average", value: "avg" },
          { Name: "Count", value: "count" },
          { Name: "Max", value: "max" },
          { Name: "Min", value: "min" },
          { Name: "Sum", value: "sum" },
          { Name: "Stddev", value: "stddev" },
          { Name: "Arbitrary", value: "arbitrary" },
          { Name: "Variance", value: "variance" },
          { Name: "Count distinct", value: "count_distinct" },
        ];

      default:
        break;
    }
  };

  const getUUID = () => {
    let id = uuidv4();

    return id;
  };

  const getPreviewResult = (podDetails) => {
    if (podDetails) {
      let request = {
        uuid: getUUID(),
        connection_name: podDetails.connectionName,
        table_name: podDetails.podName,
        page: 0,
        limit: 1,
        schema_name: podDetails.dataDomain,
        do_count: false,
        sort_body: [],
        filter_body: [],
        table_ids: [],
      };
      return store
        .dispatch(fetchPreviewTable(request))
        .then((response) => {
          if (response?.status === "success" && response?.data?.result) {
            let responseData = response.data.result;

            if (responseData && responseData !== null) {
              let outcomeColumns = [];
              if (responseData?.data_header?.length) {
                responseData.data_header.forEach((item, index) => {
                  outcomeColumns.push({
                    id: `${item}_${index}`,
                    Name: item,
                    Type: responseData?.data_dtype[index],
                    checked: false,
                    // type: podDetails.podName,
                  });
                });
                if (outcomeColumns?.length) {
                  return outcomeColumns;
                } else {
                  return [];
                }
              }
            } else {
              return [];
            }
          }
        })
        .catch((err) => []);
    }
  };

  const executeTransformer = async (filtergroup) => {
    if (
      !isfieldEmpty() &&
      groupKeys &&
      groupKeys.length && data?.length &&
      transformerName !== ""
    ) {
      settransformerName(transformerName)


      let newColumnList = groupKeys
        .map((item, i) => {
          return item.key_name;
        })
        .filter((ele) => ele && ele);

      let newoutcomeColumns = data.map((item) => {
        return {
          id: item.id,
          text: item.new_name,
          originalColumnName: item.originalColumnName,
          old_name: item.new_name,
          new_name: item.new_name,
          new_datatype: item.Type,
          format: "",
          technical: item.new_name,
          dataType: item.Type,
          checked: false,
          Name: item.new_name,
          Type: item.Type,
          type: item.Type,
          Label: item.new_name,
          columnValue: item.new_name,
          isDisableOption: false,
        };
      });
      groupKeys.forEach((item, i) => {
        newoutcomeColumns.push({
          id: item.id,
          text: item.key_name,
          originalColumnName: item.originalColumnName,
          old_name: item.key_name,
          new_name: item.key_name,
          new_datatype: item.Type,
          format: "",
          technical: item.key_name,
          dataType: item.Type,
          checked: false,
          Name: item.key_name,
          Type: item.Type,
          type: item.Type,
          Label: item.key_name,
          columnValue: item.key_name,
          isDisableOption: false,
        });
      });
      let tempData = data.map((item) => {
        if (item.aggregate_function === "count_distinct") {
          item.aggregate_function = "count";
          item["old_name"] = `distinct ${item["old_name"]}`;

        }
        return item;
      });

      let inputFrame = store.dispatch(getInputFrame())

      if (inputFrame !== "" && newColumnList.length) {
        setShowBtnLoading(true);
        setdisableExcute(true);
        let payload = {
          flow_body: {
            Id: `${transformerName}`,
            currentTransformerID: currentTransformer.id,
            NodeType: `aggregate`,
            Args: [
              {
                Name: "frame",
                Value: inputFrame,
              },
              {
                Name: "columns",
                Value: tempData,
              },
              {
                Name: "group_by_columns",
                Value: newColumnList,
              },
            ],
          },
        };
        try {
          let response =
            flowId &&
            (await store.dispatch(
              executeTransformerApi(flowId, payload, currentTransformer.id)
            ));

          setShowBtnLoading(false);
          setdisableExcute(false);
          setshowRedBtn(false)

          if (response.status === "success") {

            if (response.status === "success") {
              let newStatusInterval = setInterval(async () => {
                let transformerProgress =
                  store.getState().FlowsReducer.transformerProgress;

                let currentStatus = "";
                let error_msg = null;

                if (transformerProgress && transformerProgress.length) {
                  currentStatus = transformerProgress.find((element) => {
                    return element.crnt_transformer === currentTransformer.id;
                  })?.state;
                  error_msg = transformerProgress.find((element) => {
                    return element.crnt_transformer === currentTransformer.id;
                  })?.error_msg;
                }

                if (currentStatus?.toLowerCase() === "success") {
                  clearInterval(newStatusInterval);
                  setShowBtnLoading(false);
                  setdisableExcute(false);

                  let outcomeColumns = [];

                  let GlobalConnectionCache = localStorage.getItem('GlobalConnectionCache')
                  outcomeColumns = await getPreviewResult({
                    podName: transformerName,
                    dataDomain: `flow_${flowId}`,
                    connectionName: GlobalConnectionCache
                      ? GlobalConnectionCache
                      : "application_cache",
                  });

                  let flowsElements = store.getState().FlowsReducer.flowsElements;
                  settransformerName(transformerName)
                  let finalFlowsElements = flowsElements.map((item) => {
                    if (item.id === currentTransformer.id) {

                      item.content.outcomeColumns = outcomeColumns;
                      item.content.aggregate_columns = data;
                      item.content.groupByColumns = groupKeys;
                      item.transformerName = transformerName;
                      item.content.executed_DAG = payload.flow_body;
                    }
                    return item;
                  });
                  store.dispatch(updateFlowsElements([...finalFlowsElements]));


                } else if (
                  currentStatus?.toLowerCase() === "failed" ||
                  currentStatus?.toLocaleLowerCase() === "error"
                ) {
                  setShowBtnLoading(false);
                  setdisableExcute(false);

                  let flowsElements = store.getState().FlowsReducer.flowsElements;
                  let finalFlowsElements = flowsElements.map((item) => {
                    if (item.id === currentTransformer.id) {
                      item["content"]["outcomeColumns"] = [];
                      item.content.executed_DAG = payload.flow_body
                      item.content.aggregate_columns = data;
                      item.content.groupByColumns = groupKeys;

                      item.transformerName = transformerName;
                    }
                    return item;
                  });

                  store.dispatch(updateFlowsElements([...finalFlowsElements]));
                  clearInterval(newStatusInterval);
                }
              }, 1000);
            }



          }
        } catch (error) {

          let finalFlowsElements = flowsElements.map(item => {
            if (item.id === currentTransformer.id) {
              item.transformerName = transformerName
              item.content.executed_DAG = payload.flow_body;
              item.content.aggregate_columns = data;
              item.content.groupByColumns = groupKeys;
            }
            return item
          })
          store.dispatch(updateFlowsElements([...finalFlowsElements]))
          setShowBtnLoading(false);
          setdisableExcute(false);


        }
      } else if (!newColumnList.length) {
        emitToastNotification("info", Literals.PLEASE_SELECT_ONE_GROUP_KEY);
      }
    } else if (!groupKeys.length) {
      emitToastNotification('info', "Please add group keys")
    } else if (!data.length) {
      emitToastNotification('info', "Please add atleast one aggrigate column")
    }
  };



  useEffect(() => {
    if (
      Object.keys(transformerNameList).length &&
      currentTransformer.id &&
      transformerNameList[currentTransformer.id]
    ) {
      setshowRedBtn(false)

      settransformerName(transformerNameList[currentTransformer.id]);
    }
    let sourceContent = flowsElements.find(
      (item) => item.id === currentTransformer.id
    )?.content;



    let groupKeys = [];
    let data = [];

    if (sourceContent?.aggregate_columns?.length) {
      data = sourceContent?.aggregate_columns;
      setData(sourceContent?.aggregate_columns);
    } else {
      setData([{ ...dummyData }]);
      data = [{ ...dummyData }];
    }

    if (sourceContent?.groupByColumns?.length) {
      groupKeys = sourceContent?.groupByColumns;
      setgroupKeys(sourceContent?.groupByColumns);
    } else {
      groupKeys = [{ ...groupKeysData }];
      setgroupKeys([{ ...groupKeysData }]);
    }
    let InputTransformer = dispatch(getInputTransformerDetails(currentTransformer, flowsElements))

    let inputColumns = cloneDeep(InputTransformer[0]?.content?.outcomeColumns);
    if (InputTransformer && InputTransformer?.length && inputColumns?.length) {
      let column = [];
      inputColumns.forEach((item) => {
        column.push({
          id: item.id,
          Name: item.Name,
          Type: item.Type,
          Label: item.Name,
          columnValue: item.Name,
        });
      });
      if (column?.length) {
        let newoutcomeColumns = getNewColumnList(
          "outcomeColumns",
          "",
          column,
          column,
          groupKeys,
          data
        );
        setgroupbyColumnList([...newoutcomeColumns]);
        let groupByColumns = getNewColumnList(
          "groupKeys",
          "",
          column,
          column,
          groupKeys,
          data
        );
        setoutcomecolumnList([...groupByColumns]);

        setToggleDefaultView(false)
        setShowBtnLoading(false);
        setdisableExcute(false);
      } else {

        setgroupbyColumnList([]);

        setoutcomecolumnList([]);

        setToggleDefaultView(true)
        setShowBtnLoading(false);
        setdisableExcute(true);
      }


    } else {
      setToggleDefaultView(true)
      setShowBtnLoading(false);
      setdisableExcute(true);
    }





  }, [currentTransformer]);

  const onChange = (activeKey) => {
    setEditMode(false);
    setActiveKey(activeKey);
  };

  const closeLeftSideContent = () => {
    dispatch(setFlowsCurrenttransformer({}));
    dispatch(showFlowsTransformdetails(false));
  };

  const onAddGroupKeys = () => {
    setgroupKeys([
      ...groupKeys,
      {
        id: "",
        key_name: "",
      },
    ]);
  };

  const onAddFields = () => {
    setData([
      ...data,
      {
        old_name: "",
        aggregate_function: "",
        new_name: "",
        id: "",
        dataType: "",
        originalColumnName: "",
      },
    ]);
  };

  const onRemoveField = (index) => {
    let dataCopy = [...data];
    let columnValue = dataCopy[index]["originalColumnName"];
    dataCopy.splice(index, 1);
    let newColumnList1 = getNewColumnList(
      "removekeyOutcomeColumn",
      columnValue,
      outcomecolumnList,
      groupbyColumnList,
      groupKeys,
      dataCopy
    );
    setoutcomecolumnList([...newColumnList1]);
    let newColumnList2 = getNewColumnList(
      "removekeyGroupKeys",
      columnValue,
      outcomecolumnList,
      groupbyColumnList,
      groupKeys,
      dataCopy
    );
    setgroupbyColumnList([...newColumnList2]);

    setData([...dataCopy]);
  };

  const onRemoveGroupKeys = (index) => {
    let dataCopy = [...groupKeys];
    let columnValue = dataCopy[index]["key_name"]

    dataCopy.splice(index, 1);

    let newColumnList = getNewColumnList(
      "removekeyGroupKeys",
      columnValue,
      outcomecolumnList,
      groupbyColumnList,
      groupKeys,
      dataCopy
    );
    setgroupbyColumnList([...newColumnList]);
    let newColumnList1 = getNewColumnList(
      "removekeyOutcomeColumn",
      columnValue,
      outcomecolumnList,
      groupbyColumnList,
      groupKeys,
      dataCopy
    );
    setoutcomecolumnList([...newColumnList1]);

    setgroupKeys([...dataCopy]);
  };
  const onInputChangeHandler = (field, value, index) => {
    let dataCopy = [...data];

    dataCopy[index][field] = value;
    if (field === "originalColumnName") {
      let selectedColumn = outcomecolumnList.find(
        (item) => item.Name.toLowerCase() === value.toLowerCase()
      );
      if (selectedColumn && selectedColumn.id) {
        dataCopy[index]["id"] = selectedColumn.id;
        if (field === 'new_name') {
          removeStartingUnderscores(value.replaceAll(columnNameRegexExp, '_'))
          dataCopy[index]["new_name"] = value;

        }
        dataCopy[index]["old_name"] = value;
        dataCopy[index]["dataType"] = selectedColumn.Type;
      }
      let newColumnList = getNewColumnList(
        "outcomeColumns",
        value,
        outcomecolumnList,
        groupbyColumnList,
        groupKeys,
        dataCopy
      );

      setgroupbyColumnList([...newColumnList]);
    }
    // if (field === "aggregate_function" && value === "count_distinct") {
    //   dataCopy[index]["old_name"] = `distinct ${dataCopy[index]["old_name"]}`;
    // } 
    setData([...dataCopy]);
  };

  const getNewColumnList = (
    from,
    value,
    outcomecolumnList,
    groupbyColumnList,
    groupKeys,
    data
  ) => {
    let copyGroupbyColumns = cloneDeep(groupbyColumnList);
    let copyOutcomeColumns = cloneDeep(outcomecolumnList);
    let selectedGroupKeys = groupKeys.map((item) => item.key_name);
    let selectedOldColumn = data.map((item) => item.originalColumnName);

    let newColumnList = [];
    if (from === "outcomeColumns") {
      newColumnList = copyGroupbyColumns.map((ele) => {
        if (selectedOldColumn.includes(ele.Name) || selectedGroupKeys.includes(ele.Name)) {
          ele.isDisableOption = true;
        } else {
          ele.isDisableOption = false;
        }
        return ele;
      });
    } else if (from === "groupKeys") {

      newColumnList = copyOutcomeColumns.map((ele) => {
        if (selectedGroupKeys?.includes(ele.Name)) {
          ele.isDisableOption = true;
        }
        else {
          ele.isDisableOption = false;
        }
        return ele;
      });
    }
    // console.log(from, value, copyGroupbyColumns, copyOutcomeColumns,groupKeys,data)

    if (from === "removekeyOutcomeColumn") {
      // newColumnList = copyGroupbyColumns.map((ele) => {
      //   if (ele.Name?.toLowerCase() === value) {  //selectedOldColumn.includes(value) &&
      //     ele.isDisableOption = false;
      //   }
      //   return ele;
      // });
      newColumnList = copyOutcomeColumns.map((ele) => {
        if (ele.Name === value) {  //selectedGroupKeys.includes(value) &&
          ele.isDisableOption = false;
        }
        return ele;
      });
    }
    if (from === "removekeyGroupKeys") {
      // newColumnList = copyOutcomeColumns.map((ele) => {
      //   if (ele.Name === value) {  //selectedGroupKeys.includes(value) &&
      //     ele.isDisableOption = false;
      //   }
      //   return ele;
      // });
      newColumnList = copyGroupbyColumns.map((ele) => {
        if (!selectedOldColumn.includes(value) && ele.Name?.toLowerCase() === value) {  //selectedOldColumn.includes(value) &&
          ele.isDisableOption = false;
        }
        return ele;
      });
    }

    return newColumnList;
  };

  const onGroupKeySelect = (field, value, index) => {
    let dataCopy = [...groupKeys];

    dataCopy[index][field] = value;
    setgroupKeys([...dataCopy]);
    let selectedColumn = groupbyColumnList.find(
      (item) => item.Name.toLowerCase() === value.toLowerCase()
    );
    if (selectedColumn && selectedColumn.id) {
      dataCopy[index]["id"] = selectedColumn.id;
      dataCopy[index]["new_name"] = value;
      dataCopy[index]["dataType"] = selectedColumn.Type;
    }

    let newColumnList1 = getNewColumnList(
      "groupKeys",
      value,
      outcomecolumnList,
      groupbyColumnList,
      dataCopy,
      data
    );

    setoutcomecolumnList([...newColumnList1]);

    let newColumnList2 = getNewColumnList(
      "outcomeColumns",
      value,
      outcomecolumnList,
      groupbyColumnList,
      dataCopy,
      data
    );

    setgroupbyColumnList([...newColumnList2]);
  };

  function Validate(e) {
    e.stopPropagation()
    let keyCode = e.key

    //Regex to allow only Alphabets Numbers Dash Underscore and Space
    let pattern = /^[a-zA-Z0-9_]*$/;

    //Validating the textBox value against our regex pattern.
    let isValid = pattern.test(keyCode);
    let match = keyCode.match(pattern)
    if ((!new_name_aggrigate.current?.value?.length && (e.keyCode > 47 && keyCode < 58)) || (new_name_aggrigate.current?.selectionStart === 0 && (e.keyCode > 47 && keyCode < 58))) {
      e.preventDefault()
    } else if (!isValid) {
      e.preventDefault()
    }
  }

  const new_name_aggrigate = useRef()

  useEffect(() => {
    let temp = [];
    let rows = [...data];

    temp = rows.map((v, index) => ({
      originalColumnName: (
        <>
          <div className="align-items-center d-flex h-100 justify-content-between">
            <SingleSelection
              props={{
                placeholder: "Select column",
                isSortOptionDisable:true,

                dropDownValue: v.originalColumnName,
                onClickEvent: (val) => {
                  currentSelctedColValue.current = val;
                  onInputChangeHandler("originalColumnName", val, index);
                },
                optionList: outcomecolumnList,
                isDisable: window.location.href.includes("viewFlows"),
                selectClassName: (!isColumnValuePresent(outcomecolumnList, v.originalColumnName, "originalColumnName"))
                  ? "invalid-column-present w-100 custom-select-dropdown"
                  : " w-100 custom-select-dropdown",
              }}
            />
          </div>
        </>
      ),
      aggregate_function: (
        <>
          <div className="align-items-center d-flex h-100 justify-content-between">
            <SingleSelection
              props={{
                placeholder: "Select function",
                dropDownValue: v.aggregate_function,
                onClickEvent: (val) =>
                  onInputChangeHandler("aggregate_function", val, index),
                optionList: getAggregateFunc(v.dataType),
                isDisable: window.location.href.includes("viewFlows"),
                selectClassName: "w-100",
              }}
            />
          </div>
        </>
      ),
      new_name: (
        <>
          <div className="align-items-center d-flex h-100 justify-content-between">

            <input
              disabled={window.location.href.includes("viewFlows")}
              key={v.id}
              ref={new_name_aggrigate}
              itemRef="new_name_aggrigate"
              onKeyDown={(e) => Validate(e)}
              className={"custom-input-field"}
              type="text"
              placeholder="Enter Value"
              defaultValue={v.new_name}
              onChange={(e) => {
                // if (columnNameRegex.test(e.target.value) || e.target.value === "") {
                onInputChangeHandler("new_name", e?.target?.value, index);
                // }

              }}
            />
            <Buttons
              props={{
                buttonText: "",
                buttonClassName: "danger-btn custom-btn btn-with-icon ml-2",
                buttonEvent: () => {
                  onRemoveField(index);
                },
                toggleBtnValue: "",
                ImgSrc: () => <MINUS />,
                isDisable: window.location.href.includes("viewFlows"),
                buttonType: Literals.BTN_TERTIARY,
                toggleBtnOption: null,
              }}
            />
          </div>
        </>
      ),
    }));

    setDemo([...temp]);
  }, [data, groupbyColumnList, outcomecolumnList]);

  const currentSelctedGroup = useRef('')
  const currentSelctedColValue = useRef('')
  const isColumnValuePresent = (columnList, columnValue = '', key) => {

    if (columnList?.length && columnValue !== "") {
      let isPresent = columnList.findIndex(element => element.Name === columnValue);
      if (isPresent > -1) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const getGroupByRows = (v, i) => {
    return {
      columns: (
        <>
          <div
            key={`aggregate-colli-${i}`}
            className="align-items-center w-100 d-flex h-100"
          >
            <SingleSelection
              props={{
                placeholder: "Select column",
                isSortOptionDisable:true,

                dropDownValue: v.key_name,
                onClickEvent: (value) => { currentSelctedGroup.current = value; onGroupKeySelect("key_name", value, i) },
                optionList: groupbyColumnList,
                isDisable: window.location.href.includes("viewFlows"),
                selectClassName: (!isColumnValuePresent(groupbyColumnList, v.key_name))
                  ? "invalid-column-present w-100 custom-select-dropdown"
                  : " w-100 custom-select-dropdown",
              }}
            />
            <Buttons
              props={{
                buttonText: "",
                buttonClassName: "danger-btn custom-btn btn-with-icon ml-2",
                buttonEvent: () => {
                  onRemoveGroupKeys(i);
                },
                toggleBtnValue: "",
                ImgSrc: () => <MINUS />,
                isDisable: window.location.href.includes("viewFlows"),
                buttonType: Literals.BTN_TERTIARY,
                toggleBtnOption: null,
              }}
            />

          </div>
        </>
      ),
    };
  };
  return (
    <>
      <div className="flow-header flow-sub-header custom-border-top custom-border-bottom padding-2">
        <div className="d-flex align-items-center">
          <img src={aggregate} alt={`aggregate`} />
          <h3 className="subtitle">Aggregate</h3>
          <Tabs
            onChange={onChange}
            activeKey={activeKey}
            className="custom-tab tab-with-icon"
          >
            <TabPane tab={<PROPERTIES />} key="properties"></TabPane>

            <TabPane tab={<EDITOR />} key="editor"></TabPane>
          </Tabs>
        </div>
        <div className="d-flex align-items-center">

          <DatasetOutputNameInput
            transformerName={transformerName}
            setTransformerName={settransformerName}
          />
          <Buttons
            props={{
              buttonText: "",
              buttonClassName:
                "custom-btn-outline custom-btn btn-with-icon mr-2",
              buttonEvent: () => {
                activeKey === "properties" ||
                  (activeKey === "editor" && !editMode)
                  ? closeLeftSideContent()
                  : setEditMode(false);
                onChange("properties");
              },
              ImgSrc: () => <CLOSE />,
              isDisable: false,
              buttonType: Literals.BTN_SECONDARY,
            }}
          />

          {activeKey === "editor" ? (
            <Buttons
              props={{
                buttonText: !editMode ? "Edit" : "Save",
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                buttonEvent: !editMode
                  ? () => {
                    setEditMode(true);
                    dispatch(saveTransformerNoteClick(false));
                  }
                  : () => {
                    dispatch(saveTransformerNoteClick(true));
                  },
                ImgSrc:
                  !editMode ? () => <EDIT /> : () => <ICON_SAVE />,
                isDisable: window.location.href.includes("viewFlows")
                  ? true
                  : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}

          {activeKey !== "editor" && !showSampleRow ? (
            <Buttons
              props={{
                buttonText: "Execute",
                buttonClassName: `${showRedBtn ? 'execute-btn-gradient' : ''} custom-btn-primary custom-btn btn-with-text`,
                showLoading: showBtnLoading,
                buttonEvent:
                  activeKey === "editor"
                    ? () => {
                      setEditMode(true);
                    }
                    : () => {
                      executeTransformer([]);
                    },
                ImgSrc:
                  activeKey === "editor" ? () => <EDIT /> : () => <PLAY />,
                isDisable:
                  !isExecutePermission || disableExcute || transformerName === "" ? true : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}
          {activeKey !== "editor" && showSampleRow ? (
            <Buttons
              props={{
                buttonText: "Execute",
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                showLoading: showBtnLoading,
                buttonEvent:
                  activeKey === "editor"
                    ? () => {
                      setEditMode(true);
                    }
                    : () => {
                      executeTransformer([]);
                    },
                ImgSrc:
                  activeKey === "editor" ? () => <EDIT /> : () => <PLAY />,
                isDisable:
                  !isExecutePermission || disableExcute || transformerName === "" ? true : false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
              }}
            />
          ) : null}
        </div>
      </div>
      {activeKey === "properties" && !toggleDefaultView ? (
        <>
          <div className="bg-white section-with-drag flow-filter-height h-100">
            <div className="h-100">
              <div className="d-flex bg-white section-with-drag merge-drag flow-with-drag">
                <div
                  style={{ width: "35%" }}
                  className="custom-border-right position-relative"
                >
                  <div className="d-flex justify-content-between padding-2 py-1 custom-border-bottom">
                    <div className="d-flex align-items-center">
                      <MERGEKEYS />
                      <p className="paragraph ml-2">
                        Group Keys
                        <Tooltip
                          title={
                            "Create a group for each unique combination of these column"
                          }
                          placement="bottom"
                        >
                          {" "}
                          <i
                            class="bx bxs-info-circle"
                            style={{ color: "#828282", fontSize: "12px" }}
                          ></i>
                        </Tooltip>{" "}
                      </p>
                    </div>

                    <div className="d-flex align-items-center">
                      <Buttons
                        props={{
                          buttonText: "",
                          tooltip: "Add More Keys",
                          buttonClassName:
                            "custom-btn custom-btn-outline btn-with-icon",
                          buttonEvent: () => onAddGroupKeys(),
                          ImgSrc: () => <PLUS />,
                          isDisable: window.location.href.includes("viewFlows"),
                          buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                      />
                    </div>
                  </div>

                  <div className="aggrigate-left-table" style={{ height: "calc(100% - 110px)" }}>
                    {/* <DndVirtualList */}
                    <LandingSimpleTable
                      rowsData={groupKeys.map((v, i) => {
                        return getGroupByRows(v, i);
                      })}
                      cols={groupByColumns}
                      tableHeight={"100%"}
                    />
                    {/* rawData={[]} */}
                    {/* /> */}
                  </div>
                </div>
                <div style={{ width: "70%" }}>
                  <div className="d-flex justify-content-between custom-border-bottom padding-2 py-1">
                    <div className="d-flex align-items-center">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.39615 4.81048C8.16831 4.81048 8.79427 5.43643 8.79427 6.20859V17.7929C8.79427 18.5651 8.16831 19.191 7.39615 19.191H5.39885C4.6267 19.191 4.00073 18.5651 4.00073 17.7929V6.20859C4.00073 5.47505 4.56566 4.87344 5.28418 4.81511L5.39885 4.81048H7.39615ZM18.6019 4.81048C19.3741 4.81048 20 5.43643 20 6.20859V17.7929C20 18.5651 19.3741 19.191 18.6019 19.191H16.6046C15.8324 19.191 15.2065 18.5651 15.2065 17.7929V6.20859C15.2065 5.43643 15.8324 4.81048 16.6046 4.81048H18.6019ZM12.9886 4.80981C13.7608 4.80981 14.3867 5.43577 14.3867 6.20793V17.7923C14.3867 18.5645 13.7608 19.1904 12.9886 19.1904H10.9913C10.2192 19.1904 9.59319 18.5645 9.59319 17.7923V6.20793C9.59319 5.43577 10.2192 4.80981 10.9913 4.80981H12.9886ZM7.39615 6.00886H5.39885L5.35305 6.01413C5.26482 6.03483 5.19912 6.11404 5.19912 6.20859V17.7929C5.19912 17.9033 5.28854 17.9927 5.39885 17.9927H7.39615C7.50647 17.9927 7.59588 17.9033 7.59588 17.7929V6.20859C7.59588 6.09828 7.50647 6.00886 7.39615 6.00886ZM18.6019 6.00886H16.6046C16.4943 6.00886 16.4049 6.09828 16.4049 6.20859V17.7929C16.4049 17.9033 16.4943 17.9927 16.6046 17.9927H18.6019C18.7122 17.9927 18.8016 17.9033 18.8016 17.7929V6.20859C18.8016 6.09828 18.7122 6.00886 18.6019 6.00886ZM12.9886 6.0082H10.9913C10.881 6.0082 10.7916 6.09762 10.7916 6.20793V17.7923C10.7916 17.9026 10.881 17.992 10.9913 17.992H12.9886C13.0989 17.992 13.1883 17.9026 13.1883 17.7923V6.20793C13.1883 6.09762 13.0989 6.0082 12.9886 6.0082Z"
                          fill="#1F1F3F"
                        />
                      </svg>
                      <p className="paragraph ml-2">
                        Output Columns
                        <Tooltip
                          title={
                            "Select aggregation to be computed for each column"
                          }
                          placement="bottom"
                        >
                          {" "}
                          <i
                            class="bx bxs-info-circle"
                            style={{ color: "#828282", fontSize: "12px" }}
                          ></i>
                        </Tooltip>{" "}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <Buttons
                        props={{
                          buttonText: "",
                          tooltip: "Add Aggregate Function",
                          buttonClassName:
                            "custom-btn custom-btn-outline btn-with-icon",
                          buttonEvent: () => onAddFields(),
                          ImgSrc: () => <PLUS />,
                          isDisable: window.location.href.includes("viewFlows"),
                          buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                      />
                    </div>

                  </div>
                  <div className="h-100">
                    <div className="aggrigate-right-table"
                      style={{ overflow: "auto", height: "calc(100% - 104px)" }}
                    >
                      <LandingSimpleTable
                        cols={columns}
                        rowsData={demo}
                        tableHeight={"100%"}
                      // autoWidth={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : activeKey === "properties" && toggleDefaultView ? (
        <div className="no-datasource">
          <SCRIPTDEFAULTVIEW />
        </div>
      ) : null}

      {activeKey === "editor" && (
        <EditorIndex editMode={editMode} setEditMode={setEditMode} />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.FlowsReducer.flowsElements,
    currentTransformer: state.FlowsReducer.currentTransformer,
    appliedFilterCriteria: state.FlowsReducer.appliedFilterCriteria,
    transformerNameList: state.FlowsReducer.transformerName,
    transformerLoading: state.FlowsReducer.transformerLoading,

  };
};
export default connect(mapStateToProps)(AggregateTransformerIndex);

const columns = [
  {
    name: (
      <label className="font-w-600 grey-color label mb-0 paragraph">
        Agg. On Columns
      </label>
    ),
    key: "originalColumnName",
    sortable: false,
    resizable: false,

    // width:10
  },
  {
    name: (
      <label className="font-w-600 grey-color label mb-0 paragraph">
        Agg. Function
      </label>
    ),
    key: "aggregate_function",
    sortable: false,
    resizable: false,
    // width:50
  },
  {
    name: (
      <label className="font-w-600 grey-color label mb-0 paragraph">
        Agg. Column Name
      </label>
    ),
    key: "new_name",
    sortable: false,
    resizable: false,
    // width:100

  },
];

const groupByColumns = [
  {
    name: (
      <label className="font-w-600 grey-color label mb-0 paragraph">
        Agg. By Columns
      </label>
    ),
    key: "columns",
    sortable: false,
    resizable: false,
  },
];
