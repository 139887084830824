import React, { useEffect, useState } from 'react'
import { getUserSelectedDomain } from '../../../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import LandingDomainTabs from '../../../../Common/mainLandingPage/components/LandingDomainTabs'
import NavigatorIndex from '../../../../Common/claristaNewLandingPage/NavigatorIndex'
import CatalogFilter from '../../../../Common/CommonModal/catalogFilter/CatalogFilter'
import LandingSearchInput from '../../../../Common/mainLandingPage/components/LandingSearchInput'
import SearchInput from '../../../../Common/search/Search'
import { getObjectLength } from '../../../common/helperFunctions'

function PODSelectionList({ onRowSelection, data, fromRoute }) {
    const dispatch = useDispatch()
    let currentTransformer = useSelector(state => state.Lab.currentTransformer)
    const [searchTerm, setSearchTerm] = useState('')
    const [activeTabKey, setActiveTabKey] = useState('pod')
    const [activeDomain, setActiveDomain] = useState('')
    const [isDomainFailed, setDomainFailed] = useState(false)
    const [isDataLoading, setDataLoading] = useState(false)


    const [filterList, setFilterList] = React.useState({ connections: [], tags: [], types: [] })
    let defaultTabKey = 'pod'
    useEffect(() => {
        if (defaultTabKey) {
            setActiveTabKey(defaultTabKey)
        }
    }, [defaultTabKey])

    useEffect(() => {
        // console.log(data)
        if (data &&
            Object.keys(data).length > 0 &&
            data?.data_domain_detail?.data_domain_id
        ) {
            setActiveDomain(data?.data_domain_detail?.data_domain_id)
        } else {
            if (currentTransformer && !getObjectLength(currentTransformer?.content) && !currentTransformer?.content?.table_id) {

                dispatch(getUserSelectedDomain())
                    .then(res => {
                        const domain = res?.data?.domain ?? undefined
                        if (domain && domain?.id) {
                            setActiveDomain(domain?.id)
                        }
                        else if (domain) {
                            setActiveDomain(domain?.id ?? 0)
                        }
                    })
                    .catch(err => {
                        setDomainFailed(true)
                        // console.error(err?.message)
                    })
            }

        }

    }, [data])

    return (
        <div className='pod-selection-main-wrap'>
            {fromRoute === 'alertflows' ? <h3 className='fontInterSemiBold h6 pt-1 text-dark'>Select POD</h3> : null
            }
            <div className='w-100'>
                <div className=''>
                    <div className='domain-tabs'>
                        {
                            // activeTabKey === 'business' ? ''
                            //     :
                            <LandingDomainTabs
                                activeDomain={activeDomain}
                                setActiveDomain={setActiveDomain}
                                isDomainFailed={isDomainFailed}
                                setDomainFailed={setDomainFailed}
                                activeTabKey={activeTabKey}
                                isDataLoading={isDataLoading}
                            />
                        }
                        <div className='pod-filters d-flex justify-content-end'>

                            <SearchInput
                                searchData={searchTerm}
                                setSearchData={setSearchTerm}
                                placeholder={'Search Assests'}
                                autoFocus={true}
                            />

                            <CatalogFilter
                                id="landingPodFilter"
                                filterList={filterList}
                                setFilterList={setFilterList}
                                showApplyButton={false}
                            />

                        </div>
                    </div>
                </div>
                <div className='pod-list-wrap'>

                    {<NavigatorIndex
                        activeDomain={activeDomain}
                        searchTerm={searchTerm}
                        activeTabKey={activeTabKey}
                        onRowSelection={onRowSelection}
                        filterList={filterList}
                        groupByTags={true}
                        setFilterList={setFilterList}
                        fromRoute={'podselection'}
                        setDataLoading={setDataLoading}
                        isDataLoading={isDataLoading}
                    />}

                </div>
            </div>
        </div>
    )
}

export default PODSelectionList