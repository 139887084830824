import { Checkbox, Select, Tooltip } from "antd";
import _, { isArray } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Modal, ModalBody, ModalHeader } from 'reactstrap';
import "toastr/build/toastr.min.css";
import "../../../../../assets/scss/common/_form.scss";
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { getConnectionListCreatePODAction, testConnection } from "../../../../../store/modules/dataCatalogue/createPOD/createPODActions";
import { configureConnection, createConnection, getConnectionProperties, setConnectionDataList, setConnectionSelectedConn, updateConnection } from "../../../../../store/modules/UserManagement/connection/connectionActions";
import Buttons from '../../../../Common/button/Buttons';
import MultiSelection from '../../../../Common/dropDown/MultiSelection';
import { CLOSE, CREATECONNECTION, DELETEICON, EDIT, GREENTICK, LOADING, REDCLOSE, SAVE, TICK, WHITEPOD } from '../../../../Common/iconSource';
import Status from '../../../../Common/status/Status';
import { NoDataComponent, connectorsList, getConfirmationModal, getObjectLength, preventSpaceEvent } from '../../../common/helperFunctions';
import { Literals } from '../../../common/literals';
import './createNewConn.scss';
import { setLoadingStatus } from "../../../../../store/modules/common/loadingActions";
import { ICON_LOADING_CIRCLE } from "../../../../Common/newIconSource";
import { useSocket } from "../../../../../services/SocketContextProvider";

const getSourceList = () => {
    let list = []
    connectorsList.sources.forEach(el => list.push([el, connectorsList.icons[el], '', connectorsList.title[el]]))
    return list
}

const sourcelist = getSourceList()
const nonConfigSource = ['databricks', 'openai']

const CreatePODNewConn = ({
    closeConnection,
    type,
    openDeleteDialog,
    enableEdit,
    fromRoute = '',
    setOpenCreateConnection,
    openModal = false,
    setOpenModal = () => { },
    setEmpty = () => { }
}) => {

    const dispatch = useDispatch();

    const { Option } = Select;
    const defaultValueassigned = [];

    const selectedConn = useSelector((state) => {
        return state.UserManagement.ConnectionReducer.selectedConn
    })
    const datadomainList = useSelector(state => state.UserManagement.DataDomain.datadomainList)
    const dynamicfields = useSelector(state => state.UserManagement.ConnectionReducer.dynamicfields)
    const dynamicOptfields = useSelector(state => state.UserManagement.ConnectionReducer.optionalFields)

    const userDetails = useSelector(state => state.UserReducer.user.userDetails)
    const isSuperUser = userDetails.is_superuser
    const connList = useSelector(state => state.UserManagement.ConnectionReducer.dataList)


    let initialSources = sourcelist.map(
        (ele) => {
            if (ele[0] === selectedConn?.source_type) {

                ele[2] = 'selected'
            }
            return ele
        }
    )
    const validSelectedConnection = useMemo(() => selectedConn && getObjectLength(selectedConn) > 0, [selectedConn])

    const [connName, setConnName] = useState((validSelectedConnection && selectedConn?.connection_name) || '')
    const [connDes, setConnDes] = useState((validSelectedConnection && selectedConn?.connection_description) || '')
    const [sourcetype, setSourcetype] = useState((validSelectedConnection && selectedConn?.source_type) || [])
    const [createConnFlag, setCreateConnFlag] = useState(false)
    const [selectedMulti, setSelectedMulti] = useState(defaultValueassigned);
    const [sources, setSources] = useState(initialSources);
    const [dynamicFields, setDynamicFields] = useState([])
    const [optionalDynamicFields, setOptionalDynamicFields] = useState([])
    const [status, setStatus] = useState({})
    const [isTested, setIsTested] = useState(false)
    const [fileSystem, setFileSystem] = useState(false)
    const [disableInputs, setDisableInputs] = useState(false)
    const [connectorsDetails, setConnectorsDetails] = useState({})
    const [optionalDetails, setOptionalDetails] = useState({})
    const [isEditingDetails, setEditingDetails] = useState(false)
    const [isEditingProperties, setEditingProperties] = useState(false)
    const [assignTypeCheckbox, setAssignTypeCheckbox] = useState({ cache: false, upload: false })
    const [isCacheAlreadyAssigned, setIsCacheAlreadyAssigned] = useState(false)
    const [isUploadAlreadyAssigned, setIsUploadAlreadyAssigned] = useState(false)
    const [DomainList, setDomainList] = useState([])
    const [confOpen, setConfOpen] = React.useState(false)
    const [updating, setUpdating] = React.useState(false)
    const [clusterStatus, setClusterStatus] = React.useState("")
    const [creating, setCreating] = React.useState(false)
    const unSupportedConnections = ['txt', 'csv', 'parquet', 'upload', 'clarista_demo', 'pod of pods']

    const selectedDomainObj = useRef([])

    const SocketContext = useSocket(null);

    React.useEffect(() => {
        // SocketContext.checkConnectionState();
        if (SocketContext?.socketResponse && SocketContext?.socketResponse?.type === 'heartbeat') {
            setClusterStatus(SocketContext?.socketResponse?.state)
        }
        else if (SocketContext?.socketResponse && SocketContext?.socketResponse?.type !== 'heartbeat') {
            setClusterStatus("LOW")
        }
    }, [SocketContext])
    
    const handleMulti = selectedMulti => {
        let arr = [...selectedMulti]
        arr = arr.map(v => {
            const id = datadomainList?.find(e => e?.data_domain_name?.toLowerCase() === v?.toLowerCase())?.data_domain_id
            return ({data_domain_id: id, data_domain_name: v})
        }
        )
        selectedDomainObj.current = [...arr]
        setSelectedMulti([...selectedMulti]);

    };

    useEffect(() => {
        return () => {
            setConnDes('')
            setConnName('')
            setSelectedMulti([])
            // dispatch(setConnectionSelectedConn([]))
        }
    }, [])

    React.useEffect(() => {
        if(validSelectedConnection && selectedConn?.source_type) {
            setSourcetype(selectedConn?.source_type)
        }
    }, [validSelectedConnection, selectedConn])

    useEffect(() => {
        let children = []
        datadomainList.forEach((ele) => {

            children.push({ key: ele.data_domain_id, Name: ele.data_domain_name, Type: "", value: ele.data_domain_name })

        })
        setDomainList([...children]);
    }, [datadomainList])


    useEffect(() => {
        if (connList.length) {
            setIsCacheAlreadyAssigned(
                connList?.some(con => con.assigned_as_cache === true)
            )
            setIsUploadAlreadyAssigned(
                connList?.some(con => con.assigned_as_upload === true)
            )
        }
    }, [connList])

    useEffect(() => {
        if (dynamicfields && dynamicfields.length) {
            setDynamicFields([...dynamicfields])
        }
    }, [dynamicfields])

    useEffect(() => {
        if (dynamicOptfields && dynamicOptfields.length) {
            setOptionalDynamicFields([...dynamicOptfields])
        }
    }, [dynamicOptfields])

    useEffect(() => {
        let updateSources = []
        if (selectedConn && getObjectLength(selectedConn)) {
            setConnectorsDetails(() => {
                try {
                    if (Object.keys(selectedConn).includes('connection_properties')) {
                        return selectedConn?.connection_properties
                    }
                    else if (Object.keys(selectedConn).includes('masked_properties')) {
                        let obj = {}
                        Object?.keys(selectedConn?.masked_properties)?.forEach(k => {
                            if(selectedConn?.api?.connection_properties?.includes(k)) {
                                obj[k] = selectedConn?.masked_properties[k] ?? ''
                            }
                        })
                        return obj
                    }
                    else return {}
                }
                catch {
                    return {}
                }
            })
            setOptionalDetails(() => {
                    try {
                        if (Object.keys(selectedConn).includes('optional_properties')) {
                            return selectedConn?.optional_properties
                        }
                        else if (Object.keys(selectedConn).includes('masked_properties')) {
                            let obj = {}
                            Object?.keys(selectedConn?.masked_properties)?.forEach(k => {
                                if(selectedConn?.api?.optional_properties?.includes(k)) {
                                    obj[k] = selectedConn?.masked_properties[k] ?? ''
                                }
                            })
                            return obj
                        }
                        else return {}
                    }
                    catch {
                        return {}
                    }
                }
            )
            setAssignTypeCheckbox({
                cache: selectedConn?.assigned_as_cache,
                upload: selectedConn?.assigned_as_upload
            })
            setFileSystem(selectedConn?.file_system)
            if (type !== 'create') {
                setConnName(selectedConn?.connection_name)
                setConnDes(selectedConn?.connection_description)
            }
            updateSources = sourcelist.map((ele) => {
                if (ele[0] === selectedConn?.source_type) {
                    ele[2] = 'selected'
                } else {
                    ele[2] = ''
                }
                return ele
            })
            setSources(updateSources)
        }
    }, [selectedConn, type, openModal])

    useEffect(() => {
        if (window?.location?.href?.toLocaleLowerCase()?.includes('connection')) {
            if ((type === "create") && sources && sources.length > 0 && getObjectLength(selectedConn) === 0 && openModal) {
                connectionProperties(sources[0], true, sourcelist[0][0]);
            }
            if (type === "view") {
                setEditingDetails(false)
                setEditingProperties(false)
            }




            if (!openModal) {
                setStatus({})
                setCreateConnFlag(false)
                setIsTested(false)
            }
        }
    }, [type, openModal])
    useEffect(() => {

        if (Object.keys(selectedConn).includes('data_domain')) {
            setSelectedMulti([...selectedConn?.data_domain?.map(v => v?.data_domain_name)])
            selectedDomainObj.current = [...selectedConn?.data_domain]
            // datadomainList.filter(
            //     value => selectedConn?.data_domain
            //         .map(v => v.data_domain_id).includes(value?.data_domain_id))
            //     .map(itm => defaultValueassigned.push(itm.data_domain_id)
            //     )
            // setSelectedMulti([...defaultValueassigned])
        }
    }, [datadomainList, openModal])


    const isAnyAssigned = () => Object.keys(assignTypeCheckbox).some(key => assignTypeCheckbox[key] === true)

    const connectionProperties = (e, initial, s_type) => {

        if(type === 'create') {
            if(clusterStatus?.toLowerCase() !== "running") return
        }

        if (isSourceDisabled(s_type) || isTested) return;

        setEditingDetails(false)
        setEditingProperties(false)

        if (type !== "view" && type !== "edit") {
            // console.log({s_type, e})
            setSourcetype(s_type || e[0])

            let tempSources = [...initialSources]
            let selected = tempSources.map((ele, index) => {
                if (ele[0] === s_type || e[0]) {
                    if (initial === true && index === 0) { ele[2] = 'selected' }
                    if (initial !== true) { ele[2] = '' }
                }
                else { ele[2] = '' }
                return ele
            }
            )
            setSources(selected)
            try {
                dispatch(getConnectionProperties(s_type || e[0])).then(
                    res => {
                        if (res?.data?.connection_properties !== undefined) {
                            setDynamicFields(res?.data?.connection_properties)
                            setOptionalDynamicFields(res?.data?.optional_properties)
                            dispatch(setConnectionSelectedConn(res?.data))
                        }
                        else {
                            if (s_type === 'clarista_demo') {
                                dispatch(setConnectionSelectedConn(['pod_of_pod', [], false]))
                            }
                            else {
                                dispatch(setConnectionSelectedConn([]))
                            }
                            setDynamicFields([])
                            setOptionalDynamicFields([])
                        }
                    }
                )
                    .catch(e => {
                        if (unSupportedConnections.includes(s_type)) {

                            setDynamicFields([])
                            setOptionalDynamicFields([])
                            dispatch(setConnectionSelectedConn({

                                connection_properties: [],
                                file_system: false,
                                source_type: selectedConn?.source_type
                            }))

                        }

                        else
                            emitToastNotification('error', e.message)

                        setSourcetype(selectedConn?.source_type)
                    })
            }
            catch (e) {

            }
        }

    }

    const handleTestConnection = () => {

        // if (
        //     Object.keys(connectorsDetails).includes('azure_adls_path')
        // ) {
        //     if (connectorsDetails?.['azure_adls_path']?.filter(v => !_.isEmpty(v.trim()))?.length === 0 || connectorsDetails?.['azure_adls_path']?.[0]?.trim() === '/') {
        //         emitToastNotification('error', Literals.AZURE_ADLS_PATH_FIELD_CANNOT_BE_EMPTY)
        //         return;
        //     }
        // }
        if (
            Object.keys(connectorsDetails).includes('s3_uri')
        ) {
            if (connectorsDetails?.['s3_uri']?.filter(v => !_.isEmpty(v.trim()))?.length === 0 || connectorsDetails?.['s3_uri']?.[0]?.trim() === '/') {
                emitToastNotification('error', Literals.S3_URI_PATH_FIELD_CANNOT_BE_EMPTY)
                return;
            }
        }
        let emptyConnectorsFields = Object.keys(connectorsDetails).filter(field => {
            if (isArray(connectorsDetails[field])) return connectorsDetails[field].length === 0
            else return connectorsDetails[field].trim().length === 0
        })
        if (emptyConnectorsFields.length > 0) {
            emitToastNotification('error', `Empty field${emptyConnectorsFields.length === 1 ? '' : 's'} (${emptyConnectorsFields.join(', ')}) found.`)
            return
        }
        let requestBody = {}

        requestBody = {

            "source_type": selectedConn?.source_type,
            "connection_name": connName,
            "connection_properties": connectorsDetails,
            "file_system": fileSystem,
            "data_domain_ids": [...selectedDomainObj.current?.map(v => v?.data_domain_id)]
            // "data_domain_ids": [...selectedMulti]
        }
        setStatus({ color: '#2C83C4', message: 'Establishing', background: 'rgba(44, 131, 196, 0.2)', icon: <LOADING /> })
        try {
            
            dispatch(configureConnection(requestBody, (type === 'edit' || type === 'view'))).then(
                res => {
                    dispatch(setLoadingStatus(true));
                    if (res.status === "success") {
                        setDisableInputs(true)
                        emitToastNotification('info', 'Please wait...', { timeOut: 5000 })
                        setTimeout(() => {

                            try {
                                dispatch(testConnection(requestBody, (type === 'edit' || type === 'view'))).then(
                                    () => {
                                        setStatus({ color: '#00CC92', message: 'Connection Established', background: 'rgba(0, 204, 146, 0.2)', icon: <GREENTICK /> })
                                        setCreateConnFlag(true)
                                        setIsTested(true)
                                        setDisableInputs(false)
                                    }
                                ).catch((e) => {
                                    setStatus({ color: '#FF565E', message: 'Connection Failed', background: 'rgba(255, 86, 94, 0.2)', icon: <REDCLOSE /> })
                                    setTimeout(() => {
                                        setStatus({})
                                    }, 2000)
                                    setDisableInputs(false)
                                })
                            }
                            catch (e) {
                                setStatus({ color: '#FF565E', message: 'Connection Failed', background: 'rgba(255, 86, 94, 0.2)', icon: <REDCLOSE /> })
                                setTimeout(() => {
                                    setStatus({})
                                }, 3000)
                                setDisableInputs(false)
                            }

                        }, 5000);
                    }
                    else {
                        setDisableInputs(false)
                        emitToastNotification('error', res?.message)
                    }
                }
            )
                .catch(() => {
                    setStatus({ color: '#FF565E', message: 'Connection Failed', background: 'rgba(255, 86, 94, 0.2)', icon: <REDCLOSE /> })
                    setTimeout(() => {
                        setStatus({})
                    }, 3000)
                })
        }
        catch (e) {
            setDisableInputs(false)
            setStatus({ color: '#FF565E', message: 'Connection Failed', background: 'rgba(255, 86, 94, 0.2)', icon: <REDCLOSE /> })
            setTimeout(() => {
                setStatus({})
            }, 3000)
        }

    }

    const handleCreateConnection = () => {
        // if (
        //     Object.keys(connectorsDetails).includes('azure_adls_path')
        // ) {
        //     if (connectorsDetails?.['azure_adls_path']?.filter(v => !_.isEmpty(v.trim()))?.length === 0 || connectorsDetails?.['azure_adls_path']?.[0]?.trim() === '/') {
        //         emitToastNotification('error', Literals.AZURE_ADLS_PATH_FIELD_CANNOT_BE_EMPTY)
        //         return;
        //     }
        // }
        if (
            Object.keys(connectorsDetails).includes('s3_uri')
        ) {
            if (connectorsDetails?.['s3_uri']?.filter(v => !_.isEmpty(v.trim()))?.length === 0 || connectorsDetails?.['s3_uri']?.[0]?.trim() === '/') {
                emitToastNotification('error', Literals.S3_URI_PATH_FIELD_CANNOT_BE_EMPTY)
                return;
            }
        }
        let emptyConnectorsFields = Object.keys(connectorsDetails).filter(field => {
            if (isArray(connectorsDetails[field])) return connectorsDetails[field].length === 0
            else return connectorsDetails[field].trim().length === 0
        })
        if (emptyConnectorsFields.length > 0) {
            emitToastNotification('error', `Empty field${emptyConnectorsFields.length === 1 ? '' : 's'} (${emptyConnectorsFields.join(', ')}) found.`)
            return
        }
        setCreating(true)
        let requestBody = {}
        requestBody = {

            "source_type": sourcetype,
            "connection_name": connName,
            "connection_description": connDes,
            "connection_properties": connectorsDetails,
            "optional_properties": optionalDetails,
            "file_system": selectedConn?.file_system,
            "data_domain_ids": !isAnyAssigned() ? [...selectedDomainObj.current?.map(v => v?.data_domain_id)] : [],
            // "data_domain_ids": !isAnyAssigned() ? [...selectedMulti] : [],
            "assigned_as_cache": assignTypeCheckbox.cache,
            "assigned_as_upload": assignTypeCheckbox.upload
        }

        if(isArray(optionalDetails)) {
            delete requestBody.optional_properties
        }

        try {
            
            dispatch(createConnection(requestBody)).then(
                res => {
                    // if (res.status === "success") {
                        setConnName('')
                        setConnDes('')
                        selectedDomainObj.current = []
                        setSelectedMulti([]);
                        try {
                            dispatch(getConnectionListCreatePODAction()).then(
                                res => {
                                    if(res?.data) {
                                        setEmpty(res?.data?.length === 0)
                                    }
                                    dispatch(setConnectionDataList(res.data))
                                    setOpenCreateConnection(false)
                                    setCreating(false)
                                }
                            )
                        }
                        catch (e) {
                            setStatus({ color: '#FF565E', message: 'Connection Failed', background: 'rgba(255, 86, 94, 0.2)', icon: <REDCLOSE /> })
                            setTimeout(() => {
                                setStatus({})
                            }, 2000)
                            setCreating(false)
                        }

                        closeConnection()
                    // }
                }
            )
        }
        catch (e) {
            setStatus({ color: '#FF565E', message: 'Connection Failed', background: 'rgba(255, 86, 94, 0.2)', icon: <REDCLOSE /> })
            setTimeout(() => {
                setStatus({})
            }, 2000)
            setCreating(false)
        }
    }

    const handlePreviousTestConnection = () => {

        let requestBody = {
            "source_type": sourcetype === true ? selectedConn?.source_type : sourcetype,
            "connection_name": connName,
            "connection_properties": connectorsDetails,
            "file_system": fileSystem,
            "data_domain_ids": [...selectedDomainObj.current?.map(v => v?.data_domain_id)]
            // "data_domain_ids": [...selectedMulti]
        }
        setStatus({ color: '#2C83C4', message: 'Establishing', background: 'rgba(44, 131, 196, 0.2)', icon: <LOADING /> })
        try {
            dispatch(testConnection(requestBody)).then(
                (res) => {
                    if (res.status === "success") {
                        setStatus({ color: '#00CC92', message: 'Connection Established', background: 'rgba(0, 204, 146, 0.2)', icon: <GREENTICK /> })
                        setIsTested(true)
                    }
                }
            ).catch(() => {
                setStatus({ color: '#FF565E', message: 'Connection Failed', background: 'rgba(255, 86, 94, 0.2)', icon: <REDCLOSE /> })
                setTimeout(() => {
                    setStatus({})
                }, 3000)
            })
        }
        catch (e) {
            setStatus({ color: '#FF565E', message: 'Connection Failed', background: 'rgba(255, 86, 94, 0.2)', icon: <REDCLOSE /> })
            setTimeout(() => {
                setStatus({})
            }, 3000)
        }

    }

    const handleUpdateConnection = () => {
        let requestBody = {}
        requestBody = {

            "source_type": sourcetype,
            "connection_name": connName,
            "connection_description": connDes,
            "connection_properties": connectorsDetails,
            "file_system": selectedConn?.[2],
            "data_domain_ids": [...selectedDomainObj.current?.map(v => v?.data_domain_id)]
            // "data_domain_ids": [...selectedMulti]
        }
        try {
            dispatch(updateConnection(requestBody, connName)).then(
                res => {
                    if (res.status === "success") {
                        try {



                            dispatch(getConnectionListCreatePODAction()).then(
                                res => {
                                    if(res?.data) {
                                        setEmpty(res?.data?.length === 0)
                                    }
                                    dispatch(setConnectionDataList(res.data))
                                    closeConnection()
                                }
                            )
                        }
                        catch (e) {

                        }
                    }
                }
            )
        }
        catch (e) {
            emitToastNotification('error', Literals.UPDATE_FAILED)

        }
    }

    const labelmultiselect = () => {
        return <>Data Domain {/* <i className="bx bx-info-circle"></i> */}</>
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isAnyAssigned() || (selectedMulti && selectedMulti.length) || type !== 'create') {
            switch (type) {
                case "create":
                    handleCreateConnection()
                    // if (isTested || nonConfigSource?.includes(sourcetype?.toLowerCase())) { handleCreateConnection() }
                    // else { handleTestConnection() }
                    break;
                case "view":
                    saveProperties()
                    // if (isTested) { handleUpdateConnection() }
                    // else { handleTestConnection() }

                    break;
                default:
                    saveProperties()
                    // if (!isTested) { handleTestConnection() }
                    // else { handleUpdateConnection() }
                    break;
            }
        }
        else {
            emitToastNotification('info', Literals.PLEASE_SELECT_ONE_DATA_DOMAIN)
        }
    }

    const handleDynamicInput = (e, field) => {
        let copy = {}
        if (isArray(connectorsDetails)) {
            connectorsDetails.forEach(v => copy[v] = '')
        }
        else {
            copy = { ...connectorsDetails }
        }
        if (field.includes('path')) {
            copy[field] = (e.target.value).split(',');
        }
        else if (field.includes('s3_uri')) {
            copy[field] = [(e.target.value)];
        }
        else {
            copy[field] = e.target.value;
        }
        setConnectorsDetails({ ...copy })
    }

    const handleOptionalInput = (e, field) => {
        let copy = {}
        if (isArray(optionalDetails)) {
            optionalDetails.forEach(v => copy[v] = '')
        }
        else {
            copy = { ...optionalDetails }
        }
        if (field.includes('path')) {
            copy[field] = (e.target.value).split(',');
        }
        else if (field.includes('s3_uri')) {
            copy[field] = [(e.target.value)];
        }
        else {
            copy[field] = e.target.value;
        }
        setOptionalDetails({ ...copy })
    }

    const handleEditingDetails = () => {
        enableEdit('edit')
        setEditingDetails(true)
    }

    const handleEditingProperties = () => {
        enableEdit('edit')
        setEditingProperties(true)
    }

    const saveDetails = () => {
        if (selectedMulti && selectedMulti.length === 0) {
            emitToastNotification('info', Literals.PLEASE_SELECT_ONE_DATA_DOMAIN)
            return
        }
        let request = {

            source_type: selectedConn?.source_type,
            file_system: selectedConn?.file_system,

            connection_description: connDes,
            data_domain_ids: [...selectedDomainObj.current?.map(v => v?.data_domain_id)?.filter(v => v !== 0)]
            // data_domain_ids: [...selectedMulti]
        }

        dispatch(updateConnection(request, connName)).then(resp => {
            if (resp.status === "success") {
                emitToastNotification('success', resp?.message)

                setEditingDetails(false)
                try {
                    dispatch(getConnectionListCreatePODAction()).then(
                        res => {
                            if(res?.data) {
                                setEmpty(res?.data?.length === 0)
                            }
                            dispatch(setConnectionDataList(res.data))
                            closeConnection()
                        }
                    )
                }
                catch (e) {

                }
            } else {
                emitToastNotification('error', resp?.message)
            }
        }).catch(err => emitToastNotification('error', err))




    }

    const saveProperties = () => {

        // if (
        //     Object.keys(connectorsDetails).includes('azure_adls_path')
        // ) {
        //     if (connectorsDetails?.['azure_adls_path']?.filter(v => !_.isEmpty(v.trim()))?.length === 0 || connectorsDetails?.['azure_adls_path']?.[0]?.trim() === '/') {
        //         emitToastNotification('error', Literals.AZURE_ADLS_PATH_FIELD_CANNOT_BE_EMPTY)
        //         return;
        //     }
        // }
        if (
            Object.keys(connectorsDetails).includes('s3_uri')
        ) {
            if (connectorsDetails?.['s3_uri']?.filter(v => !_.isEmpty(v.trim()))?.length === 0 || connectorsDetails?.['s3_uri']?.[0]?.trim() === '/') {
                emitToastNotification('error', Literals.S3_URI_PATH_FIELD_CANNOT_BE_EMPTY)
                return;
            }
        }
        let emptyConnectorsFields = Object.keys(connectorsDetails).filter(field => {
            if (isArray(connectorsDetails[field])) return connectorsDetails[field].length === 0
            else return connectorsDetails[field].trim().length === 0
        })
        if (emptyConnectorsFields.length > 0) {
            emitToastNotification('error', `Empty field${emptyConnectorsFields.length === 1 ? '' : 's'} (${emptyConnectorsFields.join(', ')}) found.`)
            return
        }

        let request = {
            source_type: selectedConn?.source_type,
            connection_name: connName,
            connection_properties: {...connectorsDetails, ...optionalDetails},
            file_system: selectedConn?.file_system,
            // optional_properties: optionalDetails
        }

        setUpdating(true)
        dispatch(updateConnection(request, connName)).then(resp => {
            if (resp.status === "success") {
                emitToastNotification('success', resp?.message)
                // setDisableInputs(true)
                setEditingProperties(false)
                setUpdating(false)
                try {
                    dispatch(getConnectionListCreatePODAction()).then(
                        res => {
                            if(res?.data) {
                                setEmpty(res?.data?.length === 0)
                            }
                            dispatch(setConnectionDataList(res.data))
                            closeConnection()
                        }
                    )
                }
                catch (e) {}
            } else {
                emitToastNotification('error', resp?.message)
                setEditingProperties(true)
                setUpdating(false)
            }
        }).catch(err => {
            emitToastNotification('error', err)
            setEditingProperties(true)
            setUpdating(false)
        })
    }

    const isSourceDisabled = (name) => {
        return false

    }

    const onAssignTypeChange = (checked, type) => {
        setAssignTypeCheckbox(prev => (
            {
                ...prev,
                [type]: checked
            }
        ))
    }

    const checkAnyFieldPresent = () => {
        return (
            (connName && connName?.trim()?.length > 0)
            ||
            (connDes && connDes?.trim()?.length > 0)
            ||
            (selectedMulti && selectedMulti?.length > 0)
            ||
            (
                isArray(connectorsDetails) ? false 
                : (() => {
                    let arr = []
                    for (const key in connectorsDetails) {
                        const element = connectorsDetails[key]
                        arr.push(element ?? '')
                    }
                    return arr?.filter(v => v !== '')?.length > 0
                })()
            )
            ||
            (
                isArray(optionalDetails) ? false 
                : (() => {
                    let arr = []
                    for (const key in optionalDetails) {
                        const element = optionalDetails[key]
                        arr.push(element ?? '')
                    }
                    return arr?.filter(v => v !== '')?.length > 0
                })()
            )
        )
    }

    return (
        <>
            <Modal size="xl" style={{ width: 980 }} isOpen={openModal} className="custom-modal cm-conn-modal" centered={true}>
                <ModalHeader>
                    <div className="d-flex align-items-center">
                        <CREATECONNECTION />
                        <h2 className="section-heading">{type == 'edit' ? "Edit Connection" : type == 'view' ? 'View Connection' : "Create a new connection"}</h2>
                    </div>
                    <div className="d-flex align-items-center">
                        {Object.keys(status).length ? <div className="mr-2"><Status Icon={status.icon} color={status.color} background={status.background} message={status.message} /></div> : null}

                        {(type === 'edit' || type === 'view') && fromRoute !== 'DomainList' ?
                            <Buttons
                                props={{
                                    buttonText: '',
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon error-red mr-2",
                                    buttonEvent: () => {
                                        openDeleteDialog(selectedConn)
                                    },
                                    tooltip: 'Delete Connection',
                                    ImgSrc: () => <DELETEICON />,
                                    isDisable: !isSuperUser,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }} /> : null}
                        {/* {type === "view" ?<Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1", buttonEvent: (e) => { 
                        enableEdit('edit')
                    }, tooltip: 'Edit Connection', ImgSrc: () => <EDIT />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} /> : null} */}
                        <Buttons 
                            props={{ 
                                buttonText: '', 
                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon", 
                                tooltip: 'Close', 
                                buttonEvent: () => {
                                    if(type === "create" && checkAnyFieldPresent()) {
                                        setConfOpen(true)
                                    }
                                    else {
                                        closeConnection()
                                        setConnName('')
                                        setConnDes('')
                                        setSelectedMulti([])
                                    }
                                }, 
                                ImgSrc: () => <CLOSE />, 
                                isDisable: false, 
                                buttonType: Literals.BTN_TERTIARY 
                            }} 
                        />
                    </div>

                </ModalHeader>
                <form onSubmit={handleSubmit} className='h-100' autoComplete='off'>
                    <ModalBody className='py-0 h-100 overflow-hidden'>
                        <div className="createPOD-newconn" style={{ height: 'calc(100% - 0px)' }}>
                            <div className='border-right mt-3 mr-2 pr-2 pb-3' style={{ height: 'calc(100vh - 190px)', overflowY: 'auto' }}>
                                <div className="podHeading pb-2 bg-white sticky-top">
                                    Connection Type
                                </div>
                                <div className='connection-srcs-grid my-3'>
                                    {sources.map((ele) =>
                                        <Tooltip key={ele[0]} placement="top" title={isSourceDisabled(ele[0]) ? 'Not Activated' : ele[3]}>
                                            <div id={ele[0]} className={`connection-srcs-grid-template ${(type !== 'view' && type !== 'edit') && !isTested ? `cursor-pointer${isSourceDisabled(ele[0]) ? '--ignore' : ''}` : 'cursor-not-allowed disable-tile' + ele[2]} ${isSourceDisabled(ele[0]) ? 'cursor-not-allowed disable-tile' : ''} `} onClick={(e) => connectionProperties(e, null, ele[0])} 
                                                style={{ filter: isSourceDisabled(ele[0]) ? 'grayscale(1)' : 'grayscale(0)' }}>
                                                <div className={ele[2] + ' mr-3 ml-3'} onClick={(e) => { e.stopPropagation(); connectionProperties(e, null, ele[0]) }}>
                                                    <div>
                                                        <img onClick={(e) => { e.stopPropagation(); connectionProperties(e, null, ele[0]) }} className='img-fluid con-src-icon' id={ele[0]} src={ele[1]} alt={ele[3]} />
                                                    </div>
                                                </div>
                                                <label onClick={(e) => { e.stopPropagation(); connectionProperties(e, null, ele[0]) }} className={ele[2] + ' con-src-name mt-2 text-capitalize'}>{ele[3]}</label>
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                            <Card className="createPOD-newconn-card my-3" style={{ height: 'calc(100vh - 190px)', overflowY: 'auto', overflowX: 'hidden' }} >

                                <div className='createPOD-newconn-form px-4 py-3 h-100'>
                                    {
                                        type === 'create' && clusterStatus?.toLowerCase() !== "running" && !creating
                                        ?
                                        <>
                                            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                                <p className='h5 mb-0 text-black-50'>Cluster Is Not Running</p>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {
                                                (
                                                    type === 'create'
                                                    && unSupportedConnections.includes(selectedConn?.source_type?.toLocaleLowerCase()))
                                                    ?
                                                    <div className='d-flex align-items-center justify-content-center h-100 w-100'>
                                                        <p className='h5 mb-0 text-black-50'>Please refer create POD section.</p>
                                                    </div>
                                                    :
                                                    !dynamicFields.length ? <>
                                                        <div className='d-flex align-items-center justify-content-center h-100 w-100'>
                                                            <p className='h5 mb-0 text-black-50'>Coming Soon</p>
                                                        </div>
                                                    </> : ''
                                            }
                                            <div className={`${dynamicFields.length ? '' : 'd-none'} `}>
                                                <fieldset disabled={isTested}>
                                                    <div className={`bg-white border custom-card pb-4 px-3 rounded row`}>
                                                        <div className='col-12 px-0 d-flex justify-content-between align-items-center'>
                                                            <div>
                                                                <h5 className='font-weight-bold h5 mb-1'>Connection Details</h5>
                                                            </div>
                                                            {
                                                                type !== "create" && fromRoute !== 'DomainList' && isSuperUser ?
                                                                    <div>
                                                                        <Tooltip placement={'top'} title={isEditingDetails ? 'Save Details' : 'Edit Details'}>
                                                                            <button type='button' className={`custom-btn-outline custom-btn btn-with-text mb-1`}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    isEditingDetails ? saveDetails() : handleEditingDetails()
                                                                                }
                                                                                }
                                                                            >
                                                                                {isEditingDetails ? <><SAVE />Save</> : <><EDIT />Edit</>}
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div> : ''
                                                            }
                                                        </div>
                                                        <div className='col-12 px-0'>
                                                            <div className='border-bottom mx-n3 mb-2'></div>
                                                        </div>
                                                        <div className="col-12 px-0 pb-3">
                                                            <label htmlFor="connName" className='mt-0'>Connection Name</label>
                                                            <input
                                                                required
                                                                pattern='^[a-z0-9_]+$'
                                                                title='Alphanumeric characters are allowed with underscore'
                                                                id="connName"
                                                                type="text"
                                                                className={`${type !== "create" ? 'input-readyonly' : 'custom-input-field'}`}
                                                                value={connName}
                                                                onKeyDown={(e) => preventSpaceEvent(e)}
                                                                onChange={(e) => setConnName(e.target.value?.toLowerCase())}
                                                                readOnly={type !== "create" ? true : false}
                                                                disabled={disableInputs}
                                                            />
                                                            <small className='text-black-50'>Note: Connection name should be in lowercase, and special characters are not allowed.<br /> Connection name cannot be edited.</small>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <label htmlFor="connDes">Connection Description</label>
                                                            <textarea id="connDes"
                                                                type="text" className="custom-textarea-field"
                                                                value={connDes}
                                                                onChange={(e) => setConnDes(e.target.value)}
                                                                readOnly={type === 'view' ? true : type === 'create' ? isTested : !isEditingDetails}

                                                                disabled={disableInputs}
                                                            />
                                                        </div>
                                                        {
                                                            userDetails.is_superuser
                                                                ?
                                                                <div className="col-12 px-0">
                                                                    <label>Assign Connection as</label>
                                                                    <div>
                                                                        <Checkbox
                                                                            checked={assignTypeCheckbox.cache}
                                                                            onChange={(e) => onAssignTypeChange(e.target.checked, 'cache')}
                                                                            className='mt-0'
                                                                            disabled={isCacheAlreadyAssigned || type !== 'create'}
                                                                        >
                                                                            Cache
                                                                        </Checkbox>
                                                                        <Checkbox
                                                                            checked={assignTypeCheckbox.upload}
                                                                            onChange={(e) => onAssignTypeChange(e.target.checked, 'upload')}
                                                                            className='mt-0 ml-2'
                                                                            disabled={isUploadAlreadyAssigned || (!selectedConn?.file_system || type !== 'create')}
                                                                        >
                                                                            Upload
                                                                        </Checkbox>
                                                                    </div>
                                                                </div>
                                                                : ''
                                                        }
                                                        {
                                                            !isAnyAssigned()
                                                                ?
                                                                <div className="col-12 px-0" id="area">
                                                                    {/* <MultiSelection props={{
                                                                        label: "Data Domain", placeholder: "Please select", 
                                                                        className: "custom-select-dropdown", 
                                                                        dropDownValue: selectedMulti, onClickEvent: (value) => {
                                                                            handleMulti(value ? [...value] : [])
                                                                        }, 
                                                                        optionList: DomainList, 
                                                                        children: [], 
                                                                        isDisable:type === 'view' ? true : type === 'create' ? isTested : !isEditingDetails
                                                                    }} /> */}

                                                                        <div id="connCrtModal" className={`custom-select-dropdown`}>
                                                                                <label className="label">{"Data Domain"}</label>
                                                                                <Select
                                                                                    showSearch
                                                                                    mode="multiple"
                                                                                    allowClear
                                                                                    showArrow
                                                                                    className="create-dom-sel"
                                                                                    disabled={type === 'view' ? true : type === 'create' ? isTested : !isEditingDetails}
                                                                                    placeholder={"Select Domain"}
                                                                                    value={selectedMulti}
                                                                                    onChange={(value) => {
                                                                                        handleMulti(value ? [...value] : [])
                                                                                    }}
                                                                                    filterOption={(input, option) => {
                                                                                            const name = DomainList?.find(d => d?.value === option?.value)?.Name ?? ''
                                                                                            return name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                                                                        }
                                                                                    }
                                                                                    filterSort={(optionA, optionB) => {
                                                                                            const nmA = DomainList?.find(d => d?.value === optionA?.value)?.Name ?? ''
                                                                                            const nmB = DomainList?.find(d => d?.value === optionB?.value)?.Name ?? ''
                                                                                            return nmA?.toString()?.toLowerCase()?.localeCompare(nmB?.toString()?.toLowerCase())
                                                                                        }
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        DomainList?.length && DomainList.map((ele, index) => 
                                                                                            <Option key={index} value={ele.value}><span className="element-name" title={ele?.Name}>{ele.Name}</span></Option>
                                                                                        )
                                                                                    }
                                                                                </Select>
                                                                            </div>

                                                                </div>
                                                                : ''
                                                        }
                                                    </div>
                                                </fieldset>
                                                <div className='bg-white border custom-card pb-3 px-3 rounded row mt-3'>
                                                    <div className='col-12 px-0 d-flex justify-content-between align-items-center'>
                                                        <div>
                                                            <h5 className='font-weight-bold h5 mb-1'>Connection Properties</h5>
                                                        </div>
                                                        {
                                                            type !== "create" && fromRoute !== 'DomainList' && isSuperUser ?
                                                                <div className={isEditingProperties ? 'd-none' : ''}>
                                                                    {/* <Tooltip placement={'top'} title={isEditingProperties ? 'Save Properties' : 'Edit Properties'}> */}
                                                                        <button 
                                                                            type='button' 
                                                                            className={`custom-btn-outline custom-btn btn-with-text mb-1`}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                isEditingProperties ? saveProperties() : handleEditingProperties()
                                                                            }
                                                                            }
                                                                        >
                                                                            {isEditingProperties ? <><SAVE />Save</> : <><EDIT />Edit</>}
                                                                        </button>
                                                                    {/* </Tooltip> */}
                                                                </div> : ''
                                                        }
                                                    </div>
                                                    <div className='col-12 px-0'>
                                                        <div className='border-bottom mx-n3'></div>
                                                    </div>
                                                    <div className='col-12 px-0 mt-2'>
                                                        <fieldset disabled={isTested}>
                                                            <div className='row'>
                                                                {
                                                                    Object.keys(connectorsDetails).length && dynamicFields?.map((field, index) => (
                                                                        <React.Fragment key={`dn-field-${index}`}>
                                                                            <div className="col-6 mb-2">
                                                                                <Tooltip placement='topLeft' title={field.toUpperCase().replaceAll('_', ' ')} >
                                                                                    <label className='text-uppercase text-with-ellipsis' htmlFor={field}>{field.replaceAll('_', ' ')}</label>
                                                                                </Tooltip>
                                                                                <input required
                                                                                    id={field} type={(field.includes('password') || field.includes('key')) ? 'password' : 'text'}
                                                                                    className="custom-input-field "
                                                                                    value={connectorsDetails[field] || ""}
                                                                                    onChange={e => handleDynamicInput(e, field)}
                                                                                    readOnly={type === 'create' ? false : !isEditingProperties}
                                                                                    disabled={type === 'create' ? false : type === 'edit' ? !isEditingProperties : disableInputs}
                                                                                />
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ))
                                                                }
                                                            </div>
                                                            {
                                                                Object.keys(optionalDetails).length === 0 || optionalDynamicFields?.length === 0 ? ''
                                                                :
                                                                <div className='row mt-2'>
                                                                    <div className="col-12 mb-2">
                                                                        <span className="fontInterSemiBold fontSizeHeading text-dark">Optional Properties</span>
                                                                    </div>
                                                                    {
                                                                        Object.keys(optionalDetails).length && optionalDynamicFields?.map((field, index) => (
                                                                            <React.Fragment key={`dn-opfield-${index}`}>
                                                                                <div className="col-6 mb-2">
                                                                                    <Tooltip placement='topLeft' title={field.toUpperCase().replaceAll('_', ' ')} >
                                                                                        <label className='text-uppercase text-with-ellipsis' htmlFor={field}>{field.replaceAll('_', ' ')}</label>
                                                                                    </Tooltip>
                                                                                    <input
                                                                                        id={field} type={(field.includes('password') || field.includes('key')) ? 'password' : 'text'}
                                                                                        className="custom-input-field "
                                                                                        value={optionalDetails[field] || ""}
                                                                                        onChange={e => handleOptionalInput(e, field)}
                                                                                        readOnly={type === 'create' ? false : !isEditingProperties}
                                                                                        disabled={type === 'create' ? false : type === 'edit' ? !isEditingProperties : disableInputs}
                                                                                    />
                                                                                </div>
                                                                            </React.Fragment>
                                                                        ))
                                                                    }
                                                                </div>
                                                            }
                                                        </fieldset>
                                                    </div>
                                                    <div className='col-12 mt-4 px-0'>
                                                        <div className={!dynamicFields.length ? 'd-none' : ''} style={{ display: 'flex', justifyContent: "flex-end" }}>
                                                            {type === "create" && fromRoute !== 'DomainList' ? (
                                                                <>
                                                                    {
                                                                    // isTested || 
                                                                    nonConfigSource?.includes(sourcetype) ?
                                                                        <Buttons
                                                                            props={{
                                                                                actionType: 'submit',
                                                                                buttonText: 'Done',
                                                                                buttonClassName: "custom-btn-primary custom-btn btn-with-text mr-2",
                                                                                buttonEvent: (e) => { },
                                                                                ImgSrc: () => <TICK />,
                                                                                isDisable: nonConfigSource?.includes(sourcetype) || createConnFlag  ? false : true,
                                                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                                            }}
                                                                        />
                                                                        : <Buttons
                                                                            props={{
                                                                                actionType: 'submit',
                                                                                buttonText:
                                                                                    'Done',
                                                                                    // 'Test Connection',
                                                                                buttonClassName: "custom-btn-primary custom-btn btn-with-text mr-2",
                                                                                buttonEvent: (e) => { },
                                                                                ImgSrc: () => <TICK />,
                                                                                isDisable: disableInputs,
                                                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                                            }}
                                                                        />
                                                                    }
                                                                </>
                                                            ) : type === "view" && fromRoute !== 'DomainList' ?
                                                                <>
                                                                    {/* <Buttons 
                                                                props={{ 
                                                                    actionType : 'submit',
                                                                    buttonText: 'Test Connection', 
                                                                    buttonClassName: "custom-btn-primary custom-btn btn-with-text mr-2",
                                                                    buttonEvent: (e) => { }, 
                                                                    ImgSrc: () => <WHITEPOD />, 
                                                                    isDisable: disableInputs, 
                                                                    buttonType:Literals.BTN_PRIMARY_WITH_TEXT
                                                                }} 
                                                            /> */}
                                                                </> :
                                                                <>
                                                                    {   
                                                                        // !isTested && 
                                                                        // fromRoute !== 'DomainList' 
                                                                        // && !nonConfigSource?.includes(sourcetype)
                                                                        // ?
                                                                        // <Buttons
                                                                        //     props={{
                                                                        //         actionType: 'submit',
                                                                        //         buttonText: 'Save',
                                                                        //         // buttonText: 'Test Connection',
                                                                        //         buttonClassName: "custom-btn-primary custom-btn btn-with-text mr-2",
                                                                        //         buttonEvent: (e) => { },
                                                                        //         ImgSrc: () => <TICK />,
                                                                        //         isDisable: disableInputs,
                                                                        //         buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                                        //     }}
                                                                        // />
                                                                        // : 
                                                                        !isEditingProperties ? ''
                                                                        :
                                                                        <Buttons
                                                                            props={{
                                                                                actionType: 'submit',
                                                                                buttonText: updating ? 'Saving...' : 'Save',
                                                                                buttonClassName: "custom-btn-primary custom-btn btn-with-text mr-2",
                                                                                buttonEvent: () => { 
                                                                                    // saveProperties() 
                                                                                },
                                                                                ImgSrc: () => updating ? <ICON_LOADING_CIRCLE/> : <TICK />,
                                                                                isDisable: updating,
                                                                                // isDisable: (() => {
                                                                                //     if(createConnFlag) return false
                                                                                //     if(nonConfigSource?.includes(sourcetype)) return false
                                                                                //     return true
                                                                                // })(),
                                                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                                            }}
                                                                        />
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>

                            </Card>
                        </div>
                    </ModalBody>
                </form>
            </Modal>

            {
                getConfirmationModal({
                    open: confOpen,
                    setOpen: setConfOpen,
                    body: "Data Will Be Lost, Continue?",
                    onConfirm: () => {
                        closeConnection()
                        setConnName('')
                        setConnDes('')
                        setSelectedMulti([])
                    }
                })
            }
        </>
    )
}

export default CreatePODNewConn;