import React, { useEffect, useState } from "react";
import "../userLandingPage.scss";

import { useDispatch, useSelector, useStore } from "react-redux";

import { setViewMode } from '../../../../store/app/appAction';

import { Literals } from "../../common/literals";

import Buttons from "../../../../components/Common/button/Buttons";
import Filter from '../../../../components/Common/filter/Filter';
import { openModel } from '../../../../store/actions';
import { setEditMode, setSaveClicked } from "../../../../store/modules/entitlements/entitlementAction";
import { getUserProfile, openFilter } from '../../../../store/modules/UserManagement/users/usersActions';
import {
  FILTER, LISTVIEW, SMALLDATADOMAIN,
  SMALLGROUP,
  SMALLUSERS, TABVIEW, ENTITLEMENTICON, EDIT, SAVE, CLOSE, SETTINGS, ACCESSMANAGMENT,
  RESETFILTER
} from "../../../Common/iconSource";
import SearchInput from "../../../Common/search/Search";
import DataDomains from "../dataDomains/index";
import UserGroup from "../userGroup/index";
import Users from "../users/index";
import ENTITLEMENT from "../entitlement";
import { Checkbox, Dropdown, List, Popover, Tabs, Tooltip } from "antd";
import SAMLDetails from "../users/components/SAMLDetails";
import OAuthLandingPage from "../users/components/OAuthLandingPage";
import EmailTenant from "../users/components/EmailTenant";
import UserConfig from "../users/components/UserConfig";
import { ICON_CHECK } from "../../../Common/newIconSource";
import _ from "lodash";




const userTypes = [
  {name: 'Super User', selected: false},
  {name: 'Normal User', selected: false},
  {name: 'Active User', selected: false},
  {name: 'Inactive User', selected: false}
]

const LandingPageHeader = () => {
  let store = useStore()

  const isSuperUser = useSelector(state => state?.UserReducer?.user?.userDetails?.is_superuser)

  const [dataClone, setDataClone] = useState([]);
  const [optionOpen, setOptionOpen] = useState(false)
  // const [showSAMLPage, setshowSAMLPage] = useState(false)
  // const [showOAuthPage, setshowOAuthPage] = useState(false)
  const [activeOpt, setActiveOpt] = useState(undefined)
  const optionList = React.useMemo(() => {
    let opts = [
      {
        label: <span className={`dbox-opt-itm`}>
          <ACCESSMANAGMENT color="currentColor" />
          SAML SSO
        </span>,
        key: 'saml',
        disabled: false
      },
      {
        label: <span className={`dbox-opt-itm`}>
          <ACCESSMANAGMENT color="currentColor" />
          OAuth SSO
        </span>,
        key: 'oauth',
        disabled: false
      },
      {
        label: <span className={`dbox-opt-itm`}>
          <ACCESSMANAGMENT color="currentColor" />
          Email
        </span>,
        key: 'email',
        disabled: false
      },
    ]

    if (!isSuperUser)
      return opts?.filter(o => o?.key !== 'email')
    return opts

  }, [isSuperUser])

  const filterStatus = useSelector((state) => {

    return state.UserManagement.Users.openFilter
  })
  const userFilters = useSelector((state) => state.App)

  const entitlementEditMode = useSelector((state) =>
    state.Entitlement.isEdit
  )
  const [selectedFilters, setSelectedFilters] = useState(userFilters.Filters.user?.selectedFilters ?? []);

  const isUMHeaderBtnDisable = useSelector(state => state.UserManagement.DataDomain.isUMHeaderBtnDisable)

  const toggleBtnOption = [
    { label: <TABVIEW />, value: "grid" },
    { label: <LISTVIEW />, value: "list" },
  ];

  const dispatch = useDispatch();

  const [isSearch, setIsSearch] = useState(false);
  const [layout, setLayout] = useState("grid");

  const [filterList, setFilterList] = useState([{user_types: [...userTypes], persona: []}]);
  const [localFilterList, setLocalFilterList] = React.useState({user_types: [...userTypes], persona: []});
  const [filterSearchData, setFilterSearchData] = React.useState('');
  const [parentUsersList, setParentUsersList] = React.useState([]);

  const [selectedNavItem, setSelectedNavItem] = useState();
  const [settingsOpen, setSettingsOpen] = useState(false);

  const [showFilterPop, setFilterPop] = React.useState(false);

  const [searchValue, setSearchValue] = useState("");
  
  React.useEffect(() => {
    let usPersona = _.uniqBy(parentUsersList, 'designation')?.map(u => u?.designation)
    setFilterList(prev => ({
      ...prev,
      persona: usPersona?.map(u => ({name: u, selected: false}))
    }))
    setLocalFilterList(prev => ({
      ...prev,
      persona: usPersona?.map(u => ({name: u, selected: false}))
    }))

  }, [parentUsersList])

  const leftNavbar = [
    {
      id: Literals.DATA_DOMAIN,
      active: <SMALLDATADOMAIN color="#2C83C4" />,
      inactive: <SMALLDATADOMAIN />,
      key: 'datadomain'
    },
    {
      id: Literals.USERGROUP,
      active: <SMALLGROUP color="#2C83C4" />,
      inactive: <SMALLGROUP />,
      key: 'usergroup'
    },
    {
      id: Literals.USERS,
      active: <SMALLUSERS color="#2C83C4" />,
      inactive: <SMALLUSERS />,
      key: 'users'
    },
    {
      id: Literals.ROLES,
      active: <ENTITLEMENTICON color="#2C83C4" />,
      inactive: <ENTITLEMENTICON />,
      key: 'roles'
    }
  ];

  function setNavData(navItem) {
    window.history.pushState(null, null, `#${navItem?.key}`)
    setSelectedNavItem(navItem);
    setSettingsOpen(false)
    setSearchValue("");
    setActiveOpt(undefined)
    // setshowOAuthPage(false)
    // setshowSAMLPage(false)
  }


  // const setPODView = (view) => {
  //   dispatch(setViewMode({
  //     module: "data_domain",
  //     view_type: view
  //   })).then(() => {
  //     setLayout(view);

  //   })
  // };



  const ShowSelectedPage = () => {
    switch (selectedNavItem?.id) {
      case Literals.DATA_DOMAIN:
        return (
          <DataDomains
            layout={layout}
            setLayout={setLayout}
            setSearchData={searchValue}
            selectedNavItem={selectedNavItem}
          />
        );

      case Literals.USERGROUP:
        return (
          <UserGroup
            layout={layout}
            setLayout={setLayout}
            setSearchData={searchValue}
            selectedNavItem={selectedNavItem}
          />
        );

      case Literals.USERS:
        if (settingsOpen) {
          return <>
            <UserConfig />
          </>
        }
        return <>
          <Users
            layout={layout}
            setLayout={setLayout}
            usersList={dataClone}
            setParentUsersList={setParentUsersList}
            selectedFilters={selectedFilters}
            filterList={filterList}
            setSelectedFilters={setSelectedFilters}
            setSearchData={searchValue}
            selectedNavItem={selectedNavItem}
          />
        </>
      // switch (activeOpt) {
      //   case 'saml':
      //     return <>
      //       <SAMLDetails handleClose={() => setActiveOpt(undefined)} />
      //     </>
      //   case 'oauth':
      //     return <>
      //       <OAuthLandingPage handleClose={() => setActiveOpt(undefined)} />
      //     </>
      //   case 'email':
      //     return <>
      //       <EmailTenant handleClose={() => setActiveOpt(undefined)}/>
      //     </>
      //   default:
      //     return <>
      //     <Users
      //         layout={layout}
      //         setLayout={setLayout}
      //         usersList={dataClone}
      //         selectedFilters={selectedFilters}
      //         filterList={filterList}
      //         setFilterList={setFilterList}
      //         setSelectedFilters={setSelectedFilters}
      //         setSearchData={searchValue}
      //         selectedNavItem={selectedNavItem}
      //       />
      //     </>
      // }
      case Literals.ROLES:
        return (

          <ENTITLEMENT

            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        )
      default:
        break;
    }
  };

  const showSelectedOpt = () => {
    switch (activeOpt) {
      case 'value':
        return <></>
      default:
        return <></>
    }
  }



  const checkActiveTab = () => {

    /**
     * New LOGIC
     */
    let key = window?.location?.hash?.split('#')?.[1] ?? 'datadomain'

    setSelectedNavItem(
      leftNavbar?.find(n => n?.key === key)
    )

    const searchParams = new URLSearchParams(document.location.search)
    const openAi = searchParams.get("openAi");
    if(openAi){
      setSettingsOpen(true)
    }else{
      setSettingsOpen(false)

    }
    /**
     * OLD LOGIC
     */
    // let activeTabName = store.getState().UserManagement.UserGroupReducer.activeTabName
    // if (activeTabName !== '') {

    //   setSelectedNavItem({
    //     id: activeTabName,
    //     class: "bx-filter",
    //   })
    // }
    // else {

    //   setSelectedNavItem({
    //     id: Literals.DATA_DOMAIN,
    //     class: "bx-filter",
    //   })
    // }
  }

  const getUserProfileData = () => {
    dispatch(getUserProfile()).then((res) => {
      if (res.status == "success") {
        setLayout(res.data.view_type);
      }
    })

  }

  useEffect(() => {
    checkActiveTab()
    setActiveOpt(undefined)

    // setshowOAuthPage(false)
    // setshowSAMLPage(false)
    // getUserProfileData();
  }, [])

  const filterTabItems = React.useMemo(() => {
    const user_types = [...localFilterList.user_types]
    const persona = [...localFilterList.persona]
    
    const selectedUserType = filterList?.user_types?.filter(v => v.selected) ?? []
    const selectedPersona = filterList?.persona?.filter(v => v.selected) ?? []

    const searchTerm = filterSearchData?.toLowerCase()
    return [
        { 
            label: <span className="align-items-center d-flex small">
                User Types
                {
                    selectedUserType?.length
                    ?
                    <span className="ml-2 badge badge-pill bg-primary text-white">{selectedUserType?.length}</span>
                    : ''
                }
            </span>, 
            key: 'item-1', 
            children: <div className="comm-flt-li">
                    {
                        user_types?.length === 0
                        ?
                        <p className='mb-0 text-black-50 text-center p-3'>No User Types</p>
                        :
                        <List
                            size="small"
                            dataSource={
                                _.sortBy(
                                    user_types?.filter(v => v?.name?.toLowerCase()?.includes(searchTerm)),
                                    [(o) => o?.name?.toLowerCase()]
                                )
                            }
                            renderItem={(item) => <List.Item>
                                    <Checkbox 
                                        className="mr-2" 
                                        checked={item.selected}
                                        disabled={false} 
                                        onChange={(e) => handleFilterChange('user_types', item.name, e.target.checked)} 
                                    /> 
                                    <span>{item.name}</span>
                                </List.Item>
                            }
                        />
                    }
                </div> 
        },
        { 
            label: <span className="align-items-center d-flex small">
                Persona
                {
                    selectedPersona.length
                    ?
                    <span className="ml-2 badge badge-pill bg-primary text-white">{selectedPersona.length}</span>
                    : ''
                }
            </span>, 
            key: 'item-2', 
            children: <div className="comm-flt-li">
                    {
                        persona.length === 0
                        ?
                        <p className='mb-0 text-black-50 text-center p-3'>No Persona</p>
                        :
                        <List
                            size="small"
                            dataSource={
                                _.sortBy(
                                    persona?.filter(v => v?.name?.toLowerCase()?.includes(searchTerm)),
                                    [(o) => o?.name?.toLowerCase()]
                                )
                            }
                            renderItem={(item) => <List.Item>
                                    <Checkbox 
                                        className="mr-2" 
                                        checked={item.selected}
                                        disabled={false} 
                                        onChange={(e) => handleFilterChange('persona', item.name, e.target.checked)} 
                                    /> 
                                    {item.name}
                                </List.Item>
                            }
                        />
                    }
                </div> 
        }
      ]
  }, [filterList, localFilterList, filterSearchData, parentUsersList]) 

  const handleFilterChange = (key, name, checked) => {
      const prevFunc = prev => (
          {
            ...prev,
            [key]: prev[key].map(v => ({...v, selected: name === v.name ? checked : v.selected }))
          }
      )
      
      setLocalFilterList(prevFunc)
  }

  const handleApply = () => {
      setFilterList({...localFilterList})

      // onApply(localFilterList)
  }

  const filterPopover = {
      header: () => {
          return <>
              <div className="d-flex justify-content-between card-padding padding-2">
                  <p className="section-heading m-0">Filters</p>
                  <div className="d-flex">
                      <Buttons
                          props={{
                              buttonText: "",
                              buttonClassName:
                                  "custom-btn-outline custom-btn btn-with-icon mr-2 border-0",
                              buttonEvent: () => {
                                  setFilterList(prev => (
                                      {
                                        user_types: prev.user_types.map(v => ({...v, selected: false })),
                                        persona: prev.persona.map(v => ({...v, selected: false }))
                                      }
                                  ))
                                  setLocalFilterList(prev => (
                                      {
                                        user_types: prev.user_types.map(v => ({...v, selected: false })),
                                        persona: prev.persona.map(v => ({...v, selected: false }))
                                      }
                                  ))
                                  setFilterSearchData('')
                                  // onReset()
                              },
                              ImgSrc: () => <RESETFILTER />,
                              isDisable: false,
                              buttonType: Literals.BTN_TERTIARY,
                          }}
                      />
                      <Buttons
                          props={{
                              buttonText: "",
                              buttonClassName:
                                  "custom-btn-outline custom-btn btn-with-icon border-0",
                              buttonEvent: () => {
                                  setFilterPop(false);
                              },
                              ImgSrc: () => <CLOSE />,
                              isDisable: false,
                              buttonType: Literals.BTN_TERTIARY,
                          }}
                      />
                  </div>
              </div>
          </>
      },
      body: () => {
          return <>
              <div className='' style={{ width: '302px' }}>
                  <Tabs type="card" className="dict-filter-tabs" items={filterTabItems} />
                  {
                      <div className='p-2 d-flex align-items-center justify-content-between border-top' style={{gap: 10}}>
                          <div>
                              <SearchInput searchData={filterSearchData} setSearchData={setFilterSearchData} />
                          </div>
                          <Buttons
                              props={{
                                  buttonText: "Apply",
                                  buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                  buttonEvent: handleApply,
                                  ImgSrc: () => <ICON_CHECK />,
                                  buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                  isDisable: false,
                                  toggleBtnOption: null,
                              }}
                          />
                      </div>
                  }
              </div>
          </>
      }
  }

  const handleFilterVisibleChange = (visible) => {
      setFilterPop(visible)
  }

  const getFiltersStatus = () => {
    let filters = [...selectedFilters],
      status = null
    if (filters.length === 0) status = false
    else if (filters.length > 0) {
      status = !filters.map(v => v[Object.keys(v)[0]].length > 0).every(v => v === false)
    }

    return status

  }
  let activeTabName = store.getState().UserManagement.UserGroupReducer.activeTabName
  let isGodUser = JSON.parse(localStorage.getItem('user'))?.userDetails?.is_goduser ?? false//store.getState.UserReducer.user?.userDetails?.is_goduser;
  let isFirstUser = JSON.parse(localStorage.getItem('user'))?.userDetails?.is_firstuser ?? false //store.getState.UserReducer.user?.userDetails?.is_firstuser;
  // let isSuperUser = JSON.parse(localStorage.getItem('user'))?.userDetails?.is_superuser ?? false //store.getState.UserReducer.user?.userDetails?.is_firstuser;
  // console.log({leftNavbar})
  return (
    <div className="user-mngm-landingPage">
      <div className="sub-header">
        <div className="sub-header-left">
          <ul className="landingPage_navbar-nav">
            {leftNavbar.map((navItem) => (
              <li
                key={navItem.id}
                onClick={() => setNavData(navItem)}
                className={`subtitle ${navItem.id === selectedNavItem?.id
                  ? "landing_nav-item_active"
                  : ""
                  }`}
              >
                {navItem.id === selectedNavItem?.id
                  ? navItem.active
                  : navItem.inactive}
                {navItem.id}
              </li>
            ))}
          </ul>
        </div>
        {/*Removed this condition since it was of no essential use and affecting the visibility of filter, search buttons  && isUMHeaderBtnDisable.dataTab === selectedNavItem.id */}
        {!isUMHeaderBtnDisable.isDisable ? <div className="sub-header-right">
          <ul className="landingPage_navbar-nav-right">
            {
              selectedNavItem?.id === 'Roles' || settingsOpen ? ''
                :
                <li className="landingPage_nav-item">
                  <SearchInput searchData={searchValue} setSearchData={setSearchValue} />
                </li>
            }
            <li className="landingPage_nav-item">
              {selectedNavItem?.id == Literals.USERS && !settingsOpen && (isGodUser || isFirstUser || isSuperUser) ? <div className="d-flex ml-1 align-items-center" style={{ position: 'relative' }}>
                {

                  <Buttons props={{
                    buttonText: '',
                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon mx-1`,
                    buttonEvent: () => {
                      setSettingsOpen(true)
                    },
                    ImgSrc: () => <SETTINGS />,
                    isDisable: false,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                  }} />
                }

                <Popover 
                    arrow={false}
                    placement="bottomLeft"
                    title={filterPopover.header}
                    content={filterPopover.body}
                    trigger="click"
                    autoAdjustOverflow={true}
                    open={showFilterPop}
                    onOpenChange={handleFilterVisibleChange}
                >
                    <Tooltip placement='bottom' title='Filter'>
                        <button 
                            className={`
                                ${showFilterPop ? "custom-btn-primary" : "custom-btn-outline"} 
                                custom-btn btn-with-icon border-0 ${(filterList.user_types?.filter(v => v.selected)?.length > 0 || filterList.persona?.filter(v => v.selected)?.length > 0) ? 'rdg-filter-active' : ''}`
                            }
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFilterPop(true)
                                }
                            }
                        >
                            <FILTER />
                        </button>
                    </Tooltip>
                </Popover>

                {/* <Buttons props={{
                  buttonText: '', buttonClassName: `custom-btn-outline  custom-btn btn-with-icon ml-1 mr-1 ${getFiltersStatus() ? 'custom-btn-active' : ''}`, buttonEvent: () => {
                    dispatch(openFilter(true));
                    dispatch(openModel('filter'))

                  }, ImgSrc: () => <FILTER color={getFiltersStatus() ? '#2C83C4' : "#333333"} />, isDisable: false, buttonType: Literals.BTN_TERTIARY
                }} /> */}

              </div> : settingsOpen? <Buttons props={{
                buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1 mr-1", buttonEvent: () => {
                  setSettingsOpen(false)

                }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY
              }} />:null}
            </li>
            {selectedNavItem?.id == Literals.ROLES ? entitlementEditMode ?
              <>
                <li>
                  <Buttons props={{
                    buttonText: 'Save', buttonClassName: "custom-btn-primary custom-btn btn-with-text  ml-1 mr-1", buttonEvent: () => {
                      dispatch(setEditMode(false));
                      dispatch(setSaveClicked(true));

                    }, ImgSrc: () => <SAVE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                  }} />
                </li>
                <li>
                  <Buttons props={{
                    buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1 mr-1", buttonEvent: () => {
                      dispatch(setEditMode(false));
                      dispatch(setSaveClicked(false));

                    }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY
                  }} />
                </li>
              </> :
              <li>
                {/* <Buttons props={{
                  buttonText: 'Edit', 
                  buttonClassName: "custom-btn-primary custom-btn btn-with-text ml-1 mr-1", buttonEvent: () => {
                    dispatch(setEditMode(true));
                    dispatch(setSaveClicked(false));


                  }, ImgSrc: () =>  <EDIT />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                }} /> */}
              </li>
              : null
              // <li className="ml-2">
              //   <Buttons
              //     props={{
              //       buttonText: "",
              //       toggleBtnOption: toggleBtnOption,
              //       toggleBtnValue: layout,
              //       buttonClassName: "toggle-btn custom-btn btn-with-icon",
              //       buttonEvent: setPODView,
              //       ImgSrc: () => <></>,
              //       isDisable: false,
              //       buttonType: Literals.BTN_TOGGLE,
              //     }}
              //   />
              // </li>
            }

          </ul>
        </div> : false}
      </div>

      {ShowSelectedPage()}

      {filterStatus ? <Filter props={{ filterList: filterList, type: "user", setselectedFilters: setSelectedFilters, selectedFilters: selectedFilters, setshowFilter: () => dispatch(openFilter('')) }} /> : ""}
    </div>
  );
};

export default LandingPageHeader;
