import { Tabs, Tooltip } from 'antd';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getEntitlementOnBoardList, updateUsersOnboard } from '../../../../../store/modules/entitlements/entitlementAction';
import { ICON_CATALOG_DOMAIN, ICON_EDIT, ICON_EDITBOX, ICON_EYEOPEN, ICON_INFO, ICON_INFO_FILLED, ICON_USER, ICON_USERS_GROUP } from '../../../../Common/newIconSource';
import { Literals } from '../../../common/literals';
import Buttons from '../../../../Common/button/Buttons';
import { ErrorComponent } from '../../../common/helperFunctions';

const demo = {
	"editor":{
		"count":5,
		"users":[
			{
				"id":1,
				"email":"example@email.com",
				"first_name":"example",
				"last_name":"dotcom",
			},
			{
				"id":3,
				"email":"example3@email.com",
				"first_name":"example3",
				"last_name":"dotcom",
			}
		],
	},
	"viewer":{
		"count":20,
		"users":[
			{
				"id":2,
				"email":"example2@email.com",
				"first_name":"example2",
				"last_name":"dotcom",
			},
			{
				"id":4,
				"email":"example4@email.com",
				"first_name":"example4",
				"last_name":"dotcom",
			}
		],
	}
	
}

const unique_domain_list = ['esg', 'clarity', 'funds', 'demo', 'power']

const EntitlementOnBoard = () => {

    const [data, setData] = React.useState(undefined)
    const [err, setErrMsg] = React.useState(undefined)
    const [empty, setEmpty] = React.useState({viewers: false, editors: false})
    const [loading, setLoading] = React.useState(false)
    const [allowedUsersValue, setAllowedUsersValue] = React.useState({editor: '', viewer: ''})
    const [infoOpen, setInfoOpen] = React.useState({editor: false, viewer: false})

    const dispatch = useDispatch()

    const userDetails = useSelector(
        (state) => state.UserReducer?.user?.userDetails
    );

    const userAccess = userDetails?.is_goduser ?? false

    React.useEffect(() => {
        getOnBoardList()
    }, [])

    const getOnBoardList = () => {
        setLoading(true)
        dispatch(getEntitlementOnBoardList())
        .then((res) => {
            const dat = res?.data
            if(dat) {
                setData({...dat})
                setAllowedUsersValue({editor: dat?.editor, viewer: dat?.viewer})
                setEmpty({
                    viewers: dat?.users?.viewers?.length === 0, 
                    editors: dat?.users?.editors?.length === 0
                })
            }
            setLoading(false)
        })
        .catch((err) => {
            setErrMsg(<ErrorComponent msg={err?.message??'Undefined'} />)
            setLoading(false)
        })
    }

    const handleAllowedUsers = (e, type) => {
        e.stopPropagation()
        setAllowedUsersValue(prev => ({...prev, [type]: e?.target?.value}))
    }
    
    const updateAllowedUsers = (type) => {
        if(!userAccess) {
            handleInfoOpen(true, type)
            setTimeout(() => {
                setInfoOpen({editor: false, viewer: false})
            }, 3000)
            return
        }

        let payload = { [type]: allowedUsersValue[type] }
        
        dispatch(updateUsersOnboard(payload))
        .then(() => {
            getOnBoardList()
        })
        .catch(() => {})
    }

    const handleInfoOpen = (val, type) => {
        setInfoOpen({...infoOpen, [type]: val})
    }

    return (
        <>
        {
            loading ? ''
            :   err
                ??
            <div className='padding-2' style={{height: 'calc(100vh - 70px)'}}>
                <div className='h-100 new-ent-onboard-grid'>
                    <div className='card px-3 py-2 shadow-sm' style={{height: 'calc(100vh - 90px)'}}>
                        <div className='d-flex align-items-start justify-content-between'>
                            <div>
                                <div className='mb-2'>
                                    <span className='mr-2'>
                                        <ICON_EDITBOX height='18' width='18' />
                                    </span>
                                    <span className='fontSizeHeading font-weight-bold'>EDITOR(S)</span>
                                </div>
                                <div>
                                    <p className='mb-0 fontSizeHeading'>License Used <strong>{data?.users?.editors?.length}/{data?.editor}</strong></p>
                                </div>
                            </div>
                            {
                                !userAccess ? ''
                                :
                                <div>
                                    <div className='text-right'>
                                        <label className='fontSizeHeading font-weight-bold'>Allowed User
                                            <Tooltip 
                                                onOpenChange={(v) => handleInfoOpen(v, 'editor')} 
                                                open={infoOpen?.editor} 
                                                placement='top' 
                                                title="Please contact admin to change the number of editors.">
                                                <span className='ml-1'><ICON_INFO_FILLED color='#848484' height='12' width='12' /></span>
                                            </Tooltip>
                                        </label>
                                    </div>
                                    <div className='align-items-center d-flex justify-content-end'>
                                        <input 
                                            placeholder='eg. 10' 
                                            className={`custom-input-field ${userAccess ? '' : 'cursor-not-allowed'}`} 
                                            style={{width: 70}} 
                                            type='number' 
                                            // defaultValue={data?.editor}
                                            value={allowedUsersValue?.editor}
                                            onChange={(e) => userAccess ? handleAllowedUsers(e, 'editor') : null}
                                        />
                                        <Buttons 
                                            props={{
                                                // tooltip: 'Save',
                                                buttonText: userAccess ? 'Save' : 'Edit', 
                                                buttonClassName: "custom-btn-primary ml-2 custom-btn btn-with-text h-100", 
                                                buttonEvent: () => updateAllowedUsers('editor'), 
                                                ImgSrc: () => userAccess ? <></> : <ICON_EDIT/>, 
                                                // isDisable: !userAccess, 
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }} 
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='onboard-usrs-li'>
                            {
                                empty?.editors ? <div className='p-3 text-center text-black-50'>No Editor(s)</div>
                                :
                                data?.users?.editors?.map((u, i) => (
                                    <div key={`editors-${u?.id}-${i}`}>
                                        <UsersGrid data={u} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='card px-3 py-2 shadow-sm' style={{height: 'calc(100vh - 90px)'}}>
                        <div className='d-flex align-items-start justify-content-between'>
                            <div>
                                <div className='mb-2'>
                                    <span className='mr-2'>
                                        <ICON_EYEOPEN height='18' width='18' />
                                    </span>
                                    <span className='fontSizeHeading font-weight-bold'>EXPLORER(S)</span>
                                </div>
                                <div>
                                    <p className='mb-0 fontSizeHeading'>License Used <strong>{data?.users?.viewers?.length}/{data?.viewer}</strong></p>
                                </div>
                            </div>
                            {
                                !userAccess ? ''
                                :
                                <div>
                                    <div className='text-right'>
                                        <label className='fontSizeHeading font-weight-bold'>Allowed User
                                            <Tooltip 
                                                onOpenChange={(v) => handleInfoOpen(v, 'viewer')} 
                                                open={infoOpen?.viewer} 
                                                placement='left' 
                                                title="Please contact admin to change the number of viewers.">
                                                <span className='ml-1'><ICON_INFO_FILLED color='#848484' height='12' width='12' /></span>
                                            </Tooltip>
                                        </label>
                                    </div>
                                    <div className='align-items-center d-flex justify-content-end'>
                                        <input 
                                            placeholder='eg. 10' 
                                            className={`custom-input-field ${userAccess ? '' : 'cursor-not-allowed'}`}
                                            style={{width: 70}} 
                                            type='number' 
                                            // defaultValue={data?.viewer}
                                            value={allowedUsersValue?.viewer}
                                            onChange={(e) => userAccess ? handleAllowedUsers(e, 'viewer') : null}
                                        />
                                        <Buttons 
                                            props={{
                                                // tooltip: 'Save',
                                                buttonText: userAccess ? 'Save' : 'Edit', 
                                                buttonClassName: "custom-btn-primary ml-2 custom-btn btn-with-text h-100", 
                                                buttonEvent: () => updateAllowedUsers('viewer'), 
                                                ImgSrc: () => userAccess ? <></> : <ICON_EDIT/>, 
                                                // isDisable: !userAccess, 
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }} 
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        <div className='onboard-usrs-li'>
                            {
                                empty?.viewers ? <div className='p-3 text-center text-black-50'>No Explorer(s)</div>
                                :
                                data?.users?.viewers?.map((u, i) => (
                                    <div key={`viewers-${u?.id}-${i}`}>
                                        <UsersGrid data={u} />
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default EntitlementOnBoard

const UsersGrid = ({
    data = {}
}) => {

    const user_domain_list = data?.data_domain?.map(v => v?.name?.toUpperCase())

    return (
        <div className='usrs-grid'>
            <div>
                <div className='position-relative'>
                    <span className='mr-1 mt-2 position-absolute'>
                        <ICON_USER height='18' width='20' />
                    </span>
                    <div className='ml-auto' style={{width: 'calc(100% - 30px)'}}>
                        <label className='mb-1 fontSizeLabel font-weight-bold text-capitalize'>
                            {`${data?.first_name} ${data?.last_name}`}
                        </label>
                        <p className='mb-0 fontSizeLabel' style={{wordBreak: 'break-all'}}>{data?.email}</p>
                    </div>
                </div>
            </div>
            {/* <div>
                <div className='position-relative'>
                    <span className='mr-1 mt-2 position-absolute'>
                        <ICON_USERS_GROUP height='20' width='20' />
                    </span>
                    <div className='ml-auto' style={{width: 'calc(100% - 30px)'}}>
                        <label className='mb-1 fontSizeLabel font-weight-bold'>Usergroup</label>
                        <p className='mb-0 fontSizeLabel'>Demo Purpose</p>
                    </div>
                </div>
            </div> */}
            <div>
                <div className='position-relative'>
                    <span className='mr-1 mt-2 position-absolute'>
                        <ICON_CATALOG_DOMAIN height='20' width='20' />
                    </span>
                    <div className='ml-auto' style={{width: 'calc(100% - 30px)'}}>
                        <label className='mb-0 fontSizeLabel font-weight-bold'>Domains</label>
                        <div className='mt-n1'>
                        {/* <span className='text-black-50 font-italic small'>coming soon...</span> */}
                        {
                            user_domain_list?.length === 0 ?  <div style={{padding: '4px 8px', lineHeight: 1}} className="custom-badge text-uppercase text-black-50 small mr-2">
                                N/A
                            </div>
                            :
                            user_domain_list.map((domain, i) => {
                            return (
                            <React.Fragment  key={`conn-domains-ls-${i}`}>
                                {i < 3 ? (
                                <div 
                                    style={{padding: '4px 8px', lineHeight: 1}} 
                                    className="custom-badge text-uppercase small success mr-2">
                                    {domain}
                                </div>
                                ) : ("")}
                                {i === 3 ? (
                                <Tooltip
                                    placement="topLeft"
                                    title={user_domain_list.slice(3).join(", ")}
                                >
                                    <div style={{padding: '4px 8px', lineHeight: 1}} className="custom-badge text-uppercase small success">
                                    +{user_domain_list.length - 3}
                                    </div>
                                </Tooltip>
                                ) : ("")}
                            </React.Fragment>
                            );
                        })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}