import React, { useState } from 'react'
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader'
import { CLOSE, EDIT, LOGS, PLAY, REFRESH, SAVE } from '../../../Common/iconSource'
import { Drawer, InputNumber, Switch, Tooltip } from 'antd'
import Buttons from '../../../Common/button/Buttons'
import { Literals } from '../../common/literals'
import SchedulerBasicDetails from './SchedulerBasicDetails'
import ScheduleModelSelection from './ScheduleModelSelection'
import { useSelector, useStore } from 'react-redux'
import SchedulerNotification from './SchedulerNotification'
import { generateHtmlTitle, getObjectLength } from '../../common/helperFunctions'
import { useNavigate, useParams } from 'react-router-dom'
import SchedulerTriggerSection from './SchedulerTriggerSection'
import { useEffect } from 'react'
import { CreateScheduler, ExecuteScheduler, SetSchedulerPayload, toggleSchedulerStatus } from '../../../../store/modules/scheduler/action'
import { RESET_SCHEDULER_REDUX_, SET_SELECTED_DASHBOARD } from '../../../../store/modules/scheduler/SchedulerActionType'
import { setSelectedPodList } from '../../../../store/modules/common/podSelectionModalAction'
import { SET_FLOWS_ALERT_TRANSFORMER_LIST } from '../../../../store/modules/alert/createAlert/createAlertActiontype'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { cloneDeep } from 'lodash'
import { LastRun } from './LastRun'
import { SchedulerLogs } from './SchedulerLogs'
import ParameterPopup from './ParameterPopup'

import moment from 'moment-timezone';
import { ICON_LOGS } from '../../../Common/newIconSource'
import LoadingComponent from '../../common/loadingComponent'


function SchedulerDetailsNewPage({ isExecutePermission = true ,showLoader,setshowLoader=()=>{}}) {
    const flowsAlertTransformerList = useSelector(state => state.Alert.CreateAlert?.flowsAlertTransformerList);
    const selectedScheduledDashboard = useSelector(state => state.Scheduler?.selectedScheduledDashboard)

    const history = useNavigate();
    const store = useStore();
    const [OpenModal, setOpenModal] = useState(false)
    const { schedulerId } = useParams();
    const [showParameterBtn, setshowParameterBtn] = React.useState(false)

    const [refreshUserList, setrefreshUserList] = React.useState('flow')
    const [dashboardOffsetTime, setdashboardOffsetTime] = React.useState(900)
    const [refreshButtonClick, setrefreshButtonClick] = useState(false)
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)

    React.useEffect(() => {
        let title = generateHtmlTitle('Create', 'Scheduler')
        document.title = title
    }, [])


    function getTimezoneOffsetInMinutes(timezone) {
        const now = moment.tz(timezone);

        return -now?.utcOffset(); // Moment offset is in minutes, and negative means behind UTC
    }

    function expandRanges(cronPart) {
        const parts = cronPart.split(',');
        let expandedParts = [];

        parts.forEach(part => {
            if (part.includes('-')) {
                const [start, end] = part.split('-').map(Number);
                for (let i = start; i <= end; i++) {
                    expandedParts.push(i);
                }
            } else {
                expandedParts.push(part);
            }
        });

        return expandedParts;
    }

    function CronTZConvertor(timezoneFrom, timezoneTo, cronExpression) {
        const [minutes, hours, dayOfMonth, month, dayOfWeek] = cronExpression.split(' ');

        const offsetFrom = getTimezoneOffsetInMinutes(timezoneFrom);
        const offsetTo = getTimezoneOffsetInMinutes(timezoneTo);
        const offsetDifference = offsetTo - offsetFrom;

        // Adjust minutes
        let minutes_individual = expandRanges(minutes);
        minutes_individual.forEach((item, index) => {
            if (item !== "*") {
                minutes_individual[index] = Number(item) + offsetDifference;
            }
        });

        let hour_carry_forward = [];
        minutes_individual.forEach(item => {
            if (item !== "*") {
                hour_carry_forward.push(Math.floor(item / 60));
            }
        });

        minutes_individual.forEach((item, index) => {
            if (item !== "*") {
                minutes_individual[index] = Math.abs(item % 60);
            }
        });
        const minutes_updated = minutes_individual.join(',');

        // Adjust hours
        const offsetinHours = Math.max(...hour_carry_forward);
        let hours_individual = expandRanges(hours);
        hours_individual.forEach((item, index) => {
            if (item !== "*") {
                hours_individual[index] = Number(item) + offsetinHours;
            }
        });

        let day_carry_forward = [];
        hours_individual.forEach(item => {
            if (item !== "*") {
                day_carry_forward.push(Math.floor(item / 24));
            }
        });

        hours_individual.forEach((item, index) => {
            if (item !== "*") {
                hours_individual[index] = Math.abs(item % 24);
            }
        });
        const hours_updated = hours_individual.join(',');

        // Adjust day of the month
        const offsetinDays = Math.max(...day_carry_forward);
        let dayOfMonth_individual = expandRanges(dayOfMonth);
        dayOfMonth_individual.forEach((item, index) => {
            if (item !== "*") {
                dayOfMonth_individual[index] = Number(item) + offsetinDays;
            }
        });

        let month_carry_forward = [];
        dayOfMonth_individual.forEach(item => {
            if (item !== "*") {
                month_carry_forward.push(Math.floor(item / 31)); // Approximation for simplicity
            }
        });

        dayOfMonth_individual.forEach((item, index) => {
            if (item !== "*") {
                dayOfMonth_individual[index] = Math.abs(item % 31);
            }
        });
        const dayOfMonth_updated = dayOfMonth_individual.join(',');

        // Adjust month
        const offsetinMonths = Math.max(...month_carry_forward);
        let month_individual = expandRanges(month);
        month_individual.forEach((item, index) => {
            if (item !== "*") {
                month_individual[index] = Number(item) + offsetinMonths;
            }
        });

        let year_carry_forward = [];
        month_individual.forEach(item => {
            if (item !== "*") {
                year_carry_forward.push(Math.floor(item / 12));
            }
        });

        month_individual.forEach((item, index) => {
            if (item !== "*") {
                month_individual[index] = Math.abs(item % 12);
            }
        });
        const month_updated = month_individual.join(',');

        // Adjust day of the week (considering it doesn't change based on time zone offset in a simple approach)
        let dayOfWeek_individual = expandRanges(dayOfWeek).join(',');

        // Updated cron expression
        const updatedCronExpression = `${minutes_updated} ${hours_updated} ${dayOfMonth_updated} ${month_updated} ${dayOfWeek_individual}`;
        return updatedCronExpression;
    }

    const saveScheduler = () => {

        let payload = store.getState().Scheduler.schedulerPayload;

        if (payload && payload?.name !== '') {
            if (payload.trigger_type.toLowerCase() === 'scheduler' && !payload?.dependant_scheduler) {
                emitToastNotification('info', Literals.PLEASE_SELECT_ONE_SCHEDULER)

            } else if (payload.trigger_type.toLowerCase() === 'pod' && !payload?.pod_ids.length) {
                emitToastNotification('info', Literals.PLEASE_SELECT_ATLEAST_ONE_SCHEDULER)

            } else if (payload['recipients'] && payload['recipients']['non_user_email']?.length && !payload['recipients']['status']?.length) {
                emitToastNotification('info', 'Please select at least one status to notify the user via email.')
            } else {
                if (!getObjectLength(payload['recipients'])) {
                    payload['recipients'] = undefined;
                }
                // payload['recipients']['non_user_email']=[]
                setshowLoader(true)

                store.dispatch(CreateScheduler(window.location.href.includes('scheduler/edit') ? 'PUT' : 'POST', payload, window.location.href.includes('scheduler/edit') ? `scheduler/${schedulerId}/` : 'scheduler/')).then((resp) => {
                    setshowLoader(false)

                    if (resp.status?.toLowerCase() === 'success') {
                        emitToastNotification('success', resp.message)
                        if (window.location.href.includes('scheduler/edit')) {

                            history(Literals.links.SCHEDULER_VIEW + schedulerId, { state: { activeTab: 'alertflows' } })
                        } else if (window.location.href.includes('scheduler/create')) {
                            // history(`${Literals.links.CLARISTA_LAB}#scheduler`)
                            history(`${Literals.links.SCHEDULER_VIEW}${resp.data}?schedulerType=alertflows`, { replace: true })
                        }
                    }
                }).catch((e) => {
                    setshowLoader(false)

                })
            }

        } else if (payload?.name === '' && payload?.type !== 'PARTITION') {
            emitToastNotification('info', 'To create a scheduler please provide - scheduler name, minimum one flow')
        }


    }


    return (

        <div className='scheduler-detail-page-wrapper'>
            <LandingpageFirstHeader title={'Scheduler'} flag={false} backRoute={"/scheduler"} />

            <div className='padding-2 scheduler-sub-header custom-border-bottom'>
                <div className='float-left'>
                    Scheduler details
                </div>

                <div style={{ justifyContent: "flex-end" }} className="sub-header-right d-flex align-items-center">
                    <>
                        {!window?.location?.href?.toLowerCase()?.includes('scheduler/create') ? <>



                            <Buttons props={{
                                tooltip: 'Run',
                                buttonText: ``,
                                buttonClassName: " mr-2 custom-btn-outline btn-only-icon custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    schedulerId && store.dispatch(ExecuteScheduler(schedulerId))
                                },
                                ImgSrc: () => <PLAY />, isDisable: !isExecutePermission, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                            }} />

                            <Buttons props={{
                                tooltip: 'Show Last Run',
                                buttonText: ``,
                                buttonClassName: "custom-btn-outline btn-only-icon custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    schedulerId && setOpenModal(true);
                                    setrefreshButtonClick(true);
                                },
                                ImgSrc: () => <ICON_LOGS />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                            }} />

                            <div className='vertical-separator'></div>
                            <div className='d-flex align-items-center'>
                                <label className='label mr-1 mb-0 gray-color'>Active</label>
                                <Switch
                                    className={`custom-switch-btn`}
                                    checkedChildren="Yes" unCheckedChildren="No"
                                    checked={schedulerPayload['is_active']}
                                    onChange={(isactive) => {

                                        store.dispatch(toggleSchedulerStatus(isactive ? 'GET' : 'DELETE', schedulerId)).then((resp) => {
                                            const newPayload = cloneDeep(schedulerPayload);
                                            newPayload['is_active'] = isactive
                                            store.dispatch(SetSchedulerPayload(newPayload))

                                        }).catch((e) => {
                                            const newPayload = cloneDeep(schedulerPayload);
                                            newPayload['is_active'] = false
                                            store.dispatch(SetSchedulerPayload(newPayload))
                                        })


                                    }}
                                    disabled={!isExecutePermission}
                                />

                            </div>



                        </> : null}

                        <div className='vertical-separator'></div>
                        <Buttons props={{
                            tooltip: window?.location?.href?.toLowerCase()?.includes('scheduler/view') ? `Edit` : `Save`,
                            buttonText: window?.location?.href?.toLowerCase()?.includes('scheduler/view') ? `Edit` : `Save`,
                            buttonClassName: "mr-2 custom-btn-primary custom-btn btn-with-text",
                            buttonEvent: () => {
                                if (schedulerId && window?.location?.href?.toLowerCase()?.includes('scheduler/view')) {

                                    history(`${Literals.links.SCHEDULER_EDIT}${schedulerId}?schedulerType=alertflows`);
                                } else {

                                    saveScheduler();
                                }

                            },
                            ImgSrc: () => window?.location?.href?.toLowerCase()?.includes('scheduler/view') ? <EDIT /> : <SAVE />, isDisable: !isExecutePermission, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }} />


                        <Buttons props={{
                            tooltip: 'Close',
                            buttonText: '', buttonClassName: "custom-btn-outline mr-1 custom-btn btn-with-icon", buttonEvent: () => {

                                store.dispatch({ type: RESET_SCHEDULER_REDUX_, payload: {} })
                                store.dispatch(setSelectedPodList([]));
                                store.dispatch({ type: SET_FLOWS_ALERT_TRANSFORMER_LIST, payload: {} })
                                store.dispatch({ type: SET_SELECTED_DASHBOARD, payload: {} })

                                history(`${Literals.links.CLARISTA_LAB}#scheduler`);
                            }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                        }} />

                    </>

                </div>
            </div>
            <div className='scheduler-basic-details-section d-flex mt-2 align-items-center'>
                <div className='col-5'>
                    <SchedulerBasicDetails isEditor={isExecutePermission} />

                </div>
                <div className={showParameterBtn ? 'col-6 m-0 p-0' : 'col-7 m-0 p-0'}>

                    <ScheduleModelSelection setrefreshUserList={setrefreshUserList} isExecutePermission={isExecutePermission} setshowParameterBtn={setshowParameterBtn} />
                </div>
                <div className={showParameterBtn ? 'col-1 p-0 ml-2' : 'col-0'}>
                    <ParameterPopup flowsAlertTransformerList={flowsAlertTransformerList} showParameterBtn={showParameterBtn} setshowParameterBtn={setshowParameterBtn} isExecutePermission={isExecutePermission} />
                </div>
            </div>
            <div className='custom-dashed-border-bottom notification-section-flex d-flex justify-content-between  w-100 p-3'>
                {((getObjectLength(flowsAlertTransformerList) && flowsAlertTransformerList?.selectedFlowDetails?.id) || (getObjectLength(selectedScheduledDashboard) && selectedScheduledDashboard?.selectedDashboardDetails?.id)) ? <div className='notification-section'>
                    <SchedulerNotification refreshUserList={refreshUserList} dashboardId={selectedScheduledDashboard?.selectedDashboardDetails?.id} flowId={flowsAlertTransformerList?.selectedFlowDetails?.id} isExecutePermission={isExecutePermission} />
                </div> : null}

                {(schedulerPayload['task_type']?.toLowerCase() === 'dashboard' && (getObjectLength(selectedScheduledDashboard) && selectedScheduledDashboard?.selectedDashboardDetails?.id)) ? <div className='col-4 p-0 m-0'>
                    <label className='mt-0 mb-0 label'>Offset Time (In seconds)</label>
                    <div>
                        <InputNumber
                            keyboard={true}
                            value={schedulerPayload['offset'] ?? dashboardOffsetTime}
                            onChange={(e) => {

                                setdashboardOffsetTime(e)
                                let newPayload = cloneDeep(schedulerPayload);
                                newPayload['offset'] = e
                                store.dispatch(SetSchedulerPayload(newPayload))
                            }}
                            disabled={window?.location?.href?.toLowerCase().includes('scheduler/view')}
                            step={10}
                            min={60}
                            className='moniter-time  w-100 p-0'
                        />
                    </div>
                </div> : null}

            </div>
            <div style={{ height: getObjectLength(flowsAlertTransformerList) && flowsAlertTransformerList?.selectedFlowDetails?.id ? `calc(100vh - 336px)` : `calc(100vh - 210px)` }} className='trigger-section w-100 p-3'>
                <SchedulerTriggerSection />
            </div>

            <Drawer
                title={<h2 className="section-heading">{'Scheduler Logs'}</h2>}
                placement={'right'}
                size='large'
                onClose={() => setOpenModal(false)}
                open={OpenModal}
                closable={false}
                extra={
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <Buttons
                            props={{
                                tooltip: "Refresh Logs",

                                buttonText: "",
                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
                                buttonEvent: () => {
                                    setrefreshButtonClick(true)
                                },
                                ImgSrc: () => <span className={`${refreshButtonClick ? 'bx-spin' : ''} d-flex`}><REFRESH /></span>,
                                isDisable: refreshButtonClick,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                tooltipPlacement: 'left'

                            }}
                        />
                        <Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />

                    </div>
                }

            >
                <SchedulerLogs refreshButtonClick={refreshButtonClick} setRefreshButtonClick={setrefreshButtonClick} />
            </Drawer>

         
        </div>

    )
}

export default SchedulerDetailsNewPage