import React from 'react'
import { ICON_INFO_FILLED } from '../../../Common/newIconSource'

const style = {
    'background': '#FFEAEA',
    'display': 'flex',
    'alignItems': 'center',
    'padding': '5px 10px 5px 8px',
    'borderRadius': '22px',
    'fontSize': '12px',
    'color': '#333333',
    'lineHeight': '1.4'
}

const ContainsPII = () => {
    return (
        <div style={style} className='fontInterSemiBold'>
            <ICON_INFO_FILLED height='14' width='14' color='#B70404' />
            <span className='pl-1'></span>
            PII
        </div>
    )
}

export default ContainsPII