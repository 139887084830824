import React, { useEffect } from 'react'
import LandingPagePODGrid from '../../modules/dataCatalogue/landingPage/components/LandigPagePODGrid'
import { useDispatch, useSelector } from 'react-redux'
import { getConnectionListV2, getDomainTags, getLandingPageData, getTagList, setAvailableTags, setTagList } from '../../../store/actions'
import ClaristaLoader from '../claristaLoader/ClaristaLoader'
import { NoDataComponent, getReloadErrorTemplate } from '../../modules/common/helperFunctions'
import './../../modules/dataCatalogue/landingPage/landingPage.scss'
import ConnectionContext from '../../modules/connections/landingPage/components/ConnectionContext'
import CreateButton from '../../modules/dataCatalogue/landingPage/components/CreateButton'
import { ICON_CATALOG } from '../newIconSource'
import NoSearchResultFound from '../../modules/common/NoSearchResultFound'
import _, { cloneDeep } from 'lodash'
import { getGlobalConnectionCache } from '../../../store/modules/UserManagement/connection/connectionActions'
import { Collapse } from 'antd'
import { TAG } from '../iconSource'

const CatalogGridIndex = ({
    activeDomain = '',
    searchTerm = '',
    showFavourite = false,
    setFilterList = () => { },
    filterList = {},
    showPublished = false,
    fromRoute = '',
    onRowSelection = () => { },
    groupByTags = false,
    wrapperRef = {},
    setDataLoading = () => {}
}) => {

    const dispatch = useDispatch()
    const tagData = useSelector(state => state.DataCatalogue?.LandingPage?.tagList)

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [empty, setEmpty] = React.useState(false)
    const [data, setData] = React.useState([])
    let selectedPodId = useSelector(state => state.CommonComponent.PodSelectionModalReducer?.podList?.table_id)

    const [connectionList, setConnectionList] = React.useState([])
    const [availableTags, setAvailableTags] = React.useState([])
    const [activeCollKeys, setActiveCollKeys] = React.useState([])

    const connectionContext = React.useContext(ConnectionContext)

    const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false

    React.useEffect(() => {
        connectionContext.setContextSearchValue(searchTerm)
    }, [searchTerm])

    React.useEffect(() => {
        if (activeDomain !== '') {
            getData()
            callTagListAPI()
            getConnectionList()
        }
    }, [activeDomain])

    React.useEffect(() => {
        if (filterList?.connections?.length === 0 || filterList?.tags?.length === 0) {
            setFilterList(
                {
                    tags: availableTags?.map(c => ({ name: c?.name?.toLowerCase(), selected: false })),
                    connections: connectionList?.map(c => ({ name: c?.name?.toLowerCase(), selected: false })),
                    types: [
                        {name: 'structured', selected: false},
                        {name: 'unstructured', selected: false}
                    ]
                }
            )
        }
    }, [connectionList, availableTags])

    React.useEffect(() => {
        if (tagData && tagData?.length) {
            setAvailableTags([...tagData])
            if (availableTags?.length !== 0) {
                setFilterList(prev =>
                ({
                    ...prev,
                    tags: tagData?.map(c => ({ name: c?.name?.toLowerCase(), selected: false }))
                })
                )
            }
        }
    }, [tagData])

    const dataListObj = React.useMemo(() => {
        if(
            _.isEmpty(searchTerm) && !showFavourite && !showPublished 
            && !filterList?.connections?.some(c => c?.selected)
            && !filterList?.tags?.some(c => c?.selected)
            && !filterList?.types?.some(c => c?.selected)
        ) {
            setActiveCollKeys([])
        }

        const FILTER_COLUMNS = ["table_name", "pod_description", "updated_by_user", "tag"]
        const clone = [...data]
        let dat = clone?.filter(item => {
            return FILTER_COLUMNS?.some(key => {
                let data = item[key]
                if (key === 'updated_by_user') {
                    data = item[key]?.email
                }
                if (key === 'tag') {
                    data = item[key]?.map(v => v?.name)?.join(', ')
                }
                return data?.toLowerCase()?.includes(searchTerm.toLowerCase())
            })
        })
        if (showFavourite) {
            dat = dat?.filter(d => d?.bookmark)
        }
        if (showPublished) {
            dat = dat?.filter(d => d?.published)
        }
        if (filterList) {
            const selConns = filterList?.connections?.filter(c => c?.selected)?.map(v => v?.name === 'file upload' ? 'upload' : v?.name)
            if (selConns?.length > 0) {
                dat = dat?.filter(c => selConns.includes(c?.source_type?.toLowerCase()))
            }
            const selTags = filterList?.tags?.filter(c => c?.selected)?.map(v => v?.name)
            if (selTags?.length > 0) {
                dat = dat?.filter(c => c?.tag?.some(t => selTags?.includes(t?.name?.toLowerCase())))
            }
            const selTypes = filterList?.types?.filter(c => c?.selected)?.map(v => v?.name)
            if (selTypes?.length > 0) {
                dat = dat?.filter(c => selTypes?.includes(
                    c?.is_unstructured ? 'unstructured' : 'structured'
                ))
            }
        }
        if (fromRoute === 'podselection') {
            let elementIndex = dat?.findIndex(d => selectedPodId && d?.table_id === selectedPodId);
            if(elementIndex > -1){
                let newData = cloneDeep(dat);
                newData.splice(elementIndex, 1);
                newData.unshift(dat[elementIndex]);
    
                dat = cloneDeep(newData)
            }
           
        }

        let tags = new Set([...dat?.map(d => d?.tag)?.flat()])
        // let tags = _.uniqBy([...dat?.map(d => d?.tag)?.flat()], 'name')
        let tagWisePod = [...tags]?.reduce((prev, curr) => {
            return {
                ...prev,
                [curr?.name]: {
                    data: dat?.filter(d => d?.tag?.map(t => t?.name)?.includes(curr?.name)),
                    tagDetail: {...curr}
                }
            }
        }, {})

        let tagsPod = []
        for (const key in tagWisePod) {
            const element = tagWisePod[key];
            tagsPod.push({
                title: key,
                tagDetail: element?.tagDetail,
                data: element?.data
            })
        }

        // console.log({tags, tagWisePod, tagsPod})

        if(
            !_.isEmpty(searchTerm) || showFavourite || showPublished 
            || filterList?.connections?.some(c => c?.selected)
            || filterList?.tags?.some(c => c?.selected)
            || filterList?.types?.some(c => c?.selected)
        ) {
            setActiveCollKeys(tagsPod?.map(d => d?.title))
        }

        return {tagsPod, allData: dat}
    }, [searchTerm, data, showFavourite, filterList, showPublished])

    useEffect(() => {
        dispatch(getGlobalConnectionCache())
    }, [])

    const getData = () => {
        if (activeDomain) {
            setEmpty(false)
            setLoading(true)
            setDataLoading(true)
            let prevData = [...data]
            setData([])
            dispatch(getLandingPageData(false, false, false, activeDomain))
                .then(res => {

                    const data = res?.data ?? []
                    setEmpty(data?.length === 0)
                    setLoading(false)
                    setDataLoading(false)
                    setData([...data])
                    setError(undefined)

                })
                .catch(err => {
                    setEmpty(false)
                    setLoading(false)
                    setDataLoading(false)
                    setError(err?.message)
                    setData([...prevData])
                })
        }
    }

    const callTagListAPI = () => {
        // if (tagData?.length > 0) return

        dispatch(getDomainTags(activeDomain))
        .then(res => {
            const data = res?.data ?? []
            dispatch(setTagList([...data]))
        })
        .catch(() => {})
        // dispatch(getTagList())
    };

    const getConnectionList = () => {
        dispatch(getConnectionListV2(false))
            .then(res => {
                const data = res?.data
                if (data?.length > 0) {
                    const c_list = [...data?.map(v => ({ name: v?.source_type }))]

                    setConnectionList([
                        { name: 'file upload' },
                        { name: 'pod of pods' },
                        ..._.uniqBy(c_list, 'name')
                    ])
                }
            })
            .catch(err => console.error(err))
    };

    const checkNoRecordFound = () => {
        return (
            showFavourite
            || searchTerm?.length > 0
            || filterList?.connections?.filter(v => v?.selected)?.length > 0
            || filterList?.tags?.filter(v => v?.selected)?.length > 0
        ) && dataListObj?.allData?.length === 0
    }

    return (
        <div className={`landingPage ${!noOpenAiAccess ? 'float-talkdata-present' : ''}`} ref={wrapperRef}>
            <div className='landing-list-main p-0'>

                {
                    loading ? <ClaristaLoader height='200px' />
                        :
                        error ? getReloadErrorTemplate({ errorMessage: error, onReload: () => getData() })
                            :
                            empty ? <NoDataComponent logo={<ICON_CATALOG />} message={"Click on (+) to Create POD"} />
                                :
                                checkNoRecordFound() ? <NoSearchResultFound  fromRoute={fromRoute}/>
                                    :
                                    groupByTags
                                    ?
                                    <>

                                        <Collapse 
                                            key={`catalog-grid-collapse-${activeCollKeys?.length}`}
                                            className='catalog-grid-collapse'
                                            collapsible='header'
                                            ghost={true}
                                            // defaultActiveKey={
                                            //     // tagData?.map(t => t?.name)
                                            //     dataListObj?.tagsPod?.map(t => t?.title)
                                            // }
                                            // activeKey={dataListObj?.tagsPod?.map(t => t?.title)}
                                            defaultActiveKey={
                                                fromRoute ? dataListObj?.tagsPod?.map(t => t?.title)
                                                :
                                                activeCollKeys
                                            }
                                            items={
                                                dataListObj?.tagsPod?.map(t => ({
                                                    key: t?.title,
                                                    label: <>
                                                        <div className='round-tags-container nav-landing'>
                                                            <div className={`${t?.tagDetail?.color} round-tags-item`}>
                                                                <span>{<TAG />}</span>
                                                                <span>{t?.title}</span>
                                                            </div>
                                                        </div>
                                                    </>,
                                                    children: <div className='landinggridview-responsive p-0'>
                                                        {
                                                            t?.data?.map((d, i) => (
                                                                <LandingPagePODGrid
                                                                    key={`${d?.table_id}-pod-cat-tag-agg`}
                                                                    podDetails={d}
                                                                    onRowSelection={onRowSelection}
                                                                    fromRoute={fromRoute}
                                                                    refreshData={getData}
                                                                    index={i}
                                                                    setData={setData}
                                                                    groupByTags={groupByTags}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                }))
                                            } 
                                        />
                                        {/* {
                                            dataListObj?.tagsPod?.map((t, i) => (
                                                <div key={`tgPod-${i}`}>
                                                    <div className='bg-light px-3 py-2 fontInterSemiBold rounded border mb-2 fontSizeHeading'>
                                                        {t?.title}
                                                    </div>
                                                    <div className='landinggridview-responsive p-0'>
                                                        {
                                                            t?.data?.map((d, i) => (
                                                                <LandingPagePODGrid
                                                                    key={`${d?.table_id}-pod-cat`}
                                                                    podDetails={d}
                                                                    onRowSelection={onRowSelection}
                                                                    fromRoute={fromRoute}
                                                                    refreshData={getData}
                                                                    index={i}
                                                                    setData={setData}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        } */}
                                        {
                                            dataListObj?.allData?.filter(d => d?.tag?.length === 0)?.length !== 0
                                            ?
                                            <>
                                                <div className='bg-light px-3 py-2 fontInterSemiBold rounded border mb-2 fontSizeHeading'>
                                                    {`Untagged`}
                                                </div>
                                                <div className='landinggridview-responsive p-0'>
                                                    {
                                                        dataListObj?.allData?.filter(d => d?.tag?.length === 0)?.map((d, i) => (
                                                            <LandingPagePODGrid
                                                                key={`${d?.table_id}-pod-cat`}
                                                                podDetails={d}
                                                                onRowSelection={onRowSelection}
                                                                fromRoute={fromRoute}
                                                                refreshData={getData}
                                                                index={i}
                                                                setData={setData}
                                                            />
                                                        ))
                                                    }
                                                </div>
                                            </>
                                            :   ''
                                        }
                                    </>
                                    :
                                    <div className='landinggridview-responsive p-0'>
                                        {
                                            dataListObj?.allData?.map((d, i) => (
                                                <LandingPagePODGrid
                                                    key={`${d?.table_id}-pod-cat`}
                                                    podDetails={d}
                                                    onRowSelection={onRowSelection}
                                                    fromRoute={fromRoute}
                                                    refreshData={getData}
                                                    index={i}
                                                    setData={setData}
                                                />
                                            ))
                                        }
                                    </div>
                }
            </div>
            {fromRoute !== 'podselection' ? <CreateButton /> : null}
        </div>
    )
}

export default CatalogGridIndex