import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useStore } from "react-redux";
import { setSearchMode, setChatGpt } from "../../../../../store/modules/dataCatalogue/preview/previewActions";
import {
  setFilteringColumns,
  setHidingColumns,
  setSortingColumns,
} from "../../../../../store/modules/common/DataGridTable/dataGridTableRedux";
import {
  setPreviewDataMap,
  setPreviewTableQuery,
} from "../../../../../store/modules/dataCatalogue/preview/previewActions";
import Buttons from "../../../../Common/button/Buttons";
import {
  ACCESSMANAGMENT,
  ACCESSMANAGMENTCATALOG,
  AUDIT,
  CATALOGUEDICTIONARY,
  CATALOGPROFILE,
  CATALOGUEPREVIEW,
  CLOSE,
  DATAPODICON,
  LINEAGE_ICON,
  ANALYTICS,
  CLARISTA_LOGO_DARK,
  CLARISTA_LOGO,
  CHATGPT_ICON,
} from "../../../../Common/iconSource";
import { useSelector } from "react-redux";
import { Literals } from "../../../common/literals";
import ClaristaLoader from "../../../../Common/claristaLoader/ClaristaLoader";
import "./CatalogueSecondaryHeader.scss";
import SearchIcon from "../../../../Common/searchIcon/SearchIcon";
import { Tooltip } from "antd";
// import CheckableTag from "antd/es/tag/CheckableTag";
import { publishPod } from "../../../../../store/modules/common/GenAi/GenAiReducer";
import { toast } from "react-toastify";
import { ICON_CLARISTA } from "../../../../Common/newIconSource";

const lostMessage = "Unsaved changes will be lost. Proceed?"
const appliedMessage = "This action will reset all applied filters. \nDo you want to proceed?"
const uploadPendingMessage = "File upload is in progress!\nDo you really want to proceed?"

const CatalogueSecondaryHeader = ({
  showPage,
  pageName,
  setCurrentMenu,
  isEditPermission = true,
  podData,
  getDictionaryTableData,
  isUploadInProgress = false
}) => {
  const history = useNavigate();
  const location = useLocation();
  const store = useStore();
  const [activeTab, setActiveTab] = useState("Audit");
  const { podId } = useParams();
  const searchParams = new URLSearchParams(document.location.search);
  const history_id = searchParams.get("history_id");
  const searchMode = useSelector(
    (state) => state.DataCatalogue.PreviewPage.searchMode
  );
  const isChatgpt = useSelector(
    (state) => state.DataCatalogue.PreviewPage.isChatgpt
  );

  const [navigationTabs, setNavigationTabs] = useState([
    {
      icon: <CATALOGUEDICTIONARY />,
      title: "Dictionary",
      TabTitle: "Dictionary",
      callback: () => {
        showPage("Dictionary");
        setCurrentMenu("Dictionary");
      },
    },
    {
      icon: <CATALOGUEPREVIEW />,
      title: "Preview",
      TabTitle: "Explore",

      callback: () => {
        showPage("Preview");
        setCurrentMenu("Preview");
      },
    },
    {
      icon: <ANALYTICS />,
      title: "Charts",
      TabTitle: "Charts",

      callback: () => {
        showPage("Charts");
        setCurrentMenu("Charts");
      },
    },
    // {
    //   icon: <CATALOGPROFILE />,
    //   title: "Profile",
    //   callback: () => {
    //     showPage("Profile");
    //     setCurrentMenu("Profile");
    //   },
    // },
    {
      icon: <ACCESSMANAGMENTCATALOG />,
      title: "Access",
      TabTitle: "Access",

      callback: () => {
        showPage("AccessManagement");
        setCurrentMenu("AccessManagement");
      },
    },
    {
      icon: <AUDIT />,
      title: "Audit",
      TabTitle: "Audit",

      callback: () => {
        showPage("Audit");
        setCurrentMenu("Audit");
      },
    },
    {
      icon: <DATAPODICON />,
      title: "Source",
      TabTitle: "Source",

      callback: () => {
        showPage("Source");
        setCurrentMenu("Source");
      },
    },
  ]);

  const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

  const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false

  useEffect(() => {
    setNavigationTabs((prev) => {
      prev = prev.map((el) => ({
        ...el,
        disabled: el.title === "Source" ? !isEditPermission : false,
      }));
      return prev;
    });
  }, [isEditPermission]);

  useEffect(() => {
    if (history_id) {
      setNavigationTabs((prev) => {
        return prev.filter((v) => v.title.toLowerCase() !== "preview");
      });
    }
  }, [history_id]);

  const onTabClickHandler = (active_tab, callback) => {

    if(activeTab === active_tab) {
      return
    }

    let query = store.getState().DataCatalogue?.PreviewPage?.sqlEditorQuery;
    if (query && query?.trim().length > 0) {
      if (!window.confirm(lostMessage)) {
        return;
      }
    }
    if (isAnyFilterApplied()) {
      if (
        !window.confirm(appliedMessage)
      ) {
        return;
      }
    }
    if (isUploadInProgress) {
      if (
        !window.confirm(uploadPendingMessage)
      ) {
        return;
      }
    }
    store.dispatch(setSearchMode(false));
    store.dispatch(setPreviewDataMap({}));
    store.dispatch(setSortingColumns([]));
    store.dispatch(setFilteringColumns([]));
    store.dispatch(setHidingColumns([]));
    store.dispatch(setPreviewTableQuery(""));
    setActiveTab(active_tab);
    if (callback) {
      callback();
    }
  };

  const isAnyFilterApplied = () => {
    let sort = store.getState().CommonComponent?.DataGridTable?.sortArray;
    let filter = store.getState().CommonComponent?.DataGridTable?.filterArray;
    let hideFields =
      store.getState().CommonComponent?.DataGridTable?.hiddenFieldsArray;

    return sort.length > 0 || filter.length > 0 || hideFields.length > 0;
  };

  const onCloseTabHandler = () => {
    history(Literals.links.CLARISTA_NAVIGATOR + '#pod');
  };

  useEffect(() => {
    if (location?.state?.activeTab?.includes("Access")) {
      setActiveTab("Access");
      onTabClickHandler("Access", () => {
        showPage("AccessManagement");
        setCurrentMenu("AccessManagement");
      });
    }
  }, []);

  useEffect(() => {
    if (pageName.includes("Access")) {
      setActiveTab("Access");
      onTabClickHandler("Access", () => {
        showPage("AccessManagement");
        setCurrentMenu("AccessManagement");
      });
    } else {
      setActiveTab(pageName);
    }
  }, [pageName]);


  // const publish = async(podId, isPublish) => {
  //   // toast.promise(
  //   //   new Promise(async (resolve, reject) => {
  //       try {
  //         let res = await store.dispatch(publishPod(isPublish ? 'GET' : 'DELETE', podId));

  //         getDictionaryTableData()

  //       }
  //       catch {

  //       }
  //     // })
  //     // ,
  //     // {
  //     //   pending: `Publishing "${podData?.table_name}" please wait...`,
  //     //   success: publishResponse?.current?.message,
  //     //   error: `Failed to publish "${podData?.table_name}"`,
  //     // },
  //     // {
  //     //   position: 'bottom-right',
  //     //   autoClose: 5000,
  //     // }
  //   // )
  // }

  return (
    <div className="catalogue-subheader padding-right-2 padding-left-2">
      <nav className="catalogue-navigation">
        <ul id="catalogTablist" className="catalogue-list">
          {navigationTabs.map((tab, i) => (
            <li
              key={`tb-of-${tab.title}-${i}`}
              id={`tb-of-${tab.title}-${i}`}
              className={`${tab?.disabled ? "tab-dis" : ""} catalogue-item ${
                activeTab === tab.title ? "active" : ""
              }`}
              onClick={() =>
                tab?.disabled
                  ? null
                  : onTabClickHandler(tab.title, tab.callback)
              }
            >
              <span className="catalogue-icon">{tab.icon}</span>
              <span className="catalogue-title">{tab.TabTitle}</span>
            </li>
          ))}
        </ul>
        <div className="d-flex align-items-center">
          {/* <CheckableTag
            key={'tag'}
            checked={podData?.published}
            color={podData?.published ? '#d3d3d3' : '#2c8348'}
            onChange={(checked) => {
              publish(podId, checked)
            }}
          >
            {podData?.published ? 'Published' : 'Draft'}
          </CheckableTag> */}



          {activeTab == "Preview" ? (
            <>
              {(!searchMode || (searchMode && isChatgpt)) ? <> <div onClick={() => {
                store.dispatch(setSearchMode(true));
                store.dispatch(setChatGpt(false));
              }} className="d-flex align-items-center mr-2 cursor-pointer" style={{ gap: "10px" }}>
                {/* <SearchIcon/> */}

              </div>
                {/* <hr className="seperator ml-2" /> */}
              </> : ""
              }
              {/* {(!searchMode || (searchMode && !isChatgpt)) ? 
                  noOpenAiAccess ? ''
                  :
                  <>
                  <Buttons
                    props={{
                        buttonId: 'gptBtn',
                        buttonText: '',
                        tooltip: 'Ask Clarista',
                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
                        tooltipPlacement: 'top',
                        buttonEvent: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let query = store.getState().DataCatalogue?.PreviewPage?.sqlEditorQuery;
                            if (query && query?.trim().length > 0) {
                              if (!window.confirm(lostMessage)) {
                                return;
                              }
                            }
                            if (isAnyFilterApplied()) {
                              if (
                                !window.confirm(appliedMessage)
                              ) {
                                return;
                              }
                            }
                            store.dispatch(setSearchMode(true));
                            store.dispatch(setChatGpt(true));
                        },
                        ImgSrc: () => <ICON_CLARISTA />,
                        isDisable: false,
                        buttonType: Literals.BTN_TERTIARY
                    }}
                />
                <hr className="seperator ml-2" />

              </> : ""} */}
            </>
          ) : (
            ""
          )}

          <Buttons
            props={{
              buttonId: 'dictLineageBtn',
              tooltipPlacement: "left",

              tooltip: `Show Lineage`,
              buttonText: "",
              buttonClassName:
                "custom-btn-outline mr-1 custom-btn btn-with-icon",
              buttonEvent: () => {
                let path = `/viewLineage/pod/${podId}`;

                window.open(path);
              },
              ImgSrc: () => <LINEAGE_ICON />,

              buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
            }}
          />
          <hr className="seperator" />
          <Buttons
            props={{
              tooltip: "Close",
              buttonText: "",
              tooltipPlacement: "left",
              buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
              buttonEvent: () => {
                onCloseTabHandler();
              },
              ImgSrc: () => <CLOSE />,
              isDisable: false,
              buttonType: Literals.BTN_TERTIARY,
            }}
          />
        </div>
      </nav>
    </div>
  );
};

export default CatalogueSecondaryHeader;
