import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactEcharts from 'echarts-for-react';
import macarons from '../genAi/components/echartsThemeMacarons.json';
import LandingpageFirstHeader from '../../modules/common/LandingpageFirstHeader'

import './Clusters.scss'
import { ICON_ANALYZE, ICON_GUIDE, ICON_REFRESH } from '../../Common/newIconSource'
import { setSidebarActiveRoute } from '../../../store/actions'
import SearchInput from '../../Common/search/Search'
import { DatePicker, Drawer, Popover, Switch, Tooltip } from 'antd'
import { CLARISTA_LOGO, CLOSE, CREDITCLUSTER, DOWNLOAD, EYECLOSE, EYEOPEN, FILTER, GUIDEICON, NOTEINFO, PLUS, REFRESH, RESETFILTER, SAVE } from '../../Common/iconSource'
import { Literals } from '../common/literals'
import Buttons from '../../Common/button/Buttons'
import { CoreTableList } from './CoreTableList'
import { NotebookClusterList } from './NotebookClusterList'
import CLUSTER_CREDIT from '../../../assets/images/cluster-credit.png';

import GraphView from '../dataDictionary/components/GraphView'
import { HistoryTable } from './HistoryTable'
import CreateCluster from './CreateCluster'
import { autoShutdownApi, getClusterList, getClusterTypeList, getCreditAmountBalance, getCreditHistory, getCreditUsageChart, pinClusterAction, saveCreditValue, startClusterAction, stopClusterAction } from '../../../store/modules/cluster/Cluster'
import LoadingComponent from '../common/loadingComponent'
import { add, cloneDeep } from 'lodash'
import { downloadTableDataToCSV, getConfirmationModal, getObjectLength, showSimpleLoading } from '../common/helperFunctions';
import SingleSelection from '../../Common/dropDown/SingleSelection';
import NoResultsFound from '../flowsNew/createFlows/NoResultsFound';
import ScheduleCluster from './ScheduleCluster';
import { emitToastNotification } from '../../../helpers/toast_helper';
import dayjs from 'dayjs';
import moment from 'moment';
import NoGraphDataFound from './NoGraphDataFound';

export const ClusterIndex = () => {

    const dispatch = useDispatch()
    const [searchValue, setsearchValue] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openScheduleModal, setopenScheduleModal] = useState(false);
    const [coreClussterList, setcoreClussterList] = useState([]);
    const [notebookClussterList, setnotebookClussterList] = useState([]);
    const [isRefreshing, setisRefreshing] = useState(false)
    const [showStopped, setshowStopped] = useState(false)
    const [creditAmount, setcreditAmount] = useState(0)
    const [addcreditAmount, setaddcreditAmount] = useState()
    const [creditAmountDisable, setcreditAmountDisable] = useState(false)
    const [historyList, sethistoryList] = useState([])
    const [usageGraphData, setusageGraphData] = useState({})
    const [showdefaultGraph, setshowdefaultGraph] = useState(false)
    const [showFilterPop, setFilterPop] = useState(false);
    const [showFilterPopCommon, setFilterPopCommon] = useState(false);
    const [transaction_type, settransaction_type] = useState(null);
    const [selectedCluster, setselectedCluster] = useState(null);
    const [clusterOptionList, setclusterOptionList] = useState([]);
    const [autoShutdownValue, setautoShutdownValue] = useState(5);

    const [duration, setduration] = useState('monthly');
    const [plan_type, setplan_type] = useState(null);
    const [showLoader, setshowLoader] = useState(false);
    const [showBalanceLoader, setshowBalanceLoader] = useState(false);

    const [selectedScheduleItem, setselectedScheduleItem] = useState();
    const [end_date, setend_date] = useState(null);
    const [showWarningPopup, setshowWarningPopup] = useState(false);


    const LandingpageFirstHeaderRef = useRef(null)
    const Loading = useSelector(state => state.LoadingReducer.loadingStatus);

    const autoShutdownList = [{ Name: 'Never', value: 'Never' },
    { Name: '5', value: 5 },
    { Name: '10', value: 10 },
    { Name: '15', value: 15 },
    { Name: '20', value: 20 },
    { Name: '30', value: 30 }
    ]

    useEffect(() => {
        setshowLoader(true)
        dispatch(setSidebarActiveRoute('/clusters'))
        getClusterListApi(showStopped);
        // getCreditDetails();
        setshowBalanceLoader(true)
        dispatch(getCreditAmountBalance()).then((resp) => {
            setshowLoader(false)
            setshowBalanceLoader(false)
            if (resp?.data) {
                setcreditAmount(resp?.data?.amount)
            }
        }).catch((err) => setshowBalanceLoader(false))
    }, [])


    const onFilterChange = (transactionType, planType, Duration, plot_graph = null, clusterId, endDate) => {
        setshowLoader(true)
        sethistoryList([]);
        dispatch(getCreditHistory(Duration, clusterId, endDate)).then((resp) => {
            setshowLoader(false)
            setisRefreshing(false)

            if (resp?.data?.length) {

                let cloneHistory = cloneDeep(resp.data);
                if (transactionType && planType) {

                    sethistoryList(cloneHistory?.filter(item => item?.transaction_type?.toLowerCase() === transactionType?.toLowerCase() && item?.cluster_detail?.plan?.toLowerCase() === planType?.toLowerCase()))
                } else if (transactionType) {
                    sethistoryList(cloneHistory?.filter(item => item?.transaction_type?.toLowerCase() === transactionType?.toLowerCase()))
                } else if (planType) {

                    sethistoryList(cloneHistory?.filter(item => item?.cluster_type.toLowerCase() === planType?.toLowerCase()))
                } else {

                    sethistoryList(cloneHistory);
                }
                setshowLoader(false)

            } else {
                sethistoryList([])
            }
        }).catch(() => {
            setshowLoader(false)
            setisRefreshing(false)
            sethistoryList([])

        })
        if (plot_graph && plot_graph === 'plot_graph') {
            dispatch(getCreditUsageChart(Duration, clusterId, endDate)).then((resp) => {
                setshowLoader(false)
                setisRefreshing(false)


                let graphData = cloneDeep(resp.data);
                graphData['legend']['top'] = '280px';
                graphData['series'][0]['type'] = "line";
                graphData['tooltip'] = {
                    trigger: 'axis',
                }

                if (!graphData?.xAxis?.data?.length) {
                    setshowdefaultGraph(true)
                } else {
                    setshowdefaultGraph(false)

                    setusageGraphData(graphData)
                }
            }).catch((e) => {
                setshowLoader(false)
                setisRefreshing(false)

            })
        }

    }

    const getCreditDetails = () => {
        dispatch(getClusterList(true)).then((resp) => {
            if (resp.data.length) {
                let data = resp?.data;
                let clusterList = data.map(item => ({ Name: item.name, value: item.id }))
                setclusterOptionList(clusterList)
                setselectedCluster(clusterList[0].id)
            }
        })
        dispatch(getCreditHistory()).then((resp) => {
            setshowLoader(false)

            if (resp?.data?.length) {
                sethistoryList(resp.data);

            } else {
                sethistoryList([])

            }
        })
        setshowBalanceLoader(true)
        dispatch(getCreditAmountBalance()).then((resp) => {
            setshowLoader(false)
            setshowBalanceLoader(false)
            if (resp?.data) {
                setcreditAmount(resp?.data?.amount)
            }
        })
        dispatch(getCreditUsageChart()).then((resp) => {
            setshowLoader(false)

            let graphData = cloneDeep(resp.data);
            graphData['legend']['top'] = '280px';
            graphData['series'][0]['type'] = "line";
            graphData['tooltip'] = {
                trigger: 'axis',
            }
            if (!graphData?.xAxis?.data?.length) {
                setshowdefaultGraph(true)
            } else {
                setshowdefaultGraph(false)

                setusageGraphData(graphData)
            }

        })
    }
    const getClusterListApi = (showStopped) => {

        dispatch(getClusterTypeList('core')).then((resp) => {
            if (resp?.data?.length) {
                setcoreClussterList(resp.data);
                setisRefreshing(false)
                // if (LandingpageFirstHeaderRef?.current) {

                //     LandingpageFirstHeaderRef?.current?.refreshClusterNotification()
                // }


            }
        })
        dispatch(autoShutdownApi('GET', {})).then((resp) => {
            if (resp.data) {
                setautoShutdownValue(resp.data)
            } else if (resp.data === null) {
                setautoShutdownValue('Never')

            } else {
                setautoShutdownValue(5)

            }
        })
        dispatch(getClusterTypeList('notebook')).then((resp) => {
            if (resp?.data?.length) {
                setnotebookClussterList(resp.data);
                setisRefreshing(false)
                // if (LandingpageFirstHeaderRef?.current) {

                //     LandingpageFirstHeaderRef?.current?.refreshClusterNotification()
                // }


            }
        })
    }

    const startCluster = (item) => {
        dispatch(startClusterAction(item.id)).then((resp) => {
            if (resp.status === 'success') {
                getClusterListApi(showStopped) //added showStopped status
            }
        })
    }
    const stopCluster = (item) => {

        dispatch(stopClusterAction(item.id)).then((resp) => {
            if (resp.status === 'success') {
                getClusterListApi(showStopped) //added showStopped status
            }
        })
    }
    const pinCluster = (item) => {
        dispatch(pinClusterAction(item.id, item?.pinned)).then((resp) => {
            if (resp.status === 'success') {
                getClusterListApi(showStopped) //added showStopped status
            }
        })
    }
    const scheduleCluster = (item) => {
        setselectedScheduleItem(item)
        setopenScheduleModal(true)
    }

    const getFilterClusterList = (list) => {
        if (searchValue !== '') {
            let clone = cloneDeep(list)
            let filteredData = clone.filter((data) => {
                return (
                    data?.quantity?.toString()?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                    data?.capacity?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                    data?.state?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                    data?.size?.toLowerCase().includes(searchValue?.toLowerCase())

                )
            })
            return filteredData

        } else {
            return list;
        }
    }


    function humanizeNumber(number) {
        let number1 = Math.abs(number)
        function getStringFormat() {
            if (number1 >= 10000000) {
                return (number1 / 10000000).toFixed(2) + "C"; // Crores
            } else if (number1 >= 1000000) {
                return (number1 / 1000000).toFixed(2) + "M"; // Millions
            } else if (number1 >= 1000) {
                return (number1 / 1000).toFixed(2) + "K"; // Thousands
            } else {
                return number1.toFixed(2); // Less than a thousand
            }
        }
        let humanizedString = getStringFormat();


        if (number < 0) {
            humanizedString = "-" + humanizedString;
        }

        return humanizedString



    }

    useEffect(() => {
        if (openModal) {
            setshowLoader(true)
            setduration('monthly')
            settransaction_type(null)
            setplan_type(null)
            setcreditAmount('')
            setcreditAmountDisable(false);
            getCreditDetails();
        }
    }, [openModal])

    const getCurrentWalletBalance = () => {
        setshowBalanceLoader(true)
        dispatch(getCreditAmountBalance()).then((resp) => {
            setshowBalanceLoader(false)
            setshowLoader(false)

            if (resp?.data) {
                emitToastNotification('success', 'Balance updated successfully.')
                setcreditAmount(resp?.data?.amount);
                // if (LandingpageFirstHeaderRef?.current) {

                //     LandingpageFirstHeaderRef?.current?.refreshClusterNotification()
                // }
            }
        })
    }

    const creditUsageGraph = useMemo(() => {
        return showdefaultGraph ? <NoGraphDataFound height="272px" imgheight='50' imgwidth='50' /> : <ReactEcharts className='credit-usage-graph' style={{ height: "272px", width: document.getElementById('usage-graph-div')?.clientWidth ? `${(document.getElementById('usage-graph-div')?.clientWidth + 100)}px` : "850px" }} option={usageGraphData} theme={
            {
                ...macarons,
                color: [
                    '#2078BA',
                    '#C1DB36',
                    '#542F99',
                    '#E9B93C',
                    '#7EE9F6',
                    '#42BA72',
                    '#114E7A',
                    '#FFDB7E',
                    '#CBE9F6',
                    '#51545E'

                ]
            }}
        />

    }
        , [usageGraphData, showdefaultGraph])

    const creditAmountEnter = useRef()
    const resetAllFilter = () => {
        setshowLoader(true)
        settransaction_type(null);
        setplan_type(null);
        // setduration('monthly')
        onFilterChange(null, null, duration, '', end_date)
    }
    const commonLevelfilterPopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Filters</p>
                    <div className="d-flex">

                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    setFilterPopCommon(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                                tooltipPlacement: 'left'
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div className='row mx-n1 pb-3' style={{ width: '324px' }}>

                    {/* <div className='col-12 my-3'>
                        <div>
                            <SingleSelection
                                props={{
                                    label: "Select Cluster",
                                    placeholder: "Select Cluster",
                                    dropDownValue: selectedCluster,
                                    allowClear: true,
                                    onClickEvent: (value) => { setselectedCluster(value); setshowLoader(true); onFilterChange(transaction_type, plan_type, duration, 'plot_graph', value, end_date); },
                                    optionList: clusterOptionList,
                                    isDisable: false,
                                }}
                            />

                        </div>

                    </div> */}

                    <div className='col-12 cursor-pointer'>
                        <SingleSelection
                            props={{
                                label: "Select Duration",
                                placeholder: "Select Transactions Limit",
                                dropDownValue: duration,
                                isSortOptionDisable: true,
                                onClickEvent: (value) => {
                                    setduration(value);
                                    setshowLoader(true);
                                    onFilterChange(transaction_type, plan_type, value, 'plot_graph', selectedCluster, end_date)

                                },
                                allowClear: false,
                                optionList: [{ Name: 'Hourly', value: 'hourly' },
                                { Name: 'Daily', value: 'daily' },
                                { Name: 'Monthly', value: 'monthly' },
                                { Name: 'Yearly', value: 'yearly' }],
                                isDisable: false,
                            }}
                        />

                    </div>
                    <div className='col-12 mt-2 cursor-pointer'>
                        <label className="label"> Select Date </label>
                        <DatePicker
                            picker="date"
                            placeholder="Please select date"
                            className={
                                !end_date
                                    ? "custom-input-field "
                                    : "custom-input-field"
                            }
                            disabledDate={(current) => current && (current > moment().endOf('day'))}
                            value={end_date ? dayjs(end_date, "YYYY-MM-DD") : null}
                            format="YYYY-MM-DD"
                            onChange={(e, value) => {

                                // //let value=e.target.value
                                // let value = val;
                                // setend_date(value)
                                setend_date(value)
                                setshowLoader(true);
                                onFilterChange(transaction_type, plan_type, duration, 'plot_graph', selectedCluster, value, end_date)
                            }}
                        />

                    </div>
                </div>
            </>
        }
    }
    const filterPopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between card-padding padding-2">
                    <p className="section-heading m-0">Filters</p>
                    <div className="d-flex">
                        <Buttons
                            props={{
                                tooltip: "Reset Filters",
                                buttonText: "",
                                buttonClassName: "custom-btn-outline error-btn custom-btn btn-with-icon mr-2",
                                buttonEvent: () => {
                                    resetAllFilter()
                                },
                                ImgSrc: () => <RESETFILTER />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                            }}
                        />
                        <Buttons
                            props={{
                                buttonText: "",
                                buttonClassName:
                                    "custom-btn-outline custom-btn btn-with-icon",
                                buttonEvent: () => {
                                    setFilterPop(false);
                                },
                                ImgSrc: () => <CLOSE />,
                                isDisable: false,
                                buttonType: Literals.BTN_TERTIARY,
                                tooltipPlacement: 'left'
                            }}
                        />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div className='row mx-n1 pb-3' style={{ width: '324px' }}>
                    <div className='col-12 my-3'>
                        <div>
                            <SingleSelection
                                props={{
                                    label: "",
                                    placeholder: "Select Amount Type",
                                    dropDownValue: transaction_type,
                                    allowClear: false,
                                    onClickEvent: (value) => { settransaction_type(value); setshowLoader(true); onFilterChange(value, plan_type, duration, null, selectedCluster, end_date); },
                                    optionList: [{ Name: 'Credited', value: 'CREDIT' }, { Name: 'Debited', value: 'DEBIT' }],
                                    isDisable: false,
                                }}
                            />

                        </div>

                    </div>

                    <div className='col-12 mb-3'>
                        <div>
                            <SingleSelection
                                props={{
                                    label: "",
                                    placeholder: "Select Cluster Type",
                                    dropDownValue: plan_type,
                                    onClickEvent: (value) => {
                                        setplan_type(value);
                                        setshowLoader(true);
                                        onFilterChange(transaction_type, value, duration, null, selectedCluster, end_date)
                                        // if (value !== '') {
                                        //     dispatch(getCreditHistory(value)).then((resp) => {
                                        //         setshowLoader(false)

                                        //         if (resp?.data?.length) {

                                        //             sethistoryList(resp.data);
                                        //             setshowLoader(false)
                                        //         } else {
                                        //             sethistoryList([])
                                        //         }
                                        //     })
                                        // }
                                    },
                                    allowClear: true,
                                    optionList: [{ Name: 'NOTEBOOK', value: 'NOTEBOOK' },
                                    { Name: 'CORE', value: 'CORE' },
                                    ],
                                    isDisable: false,
                                }}
                            />

                        </div>
                    </div>

                </div>
            </>
        }
    }

    const downloadCSV = () => {
        let clone = [...historyList] ?? [];
        clone = clone?.map((d) => ({
            ...d,
            user: getObjectLength(d?.user_detail) ? `${d?.user_detail?.first_name} ${d?.user_detail?.last_name}` : "N/A" //added NA for if user not found
        }));

        const csvString = [
            [
                "Usage Date/Time",
                "Cluster Type",
                "User",
                "Total Cluster Size",
                "Run Time",
                "Coins(CC)"
            ],
            ...clone.map((item) => [
                item.timestamp,
                item.cluster_type,
                item.user,
                item.cluster_size,
                item.human_runtime,
                item.amount
            ]),
        ]
            .map((e) => e.join(",").replace(/(\r\n|\n|\r)/gm, " "))
            .join("\n");

        downloadTableDataToCSV(csvString, 'Cluster Usage')
    }


    return (
        <>
            <div className='clusters-wrapper'>
                <LandingpageFirstHeader ref={LandingpageFirstHeaderRef} title={'Clusters'} flag={false} backRoute={"/clusters"} />
                <ScheduleCluster openModal={openScheduleModal} showStopped={showStopped} getClusterListApi={getClusterListApi} selectedScheduleItem={selectedScheduleItem} setOpenModal={setopenScheduleModal} />

                <CreateCluster openModal={openCreateModal} showStopped={showStopped} getClusterListApi={getClusterListApi} setOpenModal={setOpenCreateModal} />

                <div className='padding-2' style={{ height: "calc(100vh - 69px)" }}>
                    {/* <div className='float-left'>    <Buttons props={{
                        buttonText: 'Create Cluster',
                        buttonClassName: "custom-btn-primary custom-btn  btn-with-text ",  //fixed-button addbutton_plusicon
                        buttonEvent: () => {
                            setOpenCreateModal(true)
                        },
                        tooltip: 'Create Cluster',
                        ImgSrc: () => <PLUS />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }} /></div> */}

                    <div style={{ justifyContent: "flex-start" }} className="sub-header-right d-flex align-items-center credit-btn">
                        <>
                            <Buttons props={{
                                tooltip: showBalanceLoader ? 'Loading...' : creditAmount !== null ? `CC ${Math.round(creditAmount)?.toFixed(2)}` : '',
                                buttonText: showBalanceLoader ? 'Loading...' : creditAmount !== null ? `CC ${humanizeNumber(creditAmount)}` : '',
                                buttonClassName: `ml-1 custom-btn-primary animate-btn custom-btn btn-with-text ${openModal ? 'hide-button' : ''}`,
                                buttonEvent: () => { setOpenModal(true); },
                                ImgSrc: () => { }, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                            }} />


                        </>

                    </div>
                    <div className='tables mt-2'>
                        <div className='clusters-tables'>
                            <div className='dict-title-strip'>
                                <div className='d-flex align-items-center w-100 justify-content-between'>
                                    <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold'>
                                        <CLARISTA_LOGO height={15} width={15} /> <span className='ml-2'>Clarista Core</span>
                                    </label>
                                    <div className='d-flex justify-content-end align-items-center'>

                                        <div className={`${autoShutdownValue === 'Never' ? 'justify-content-end'
                                            : ''
                                            } row align-items-center col-8 p-0 m-0`}>
                                            {autoShutdownValue !== 'Never' ? <div className='paragraph fw-600 mr-2'>
                                                <b>Auto shuts down after</b>
                                            </div> : ''}
                                            <SingleSelection
                                                props={{
                                                    label: "",
                                                    placeholder: "",
                                                    selectClassName: "col-3 p-0 m-0",
                                                    dropDownValue: autoShutdownValue,
                                                    allowClear: false,
                                                    onClickEvent: (value) => {
                                                        setshowWarningPopup(true);
                                                        setautoShutdownValue(value);

                                                    },
                                                    isSortOptionDisable: true,
                                                    optionList: autoShutdownList,
                                                    isDisable: false,
                                                }}
                                            />
                                            {autoShutdownValue !== 'Never' ? <span className='sub-msg ml-2 '>minutes of inactivity</span> : <span className='sub-msg ml-2 mr-0 fw-400 '>auto shut down the cluster</span>}

                                        </div>
                                        <div className='col-4 p-0 ml-2'>

                                            <Tooltip placement="topLeft" title={'Search Cluster'}><SearchInput searchData={searchValue} setSearchData={setsearchValue} /></Tooltip>
                                        </div>

                                        {/* <Buttons
                                            props={{
                                                tooltip: showStopped ? 'Hide stopped clusters' : 'Show stopped clusters',
                                                buttonText: "",
                                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2",
                                                buttonEvent: () => {
                                                    setshowStopped(!showStopped);

                                                    setTimeout(() => {
                                                        getClusterListApi(!showStopped)
                                                    }, 500);
                                                },
                                                ImgSrc: () => showStopped ? <EYEOPEN /> : <EYECLOSE />,
                                                isDisable: isRefreshing,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                tooltipPlacement: 'left'

                                            }}
                                        /> */}
                                        <Buttons
                                            props={{
                                                tooltip: "Refresh Logs",
                                                buttonText: "",
                                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2",
                                                buttonEvent: () => {
                                                    setisRefreshing(true)
                                                    getClusterListApi(showStopped)
                                                },
                                                ImgSrc: () => <span className={`${isRefreshing ? 'bx-spin' : ''} d-flex`}><REFRESH /></span>,
                                                isDisable: isRefreshing,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                tooltipPlacement: 'left'

                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                            <CoreTableList showStopped={showStopped} clusterList={getFilterClusterList(coreClussterList)} setClusterList={setcoreClussterList} startCluster={startCluster} isEditPermission={true} stopCluster={stopCluster} getClusterListApi={getClusterListApi} scheduleCluster={scheduleCluster} pinCluster={pinCluster} />

                        </div>
                        <div className='notebook-cluster-table '>
                            <div className='dict-title-strip'>
                                <div className='d-flex align-items-center w-100 justify-content-between'>
                                    <label className='mb-0 d-flex section-heading-dark align-items-center font-weight-bold'>
                                        <ICON_GUIDE color="#275a89" height={15} width={15} /><span className='ml-2'> Notebook Cluster</span>
                                    </label>
                                    <div className='d-flex justify-content-end align-items-center'>

                                        <div className='label fw-600 mr-1'>
                                            <i><b>Auto shuts down after 15 mins of python kernel inactivity.</b></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <NotebookClusterList clusterList={notebookClussterList} setCluterList={setnotebookClussterList} getClusterListApi={getClusterListApi} ></NotebookClusterList>

                        </div>

                        <Drawer
                            title={<h2 className="section-heading"></h2>}
                            placement={'right'}
                            width={'80vw'}
                            id={'credit-usage-modal'}
                            className='credit-modal'
                            onClose={() => setOpenModal(false)}
                            open={openModal}
                            closable={false}
                            extra={
                                <div className="d-flex align-items-center justify-content-end w-100 m-0">
                                    <Buttons
                                        props={{
                                            tooltip: "Refresh Credit Usage",
                                            buttonText: "",
                                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
                                            buttonEvent: () => {
                                                setisRefreshing(true)
                                                setshowLoader(true)
                                                settransaction_type(null);
                                                setplan_type(null);
                                                setselectedCluster(null)
                                                setend_date(null)
                                                // setduration('monthly')
                                                getCurrentWalletBalance()
                                                onFilterChange(null, null, duration, 'plot_graph', null, null)
                                            },
                                            ImgSrc: () => <span className={`${isRefreshing ? 'bx-spin' : ''} d-flex`}><REFRESH /></span>,
                                            isDisable: isRefreshing,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                            tooltipPlacement: 'left'

                                        }}
                                    />
                                    <Buttons props={{ buttonText: 'Done', buttonClassName: "custom-btn-outline custom-btn", buttonEvent: (e) => { setOpenModal(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />

                                </div>
                            }



                        >
                            <div className='d-flex justify-content-center align-items-start'>
                                <div className='credits col-4 p-0'>
                                    <h6 className='fw-bolder InterBold'><b>Credits</b></h6>
                                    <div className='credit-card mt-5' >
                                        <img className='credit-img' src={CLUSTER_CREDIT}></img>
                                        <div className='total-credit'>Clarista Credits  <Tooltip title="Refresh Balance"><span className='ml-1 cursor-pointer' onClick={() => { getCurrentWalletBalance() }}><ICON_REFRESH color="#c0e8ff" width='20' height='20' ></ICON_REFRESH></span></Tooltip></div>
                                        <div className='credit-amount mt-1 d-flex align-items-center'><Tooltip title={Number(creditAmount)?.toFixed(2)}> <span className=''>CC {humanizeNumber(creditAmount)}</span></Tooltip> </div>
                                        {JSON.parse(localStorage?.getItem('user'))?.userDetails?.is_goduser ? < div > {!creditAmountDisable ? <div className='add-credit ml-2' onClick={() => { setcreditAmountDisable(true) }}>Add Credits</div> :
                                            <div className='d-flex align-item-center justify-content-left flex-column mt-3 edit-credit-amount'> <input
                                                type="number"
                                                id="add-credit-input"
                                                placeholder='Enter credit'
                                                onKeyDown={(e) => e.which === 189 || e.which === 109 || e.which === 107 || e.which === 187 || e.which === 69 ? e.preventDefault() : true}
                                                onChange={(e) => {
                                                    creditAmountEnter.current = e.target.value;
                                                    setaddcreditAmount(e.target.value)

                                                }}
                                                min={1}
                                                value={addcreditAmount}
                                                autoFocus
                                                className={'custom-search-input trans-input pl-2'}

                                            />

                                                <Buttons props={{ buttonText: 'Add', buttonClassName: "mt-3 ml-1 custom-btn-primary   custom-btn btn-with-text", buttonEvent: (e) => { dispatch(saveCreditValue({ amount: creditAmountEnter.current }, true)).then((resp) => { setcreditAmountDisable(false); getCurrentWalletBalance() }) }, ImgSrc: () => <PLUS color='#fff' width='15px' height='15px' />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />

                                            </div>}
                                        </div> : null}
                                    </div>
                                </div>
                                <div className='usage-chart m-0 p-0 col-8'>

                                    <div className='m-0 p-0 d-flex align-items-center justify-content-between'>
                                        <h6 className='fw-bolder InterBold'><b>Usage Chart</b></h6>
                                        <Popover placement="bottomLeft"
                                            title={commonLevelfilterPopover.header}
                                            content={commonLevelfilterPopover.body}
                                            trigger="click"
                                            autoAdjustOverflow={true}
                                            open={showFilterPopCommon}
                                            onOpenChange={(value) => { setFilterPopCommon(value) }}
                                        >
                                            <Tooltip placement='bottom' title='Filter'>
                                                <button className={`${showFilterPopCommon ? "custom-btn-primary" : "custom-btn-outline"
                                                    } custom-btn btn-with-icon  ${(
                                                        duration || selectedCluster ? 'rdg-filter-active' : ''
                                                    )}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setFilterPopCommon(true)
                                                    }
                                                    }
                                                >
                                                    <FILTER />
                                                </button>
                                            </Tooltip>
                                        </Popover>

                                    </div>
                                    <div id='usage-graph-div'>
                                        {creditUsageGraph}
                                    </div>

                                </div>

                            </div>

                            <div className='d-flex mb-3 justify-content-between align-items-center'>
                                <h6 className='fw-bolder InterBold'><b>Usage History</b></h6>
                                <div className='d-inline-flex'>
                                    <Buttons
                                        props={{
                                            buttonId: 'dictDownloadBtn',
                                            tooltip: "Download",
                                            buttonText: "",
                                            buttonClassName:
                                                "custom-btn-outline custom-btn btn-with-icon icon-color refresh-btn mr-2",
                                            buttonEvent: downloadCSV,
                                            ImgSrc: () => <DOWNLOAD />,
                                            // isDisable: !isEditPermission,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                    <Popover placement="bottomLeft"
                                        title={filterPopover.header}
                                        content={filterPopover.body}
                                        trigger="click"
                                        autoAdjustOverflow={true}
                                        open={showFilterPop}
                                        onOpenChange={(value) => { setFilterPop(value) }}
                                    >
                                        <Tooltip placement='bottom' title='Filter'>
                                            <button className={`${showFilterPop ? "custom-btn-primary" : "custom-btn-outline"
                                                } custom-btn btn-with-icon  ${(
                                                    transaction_type || plan_type ? 'rdg-filter-active' : ''
                                                )}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setFilterPop(true)
                                                }
                                                }
                                            >
                                                <FILTER />
                                            </button>
                                        </Tooltip>
                                    </Popover>
                                </div>
                            </div>
                            <div className='history m-0 p-0  w-100'>

                                {showLoader ? showSimpleLoading('Please wait data loading') : historyList?.length ?
                                    <HistoryTable clusterList={historyList} /> :

                                    !historyList?.length ? <div className='d-flex align-items-center w-100 h-50 justify-content-center '>
                                        <NoResultsFound imgheight='100' imgwidth="100"></NoResultsFound> </div> : null
                                }
                            </div>
                        </Drawer>


                    </div>
                </div >

            </div >
            {Loading ? <LoadingComponent /> : null}
            {getConfirmationModal({
                open: showWarningPopup,
                setOpen: setshowWarningPopup,
                onConfirm: () => {
                    dispatch(autoShutdownApi('POST', { "auto_shutdown": autoShutdownValue === 'Never' ? null : autoShutdownValue }))
                },
                onCancel: () => {
                    setautoShutdownValue(5)
                },

                heading: 'Warning',
                hideButton: false,
                body: autoShutdownValue === 'Never' ? <div>Are you sure, you want to keep the cluster always running?</div> : <div>Are you sure, you want to auto shutdown the cluster in <b>{autoShutdownValue}</b>  minutes?</div>
            })}
        </>
    )
}
