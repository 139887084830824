import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { CLOSE, MERGE, SELECT, } from '../../../../Common/iconSource'
import Buttons from '../../../../Common/button/Buttons'
import { Literals } from '../../../common/literals'
import { LandingSimpleTable, LandingSimpleTableInner } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import { cloneDeep } from 'lodash';
import { Tooltip } from 'antd';


const FileOptionsHelpLinkModal = ({ openModal, onClose, handleSelect, currentSelected }) => {
    let CNTRY_CD = [201, 202, 203, 204, 205,]
    let CLM_NO = [63129464, 63141876, 63150836, 63156014, 63157116,]
    let CDE_DMG_TYP = [73, 73, 81, 53, 73,]
    let CLMS_D_REP = ['27 / 11 / 2020', '30 / 11 / 2020', '30 / 11 / 2020', '30 / 11 / 2020', '27 / 11 / 2020']
    let CLMS_TOTAL_COST = [22392, 22324, 8656, 43228, 56769]
    let BAUM = [447813, 177084, 247615, 205000, 212006]
    let TEXT = ["/Renew the cover on the right rear bumper/", "Dismantle right side panel for rear bumper/ assemble/ ", "Servicing right side panel for rear bumper", "Renew left cover on rear bumper /bumper removed/", "/Renew rear left bumper shell bumper removed/"]

    let columns = [
        {
            name: 'CNTRY_CD',
            key: "CNTRY_CD",
            sortable: false,
            resizable: true,
            width: 'auto'
        }
        ,
        {
            name: 'CLM_NO',
            key: "CLM_NO",
            sortable: false,
            resizable: true,
            width: 'auto'
        },
        {
            name: 'CDE_DMG_TYP',
            key: "CDE_DMG_TYP",
            sortable: false,
            resizable: false,
            width: 'auto'

        },
        {
            name: 'BAUM',
            key: "BAUM",
            sortable: false,
            resizable: false,
            width: 'auto'

        },
        {
            name: 'CLMS_D_REP',
            key: "CLMS_D_REP",
            sortable: false,
            resizable: false,
            width: 'auto'

        },
        {
            name: 'CLMS_TOTAL_COST',
            key: "CLMS_TOTAL_COST",
            sortable: false,
            resizable: false,
            width: 'auto'

        },

    ]
    let sampleResultData = Array.from(Array(5).keys()).map((item, i) => {
        return {
            CNTRY_CD: CNTRY_CD[i],
            CLM_NO: CLM_NO[i],
            CDE_DMG_TYP: CDE_DMG_TYP[i],
            BAUM: BAUM[i],
            CLMS_D_REP: CLMS_D_REP[i],
            CLMS_TOTAL_COST: CLMS_TOTAL_COST[i]
        }

    })
    let newcol = cloneDeep(columns)
    newcol.splice(1, 1, {
        name: 'TEXT',
        key: "TEXT",
        sortable: false,
        resizable: false,
        width: 100
    })

    let newcol2 = cloneDeep(columns)
    newcol2.splice(1, 1,  {
        name: 'TEXT',
        key: "TEXT",
        sortable: false,
        resizable: false,
        width: 100


    },)

    const getInfographic = (type) => {
        switch (type) {
            case 'delimeter':
                return <div>
                    <p className='paragraph helplink'>Delimiter splits the data fields/columns in your file. Example - Delimiter = ;</p>
                    <p style={{ fontSize: "11px" }} className='paragraph helplink'>CNTRY_CD;CLM_NO.;CDE_DMG_TYP;BAUM;CLMS_D_REP;CLMS_TOTAL_COST
                        <br></br>
                        201;63129464;73;447813;2020-11-27;22392
                        <br></br>

                        202;63141876;73;177084;2020-11-30;22324
                        <br></br>

                        203;63150836;81;247615;2020-11-30;8656
                        <br></br>

                        204;63156014;53;205000;2020-11-30;43228
                        <br></br>

                        205;63157116;73;212006;2020-11-27;56769	</p>

                    <p style={{ fontWeight: "bold" }} className='paragraph helplink'> Expected Result</p>
                    <div style={{ height: "150px" }}>
                        <LandingSimpleTableInner
                            rowsData={sampleResultData}
                            cols={columns}
                            rowHeight={25}
                            tableHeight={'100%'}
                            showCursor={false}
                        />
                    </div>
                </div>
                break;
            case 'headercount':
                return <div>
                    <p className='paragraph helplink'>Header value defines the row from the top of your file that represents column headers,<br />
                        with first row indexed as Row 0.  <br></br> <strong>Example - Header= 2"</strong>
                    </p>
                    <p style={{ fontSize: "11px" }} className='paragraph helplink'>
                        *** &nbsp;&nbsp; ********* &nbsp;&nbsp; ** &nbsp;&nbsp; ******* &nbsp;&nbsp; ********** &nbsp;&nbsp; **********
                        <br></br>
                        *** &nbsp;&nbsp; ********* &nbsp;&nbsp; ** &nbsp;&nbsp; ******* &nbsp;&nbsp; ********** &nbsp;&nbsp; **********</p>
                    <div style={{ height: "150px" }}>
                        <LandingSimpleTableInner
                            rowsData={cloneDeep(sampleResultData).slice(2, sampleResultData.length)}
                            cols={columns}
                            rowHeight={25}
                            tableHeight={'100%'}
                            showCursor={false}
                        />
                    </div>
                    <p style={{ fontWeight: "bold" }} className='paragraph helplink '> Expected Result</p>
                    <div style={{ height: "150px" }}>
                        <LandingSimpleTableInner
                            rowsData={cloneDeep(sampleResultData).slice(2, sampleResultData.length)}
                            cols={columns}
                            rowHeight={25}
                            tableHeight={'100%'}
                            showCursor={false}
                        />
                    </div>
                </div>
            case 'skiprows':
                return <div>
                    <p className='paragraph helplink'>"Skip Rows defines the number of rows after the Header row that need to be skipped
                        <br></br> <strong>Example - Skip Rows = 2"</strong>

                    </p>

                    <div style={{ height: "150px" }}>
                        <LandingSimpleTableInner
                            rowsData={sampleResultData}
                            cols={columns}
                            rowHeight={25}
                            tableHeight={'100%'}
                            showCursor={false}
                        />
                    </div>
                    <p style={{ fontWeight: "bold" }} className='paragraph helplink     '> Expected Result</p>
                    <div style={{ height: "150px" }}>
                        <LandingSimpleTableInner
                            rowsData={cloneDeep(sampleResultData).slice(2, sampleResultData.length)}
                            cols={columns}
                            rowHeight={25}
                            tableHeight={'100%'}
                            showCursor={false}
                        />
                    </div>
                </div>
            case 'escapechar':

                let clonesampleResultData = Array.from(Array(6).keys()).map((item, i) => {
                    return {
                        CNTRY_CD: CNTRY_CD[i],
                        CLM_NO: CLM_NO[i],
                        CDE_DMG_TYP: CDE_DMG_TYP[i],
                        BAUM: BAUM[i],
                        CLMS_D_REP: CLMS_D_REP[i],
                        CLMS_TOTAL_COST: CLMS_TOTAL_COST[i],
                        TEXT: <Tooltip title={TEXT[i]} placement='topLeft'>{TEXT[i]}</Tooltip>,
                        __TEXT: TEXT[i],
                    }

                })
                let expectedResultData = cloneDeep(clonesampleResultData).map((item) => {
                    if (item.__TEXT) {
                        item.__TEXT = item?.__TEXT?.replace(/[^\w\s]/gi, '')
                        item.__TEXT = <Tooltip title={item.__TEXT} placement='topLeft'>{item.__TEXT}</Tooltip>
                    }
                    return item
                })
                return <div>
                    <p className='paragraph helplink'>"Escape Character is skipped from all values in the file.


                        <br></br> <strong>Example - Escape Char = /"	</strong>

                    </p>

                    <div style={{ height: "150px" }}>
                        <LandingSimpleTableInner
                            rowsData={clonesampleResultData}
                            cols={newcol}
                            rowHeight={25}
                            tableHeight={'100%'}
                            showCursor={false}
                        />
                    </div>
                    <p style={{ fontWeight: "bold" }} className='paragraph helplink mb-0 mt-2'> Expected Result</p>
                    <div className='esc_exp_res_table' style={{ height: "150px" }}>
                        <LandingSimpleTableInner
                            rowsData={expectedResultData}
                            cols={newcol}
                            rowHeight={25}
                            tableHeight={'100%'}
                            showCursor={false}
                        />
                    </div>
                </div>
            case 'quotechar':

                let clonesampleResultData2 = [{

                    CNTRY_CD: 201,

                    CDE_DMG_TYP: 73,
                    TEXT: <Tooltip title="Please; keep my text" placement='topLeft'>Please; keep my text.</Tooltip>,
                    BAUM: 447813,
                    CLMS_D_REP: '27 / 11 / 2020',
                    CLMS_TOTAL_COST: 22392,


                }]

                return <div>
                    <p className='paragraph helplink'>""Text between Qote Chracters is retained as such.
                        <br></br> <strong>Example - Quote Char = "" and Delimiter = ;"	</strong>

                    </p>

                    <p className='paragraph'>
                        CNTRY_CD;CLM_TXT.;CDE_DMG_TYP;BAUM;CLMS_D_REP;CLMS_TOTAL_COST
                        201;"Please; keep my text.";73;447813;2020-11-27;22392
                    </p>
                    <p style={{ fontWeight: "bold" }} className='paragraph helplink'> Expected Result</p>
                    <div style={{ height: "150px" }}>
                        <LandingSimpleTableInner
                            rowsData={clonesampleResultData2}
                            cols={newcol2}
                            rowHeight={25}
                            tableHeight={'100%'}
                            showCursor={false}
                        />
                    </div>
                </div>
            case 'skipfooter':

                return <div>
                    <p className='paragraph helplink'>Skip Footer defines the number of rows from the bottom of the file that need to be skipped.

                        <br></br> <strong> Example - Skip Footer = 3	</strong>

                    </p>

                    <div style={{ height: "150px" }}>
                        <LandingSimpleTableInner
                            rowsData={sampleResultData}
                            cols={columns}
                            rowHeight={25}
                            tableHeight={'100%'}
                            showCursor={false}
                        />
                    </div>
                    <p style={{ fontWeight: "bold" }} className='paragraph helplink     '> Expected Result</p>
                    <div style={{ height: "150px" }}>
                        <LandingSimpleTableInner
                            rowsData={cloneDeep(sampleResultData).slice(0, 2)}
                            cols={columns}
                            rowHeight={25}
                            tableHeight={'100%'}
                            showCursor={false}
                        />
                    </div>
                </div>
            default:
                break;
        }
    }
    const [infoGraphic, setInfoGraphic] = useState([{
        id: 1,
        name: 'Delimeter',
        infoGraphic: getInfographic('delimeter')
    }])

    let FILE_OPTIONS = [{
        id: 1,
        name: 'Delimeter',
        infoGraphic: getInfographic('delimeter')
    }, {
        id: 2,
        name: 'Header Count',
        infoGraphic: getInfographic('headercount')
    }, {
        id: 3,
        name: 'Escape Char',
        infoGraphic: getInfographic('escapechar')

    }, {
        id: 4,
        name: 'Quote Char',
        infoGraphic: getInfographic('quotechar')
    }, {
        id: 5,
        name: 'Skip Footer',
        infoGraphic: getInfographic('skipfooter')

    }, {
        id: 6,
        name: 'Skip Rows',
        infoGraphic: getInfographic('skiprows')

    },]




    return (
        <Modal size="lg" isOpen={openModal} toggle={() => onClose(false)} className="custom-modal" centered={true}>
            <ModalHeader>
                <div className="d-flex align-items-center">

                    <h2 className="section-heading"></h2>
                </div>
                <div className="tab-menu right-flow-header">
                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => { onClose(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />
                </div>
            </ModalHeader>
            <ModalBody className="p-0">
                <div className="merge-join-content">
                    <div style={{ width: "20%" }} className="join-list custom-border-right">
                        {
                            FILE_OPTIONS.map(
                                item => {
                                    const { id, Img, name, InfoGraphic } = item
                                    return (
                                        <React.Fragment key={id}>
                                            <div
                                                onClick={() => {
                                                    setInfoGraphic([item])
                                                }}
                                                className={`join-list-item ${infoGraphic[0].id === id ? `selected-list-item` : ``}`}
                                            >
                                                {/* <Img /> */}
                                                <p className="label mb-0">{name}</p>
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            )
                        }
                    </div>
                    <div style={{ width: "80%" }} className="infographic">
                        {
                            infoGraphic.map(
                                item => {
                                    const { id, Img, name, infoGraphic } = item
                                    return (infoGraphic)
                                }
                            )
                        }
                    </div>
                </div>
            </ModalBody>

        </Modal>
    )
}

export default FileOptionsHelpLinkModal