import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { NoDataComponent, getQueryParams, getReloadErrorTemplate } from '../../common/helperFunctions'
import { getDataDomainList, setDataDomainList, setUserSelectedDomain } from '../../../../store/actions'
import { Checkbox, Input, Popover, Select, Tooltip } from 'antd'
import { ICON_ARROW_RIGHT, ICON_CATALOG_DOMAIN, ICON_CHECK, ICON_CLOSE, ICON_DELETE, ICON_EDIT, ICON_LOADING_CIRCLE, ICON_PLUS, ICON_SYNONYM } from '../../../Common/newIconSource'
import Buttons from '../../../Common/button/Buttons'
import { Literals } from '../../common/literals'
import CommonModal from '../../../Common/CommonModal/CommonModal'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { CustomSkeleton } from '../../../Common/skeleton/CustomSkeleton'
import { bulkDeleteVocabulary, createSynonym, deleteSynonym, getSynonyms, updateSynonym } from '../../../../store/modules/common/GenAi/GenAiReducer'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import DeleteModal from '../../../Common/deleteModal/DeleteModal'
import useHideSidebar from '../../../../customHooks/useHideSidebar'
import SearchInput from '../../../Common/search/Search'

const TraceSynonym = ({
    parentDomain = '',
    setParentDomain = () => {}
}) => {

    const history = useNavigate()

    const dispatch = useDispatch()
    
    // const location = useLocation()

    // const {dm: domainId} = getQueryParams(location.search)

    const [loadingDomains, setLoadingDomains] = React.useState(false)
    const [domainError, setDomainError] = React.useState(undefined)
    const [domains, setDomains] = React.useState([])
    const [activeDomain, setActiveDomain] = React.useState(parentDomain ?? null)
    const [openModal, setOpenModal] = React.useState(false)
    const [createLoading, setCreateLoading] = React.useState(false)
    const [updating, setUpdating] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [deleting, setDeleting] = React.useState(false)
    const [deleteOpen, setDeleteOpen] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [data, setData] = React.useState([])
    const [empty, setEmpty] = React.useState(false)
    const [stopLazyload, setStopLazyload] = React.useState(false)
    const [domainSearch, setDomainSearch] = React.useState('')
    const [loadMore, setLoadMore] = React.useState(false)
    const [pageNo, setPageNo] = React.useState(1)
    const [synonymName, setSynonymName] = React.useState('')
    const [searchText, setSearchText] = React.useState('')
    const [searchLoader, setSearchLoader] = React.useState(false)
    const [synonymList, setSynonymList] = React.useState([
        {value: ''}
    ])
    const [bulkDeleting, setBulkDeleting] = React.useState(false)

    const { datadomainList } = useSelector(state => state.UserManagement.DataDomain)

    const listElementRef = React.useRef({})
    const selectedDataRef = React.useRef(null)
    const searchRef = React.useRef('')

    useHideSidebar(true)

    React.useEffect(() => {
        if(datadomainList?.length === 0) {
            setDomainError(undefined)
            setLoadingDomains(true)
            dispatch(getDataDomainList(false, false))
            .then(
                res => {
                    if(res?.data?.length) {
                        setDomains([...res?.data])
                    }
                    // if(activeDomain === null || activeDomain === 0 || activeDomain === isNaN) {
                    //     setActiveDomain(res?.data?.[0]?.data_domain_id)
                    // }
                    dispatch(setDataDomainList(res.data))
                    setLoadingDomains(false)
                }
            )
            .catch((err) => {
                setLoadingDomains(false)
                setDomainError(err?.message)
            })
        }
        else {
            setDomains([...datadomainList])
        }

    }, [])

    React.useEffect(() => {
        setActiveDomain(parentDomain)
    }, [parentDomain])

    React.useEffect(() => {
        if (pageNo !== 1) {
            setPageNo(1)
        }
        searchRef.current = searchText?.toLowerCase()
        fetchSynonyms(true)
    }, [activeDomain, searchText])

    const fetchSynonyms = (reset = false) => {
        if(reset) {
            if(searchRef.current?.trim() !== "") {
                setSearchLoader(true)
            }
            else {
                setLoading(true)
            }
        }

        setError(undefined)
        setStopLazyload(false)

        const pageNumber = reset ? 1 : pageNo
        const pageLimit  = 30
        const search = searchRef.current ?? ''

        dispatch(getSynonyms(pageNumber, pageLimit, activeDomain, search, false))
        .then(res => {
            let dat = res?.data
            if(dat) {
                setEmpty(dat?.results?.length === 0)
                if(reset) {
                    let list = dat?.results
                    if(list?.length > 0) {
                        list[0]['active'] = true
                    }
                    setData([...list])
                }
                else {
                    setData(prev => [...prev, ...dat?.results])
                }
            }
            if (dat?.has_next === false) {
                setStopLazyload(true)
                setLoadMore(false)
            }

            if (res?.status === "success") {
                setLoadMore(false)
                setPageNo(prev => prev + 1)
            }

            if(searchRef.current?.trim() !== "") {
                setSearchLoader(false)
            }
            else {
                setLoading(false)
            }
        })
        .catch(err => {
            console.error({err})
            setError(err?.message)
            if(searchRef.current?.trim() !== "") {
                setSearchLoader(false)
            }
            else {
                setLoading(false)
            }
            setLoadMore(false)
        })
    }

    const handleCreatePersona = () => {
        setCreateLoading(true)

        let payload = {
            data_domain: activeDomain,
            type: 'SYNONYM',
            name: synonymName,
            synonym: synonymList?.filter(s => s?.value?.trim() !== "")?.map(s => s?.value)
        }

        if(payload?.synonym?.length === 0) {
            setCreateLoading(false)
            emitToastNotification('error', 'Please provide at least one Synonym')
            return
        }

        dispatch(createSynonym(payload, false))
        .then(res => {
            setSynonymName('')
            setSynonymList([{value: ''}])

            if(empty) setEmpty(false)

            setData(prev => {
                let list = [{...res?.data}, ...prev]
                if(list?.length === 1) {
                    list[0]['active'] = true
                }
                return list
            })
            
            setOpenModal(false)
            setCreateLoading(false)
        })
        .catch(err => {
            console.error({err})
            emitToastNotification('error', err?.message)
            setCreateLoading(false)
        })
    }

    const handleUpdateSynonym = (sData) => {
        setUpdating(true)

        let payload = {
            data_domain: activeDomain,
            name: synonymName,
            synonym: synonymList?.filter(s => s?.value?.trim() !== "")?.map(s => s?.value)
        }

        if(payload?.synonym?.length === 0) {
            setUpdating(false)
            emitToastNotification('error', 'Please provide at least one Synonym')
            return
        }

        dispatch(updateSynonym(sData?.id, payload, false))
        .then(res => {
            setData(prev => prev?.map(p => (p?.id === sData?.id ? {...res?.data} : {...p})))
            setSynonymName('')
            setSynonymList([{value: ''}])
            setOpenModal(false)
            setUpdating(false)
        })
        .catch(err => {
            console.error({err})
            emitToastNotification('error', err?.message)
            setUpdating(false)
        })
    }

    const handleDeleteSynonym = (sData) => {
        setDeleting(true)

        dispatch(deleteSynonym(sData?.id))
        .then(res => {
            setData(prev => {
                let list = prev?.filter(p => p?.id !== sData?.id)
                if(sData?.active === true && list?.length > 0) {
                    list[0]['active'] = true
                }
                setEmpty(list?.length === 0)
                return list
            })
            setSynonymName('')
            setSynonymList([{value: ''}])
            setDeleteOpen(false)
            setDeleting(false)
        })
        .catch(err => {
            console.error({err})
            emitToastNotification('error', err?.message)
            setDeleting(false)
        })
    }

    const onScrollToBottom = (paginate) => {
        if (!stopLazyload && !loading) {
            if (paginate && !loadMore) {
                setLoadMore(true)
                fetchSynonyms()
            }
        }
    }

    const domainOptions = React.useMemo(() => {
        let list = domains?.map((item) => {
            return {
                key: item?.data_domain_id?.toString(),
                value: item.data_domain_id,
                Name: item?.data_domain_name?.replaceAll("_"," ")?.toUpperCase(),
                domain: item?.data_domain_name,
            }
        })
        return list
    }, [domains])

    const userSelectedOomain = (d_id) => {
        dispatch(setUserSelectedDomain({ data_domain_id: d_id }, false))
        .catch(err => console.error(err?.message))
    }

    const onChange = (key) => {
        setParentDomain(key)
    };

    const bulkDelete = (ids) => {
        setBulkDeleting(true)
        dispatch(bulkDeleteVocabulary({ids}))
        .then(() => {
            setBulkDeleting(false)
            setPageNo(1)
            fetchSynonyms(true)
        })
        .catch(() => {
            setBulkDeleting(false)
        })
    }

    const activeData = React?.useMemo(() => {
        return data?.find(d => d?.active === true)
    }, [data])
    
    return (
        <div className='trace-synonym-wrapper'>
            <div className='d-flex align-items-center justify-content-between trace-synonym-header' 
                style={{borderBottom: 'solid 1px #f0f0f0'}}
            >
                <div>
                <div className='d-flex align-items-center color-primary'>
                    {
                        loading
                        ?
                        <CustomSkeleton height='24px' width='100px' />
                        :
                        <>
                            <Popover
                                trigger={'hover'}
                                placement='bottomLeft'
                                arrow={false}
                                rootClassName='feed-dm-li'
                                className='d-flex align-items-center'
                                title={
                                    <>
                                        <div className='d-flex fontInterSemiBold fontSizeHeading 
                                        align-items-center justify-content-between p-2'>
                                            <input
                                                className='custom-input-field'
                                                placeholder='Search...'
                                                value={domainSearch}
                                                onChange={e => {
                                                    setDomainSearch(e?.target?.value)
                                                }}
                                            />
                                        </div>
                                    </>
                                }
                                content={
                                    <>
                                        {
                                            loadingDomains ? <ClaristaLoader height='145px' />   
                                            :
                                            domainOptions?.filter(v => v?.Name?.toLowerCase()?.includes(domainSearch?.toLowerCase()))?.length === 0 ?
                                            <div className='p-3 text-center text-black-50 fontSizeHeading'>
                                                No Domains Found
                                            </div>
                                            :
                                            <div className='p-2' style={{maxHeight: 180, overflowY: 'auto'}}>
                                            {
                                                domainOptions?.filter(v => v?.Name?.toLowerCase()?.includes(domainSearch?.toLowerCase()))
                                                ?.map((d, i) => (
                                                    <div 
                                                        key={`${d?.value}-${d?.Name}=${i}`} 
                                                        className={`d-flex align-items-center my-1 px-2 py-1 feed-dm-li-itm
                                                            ${activeDomain?.toString() === d?.key ? 'active' : ''}
                                                        `}
                                                        onClick={() => {
                                                            if(activeDomain?.toString() !== d?.key) {
                                                                setParentDomain(d?.key)
                                                                userSelectedOomain(d?.key)
                                                            }
                                                        }}
                                                    >
                                                        <ICON_CATALOG_DOMAIN color='currentColor' height='14' width='14' />
                                                        <label 
                                                            title={d?.Name}
                                                            className='mb-0 fontSizeLabel ml-2 text-uppercase text-with-ellipsis cursor-pointer'
                                                            style={{width: 100}}
                                                            >
                                                            {d?.Name}
                                                        </label>
                                                        {/* <span title={`${d?.pending_count} Pending`} className='feed-dm-li-tg ml-2'>
                                                            {d?.pending_count ?? 0}
                                                        </span> */}
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        }
                                    </>
                                }
                            >
                                <ICON_CATALOG_DOMAIN height='20' width='20'/>
                                <span style={{fontSize: 14}} className='ml-2 fontInterSemiBold feedback-custom-domain'>
                                    {
                                        !domainOptions?.find(d => d?.key?.toString() === activeDomain?.toString())?.Name ? 'Select Domain'
                                        :
                                        domainOptions?.find(d => d?.key?.toString() === activeDomain?.toString())?.Name
                                    }
                                </span>
                                <span className='d-inline-block bx-rotate-90 color-primary ml-2'>
                                    <ICON_ARROW_RIGHT color='currentColor' height='12' width='12' />
                                </span>
                            </Popover>
                        </>
                    }
                        <div className='vertical-separator' style={{height: 24, width: 2}}></div>
                        <label className='mb-0 fontSizeLargeHeading fontInterSemiBold text-dark'>Manage Synonyms</label>
                    </div>



                    {/* <Select
                        placeholder="Select Domain"
                        showSearch={true}
                        optionFilterProp="children"
                        loading={loadingDomains}
                        value={
                            domainError    ? 'Error!' :
                            loadingDomains ? 'Loading...' :
                            domainOptions?.find(d => d?.key?.toString() === activeDomain?.toString())?.value ?? 'Select Domain'
                        }
                        onChange={onChange}
                        filterOption={(input, option) => {
                                const name = domainOptions?.find(d => d?.value === option?.value)?.Name ?? ''
                                return name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                            }
                        }
                        filterSort={(optionA, optionB) => {
                                const nmA = domainOptions?.find(d => d?.value === optionA?.value)?.Name ?? ''
                                const nmB = domainOptions?.find(d => d?.value === optionB?.value)?.Name ?? ''
                                return nmA?.toString()?.toLowerCase()?.localeCompare(nmB?.toString()?.toLowerCase())
                            }
                        }
                        >
                        {
                            domainOptions?.map((ele, index) => (
                                <Select.Option key={index} value={ele.value ? ele.value : ele.Name}>
                                    <div title={ele?.Name} className='align-items-center d-flex px-1'>
                                        <span className='mr-1'><ICON_CATALOG_DOMAIN width='18' height='18' /></span>
                                        <span className='fontSizeHeading ml-2 text-with-ellipsis text-uppercase'>{ele.Name}</span>
                                    </div>
                                </Select.Option>
                            ))
                        }
                    </Select> */}
                </div>
                <div className='d-flex align-items-center'>
                    {
                        data?.length === 0 ? ''
                        :
                        <div>
                            <Tooltip 
                                title={data?.every(d => d?.checked) ? 'Unselect All' : 'Select All'}
                            >
                                <Checkbox 
                                    className='mr-2'
                                    checked={data?.every(d => d?.checked)}
                                    onChange={e => {
                                        let chk = e?.target?.checked
                                        setData(prev => {
                                            return prev?.map(v => ({...v, checked: chk}))
                                        })
                                    }}
                                    disabled={bulkDeleting}
                                />
                            </Tooltip>
                        </div>
                    }
                    {
                        data?.some(d => d?.checked)
                        ?
                        <Buttons
                            props={{
                                buttonText: bulkDeleting ? 'Deleting...' : `Delete (${data?.filter(d => d?.checked)?.length})`,
                                tooltip: "",
                                buttonClassName: `custom-btn-outline error-red custom-btn btn-with-text mr-2 border`,
                                buttonWrapperClass: 'd-flex justify-content-end',
                                buttonEvent: () => {
                                    bulkDelete(data?.filter(d => d?.checked)?.map(c => c?.id))
                                },
                                ImgSrc: () => bulkDeleting ? <ICON_LOADING_CIRCLE/> : <ICON_DELETE/>,
                                isDisable: bulkDeleting,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                        :   ''
                    }
                    <Buttons
                        props={{
                            buttonText: "Create",
                            tooltip: "",
                            buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                            buttonWrapperClass: 'd-flex justify-content-end',
                            buttonEvent: () => {
                                selectedDataRef.current = null
                                setOpenModal(true)
                            },
                            ImgSrc: () => <ICON_PLUS/>,
                            isDisable: bulkDeleting,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                </div>
            </div>
            {
                error ? getReloadErrorTemplate({
                    errorMessage: error, 
                    onReload: () => {
                        setPageNo(1)
                        fetchSynonyms(true)
                    }
                })
                :
                <div className='trace-synonym-body'>
                    <div className='position-relative'>
                        <div className='pr-2 py-2'>
                            <SearchInput
                                searchData={searchText}
                                setSearchData={setSearchText}
                                useDelay={true}
                                // useButtonClick={true}
                                // onEnterCallBack={val => {
                                //     searchRef.current = val
                                //     if (pageNo !== 1) {
                                //         setPageNo(1)
                                //     }
                                //     fetchSynonyms(true, true)
                                // }}
                                // onClearCallback={() => {
                                //     searchRef.current = ''
                                //     if (pageNo !== 1) {
                                //         setPageNo(1)
                                //     }
                                //     fetchSynonyms(true)
                                // }}
                                isDisable={loading || searchLoader || (empty && searchText === "")}
                            />
                        </div>
                        <div className='trace-synonym-list'
                            ref={listElementRef} 
                            onScroll={
                                ({currentTarget}) => {
                                    onScrollToBottom(currentTarget.scrollTop + 10 >=
                                        currentTarget.scrollHeight - currentTarget.clientHeight)
                                }
                            }
                        >
                            {
                                loading ? <ClaristaLoader/>
                                :
                                searchLoader
                                ?
                                <div className='mr-2'>
                                    {
                                        [...Array(3)]?.map((s, i) => (
                                            <React.Fragment key={`searchLoad-${i}`}>
                                                <div className='mb-3'>
                                                    <CustomSkeleton height='30px' width={`100%`} />
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                                :
                                data?.length === 0
                                ?
                                <div className='py-3 text-center text-black-50 fontSizeHeading'>
                                    No Data Found
                                </div>
                                :
                                data
                                ?.map((s, i) => (
                                    <React.Fragment key={`${i}-synonym`}>
                                        <div 
                                            className={`d-flex align-items-center justify-content-between
                                            py-2 text-highlight-div ${activeData?.id === s?.id ? 'active' : ''}`}
                                            onClick={(e) => {
                                                e?.stopPropagation()
                                                setData(prev => prev?.map(d => ({...d, active: d?.id === s?.id})))
                                            }}
                                        >
                                            <div className='d-flex'>
                                                    <Checkbox 
                                                        className='mr-2'
                                                        checked={!!s?.checked}
                                                        onChange={e => {
                                                            e?.stopPropagation()
                                                            let chk = e?.target?.checked
                                                            setData(prev => {
                                                                return prev?.map(v => ({...v, checked: v?.id === s?.id ? chk : !!v?.checked}))
                                                            })
                                                        }}
                                                        disabled={bulkDeleting}
                                                    />
                                                <label className='mb-0 fontSizeHeading fontInterSemiBold syn-label'>
                                                    {s?.name}
                                                </label>
                                            </div>
                                            <div className='align-items-center syn-action-btns'>
                                                <Buttons
                                                    props={{
                                                        buttonText: "",
                                                        // tooltip: "Edit",
                                                        // tooltipPlacement: "top",
                                                        buttonClassName: `custom-btn-outline mr-1 custom-btn btn-with-icon border-0 bg-transparent`,
                                                        buttonEvent: (e) => {
                                                            e?.stopPropagation()
                                                            selectedDataRef.current = {...s}
                                                            setSynonymName(s?.name)
                                                            setSynonymList(s?.synonym?.map(s => ({value: s})))
                                                            setOpenModal(true)
                                                        },
                                                        ImgSrc: () => <ICON_EDIT/>,
                                                        isDisable: bulkDeleting,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                    }}
                                                />
                                                <Buttons
                                                    props={{
                                                        buttonText: "",
                                                        // tooltip: "Delete",
                                                        // tooltipPlacement: 'top',
                                                        buttonClassName: `custom-btn-outline error-red custom-btn btn-with-icon border-0 bg-transparent`,
                                                        buttonEvent: (e) => {
                                                            e?.stopPropagation()
                                                            selectedDataRef.current = {...s}
                                                            setDeleteOpen(true)
                                                        },
                                                        ImgSrc: () => <ICON_DELETE/>,
                                                        isDisable: bulkDeleting,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                        {
                            !loadMore ? ''
                            :
                            <div className='trace-loading-more-wrap w-100'>
                                <div className='trace-loading-more'>
                                    <span className='mr-2'>
                                        <ICON_LOADING_CIRCLE height='20' width='20' color='currentColor'/>
                                    </span>
                                    Loading More
                                </div>
                            </div>
                        }
                    </div>
                    <div className='pl-3'>
                        {
                            empty ? <NoDataComponent logo={<ICON_SYNONYM/>} message={'No Synonym Found'} />
                            :
                            <React.Fragment>
                                <div className='py-2'>
                                    <div>
                                        {
                                            loading
                                            ?
                                            <CustomSkeleton height='24px' width='180px' />
                                            :
                                            <label className='mb-0 fontSizeLargeHeading fontInterSemiBold'>
                                                {activeData?.name}
                                            </label>
                                        }
                                    </div>
                                    <div>
                                        <label className='mb-0 fontSizeLargeHeading font-italic'>
                                            {
                                                loading
                                                ? 'Loading Synonyms...'
                                                : 'Synonyms-'
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className='trace-synonym-list-content'>
                                    {
                                        loading
                                        ?
                                        <div>
                                            {
                                                [...Array(3)]?.map((s, i) => (
                                                    <React.Fragment key={`syn-${i}`}>
                                                        <div className='py-2 pl-3 d-flex align-items-center'>
                                                            <i className='bx bxs-circle mr-2 small text-muted'></i>
                                                            <CustomSkeleton height='24px' width={`${100 + ((i+3)*10)}px`} />
                                                        </div>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </div>
                                        :
                                        !activeData?.synonym
                                        ?
                                        <div className='text-black-50 py-2 font-italic'>No Synonym</div>
                                        :
                                        activeData?.synonym
                                        ?.map((s, i) => (
                                            <React.Fragment key={`syn-${i}`}>
                                                <div className='py-2 pl-3'>
                                                    <i className='bx bxs-circle mr-2 small text-muted'></i>
                                                    {s}
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>

            }

            <CommonModal
                open={openModal}
                setOpen={setOpenModal}
                header='Create Synonym'
                size='md'
                body={<>
                    <div>
                        <div className='mb-3'>
                            <label className='label'>Title</label>
                            <div>
                                <Input 
                                    value={synonymName}
                                    onChange={e => {
                                        setSynonymName(e?.target?.value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='d-flex align-items-end justify-content-between mb-2'>
                                <label className='label mb-1'>Synonyms</label>
                                <Buttons
                                    props={{
                                        buttonText: "Add",
                                        tooltip: "",
                                        buttonClassName: `custom-btn-outline custom-btn btn-with-text bg-light`,
                                        buttonEvent: () => {
                                            setSynonymList(prev => [...prev, {value: ''}])
                                        },
                                        ImgSrc: () => <ICON_PLUS/>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </div>
                            {
                                synonymList
                                .map((v, i) => (
                                    <React.Fragment key={`${i}-synonym`}>
                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <div className='w-100'>
                                                <Input 
                                                    value={v?.value}
                                                    onChange={e => {
                                                        let val = e?.target?.value
                                                        setSynonymList(prev => prev?.map((s, idx) => (idx === i ? {value: val} : {...s})))
                                                    }}
                                                    className='w-100'
                                                />
                                            </div>
                                            {
                                                synonymList?.length > 1
                                                ?
                                                <div className='ml-2'>
                                                    <Buttons
                                                        props={{
                                                            buttonText: "",
                                                            tooltip: "",
                                                            buttonClassName: `custom-btn-outline error-red custom-btn btn-with-icon border-0`,
                                                            buttonEvent: () => {
                                                                setSynonymList(prev => prev?.filter((p, idx) => idx !== i ))
                                                            },
                                                            ImgSrc: () => <ICON_CLOSE/>,
                                                            isDisable: false,
                                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                        }}
                                                    />
                                                </div>
                                                :   ''
                                            }
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                        <div className='d-flex justify-content-center'>
                            {
                                selectedDataRef.current === null
                                ?
                                <Buttons
                                    props={{
                                        buttonText: createLoading ? "Creating..." : "Submit",
                                        tooltip: "",
                                        buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                                        buttonEvent: () => {
                                            handleCreatePersona()
                                        },
                                        ImgSrc: () => createLoading ? <ICON_LOADING_CIRCLE/> : <ICON_CHECK/>,
                                        isDisable: createLoading,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                                :
                                <Buttons
                                    props={{
                                        buttonText: updating ? "Updating..." : "Update",
                                        tooltip: "",
                                        buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                                        buttonEvent: () => {
                                            handleUpdateSynonym(selectedDataRef.current)
                                        },
                                        ImgSrc: () => updating ? <ICON_LOADING_CIRCLE/> : <ICON_CHECK/>,
                                        isDisable: updating,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            }
                        </div>
                    </div>
                </>}
                hideFooter={true}
                onClose={() => {
                    setSynonymName('')
                    setSynonymList([{value: ''}])
                }}
            />

        <DeleteModal
            heading='Delete Persona'
            loading={deleting}
            deleteBtnDisabled={deleting}
            open={deleteOpen}
            setOpen={setDeleteOpen}
            onConfirm={() => {
                handleDeleteSynonym(selectedDataRef.current)
            }}
        />
        </div>
    )
}

export default TraceSynonym