import { Select } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { createTenantOpenAi, getTenantOpenAi } from '../../../../../store/modules/UserManagement/users/usersActions'
import { ICON_ALERT, ICON_CHECK, ICON_LOADING_CIRCLE } from '../../../../Common/newIconSource'

const OpenAiConfigForm = React.forwardRef(({
    fieldValues = {},
    setFieldValues = () => { },
    loading = false,
    setLoading = () => { },
    setisActiveToggle = () => { },
    saving = false,
    setSaving = () => { },
}, ref) => {

    const dispatch = useDispatch()

    const [showSuccess, setShowSuccess] = React.useState(false)
    const [showError, setShowError] = React.useState(false)
    const [type, setType] = React.useState('openai')

    React.useImperativeHandle(ref, () => {
        return {
            save: () => {
                handleSave()
            }
        }
    }, [fieldValues])

    React.useEffect(() => {
        setFieldValues(prev => (
            {
                ...prev,
                openai: {
                    type: "",
                    model_name: "",
                    API_key: "",
                    API_base: "",
                    API_version: "",
                    deployment_name: "",
                    organization_id: "",
                    embedding_model_name: "",
                    embedding_deployment_name: "",
                    // proxy: ""
                }
            }
        ))
        getDetails()
    }, [])

    const getDetails = () => {
        setLoading(true)
        dispatch(getTenantOpenAi())
            .then(res => {
                let data = res?.data ?? []
                let el = data
                setType(el?.type)
                setFieldValues(prev => (
                    {
                        ...prev,
                        openai: {
                            type: el?.type ?? "openai",
                            model_name: el?.model_name ?? "",
                            API_key: el?.API_key ?? "",
                            is_active: el?.is_active ?? false,
                            API_base: el?.API_base ?? "",
                            API_version: el?.API_version ?? "",
                            deployment_name: el?.deployment_name ?? "",
                            organization_id: el?.organization_id ?? "",
                            embedding_model_name: el?.embedding_model_name ?? "",
                            embedding_deployment_name: el?.embedding_deployment_name ?? "",
                            // proxy: el?.proxy ?? ""
                        }
                    }
                ))
                setisActiveToggle(el?.is_active)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    const handleSave = () => {
        setShowError(false)
        setShowSuccess(false)
        setSaving(true)
        let values = fieldValues?.openai
        let payload = {
            ...values
        }
        setTimeout(() => {
            dispatch(createTenantOpenAi(payload))
                .then(res => {
                    setSaving(false)
                    setShowSuccess(true)
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 5000)
                })
                .catch(err => {
                    setSaving(false)
                    setShowError(true)
                    setTimeout(() => {
                        setShowError(false)
                    }, 5000)
                })
        }, 5000)
    }

    const onInputHandler = (key, value) => {
        setFieldValues(prev => {
            return {
                ...prev,
                openai: {
                    ...prev?.openai,
                    [key]: value
                }
            }
        })
    }

    return (
        <div>
            <div className='row'>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Type
                        </label>
                    </div>
                    <Select
                        className='conf-sel'
                        defaultValue="openai"
                        value={type}
                        onChange={(val) => {
                            setType(val)
                            onInputHandler('type', val)
                        }}
                        options={[
                            {
                                value: 'openai',
                                label: 'OpenAI',
                            },
                            {
                                value: 'azure',
                                label: 'Azure',
                            }
                        ]}
                        disabled={saving}
                    />
                </div>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Model Name
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="text"
                        value={fieldValues?.openai?.model_name ?? ''}
                        onChange={(e) => {
                            onInputHandler('model_name', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            API Key
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        type="password"
                        value={fieldValues?.openai?.API_key ?? ''}
                        onChange={(e) => {
                            onInputHandler('API_key', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>
                {
                    type === 'azure'
                        ?
                        <>
                            <div className="col-6 form-group">
                                <div className='d-flex align-items-center justify-content-between p-0'>
                                    <label className="label text-muted">
                                        API Base
                                    </label>
                                </div>
                                <input
                                    className="custom-input-field w-100"
                                    type="text"
                                    value={fieldValues?.openai?.API_base}
                                    onChange={(e) => {
                                        onInputHandler('API_base', e?.target?.value)
                                    }}
                                    disabled={saving}
                                    required
                                />
                            </div>
                            <div className="col-6 form-group">
                                <div className='d-flex align-items-center justify-content-between p-0'>
                                    <label className="label text-muted">
                                        API Version
                                    </label>
                                </div>
                                <input
                                    className="custom-input-field w-100"
                                    value={fieldValues?.openai?.API_version}
                                    onChange={(e) => {
                                        onInputHandler('API_version', e?.target?.value)
                                    }}
                                    disabled={saving}
                                    required
                                />
                            </div>
                            <div className="col-6 form-group">
                                <div className='d-flex align-items-center justify-content-between p-0'>
                                    <label className="label text-muted">
                                        Deployment Name
                                    </label>
                                </div>
                                <input
                                    className="custom-input-field w-100"
                                    value={fieldValues?.openai?.deployment_name}
                                    onChange={(e) => {
                                        onInputHandler('deployment_name', e?.target?.value)
                                    }}
                                    disabled={saving}
                                    required
                                />
                            </div>
                            <div className="col-6 form-group">
                                <div className='d-flex align-items-center justify-content-between p-0'>
                                    <label className="label text-muted">
                                        Embedding Deployment Name
                                    </label>
                                </div>
                                <input
                                    className="custom-input-field w-100"
                                    value={fieldValues?.openai?.embedding_deployment_name}
                                    onChange={(e) => {
                                        onInputHandler('embedding_deployment_name', e?.target?.value)
                                    }}
                                    disabled={saving}
                                    required
                                />
                            </div>
                        </>
                        : ''
                }

                <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Embedding Model Name
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        value={fieldValues?.openai?.embedding_model_name}
                        onChange={(e) => {
                            onInputHandler('embedding_model_name', e?.target?.value)
                        }}
                        disabled={saving}
                        required
                    />
                </div>

                {
                    type === 'openai'
                        ?
                        <div className="col-6 form-group">
                            <div className='d-flex align-items-center justify-content-between p-0'>
                                <label className="label text-muted">
                                    Organization ID
                                </label>
                            </div>
                            <input
                                className="custom-input-field w-100"
                                value={fieldValues?.openai?.organization_id}
                                onChange={(e) => {
                                    onInputHandler('organization_id', e?.target?.value)
                                }}
                                disabled={saving}
                                required
                            />
                        </div>
                        : ''
                }

                {/* <div className="col-6 form-group">
                    <div className='d-flex align-items-center justify-content-between p-0'>
                        <label className="label text-muted">
                            Proxy (Optional)
                        </label>
                    </div>
                    <input
                        className="custom-input-field w-100"
                        value={fieldValues?.openai?.proxy}
                        onChange={(e) => {
                            onInputHandler('proxy', e?.target?.value)
                        }}
                        disabled={saving}
                    />
                </div> */}
                {
                    showSuccess
                        ?
                        <div className='col-12 mt-2'>
                            <div className='bg-light border border-success fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-success'>
                                <span className='mr-2'><ICON_CHECK width='16' height='16' color='currentColor' /></span>
                                <span>
                                    Succesfully Configured OpenAI
                                </span>
                            </div>
                        </div>
                        : ''
                }
                {
                    saving
                        ?
                        <div className='col-12 mt-2'>
                            <div className='bg-light border border-primary fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-primary'>
                                <span className='mr-2'><ICON_LOADING_CIRCLE width='16' height='16' color='currentColor' /></span>
                                <span>
                                    Configuring OpenAI
                                </span>
                            </div>
                        </div>
                        : ''
                }
                {
                    showError
                        ?
                        <div className='col-12 mt-2'>
                            <div className='bg-light border border-danger fontInterSemiBold fontSizeHeading px-2 py-2 rounded text-danger'>
                                <span className='mr-2'><ICON_ALERT width='16' height='16' color='currentColor' /></span>
                                <span>
                                    Failed to configure OpenAI
                                </span>
                            </div>
                        </div>
                        : ''
                }
            </div>
        </div>
    )
})

export default OpenAiConfigForm