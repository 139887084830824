import React,{useEffect} from 'react'
import { useSelector, useDispatch, useStore } from "react-redux"

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Buttons from '../../../Common/button/Buttons';
import { CHECK, CLOSE } from '../../../Common/iconSource';
import { Literals } from '../../common/literals';
import { SET_FLOWS_ALERT_TRANSFORMER_LIST } from '../../../../store/modules/alert/createAlert/createAlertActiontype';

import { Scheduler } from '../index'
import { setSelectedScheduler } from '../../../../store/modules/scheduler/action';
import { emitToastNotification } from '../../../../helpers/toast_helper';
import {AdditionalViewTable} from './SettingsAdditionalDetails';


const AdditionalDetailsModal = ({ 
    isModalOpen,
    selected,
    setSelected, 
    onClose,
    save, 
    reset,
    present,
    isEdit
}) => {
    const [btnDisabled, setBtnDisabled] = React.useState(false)

    const onSaveHandler= () => {
        save(selected);
    }
    // useEffect(()=>{

    //     if(reset)
    //         setSelected([]);
    // },[reset])
    useEffect(()=>{
        if(present)
        setSelected([...present]);
    },[present])
    return (
        <Modal size="lg" isOpen={isModalOpen} className="custom-modal connection-modal" centered={true}>
            <ModalHeader>

                <div className="modal-heading-logs fontSizeHeading fontInterSemiBold">Select Additional Fields</div>
                <Buttons props={{ tooltip: 'Close', buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon float-right", buttonEvent: () => { onClose() }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY }} />

            </ModalHeader>
            <ModalBody className='overflow-hidden'>
                <div style={{height:"500px"}}>
                    <AdditionalViewTable 
                        isEdit={isEdit} 
                        selected={selected} 
                        setSelected={setSelected}
                        alreadyPresent={present}
                        getButtonDisabled={(bool) => {
                            setBtnDisabled(bool)
                        }}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
            <Buttons 
                props={{ 
                    tooltip: 'Done', 
                    buttonText: 'Done', 
                    buttonClassName: " custom-btn-primary custom-btn btn-with-text float-right", 
                    buttonEvent: () => { onSaveHandler();onClose();}, 
                    ImgSrc: () => <CHECK />, 
                    isDisable: btnDisabled, 
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT 
                }} 
            />

            </ModalFooter>

        </Modal >
    )
}

export default AdditionalDetailsModal
