
import { Input, Popover, Radio, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import useDebounce from '../../../../../customHooks/useDebounce';
import { getSearchedBusinessTerms } from '../../../../../store/modules/dataDictionary/action';
import { CLOSE } from '../../../../Common/iconSource';
import { businessTermRegex, columnNameRegex } from '../../../common/helperFunctions';
import './BusinessTermInput.scss'
import { ICON_AUTO_CLASSIFY, ICON_REDIRECT } from '../../../../Common/newIconSource';
import { useNavigate } from 'react-router-dom';

export const BusinessTermInput = ({fieldValue, ...props}) => {

    const [isReady, setIsReady] = useState(true)
    const [isBizSearch, setIsBizSearch] = useState(false)
    const [value, setValue] = useState(fieldValue)
    const [focused, setFocused] = useState(false)
    const [open, setOpen] = useState(false);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [list, setList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [bestMatchesData, setBestMatchesData] = useState([]);
    const [inputDict, setInputDict] = useState({});
    const [preventReq, setPreventReq] = useState(true); // To Prevent Unnecessary API
    const [activeDictId, setActiveDictId] = useState(undefined);
    const [isMouseOnPopover, setIsMouseOnPopover] = useState(false);

    const currentTerm = useRef(null)

    const counter = useRef(0)
    const debouncedTerm = useDebounce(value, 500);

    const dispatch = useDispatch()
    const history = useNavigate()

    React.useEffect(() => {
        if(props?.row) {
            setActiveDictId(props?.row?.curr_bizterm_id)
        }
    }, [props])

    useEffect(() => {
        if(!props.disabled) {
            (async() => {
                try {
                    /* let payload = { tech_term: [props.row.technical_term] }
                    let response = await dispatch(getSearchedBusinessTerms(payload, false))
                    setBestMatchesData([...response.data])
                    let data = [...response.data]
                    data = data.map(item => {
                        return {
                            ...item,
                            data_dict_options: [...item.data_dict_options.filter(v => v.data_dict !== null)]
                        }
                    })
                    let list = data?.[0]?.data_dict_options
                    setList([...list]) */

                    let data = props?.row?.output_regex?.data_dict_options??[]
                    setList([...data])
                    setBestMatchesData([...data])
                }
                catch(e) {
                    setIsReady(true)
                }
            })()
        }
    }, [])
    
    useEffect(() => {
        (async() => {
            
            if(focused) {
                if(!props.disabled && !preventReq) {
                    if(value?.trim() === '') return
                    setIsBizSearch(true)
                    let dataDict = {}
                    try {
                        let payload = { business_term: [value.toLowerCase()] }
                        let res = await dispatch(getSearchedBusinessTerms(payload, false))
                        let inpData = res.data.map(item => {
                            return {
                                ...item,
                                data_dict_options: [...item.data_dict_options.filter(v => v.data_dict !== null)]
                            }
                        })
                        
                        setSuggestions(inpData?.[0]?.data_dict_options ?? [])
                        setList(inpData?.[0]?.data_dict_options ?? [])
                        setShowSuggestion(true)

                        let inpBizTerm = inpData?.[0]?.data_dict_options?.[0]
                        
                        setInputDict({...inpData?.[0]?.data_dict_options?.[0]})
                        dataDict = {...inpBizTerm}
                        setTimeout(() => { setIsBizSearch(false) }, 50)
                        
                        currentTerm.current = inpBizTerm.id
                        setOpen(true)
                        if(bestMatchesData.length === 0) {
                            setTimeout(() => { setIsReady(true) }, 50)
                        }

                        setPreventReq(true) // To Prevent API
                    }
                    catch(e) {
                        setIsReady(true)
                    }
                    props.onPause(debouncedTerm, dataDict)
                        
                    // counter.current = counter.current + 1
                }
            }
        })()
    }, [debouncedTerm, focused])

    const hide = () => {
        setOpen(false);
    };
    
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const selectTerm = (obj) => {
        
        if(obj) {
            setValue(() => {
                if(obj) {
                    return obj.business_term
                }
                return debouncedTerm
            })
            props.onPause(obj.business_term, obj)
            currentTerm.current = obj.id
            setOpen(false)
            setFocused(false)
        }
        else {
            setValue(() => {
                return debouncedTerm
            })
            props.onPause(
                debouncedTerm?.toLowerCase(), 
                debouncedTerm?.toLowerCase() === inputDict?.tech_term?.toLowerCase() 
                    ? {...inputDict} : {}
                )
            currentTerm.current = null
            setOpen(false)
            setFocused(false)
        }
    }

    const popOverContent = {
        title: <>
            <div className='d-flex align-items-center justify-content-between best-match-biz-terms-header'>
                <h5>Matches</h5>
                <button onClick={() => hide()} className='custom-btn custom-btn-outline btn-with-icon'>
                    <CLOSE/>
                </button>
            </div>
        </>,
        body: <>
            <ul className='best-match-biz-terms position-relative'
                onMouseLeave={() => {
                    setIsMouseOnPopover(false)
                }}
                onMouseEnter={() => {
                    setIsMouseOnPopover(true)
                }}
            >
                <li className='biz-no-of-results sticky-top'>
                    <div className='text-center py-1 bg-white'>
                        <Radio.Group 
                            className='bst-mtch-rd' 
                            value={showSuggestion}
                            onChange={(e) => {
                                e.stopPropagation()
                                const bool = e?.target?.value
                                if(bool) {
                                    setList([...suggestions])
                                }
                                else {
                                    setList([...bestMatchesData])
                                }
                                setShowSuggestion(bool)
                                setFocused(true); 
                                setOpen(true)
                            }}
                            >
                            <Radio.Button 
                                style={{height: 24, width: 72, display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}} 
                                value={false}>
                                    Best
                            </Radio.Button>
                            <Radio.Button 
                                style={{height: 24, width: 72, display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}} 
                                value={true}>
                                    Search
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    {
                        !showSuggestion
                        ?
                        <small className='text-white bg-theme-primary d-block w-100 mb-0 text-center'>
                            {
                                !isReady 
                                ?   'Please wait'
                                :   list.length === 0
                                    ?   showSuggestion ? 'Try searching something else' : 'May be a new Display Name'
                                    :   `${list.length} match${list.length===1?'':'es'} found`
                            }
                        </small>
                        :   ''
                    }
                </li>
                {
                    list.length
                    ?   list.map(item => (
                            <>
                                <li key={item.id} className={`bst-match-biz-term-li ${activeDictId === item?.id ? 'active' : ''}`} 
                                // <li key={item.id} className={`bst-match-biz-term-li ${currentTerm.current === item.id ? 'active' : ''}`} 
                                    onClick={() => {
                                            selectTerm(item)
                                            currentTerm.current = item.id
                                            setOpen(false)
                                            setFocused(false)
                                    }}
                                >
                                    <div className='pb-1'>
                                        <div className='w-100'>
                                            <div className='position-relative'>
                                                <p title={item.business_term} style={{width: 'calc(100% - 30px)'}} 
                                                    className='terms-txt mb-0 paragraph font-w-600 text-with-ellipsis'>
                                                    {item.business_term}
                                                </p>
                                                <Tooltip placement="right" title="View Details">
                                                    <button 
                                                        style={{position: 'absolute', right: 0, top: -6}}
                                                        className="custom-btn-outline bg-transparent  custom-btn btn-with-icon"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            history(window.open(`/data-dictionary/${item?.id}`, '_blank'))
                                                        }}
                                                    >
                                                        <ICON_REDIRECT />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                            {/* <div className='w-50'>
                                                <p className='text-lowercase text-right pr-1 terms-txt mb-0 paragraph text-black-50 text-with-ellipsis'>
                                                    {item.tech_term}
                                                </p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='border px-1 py-1 rounded bg-light shadow-sm'>
                                        {/* <div className='d-flex w-100 align-items-center jusitfy-content-between'>
                                            <div className='w-50'>
                                                <label>Technical Term</label>
                                                <p className='catTag font-w-600 h-auto mb-0 paragraph rounded-0 text-with-ellipsis'>
                                                    {
                                                        item?.tech_term
                                                        ?   item?.tech_term??'-'
                                                        :   '-'
                                                    }
                                                </p>
                                            </div>
                                            <div className='w-50'>
                                                <label>Connection</label>
                                                <p className='clsTag font-w-600 h-auto mb-0 paragraph rounded-0 text-with-ellipsis'>
                                                    {
                                                        item?.connection && item?.connection !== null
                                                        ?   item?.connection??'-'
                                                        :   '-'
                                                    }
                                                </p>
                                            </div>
                                        </div> */}
                                        <div className='d-flex w-100 align-items-center jusitfy-content-between'>
                                            <div className='w-50'>
                                                <label>Category</label>
                                                <p className='catTag font-w-600 h-auto mb-0 paragraph rounded-0 text-with-ellipsis'>
                                                    {
                                                        item?.category_detail
                                                        ?   item?.category_detail?.name??'-'
                                                        :   '-'
                                                    }
                                                </p>
                                            </div>
                                            <div className='w-50'>
                                                <label>Classifications</label>
                                                <p className='clsTag font-w-600 h-auto mb-0 paragraph rounded-0 text-with-ellipsis'>
                                                    {
                                                        item?.classification_detail
                                                        ?   item?.classification_detail?.map(v => v.name)?.join(', ')??'-'
                                                        :   '-'
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className='d-flex w-100 align-items-center jusitfy-content-between'>
                                            <div className='w-100'>
                                                <label>Description</label>
                                                <p className='catTag font-w-600 h-auto mb-0 paragraph rounded-0 text-with-ellipsis'>
                                                    {
                                                        item?.description
                                                        ?   item?.description??'-'
                                                        :   '-'
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </>
                        ))
                    :   <li className='biz-not-found p-2'>
                            <p className='paragraph text-center text-black-50 mb-0'>
                                {
                                    isReady 
                                    ?   'No Match Found'
                                    :   'Searching...'
                                }
                            </p>
                        </li>
                }
            </ul>
        </>
    }

    return (
        <>
            <Popover
                content={popOverContent.body}
                title={popOverContent.title}
                trigger="focus"
                // visible={focused && open}
                open={focused && open}
                // onVisibleChange={handleOpenChange}
                // onOpenChange={handleOpenChange}
                placement="bottomLeft"
                overlayClassName='bst-mtch-pop'
            >
                <Input 
                    // suffix={<i className={!props.disabled && isBizSearch ? 'bx bx-loader-alt bx-spin' : ''}></i>}
                    onFocus={() => {setFocused(true); setOpen(true)}} 
                    value={value}
                    onChange={(e) => {
                            if (businessTermRegex.test(e.target.value) || e.target.value === "") {

                                if(preventReq) setPreventReq(false)

                                setValue(e.target.value)
                            }
                        }
                    }
                    onBlur={() => setTimeout(() => {if(!isMouseOnPopover) {setFocused(false); setOpen(false)}}, 500)}
                    {...props}
                    className={`${props.className} custom-biz-input ${!props.disabled && isBizSearch ? 'searching' : ''}`}
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => e?.stopPropagation()}
                />
            </Popover>
            {
                props?.row?.output_regex?.has_classify_match 
                ?
                <span className='has-match'>
                    <ICON_AUTO_CLASSIFY height='15' width='15' />
                </span>
                :   ''
            }
        </>
    )
}
