import React from 'react'
import './../podCreation.scss'
import { ICON_ARROW_LEFT, ICON_ARROW_RIGHT, ICON_CREATE_POD, ICON_EDITBOX, ICON_IMPORT_FROM_DATABASE, ICON_MULTIPLE_FILES, ICON_REDIRECT, ICON_SAVE, ICON_UPLOAD } from '../../../../Common/newIconSource'
import Buttons from '../../../../Common/button/Buttons'
import { Literals } from '../../../common/literals'
import { CLOSE } from '../../../../Common/iconSource'
import _, { isArray } from 'lodash'
import ConnectionContext from '../../../connections/landingPage/components/ConnectionContext'
import { emitToastNotification } from '../../../../../helpers/toast_helper'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { getConfirmationModal, getObjectLength } from '../../../common/helperFunctions'
import { useNavigate } from 'react-router-dom'
import { setPodCreationData } from '../../../../../store/modules/dataCatalogue/createPOD/podCreationRedux'
import { Popconfirm, Result, Select } from 'antd'
import { createBussinessTerm } from '../../../../../store/modules/dataDictionary/action'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import SearchInput from '../../../../Common/search/Search'

const reRunMessage = {
    explorer: 'Preview again as file options have been changed',
    schema: 'Run SQL again as "Query, Parameters Or Engine" have been changed'
}

const PodCreationHeader = ({
    steps = [],
    onStepChange = () => {},
    newBusinessTerms = [],
    isEditMode = false,
    isEditPermission = false,
    isEditing = false,
    setIsEditing = () => {},
    fetchPodDetails = () => {},
    isFileUpload = false,
    setPodCreationReady = () => {},
    isPodOfPod = false,
    isPodSaveRunning = false,
    isPodPreviewRunning = false,
    stringPattern = '',
    storedStringPattern,
    podCreationTutorialComponentIds = {},
    isDataDictionary = false,
    updateSchemaRef = {},
    setPublishedTable = () => {},
    publishedTable = {},
    isCustomQuery = false,
    setIsCustomQuery = () => {},
    activeTab = '',
    setPreviewUUID = () => {},
    editorQuery = '',
    isApiCompleted = false,
    searchConn = '',
    setSearchConn = () => {},
    resetPodType = () => {},
    isUnstructured = false
}) => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const store = useStore()

    const { setConnectionStatus } = React.useContext(ConnectionContext);

    const [confirmModalObj, setConfirmModalObj] = React.useState({open: false, btnType: 'close'})
    const [tableSelectValue, setTableSelectValue] = React.useState('')
    const [unPublishedPop, setUnPublishedPop] = React.useState(false)
    const [isPublishing, setIsPublishing] = React.useState(false)

    const unPublishedKeyRef = React.useRef(undefined)

    const counter = React.useRef(0)

    const reduxData = useSelector(state => state?.DataCatalogue?.PodCreationData)
    const schemaTableDataPayload = reduxData?.schemaTableDataPayload??[]

    const selectedConn = useSelector((state) => state.UserManagement.ConnectionReducer.selectedConn)

    const firstStepKey = _.head(steps)?.key
    const currentStepKey = steps?.find(s => s?.active)?.key
    const lastStepKey = _.last(steps)?.key

    const stepKeyName = {
        connection: 0,
        explorer: 1,
        schema: 2,
        // connection: isFileUpload || isPodOfPod ? -1 : 0,
        // explorer: isFileUpload || isPodOfPod ? 0 : 1,
        // schema: isFileUpload || isPodOfPod ? 1 : 2,
    }

    React.useEffect(() => {
        if(isEditMode && counter.current === 0) {
            if(selectedConn && getObjectLength(selectedConn) > 0) {
                setConnectionStatus(selectedConn)
                counter.current ++
            }
        }
    }, [selectedConn])

    const validateDuplicateEntries = () => {
        let colsName = schemaTableDataPayload.map(v => v.column_name.toLocaleLowerCase())
        let duplicateArr = colsName.filter((item, index) => colsName.indexOf(item) !== index)
        let isDuplicateFoundInColName = duplicateArr.length > 0

        let colsSourceName = schemaTableDataPayload.map(v => v.column_source_name.toLocaleLowerCase())
        let duplicateArrSrc = colsSourceName.filter((item, index) => colsSourceName.indexOf(item) !== index)
        let isDuplicateFoundInSrc = duplicateArrSrc.length > 0

        if (isDuplicateFoundInColName || isDuplicateFoundInSrc) {
            if (isDuplicateFoundInColName) {
                emitToastNotification('error', `Duplicate entries (${duplicateArr.join(', ')}) found in Display Name`, { timeOut: 100000 })
            }
            if (isDuplicateFoundInSrc) {
                emitToastNotification('error', `Duplicate entries (${duplicateArrSrc.join(', ')}) found in Technical Name, try using alias in query with different term.`, { timeOut: 100000 })
            }
            return false;
        }
        return true
    }

    const handleNextButton = () => {
        if(currentStepKey === lastStepKey) return

        if(currentStepKey === stepKeyName?.explorer) {

            if(isDataDictionary) {
                if(!selectedConn?.file_system) {
                    const keys = Object.keys(reduxData?.tableData)
                    reduxData['output_regex'] = reduxData.tableData[keys[1]]['output_regex']??[]
                    reduxData.tableData['result'] = reduxData.tableData[keys[1]]
                    dispatch(setPodCreationData(reduxData))
                }
                onStepChange(currentStepKey + 1)
                return
            }

            if(isFileUpload || selectedConn?.file_system) {
                if(stringPattern !== storedStringPattern) {
                    emitToastNotification('error', reRunMessage.explorer)
                    return
                }
            }
            else if(isPodOfPod || !selectedConn?.file_system) {

                if(!isApiCompleted) {
                    emitToastNotification('info', "Please wait for the response")
                    return
                }

                /**
                 * (Old Comment)
                 * Now in both condition stringPattern logic is used, Only in Edit Mode
                 * 
                 * (New Comment)
                 * As Comment is Removed, removing the below IF ELSE Condition
                 */
                // if(isEditMode) {
                //     if(isCustomQuery) {
                //         if(stringPattern !== storedStringPattern) {
                //             emitToastNotification('error', reRunMessage.schema)
                //             return
                //         }
                //     }
                // }
                // else {
                //     if(isCustomQuery) {
                //         if(stringPattern !== storedStringPattern) {
                //             emitToastNotification('error', reRunMessage.schema)
                //             return
                //         }
                //     }
                // }

                /**
                 *  For only Custom Query stringPattern logic use below code
                 */
                if(isCustomQuery) {
                    if(stringPattern !== storedStringPattern) {
                        emitToastNotification('error', reRunMessage.schema)
                        return
                    }
                }

                if(getObjectLength(reduxData.tableData['result']) === 0 && !isCustomQuery) {
                    setIsCustomQuery(false)
                    let uuid = uuidv4()
                    setPreviewUUID(uuid)
                    reduxData['schemaUUID'] = uuid
                    reduxData['output_regex'] = reduxData.tableData[activeTab]['output_regex'] ?? []
                    reduxData.tableData['result'] = reduxData.tableData[activeTab]
                    reduxData.schemaQueryMap['result'] = editorQuery
                    dispatch(setPodCreationData(reduxData))
                }

            }
        }
        const status = isArray(selectedConn) ? false : getObjectLength(selectedConn)
        if(!isFileUpload && !status && !isPodOfPod) {
            emitToastNotification('info', 'Select any connection to proceed')
            return
        }
        
        if(currentStepKey === stepKeyName?.schema) {
            if(schemaTableDataPayload?.length === 0) {
                emitToastNotification('info', "No columns selected in schema")
                return
            }
            let validateColumnName = schemaTableDataPayload?.every((el) => el?.column_name.trim().length > 0)
            if(!validateColumnName) {
                emitToastNotification('error',Literals.EMPTY_FIELD_FOUND_IN_BUSSINESS_SCHEMA)
                return
            }
            else {
                
                if(!validateDuplicateEntries()) return


                /**CODE: To Prevent Empty Category and Classifiers**/

                // let payload = [...newBusinessTerms]
                // if (payload.length > 0) {
                //     let isAnyCategoryEmpty = payload.some(item => item.category === '')
                //         // , isAnyClassificationEmpty = payload.some(item => item.classification === '')

                //     if (isAnyCategoryEmpty) {
                //         emitToastNotification('info',Literals.EMPTY_CATEGORY_FOUND)
                //         return
                //     }
                //     // if (isAnyClassificationEmpty) {
                //     //   emitToastNotification('info',Literals.EMPTY_CLASSIFICATION_FOUND)
                //     // }
                // }
            }
        }
        onStepChange(currentStepKey + 1)
    }

    const handlePreviousButton = () => {
        if(currentStepKey === firstStepKey) return
        if(!isEditMode) {
            if(currentStepKey === stepKeyName?.explorer || currentStepKey === stepKeyName?.schema) {
                if(!isFileUpload) {
                    setConfirmModalObj({open: true, btnType: 'previous'})
                    return
                }
                
            }
        }
        
        /**
         * For Edit Mode For Non Custom Query, Emptying result when moving back
         */
        if(currentStepKey === stepKeyName?.schema) {
            if(!isCustomQuery && !selectedConn?.file_system && !isFileUpload) {
                reduxData.tableData['result'] = {}
                reduxData.schemaTableData = []
                dispatch(setPodCreationData(reduxData))
            }
        }

        onStepChange(currentStepKey - 1)
    }

    const handleClose = () => {
        history(Literals.links.CLARISTA_NAVIGATOR + '#pod');
    }

    const handleCloseButton = () => {
        if(isEditMode) {
            if(!isEditing) {
                setIsEditing(true)
                return
            }
        }

        setConfirmModalObj({open: true, btnType: 'close'})
    }

    const onConfirm = () => {
        if(confirmModalObj?.btnType === 'close') {
            if(isEditMode) {
                resetPodType()
                onStepChange(firstStepKey)
                setConnectionStatus(null)
                fetchPodDetails()
                setIsEditing(false)
                setPodCreationReady(false)
                return
            }
            if(isDataDictionary) {
                history(Literals.links.CLARISTA_NAVIGATOR + '#business')
            }
            else handleClose()
        }
        else if(confirmModalObj?.btnType === 'previous') {
            if(isDataDictionary) {

                if(selectedConn?.file_system) {
                    const keys = reduxData?.selectedFolder
                    setTableSelectValue(keys[0])
                }
                else {
                    reduxData.tableData['result'] = {}
                    reduxData['output_regex'] = []
                    dispatch(setPodCreationData(reduxData))
    
                    const keys = Object.keys(reduxData?.tableData)
                    setTableSelectValue(keys[1])
                }

            }

            if(!isCustomQuery && !selectedConn?.file_system && !isFileUpload) {
                reduxData.tableData['result'] = {}
                reduxData.schemaTableData = []
                dispatch(setPodCreationData(reduxData))
            }
            onStepChange(currentStepKey - 1)
        }
    }

    const setOpenModal = (open) => {
        setConfirmModalObj(prev => ({...prev, open}))
    }

    const getConfirmMessage = () => {
        if(confirmModalObj?.btnType === 'close') {
            return isEditMode ? "Unsaved changes will be lost. Are you sure?" : 'Are you sure, want to close?'
        }
        else if(!isFileUpload && currentStepKey === stepKeyName?.explorer && confirmModalObj?.btnType === 'previous') {
            return <span>Any unsaved data will be lost, if connection changes. <br/>Do you want to continue?</span>
        }
        else if(!isFileUpload && currentStepKey === stepKeyName?.schema && confirmModalObj?.btnType === 'previous') {
            return <span>This action may reset all fields of the schema. <br/>Do you want to continue?</span>
        }
        return 'Are you sure, want to continue?'
    }
    
    const nextButtonVisibility = React.useMemo(() => {
        if(currentStepKey === stepKeyName?.connection) {
            const connStatus = isArray(selectedConn) ? true : !getObjectLength(selectedConn)
            if(isFileUpload || isPodOfPod) {
                return false
            }
            return connStatus
        }
        if(currentStepKey === stepKeyName?.explorer) {

            /*******************To Disable Button Use This Code*******************/
            // let patternUnMatched = false
            // if(storedStringPattern) {
            //     patternUnMatched = stringPattern !== storedStringPattern
            // }


            /************For Data Dictionary Imort Database************/
            if(isDataDictionary) {
                if(selectedConn?.file_system) {
                    return isPodPreviewRunning || getObjectLength(reduxData?.tableData?.result) === 0
                }
                return isPodPreviewRunning || getObjectLength(reduxData?.tableData) === 1
            }

            if(!isFileUpload && !selectedConn?.file_system && isEditMode) {
                // const podData = store.getState().DataCatalogue.Dictionary.dictionaryPodData
                // console.log({reduxData, s:podData?.source})

                return isPodPreviewRunning
                        || !(getObjectLength(reduxData?.tableData ?? {}) > 1) // denote is any table selected
                        || !(getObjectLength(reduxData?.tableData?.[activeTab]) > 0) // denotes whether the active tab has result or not
                                

                        // || (
                        //         getObjectLength(reduxData?.tableData?.result) === 0 
                        //         // || (podData?.source?.length + 1) !== getObjectLength(reduxData?.tableData) // Don't uncomment this
                        //     )
            }

            if(isFileUpload || selectedConn?.file_system) {
                return isPodPreviewRunning     // to handle if previewing is in progress
                || getObjectLength(reduxData?.tableData?.result) === 0
                || reduxData?.tableData?.result?.data_header?.length === 0
            }

            return  isPodPreviewRunning     // to handle if previewing is in progress    
                    || !(getObjectLength(reduxData?.tableData ?? {}) > 1) // denote is any table selected
                    || !(getObjectLength(reduxData?.tableData?.[activeTab]) > 0) // denotes whether the active tab has result or not
                    

                    // || getObjectLength(reduxData?.tableData?.result) === 0 
                    // || patternUnMatched           // to handle, if someone, after preview change's the query or file options without previewing
        }

        if(currentStepKey === stepKeyName?.schema) {
            return isPodPreviewRunning
        }
        return currentStepKey === lastStepKey
    }, [
        steps, 
        selectedConn, 
        reduxData, 
        isPodPreviewRunning,
        activeTab,
        isCustomQuery,
        isFileUpload,
        isPodOfPod,
        // stringPattern,
        // storedStringPattern
    ])

    const isWarning = React.useMemo(() => {
        if(
            // getObjectLength(reduxData?.tableData?.result) === 0 || 
            currentStepKey !== stepKeyName?.explorer
            ) return false

        let patternUnMatched = false
        if(storedStringPattern) {
            patternUnMatched = stringPattern !== storedStringPattern
        }
        return patternUnMatched
    }, [steps, reduxData, stringPattern, storedStringPattern])

    const getWarningMessage = () => {
        if(isFileUpload || selectedConn?.file_system) {
            if(stringPattern !== storedStringPattern) {
                return reRunMessage.explorer
            }
        }
        else if(isPodOfPod || !selectedConn?.file_system) {
            if(stringPattern !== storedStringPattern) {
               return reRunMessage.schema
            }
        }
        return "Next"
    }

    const publishTableList = React.useMemo(() => {
        let keys;
        if(selectedConn?.file_system) {
            keys = [reduxData?.selectedFolder?.[0]]
        }
        else {
            keys = Object.keys(reduxData?.tableData)?.filter(k => k !== 'result')
        }
        return keys?.map(obj => (
            {
                name: obj,
                value: obj,
                disabled: publishedTable[obj] ?? false
            }
        ))
    }, [reduxData, publishedTable, selectedConn])

    React.useEffect(() => {
        if(!isDataDictionary) return
        if(steps?.find(s => s?.active)?.key === stepKeyName.schema) {
            if(selectedConn?.file_system) {
                const keys = reduxData?.selectedFolder
                setTableSelectValue(keys[0])
            }
            else {
                const keys = Object.keys(reduxData?.tableData)
                setTableSelectValue(keys[1])
            }
        }
    }, [steps, selectedConn])

    const handleTableSelected = (key) => {
        reduxData['output_regex'] = reduxData.tableData[key]['output_regex']??[]
        reduxData.tableData['result'] = reduxData.tableData[key]
        dispatch(setPodCreationData(reduxData))
        setTableSelectValue(key)

        setTimeout(() => {
            updateSchemaRef.current.updateSchema()
        }, 50)
        unPublishedKeyRef.current = undefined
    }

    const nextPublish = (key) => {
        const index = publishTableList?.findIndex(({value}) => value === key)
        const nextKey = publishTableList[index + 1]?.value
        // console.log({key, index, nextKey})
        if(nextKey) {
            setTableSelectValue(nextKey)
            handleTableSelected(nextKey)
        }
    }

    const handlePublish = () => {
        const key = tableSelectValue
        // console.log({key, tableSelectValue})
        const payload = [...newBusinessTerms]
        setIsPublishing(true)
        toast.promise(
            new Promise(async(resolve, reject) => {
                try {
                    await dispatch(createBussinessTerm(payload, false, false))
                    if(!selectedConn?.file_system) {
                        reduxData['output_regex'] = reduxData.tableData[key]['output_regex'] ?? []
                        reduxData.tableData['result'] = reduxData.tableData[key]
                        dispatch(setPodCreationData(reduxData))
                        setPublishedTable(prev => ({...prev, [key]: true}))
                        nextPublish(key)
    
                        setTimeout(() => {
                            updateSchemaRef.current.updateSchema()
                        }, 50)
                    }
                    else {
                        setPublishedTable({[key]: true})
                    }
                    setIsPublishing(false)
                    resolve()
                }
                catch {
                    setIsPublishing(false)
                    reject()
                }
            })
            ,
            {
                pending: `Publishing "${key}" please wait...`,
                success: `Table "${key}" published`,
                error: `Failed to publish "${key}"`,
            },
            {
                position: 'bottom-right',
                autoClose: 5000,
            }
        )
    }

    const publishedSuccessButtons = () => {
        const key = tableSelectValue
        const index = publishTableList?.findIndex(({value}) => value === key)
        const lastKeyIndex = publishTableList?.length - 1
        if(getObjectLength(publishedTable) !== publishTableList?.length) {
            return []
        }
        if(index === lastKeyIndex) {
            return [
                    <Buttons
                props={{
                    buttonText: "Business Glossary",
                    buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                    buttonEvent: () => {
                        history(Literals.links.CLARISTA_NAVIGATOR + '#business')
                    },
                    ImgSrc: () => <ICON_REDIRECT />,
                    isDisable: false,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                }}
            />
            ]
        }
        else {
            return [
                <Buttons
                    props={{
                        buttonText: "Next",
                        buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                        buttonEvent: () => {
                            nextPublish(key)
                        },
                        ImgSrc: () => <ICON_ARROW_RIGHT />,
                        isDisable: false,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    }}
                />
            ]
        }
    }

    // console.log({publishTableList, publishedTable, tableSelectValue})
    return (
        <div className='pod-creation-header'>
            <div className='d-flex align-items-center'>
                <span className='mr-2 d-flex'>
                    {
                        isDataDictionary ? <ICON_IMPORT_FROM_DATABASE height='20' width='20' />
                        :
                        isFileUpload
                        ?   <ICON_UPLOAD height='20' width='20' />
                        :   isUnstructured
                            ?   <ICON_MULTIPLE_FILES height='20' width='20'/>
                            :   <ICON_CREATE_POD height='20' width='20'/>
                    }
                </span>
                <label className='fontSizeHeading mb-0 pod-creation-header-title'>
                    {
                        isDataDictionary ? 'Import From Database'
                        :
                        isFileUpload 
                            ? isUnstructured ? 'Unstructured File Upload' : 'File Upload' 
                            : isUnstructured ? 'Unstructured' : 'Create POD'
                    }
                </label>
            </div>
            <div className='pod-crt-step-container'>
                {
                    steps?.map((el, index) => (
                        <div 
                            key={`p-crt-stp-${el.key}`} 
                            className={`${el.active ? 'active' : ''} ${currentStepKey > el.key ? 'visited' : ''}`} 
                            // onClick={() => onStepChange(el?.key)}
                        >
                            <span className='mr-2 fontSizeHeading indic'>
                                {index + 1}
                            </span>
                            <label className='mb-0 fontSizeHeading'>
                                {el.title}
                            </label>
                        </div>
                    ))
                }
            </div>
            <div className='d-flex align-items-center justify-content-end'>
                {
                    isDataDictionary && currentStepKey === stepKeyName.schema
                    ?
                    <>
                        {
                            isPublishing ?
                            <div className='publishing-overlay'></div>
                            :   ''
                        }
                        {
                            publishedTable[tableSelectValue]
                            ?
                            <div className='published-content'>
                                <Result
                                    status="success"
                                    title="Successfully Published!"
                                    subTitle={ 
                                        getObjectLength(publishedTable) === publishTableList?.length
                                        ? `Table "${tableSelectValue}" published`
                                        : <>
                                        {`Table "${tableSelectValue}" published`}
                                        <br/>
                                        <span className='fontSizeHeading fontInterSemiBold'>
                                        <i className='bx bx-check-circle text-success mr-1'></i>
                                        {`${getObjectLength(publishedTable)}/${publishTableList?.length} Published`}
                                        </span>
                                        </>
                                    }
                                    extra={publishedSuccessButtons()}
                                    className='publish-res'
                                />
                            </div>
                            :   ''
                        }
                        {
                            selectedConn?.file_system ? ''
                            :
                            <div className='d-flex mr-2'>
                                <i className='bx bx-check-circle text-success mr-1'></i>
                                <span className='fontSizeLabel fontInterSemiBold'>
                                    {`${getObjectLength(publishedTable)}/${publishTableList?.length} Published`}</span>
                            </div>
                        }
                        {/* <Popconfirm
                            title="Unpublished Data Found"
                            description="Data will be lost whenever you change, continue?"
                            onConfirm={() => console.log('CONFIRM')}
                            onCancel={() => console.log('CANCELLED')}
                            okText="Yes"
                            cancelText="No"
                            open={unPublishedPop}
                            onOpenChange={(bool) => setUnPublishedPop(bool)}
                            placement='left'
                        > */}

                            <Select
                                className='mx-2'
                                showSearch={false}
                                optionFilterProp="children"
                                defaultValue={publishTableList?.[0]?.value ?? ''}
                                value={tableSelectValue}
                                onChange={(val) => {
                                    if(publishTableList?.find(t => t?.value === val)?.disabled)
                                    return
                                    
                                    if(newBusinessTerms?.length > 0) {
                                        unPublishedKeyRef.current = val
                                        setUnPublishedPop(true)
                                        return
                                    }

                                    handleTableSelected(val)
                                }}
                                style={{width: 180}}
                                // filterOption={(input, option) =>
                                //     option.value?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                // }
                                // filterSort={(optionA, optionB) =>
                                //     optionA.value.toString().toLowerCase().localeCompare(optionB.value.toString().toLowerCase())
                                // }
                                >
                                {
                                    publishTableList?.map((ele, index) => (
                                        <Select.Option key={index} value={ele.value ? ele.value : ele.name}>
                                            <div 
                                                title={ele.name} 
                                                style={{opacity: ele?.disabled ? '.6' : '1', cursor: ele?.disabled ? 'not-allowed' : 'pointer'}} 
                                                className='text-with-ellipsis fontSizeLabel fontInterSemiBold'>
                                                    {
                                                        ele?.disabled ? <i className='bx bx-check-circle mx-1 text-success'></i>
                                                        :   <i className='bx bx-circle mx-1 text-dark'></i>
                                                    }
                                                {ele.name}
                                            </div>
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        {/* </Popconfirm> */}
                        <Buttons
                            props={{
                                // tooltip: "Published",
                                tooltipPlacement: "bottom",
                                buttonText: "Publish",
                                buttonClassName: `custom-btn-primary custom-btn btn-with-text mx-2`,
                                buttonEvent: handlePublish,
                                ImgSrc: () => <ICON_SAVE />,
                                isDisable: publishedTable[tableSelectValue] ?? false,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    </> : ''
                }

                {
                    (currentStepKey === stepKeyName.connection)
                    // (currentStepKey === stepKeyName.connection && !isFileUpload && !isPodOfPod)
                    ?
                    <span className='mr-2'>
                        <SearchInput
                            searchData={searchConn}
                            setSearchData={setSearchConn}
                        />
                    </span>
                    :   ''
                }



                {
                    currentStepKey === firstStepKey ? ''
                    :
                    <Buttons
                        props={{
                            tooltip: currentStepKey === firstStepKey ? "" : "Previous",
                            tooltipPlacement: "top",
                            buttonText: "",
                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
                            buttonEvent: handlePreviousButton,
                            ImgSrc: () => <ICON_ARROW_LEFT />,
                            isDisable: isPodPreviewRunning || isPodSaveRunning || currentStepKey === firstStepKey,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                }
                {
                    currentStepKey === lastStepKey 
                    || (isDataDictionary && currentStepKey === stepKeyName.schema) ? ''
                    :
                    <Buttons
                        props={{
                            buttonId: podCreationTutorialComponentIds?.nextButtonId,
                            tooltip: nextButtonVisibility ? "" 
                                        : isWarning ? getWarningMessage() : "Next",
                            tooltipPlacement: "top",
                            buttonText: "",
                            buttonClassName: `custom-btn-primary custom-btn btn-with-icon mr-2 ${!nextButtonVisibility && isWarning ? 'warn-btn' : ''}`,
                            buttonEvent: handleNextButton,
                            ImgSrc: () => <ICON_ARROW_RIGHT />,
                            isDisable: nextButtonVisibility,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                }
                <Buttons
                    props={{
                        tooltip: isEditMode 
                                    ? "" 
                                    // ? isEditing ? "Close Edit" : "Edit" 
                                    : "Close",
                        tooltipPlacement: "left",
                        buttonText: isEditMode ? isEditing ? 'Cancel' : 'Edit' : "",
                        buttonClassName: `custom-btn-${isEditMode ? isEditing ? 'outline border' : 'primary' : 'outline'} custom-btn btn-with-${isEditMode ? 'text' : 'icon'}`,
                        buttonEvent: handleCloseButton,
                        ImgSrc: () => isEditMode
                                        ? isEditing ? <CLOSE/> : <ICON_EDITBOX />
                                        : <CLOSE />,
                        isDisable: isEditMode ? !isEditPermission : isPodSaveRunning,
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                    }}
                />
            </div>
            {
                getConfirmationModal({
                    open: confirmModalObj?.open,
                    setOpen: setOpenModal,
                    onConfirm: onConfirm,
                    body: getConfirmMessage()
                })
            }


            {/* Unpublished Business Term Confirm Modal */}
            {
                getConfirmationModal({
                    open: unPublishedPop,
                    setOpen: setUnPublishedPop,
                    onConfirm: () => {
                        let key = unPublishedKeyRef.current
                        if(key)
                        handleTableSelected(key)
                    },
                    body: 'Unpublished "Display Name(s)" found and will be lost, Do you want to continue?'
                })
            }
        </div>
    )
}

export default PodCreationHeader