import { Dropdown, Input, Popover, Tooltip } from "antd";
import React, { useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody } from "reactstrap";
import {
  duplicate
} from "../../../../store/modules/dataCatalogue/dictionary/dictionaryAction";
import Buttons from "../../../Common/button/Buttons";
import {
  CLOCK, CLOSE, COPY, CRAWLER,
  DELETEICON,
  EDIT, LINEAGE_ICON, PLAY, SAVE, TAG, UPDATED_BY, USER
} from "../../../Common/iconSource";
import {  getRelativeTime, permissions } from "../../common/helperFunctions";
import { Literals } from "../../common/literals";
import eventBasedIcon from "../../../../assets/images/trigger-event.png"
import { assignSchedulerTag, ExecuteScheduler, setSelectedTempScheduler, unassignSchedulerTag } from "../../../../store/modules/scheduler/action";
import { useEffect } from "react";
import { ICON_EVENT, ICON_LOADING_CIRCLE, ICON_MINUS, ICON_PLUS } from "../../../Common/newIconSource";
import _ from "lodash";
import { tags } from "../../dataCatalogue/landingPage/landingPageTagHelper";


export const SchedulersGrid = ({ openDeleteDialog = () => { }, getSchedulerList, Selectedid, loadingStatus, schedulerData = [], searchData, fromRoute }) => {

  const { schedulerId } = useParams();

  return (
    <>
      <div style={fromRoute.toLocaleLowerCase() === 'schedulermodal' ? { height: "calc(100vh - 332px)",overflow:"auto" } : {}} className="scheduler-gridview-responsive new-box-style">
        {
          schedulerData.map((item, i) => {

            return !schedulerId || schedulerId?.toString() !== item.id.toString() ?
              <div key={`sch-grd-${i}-${item}`} >
                <GridCard
                  Selectedid={Selectedid}
                  item={item}
                  openDeleteDialog={openDeleteDialog}
                  getSchedulerList={getSchedulerList}
                  fromRoute={fromRoute}
                  searchData={searchData}
                />
              </div>
              : null
          })
        }
      </div>
    </>
  );
};

export const GridCard = (props) => {
  const { Selectedid, item, searchData, fromRoute, getSchedulerList, openDeleteDialog } = props

  const store = useStore()
  const history = useNavigate()
  const dispatch = useDispatch()

  const [renameText, setRenameText] = useState({});
  const [visible, setVisible] = useState({});
  const [optionOpen, setOptionOpen] = useState(false)
  const [tagLoader, setTagLoader] = React.useState(false)
  const [showTagPop, setShowTagPop] = React.useState(false)
  const [tagMenu, setTagMenu] = React.useState([]);

  const isEditor = !!item?.permission?.find(v => v === permissions.scheduler.editor)

  const tagList = useSelector(state => state.DataCatalogue?.LandingPage?.tagList);

  useEffect(() => {
    if (!optionOpen) {
      setVisible({ ...renameText, [item.id]: false })
    }
  }, [optionOpen])

  React.useEffect(() => {
    setTagSubMenu([...item?.tag])
  }, [item, tagList])

  const addRemoveTagToPOD = (tag_id, remove = false) => {
    // console.log({tag_id})
    setTagLoader(true)
    let requestBody = {
      "tag": [tag_id]
    }
    if (!remove) {
      dispatch(assignSchedulerTag(requestBody, item?.id)).then(
        res => {
          if (res.status === 'success') {
            try {
              setTagLoader(false)
              getSchedulerList();
            }
            catch (e) { 
              setTagLoader(false)
            }
          }
        }
      )
      .catch(() => {
        setTagLoader(false)
      })
    }
    else {
      dispatch(unassignSchedulerTag(tag_id, item?.id)).then(
        res => {
          if (res.status === 'success') {
            setTagLoader(false)
            getSchedulerList();
          }
        }
      )
      .catch(() => {
        setTagLoader(false)
      })
    }
  }

  const tagColor = (value) => {
    var res = value.split("#");
    let colorname = res[0];
    let color = '';
    for (let x in tags) {
      if (tags[x].id === colorname) {
        color = tags[x].color;
        break;
      }
    }
    return color+'95';
  }

  const setTagSubMenu = (tags) => {
    let podTag = tags.map(t => t?.name)
    let returnList = [];
    let availableTags = _.sortBy([...tagList], [function(o) { return o?.name; }])
    // console.log({availableTags, tagList})
    availableTags?.length && availableTags.forEach((tag, x) => {
      if (!podTag.includes(tag?.name)) {
        returnList.push(
          <div onClick={(e) => { e.stopPropagation() }} key={x} className={`${x === availableTags.length - 1 ? '' : 'custom-border-bottom'} d-flex justify-content-between align-items-center padding-top-1 padding-bottom-1`}>
            <div className="d-flex align-items-center">
              <i className="bx bxs-circle" style={{ color: tagColor(tag?.color) }}></i>
              <Tooltip title={tag?.name?.toUpperCase()} placement="topLeft">
                <p className="paragraph m-0 ml-2">{tag?.name?.toUpperCase()}</p>
              </Tooltip>
            </div>
            <Buttons
              props={{
                buttonText: '',
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2 ml-2",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false);
                  addRemoveTagToPOD(tag?.id)
                },
                ImgSrc: () => tagLoader ? <ICON_LOADING_CIRCLE/> : <ICON_PLUS />,
                isDisable: tagLoader,
                buttonType: Literals.BTN_TERTIARY
              }}
            />
          </div>
        )
      } else {
        returnList.push(
          <div onClick={(e) => { e.stopPropagation() }} key={x} className='d-flex justify-content-between custom-border-bottom align-items-center padding-top-1 padding-bottom-1'>
            <div className="d-flex align-items-center">
              <i className="bx bxs-circle" style={{ color: tagColor(tag?.color) }}></i>
              <Tooltip title={tag?.name?.toUpperCase()} placement="topLeft">
                <p className="paragraph m-0 ml-2">{tag?.name?.toUpperCase()}</p>
              </Tooltip>
            </div>
            <Buttons
              props={{
                buttonText: '',
                buttonClassName: "danger-btn custom-btn btn-with-icon mr-2 ml-2 border-0",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false);
                  addRemoveTagToPOD(tag?.id, true)
                },
                ImgSrc: () => tagLoader ? <ICON_LOADING_CIRCLE/> : <ICON_MINUS />,
                isDisable: tagLoader,
                buttonType: Literals.BTN_TERTIARY
              }}
            />
          </div>
        )
      }
    })
    if (returnList.length) {

      setTagMenu([...returnList])
    } else {
      setTagMenu([])

    }
  }

  const tagPopover = {
    header: () => {
      return <>
        <div className="d-flex justify-content-between custom-border-bottom padding-2">
          <p className="section-heading m-0">Tags</p>
          <div className="d-flex">
            <Tooltip title='Close' placement="bottom">
              <button className="custom-btn-outline custom-btn btn-with-icon"
                onClick={function (e) {
                  e.stopPropagation()
                  setShowTagPop(false)
                }}
              >
                <CLOSE />
              </button>
            </Tooltip>
            {/*  <Buttons
              props={{
                buttonText: 'TAG',
                buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                tooltip: 'Add Tag',
                buttonEvent: (e) => {
                  e.stopPropagation();
                  setShowTagPop(false)
                  openAddTag()
                },
                ImgSrc: () => <PLUS />,
                isDisable: false,
                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
              }}
            /> */}
          </div>
        </div>
      </>
    },
    body: () => {
      return <>
        <div className="padding-2 card-tags-auto-overflow tag-popover-body" style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {
            tagMenu.length
              ? tagMenu.map((val, i) => <span key={`tag-pop-itm-${i}`}>{val}</span>)
              : <div className="py-1 text-center text-black-50">No Tag Found</div>
          }
        </div>
      </>
    }
  }

  const handleVisibleChange = (visible) => {
    setShowTagPop(visible)
    if (visible) {
      setTagSubMenu(item?.tag)
    }
  }

  const handleSaveClick = (val) => {
    try {
      dispatch(duplicate({ name: renameText[val.id] }, "scheduler", val.id)).then((res) => {
        if (res.status === 'success') {
          getSchedulerList();
        }
      })
    }
    catch (err) {

    }
  }

  const onClickScheduler = (item) => {

    if (fromRoute.toLocaleLowerCase() === 'schedulermodal') {
      // localStorage.setItem('selectedScheduler',JSON.stringify(item))
      dispatch(setSelectedTempScheduler(item))
    } else {
      history(Literals.links.SCHEDULER_VIEW + item.id)
    }
  }

  const optionList = [
    {
      label: <span className={`${!item.is_active ? 'disabled cursor-not-allowed' : ''} dbox-opt-itm ${!isEditor ? 'disabled' : ''}`}

        onClick={(e) => {
          e.stopPropagation();
          if(!isEditor || !item.is_active) return
          store.dispatch(ExecuteScheduler(item.id))
        }}
      >
        <PLAY width={10} height={10}/>
        Execute
      </span>,
      key: '0',
      disabled: !isEditor,
    },
    {
      label: <span className={`dbox-opt-itm ${!isEditor ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          if(!isEditor) return
          history(Literals.links.SCHEDULER_EDIT + item.id)
        }}>
        <EDIT />
        Edit
      </span>,
      key: '1',
      disabled: !isEditor
    },
    {
      label: <Popover
        destroyTooltipOnHide={true}
        content={
          <div onClick={(e) => {
            e.stopPropagation();
          }}
            style={{ display: "flex", padding: "10px", alignItems: "center" }}>


            <Tooltip title={renameText[item.id] ?? `${item.name}_copy`}>
              <Input value={renameText[item.id] ?? `${item.name}_copy`} onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setRenameText({ ...renameText, [item.id]: e.target.value })
              }} />
            </Tooltip>
            <Buttons
              props={{
                buttonText: "",
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-1",
                buttonEvent: (e) => {
                  e.preventDefault();
                  handleSaveClick(item);
                },
                ImgSrc: () => <SAVE />,
                isDisable: false,
                buttonType: Literals.BTN_SECONDARY,
              }}
            />
          </div>
        }
        title={
          <div onClick={(e) => {
            e.stopPropagation();
          }}
            style={{ display: "flex", padding: "10px", justifyContent: "space-between" }}><span>Create a Copy</span> <Buttons
              props={{
                buttonText: "",
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-1",
                buttonEvent: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setVisible({ ...renameText, [item.id]: false });
                },
                ImgSrc: () => <CLOSE />,
                isDisable: false,
                buttonType: Literals.BTN_TERTIARY,
              }}
            /></div>
        }
        onOpenChange={(visible) => setVisible({ ...renameText, [item.id]: visible })}
        trigger="click"
        open={isEditor && (visible[item.id] ?? false)}
      >
        <span className={`dbox-opt-itm ${!isEditor ? 'disabled' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setRenameText({ ...renameText, [item.id]: item?.name + '_copy' })
            setVisible({ ...renameText, [item.id]: true })
          }}>
          <COPY />
          Duplicate
        </span>
      </Popover>,
      key: '3',
      disabled: !isEditor
    },
    {
      label: <Popover placement="right"
        title={tagPopover.header}
        content={tagPopover.body}
        trigger="hover"
        autoAdjustOverflow={true}
        open={showTagPop}
        onOpenChange={handleVisibleChange}
        destroyTooltipOnHide={true}
      >
        <span className="dbox-opt-itm"
          onClick={(e) => {
            e.stopPropagation();
            setShowTagPop(true)
            setTagSubMenu(item?.tag)
          }}>
          <TAG />
          Tags
        </span>
      </Popover>,
      key: '4',
    },
    {
      label: <span className="dbox-opt-itm"
        onClick={(e) => {
          e.stopPropagation();
          let path = `/viewLineage/scheduler/${item.id}`;
          window.open(path)
        }}>
        <LINEAGE_ICON />
        Show Lineage
      </span>,
      key: '5',
    },
    {
      label: <span className={`dbox-opt-itm danger ${!isEditor ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if(!isEditor) return
          openDeleteDialog(item.id);
        }}>
        <DELETEICON />
        Delete
      </span>,
      key: '6',
      disabled: !isEditor
    },
  ]

  return <>
    <Card className={
      Selectedid && Selectedid === item.id
        ? "position-relative flow-grid selected-border"
        : "position-relative flow-grid "
    } onClick={() => { onClickScheduler(item) }}>
      <CardBody>

        <div className='data-view-dbox' onClick={() => { }}>
          <div className='_src_img'>
            <div className="scheduler-icon-box">
              {
                item.type?.toLocaleLowerCase() === 'time' 
                ? <CLOCK color="#2C83C4" />
                : item.type?.toLocaleLowerCase() === 'partition' 
                  ? <CRAWLER color="#2C83C4" height={"10"} width={"10"} />
                  : <ICON_EVENT color="#2C83C4" />
              }
            </div>
          </div>
          <div className='desc-box'>
            <div className="d-flex align-items-start">
              <Tooltip placement='topLeft' title={item?.name?.toUpperCase()}>
                <label style={{ width: 'calc(100% - 52px)' }} className=' paragraph mt-1 title-txt font-w-600 text-uppercase text-with-ellipsis'>
                  <Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.name ? item.name : ''}> {item.name}</Highlighter>
                </label>
              </Tooltip>
              {
                fromRoute.toLocaleLowerCase() !== 'schedulermodal'
                  ? <>
                    <Dropdown
                      destroyPopupOnHide={true}
                      menu={{
                        items: optionList,
                        onClick: (e) => e.domEvent.stopPropagation()
                      }}
                      trigger={["hover"]}
                      placement="bottomRight"
                      onOpenChange={(bool) => setOptionOpen(bool)}
                      overlayStyle={{ border: 'solid 1px #e4e4e4' }}
                    >
                      <span onClick={e => e.stopPropagation()} className={`dbox-opts ${optionOpen ? 'active' : ''}`}><i className="bx bx-dots-vertical-rounded"></i></span>
                    </Dropdown>
                  </>
                  : ''
              }
            </div>
            <div>
              <Tooltip placement='topLeft' title={item.description}>
                <p className='mb-1 paragraph text-black-50 small desc-txt custom-ellipsis two-line-ellipsis'>
                  {item.description?.length > 0 ?
                    <Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={item.description ? item.description : ''}>{item.description}</Highlighter>
                    : "No description has been given yet."}
                </p>
              </Tooltip>
            </div>
            <ul className="landingCard-profile-list">
              <li className="landingCard-profile-listitem d-flex align-items-center justify-content-between">
                <div style={{flexBasis: 'calc(100% - 60px)'}}>
                  <UPDATED_BY width="15 " height="15" />
                  <span className="label label-gray ml-2">{'Last Updated'} </span>
                  {/* <Tooltip placement="topLeft" title={getRelativeTime(new Date(item.updated_on), undefined)}> */}
                    <span className="paragraph ml-1 ant-tooltip-open">
                      <Tooltip placement="top" title={getRelativeTime(item.updated_on, undefined)}>
                        {getRelativeTime(item.updated_on)}
                      </Tooltip>
                      {/* <Highlighter
                        searchWords={[searchData?.toLocaleLowerCase()]}
                        autoEscape={true}
                        textToHighlight={item.updated_on ? getRelativeTime(item.updated_on) : ''}>{getRelativeTime(item.updated_on)}
                      </Highlighter> */}
                    </span>
                  {/* </Tooltip> */}
                </div>
                <div>
                  <div className="d-flex align-items-center small px-1 rounded-pill" style={{ background: item.is_active ? '#d5f1dc' : '#f5dbdb' }}>
                    <span className={`sphere mr-1 ${item.is_active ? 'bg-success' : 'bg-danger'}`}></span>
                    <label className={`small mb-0 font-w-600 ${item.is_active ? 'text-success' : 'text-danger'}`}>
                      {!item.is_active ? 'Inactive' : 'Active'}
                    </label>
                  </div>
                </div>
              </li>
              <li className="landingCard-profile-listitem">
                <div className="align-items-end d-flex w-100" style={{ paddingLeft: '0px' }}>
                  <USER width="22" height="15" />
                  <span className="label label-gray ml-2" style={{flexBasis: 100}}>{'Updated By'} </span>
                  <Tooltip placement="topLeft" title={item?.updated_by_user?.email}>
                    <span className="paragraph d-block text-with-ellipsis ant-tooltip-open label-color ml-1">
                      {/* <span>{item?.updated_by_user?.email}</span>  */}
                      <Highlighter
                        searchWords={[searchData?.toLocaleLowerCase()]}
                        autoEscape={true}
                        textToHighlight={item?.updated_by_user?.email ?? ''}>
                        {item?.updated_by_user?.email}
                      </Highlighter>
                    </span>
                  </Tooltip>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </CardBody>
    </Card>
  </>
}