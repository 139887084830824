import React, { useEffect, useState } from 'react'
import { CLOCK, TRIGGERICON } from '../../../Common/iconSource'
import { ICON_CALENDAR } from '../../../Common/newIconSource'
import { Radio } from 'antd'
import CronDashboard from './CronDashboard';
import { useSelector, useStore } from 'react-redux';
import { SetSchedulerPayload, setSelectedScheduler, setSelectedTempScheduler } from '../../../../store/modules/scheduler/action';
import { cloneDeep } from 'lodash';
import TriggerEventModelSelection from './TriggerEventModelSelection';
import { setSelectedPodList } from '../../../../store/modules/common/podSelectionModalAction';

function SchedulerTriggerSection({ isExecutePermission = true }) {
    const [triggerType, settriggerType] = useState('TIME');
    const plainOptions = ['SCHEDULER', 'POD'];
    const [modelType, setmodelType] = useState('SCHEDULER');
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)

    const store = useStore();

    useEffect(() => {
        if (schedulerPayload?.trigger_type === 'POD' || schedulerPayload?.trigger_type === 'SCHEDULER') {
            settriggerType('EVENT');
            setmodelType(schedulerPayload?.trigger_type)
        } else {

            settriggerType('TIME');


        }
    }, [schedulerPayload])


    const changeTriggerWarning = (triggerType) => {
        const newPayload = cloneDeep(store?.getState().Scheduler.schedulerPayload);
        // console.log(newPayload, triggerType,newPayload["cron"] !== '0,8 13 * * 1')
        if (!window?.location?.href?.includes('scheduler/view')) {
            if (triggerType?.toLowerCase() === 'time' && (newPayload["dependant_scheduler"] || newPayload["pod_ids"]?.length || newPayload["dependant_query"])) {
                if (window.confirm('Unsaved changes will be lost. Are you sure, want to continue?')) {
                    newPayload["dependant_status"] = "SUCCESS"
                    newPayload["dependant_scheduler"] = null
                    newPayload["pod_ids"] = []
                    newPayload["last_count_flag"] = false
                    newPayload["dependant_query"] = null
                    newPayload["monitor_frequency"] = 900
                    newPayload["last_count"] = 0
                    store.dispatch(setSelectedTempScheduler({}))
                    store.dispatch(setSelectedScheduler({}))
                    store.dispatch(setSelectedPodList({}));
                    newPayload['trigger_type'] = triggerType //modelType?.toUpperCase();
                    settriggerType(triggerType)
                }

            } else if (triggerType?.toLowerCase() !== 'time') {  //&& newPayload["cron"] !== '0,8 13 * * 1'
                if (window.confirm('Unsaved changes will be lost. Are you sure, want to continue?')) {
                    newPayload["cron"] = '0,8 13 * * 1'
                    newPayload["cron_redux"] = {}
                    newPayload['trigger_type'] = triggerType //modelType?.toUpperCase();
                    settriggerType(triggerType)
                }
            } else {
                newPayload['trigger_type'] = triggerType //modelType?.toUpperCase();
                settriggerType(triggerType)
            }



        } else {
            // newPayload['trigger_type'] = triggerType //modelType?.toUpperCase();

            // settriggerType(triggerType)

        }

        store.dispatch(SetSchedulerPayload(newPayload))


    }

    return (
        <div className='h-100'>
            <TRIGGERICON /> Trigger
            <div className='d-flex align-items-start h-100'>
                <div className='col-2-trigger custom-border-right mt-3 h-100 menu-list-item'>
                    <ul>
                        <li onClick={() => changeTriggerWarning('TIME')} className={triggerType?.toLowerCase() === 'time' ? 'active' : ''}><CLOCK width='15px' height='15px' color={triggerType?.toLowerCase() === 'time' ? '#FFF' : "#000"} /> Time</li>
                        <li onClick={() => changeTriggerWarning('EVENT')} className={triggerType?.toLowerCase() === 'event' ? 'active' : ''}><ICON_CALENDAR width='15px' height='15px' color={triggerType?.toLowerCase() === 'event' ? '#FFF' : "#000"} /> Event</li>
                    </ul>
                </div>
                {triggerType?.toLowerCase() === 'event' ? <div className='col-10 m-3'>
                    <label className='paragraph'>Events on </label>
                    <div className='row p-3'>
                        <Radio.Group disabled={!isExecutePermission || window?.location?.href?.toLowerCase().includes('scheduler/view')} options={plainOptions} onChange={(e) => setmodelType(e.target.value)} value={modelType} />
                    </div>

                    <TriggerEventModelSelection isExecutePermission={isExecutePermission} modelType={modelType}></TriggerEventModelSelection>


                </div> : <div className='col-10-trigger m-3'>
                    <div className='row margin-top-crondashboard'>
                        <div className='col-12 mb-2 px-0'>
                            <CronDashboard isExecutePermission={isExecutePermission}/>
                        </div>

                    </div>


                </div>}

            </div>
        </div >
    )
}

export default SchedulerTriggerSection