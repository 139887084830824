import React, { useEffect, useRef, useState } from "react"
import { connect, useDispatch, useSelector, useStore } from "react-redux"
import { useNavigate } from "react-router-dom"
import { deleteDashboardApi, getDashboardListApi } from "../../../store/modules/analytics/dashboard/dashboardActions"
import { NoDataComponent, getDeleteMessage, getRelativeTime, getReloadErrorTemplate, permissions } from "../../modules/common/helperFunctions"
import DeleteModal from "../deleteModal/DeleteModal"
import DashboardNameModal from "../../modules/dashboard/DashboardNameModal"
import { ICON_DASHBOARD } from "../newIconSource"
import { DashboardGridView, GridCard } from "../../modules/dashboard/DashboardGridView"
import { DELETEICON, EDIT, PLUS, TAG } from "../iconSource"
import Buttons from "../button/Buttons"
import NoSearchResultFound from "../../modules/common/NoSearchResultFound"
import _, { cloneDeep, sortBy } from "lodash"
import { Collapse, Space, Tooltip } from "antd"
import Highlighter from "react-highlight-words"
import { Literals } from "../../modules/common/literals"
import ClaristaLoader from "../claristaLoader/ClaristaLoader"
import '../../modules/scheduler/scheduler.scss';
import { getDomainTags, setTagList } from "../../../store/actions"
import {  SET_SELECTED_DASHBOARD_TEMP } from "../../../store/modules/scheduler/SchedulerActionType"

function DashboardGridListIndex(props) {
    let { activeDomain = '', fromRoute = 'dashboard', searchTerm = '', showFavourite, loadingStatus, startWalkThrough = () => { },
        groupByTags = true
    } = props

    const [openModal, setOpenModal] = useState(false)
    const history = useNavigate()
    const store = useStore()

    const [showLoadingMsg, setshowLoadingMsg] = useState(true)

    const [errMsg, setErrorMsg] = useState("");
    const [responseData, setresponseData] = useState([])


    const currentDashboardIdRef = useRef({ current: {} })

    var VISUALIZATION_BASE_URL = window._env_.REACT_APP_VISUALIZATION_BASE_URL;


    const [openDeletePopup, setOpenDeletePopup] = useState(false);

    const [listData, setlistData] = useState([])


    const dispatch = useDispatch();

    const [empty, setEmpty] = useState(false);

    const tagData = useSelector(state => state.DataCatalogue?.LandingPage?.tagList)

    const [showSelectedCardId, setshowSelectedCardId] = useState(null)
    const [activeCollKeys, setActiveCollKeys] = React.useState([])



    useEffect(() => {

        if (activeDomain !== '') {
            getDashboardList()
            callTagListAPI()
        }
    }, [activeDomain])


    const openDeleteDialog = (id) => {

        currentDashboardIdRef.current = id

        setOpenDeletePopup(true);
    };

    const callTagListAPI = () => {
        // if (tagData?.length > 0) return

        dispatch(getDomainTags(activeDomain))
            .then(res => {
                const data = res?.data ?? []
                dispatch(setTagList([...data]))
            })
            .catch(() => { })
    };

    const handleDelete = id => {
        closeDeletePopUp();

        dispatch(deleteDashboardApi(id)).then((rsp) => {
            if (rsp.status === 'success') {
                setlistData([])
                getDashboardList()

            }
        })
            .catch(() => {

            })
    }


    const closeDeletePopUp = () => {
        setOpenDeletePopup(false);
    };

    const setData = (data) => {
        if (data && data?.length) {


            let data_arr = data.map((ele, i) => {

                const isEditor = !!ele?.permission?.find(v => v === permissions.dashboard.editor)

                return {
                    dashboard_name: <div className='flexible-search-wrap d-flex align-items-center font-w-600 text-uppercase'><label className='pr-4 mb-0'>   <span className="primary-svg-color">  <ICON_DASHBOARD height='18' width='24' /></span></label><Tooltip placement="bottom" title={ele?.name}>< Highlighter searchWords={[searchTerm?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.name ? ele?.name : ''} /></Tooltip></div>,
                    description: ele.description ? <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={`${ele?.description?.slice(0, 300)}...`}>< Highlighter searchWords={[searchTerm?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.description ? ele?.description : ''} /></Tooltip></span> : <span style={{ marginLeft: "0px" }}>No description has been given yet.</span>,
                    // updated_on: <Tooltip placement="top" title={getRelativeTime(ele?.updated_on)}>{getRelativeTime(ele?.updated_on)}</Tooltip>,
                    // updated_on: <Tooltip placement="right" title={getRelativeTime(ele?.updated_on)}>< Highlighter searchWords={[searchTerm?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={getRelativeTime(ele?.updated_on) ? getRelativeTime(ele?.updated_on) : ''} /></Tooltip>,
                    created_by: <span className='flexible-search-wrap'><Tooltip placement="topLeft" title={ele.created_by}>< Highlighter searchWords={[searchTerm?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele.created_by ? ele.created_by : ''} /></Tooltip></span>,
                    updated_on: getRelativeTime(ele?.updated_on),
                    updated_by: <span className='flexible-search-wrap'><Tooltip placement="bottom" title={ele?.updated_by}>< Highlighter searchWords={[searchTerm?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele?.updated_by ? ele?.updated_by : ''} /></Tooltip></span>,
                    data_domain: (
                        ele?.data_domain?.length === 0 ? <span className='text-black-50'>N/A</span>
                            :
                            <Tooltip
                                placement="top"
                                title={ele.data_domain?.join(",")?.toUpperCase()}
                                className="text-capitalize"
                            >
                                <Highlighter
                                    searchWords={[searchTerm?.toLocaleLowerCase()]}
                                    autoEscape={true}
                                    textToHighlight={
                                        ele.data_domain && ele.data_domain.length ? ele.data_domain?.join(", ")?.toUpperCase() : ""
                                    }
                                >

                                </Highlighter>
                            </Tooltip>
                    ),
                    icon: <div className='d-flex align-items-center justify-content-center h-100'>
                        <Space size={4} className='justify-content-end h-100'>

                            <Tooltip placement='bottom' title={'Edit'}>
                                <Buttons
                                    props={{
                                        tooltip: 'Edit',
                                        tooltipPlacement: 'bottom',
                                        buttonText: "",
                                        buttonClassName: "custom-btn custom-btn-outline table-icon edit-icon",
                                        buttonEvent: (e) => {
                                            e.stopPropagation();

                                            let editURL = `${VISUALIZATION_BASE_URL}superset/dashboard/${ele.id}/?edit=true&standalone=1`;

                                            history(`${Literals.links.DASHBOARD}/${ele.id}`, { state: { dashboardURL: editURL } })

                                        },
                                        ImgSrc: () => <EDIT />,
                                        isDisable: !isEditor,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />

                            </Tooltip>

                            <Tooltip placement='bottom' title={'Delete'}>
                                <Buttons
                                    props={{
                                        tooltip: 'Delete',
                                        tooltipPlacement: 'bottom',
                                        buttonText: "",
                                        buttonClassName: "custom-btn custom-btn-outline table-icon error-red",
                                        buttonEvent: (e) => { e.stopPropagation(); openDeleteDialog(ele?.id) },
                                        ImgSrc: () => <DELETEICON />,
                                        isDisable: !isEditor,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                                {/* <button disabled={!isDelete} className="custom-btn custom-btn-outline table-icon error-red" onClick={(e) => { e.stopPropagation(); openDeleteDialog(ele?.id) }}><DELETEICON /></button> */}
                            </Tooltip>
                        </Space>
                    </div>,
                    key: ele.id,

                    allData: ele,

                }

            })
            setlistData([...data_arr])
        } else {
            setlistData([])

        }
    }






    const getDashboardList = (domain_name) => {

        setshowLoadingMsg(true)
        let prevData = [...responseData]
        setresponseData([])
        setlistData([])
        store.dispatch(getDashboardListApi(activeDomain)).then((resp, reject) => {

            if (resp.data?.length <= 0) {
                setEmpty(true);
            }
            if (resp && resp.data.length) {
                setshowLoadingMsg(false)
                setEmpty(false);

                setresponseData(resp.data)

            } else {
                setshowLoadingMsg(false)
                setEmpty(true);
                setlistData([])

            }

        }).catch((err) => {
            setshowLoadingMsg(false)
            setEmpty(false);
            setresponseData([...prevData])
            setErrorMsg(err?.message)
        })


    }

    const editDashboard = (ele) => {

        let editURL = `${VISUALIZATION_BASE_URL}superset/dashboard/${ele.id}/?edit=true&standalone=1`;



        history(`${Literals.links.DASHBOARD}/${ele.id}`, { state: { dashboardURL: editURL } })

    }
    const filterData = () => {
        let oldObj = cloneDeep(responseData)
        if (showFavourite && oldObj?.length) {

            oldObj = oldObj.filter((ele) => ele?.bookmark === true);

        }
        if (showFavourite) {
            return (oldObj)
        } else {
            return (responseData)
        }


    }

    useEffect(() => {
        let filteredData = [...filterData()]

        filteredData = filteredData?.map((d, i) => ({
            ...d,
            tag: d?.tag?.filter(t => t?.data_domain?.toString() === activeDomain?.toString()) ?? []

        }))

        if (searchTerm !== '' && filteredData?.length) {
            filteredData = filteredData.filter((data) => {
                return (data?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    data?.description?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    getRelativeTime(data?.updated_on)?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    data?.created_by?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    getRelativeTime(data?.updated_on)?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    data?.updated_by?.toLowerCase().includes(searchTerm?.toLowerCase()))

            })

            setlistData([...filteredData])
        } else {
            setlistData([...filteredData])
        }
    }, [searchTerm, activeDomain, showFavourite, responseData])

    const dataListObj = React.useMemo(() => {
        // console.log({listData})

        if(
            _.isEmpty(searchTerm) && !showFavourite
        ) {
            setActiveCollKeys([])
        }

        const dat = [...listData]

        let tags = new Set([...dat?.map(d => d?.tag)?.flat()])
        // let tags = _.uniqBy([...dat?.map(d => d?.tag)?.flat()], 'name')
        let tagWiseDash = [...tags]?.reduce((prev, curr) => {
            return {
                ...prev,
                [curr?.name]: {
                    data: dat?.filter(d => d?.tag?.map(t => t?.name)?.includes(curr?.name)),
                    tagDetail: { ...curr }
                }
            }
        }, {})

        let tagsDash = []
        for (const key in tagWiseDash) {
            const element = tagWiseDash[key];
            tagsDash.push({
                title: key,
                tagDetail: element?.tagDetail,
                data: element?.data
            })
        }

        tagsDash = sortBy(tagsDash, [o => o?.title])

        if(
            !_.isEmpty(searchTerm) || showFavourite
        ) {
            setActiveCollKeys(tagsDash?.map(d => d?.title))
        }

        return { tagsDash, allData: dat }
    }, [listData])

    // console.log({dataListObj})


    const onRowSelection = (record) => {
        
        if (fromRoute === 'scheduler') {

            store.dispatch({
                type: SET_SELECTED_DASHBOARD_TEMP,
                payload: {
                    dashboardId: record.id,
                    selectedDashboardDetails: record,
                },
            });

            setshowSelectedCardId(record.id)
        } else {
            let viewURL = `${VISUALIZATION_BASE_URL}superset/dashboard/${record?.id}/?standalone=1`;


            history(`${Literals.links.DASHBOARD}/${record.id}`, { state: { dashboardURL: viewURL, domainId: activeDomain } })
        }


    }

    return (
        <React.Fragment>


            <div className='dashboard-index-page'>

                <Buttons props={{
                    buttonId: 'createDashboardBtn',
                    tooltip: 'Create Dashboard',
                    buttonText: '', buttonClassName: "custom-btn-primary custom-btn fixed-button", buttonEvent: () => {
                        setOpenModal(true)

                    }, ImgSrc: () => <PLUS />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                }} />

                <div className='landing-list-main'>

                    {
                        loadingStatus ? <ClaristaLoader height='200px' />
                            :
                            errMsg?.length > 0 ? getReloadErrorTemplate({ errorMessage: errMsg, onReload: () => getDashboardList() })
                                :
                                empty ? <NoDataComponent logo={<ICON_DASHBOARD
                                    width="60" height="60" color="black" />}
                                    message=" Click On (+) To Create Dashboard" />
                                    :
                                    (searchTerm?.length > 0 || showFavourite) && listData?.length === 0 ? <NoSearchResultFound />
                                        :
                                        <div className="">
                                            {
                                                groupByTags
                                                    ?
                                                    <>
                                                        {
                                                            dataListObj?.allData?.length === 0 ? ''
                                                                :
                                                                <Collapse
                                                                    key={`catalog-grid-collapse-${activeCollKeys?.length}`}
                                                                    className='catalog-grid-collapse'
                                                                    collapsible='header'
                                                                    ghost={true}
                                                                    defaultActiveKey={activeCollKeys}
                                                                    // defaultActiveKey={
                                                                    //     tagData?.map(t => t?.name)
                                                                    //     // dataListObj?.tagsDash?.map(t => t?.title)
                                                                    // }
                                                                    // activeKey={dataListObj?.tagsPod?.map(t => t?.title)}
                                                                    items={
                                                                        dataListObj?.tagsDash?.map(t => ({
                                                                            key: t?.title,
                                                                            label: <>
                                                                                <div className='round-tags-container nav-landing'>
                                                                                    <div className={`${t?.tagDetail?.color} round-tags-item`}>
                                                                                        <span>{<TAG />}</span>
                                                                                        <span>{t?.title}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </>,
                                                                            children:
                                                                                <div className="scheduler-gridview-responsive new-box-style">
                                                                                    {
                                                                                        t?.data?.map((d, i) => (
                                                                                            <GridCard
                                                                                                key={`dash-${i}`}
                                                                                                Selectedid={showSelectedCardId}
                                                                                                item={d}
                                                                                                openDeleteDialog={openDeleteDialog}
                                                                                                getSchedulerList={getDashboardList}
                                                                                                fromRoute={''}
                                                                                                searchData={searchTerm}
                                                                                                editDashboard={editDashboard}
                                                                                                viewDashboard={onRowSelection}
                                                                                                index={i}
                                                                                            />
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                        }))
                                                                    }
                                                                />
                                                        }
                                                        {
                                                            dataListObj?.allData?.filter(d => d?.tag?.length === 0)?.length !== 0
                                                                ?
                                                                <div className="px-2">
                                                                    <div className='bg-light px-3 py-2 fontInterSemiBold rounded border mb-2 fontSizeHeading'>
                                                                        {`Untagged`}
                                                                    </div>
                                                                    <div className="scheduler-gridview-responsive new-box-style">
                                                                        {
                                                                            dataListObj?.allData?.filter(d => d?.tag?.length === 0)?.map((d, i) => (
                                                                                <GridCard
                                                                                    key={`untag-dash-${i}`}
                                                                                    Selectedid={showSelectedCardId}
                                                                                    item={d}
                                                                                    openDeleteDialog={openDeleteDialog}
                                                                                    getSchedulerList={getDashboardList}
                                                                                    fromRoute={''}
                                                                                    searchData={searchTerm}
                                                                                    editDashboard={editDashboard}
                                                                                    viewDashboard={onRowSelection}
                                                                                    index={i}
                                                                                />
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                                : ''
                                                        }
                                                    </>
                                                    :
                                                    <DashboardGridView
                                                        openDeleteDialog={openDeleteDialog}
                                                        editDashboard={editDashboard}
                                                        viewDashboard={onRowSelection}
                                                        getSchedulerList={getDashboardList}
                                                        Selectedid={showSelectedCardId}
                                                        schedulerData={listData}
                                                        searchData={searchTerm}
                                                        fromRoute={''}
                                                    />
                                            }


                                        </div>
                    }
                </div>

                <DashboardNameModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}

                    activeDomain={activeDomain}
                />
                {/* {props.loadingStatus ? <LoadingComponent /> : null} */}

            </div>

            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={getDeleteMessage({
                    title: 'Dashboard'
                })}
                onConfirm={() => handleDelete(currentDashboardIdRef?.current)}
            />




        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        loadingStatus: state.LoadingReducer.loadingStatus,
    };
};
export default connect(mapStateToProps)(DashboardGridListIndex)
