import { Popover, Tooltip } from 'antd'
import React from 'react'
import Buttons from '../../button/Buttons'
import { ICON_FILTER } from '../../newIconSource'
import { Literals } from '../../../modules/common/literals'
import DataGridSortComp from './DataGridSortComp'
import DataGridClearFilter from './DataGridClearFilter'
import DataGridFilterCombination from './DataGridFilterCombination'
import './newDataGridTable.scss'
import { useStore } from 'react-redux'
import { isArray } from 'lodash'

const divider = <div className='d-block my-2' style={{background: '#D9D9D9', height: 1}}></div>

const DataGridFilterPopover = React.forwardRef(({
    colFilterRef = {},
    column = {},
    handleApply = () => {},
    isEnableResetAll = false,
    onClearFilter = () => {},
    domainName = '',
    tableName = '',
    tableId,
    isCustomSql = false,
    staticQuery
}, ref) => {

    const store = useStore()
    
    const [filterOpen, setFilterOpen] = React.useState(false)
    const [showFilterErr, setShowFilterErr] = React.useState(false)
    const [emptyFilter, setEmptyFilter] = React.useState(false)
    const [parentSort, setParentSort] = React.useState(null)
    const [parentFilters, setParentFilters] = React.useState([])
    const [filterType, setFilterType] = React.useState('values')
    const [parentFilterValues, setParentFilterValues] = React.useState([])
    const [filterByValData, setFilterByValData] = React.useState([])

    const sortRef = React.useRef({})
    const filterConditionRef = React.useRef({})

    React.useImperativeHandle(ref, () => {
        // Future Use
        return {
            
        }
    }, [])

    React.useEffect(() => {
        if(filterOpen) {
            let sortColumnList = store.getState().CommonComponent?.DataGridTable?.sortArray
            let filterColumnList = store.getState().CommonComponent?.DataGridTable?.filterArray

            let reduxSortObj = sortColumnList?.find(c => c?.column === column?.name)
            
            if(reduxSortObj !== undefined) {
                sortRef?.current?.setSortingCriteria(reduxSortObj?.sort_criteria)
            }
            else {
                sortRef?.current?.setSortingCriteria(colFilterRef.current[column?.name]?.sorting)
            }



            let reduxFilter = filterColumnList?.filter(c => c?.column === column?.name)
            
            if(reduxFilter && reduxFilter?.length > 0) {
                /**
                 * For Setting Filter by Condition Values
                 */
                let conArr = reduxFilter
                             ?.map(c => ({criteria: c?.objCriteria, value: c?.objValue, date_factor: c?.date_factor}))
                // let conArr = reduxFilter?.filter(v => v?.criteria !== 'in' && v?.criteria !== 'not in')
                                // ?.map(c => ({criteria: c?.objCriteria, value: c?.objValue, date_factor: c?.date_factor}))

                /**
                 * For Setting Filter by Values
                 */
                let valArr = reduxFilter?.filter(v => (v?.criteria === 'in' || v?.criteria === 'not in') && v?.column === column?.name)
                                ?.map(c => c?.value)?.flat()

                if(valArr?.length > 0) {
                    let prev = colFilterRef.current[column?.name]?.column_values ?? []
                    prev = prev?.map(c => ({...c, checked: valArr?.includes(c?.name ?? "")}))
                    filterConditionRef?.current?.setFilterByValues(prev ?? [])
                }

                if(conArr?.length === 0) {
                    conArr = [{
                        "criteria": "none",
                        "value": "",
                        "date_factor": "",
                        "id": 0
                    }]
                }
                
                filterConditionRef?.current?.setConditions(conArr)
                filterConditionRef?.current?.setConditionOperation(reduxFilter?.[0]?.operation)

            }
            else {
                let arr = colFilterRef?.current[column?.name]?.filters

                if(arr?.length === 0) {
                    arr = [{
                        "criteria": "none",
                        "value": "",
                        "date_factor": "",
                        "id": 0
                    }]
                    colFilterRef.current[column.name].filters = arr
                }

                filterConditionRef?.current?.setConditions(arr)
                filterConditionRef?.current?.setConditionOperation(colFilterRef?.current[column?.name]?.operation)
            }

            setFilterType(colFilterRef.current[column?.name]?.filterType)
            filterConditionRef?.current?.setInvertByValue(colFilterRef?.current[column?.name]?.invert ?? false)

        }
    }, [filterOpen])

    React.useEffect(() => {
        if(showFilterErr) {
            setTimeout(() => {
                setShowFilterErr(false)
            }, 2000)
        }
    }, [showFilterErr])

    React.useEffect(() => {
        if(emptyFilter) {
            setTimeout(() => {
                setEmptyFilter(false)
            }, 2000)
        }
    }, [emptyFilter])

    const onApply = () => {
        if(
            colFilterRef.current[column?.name]?.sorting === null 
            && colFilterRef.current[column?.name]?.filters?.every(f => f?.criteria === 'none')
            && filterType === 'condition'
            ) {
                setEmptyFilter(true)
                return
            }

        colFilterRef.current[column?.name] = {
            ...colFilterRef.current[column?.name],
            active: true
        }

        let obj = colFilterRef.current[column?.name]
        if(obj?.filters?.some(f => f?.criteria !== 'none' 
            && (() => {
                    if(isArray(f?.value)) 
                    return f?.value?.some(v => v?.trim() === "")
                    else return f?.value?.trim() === ""
                })()
            )
        ) {
            setShowFilterErr(true)
            return
        }

        handleApply()
    }

    const onClear = () => {
        colFilterRef.current[column?.name] = {
            ...colFilterRef.current[column?.name],
            sorting: null,
            active: false,
            filters: [{
                "criteria": "none",
                "value": "",
                "date_factor": "",
                "id": 0
            }],
            operation: 'and',
            invert: false,
            column_values: colFilterRef.current[column?.name]?.column_values?.map(c => ({...c, checked: false})),
            valuesLoaded: false
        }

        filterConditionRef?.current?.resetFilterByValues()
        
        if(isEnableResetAll) {
            handleApply()
        }
        else {
            sortRef?.current?.setSortingCriteria(null)
            filterConditionRef?.current?.setConditions([{criteria: 'none', value: '', date_factor: ''}])
            filterConditionRef?.current?.setConditionOperation('and')
            filterConditionRef?.current?.setInvertByValue(false)
        }
    }

    const disableApplyBtn = React.useMemo(() => {
        if(filterType === 'condition')
        return parentSort === null && parentFilters?.every(f => f?.criteria === 'none')
        return parentSort === null && filterByValData?.every(f => !f?.checked) // new code
        // return parentSort === null && parentFilterValues?.every(f => !f?.checked) // Previous code
    }, [parentFilters, parentSort, filterType, filterByValData])

    return (
        <div className='new-dgt-filter-comp'>
            <Popover
                arrow={false}
                title=''
                content={
                    <>
                        <div>
                            <DataGridSortComp
                                colFilterRef={colFilterRef}
                                column={column}
                                setParentSort={setParentSort}
                                ref={sortRef}
                            />
                            {divider}
                            <DataGridClearFilter onClear={onClear}/>
                            {divider}
                            <DataGridFilterCombination 
                                colFilterRef={colFilterRef}
                                column={column}
                                setParentFilters={setParentFilters}
                                tableName={tableName}
                                tableId={tableId}
                                domainName={domainName}
                                setFilterType={setFilterType}
                                filterType={filterType}
                                setParentFilterValues={setParentFilterValues}
                                setFilterByValData={setFilterByValData}
                                staticQuery={staticQuery}
                                isCustomSql={isCustomSql}
                                ref={filterConditionRef}
                            />
                            {divider}
                            {
                                parentFilterValues?.length === 0 ? ''
                                :
                                <p className='text-muted fontSizeLabel px-2 mb-1'>
                                    {
                                        parentFilterValues?.length > 10000
                                        ?
                                        "Only showing unique 10,000 records"
                                        :
                                        `Showing unique ${parentFilterValues?.length} ${parentFilterValues?.length === 1 ? 'record' : 'records'}`
                                    }
                                </p>
                            }
                            <p className={`bg-danger text-center border border-danger fontSizeLabel mb-1 ${showFilterErr ? 'px-2 py-1' : 'p-0'} rounded text-white`}
                                    style={{transform: showFilterErr ? 'scale(1)' : 'scale(0)', height: showFilterErr ? 'auto' : 0 , transition: '.3s'}}
                                >
                                Empty field found in "Filter"
                            </p>
                            <p className={`bg-warning text-center text-black fontSizeLabel mb-1 ${emptyFilter ? 'px-2 py-1' : 'p-0'} rounded`}
                                    style={{transform: emptyFilter ? 'scale(1)' : 'scale(0)', height: emptyFilter ? 'auto' : 0 , transition: '.3s'}}
                                >
                                Nothing to Apply
                            </p>
                            <div className='d-flex w-100 justify-content-end align-items-center pt-1 dgt-filter-action-wrap'>
                                <Buttons
                                    props={{
                                        buttonText: "Cancel",
                                        buttonClassName: `custom-btn-outline custom-btn btn-with-text border mr-2`,
                                        buttonEvent: () => {
                                            setFilterOpen(false)
                                        },
                                        ImgSrc: () => <></>,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                                <Buttons
                                    props={{
                                        tooltip: "",
                                        buttonText: "Apply",
                                        buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                                        buttonEvent: () => {
                                            onApply()
                                        },
                                        ImgSrc: () => <></>,
                                        isDisable: disableApplyBtn,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                }
                trigger={'click'}
                placement="rightTop"
                open={filterOpen}
                onOpenChange={open => setFilterOpen(open)}
                rootClassName="prv-new-filter-pop"
                autoAdjustOverflow
                destroyTooltipOnHide
              >
                <Tooltip
                    title={'Filter'}
                    placement='top'
                    fresh
                    destroyTooltipOnHide
                >
                    <button
                        className={`dgt-new-flt-btn custom-btn-outline custom-btn btn-with-icon 
                        ${filterOpen ? 'rdg-filter-active' : ''}
                        ${colFilterRef.current[column?.name]?.active ? 'dgt-filter-active rdg-filter-active' : ''}
                        `}
                        onClick={() => {
                            setFilterOpen(!filterOpen)
                        }}
                    >
                        <ICON_FILTER/>
                    </button>
                </Tooltip>
              </Popover>
        </div>
    )
})

export default DataGridFilterPopover