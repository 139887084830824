// src/SocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { retriveAPIBaseURL } from '../store/modules/dataCatalogue/preview/previewActions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserGroupList } from '../store/modules/UserManagement/users/usersActions';
import { isUrlIncludePublicRoute } from '../components/modules/common/helperFunctions';


const SocketContext = createContext();

export const SocketProvider = ({ children }) => {

    const [socketResponse, setSocketResponse] = useState(null);
    const [userDetails, setuserDetails] = useState(null);

    const newSocket = useRef(null);
    let socketInstances = useRef([]);
    const userList = useRef([]);
    

    // const CurrentUserDetails = useSelector(state => state?.UserReducer?.user?.userDetails)
    // const getUsersList = async () => {

    //     let response;
    //     try {
    //         response = await dispatch(
    //             getUserGroupList({ method: "GET", endPoint: "group_manager/user/" })
    //         );
    //         if (response.status === "success") {
    //             userList.current = response?.data

    //         }
    //     } catch (error) {
    //     }
    // };



    // Function to close WebSocket connection


    function closeAllSockets() {

        socketInstances?.current?.forEach(socket => {
            if (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING) {
                socket.close(); 
            }
        });
        // Optionally clear the socketInstances array after closing all sockets
        socketInstances.current = [];
    }


    // const checkConnection = () => {
    //     const now = Date.now();
    //     console.log(socketInstances.current?.length)
    //     if (!isUrlIncludePublicRoute() && (now - lastStatusTime) > 30000) {

    //         if (!socketInstances.current?.length) {
    //             closeAllSockets();
    //             createSocketConnection()

    //         }
    //     }
    // };

    useEffect(() => {

        if (isUrlIncludePublicRoute()) {
            if (socketInstances?.current?.length) {
                closeAllSockets();
            }
        }else{
            closeAllSockets();
            checkConnectionState();
        }
    }, []);

    const createSocketConnection = async () => {


        try {

            const BASE_URL = window._env_.REACT_APP_API_BASE_URL;
            const apiUrl = await retriveAPIBaseURL(`wss://${BASE_URL?.split('https://')[1]}ws/cluster/`);
            let newSocket1 = new WebSocket(apiUrl);
            newSocket.current = newSocket1;
            socketInstances.current.push(newSocket1)

            newSocket1.onopen = function (event) {

                console.log('WebSocket connection established.');
            };

            newSocket1.onmessage = function (event) {
                const data = JSON.parse(event.data);
                setSocketResponse(data);
                if (data?.users_detail?.length) {
                    setuserDetails(data?.users_detail);
                    userList.current=data?.users_detail;
                }

            };

            newSocket1.onerror = function (event) {
             
                // The error event does not necessarily mean the connection is closed.
            };

            newSocket1.onclose = function (event) {
                socketInstances.current = socketInstances?.current.filter(s => s !== newSocket1);
            };

        } catch (error) {

            

        }

    }

    // useEffect(() => {
    //     if (CurrentUserDetails) {
    //         getUsersList();
    //     }

    // }, [CurrentUserDetails])

    function checkConnectionState() {
        if (!socketInstances?.current?.length) {
            closeAllSockets();
            createSocketConnection()
        }


    }


    // Handle visibility change
    const handleVisibilityChange = () => {
        if (document.hidden) {

        } else {
            checkConnectionState();
        }
    };


    useEffect(() => {
        // Add event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup WebSocket and event listener when component unmounts
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);



    return (
        <SocketContext.Provider value={{
            socket: newSocket.current,
            socketInstances: socketInstances.current,
            socketResponse: socketResponse,
            userDetails: userDetails,
            userList: userList.current,
            checkConnectionState: checkConnectionState,
            closeAllSockets: closeAllSockets
        }}>

            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => {
    return useContext(SocketContext);
};
